import React, { useEffect, useState } from 'react'

import { ID } from 'src/constants/id.constants'
import { LANGUAGE_OPTIONS, LanguageOptions, savedLanguage, userLanguage } from 'src/models/language.model'
import { getInitialLanguage } from 'src/utils/language.utils'

import i18n from '../../i18n'
import LanguageButton from '../button/languageButton/LanguageButton'
import Dropdown from '../dropdown/Dropdown'

import styles from './langSelect.module.scss'

interface ILangSelect {
  type?: 'text' | 'flag';
  background?: 'dark' | 'light';
}

const LangSelect = ({ type = 'text', background = 'dark' }: ILangSelect) => {
  const initialLanguage = getInitialLanguage()
  const [selected, setSelected] = useState<LanguageOptions>(initialLanguage)

  useEffect(() => {
    setSelected(savedLanguage || userLanguage)
    i18n.changeLanguage(savedLanguage || userLanguage)
  }, [])

  return (
    <Dropdown
      id={ID.headerButtonOption}
      selectedAction={selected}
      sections={{ language: LANGUAGE_OPTIONS }}
      className={styles.main}
    >
      <div>
        <LanguageButton
          label={selected}
          type={type}
          background={background}
        />
      </div>
    </Dropdown>
  )
}

export default React.memo(LangSelect)
