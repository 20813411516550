import { RegularButtonTypes } from 'src/components/button/RegularButton'
import SWRErrorNotification from 'src/components/swr/SWRErrorNotification'
import { I18N_OFFER_CARD_PREVIOUS } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { ILimitedSingleOfferList, IStackBoundaryProps, IStackProps } from 'src/models/post.model'
import { useGetPostList } from 'src/services/post.service'

import CardStack from './CardStack'

const ViewHistoryCardStack = ({ stackEndpoint }: IStackProps) => {
  const { data: viewedOffers, isLoading } = useGetPostList<ILimitedSingleOfferList>(stackEndpoint)

  if (isLoading || !viewedOffers) {
    return null
  }

  const { items, hasMore } = viewedOffers

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const moreButton = hasMore ? {
    id: ID.viewHistoryCardStackLinkToViewHistory,
    buttonType: RegularButtonTypes.transparentWithBorder,
    linkTo: YomeURL.notFound,
    label: '',
  } : undefined

  return (
    <CardStack
      idOfferPrefix={ID.viewHistoryCardStackPrefixOffer}
      cardStackLabel={`${I18N_OFFER_CARD_PREVIOUS}.label`}
      offerList={items}
      offerListKey={stackEndpoint}
    />
  )
}

const ViewHistoryCardStackBoundary = ({ offerLimit }: IStackBoundaryProps) => {
  const stackEndpoint = SWRkeys.viewedOffers({ limit: offerLimit })

  return (
    <SWRErrorNotification swrKey={stackEndpoint}>
      <ViewHistoryCardStack stackEndpoint={stackEndpoint}/>
    </SWRErrorNotification>
  )
}

export default ViewHistoryCardStackBoundary
