import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getSearchBarInitialCategory, getSearchBarInitialCategoryPath } from 'src/utils/search.utils'

export const useSelectedCategoryPath = () => {
  const [searchParams] = useSearchParams()
  const initialSearchCategory = getSearchBarInitialCategory(searchParams)
  const [selectedPath, setSelectedPath] = useState<(string)[]>(getSearchBarInitialCategoryPath(initialSearchCategory))

  return { selectedPath, setSelectedPath }
}

export const useCategoryLevel = (initialLevel: number, setSelectedPath: (newPath: string[]) => void) => {
  const [level, setLevel] = useState(initialLevel)

  const goToNextLevel = useCallback((newPath: string[]) => {
    setLevel(level + 1)
    setSelectedPath(newPath)
  }, [setSelectedPath, level])

  const goLevelBack = useCallback(() => setLevel(level - 1), [level])

  return { level, goToNextLevel, goLevelBack }
}
