import classNames from 'classnames'

import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { OAuthProviders } from 'src/models/auth.model'

import styles from './socialButton.module.scss'

interface SocialButtonProps {
  socialName: OAuthProviders;
  socialLink: string;
}

const SocialButton = ({ socialName, socialLink }: SocialButtonProps) => (
  <a className={classNames(styles.singleSocial, styles[socialName])} href={socialLink}>
    <SvgIcon name={socialName} size={24}/>
  </a>
)

export default SocialButton
