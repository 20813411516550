import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'

import ProfileHeading from 'src/routes/profile/components/ProfileHeading'

import { ITabItem } from '../../models/navigation.model'
import ErrorBoundary from '../errorBoundary/ErrorBoundary'
import Heading from '../heading/Heading'
import SomethingWentWrong from '../somethingWentWrong/SomethingWentWrong'

import styles from './layout.module.scss'

type Props = {
  title: string;
  tabItems: ITabItem[];
}

const TabBarLayout: FC<Props> = ({ title, tabItems }: Props) => {
  const { t } = useTranslation()

  return (
    <main className={styles.main}>
      <ProfileHeading label={title} />
      <div className={styles.nav}>
        <div className={styles.tabItems}>
          {
            tabItems.map(({ label, link, count }) => (
              <Heading level='h3' key={label} className={classNames('mobile-h6', styles.tabItem)}>
                <NavLink
                  className={(meta) => classNames(styles.tabLink, { [styles.selected]: meta.isActive })}
                  to={link}
                  end
                >
                  <div className={styles.tabName}>
                    { t(label) }
                  </div>
                  <sup className={styles.powerItemNumber}>
                    {count}
                  </sup>
                </NavLink>
              </Heading>
            ))
          }
        </div>
        <div className={styles.hr} />
      </div>
      <ErrorBoundary fallback={<SomethingWentWrong />}>
        <Outlet />
      </ErrorBoundary>
    </main>
  )
}

export default TabBarLayout
