import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'

import { PropsWithChildren, memo } from 'react'
import styles from '../profileLayout.module.scss'

interface IProfileHeading {
  label?: string;
}

const ProfileHeading = ({ label, children }: PropsWithChildren<IProfileHeading>) => {
  const className = classNames('desktop-h4 mobile-h4', styles.profileHeading)

  if (label) {
    return <Heading label={label} className={className} />
  }

  return (
    <Heading className={className}>
      {children}
    </Heading>
  )
}

export default memo(ProfileHeading)
