import React, { useCallback, useEffect, useState } from 'react'

import { EmailUrlAction } from 'src/models/url.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { BaseAuthModalProps } from 'src/models/auth.model'
import EmailSentModal from './EmailSentModal'

import styles from './auth.module.scss'

export interface IAuthModalBase {
  handleClose: () => void;
}

interface IAuthModal extends IAuthModalBase {
  SingleAuthModal: React.MemoExoticComponent<({ onSuccessSubmit }: BaseAuthModalProps) => JSX.Element | null>;
}

const AuthModal = React.memo(({ handleClose, SingleAuthModal }: IAuthModal) => {
  const { data: currentUser } = useCurrentUser()
  const [actionSubmitted, setActionSubmitted] = useState<EmailUrlAction | null>(null)
  const [email, setEmail] = useState<string>('')

  const onSuccessSubmit = useCallback((action: EmailUrlAction, userEmail: string) => {
    setActionSubmitted(action)
    setEmail(userEmail)
  }, [setActionSubmitted, setEmail])

  useEffect(() => {
    if (currentUser) {
      handleClose()
    }
  }, [currentUser, handleClose])

  if (currentUser) {
    return null
  }

  if (actionSubmitted) {
    return (
      <div className={styles.authModalContainer}>
        <EmailSentModal
          action={actionSubmitted}
          handleClose={handleClose}
          email={email}
        />
      </div>
    )
  }

  return (
    <div className={styles.authModalContainer}>
      <SingleAuthModal onSuccessSubmit={onSuccessSubmit} handleClose={handleClose} />
    </div>
  )
})

export default AuthModal
