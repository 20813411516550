import { StringRecords } from './common.model'

export interface IModalWithClose {
  handleClose?: () => void;
}

export interface IModalWithCloseAndContext extends IModalWithClose{
  payload?: StringRecords;
}

export type ModalComponentType = (React.MemoExoticComponent<(
  { handleClose, payload }: { handleClose: () => void; payload?: StringRecords }
) => JSX.Element | null>) | undefined

export const enum ContextModalType {
  verifiedPhone = 'verifiedPhone',
  userReported = 'userReported',
  blockUser = 'blockUser',
  userBlocked = 'userBlocked',
}
