import { Navigate } from 'react-router-dom'

import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import PrivateRoute from '../PrivateRoute'

import ProfileMenuBoundary from './components/profileMenu/ProfileMenu'

const PrivateProfileRoute = () => {
  const { isDesktop } = useViewport()

  if (isDesktop) {
    return <Navigate to={YomeURL.offers} />
  }

  return (
    <PrivateRoute>
      <ProfileMenuBoundary />
    </PrivateRoute>
  )
}

export default PrivateProfileRoute
