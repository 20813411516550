// eslint-disable-next-line import/prefer-default-export
export enum OfferStatus {
  draft = 'draft',
  deleted = 'deleted',

  moderating = 'moderating',
  declined = 'declined',
  blocked = 'blocked',

  active = 'active',
  expired = 'expired',
  closed = 'closed',
}
