import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { ICategory } from 'src/yome-categories-fields-config/models/category.model'

import CategorySingleButton, { ICategoryDropdownBaseProps } from './CategorySingleButton'

import styles from './categorySearchDropdown.module.scss'

interface ICategoryDropdownLevelProps extends ICategoryDropdownBaseProps {
  items: ICategory[];
  parent?: string | undefined;
}

const CategoryDropdownLevel = ({ items, parent, ...other }: ICategoryDropdownLevelProps) => {
  const { t } = useTranslation()

  return (
    <ul className={styles.categoryLevel}>
      { parent && (
        <div className={classNames(styles.parent, 'label-20')}>
          <p>
            { t(`${I18N_CATEGORIES}.${parent}`) }
          </p>
        </div>
      )}
      {items.map((category) => (
        <CategorySingleButton
          key={category.title}
          category={category}
          {...other}
        />
      ))}
    </ul>
  )
}

export default CategoryDropdownLevel
