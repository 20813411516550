import classNames from 'classnames'
import { memo } from 'react'
import { Trans } from 'react-i18next'
import { createSearchParams, Link } from 'react-router-dom'

import { I18N_CATEGORY_CARD } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'

import { FREE_ITEMS_CARD_ID } from './categoryCards.data'

import styles from './categoryCards.module.scss'

export interface ICategoryCard {
  id: string;
  isLarge?: boolean;
}

const getLinkAddress = (id: string) => {
  if (id.toLowerCase() === FREE_ITEMS_CARD_ID) {
    const params = createSearchParams({
      category: ANY_CATEGORY,
      priceMin: '0',
      priceMax: '0',
    }).toString()

    return `${YomeURL.search}?${params}`
  }

  const params = createSearchParams({
    category: id,
  }).toString()

  return `${YomeURL.search}?${params}`
}

const CategoryCard = ({ id, isLarge = false }: ICategoryCard) => {
  const categoryId = id.toUpperCase()

  return (
    <Link
      className={classNames(styles.card, { [styles.large]: isLarge })}
      to={getLinkAddress(categoryId)}
      style={{ backgroundImage: `url('/category-cards/${id}.png')` }}
    >
      <span className='label-14'>
        <Trans i18nKey={`${I18N_CATEGORY_CARD}.${categoryId}`} />
      </span>
    </Link>
  )
}

export default memo(CategoryCard)
