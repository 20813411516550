import classNames from 'classnames'
import { ButtonHTMLAttributes, memo } from 'react'
import { useTranslation } from 'react-i18next'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './imageButton.module.scss'

interface ImageButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  label: string;
  size: 'medium' | 'large';
  icon?: string;
  isActive?: boolean;
  showChevron?: boolean;
  supportiveText?: string;
  className?: string;
}

const ImageButton = (
  {
    onClick,
    label,
    icon,
    showChevron = true,
    isActive = false,
    size,
    supportiveText,
    className,
    ...rest
  }: ImageButtonProps,
) => {
  const { t } = useTranslation()
  const iconSize = size === 'medium' ? 32 : 48

  const supportiveSizeClass = size === 'medium' ? 'supportive-12' : 'supportive-14'

  return (
    <button
      type='button'
      onClick={onClick}
      className={classNames(styles.main, { [styles.active]: isActive, [styles.medium]: size === 'medium' })}
      {...rest}
    >
      <div className={styles.container}>
        <div className={styles.primary}>
          {icon && (
            <SvgIcon name={icon} size={iconSize} />
          )}
          <span className={classNames(styles.label, { 'label-20': size === 'large' })}>
            {t(label)}
          </span>
        </div>
        {supportiveText && (
          <span className={classNames(styles.secondary, supportiveSizeClass, className)}>
            {t(supportiveText)}
          </span>
        )
        }
      </div>
      {
        showChevron && (
          <div className={styles.chevron}>
            <SvgIcon name={'chevron-right'} size={24} />
          </div>
        )
      }
    </button >
  )
}

export default memo(ImageButton)
