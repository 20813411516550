import { memo } from 'react'
import { FormRenderProps } from 'react-final-form'

import { FormButton } from 'src/components/form'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { PostFormFields } from 'src/models/post.model'

import FullOfferForm from './FullOfferForm'
import MultistepOfferForm from './MultistepOfferForm'

interface IEditDraftForm extends FormRenderProps<PostFormFields, PostFormFields> {
  partIndex: number;
  setPartIndex: (value: number) => void;
}

const EditOfferForm = ({ handleSubmit, form: { getState }, setPartIndex, partIndex }: IEditDraftForm) => {
  const { isDesktop } = useViewport()

  const { values, hasValidationErrors } = getState()

  return (isDesktop ? (
    <FullOfferForm
      handleSubmit={handleSubmit}
      categoryPath={values.categoryPath}
      publicId={values.publicId}
    >
      <FormButton id={ID.editItemEditPost} disabled={hasValidationErrors} />
    </FullOfferForm>
  ) : (
    <form onSubmit={handleSubmit} className='h-100'>
      <MultistepOfferForm
        categoryPath={values.categoryPath}
        publicId={values.publicId}
        setPartIndex={setPartIndex}
        partIndex={partIndex}
      />
    </form>
  ))
}

export default memo(EditOfferForm)
