import { memo, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useSearchParams } from 'react-router-dom'

import IconButton from 'src/components/button/IconButton'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import { I18N_GLOBAL, I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { CATEGORY_QPARAM, IFilterValues } from 'src/models/search.model'
import TranslationToggle from 'src/routes/search/components/searchActionBar/TranslationToggle'
import { getFilterSearchParams, getFiltersFieldsStructure, getInitialFilters } from 'src/utils/filter.utils'
import { useDebouncedEffect } from 'src/utils/hooks.utils'

import FilterSidebarSummary from './FilterSidebarSummary'
import FiltersList from './FiltersList'
import ResetFiltersButton from './ResetFiltersButton'

import styles from './filter.module.scss'

const FILTER_SUBMIT_DEBOUNCE_DELAY = 300

interface IFilterSidebar {
  closePage?: () => void;
}

const FilterSidebar = ({ closePage }: IFilterSidebar) => {
  const { isDesktop } = useViewport()

  const [searchParams, setSearchParams] = useSearchParams()
  const currentCategory = searchParams.get(CATEGORY_QPARAM)

  const categoryFields = getFiltersFieldsStructure(currentCategory)

  const initialValues = useMemo(() => getInitialFilters(searchParams, categoryFields), [searchParams, categoryFields])

  const onFilterSubmit = useCallback((values: IFilterValues) => {
    const newSearchParams = getFilterSearchParams(values)

    if (searchParams.toString() !== newSearchParams.toString()) {
      setSearchParams(newSearchParams)
    }

    if (closePage) {
      closePage()
    }
  }, [closePage, setSearchParams, searchParams])

  return (
    <Form
      onSubmit={onFilterSubmit}
      initialValues={initialValues}
      render={function FiltersForm({ handleSubmit, values }) {
        useDebouncedEffect(() => {
          handleSubmit()
        }, FILTER_SUBMIT_DEBOUNCE_DELAY, values, !isDesktop)

        return (
          <form onSubmit={handleSubmit} className={styles.filterSidebarForm}>
            <div className={styles.heading}>
              {closePage && (
                <IconButton
                  id={ID.modalBackButton}
                  iconName='arrow-left'
                  onClick={closePage}
                />
              )}
              <Heading
                level='h2'
                label={`${I18N_SEARCH_FILTER}.title`}
                className={isDesktop ? 'desktop-h5' : 'label-20'}
              />
              {!isDesktop && <ResetFiltersButton/>}
            </div>
            <div className={styles.filterPanel}>
              {isDesktop ? (
                <FilterSidebarSummary categoryFields={categoryFields} />
              ) : (
                <TranslationToggle/>
              )}
              <FiltersList initialFields={categoryFields}/>
            </div>
            <div className='fixed-modal-button'>
              <RegularButton
                id={ID.searchFilterShowOffer}
                buttonType={RegularButtonTypes.main}
                label={`${I18N_GLOBAL}.showOffers`}
                type='submit'
                className={'desktop-hide'}
              />
            </div>
          </form>
        )
      }}
    />
  )
}

export default memo(FilterSidebar)
