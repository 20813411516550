import classNames from 'classnames'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton, FormEmailField, FormError } from 'src/components/form'
import { ERROR_CODE, RESET_ALREADY_SENT } from 'src/constants/error.constants'
import { I18N_AUTH } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { YomeHash } from 'src/constants/navigate.constants'
import { useHashUrl } from 'src/hooks/router.hooks'
import { BaseAuthModalProps } from 'src/models/auth.model'
import { EmailUrlAction } from 'src/models/url.model'
import { reset } from 'src/services/auth.service'
import { useViewport } from 'src/contexts/viewportContext'
import InfoModal from '../infoModal/InfoModal'
import AuthModal, { IAuthModalBase } from './AuthModal'

import styles from './auth.module.scss'

type ResetProps = BaseAuthModalProps

const ResetModal = React.memo(({ onSuccessSubmit, handleClose }: ResetProps) => {
  const { t } = useTranslation()
  const { isDesktop } = useViewport()

  return (
    <InfoModal
      title={`${I18N_AUTH}.passwordReset.title`}
      handleClose={handleClose}
    >
      <p className={classNames(styles.secondaryText, 'bodySizeL')}>
        {t(`${I18N_AUTH}.passwordReset.text`)}
      </p>
      <Form
        onSubmit={reset}
        render={function ResetFormRender({ values, handleSubmit, submitErrors, submitError, submitSucceeded }) {
          React.useEffect(() => {
            const isAlreadySent = !!submitErrors && submitErrors[ERROR_CODE] === RESET_ALREADY_SENT

            if (submitSucceeded || isAlreadySent) {
              onSuccessSubmit(EmailUrlAction.reset, values.email)
            }
          }, [submitErrors, submitSucceeded, values.email])

          return (
            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <FormEmailField name="email" id={ID.resetModalInputEmail} className={styles.field} autoFocus />
              <FormError error={submitError}/>
              <FormButton id={ID.resetModalResetPassword} label={t(`${I18N_AUTH}.passwordReset.button`)}/>
            </form>
          )
        }}
      />
      <div className={styles.footerReset}>
        <p className="bodySizeM">
          {t(`${I18N_AUTH}.dontHaveAccount`)}
        </p>
        <RegularButton
          id={ID.signupModalLinkToLoginModal}
          label={`${I18N_AUTH}.button.createAccount`}
          linkTo={useHashUrl(YomeHash.signup)}
          buttonType={
            isDesktop ? RegularButtonTypes.transparentNoBorderLight : RegularButtonTypes.transparentWithBorder
          }
          className={styles.buttonFullWidth}
        />
      </div>
    </InfoModal>
  )
})

const AuthResetModal = React.memo(({ handleClose }: IAuthModalBase) => (
  <AuthModal handleClose={handleClose} SingleAuthModal={ResetModal}/>
))

export default AuthResetModal
