import YomeURL from 'src/constants/navigate.constants'
import { addProfileActionNames } from 'src/utils/profile.utils'

import { ICurrentUser } from './currentUser.model'
import { TDropdownSections } from './dropdown.model'
import { IUser } from './user.model'

export interface IProfile extends IUser {
  phone: string;
  city: string;
  email: string;
}

export interface ISettingsProps {
  profile: ICurrentUser;
}

export const PROFILE_MENU_SECTIONS: TDropdownSections = {
  posts: {
    offers: {
      linkTo: YomeURL.offers,
      icon: 'list',
    },
    orders: {
      linkTo: YomeURL.orders,
      icon: 'announcement',
    },
    reviews: {
      linkTo: YomeURL.reviews,
      icon: 'feedback',
    },
    favorites: {
      linkTo: YomeURL.favorites,
      icon: 'heart',
    },
  },
  messages: {
    messages: {
      linkTo: YomeURL.messages,
      icon: 'message',
    },
    notifications: {
      linkTo: YomeURL.notifications,
      icon: 'bell',
    },
  },
  finance: {
    wallet: {
      linkTo: YomeURL.wallet,
      icon: 'wallet',
    },
    business: {
      linkTo: YomeURL.business,
      icon: 'chart-up',
    },
    services: {
      linkTo: YomeURL.services,
      icon: 'currency-euro',
    },
  },
  setting: {
    settings: {
      linkTo: YomeURL.settings,
      icon: 'gear',
    },
    legal: {
      linkTo: YomeURL.legal,
      icon: 'certificate',
    },
    help: {
      linkTo: YomeURL.help,
      icon: 'help',
    },
  },
}

export const PROFILE_MENU_SECTIONS_WITH_NAMES = addProfileActionNames(PROFILE_MENU_SECTIONS)

export enum AvatarChangeTypes {
  upload = 'upload',
  delete = 'delete',
}

export const PROFILE_AVATAR_LOCALIZE_PATH = 'profile.avatar'
