import classNames from 'classnames'

import RegularButton, { IRegularButtonProps } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import PostList from 'src/components/postList/PostList'
import ShortenOfferCardList from 'src/components/shortenOfferCard/ShortenOfferCardList'
import { useViewport } from 'src/contexts/viewportContext'
import { CardInfo, SinglePost } from 'src/models/post.model'

import styles from './cardStack.module.scss'

interface CardStackProps {
  idOfferPrefix: string;
  cardStackLabel: string;
  offerList: SinglePost[];
  offerListKey: string;
  moreButton?: IRegularButtonProps;
  hasAllFavorite?: boolean;
  cardInfo?: CardInfo;
}

const CardStack = (
  {
    idOfferPrefix,
    cardStackLabel,
    offerList,
    offerListKey,
    moreButton,
    hasAllFavorite = false,
    cardInfo = CardInfo.location,
  }: CardStackProps,
) => {
  const { isDesktop } = useViewport()

  return (
    <>
      {offerList.length > 0 && (
        <div className={styles.cardStackMain}>
          <Heading
            level='h3'
            label={cardStackLabel}
            className={classNames(styles.cardStackLabel, 'desktop-h6 mobile-h5')}
          />
          {isDesktop ? (
            <ShortenOfferCardList
              idPrefix={idOfferPrefix}
              postList={offerList}
              offerSourceEndpoint={offerListKey}
              hasAllFavorite={hasAllFavorite}
              cardInfo={cardInfo}
            />
          )
            : (
              <PostList
                idPostPrefix={idOfferPrefix}
                posts={offerList}
                postListKey={offerListKey}
                hasAllFavorite={hasAllFavorite}
                cardInfo={cardInfo}
              />
            )}
          {moreButton && (
            <div className={styles.moreButton}>
              <RegularButton
                {...moreButton}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CardStack
