import IconButton from 'src/components/button/IconButton'
import { SvgColorAttr } from 'src/components/svgIcon/SvgIcon'
import { ID } from 'src/constants/id.constants'
import YomeAPI from 'src/constants/network.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useFavorite } from 'src/hooks/favorite.hooks'
import { useHandleShareOfferLink } from 'src/hooks/offer.hooks'

import styles from './fullscreenOfferImages.module.scss'

interface ITopBarButtonsProps {
  slug: string;
  title: string;
  isFavorite: boolean;
  isCreatedByCurrentUser?: boolean;
  handleClose: () => void;
}

const TopBarButtons = ({ slug, title, isFavorite, isCreatedByCurrentUser, handleClose }: ITopBarButtonsProps) => {
  const { isDesktop } = useViewport()

  const offerSourceEndpoint = YomeAPI.getPublicOffer(slug)
  const { handlePostLike, favoriteIconName } = useFavorite({ slug, isFavoritePost: isFavorite, offerSourceEndpoint })

  const handleShareLink = useHandleShareOfferLink(title)

  const commonButtonProps = {
    iconColor: 'stroke' as SvgColorAttr,
    size: 32,
  }

  return (
    <div className={styles.buttonRow}>
      <IconButton
        id={ID.topBarBackButton}
        iconName='share'
        onClick={handleShareLink}
        className={styles.shareButton}
        {...commonButtonProps}
      />
      {!isCreatedByCurrentUser && (
        <IconButton
          id={ID.topBarBackButton}
          iconName={favoriteIconName}
          onClick={handlePostLike}
          className={styles.likeButton}
          {...commonButtonProps}
          iconColor={isFavorite ? 'fill' : 'stroke'}
        />
      )
      }
      <IconButton
        id={ID.topBarBackButton}
        iconName={isDesktop ? 'close-cross' : 'arrow-left'}
        onClick={handleClose}
        className={styles.closeButton}
        {...commonButtonProps}
      />
    </div>
  )
}

export default TopBarButtons
