import React from 'react'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_FEATURE_UNAVAILABLE_MODAL, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import InfoModal from '../infoModal/InfoModal'

import styles from './featureUnavailableModal.module.scss'

interface IFeatureUnavailableModal {
  handleClose: () => void;
}

const FeatureUnavailableModal = React.memo(({ handleClose }: IFeatureUnavailableModal) => {
  const footerButton = (
    <RegularButton
      id={ID.featureUnavailableCloseModal}
      label={`${I18N_GLOBAL}.gotIt`}
      buttonType={RegularButtonTypes.blackNoBorder}
      onClick={handleClose}
    />
  )

  return (
    <InfoModal
      className={styles.main}
      topIcon='locked'
      title={`${I18N_FEATURE_UNAVAILABLE_MODAL}.title`}
      modalFooter={footerButton}
    >
      <Paragraph
        text={`${I18N_FEATURE_UNAVAILABLE_MODAL}.text`}
        className='bodySizeL'
      />
    </InfoModal>
  )
})

export default FeatureUnavailableModal
