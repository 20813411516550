import useSWR from 'swr'

import { ICurrentUser } from 'src/models/currentUser.model'
import { useRefetch } from 'src/utils/hooks.utils'
import { sendGet } from 'src/utils/network.utils'
import SWRkeys from 'src/constants/swr.constants'

export const useCurrentUser = () => useSWR<ICurrentUser>(
  SWRkeys.currentUser,
  sendGet,
)

export const useResetCurrentUser = () => useRefetch(SWRkeys.currentUser)
