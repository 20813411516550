import classNames from 'classnames'
import { memo, useCallback, useState } from 'react'
import { FreeMode, Keyboard, Navigation, Swiper as SwiperInstance, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

import ImageOrNoPhoto from 'src/components/imageOrNoPhoto/ImageOrNoPhoto'
import { useViewport } from 'src/contexts/viewportContext'
import { SinglePost } from 'src/models/post.model'
import { useToggle } from 'src/utils/hooks.utils'
import { getImagePostUrl, getThumbSmallUrl } from 'src/utils/images.utils'
import { checkIsInactiveView } from 'src/utils/post.utils'

import FullScreenOfferImages from '../fullscreenOfferImages/FullScreenOfferImages'
import ImagesCounter from './ImagesCounter'
import ThumbsSwiper from './ThumbsSwiper'

import styles from './offerImages.module.scss'

interface IBluredImage {
  src: string;
  semitransparent: boolean;
}

interface IOfferImage {
  src: string;
  title: string;
  isOfferInactive: boolean;
}

interface ISingleImage extends Omit<IOfferImage, 'src'>{
  src: string | null;
  onClick: () => void;
}

interface IOfferImagesProps {
  offer: SinglePost;
  showNumber?: boolean;
}

const BluredImage = memo(({ src, semitransparent }: IBluredImage) => (
  <ImageOrNoPhoto
    src={src}
    className={classNames(styles.blur, { semitransparent })}
  />
))

const OfferImage = memo(({ src, title, isOfferInactive }: IOfferImage) => (
  <>
    <BluredImage src={src} semitransparent={isOfferInactive} />
    <ImageOrNoPhoto
      src={src}
      alt={title}
      objectFit='contain'
      className={classNames(styles.image, { semitransparent: isOfferInactive })}
    />
  </>
))

const SingleImage = memo(({ src, title, isOfferInactive, onClick }: ISingleImage) => (
  <div className={styles.main}>
    <div className={styles.single} onClick={onClick}>
      { src ? (
        <OfferImage src={src} title={title} isOfferInactive={isOfferInactive} />
      ) : (
        <ImageOrNoPhoto
          src={src}
          alt={title}
          objectFit='contain'
          className={classNames(styles.image, { semitransparent: isOfferInactive })}
        />
      )}
    </div>
  </div>
))

const OfferImages = ({ offer, showNumber = false }: IOfferImagesProps) => {
  const { isDesktop } = useViewport()

  const [isFullScreen, toggleFullScreen] = useToggle()

  const [activeImageIndex, setActiveImageIndex] = useState(0)

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperInstance | null>(null)
  const images = offer.photos.map((imageObj) => getImagePostUrl(imageObj, offer.publicId))
  const offerThumbPhotos = offer.photos.map((imageObj) => getThumbSmallUrl(imageObj, offer.publicId))

  const hasSwiper = images.length > 1
  const isInactiveView = checkIsInactiveView(offer)
  const isDisplayFullScreen = isFullScreen && !isInactiveView

  const onActiveIndexChange = useCallback((swiper: SwiperInstance) => {
    setActiveImageIndex(swiper.realIndex)
  }, [])

  if (!hasSwiper) {
    const src = images.length ? images[0] : null

    return (
      <div>
        <SingleImage
          src={src}
          title={offer.title}
          isOfferInactive={isInactiveView}
          onClick={toggleFullScreen}
        />
        {isDisplayFullScreen && src && (
          <FullScreenOfferImages
            handleClose={toggleFullScreen}
            offer={offer}
            showNumber={showNumber}
          />
        )}
      </div>
    )
  }

  return (
    <div className={styles.main}>
      <Swiper
        spaceBetween={10}
        className="offer-page-slider"
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Keyboard]}
        keyboard={{
          enabled: true,
        }}
        onClick={toggleFullScreen}
        onActiveIndexChange={onActiveIndexChange}
        loop
        navigation
      >
        {images?.map((url, index) => (
          <SwiperSlide key={`slide-${index}`}>
            <OfferImage src={url!} title={offer.title} isOfferInactive={isInactiveView} />
            {showNumber && <ImagesCounter index={index} total={images.length} />}
          </SwiperSlide>
        ))}
      </Swiper>
      {isDesktop && (
        <ThumbsSwiper
          thumbPhotos={offerThumbPhotos}
          title={offer.title}
          isInactiveView={isInactiveView}
          onSwiper={setThumbsSwiper}
        />
      )}
      {isDisplayFullScreen && (
        <FullScreenOfferImages
          handleClose={toggleFullScreen}
          offer={offer}
          showNumber={showNumber}
          activeImageIndex={activeImageIndex}
        />
      )}
    </div>
  )
}

export default memo(OfferImages)
