import React from 'react'

import { DateFormatType } from 'src/models/date.model'
import { assertData } from 'src/utils/error.utils'

import { useDisplayChatDate, useDisplayDate, useDisplayDateFormatted, useDisplayTime } from '../../utils/date.utils'

interface IDateComponentProp {
  isoTimestamp: string;
  type?: DateFormatType;
  options?: Intl.DateTimeFormatOptions;
  hasOnlyLabel?: boolean;
}

const DateComponent = React.memo(({
  type,
  options,
  isoTimestamp,
  hasOnlyLabel = false,
}: IDateComponentProp) => {
  const displayDate = useDisplayDate()
  const displayTime = useDisplayTime()
  const displayDateFormatted = useDisplayDateFormatted()
  const displayChatDate = useDisplayChatDate(type === DateFormatType.chatShort)

  if (type === DateFormatType.time) {
    return (
      <>
        {displayTime(isoTimestamp)}
      </>
    )
  }

  if (type === DateFormatType.chat || type === DateFormatType.chatShort) {
    return (
      <>
        {displayChatDate(isoTimestamp, hasOnlyLabel)}
      </>
    )
  }

  assertData(options)

  if (type === DateFormatType.noLabel) {
    return (
      <>
        {displayDate(isoTimestamp, options)}
      </>
    )
  }

  return (
    <>
      {displayDateFormatted(isoTimestamp, options, hasOnlyLabel)}
    </>
  )
})

export default DateComponent
