import React from 'react'
import SvgIcon from 'src/components/svgIcon/SvgIcon'

import styles from './socialIconLink.module.scss'

interface ISocialIconLink {
  name: string;
  id: string;
  link: string;
}

const SocialIconLink: React.FC<ISocialIconLink> = ({ name, id, link }) => (
  <a href={link} id={id} className={styles.main} target='_blank' rel='noopener noreferrer'>
    <SvgIcon name={name} size={24}/>
  </a>
)

export default SocialIconLink
