import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import CityModal from 'src/components/modal/cityModal/CityModal'
import { ID } from 'src/constants/id.constants'
import { useToggle } from 'src/utils/hooks.utils'
import CitySelectLabel from './CitySelectLabel'

import styles from '../../search.module.scss'

const SelectCityButton = () => {
  const [isCityModalOpen, toggleCityModal] = useToggle()

  return (
    <>
      <RegularButton
        id={ID.searchBarSelectCity}
        onClick={toggleCityModal}
        buttonType={RegularButtonTypes.transparentNoBorder}
        leftIcon={{ name: 'location-map_pin', size: 24 }}
        className={styles.citySelectorButton}
      >
        <CitySelectLabel />
      </RegularButton>
      {isCityModalOpen && <CityModal onClose={toggleCityModal}/>}
    </>
  )
}

export default SelectCityButton
