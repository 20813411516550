import { IconProps } from 'react-toastify/dist/components'

import SvgIcon from '../svgIcon/SvgIcon'

const getIconByToastType = (type: IconProps['type']) => {
  switch (type) {
    case 'error':
      return 'alert'
    default:
      return 'check-tick'
  }
}

const ToastIcon = ({ type }: IconProps) => (
  <SvgIcon size={20} name={getIconByToastType(type)} />
)

// doesn't work with memo
export default ToastIcon
