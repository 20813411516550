import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_GLOBAL, I18N_REPORT_MODAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { IModalWithClose } from 'src/models/modal.model'

import InfoModal from '../../infoModal/InfoModal'

import styles from './reportSubmittedModal.module.scss'

const ReportSubmittedModal = ({ handleClose }: IModalWithClose) => {
  const { t } = useTranslation()

  const footerButton = (
    <RegularButton
      id={ID.modalCloseButton}
      label={`${I18N_GLOBAL}.gotIt`}
      buttonType={RegularButtonTypes.blackNoBorder}
      onClick={handleClose}
    />
  )

  return (
    <InfoModal
      title={`${I18N_REPORT_MODAL}.success.title`}
      topIcon='check-success'
      topIconSize={48}
      modalFooter={footerButton}
      className={styles.modalContainer}
    >
      <div className='bodySizeL'>
        <Paragraph>
          {t(`${I18N_REPORT_MODAL}.success.p1`)}
        </Paragraph>
        <Paragraph>
          {t(`${I18N_REPORT_MODAL}.success.p2`)}
        </Paragraph>
      </div>
    </InfoModal>
  )
}

export default memo(ReportSubmittedModal)
