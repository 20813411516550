import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { IResendEmailResponse } from 'src/models/auth.model'
import { AVAILABLE_URL_ACTIONS, EmailUrlAction, SocialLoginUrlAction } from 'src/models/url.model'
import { captureErrorAndShowToast } from 'src/utils/error.utils'

export const useGetTokenFromURL = (action: EmailUrlAction) => {
  const [searchParams] = useSearchParams()

  if (searchParams.has(action)) {
    return searchParams.get(action)
  }

  return null
}

export const useGetActionFromURL = (): SocialLoginUrlAction | EmailUrlAction | null => {
  const [searchParams] = useSearchParams()

  const action = AVAILABLE_URL_ACTIONS.find((act) => searchParams.has(act))

  return action || null
}

interface IAllowResend {
  resendEmail: () => Promise<IResendEmailResponse>;
  successToast: string;
  failedToast: string;
}

export const useAllowResendEmail = ({ resendEmail, successToast, failedToast }: IAllowResend) => {
  const [isResendAllowed, setIsResendAllowed] = useState(true)
  const [timeToResend, setTimeToResend] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsResendAllowed(true)
    }, timeToResend)

    return () => clearTimeout(timer)
  }, [timeToResend])

  return useCallback(async () => {
    if (!isResendAllowed) {
      toast.error(failedToast)

      return
    }

    try {
      const { timeToResend: time } = await resendEmail()

      if (time === 0) {
        toast.success(successToast)

        return
      }

      setTimeToResend(time)
      setIsResendAllowed(false)

      toast.error(failedToast)
    } catch (error) {
      captureErrorAndShowToast(error)
    }
  }, [isResendAllowed, resendEmail, successToast, failedToast])
}
