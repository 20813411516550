import { Trans } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import ModalLayout from 'src/components/modal/ModalLayout'
import InfoModal from 'src/components/modal/infoModal/InfoModal'
import { I18N_FORM, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID, PrefixAction, generateIdWithPrefix } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'

import { useNavigate } from 'react-router-dom'
import styles from './offerForm.module.scss'

interface ISuccessOfferPosted {
  category: string;
  slug: string;
  title: string;
  onClose: () => void;
}

const SuccessOfferPosted = ({ category, slug, title, onClose } : ISuccessOfferPosted) => {
  const navigate = useNavigate()

  const handleToOffersClick = () => {
    onClose()
    navigate(YomeURL.offers)
  }

  const footerButtons = (
    <div className={styles.successButtons}>
      <RegularButton
        id={ID.editDraftLinkToActiveOffer}
        label={`${I18N_FORM}.button.toOffers`}
        buttonType={RegularButtonTypes.blackNoBorder}
        onClick={handleToOffersClick}
      />
      <RegularButton
        id={ID.editDraftLinkToMainPage}
        label={`${I18N_GLOBAL}.backToHome`}
        buttonType={RegularButtonTypes.transparentWithBorder}
        linkTo={YomeURL.mainPage}
      />
    </div>
  )

  const IDLinkToOffer = generateIdWithPrefix(ID.editDraftPrefixOffer, PrefixAction.Link, slug)

  return (
    <ModalLayout handleClose={onClose}>
      <InfoModal
        title={`${I18N_FORM}.success.title`}
        topIcon='check-success'
        modalFooter={footerButtons}
        className={styles.successContainer}
      >
        <div className='bodySizeM'>
          <p>
            <Trans i18nKey={`${I18N_FORM}.success.p1`} values={{ offerTitle: title }}>
              0
              <RegularButton
                linkTo={YomeURL.offer(category, slug)}
                id={IDLinkToOffer}
                buttonType={RegularButtonTypes.transparentNoBorderLight}
                className='inline'
              >
                1
              </RegularButton>
              2
            </Trans>
          </p>
          <p>
            <Trans i18nKey={`${I18N_FORM}.success.p2`} >
              0
              <RegularButton
                linkTo={YomeURL.offers}
                id={ID.editDraftLinkToActiveOffer}
                buttonType={RegularButtonTypes.transparentNoBorderLight}
                className='inline'
              >
                1
              </RegularButton>
              2
            </Trans>
          </p>
        </div>
      </InfoModal>
    </ModalLayout>
  )
}

export default SuccessOfferPosted
