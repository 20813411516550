import { captureErrorAndShowToast } from 'src/utils/error.utils'
import YomeAPI from 'src/constants/network.constants'

import { IUploadImageResponse } from '../models/upload.model'
import { sendDelete, sendPostWithFormData } from '../utils/network.utils'

const sendImages = async (images: File[], folder: string): Promise<IUploadImageResponse[]> => {
  const formData = new FormData()

  formData.append('folder', folder)

  images.forEach((image) => {
    formData.append('photos', image)
  })

  const filesObj: IUploadImageResponse[] = await sendPostWithFormData(
    YomeAPI.uploadOfferImages,
    formData,
  )

  return filesObj
}

const deleteImages = async (folder: string, filename: string): Promise<boolean> => {
  try {
    await sendDelete(
      YomeAPI.deleteOfferImage,
      {
        folder,
        filename,
      },
    )

    return true
  } catch (error) {
    captureErrorAndShowToast(error)

    return false
  }
}

const sendAvatar = async (image: File, previousAvatarName?: string): Promise<IUploadImageResponse | null> => {
  const formData = new FormData()

  if (previousAvatarName) {
    formData.append('previous', previousAvatarName)
  }

  formData.append('photos', image)

  return sendPostWithFormData(YomeAPI.uploadAvatar, formData)
}

const deleteAvatar = async (filename?: string) => {
  if (!filename) {
    return null
  }

  return sendDelete(YomeAPI.deleteAvatar, { filename })
}

export {
  sendImages,
  deleteImages,
  sendAvatar,
  deleteAvatar,
}
