import { Navigate, useLocation } from 'react-router-dom'

import { YomeHash } from 'src/constants/navigate.constants'
import { useCurrentUser } from 'src/services/currentUser.service'
import { usePageTitle } from 'src/utils/hooks.utils'

const PrivateRoute = ({ children }: { children : JSX.Element }) => {
  usePageTitle()
  const curPath = useLocation().pathname

  const { data: currentUser, isLoading } = useCurrentUser()

  if (isLoading) {
    return null
  }

  if (!currentUser) {
    return <Navigate to={`/?next=${encodeURIComponent(curPath)}${YomeHash.auth}`}/>
  }

  return children
}

export default PrivateRoute
