import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './toggle.module.scss'

interface IToggleProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
  checked: boolean;
  supportingText?: string;
  size?: 'large';
  className?: string;
  disabled?: boolean;
  labelClassName?: string;
  iconName?: string;
}

const Toggle = ({
  name,
  label,
  checked,
  className,
  onChange,
  size,
  disabled = false,
  labelClassName,
  supportingText,
  iconName,
}: IToggleProps) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.main, styles[size || ''], { [styles.disabled]: disabled }, className)}>
      <label className={styles.switch}>
        <input
          name={name}
          className={styles.switchInput}
          type='checkbox'
          checked={checked}
          onChange={onChange}
          aria-checked={checked}
          disabled={disabled}
        />
        <span className={styles.toggle}></span>
      </label>
      <div className={styles.textBlock}>
        <span className={classNames('bodySizeM', styles.label, labelClassName)}>
          {t(label)}
          {iconName && <SvgIcon name={iconName} size={16} />}
        </span>
        {supportingText && (
          <p className={classNames('supportive-14', styles.supportiveText)}>
            {t(supportingText)}
          </p>
        )}
      </div>
    </div>
  )
}

export default Toggle
