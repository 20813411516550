import React from 'react'

import { useViewport } from 'src/contexts/viewportContext'
import { StringRecords } from 'src/models/common.model'

import FullscreenModalLayout from '../modal/mobileModals/FullscreenModalLayout'
import ModalLayout from '../modal/ModalLayout'

export interface IWithModalLayoutProps {
  handleClose: () => void;
  modalProps: StringRecords;
}

const withModalLayout = (
  WrappedComponent: React.ComponentType<any>,
) => (props: IWithModalLayoutProps) => {
  const { isDesktop } = useViewport()
  const Wrapper = isDesktop ? ModalLayout : FullscreenModalLayout

  return (
    <Wrapper handleClose={props.handleClose}>
      <WrappedComponent {...props}/>
    </Wrapper>
  )
}

export default withModalLayout
