import config from 'src/config'

import { Loader } from '@googlemaps/js-api-loader'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GMAPS_LIBS } from 'src/constants/map.constants'
import { LanguageOptions } from 'src/models/language.model'
import { captureException } from 'src/utils/browser.utils'

let GMAP_LOADER: Loader | undefined

const getLoader = (language: LanguageOptions) => {
  if (GMAP_LOADER) {
    return GMAP_LOADER
  }

  GMAP_LOADER = new Loader({
    apiKey: config.mapApiKey,
    language,
    libraries: GMAPS_LIBS,
    version: 'weekly',
  })

  return GMAP_LOADER
}

const MapProvider = ({ children }: React.PropsWithChildren) => {
  const { i18n: { language } } = useTranslation()
  const [isMapLoaded, setIsMapLoaded] = useState(false)

  useEffect(() => {
    if (!isMapLoaded) {
      getLoader(language as LanguageOptions).importLibrary('maps').then(() => {
        setIsMapLoaded(true)
      }).catch((error): void => {
        setIsMapLoaded(false)
        captureException(error)
      })
    }
  }, [language, isMapLoaded])

  if (!isMapLoaded) {
    return null
  }

  return (
    <>
      {children}
    </>
  )
}

export default MapProvider
