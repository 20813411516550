import classNames from 'classnames'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import { ID } from 'src/constants/id.constants'
import { DESCRIPTION_FIELD_NAME, SinglePost } from 'src/models/post.model'
import { useToggle } from 'src/utils/hooks.utils'
import { ICategoryStructure, IFieldConfig } from 'src/yome-categories-fields-config/models/fields.model'

import PostSectionFields from './PostSectionFields'

import styles from './postField.module.scss'

interface IPostFieldSection {
  section: ICategoryStructure;
  post: SinglePost;
}

const fieldsWithShowBtn = ['characteristics']

const checkSectionEmpty = (
  offer: SinglePost,
  fields: Record<string, IFieldConfig>,
) => !Object.keys(fields).some((field) => !!offer[field as keyof SinglePost])

const PostSection: React.FC<IPostFieldSection> = ({
  post,
  section: { section: sectionName, fields, fieldsFlatten },
}) => {
  const { t } = useTranslation()
  const [showAll, toggleShowAll] = useToggle()
  const buttonLabel = showAll ? t('Hide') : t('')
  const sectionFields = fieldsFlatten || fields

  if (checkSectionEmpty(post, sectionFields)) {
    return null
  }

  return (
    <section className={styles.sectionMain}>
      {sectionName !== DESCRIPTION_FIELD_NAME && (
        <Heading
          level='h3'
          label={`post.section.${sectionName}`}
          className={classNames(styles.sectionHeading, 'desktop-h6 mobile-h6')}
        />
      )}

      <PostSectionFields fields={sectionFields} post={post} />

      {fieldsWithShowBtn.includes(sectionName) && Object.keys(sectionFields).length > 5 && (
        <div className={classNames(styles.showAllBtn, showAll ? styles.iconRotate : '')}>
          <RegularButton
            id={ID.postPageToggleDetails}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
            onClick={toggleShowAll}
            label={`post.details.showMoreBtn${buttonLabel}`}
            rightIcon={{ name: 'chevron-down' }}
          />
        </div>
      )}
    </section>
  )
}

export default memo(PostSection)
