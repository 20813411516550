import classNames from 'classnames'
import { FC, useCallback } from 'react'
import Loader from 'react-spinners/ClipLoader'

import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import { I18N_MY_OFFER_LIST } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import YomeAPI from 'src/constants/network.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useGetValueFromUrlParam } from 'src/hooks/query.hooks'
import { useSorting } from 'src/hooks/sort.hooks'
import { SORT_QPARAM } from 'src/models/search.model'
import { SortPostListTypes } from 'src/models/sort.model'
import { assertData } from 'src/utils/error.utils'

import { ILimitedSingleOfferList, IMyOffersCounts, IOfferList, PostListType, SinglePost } from '../../models/post.model'
import { useGetPostList } from '../../services/post.service'
import RegularButton, { RegularButtonTypes } from '../button/RegularButton'
import NoPosts, { NoPostsType } from '../noPosts/NoPosts'
import SortDropdown from '../SortDropdown/SortDropdown'
import SWRErrorBoundary from '../swr/SWRErrorBoundary'
import MyOfferCard from './MyOfferCard'

import styles from './styles.module.scss'

interface MyOfferListPropsBoundary {
  listType: PostListType;
}

interface MyOfferListProps extends MyOfferListPropsBoundary{
  sortBy: SortPostListTypes;
}

const MyOfferIdMap = {
  [PostListType.active]: ID.myItemsPrefixActiveOffer,
  [PostListType.inactive]: ID.myItemsPrefixInactiveOffer,
  [PostListType.archive]: ID.myItemsPrefixArchiveOffer,
  [PostListType.draft]: ID.myItemsPrefixDraftOffer,
}

const MyOfferList: FC<MyOfferListProps> = ({
  listType,
  sortBy,
}) => {
  const { isDesktop } = useViewport()
  const offerListEndpoint = YomeAPI.profileOfferList(sortBy, listType)

  const {
    data: profileOffers,
    mutate,
    isLoading,
  } = useGetPostList<ILimitedSingleOfferList>(offerListEndpoint)

  const {
    mutate: offerCountMutator,
  } = useGetPostList<IOfferList<IMyOffersCounts>>(YomeAPI.offerProfileCounters)

  const { sortOfferList } = useSorting()

  const myOfferMutator = useCallback(() => {
    mutate()
    offerCountMutator()
  }, [mutate, offerCountMutator])

  if (isLoading) {
    return <Loader />
  }

  assertData(profileOffers)

  const { items } = profileOffers

  const idPrefix = MyOfferIdMap[listType]

  const newOfferButtonCommonProps = {
    linkTo: YomeURL.newOffer,
    id: ID.myItemsButtonNewOffer,
    label: `${I18N_MY_OFFER_LIST}.newItemBtn`,
  }

  const noOffersButton = {
    buttonType: RegularButtonTypes.blackNoBorder,
    ...newOfferButtonCommonProps,
  }

  const hasOneItem = items.length === 1

  return (
    <div className={classNames(
      styles.myOfferListContainer,
      { [styles.oneItemContainer]: hasOneItem },
    )}
    >
      <SortDropdown
        idSortButton={ID.favoritesPageSortPost}
        defaultValue={sortBy}
        onChange={sortOfferList}
        sortOptions={Object.values(SortPostListTypes)}
      />
      <div className={styles.wrapper}>
        {(!items || items.length < 1) ? (
          <div className={styles.noPosts}>
            <NoPosts
              type={NoPostsType.myOffers}
              button = {isDesktop
                ? noOffersButton
                : undefined
              }
            />
          </div>
        ) : (items.map((item: SinglePost) => (
          <MyOfferCard
            idPrefix={idPrefix}
            listType={listType}
            offer={item}
            mutator={myOfferMutator}
            key={item.slug}
          />
        ))
        )}
        {(!isDesktop && !!items.length) && <ShowcaseArea type='mobile-small' />}
        {!isDesktop && (
          <div className='sticky-button-wrapper'>
            <RegularButton
              buttonType={RegularButtonTypes.main}
              className={styles.newItemBtn}
              leftIcon={{ name: 'plus' }}
              {...newOfferButtonCommonProps}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const MyOffersListBoundary = ({ listType }: MyOfferListPropsBoundary) => {
  const sortBy = useGetValueFromUrlParam(SORT_QPARAM, SortPostListTypes.newest)

  return (
    <SWRErrorBoundary swrKey={YomeAPI.profileOfferList(sortBy, listType)}>
      <MyOfferList
        listType={listType}
        sortBy={sortBy}
      />
    </SWRErrorBoundary>

  )
}

export default MyOffersListBoundary
