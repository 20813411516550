import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import SvgIcon from 'src/components/svgIcon/SvgIcon'

import styles from './setting.module.scss'

interface ISettingLineCurrentInfo {
  onClick?: () => void;
  title: string;
  currentValue: string;
  valueIcon?: string;
}

const SettingLineCurrentInfo = ({ onClick, title, currentValue, valueIcon }: ISettingLineCurrentInfo) => {
  const { t } = useTranslation()

  return (
    <div onClick={onClick} className={styles.settingLineCurrentInfo}>
      <div className={'bodySizeM desktop-body-18'}>
        {t(title)}
      </div>
      <div className={classNames('label-16 desktop-label-18', styles.value, { [styles.hasIcon]: valueIcon }) }>
        {valueIcon && <SvgIcon name={valueIcon} size={16}/>}
        {currentValue}
      </div>
    </div>
  )
}

export default memo(SettingLineCurrentInfo)
