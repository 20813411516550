import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { I18N_FOOTER_SECTION } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'

import { ReactComponent as Logo } from '../../assets/logo-black.svg'
import Paragraph from '../paragraph/Paragraph'
import FooterSocial from './FooterSocial'

import styles from './footer.module.scss'

const FooterLogoAndSocial: React.FC = () => (
  <div className={styles.section}>
    <Link to={YomeURL.mainPage} className={styles.logo}>
      <Logo />
    </Link>
    <FooterSocial/>
    <Paragraph className={classNames('supportive-12', styles.copyright)} text={`${I18N_FOOTER_SECTION}.date`} />
  </div>
)

export default FooterLogoAndSocial
