export const NO_PHOTOS = '/images/noPhotos.png'

export const ICON_PATH = '/icons'

export const ICON_16_PATH = '/icons/icons-16'

export const ICON_20_PATH = 'icons-20'

export const ICON_24_PATH = '/icons/icons-24'

export const ICON_32_PATH = '/icons/icons-32'
