import { memo, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'

interface IParagraphProps {
  text?: string;
  className?: string;
}

const Paragraph = ({ text, className, children }: PropsWithChildren<IParagraphProps>) => (
  <p className={className}>
    <Trans>
      {children || text}
    </Trans>
  </p>
)

export default memo(Paragraph)
