import { useTranslation } from 'react-i18next'

import DropdownSectionList from 'src/components/dropdown/DropdownSectionList'
import { I18N_PROFILE_SETTING_GENERAL } from 'src/constants/i18n.constants'
import { ID, Subject } from 'src/constants/id.constants'
import { LANGUAGE_OPTIONS } from 'src/models/language.model'
import SettingLine from 'src/routes/profile/components/settings/SettingLine/SettingLine'
import { getInitialLanguage } from 'src/utils/language.utils'

import styles from './languageSettings.module.scss'

const LanguageSettings = () => {
  const { t } = useTranslation()
  const initialLanguage = getInitialLanguage()

  return (
    <SettingLine
      settingId={Subject.Language}
      title={`${I18N_PROFILE_SETTING_GENERAL}.language`}
      currentValue={t(`${initialLanguage}`)}
      btnLabel='change'
      valueIcon={initialLanguage}
      isSubmitButtonHidden
    >
      <DropdownSectionList
        id={ID.profileSettingEditLanguage}
        sections={{ language: LANGUAGE_OPTIONS }}
        selectedAction={initialLanguage}
        className={styles.dropdown}
      />
    </SettingLine>
  )
}

export default LanguageSettings
