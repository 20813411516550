import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { Form, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { FormPhoneField } from 'src/components/form'
import Paragraph from 'src/components/paragraph/Paragraph'
import { emptyFunction } from 'src/constants/function.constants'
import { I18N_VERIFY_PHONE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { IPhoneNumber, IVerifyPhoneModalProps, PhoneCodeOptions, VerifyPhoneModals } from 'src/models/verifyPhone.model'
import { sendCall, sendSms } from 'src/services/verifyPhone.service'
import { SubmitFormError, TooManyRequestsError } from 'src/utils/error.utils'

import InfoModal from '../infoModal/InfoModal'

import styles from './verifyPhoneModals.module.scss'

const VerifyPhoneForm = ({ setCurrentModal, setCodeType, setPhoneNumber }: IVerifyPhoneModalProps) => {
  const { values } = useFormState<IPhoneNumber>()

  const [fieldError, setFieldError] = useState<string | undefined>()

  const { t } = useTranslation()

  const onSendCode = useCallback((
    sendCode: (phone: IPhoneNumber) => Promise<void>,
    codeType: PhoneCodeOptions,
  ) => async () => {
    try {
      setPhoneNumber(values)
      await sendCode(values)
      setCurrentModal(VerifyPhoneModals.enterCode)
      setCodeType(codeType)
    } catch (error) {
      if (error instanceof TooManyRequestsError) {
        setCurrentModal(VerifyPhoneModals.limitReached)
      } else if (error instanceof SubmitFormError && error.errorObject.phoneBody) {
        setFieldError(error.errorObject.phoneBody)
      } else if (error instanceof Error) {
        toast.error(t(error.message))
      }
    }
  }, [setCurrentModal, setPhoneNumber, values, setCodeType, t])

  const modalFooter = (
    <div className={styles.buttonRow}>
      <RegularButton
        id={ID.verifyPhoneModalSmsButton}
        buttonType={RegularButtonTypes.main}
        label={`${I18N_VERIFY_PHONE}.sendSms.label`}
        onClick={onSendCode(sendSms, PhoneCodeOptions.sms)}
        type='submit'
      />
      <RegularButton
        id={ID.verifyPhoneModalCallButton}
        buttonType={RegularButtonTypes.transparentWithBorder}
        label={`${I18N_VERIFY_PHONE}.recieveCall.label`}
        onClick={onSendCode(sendCall, PhoneCodeOptions.call)}
      />
    </div>
  )

  return (
    <InfoModal className={styles.verifyPhoneContainer} modalFooter={modalFooter}>
      <EmptyStateMessage
        icon={'phone-verification'}
        iconSize={120}
        title={`${I18N_VERIFY_PHONE}.base.title`}
      />
      <Paragraph text={`${I18N_VERIFY_PHONE}.base.text`} className={classNames('bodySizeL', styles.mainText)} />
      <FormPhoneField
        name='phone'
        fieldWrapperProps={{ name: 'phone', label: `${I18N_VERIFY_PHONE}.base.enterPhone`, customError: fieldError }}
      />
    </InfoModal>
  )
}

const VerifyPhoneModal = (props: IVerifyPhoneModalProps) => (
  <Form<IPhoneNumber>
    initialValues={props.phoneNumber}
    onSubmit={emptyFunction}
    render={function RenderVerifyPhoneForm({ handleSubmit }) {
      return (
        <form onSubmit={handleSubmit} className={styles.verifyPhoneForm}>
          <VerifyPhoneForm {...props}/>
        </form>
      )
    }}
  />
)

export default VerifyPhoneModal
