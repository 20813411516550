import React, { ReactNode, useContext, useState } from 'react'

import { IUser } from 'src/models/user.model'

interface ILoginUserContext {
  loginUser: IUser | null;
  setLoginUser: (user: IUser | null) => void;
}

const LoginUserContext = React.createContext<ILoginUserContext>({
  loginUser: null,
  setLoginUser: () => {},
})

const LoginUserProvider = ({ children }: { children: ReactNode }) => {
  const [loginUser, setLoginUser] = useState<IUser | null>(null)

  return (
    <LoginUserContext.Provider value={{ loginUser, setLoginUser }} >
      {children}
    </LoginUserContext.Provider>
  )
}

export const useLoginUserContext = () => useContext(LoginUserContext)

export default LoginUserProvider
