import classNames from 'classnames'
import { memo, PropsWithChildren } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { ICON_16_PATH } from '../../constants/images.constants'
import { getFieldError } from '../../utils/form.utils'
import { ITooltipData } from '../../yome-categories-fields-config/models/fields.model'
import CustomTooltip from '../tooltip/CustomTooltip'
import FormError from './FormError'

import styles from './form.module.scss'

export interface IFieldWrapperProps {
  name: string;
  info?: string;
  label?: string;
  className?: string;
  infoRight?: string;
  customError?: string;
  tooltip?: ITooltipData;
  disabled?: boolean;
  errorClassName?: string;
}

const FieldWrapper = ({
  name,
  info,
  label,
  tooltip,
  className,
  infoRight,
  customError,
  children,
  disabled = false,
  errorClassName,
}: PropsWithChildren<IFieldWrapperProps>) => {
  const { t } = useTranslation()
  const { meta } = useField(name)
  const error = customError || getFieldError(meta)

  return (
    <div className={classNames(styles.field, !!error && styles.error, className)}>
      {label && (
        <label htmlFor={name}
          className={classNames(
            'label-14',
            styles.fieldLabel,
            { [styles.disabled]: disabled },
          )}
        >
          { t(label) }
        </label>
      )}
      {children}
      {(info || infoRight || error) && (
        <div className={classNames('caption-14', styles.fieldInfo)}>
          {error ? <FormError error={error} className={errorClassName}/> : (
            <span>
              {info && (
                <>
                  {t(info)}
                  &nbsp;
                  {tooltip && (
                    <CustomTooltip tooltipData={tooltip}>
                      <img src={`${ICON_16_PATH}/hint.svg`} alt="" />
                    </CustomTooltip>
                  )}
                </>
              )}
            </span>
          )}
          {infoRight && (
            <span className={classNames(styles.infoRight, { [styles.fieldError]: !!error })}>
              { t(infoRight) }
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(FieldWrapper)
