import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { StringRecords } from 'src/models/common.model'

import SvgIcon from '../../svgIcon/SvgIcon'

import styles from './checkbox.module.scss'

interface IFormCheckboxGroupProps {
  name: string;
  options: StringRecords;
  icon?: boolean;
}

const FormCheckboxGroup = React.memo(({
  name,
  options,
  icon,
}: IFormCheckboxGroupProps) => {
  const { t } = useTranslation()

  return (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = [...(value || [])]

          if (event.target.checked) {
            newValue.push(event.target.value)
          } else {
            newValue.splice(newValue.indexOf(event.target.value), 1)
          }

          return onChange(newValue)
        }

        return (
          <>
            {Object.entries(options).map(([key, option]) => (
              <label key={key} className={styles.label} htmlFor={`${name}-${key}`}>
                <input
                  {...input}
                  id={`${name}-${key}`}
                  type="checkbox"
                  className={styles.checkbox}
                  value={key}
                  checked={value.includes(key)}
                  onChange={handleChange}
                />
                <span className={styles.visibleInput}>
                  {icon && <SvgIcon name={`${name}/${key}`} />}
                  {t(option)}
                </span>
              </label>
            ))}
          </>
        )
      }}
    </Field>
  )
})

export default FormCheckboxGroup
