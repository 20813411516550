import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'
import { I18N_ADVERTISING } from 'src/constants/i18n.constants'

import styles from './advertising.module.scss'

interface ISectionHeading {
  label: string;
}

const SectionHeading = ({ label }: ISectionHeading) => (
  <Heading
    level='h3'
    label={`${I18N_ADVERTISING}.${label}`}
    className={classNames(styles.sectionTitle, 'desktop-h2 mobile-h3')}
  />
)

export default SectionHeading
