import config from 'src/config'

import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const LOCAL_ENV = 'local'

export const initSentry = () => {
  const { env, sentryDsn } = config

  if (env === LOCAL_ENV) {
    return
  }

  Sentry.init({
    environment: env,
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/dev\.yome\.site/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export const captureException = (error: Error) => {
  if (config.env === LOCAL_ENV) {
    // eslint-disable-next-line no-console
    console.error(error)
  } else {
    Sentry.captureException(error)
  }
}

export const scrollWindowTop = () => window.scrollTo(0, 0)

export const setDocumentTitle = (title: string) => {
  document.title = title
}
