import { NO_PHOTOS } from 'src/constants/images.constants'
import styles from './noPhoto.module.scss'

const NoPhoto: React.FC = () => (
  <div className={styles.main}>
    <img src={NO_PHOTOS} />
  </div>
)

export default NoPhoto
