import classNames from 'classnames'
import React from 'react'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './roundedControls.module.scss'

interface IRoundedControlsProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  id: string;
  iconName: string;
  iconSize?: number;
  isActive?: boolean;
  isTransparent?: boolean;
}

const RoundedControls = (
  { onClick, id, iconName, iconSize = 22, isActive = false }: IRoundedControlsProps,
  ref?: React.ForwardedRef<HTMLButtonElement>,
) => (
  <button
    id={id}
    onClick={onClick}
    ref={ref}
    className={classNames(
      styles.main,
      { [styles.active]: isActive },
    )}
  >
    <SvgIcon
      name={iconName}
      size={iconSize}
      className={classNames(styles[iconName], { [styles.active]: isActive })}
    />
  </button>
)

export default React.memo(React.forwardRef(RoundedControls))
