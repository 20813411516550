import { ICategoryStructure, MainCategories } from '../models/fields.model'
import fieldsConfig from './field-config'

const {
  categoryPath,
  title,
  description,
  priceWithRate,
  photos,
  experience,
  place,
  urgent,
  location,
  // phone,
  // communication,
  condition,
  priceWithNegotiable,
  color,
  isAutoRenewal,
} = fieldsConfig

const fieldsStructure: Record<MainCategories, ICategoryStructure[]> = {
  services: [
    {
      section: 'generalInfo',
      fields: { title, categoryPath },
    },
    {
      section: 'details',
      fields: { description, priceWithRate, experience, place, urgent, photos },
    },
    {
      section: 'location',
      info: 'section.location.info',
      fields: { location },
    },
    // {
    //   section: 'contacts',
    //   fields: { phone, communication },
    // },
    {
      section: 'options',
      fields: { isAutoRenewal },
      hasDivider: true,
      isTitleHiddenOnDesktop: true,
    },
  ],
  others: [
    {
      section: 'generalInfo',
      fields: { title, categoryPath, condition },
    },
    {
      section: 'details',
      fields: { description, priceWithNegotiable, photos },
    },
    {
      section: 'characteristics',
      info: 'section.characteristics.info',
      fields: { color },
    },
    {
      section: 'location',
      info: 'section.location.info',
      fields: { location },
    },
    // {
    //   section: 'contacts',
    //   fields: { phone, communication },
    // },
    {
      section: 'options',
      fields: { isAutoRenewal },
      hasDivider: true,
      isTitleHiddenOnDesktop: true,
    },
  ],
}

export default fieldsStructure
