import { IFieldConfig, MainCategories } from '../models/fields.model'
import fieldsConfig from './field-config'

const {
  category,
  priceWithRate,
  priceWithNegotiable,
  place,
  condition,
  color,
  descriptionSearch,
  // rating,
} = fieldsConfig

export const SORT_FILTER_NAME = 'sortBy'
export const SEARCH_CONTENT_FILTER_NAME = 'searchContent'
export const ORIGINAL_FILTER_NAME = 'isOriginalContent'
export const PRICE_FILTER_NAMES = ['priceMin', 'priceMax']
export const EXTRA_FILTER_NAMES = [
  ...PRICE_FILTER_NAMES,
  SORT_FILTER_NAME,
  SEARCH_CONTENT_FILTER_NAME,
  ORIGINAL_FILTER_NAME,
]

export const searchFiltersStructure: Record<MainCategories, IFieldConfig[]> = {
  services: [category, place, priceWithRate, descriptionSearch],
  others: [category, priceWithNegotiable, condition, color, descriptionSearch],
}

export const searchFilterNames: Record<MainCategories, string[]> = {
  services: [
    category,
    place,
    priceWithRate.fields!.rate,
    descriptionSearch,
  ].map((field) => field.name).concat(EXTRA_FILTER_NAMES),
  others: [
    category,
    priceWithNegotiable.fields!.negotiable,
    condition,
    color,
    descriptionSearch,
  ].map((field) => field.name).concat(EXTRA_FILTER_NAMES),
}
