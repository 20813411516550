import classNames from 'classnames'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ADVERTISING } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { ADVERTISING_FORMATS } from 'src/constants/staticPages.constants'
import { useViewport } from 'src/contexts/viewportContext'

import SectionHeading from '../../SectionHeading'
import SingleFormat from './SingleFormat'

import styles from './formats.module.scss'

const Formats = () => {
  const { isDesktop } = useViewport()

  return (
    <div className={styles.main}>
      <SectionHeading
        label='formats.heading'
      />
      <Paragraph
        className={classNames(styles.subtitle, 'body-text desktop-body-28 bodySizeL')}
        text={`${I18N_ADVERTISING}.formats.subheading`}
      />
      <ul className={styles.list}>
        {ADVERTISING_FORMATS.map((format, index) => {
          const isSecondElementOnDesktop = index === 1 && isDesktop

          return (
            <SingleFormat
              key={format}
              format={format}
              isSecondElementOnDesktop={isSecondElementOnDesktop}
            />
          )
        })}
      </ul>
      <RegularButton
        size={RegularButtonSizes.large}
        label={`${I18N_ADVERTISING}.formats.btn`}
        id={ID.advertisingButtonFormat}
        buttonType={RegularButtonTypes.main}
        linkTo={YomeURL.help}
        className={classNames(styles.btn, 'static-btn')}
      />
    </div>
  )
}

export default Formats
