import classNames from 'classnames'
import { memo } from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_LEGAL_SAFETY } from 'src/constants/i18n.constants'
import { usePageTitle } from 'src/utils/hooks.utils'
import { getStaticPageMetaTitle } from 'src/utils/meta.utils'
import SafetySupportBlock from './SafetySupportBlock'
import SafetyFeatures from './components/safetyFeatures/SafetyFeatures'

import styles from './safety.module.scss'

const SafetyRoute = () => {
  const { pathname } = useLocation()
  usePageTitle(getStaticPageMetaTitle(pathname))

  return (
    <div className={classNames('static-page-wrapper', styles.main)}>
      <Heading level='h1' className="mobile-h1 desktop-h1">
        <Trans
          i18nKey={`${I18N_LEGAL_SAFETY}.heading`}
          components={{ 1: <span className='color-primary' /> }}
        >
        </Trans>
      </Heading>
      <Paragraph
        text={`${I18N_LEGAL_SAFETY}.subheading`}
        className={styles.subheading}
      />
      <SafetyFeatures />
      <SafetySupportBlock />
    </div>
  )
}

export default memo(SafetyRoute)
