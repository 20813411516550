import { createSearchParams } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'
import { LatLng } from 'use-places-autocomplete'

import YomeAPI from 'src/constants/network.constants'
import { TranslatedOptions } from 'src/models/language.model'
import { ILatLng, IOfferLocation } from 'src/models/location.model'
import {
  BASE_FREE_OFFERS_SEARCH_PARAMS,
  CATEGORY_QPARAM,
  CITY_QPARAM,
  IFilterValues,
  ISaveSeacrhResponse,
  ISortedCityPosts,
  ORIGINAL_QPARAM,
  SORT_QPARAM,
} from 'src/models/search.model'
import { DEFAULT_SORT_TYPE, SortPostListTypes } from 'src/models/sort.model'
import { getLatLngString, getShortenPosition } from 'src/utils/map.utils'
import { sendDelete, sendFormGet, sendGet, sendPost } from 'src/utils/network.utils'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'

const getSearchPostList = async (key: string): Promise<ISortedCityPosts> => sendFormGet(key)

// eslint-disable-next-line import/prefer-default-export
export const useGetSearchPostList = (key: string) => useSWRImmutable<ISortedCityPosts>(
  key,
  getSearchPostList,
  {
    revalidateOnMount: true,
    keepPreviousData: true,
  },
)

export const handleDeleteSearch = async (savedSearchId: string) => sendDelete(YomeAPI.search, { savedSearchId })

const getSimilarPostList = async (key: string): Promise<ISortedCityPosts> => sendGet(key)

export const useGetSimilarPostList = (key: string) => useSWRImmutable<ISortedCityPosts>(
  key,
  getSimilarPostList,
  {
    revalidateOnMount: true,
    keepPreviousData: true,
  },
)

export const getCurrentSearchPostParams = (
  searchParams: URLSearchParams,
  cityPosition: LatLng,
): IFilterValues => ({
  category: searchParams.get(CATEGORY_QPARAM) || ANY_CATEGORY,
  ...Object.fromEntries(searchParams),
  city: getLatLngString(cityPosition),
  [SORT_QPARAM]: searchParams.get(SORT_QPARAM) || DEFAULT_SORT_TYPE,
  [ORIGINAL_QPARAM]: searchParams.get(ORIGINAL_QPARAM) || TranslatedOptions.translated,
})

export const handleSaveSearch = async (
  searchParams: IFilterValues,
  savedSearchId: string | undefined,
): Promise<ISaveSeacrhResponse> => {
  const actionMethod = savedSearchId ? sendDelete : sendPost
  const actionBody = savedSearchId ? { savedSearchId } : searchParams

  return actionMethod(YomeAPI.search, actionBody)
}

export const getSearchFreeOffersParams = (location?: ILatLng, limit?: string) => {
  if (!location || !limit) {
    return createSearchParams(BASE_FREE_OFFERS_SEARCH_PARAMS)
  }

  const positionString = getLatLngString(location)
  const shortenPosition = getShortenPosition(positionString)

  return createSearchParams({
    ...BASE_FREE_OFFERS_SEARCH_PARAMS,
    [CITY_QPARAM]: shortenPosition,
    limit,
  })
}

export const getSimilarOffersParams = (category: string, location: IOfferLocation) => {
  const positionString = getLatLngString(location.position)
  const shortenPosition = getShortenPosition(positionString)

  return createSearchParams({
    category,
    city: shortenPosition,
    sortBy: SortPostListTypes.newest,
  })
}
