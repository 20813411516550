import useSWR from 'swr'

import SWRkeys from 'src/constants/swr.constants'
import { I18N_HEADER_TABBAR } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'

import { SvgColorAttr } from '../svgIcon/SvgIcon'
import TabBarButton from './tabBarButton/TabBarButton'

import styles from './header.module.scss'

const tabBarData = [
  {
    id: ID.headerLinkToMainPage,
    linkTo: YomeURL.mainPage,
    icon: 'logo',
    label: `${I18N_HEADER_TABBAR}.home`,
    currentColor: 'fill' as SvgColorAttr,
  },
  {
    id: ID.headerLinkToFavorite,
    linkTo: YomeURL.favorites,
    icon: 'heart',
    label: `${I18N_HEADER_TABBAR}.favorites`,
  },
  {
    id: ID.headerLinkToNewOffer,
    linkTo: YomeURL.offers,
    icon: 'grid-lines',
    label: `${I18N_HEADER_TABBAR}.offers`,
  },
  {
    id: ID.headerLinkToMessenger,
    linkTo: YomeURL.messages,
    icon: 'message',
    label: `${I18N_HEADER_TABBAR}.messages`,
  },
  {
    id: ID.headerLinkToProfile,
    linkTo: YomeURL.profilePage,
    icon: 'user',
    label: `${I18N_HEADER_TABBAR}.profile`,
  },
]

const MobileTabBar = () => {
  const { data: hasMessangerUnreadChats } = useSWR<boolean>(SWRkeys.hasMessangerUnreadChats)

  return (
    <ul className={styles.mobileTabBar}>
      {tabBarData.map((item) => (
        <TabBarButton
          {...item}
          key={item.label}
          hasNotifications={item.id === ID.headerLinkToMessenger ? hasMessangerUnreadChats : undefined}
        />
      ))}
    </ul>
  )
}

export default MobileTabBar
