import fieldsConfig from '../fields-config/field-config'
import fieldsStructure from '../fields-config/formPostFields'
import reviewFieldsConfig from '../fields-config/reviewFieldConfig'
import { FieldType, ICategoryStructure, IFieldConfig, MainCategories } from '../models/fields.model'

export interface IFieldConfigWithOptions extends Omit<IFieldConfig, 'options' | 'default'> {
  options: string[];
  defaultValue: string;
}

export const LOCATION_ADDRESS_FIELD_NAME = 'locationAddress'
export const LOCATION_POSITION_FIELD_NAME = 'locationPosition'
export const LOCATION_FIELDS = [LOCATION_ADDRESS_FIELD_NAME, LOCATION_POSITION_FIELD_NAME]

const findSubfieldConfig = (targetFieldName: string) => {
  const correctedTargetFieldName = targetFieldName.replace(/(Min|Max)$/, '')

  // loop for every field that present in config file
  const allConfigs = Object.values(fieldsConfig)

  for (let i = 0; i < allConfigs.length; i += 1) {
    const fieldConf = allConfigs[i]

    if (fieldConf.fields) {
      // check if target field is in current field subfields
      const subfieldConfig = Object.values(fieldConf.fields).find(
        (subfieldConf) => subfieldConf.name === correctedTargetFieldName,
      )

      if (subfieldConfig) {
        return subfieldConfig
      }
    }
  }

  return null
}

export const getFieldConfig = (field: string) => {
  let config: IFieldConfig | undefined | null = fieldsConfig[field]

  // check if target field is in subfields
  if (!config) {
    config = findSubfieldConfig(field)
  }

  return config
}

export const getMainCategory = (rootCategory: string) => {
  if (rootCategory === 'SERVICES') {
    return MainCategories.services
  }

  return MainCategories.others
}

export const getStructure = (mainCategory: MainCategories, partIndex?: number) => {
  const structure = fieldsStructure[mainCategory]

  if (partIndex !== undefined) {
    const partStructure = structure[partIndex]

    if (!partStructure) {
      throw new Error(`Unable to get structure for given partIndex=${partIndex}`)
    }

    return [partStructure]
  }

  return structure
}

export const getFieldsForCategory = (category: string, partIndex?: number) => {
  const mainCategory = getMainCategory(category)
  const structure = getStructure(mainCategory, partIndex)

  return structure.map((section: ICategoryStructure) => {
    const sectionFieldNames = Object.keys(section.fields)

    return sectionFieldNames.map((fieldName) => {
      const field = getFieldConfig(fieldName)

      if (!field) {
        return fieldName
      }

      if (field.type === FieldType.location) {
        return LOCATION_FIELDS
      }

      const children = (field.type === FieldType.complex) && field.fields

      return children ? Object.keys(children) : field.name
    }).flat()
  }).flat()
}

export const getFieldOptionAndDefault = (field: string, subfield?: string): IFieldConfigWithOptions => {
  const config = getFieldConfig(field)

  if (!config) {
    throw new Error(`No field ${field} in field-config.json`)
  }

  if ((!config.options || !config.defaultValue) && !subfield) {
    throw new Error(`No options/default for field ${field} in field-config.json`)
  }

  if (subfield && config.fields) {
    if (!config.fields[subfield]?.options || !config.fields[subfield]?.defaultValue) {
      throw new Error(`No options/default for field ${field} in field-config.json`)
    }

    return config.fields[subfield] as IFieldConfigWithOptions
  }

  return config as IFieldConfigWithOptions
}

export const getReviewFieldConfig = (field: string) => {
  const config: IFieldConfig | undefined | null = reviewFieldsConfig[field]

  return config
}
