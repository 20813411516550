import YomeURL from './navigate.constants'

export const MAIN_FEATURES = [
  'data',
  'contacts',
  'multiLang',
  'interface',
  'ratings',
  'communication',
  'crossPlatform',
]
export const MAIN_FEATURES_DESKTOP = [
  'data',
  'multiLang',
  'ratings',
  'crossPlatform',
  'contacts',
  'interface',
  'communication',
]
export const BENEFITS = [
  'variousFeatures',
  'automaticallyImport',
  'advancedStatistics',
  'paidPromotion',
  'flexibleSettings',
]
export const HISTORY_POINTS = ['firstDiff', 'moreChallenges', 'evenMore', 'opportunities', 'finally']
export const HERE_YOU_CAN = [
  'specialists',
  'buyInexpensive',
  'rent',
  'buyVehicle',
  'vacancies',
  'buyBusiness',
  'findEquipment',
  'postInfo',
]

export const SAFETY_ITEMS = [
  'transactions',
  'profile',
  'phone',
  'communication',
  'deliveries',
  'rating',
  'hacking',
  'checks',
]

export const SAFETY_STICKER_SIZE = 200

export const FOR_BUSINESS_STATS = [
  {
    num: '+22%',
    caption: 'increase',
  },
  {
    num: '6,738',
    caption: 'dailySearches',
  },
  {
    num: '83%',
    caption: 'satisfaction',
  },
]

export const FOR_BUSINESS_CATEGORIES = ['REALTY', 'AUTO', 'GOODS', 'JOBS', 'SERVICES', 'BUSINESS']

export const FOR_BUSINESS_FEATURES = ['sell', 'save', 'posting', 'insights', 'plan', 'audience']

export const FOR_BUSINESS_PLANS = [
  {
    name: 'basic',
    includes: ['stat', 'ads', 'multiAd'],
  },
  {
    name: 'opt',
    includes: ['all', 'ads'],
    benefits: ['onHomePage', 'newAd', 'companyPage', 'newPartners', 'boost', 'support'],
    benefitsDesktop: ['onHomePage', 'newPartners', 'newAd', 'boost', 'companyPage', 'support'],
  },
  {
    name: 'max',
    includes: ['all', 'ads'],
    benefits: [
      'newsletters',
      'multiAd',
      'autofill',
      'bestsellers',
      'publications',
      'support',
      'design',
      'uniqueAddress',
    ],
    benefitsDesktop: [
      'newsletters',
      'publications',
      'multiAd',
      'support',
      'autofill',
      'design',
      'bestsellers',
      'uniqueAddress',
    ],
  },
]

export const FOR_BUSINESS_STEPS = [
  {
    label: 'create',
  },
  {
    label: 'choose',
    btnLabel: 'view',
    btnLink: YomeURL.help,
  },
  {
    label: 'list',
  },
  {
    label: 'start',
    btnLabel: 'getStarted',
    btnLink: YomeURL.help,
  },
]

export const FOR_BUSINESS_IMAGES_PATH = '/images/for-business'

export const ADVERTISING_STATS = [
  {
    num: '6,738',
    caption: 'dailySearches',
  },
  {
    num: '+22%',
    caption: 'monthlyIncrease',
  },
  {
    num: '43%',
    caption: 'converting',
  },
]

export const ADVERTISING_FEATURES = ['audience', 'leads', 'brand', 'adFormats', 'results']

export const ADVERTISING_FORMATS = ['capture', 'target', 'drive']

export const ADVERTISING_TOOLKIT = ['intent', 'location', 'interests', 'details', 'categories', 'keywords']

export const ADVERTISING_IMAGES_PATH = 'images/advertising'
