import { Field } from 'react-final-form'

import { useInputBlurOnValueUpdate } from 'src/hooks/form.hooks'

import Toggle from '../toggle/Toggle'

import styles from './form.module.scss'

interface IFormToggleProps {
  name: string;
  label: string;
}

const FormToggle = ({ name, label }: IFormToggleProps) => {
  useInputBlurOnValueUpdate(name)

  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <Toggle
          label={label}
          name={input.name}
          className={styles.toggleContainer}
          checked={!!input.checked}
          onChange={input.onChange}
        />
      )}
    </Field>
  )
}

export default FormToggle
