import { IUploadImageResponse } from './upload.model'

export interface IScanContent {
  risk: string;
  resume: string;
  recommendations: string[];
  scanWarningId: string;
  isDisplayFeedback?: boolean;
}

interface IMessageBase {
  chatId: string;
  isCurrentUserAuthor: boolean;
  contentType: ContentType;
  createdAt: string;
  isRead: boolean;
  author: IChatUser;
}

export interface IChatMessage extends IMessageBase {
  content: string ;
}

export interface IWarningMessage extends IMessageBase {
  scanContent: IScanContent;
}

export interface ISendChatMessage {
  chatId: string;
  contentType: ContentType;
  content: string;
}

export enum ContentType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  SCAN_WARNING = 'SCAN_WARNING',
}

export interface IChat {
  id: string;
  offer: IChatOffer;
  receiverInfo: IChatUser;
  isReceiverOnline: boolean;
  lastMessage: string;
  lastMessageFrom: string;
  lastMessageCreatedAt: string;
  unreadMessagesCount: number;
}

// TODO: sync with Chat API
export interface IChatOffer {
  photo: IUploadImageResponse;
  slug: string;
  title: string;
  publicId: string;
  category: string;
  price: number;
}

export type IChatUser = {
  id: string;
  name: string;
  avatar: IUploadImageResponse;
  isBlocked: boolean;
}

export interface IOnlineStatus {
  chatId: string;
  isOnline: boolean;
}
