import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Heading from 'src/components/heading/Heading'
import { I18N_SEARCH } from 'src/constants/i18n.constants'

import styles from './searchResults.module.scss'

interface ISearchResultsTitleProps {
  query: string | null;
  city: string;
  foundPostsCount: number;
}

const SearchResultsTitle: React.FC<ISearchResultsTitleProps> = ({ query, city, foundPostsCount }) => {
  const { t } = useTranslation()

  const titleText = query
    ? t(`${I18N_SEARCH}.adsWithQuery.title`, { query })
    : t(`${I18N_SEARCH}.ads.title`)

  return (
    <Heading className={classNames('desktop-h4 mobile-h6', styles.title)}>
      <span className={styles.breakableTitle}>
        {titleText}
      </span>
      &nbsp;
      {t(`${I18N_SEARCH}.filterPanel.in`)}
      &nbsp;
      {city}
      <span className={styles.foundPostsCount}>
        {foundPostsCount}
        &nbsp;
        {t(`${I18N_SEARCH}.filterPanel.offers`)}
      </span>
    </Heading>
  )
}

export default SearchResultsTitle
