import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ChangePasswordModal from 'src/components/modal/authModals/ChangePasswordModal'
import VerifyEmailModal from 'src/components/modal/authModals/VerifyEmailModal'
import { YomeHash } from 'src/constants/navigate.constants'
import LoginUserProvider from 'src/contexts/loginUser.context'
import { useGetActionFromURL } from 'src/hooks/auth.hooks'
import { ModalComponentType } from 'src/models/modal.model'
import { EmailUrlAction, SocialLoginUrlAction } from 'src/models/url.model'

import LoginModal from './authModals/LoginModal'
import ResetModal from './authModals/ResetModal'
import SignupModal from './authModals/SignupModal'
import CookieManage from './cookieModal/CookieManage'
import FeatureUnavailableModal from './featureUnavailableModal/FeatureUnavailableModal'
import ResponsiveModalWrapper from './ResponsiveModalWrapper'
import SocialFailureModal from './smthWentWrongModal/SocialFailureModal'
import VerifyPhoneModalWrapper from './verifyPhoneModals/VerifyPhoneModalWrapper'

const MODAL_BY_HASH: { [key: string]: ModalComponentType } = {
  [YomeHash.auth]: LoginModal,
  [YomeHash.signup]: SignupModal,
  [YomeHash.resetPassword]: ResetModal,
  [YomeHash.cookie]: CookieManage,
  [YomeHash.unavailable]: FeatureUnavailableModal,
  [YomeHash.verifyPhone]: VerifyPhoneModalWrapper,
}

const MODAL_BY_QUERY: { [key: string]: ModalComponentType } = {
  reset: ChangePasswordModal,
  verify: VerifyEmailModal,
  socialFailure: SocialFailureModal,
}

const NON_CLOSABLE_BY_DEFAULT: [ModalComponentType] = [CookieManage]
const NON_FULLSCREEN_BY_DEFAULT: [ModalComponentType] = [FeatureUnavailableModal]
const HAS_MOBILE_CLOSE_BUTTON_BY_DEFAULT: [ModalComponentType] = [VerifyPhoneModalWrapper]

const getModalByCurrentUrl = (activeHash: string | null, activeQuery: EmailUrlAction | SocialLoginUrlAction | null) => {
  const modalByHash = !!activeHash && MODAL_BY_HASH[activeHash]

  if (modalByHash) {
    return modalByHash
  }

  const modalByQuery = !!activeQuery && MODAL_BY_QUERY[activeQuery]

  if (modalByQuery) {
    return modalByQuery
  }

  return null
}

const ModalByHashOrQuery = React.memo(() => {
  const activeHash = useLocation().hash
  const activeQuery = useGetActionFromURL()
  const [ModalComponent, setModalComponent] = useState<ModalComponentType | null>(null)

  const handleClose = React.useCallback(() => {
    if (activeQuery) {
      window.location.search = ''
    }

    if (activeHash) {
      window.location.hash = ''
    }
  }, [activeHash, activeQuery])

  useEffect(() => {
    setModalComponent(getModalByCurrentUrl(activeHash, activeQuery))
  }, [activeHash, activeQuery])

  if (!ModalComponent) {
    return null
  }

  const isCloseButtonHidden = NON_CLOSABLE_BY_DEFAULT.includes(ModalComponent)
  const isNonFullscreen = NON_FULLSCREEN_BY_DEFAULT.includes(ModalComponent)
  const hasMobileCloseButton = HAS_MOBILE_CLOSE_BUTTON_BY_DEFAULT.includes(ModalComponent)

  return (
    <LoginUserProvider>
      <ResponsiveModalWrapper
        isCloseButtonHidden={isCloseButtonHidden}
        handleClose={handleClose}
        isNonFullscreen={isNonFullscreen}
        showMobileCloseButton={hasMobileCloseButton}
      >
        <ModalComponent handleClose={handleClose}/>
      </ResponsiveModalWrapper>
    </LoginUserProvider>
  )
})

export default ModalByHashOrQuery
