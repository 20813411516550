import classNames from 'classnames'
import { SubmissionErrors } from 'final-form'
import { useCallback } from 'react'
import { Form, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import LargeToggle from 'src/components/largeToggle/LargeToggle'
import Toggle from 'src/components/toggle/Toggle'
import { I18N_PROFILE_SETTING_NOTIFICATIONS } from 'src/constants/i18n.constants'
import { Subject } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useToggle } from 'src/utils/hooks.utils'

import SettingLineCurrentInfo from './SettingLineCurrentInfo'
import SettingLineEditButton from './SettingLineEditButton'
import SettingLineEditModal from './SettingLineEditModal'

import styles from './setting.module.scss'

interface SettingToggleProps<T> {
  title: string;
  toggleNames: string[];
  onSubmit: (values: T) => Promise<SubmissionErrors>;
  initialValues?: T;
  checkPreventChange?: (prevValue: boolean) => boolean;
}

interface SettingToggleContentProps<T> extends SettingToggleProps<T> {
  onToggleChange: (name: string) => () => void;
}

type TSettingToggle = Record<string, boolean>

// eslint-disable-next-line @typescript-eslint/comma-dangle
const MobileToggleSettingsContent = <T,>({
  title,
  onSubmit,
  toggleNames,
  initialValues,
  onToggleChange,
}: SettingToggleContentProps<T>) => {
  const { t } = useTranslation()

  const [isFormOpen, toggleShowForm] = useToggle(false)

  const { values } = useFormState()
  const turnedOnKeys = Object.keys(values).filter((key) => values[key])
  const currentValue = turnedOnKeys.length ? (
    turnedOnKeys.map((key) => t(`${I18N_PROFILE_SETTING_NOTIFICATIONS}.${key}.label`)).join(', ')
  ) : t(`${I18N_PROFILE_SETTING_NOTIFICATIONS}.turnedOff`)

  return (
    <div className={classNames(styles.content, styles.mobileToggleLine)}>
      {!isFormOpen ? (
        <>
          <SettingLineCurrentInfo
            onClick={toggleShowForm}
            title={title}
            currentValue={currentValue}
          />
          <SettingLineEditButton
            toggle={toggleShowForm}
            settingId={Subject.Notifications}
          />
        </>
      ) : (
        <SettingLineEditModal
          onSubmit={onSubmit}
          toggle={toggleShowForm}
          title={title}
          initialValues={initialValues as Partial<T>}
          isSubmitButtonHidden
        >
          {toggleNames.map((toggleName) => (
            <LargeToggle
              name={toggleName}
              label={`${I18N_PROFILE_SETTING_NOTIFICATIONS}.${toggleName}.label`}
              supportingText={`${I18N_PROFILE_SETTING_NOTIFICATIONS}.${toggleName}.supportingText`}
              className={styles.singleToggleLine}
              onChange={onToggleChange(toggleName)}
              checked={values[toggleName]}
              key={toggleName}
            />
          ))}
        </SettingLineEditModal>
      )
      }
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const DesktopToggleSettingsContent = <T,>({
  title,
  toggleNames,
  onToggleChange,
}: SettingToggleContentProps<T>) => {
  const { values } = useFormState()

  const { t } = useTranslation()

  return (
    <div>
      <h4 className={'label-20'}>
        {t(title)}
      </h4>
      {toggleNames.map((toggleName) => (
        <Toggle
          name={toggleName}
          label={`${I18N_PROFILE_SETTING_NOTIFICATIONS}.${toggleName}.label`}
          supportingText={`${I18N_PROFILE_SETTING_NOTIFICATIONS}.${toggleName}.supportingText`}
          className={styles.singleToggleLine}
          onChange={onToggleChange(toggleName)}
          checked={values[toggleName]}
          key={toggleName}
        />
      ))}
    </div>
  )
}

const ToggleSettingsLine = (props: SettingToggleProps<TSettingToggle>) => {
  const { isDesktop } = useViewport()

  const { initialValues, onSubmit, checkPreventChange } = props

  const submitForm = useCallback(async (values: TSettingToggle) => {
    const errors = await onSubmit(values)

    if (errors && Object.keys(errors).length) {
      return errors
    }

    return undefined
  }, [onSubmit])

  const FormContent = isDesktop ? DesktopToggleSettingsContent : MobileToggleSettingsContent

  return (
    <Form
      onSubmit={submitForm}
      initialValues={initialValues}
      render={({ handleSubmit, values, form: { change } }) => {
        const onToggleChange = (name: string) => () => {
          const isPreventChange = checkPreventChange && checkPreventChange(values[name])

          if (isPreventChange) return

          change(name, !values[name])
          handleSubmit()
        }

        return (
          <form
            onSubmit={handleSubmit}
            className={styles.main}
          >
            <FormContent {...props} onToggleChange={onToggleChange}/>
          </form>
        )
      }}
    />
  )
}

export default ToggleSettingsLine
