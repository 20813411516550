import React from 'react'

import { getRemainingDatePercentage } from 'src/utils/date.utils'

import styles from './styles.module.scss'

interface IDurationLine {
  date?: string;
  isEmptyDuration?: boolean;
}

const DurationLine: React.FC<IDurationLine> = ({ date, isEmptyDuration }) => {
  if (!isEmptyDuration && !date) {
    throw new Error('DurationLine should have date property')
  }

  const width = (!isEmptyDuration && date) ? getRemainingDatePercentage(date) : 0

  return (
    <div className={styles.innerWrapper}>
      <div className={styles.inner} style={{ width: `${width}%` }}></div>
    </div>
  )
}

export default DurationLine
