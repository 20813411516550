import classNames from 'classnames'
import React, { HTMLAttributes, memo } from 'react'
import { useTranslation } from 'react-i18next'

import NumberOfLinesEnum from 'src/models/cardHeading.model'
import { LevelType } from 'src/models/heading.model'
import { SinglePost } from 'src/models/post.model'
import { uppercaseTitle } from 'src/utils/post.utils'

import Heading from '../heading/Heading'

import styles from './cardHeading.module.scss'

interface ICardHeadingProps extends HTMLAttributes<HTMLHeadingElement>{
  level?: LevelType;
  className?: string;
  offer: SinglePost;
  numberOfLines?: NumberOfLinesEnum;
  isStaticText?: boolean;
  isDisabled?: boolean;
}

const CardHeading: React.FC<ICardHeadingProps> = ({
  level,
  className,
  offer,
  numberOfLines = NumberOfLinesEnum.TWO,
  isStaticText = false,
  isDisabled = false,
  ...headingProps
}) => {
  const { t } = useTranslation()

  return (
    <Heading
      level = 'h1'
      className={classNames(
        styles.main,
        styles[numberOfLines],
        { [styles.color]: !isStaticText },
        { [styles.disabled]: isDisabled },
        { [styles.visited]: offer.isVisited },
        className,
      )}
      {...headingProps}
    >
      {uppercaseTitle(offer.title, t)}
    </Heading>
  )
}

export default memo(CardHeading)
