import classNames from 'classnames'
import { memo } from 'react'

import Chip, { ChipType } from 'src/components/button/Chip/Chip'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import FilterSidebar from 'src/components/filterSidebar/FilterSidebar'
import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import { I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useToggle } from 'src/utils/hooks.utils'

interface IFullscreenFilters {
  isChip?: boolean;
}

const FullscreenFilters = ({ isChip = false }: IFullscreenFilters) => {
  const [isFiltersOpen, toggleFilters] = useToggle()

  return (
    <>
      {isChip ? (
        <li>
          <Chip
            value={`${I18N_SEARCH_FILTER}.moreFilters`}
            iconName='plus'
            type={ChipType.light}
            onClick={toggleFilters}
            isNonBreakableText
          />
        </li>
      ) : (
        <div>
          <RegularButton
            id={ID.searchFilterShowFilters}
            buttonType={RegularButtonTypes.main}
            leftIcon={{ name: 'filters', size: 24 }}
            label={`${I18N_SEARCH_FILTER}.title`}
            onClick={toggleFilters}
          />
        </div>
      )}
      {isFiltersOpen && (
        <FullscreenModalLayout>
          <div className={classNames('mobile-paddings', 'fixed-button-container')}>
            <FilterSidebar closePage={toggleFilters}/>
          </div>
        </FullscreenModalLayout>
      )}
    </>
  )
}

export default memo(FullscreenFilters)
