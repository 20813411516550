import SvgIcon from 'src/components/svgIcon/SvgIcon'

import classNames from 'classnames'
import { Trans } from 'react-i18next'
import Paragraph from 'src/components/paragraph/Paragraph'
import styles from './plans.module.scss'

interface IPlanListItem {
  isBenefit?: boolean;
  label: string;
}

const PlanListItem = ({ isBenefit = false, label }: IPlanListItem) => (
  <li className={classNames('bodySizeL', styles.item)}>
    <SvgIcon className={classNames({ [styles.iconGreen]: isBenefit })} name={isBenefit ? 'plus' : 'check-tick'} />
    <Paragraph className={styles.itemText}>
      <Trans
        i18nKey={label}
        components={{
          1: <span className='desktop-h5'/>,
        }}
      >
      </Trans>
    </Paragraph>
  </li>
)

export default PlanListItem
