import classNames from 'classnames'

import OfferSkeleton from './OfferSkeleton'

import styles from './skeletons.module.scss'

interface IPostListLoader {
  itemsNumber?: number;
  isHorizontalView?: boolean;
}

const DEFAULT_ITEMS_NUM = 24
const DEFAULT_ITEMS_NUM_HORIZONTAL = 9

const PostListLoader = ({ itemsNumber, isHorizontalView = false }: IPostListLoader) => {
  const nItems = itemsNumber || (isHorizontalView ? DEFAULT_ITEMS_NUM_HORIZONTAL : DEFAULT_ITEMS_NUM)

  return (
    <div className={classNames(styles.skeletonList, { [styles.horizontal]: isHorizontalView })}>
      {
        Array.from({ length: nItems }).map((_, ind) => (
          <OfferSkeleton key={ind} isHorizontalView={isHorizontalView}/>
        ))
      }
    </div>
  )
}

export default PostListLoader
