import SWRErrorNotification from 'src/components/swr/SWRErrorNotification'
import { ILimitedSingleOfferList, IStackBoundaryProps, IStackProps } from 'src/models/post.model'
import { useGetPostList } from 'src/services/post.service'
import SWRkeys from 'src/constants/swr.constants'

import FavoriteCardStack from './FavoriteCardStack'
import ViewHistoryCardStack from './ViewHistoryCardStack'

const FavoriteOrViewHistoryCardStack = ({ stackEndpoint, offerLimit }: IStackProps) => {
  const { data: viewHistory, isLoading } = useGetPostList<ILimitedSingleOfferList>(stackEndpoint)

  if (isLoading) {
    return null
  }

  if (viewHistory && viewHistory.items.length > 0) {
    return (
      <FavoriteCardStack
        stackInfo={viewHistory}
        stackEndpoint={stackEndpoint}
      />
    )
  }

  return (
    <ViewHistoryCardStack offerLimit={offerLimit!}/>
  )
}

const FavoriteOrViewHistoryCardStackBoundary = ({ offerLimit }: IStackBoundaryProps) => {
  const stackEndpoint = SWRkeys.favoriteList({ limit: offerLimit })

  return (
    <SWRErrorNotification swrKey={stackEndpoint}>
      <FavoriteOrViewHistoryCardStack stackEndpoint={stackEndpoint} offerLimit={offerLimit}/>
    </SWRErrorNotification>
  )
}

export default FavoriteOrViewHistoryCardStackBoundary
