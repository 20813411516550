import { CountryIsoCode, FieldType, IFieldConfig } from '../models/fields.model'

const createInfoString = (field: string) => `field.${field}.info`
const createPlaceholderString = (field: string) => `field.${field}.placeholder`
const createMainLabelInfoString = (field: string) => `field.${field}.mainLabelInfo`
const createOptionsInfoArray = (field: string, options: string[]) => (
  options.map((opt) => `field.${field}.optionsInfo.${opt}`)
)
const createTooltipString = (field: string, part: string) => `field.${field}.tooltip.${part}`
const createTooltipArray = (field: string, nparts: number) => Array.from(
  { length: nparts },
  (_, i) => createTooltipString(field, `p${i + 1}`),
)

const fieldsConfig: Record<string, IFieldConfig> = {
  categoryPath: {
    name: 'categoryPath',
    type: FieldType.categorySelect,
    validation: { array: true },
  },
  category: {
    name: 'category',
    type: FieldType.category,
    validation: { min: 1, max: 60 },
    filter: { type: FieldType.category, isFullsizeMobile: true },
  },
  title: {
    name: 'title',
    type: FieldType.text,
    info: createInfoString('title'),
    placeholder: createPlaceholderString('title'),
    validation: { min: 1, max: 50 },
    showCounter: true,
  },
  description: {
    name: 'description',
    type: FieldType.textarea,
    placeholder: createPlaceholderString('description'),
    validation: { min: 1, max: 3000 },
    showCounter: true,
  },
  priceWithRate: {
    name: 'priceWithRate',
    type: FieldType.complex,
    filter: { type: FieldType.complex },
    fields: {
      price: {
        name: 'price',
        type: FieldType.price,
        placeholder: createPlaceholderString('price'),
        filter: { type: FieldType.price },
      },
      rate: {
        name: 'rate',
        type: FieldType.select,
        options: ['per-hour', 'per-service'],
        defaultValue: 'per-hour',
        filter: {
          type: FieldType.radio,
          isTwoColumns: true,
        },
      },
    },
  },
  photos: {
    name: 'photos',
    type: FieldType.file,
    validation: { array: true },
    mainLabelInfo: createMainLabelInfoString('photos'),
  },
  experience: {
    name: 'experience',
    type: FieldType.text,
    optional: true,
    validation: { min: 1, max: 60 },
  },
  place: {
    name: 'place',
    type: FieldType.select,
    options: ['remote', 'specialist', 'customer'],
    defaultValue: 'customer',
    filter: { type: FieldType.checkbox },
  },
  urgent: {
    name: 'urgent',
    type: FieldType.radio,
    options: ['pre-orders', 'urgent'],
    defaultValue: 'pre-orders',
  },
  location: {
    name: 'location',
    type: FieldType.location,
    info: createInfoString('location'),
    tooltip: {
      header: createTooltipString('location', 'header'),
      paragraphs: createTooltipArray('location', 2),
    },
    placeholder: createPlaceholderString('location'),
  },
  phone: {
    name: 'phone',
    type: FieldType.phone,
    options: Object.values(CountryIsoCode),
    defaultValue: CountryIsoCode.de,
    // info: createInfoString('phone'),
  },
  communication: {
    name: 'communication',
    type: FieldType.radio,
    options: ['calls-messages', 'calls', 'messages'],
    defaultValue: 'calls-messages',
  },
  type: {
    name: 'type',
    type: FieldType.select,
    options: ['private', 'commerce'],
    defaultValue: 'private',
  },
  condition: {
    name: 'condition',
    type: FieldType.radio,
    options: ['new', 'excellent', 'good', 'satisfactory'],
    defaultValue: 'good',
    optionsInfo: createOptionsInfoArray('condition', ['new', 'excellent', 'good', 'satisfactory']),
    tooltip: {
      header: createTooltipString('condition', 'condition'),
      paragraphs: createTooltipArray('condition', 4),
    },
    filter: {
      type: FieldType.checkbox,
    },
  },
  priceWithNegotiable: {
    name: 'priceWithNegotiable',
    type: FieldType.complex,
    filter: { type: FieldType.complex },
    fields: {
      price: {
        name: 'price',
        type: FieldType.price,
        placeholder: createPlaceholderString('price'),
        filter: { type: FieldType.price },
      },
      negotiable: {
        name: 'negotiable',
        type: FieldType.checkbox,
        optional: true,
        filter: { type: FieldType.toggle },
      },
    },
  },
  color: {
    name: 'color',
    type: FieldType.select,
    options: [
      'grey', 'blue', 'beige', 'black', 'brown', 'white', 'green', 'red', 'pink',
      'multicolored', 'violet', 'orange', 'yellow', 'silver', 'gold', 'transparent',
    ],
    optional: true,
    defaultValue: 'multicolored',
    filter: {
      type: FieldType.checkbox,
      isTwoColumns: true,
      isFullsizeMobile: true,
    },
  },
  // search filters only fields
  descriptionSearch: {
    name: 'descriptionSearch',
    type: FieldType.text,
    tooltip: {
      header: createTooltipString('descriptionSearch', 'header'),
      paragraphs: createTooltipArray('descriptionSearch', 1),
    },
    validation: { array: true, max: 3 },
    filter: {
      type: FieldType.descriptionSearch,
    },
  },
  rating: {
    name: 'rating',
    type: FieldType.checkbox,
    filter: { type: FieldType.toggle },
  },
  isAutoRenewal: {
    name: 'isAutoRenewal',
    type: FieldType.isAutoRenewal,
    optional: true,
  },
}

export default fieldsConfig
