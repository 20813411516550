import classNames from 'classnames'
import { ReactNode } from 'react'
import { useForm, useFormState } from 'react-final-form'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton, FormError } from 'src/components/form'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_PROFILE_SETTING_BTN } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'

import styles from './setting.module.scss'

interface ISettingLineEditFormContent {
  children: ReactNode;
  settingId: string;
  toggle: () => void;
  title: string;
}

const SettingLineEditFormContent = ({ children, settingId, toggle, title }: ISettingLineEditFormContent) => {
  const { reset } = useForm()
  const { submitError } = useFormState()

  return (
    <>
      <Paragraph className={classNames('bodySizeM desktop-body-18', styles.fieldTitle)}>
        {title}
      </Paragraph>
      <div className={styles.formFieldsWithButtons}>
        {children}
        {submitError && <FormError error={submitError} />}
        <div className={styles.sumbitCancelButtons}>
          <RegularButton
            id={concatId(ID.profileSettingCancelSetting, settingId)}
            label={`${I18N_PROFILE_SETTING_BTN}.cancelChanges`}
            buttonType={RegularButtonTypes.transparentWithBorder}
            onClick={() => {
              toggle()
              reset()
            }}
          />
          <FormButton
            id={concatId(ID.profileSettingSubmitSetting, settingId)}
            label={`${I18N_PROFILE_SETTING_BTN}.saveChanges`}
            buttonType={RegularButtonTypes.blackNoBorder}
          />
        </div>
      </div>
    </>
  )
}

export default SettingLineEditFormContent
