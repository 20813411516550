import { ReactNode } from 'react'

import RegularButton, { IRegularButtonProps, RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_AUTH } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { IModalWithClose } from 'src/models/modal.model'

import InfoModal from '../infoModal/InfoModal'

import styles from './smthWentWrongModal.module.scss'

interface ISmthWentWrongModal extends IModalWithClose {
  title: string;
  text: string;
  mainButtonProps: Partial<IRegularButtonProps> & { id: string };
  secondButtonProps: Partial<IRegularButtonProps> & { id: string };
  emptyStateIcon?: string;
  bottomEl?: ReactNode;
}

const SmthWentWrongModal = (
  { title, text, mainButtonProps, secondButtonProps, handleClose, bottomEl, emptyStateIcon }: ISmthWentWrongModal,
) => {
  const defaultSecondButtonProps = {
    id: secondButtonProps.id,
    label: `${I18N_AUTH}.contactSupport.buttonLabel`,
    linkTo: YomeURL.help,
  }
  const footerButtons = (
    <div className={styles.buttonRow}>
      <RegularButton
        buttonType={RegularButtonTypes.blackNoBorder}
        {...mainButtonProps}
      />
      <RegularButton
        buttonType={RegularButtonTypes.transparentWithBorder}
        {...(secondButtonProps.label ? secondButtonProps : defaultSecondButtonProps)}
      />
    </div>
  )

  return (
    <InfoModal modalFooter={bottomEl || footerButtons} handleClose={handleClose}>
      <EmptyStateMessage
        icon={emptyStateIcon || 'something-went-wrong-2'}
        title={title}
        text={text}
      />
      {bottomEl && (
        footerButtons
      )}
    </InfoModal>
  )
}

export default SmthWentWrongModal
