import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from 'src/assets/logo.svg'
import YomeURL from 'src/constants/navigate.constants'

import styles from './header.module.scss'

const HeaderLogo = () => (
  <nav className={styles.item}>
    <Link className={styles.logo} to={YomeURL.mainPage} title='Yome Network'>
      <Logo />
    </Link>
  </nav>
)

export default React.memo(HeaderLogo)
