import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormButton, FormError, FormInput, FormSelectDropdown } from 'src/components/form'
import FormEmailValidationField from 'src/components/form/FormEmailValidationField'
import FormFileField from 'src/components/form/FormFileField/FormFileField'
import FormTextArea from 'src/components/form/FormTextArea/FormTextArea'
import { FormVerificationCodeInput } from 'src/components/form/FormVerificationCodeInput'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { TOPICS } from 'src/constants/helpCenter.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { IHelpFormValues } from 'src/models/form.model'
import * as HelpService from 'src/services/help.service'
import { ForbiddenError, SubmitFormError, TooManyRequestsError } from 'src/utils/error.utils'
import { usePageTitle } from 'src/utils/hooks.utils'
import { getStaticPageMetaTitle } from 'src/utils/meta.utils'

import { I18N_GLOBAL, I18N_HELP_FORM } from '../../constants/i18n.constants'
import formStyles from '../newItem/components/form/offerForm.module.scss'
import HelpRouteHeader from './HelpRouteHeader'
import HelpRouteResponseErrorModal, { CODE_VERIFICATION_MODAL_ERROR_STATUS } from './HelpRouteResponseErrorModal'
import HelpRouteResponseSuccess from './HelpRouteResponseSuccess'

import styles from './helpRoute.module.scss'

const MAX_IMAGE_SIZE = 6 // MB
const CODE_EXPIRES_IN_SEC = '5.00' // 5 minute

export type IErrorModalStatus =
typeof CODE_VERIFICATION_MODAL_ERROR_STATUS[keyof typeof CODE_VERIFICATION_MODAL_ERROR_STATUS]

const HelpRoute = () => {
  usePageTitle(getStaticPageMetaTitle(YomeURL.help))
  const [isDisplayCodeInput, setIsDisplayCodeInput] = useState<boolean>(false)
  const [errorModalStatus, setErrorModalStatus] = useState<IErrorModalStatus>(CODE_VERIFICATION_MODAL_ERROR_STATUS.OFF)
  const [getCodeError, setGetCodeError] = useState<string>('')
  const [isValidationFormWithCodeField, setIsValidationFormWithCodeField] = useState<boolean>(false)
  const [isDisplayButton, setIsDisplayButton] = useState<boolean>(false)
  const [verificationCodeInputInfo, setVerificationCodeInputInfo] = useState<string>('')

  const { data: emails = [] } = HelpService.useGetUserUsedEmails()

  const { t } = useTranslation()
  const { isDesktop } = useViewport()

  const dropdownOptions = TOPICS.reduce((options, topic) => ({
    ...options,
    [topic]: t(`${I18N_HELP_FORM}.topic.${topic}`),
  }), {})

  const resetGetCodeError = () => {
    setGetCodeError('')
    setIsDisplayCodeInput(false)
  }

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    const hasButton = !!(value && (!emails.includes(value)))
    setIsDisplayButton(hasButton)
    setIsValidationFormWithCodeField(hasButton)
  }

  return (
    <div className={styles.main}>
      <Form
        onSubmit={(values: IHelpFormValues) => HelpService.handleHelpFormSubmit(values, isValidationFormWithCodeField)}
        render={function HelpForm({ handleSubmit, form, submitErrors }) {
          const { submitSucceeded, submitError, values } = form.getState()

          const handleGetVerifyCode = async (email: string) => {
            try {
              const isVerificationCodeSending = await HelpService.getVerificationCode(
                email,
                values.name,
              )

              if (isVerificationCodeSending) {
                setIsDisplayCodeInput(isVerificationCodeSending)

                toast.success(
                  t(`${I18N_HELP_FORM}.email.verificationCodeSent.toast`),
                  { icon: <SvgIcon name={'email'} size={16} /> },
                )

                setVerificationCodeInputInfo(
                  t(`${I18N_HELP_FORM}.email.verifyCode.info`,
                    { timer: CODE_EXPIRES_IN_SEC }),
                )
              }
            } catch (error) {
              setIsDisplayCodeInput(false)

              if (error instanceof TooManyRequestsError) {
                setErrorModalStatus(CODE_VERIFICATION_MODAL_ERROR_STATUS.TOO_MANY_REQUESTS)
              } else if (error instanceof ForbiddenError) {
                setErrorModalStatus(CODE_VERIFICATION_MODAL_ERROR_STATUS.FORBIDDEN)
              } else if (error instanceof SubmitFormError) {
                setGetCodeError(error.errorObject.email)
              } else {
                setErrorModalStatus(CODE_VERIFICATION_MODAL_ERROR_STATUS.INTERNAL_SERVER_ERROR)
              }
            }
          }

          const closeErrorModal = () => {
            setErrorModalStatus(CODE_VERIFICATION_MODAL_ERROR_STATUS.OFF)
          }

          useEffect(() => {
            if (submitErrors?.FORBIDDEN) {
              setErrorModalStatus(CODE_VERIFICATION_MODAL_ERROR_STATUS.FORBIDDEN)
            }
          }, [submitErrors?.FORBIDDEN])

          if (submitSucceeded) {
            return <HelpRouteResponseSuccess />
          }

          return (
            <form
              onSubmit={handleSubmit}
              className={classNames(styles.form, formStyles.main)}
            >
              { (errorModalStatus !== CODE_VERIFICATION_MODAL_ERROR_STATUS.OFF) && (
                <HelpRouteResponseErrorModal
                  type={errorModalStatus}
                  close={closeErrorModal}
                />
              )
              }
              <HelpRouteHeader />
              <div className={styles.group}>
                <div className={styles.select}>
                  <FormSelectDropdown
                    label={`${I18N_HELP_FORM}.topic`}
                    name='topic'
                    options={dropdownOptions}
                    isFullSize={!isDesktop}
                  />
                </div>

                <FormInput
                  name='name'
                  id={ID.supportPageInputUsername}
                  placeholder={t(`${I18N_HELP_FORM}.input.name`)}
                  fieldWrapperProps={{
                    name: 'name',
                    label: `${I18N_HELP_FORM}.username`,
                  }}
                />

                <FormEmailValidationField
                  name='email'
                  handleClick={handleGetVerifyCode}
                  id={ID.supportPageInputEmail}
                  resetError={resetGetCodeError}
                  isDisplayButton={isDisplayButton}
                  lastUsedEmail={emails[0]}
                  handleBlur={handleBlur}
                  fieldWrapperProps={{
                    customError: getCodeError,
                    name: 'email',
                    label: `${I18N_HELP_FORM}.email`,
                  }}
                />
                { (isDisplayCodeInput || (isValidationFormWithCodeField && submitErrors?.emailVerifyCode)) && (
                  <FormVerificationCodeInput
                    id={ID.supportPageVerifyEmail}
                    name='emailVerifyCode'
                    autoFocus={false}
                    placeholder={'123456'}
                    fieldWrapperProps={{
                      info: verificationCodeInputInfo,
                      name: 'emailVerifyCode',
                      label: `${I18N_HELP_FORM}.email.verifyCode.label`,
                    }}
                  />
                )}
                <FormTextArea
                  name='message'
                  id={ID.supportPageInputMessage}
                  placeholder={`${I18N_HELP_FORM}.input.message`}
                  fieldWrapperProps={{
                    name: 'message',
                    label: `${I18N_HELP_FORM}.message`,
                    info: `${I18N_HELP_FORM}.hint.message`,
                  }}
                />
              </div>
              <FormFileField name='files' error={submitError} maxSize={MAX_IMAGE_SIZE}/>
              <FormError />
              <FormButton
                id={ID.supportPageSubmitForm}
                label={`${I18N_GLOBAL}.sendRequest`}
                className={classNames(styles.formBtn, { 'sticky-button-wrapper': !isDesktop })}
              />
              <p className={classNames(styles.policy, 'supportive-14')}>
                {t(`${I18N_HELP_FORM}.policy`)}
                &nbsp;
                <Link to={YomeURL.privacy}>
                  {t(`${I18N_GLOBAL}.privacyPolicy`)}
                </Link>
              </p>
            </form>
          )
        }}
      />
    </div>
  )
}

export default HelpRoute
