import config from '../config'

import { AvatarType, ImageType, IUploadImageResponse } from '../models/upload.model'

export const GOOGLE_API_URL = 'https://storage.googleapis.com'

export const getImageOfSize = (
  imageObj: IUploadImageResponse | undefined,
  folder: string,
  size: ImageType,
) => {
  if (!imageObj) {
    return null
  }

  const { filename, extension } = imageObj

  return `${GOOGLE_API_URL}/${config.bucket.image}/${folder}/${filename}/${size}.${extension}`
}

export const getThumbSmallUrl = (
  imageObj: IUploadImageResponse,
  folder: string,
) => getImageOfSize(imageObj, folder, ImageType.ThumbSmall)

export const getImageOrigUrl = (
  imageObj: IUploadImageResponse,
  folder: string,
) => getImageOfSize(imageObj, folder, ImageType.Orig)

export const getThumbBigUrl = (
  imageObj: IUploadImageResponse,
  folder: string,
) => getImageOfSize(imageObj, folder, ImageType.ThumbBig)

export const getImagePostUrl = (
  imageObj: IUploadImageResponse,
  folder: string,
) => getImageOfSize(imageObj, folder, ImageType.Post)

export const getAvatar = (userId: string, avatarType: AvatarType, image: IUploadImageResponse) => {
  const { filename, extension } = image

  return `${GOOGLE_API_URL}/${config.bucket.avatar}/${userId}/${filename}/${avatarType}.${extension}`
}
