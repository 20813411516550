import classNames from 'classnames'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_FOR_BUSINESS } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'

import styles from './steps.module.scss'

interface ISingleStep {
  label: string;
  btnLabel?: string;
  btnLink?: string;
  isLastEl: boolean;
  index: number;
}

const SingleStep = ({ label, index, isLastEl, btnLabel, btnLink }: ISingleStep) => (
  <li className={classNames(styles.card, styles[`card-${label}`])}>
    <div className={styles.content}>
      <Heading level='h3' className='mobile-h1' label={(index + 1).toString()} />
      <Paragraph
        text={`${I18N_FOR_BUSINESS}.steps.${label}.card.title`}
        className={classNames(isLastEl ? 'heading mobile-h2' : 'label-28', styles.title)}
      />
      <Paragraph
        text={`${I18N_FOR_BUSINESS}.steps.${label}.card.text`}
        className={isLastEl ? 'body-text desktop-body-22' : 'bodySizeL'}
      />
      {btnLabel && (
        <RegularButton
          label={`${I18N_FOR_BUSINESS}.steps.${btnLabel}`}
          linkTo={btnLink}
          buttonType={isLastEl ? RegularButtonTypes.main : RegularButtonTypes.transparentWithBorder}
          id={concatId(ID.forBusinessButtonOption, label)}
          className={styles.btn}
        />
      )}
    </div>
  </li>
)

export default SingleStep
