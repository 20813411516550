/* eslint-disable max-len */
import { memo } from 'react'

import Paragraph from 'src/components/paragraph/Paragraph'
import LegalSectionTitle from '../../LegalSectionTitle'

const PrivacyPolicyQuestions = () => (
  <>
    <LegalSectionTitle
      label='6. Fragen und Kommentare'
    />
    <Paragraph>
      Für Fragen oder Kommentare bezüglich dieser Datenschutzerklärung stehen wir gern unter den oben angegebenen Kontaktdaten zur Verfügung.
    </Paragraph>
  </>
)

export default memo(PrivacyPolicyQuestions)
