import React, { memo, useCallback, useEffect } from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { EURO } from 'src/constants/general.constants'
import { usePriceFormatter } from 'src/hooks/price.hooks'
import { parseFloatFromString } from 'src/utils/number.utils'
import { getInputValueFieldName } from 'src/utils/form.utils'

import FieldWrapper, { IFieldWrapperProps } from '../FieldWrapper'

import formStyles from '../form.module.scss'
import priceStyles from './price.module.scss'

interface IFormPriceInternalProps {
  name: string;
  placeholder?: string;
}

interface IFormPriceProps extends IFormPriceInternalProps {
  fieldWrapperProps?: IFieldWrapperProps;
}

const FormPriceInternal = ({ name, placeholder = '' }: IFormPriceInternalProps) => {
  const { change } = useForm()
  const { t } = useTranslation()
  const { input } = useField(name)
  const priceFormatter = usePriceFormatter(true)
  const priceFieldInputName = getInputValueFieldName(name)

  const priceValue = input.value
  const { input: { value: actualPriceValue } } = useField(priceFieldInputName)

  useEffect(() => {
    if (priceValue === actualPriceValue || priceValue.toString().includes(EURO)) {
      return
    }

    change(priceFieldInputName, priceValue)
  }, [change, priceValue, actualPriceValue, priceFieldInputName])

  const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (priceValue) {
      change(name, priceFormatter(priceValue))
    }

    input.onBlur(event)
  }, [change, priceFormatter, input, priceValue, name])

  const handleFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (actualPriceValue) {
      change(name, actualPriceValue)
    }

    input.onFocus(event)
  }, [name, actualPriceValue, change, input])

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newVal = event.currentTarget.value

    const floatNumber = parseFloatFromString(newVal)
    change(name, floatNumber)
  }

  return (
    <input
      {...input}
      id={name}
      autoComplete="off"
      type="text"
      className={formStyles.fieldInput}
      title={name}
      placeholder={t(placeholder)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
    />
  )
}

const FormPrice = ({ name, fieldWrapperProps, ...other }: IFormPriceProps) => {
  const { change } = useForm()
  const priceFormatter = usePriceFormatter(true)
  const { input: { value }, meta: { active } } = useField(name)

  useEffect(() => {
    // value can be number or string, 0 should be formatted
    if (value !== '' && !active) {
      change(name, priceFormatter(value))
    }
  }, [active, value, priceFormatter, name, change])

  return (
    <Field name={name}>
      {function renderFormPrice() {
        return (
          <FieldWrapper
            {...fieldWrapperProps}
            name={name}
            className={priceStyles.priceField}
            errorClassName={priceStyles.fieldError}
          >
            <FormPriceInternal {...other} name={name} />
          </FieldWrapper>
        )
      }}
    </Field>
  )
}

export default memo(FormPrice)
