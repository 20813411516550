/* eslint-disable max-len */
import { memo } from 'react'

import Paragraph from 'src/components/paragraph/Paragraph'
import LegalSectionTitle from '../../LegalSectionTitle'

const PrivacyPolicyChanges = () => (
  <>
    <LegalSectionTitle
      label='5. Änderungen dieser Datenschutzerklärung'
    />
    <Paragraph>
      Wir behalten uns das Recht vor, diese Datenschutzerklärung mit Wirkung für die Zukunft zu ändern. Eine aktuelle Version ist jeweils hier verfügbar.
    </Paragraph>
  </>
)

export default memo(PrivacyPolicyChanges)
