import classNames from 'classnames'

import { ID } from 'src/constants/id.constants'
import SWRkeys from 'src/constants/swr.constants'
import { SinglePost } from 'src/models/post.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { useGetPostList } from 'src/services/post.service'
import { assertData } from 'src/utils/error.utils'

import Heading from '../heading/Heading'
import PostListLoader from '../skeletons/PostListLoader'
import SWRErrorBoundary from '../swr/SWRErrorBoundary'
import PostList from './PostList'

import styles from './recommendedPostList.module.scss'

interface RecommendedPostListProps {
  recommendedListKey: string;
}

const RecommendedPostList = ({ recommendedListKey }: RecommendedPostListProps) => {
  const { data: recommendedPosts, isLoading: isPostListLoading } = useGetPostList<Array<SinglePost>>(recommendedListKey)

  if (isPostListLoading) {
    return (
      <PostListLoader/>
    )
  }

  assertData(recommendedPosts)

  return (
    <PostList
      idPostPrefix={ID.recommendedPrefixPost}
      posts={recommendedPosts}
      postListKey={recommendedListKey}
    />
  )
}

const RecommendedPostListBoundary = () => {
  const { data: currentUser, isLoading: isCurrentUserLoading } = useCurrentUser()
  const recommendedListKey = currentUser ? SWRkeys.recomendedOffers : SWRkeys.popularOffers

  return (
    <div className={styles.main}>
      <Heading
        className={classNames('desktop-h5 mobile-h5', styles.title)}
        label={currentUser ? 'posts.titleRecommended' : 'posts.titlePopular'}
      />
      {isCurrentUserLoading ? (
        <PostListLoader/>
      ) : (
        <SWRErrorBoundary swrKey={recommendedListKey}>
          <RecommendedPostList
            recommendedListKey={recommendedListKey}
          />
        </SWRErrorBoundary>
      )}
    </div>
  )
}

export default RecommendedPostListBoundary
