import classNames from 'classnames'
import { parseDigits } from 'libphonenumber-js'
import React, { memo } from 'react'
import { Field, useField, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import Dropdown from 'src/components/dropdown/Dropdown'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useTranslatedCountriesSorted } from 'src/hooks/form.hooks'
import { assertData } from 'src/utils/error.utils'
import { getPhoneCountryCodeFieldName, getPhoneNumberFieldName } from 'src/utils/form.utils'
import { COUNTRY_TO_PHONE_CODE, CountryIsoCode } from 'src/yome-categories-fields-config/models/fields.model'
import { getFieldConfig } from 'src/yome-categories-fields-config/utils/fieldConfig.utils'

import FieldWrapper, { IFieldWrapperProps } from '../FieldWrapper'

import selectStyles from '../FormSelect/select.module.scss'
import formStyles from '../form.module.scss'
import styles from './phone.module.scss'

interface IFormPhoneProps {
  fieldWrapperProps?: IFieldWrapperProps;
  name: string;
  className?: string;
}

const formatPhoneNumber = (value: string): string => parseDigits(value)

export const CodeDropdown = ({ name }: { name: string }) => {
  const { values } = useFormState()
  const { change } = useForm()

  const { t } = useTranslation()

  const phoneConfig = getFieldConfig('phone')
  assertData(phoneConfig)
  assertData(phoneConfig.options)
  assertData(phoneConfig.defaultValue)

  const { defaultValue } = phoneConfig

  const currentValue = (values[name] || defaultValue) as CountryIsoCode
  const currentCode = COUNTRY_TO_PHONE_CODE[currentValue]

  const currentIcon = currentValue || defaultValue
  const currentOptionName = `${I18N_GLOBAL}.country.${currentValue}`

  const countries = phoneConfig.options as CountryIsoCode[]
  const translatedCountries = useTranslatedCountriesSorted(countries)

  const selectActions = Object.fromEntries(
    translatedCountries.map(({ translated, isoCode }) => {
      const countryCode = COUNTRY_TO_PHONE_CODE[isoCode]

      return (
        [`${translated} ${countryCode}`, {
          onClick: () => change(name, isoCode),
          icon: isoCode || undefined,
        }]
      )
    }),
  )

  return (
    <Dropdown
      id={ID.formFieldSelectForm}
      sections={{ section: selectActions }}
      selectedAction={`${t(currentOptionName)} ${currentCode}`}
      alignLeft
      alignAbove
    >
      <div className={styles.codeDropdown}>
        <span className={selectStyles.currentOption}>
          <SvgIcon
            name={currentIcon}
            size={20}
            className={styles.codeButtonIcon}
          />
          <span className={classNames(selectStyles.currentOptionText, 'bodySizeM')}>
            { currentCode }
          </span>
        </span>
        <button className={classNames(selectStyles.chevron, styles.codeDropdownButton)} type='button'>
          <SvgIcon name='chevron-down' size={24}/>
        </button>
      </div>
    </Dropdown>
  )
}

const FormPhoneField = ({ fieldWrapperProps, name, className }: IFormPhoneProps) => {
  const { change } = useForm()
  const { input: { onBlur } } = useField(name)

  const countryCodeFieldName = getPhoneCountryCodeFieldName(name)
  const phoneBodyFieldName = getPhoneNumberFieldName(name)

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const formattedValue = formatPhoneNumber(value)
    change(phoneBodyFieldName, formattedValue)
    onBlur()
  }, [change, onBlur, phoneBodyFieldName])

  return (
    <FieldWrapper {...fieldWrapperProps} name={name} className={classNames(className)}>
      <div className={styles.phoneField}>
        <Field name={countryCodeFieldName}>
          {() => (
            <CodeDropdown name={countryCodeFieldName}/>
          )}
        </Field>
        <Field name={phoneBodyFieldName}>
          {({ input }) => (
            <input
              {...input}
              name={phoneBodyFieldName}
              type="tel"
              className={classNames(formStyles.fieldInput, styles.phoneBodyInput)}
              onChange={handleChange}
              placeholder="XXXX-XXX-XXX"
            />
          )}
        </Field>
      </div>
    </FieldWrapper>
  )
}

export default memo(FormPhoneField)
