import { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { useToggle } from 'src/utils/hooks.utils'

const initialVallues = {
  isTabActive: true,
}

const activeTabContext = createContext(initialVallues)

export const ActiveTabProvider = ({ children }: PropsWithChildren) => {
  const [isTabActive, toggleIsTabActive] = useToggle(true)

  useEffect(() => {
    document.addEventListener('visibilitychange', toggleIsTabActive)

    return () => {
      document.removeEventListener('visibilitychange', toggleIsTabActive)
    }
  }, [toggleIsTabActive])

  return (
    <activeTabContext.Provider value={{ isTabActive }}>
      {children}
    </activeTabContext.Provider>
  )
}

export const useIsTabActiveContext = () => {
  const { isTabActive } = useContext(activeTabContext)

  return isTabActive
}
