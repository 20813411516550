import { ALL_CATEGORIES } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import { ICategory } from 'src/yome-categories-fields-config/models/category.model'

enum CategoryGroupEnum {
  SERVICES = 'services',
  ALL_BUT_SERVICES_CATEGORIES = 'all_but_services_categories',
}

const getAllCategoriesFlat = (
  category: ICategory,
): string[] => [category.title, ...(category.items ? category.items.flatMap(getAllCategoriesFlat) : [])]

export const ALL_CATEGORIES_FLAT: string[] = ALL_CATEGORIES.flatMap((category) => getAllCategoriesFlat(category))

export default CategoryGroupEnum
