import { useCallback } from 'react'

import ImageButton from 'src/components/button/ImageButton'
import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { MAX_CATEGORY_SEARCH_LEVELS } from 'src/utils/search.utils'
import { ICategory } from 'src/yome-categories-fields-config/models/category.model'

export interface ICategoryDropdownBaseProps {
  openSubcategory: (path: string[]) => void;
  submitCategory: (title: string) => void;
  path?: string[];
  active?: string;
  hasIcon: boolean;
}

interface ICategoryButtonProps extends ICategoryDropdownBaseProps {
  category: ICategory;
}

const CategorySingleButton = (
  { openSubcategory,
    submitCategory,
    path = [],
    active,
    hasIcon,
    category }: ICategoryButtonProps,
) => {
  const { isDesktop } = useViewport()

  const { title } = category

  const iconTitle = title.includes('OTHER') ? 'OTHER' : title
  const isLastLevel = path.length === MAX_CATEGORY_SEARCH_LEVELS
  const icon = hasIcon ? `category/${iconTitle.toLocaleLowerCase()}` : undefined
  const hasChildren = Boolean(category.items?.length)

  const handleClick = useCallback(() => {
    if (isDesktop) {
      submitCategory(title)

      return
    }

    if (hasChildren) {
      openSubcategory(path.concat(title))
    } else {
      submitCategory(title)
    }
  }, [openSubcategory, submitCategory, path, title, hasChildren, isDesktop])

  const handleHover = useCallback(() => {
    if (active === title) {
      return
    }

    openSubcategory(path.concat(title))
  }, [active, path, title, openSubcategory])

  return (
    <li>
      <ImageButton
        label={`${I18N_CATEGORIES}.${title}`}
        onClick={handleClick}
        icon={icon}
        size='medium'
        onMouseOver={isDesktop ? handleHover : undefined}
        isActive={title === active}
        showChevron={((hasChildren && !isLastLevel) || !isDesktop)}
      />
    </li>
  )
}

export default CategorySingleButton
