import { useCallback } from 'react'
import { mutate } from 'swr'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import SortDropdown from 'src/components/SortDropdown/SortDropdown'
import { I18N_SEARCH_ACTION_BAR } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useSorting } from 'src/hooks/sort.hooks'
import { IFilterValues, SORT_QPARAM } from 'src/models/search.model'
import { SortPostListTypes } from 'src/models/sort.model'
import { handleSaveSearch, useGetSearchPostList } from 'src/services/search.service'
import { captureErrorAndShowToast } from 'src/utils/error.utils'

import TranslationToggle from './TranslationToggle'

import styles from './searchActionBar.module.scss'

export enum SortOptions {
  DEFAULT = 'default',
  CHEAP = 'cheap',
  EXPENSIVE = 'expensive',
  RECENT = 'recent',
}

interface ISearchActionBar {
  searchEndpoint: string;
  endpointSearchFields: IFilterValues;
}

const SearchActionBar = ({ searchEndpoint, endpointSearchFields }: ISearchActionBar) => {
  const { data: searchData } = useGetSearchPostList(searchEndpoint)
  const searchId = searchData?.savedSearchId

  const { isDesktop } = useViewport()

  const saveCurrentSearch = useCallback(async () => {
    try {
      const { savedSearchId } = await handleSaveSearch(endpointSearchFields, searchId)
      mutate(searchEndpoint, { ...searchData, savedSearchId }, false)
    } catch (error) {
      captureErrorAndShowToast(error)
    }
  }, [endpointSearchFields, searchId, searchData, searchEndpoint])

  const { sortOfferList } = useSorting()

  const favoriteIconName = searchId ? 'heart-filled' : 'heart'

  return (
    <div className={styles.actionBar}>
      <SortDropdown
        onChange={sortOfferList}
        idSortButton={ID.searchFilterSortResult}
        defaultValue={endpointSearchFields[SORT_QPARAM] as SortPostListTypes}
        sortOptions={Object.values(SortPostListTypes)}
      />
      <div className={styles.rightAligned}>
        {isDesktop && <TranslationToggle />}
        <RegularButton
          id={ID.searchFilterSaveSearch}
          label={`${I18N_SEARCH_ACTION_BAR}.saveSearch`}
          onClick={saveCurrentSearch}
          buttonType={RegularButtonTypes.transparentNoBorder}
          leftIcon={{ name: favoriteIconName, size: 24, currentColor: searchId ? 'fill' : undefined }}
        />
      </div>
    </div>
  )
}

export default SearchActionBar
