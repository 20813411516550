import { Form } from 'react-final-form'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton, FormRadio, FormTextArea } from 'src/components/form'
import withModalLayout from 'src/components/hocs/withModalLayout'
import { I18N_GLOBAL, I18N_REPORT_MODAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import {
  IReportFormProps,
  IReportOfferFormValues,
  IReportOfferModal,
  REPORT_OFFER_REASON_MAP,
} from 'src/models/report.model'
import { reportPost } from 'src/services/report.service'
import { captureErrorAndShowToast, SubmitFormError } from 'src/utils/error.utils'
import { useToggle } from 'src/utils/hooks.utils'

import InfoModal from '../infoModal/InfoModal'
import ModalLayout from '../ModalLayout'
import ReportSubmittedModal from './reportedModal/ReportSubmittedModal'

import styles from './reportModal.module.scss'

const ReportForm = ({ handleClose }: IReportFormProps) => {
  const footerButtons = (
    <div className={styles.buttonRow}>
      <FormButton
        id={ID.reportOfferModalSubmitButton}
        label={`${I18N_REPORT_MODAL}.submitButton`}
        buttonType={RegularButtonTypes.blackNoBorder}
      />
      <RegularButton
        id={ID.reportOfferModalCancelButton}
        label={`${I18N_GLOBAL}.cancel`}
        buttonType={RegularButtonTypes.transparentWithBorder}
        onClick={handleClose}
      />
    </div>
  )

  return (
    <InfoModal
      title={`${I18N_REPORT_MODAL}.title`}
      modalFooter={footerButtons}
      handleClose={handleClose}
    >
      <FormRadio
        name='reason'
        options={REPORT_OFFER_REASON_MAP}
        className={styles.formRadio}
        id={ID.reportOfferModalInputReason}
      />
      <FormTextArea
        name='comment'
        id={ID.reportOfferModalInputComment}
        placeholder={`${I18N_REPORT_MODAL}.comment.placeholder`}
        fieldWrapperProps={{
          name: 'comment',
          info: `${I18N_REPORT_MODAL}.comment.info`,
        }}
        autoFocus
      />
    </InfoModal>
  )
}

const ReportOfferModal = ({ handleClose, modalProps }: IReportOfferModal) => {
  const [successOpen, toggleSuccessOpen] = useToggle()

  const { postId } = modalProps

  const handleReportPost = async ({ reason, comment }: IReportOfferFormValues) => {
    try {
      await reportPost(postId, reason, comment)
      toggleSuccessOpen()
    } catch (error) {
      if (error instanceof SubmitFormError) {
        return error.errorObject
      }

      captureErrorAndShowToast(error)
    }

    return null
  }

  if (successOpen) {
    return (
      <ModalLayout handleClose={handleClose}>
        <ReportSubmittedModal handleClose={handleClose}/>
      </ModalLayout>
    )
  }

  return (
    <Form
      onSubmit={handleReportPost}
      render={function RenderReportedUserModalForm({ handleSubmit }) {
        return (
          <form onSubmit={handleSubmit} className={styles.modalContainer}>
            <ReportForm handleClose={handleClose}/>
          </form>
        )
      }}
    />
  )
}

export default withModalLayout(ReportOfferModal)
