import { useEffect } from 'react'
import { useFormState } from 'react-final-form'
import useSWR from 'swr'

import SWRErrorNotification from 'src/components/swr/SWRErrorNotification'
import SWRkeys from 'src/constants/swr.constants'
import { IChat } from 'src/models/messenger.model'
import { ClientToServerEvents, getSocket } from 'src/services/messenger.service'
import { getFilteredChats } from 'src/utils/chat.utils'

import ChatDisplay from '../chatDisplay/ChatDisplay'
import NoChats from '../noChats/NoChats'

import styles from './chatList.module.scss'

interface IChatList {
  currentChat: IChat | undefined;
}

const ChatList = ({ currentChat }: IChatList) => {
  const { data: chats } = useSWR<IChat[]>(SWRkeys.getAllChats)
  const { values } = useFormState()

  useEffect(() => {
    const socket = getSocket()
    socket.emit(ClientToServerEvents.GetAllChats)
  }, [chats])

  if (chats === undefined || !chats?.length) {
    return <NoChats />
  }

  const filteredChats = getFilteredChats(chats, values.filterMessage)

  if (!filteredChats.length) {
    return <NoChats />
  }

  return (
    <div className={styles.scrollableChatListContent}>
      {filteredChats.map((chat) => (
        <ChatDisplay
          key={chat.id}
          chat={chat}
          isSelected={chat.id === currentChat?.id}
        />
      ))}
    </div>
  )
}

const ChatListBoundary = ({ currentChat }: IChatList) => (
  <SWRErrorNotification swrKey={SWRkeys.getAllChats}>
    <ChatList currentChat={currentChat}/>
  </SWRErrorNotification>
)

export default ChatListBoundary
