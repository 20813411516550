import classNames from 'classnames'
import React, { cloneElement, memo } from 'react'

import { IdStructure } from 'src/constants/id.constants'
import { TDropdownSection } from 'src/models/dropdown.model'

import { emptyFunction } from '../../constants/function.constants'
import { useOnClickOutside, useToggle } from '../../utils/hooks.utils'
import DropdownSectionList from './DropdownSectionList'

import styles from './dropdown.module.scss'

interface IDropdownProps {
  id: IdStructure;
  sections: Record<string, TDropdownSection>;
  children?: JSX.Element;
  selectedAction?: string;
  className?: string;
  disabled?: boolean;
  alignLeft?: boolean;
  alignAbove?: boolean;
}

const Dropdown = ({
  id,
  children,
  sections,
  className,
  selectedAction,
  disabled = false,
  alignLeft = false,
  alignAbove = false,
}: IDropdownProps) => {
  const [isDropdownOpen, toggleDropdown] = useToggle()
  const buttonRef = React.useRef<HTMLButtonElement | null>(null)
  useOnClickOutside([buttonRef], toggleDropdown, isDropdownOpen)

  const dropdownField = (
    <div className={classNames(styles.main, className, alignLeft && styles.left, alignAbove && styles.above)}>
      <DropdownSectionList
        id={id}
        sections={sections}
        selectedAction={selectedAction}
      />
    </div>
  )

  if (!children) {
    return (
      <>
        {dropdownField}
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      {cloneElement(children, { onClick: (disabled ? emptyFunction : toggleDropdown), ref: buttonRef })}
      {isDropdownOpen && dropdownField}
    </div>
  )
}

export default memo(Dropdown)
