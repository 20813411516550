export const FREE_ITEMS_CARD_ID = 'free'

export default [{
  id: 'cars',
  isLarge: true,
}, {
  id: 'children_goods',
  isLarge: false,
}, {
  id: 'realty',
  isLarge: true,
}, {
  id: 'furniture_and_interior',
  isLarge: false,
}, {
  id: 'clothes',
  isLarge: true,
}, {
  id: 'pets',
  isLarge: false,
}, {
  id: 'spare_parts',
  isLarge: false,
}, {
  id: 'beauty',
  isLarge: false,
}, {
  id: 'services',
  isLarge: true,
}, {
  id: 'electronics',
  isLarge: false,
}, {
  id: 'hobbies',
  isLarge: true,
}, {
  id: 'home',
  isLarge: false,
}, {
  id: 'household_appliances',
  isLarge: false,
}, {
  id: FREE_ITEMS_CARD_ID,
  isLarge: true,
}]
