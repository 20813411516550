import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import { IDSection } from 'src/constants/id.constants'
import META_DATA from 'src/constants/meta.data.json'
import YomeURL from 'src/constants/navigate.constants'

import { setDocumentTitle } from './browser.utils'
import { getOfferPageMetaTitle, getSearchPageMetaTitle, getUserPageMetaTitle } from './meta.utils'

export const useToggle = (defaultValue: boolean = false): [boolean, () => void] => {
  const [value, update] = React.useState(defaultValue)
  const toggle = React.useCallback(() => update(!value), [value])

  return [value, toggle]
}

export const useRefetch = (key: string) => {
  const { mutate } = useSWRConfig()

  return React.useCallback(() => mutate(key), [mutate, key])
}

export const useDebouncedEffect = <T>(effect: Function, delay: number, dependency: T, hasStopCondition = false) => {
  React.useEffect(() => {
    if (hasStopCondition) {
      return undefined
    }

    const handler = setTimeout(() => effect(), delay)

    return () => clearTimeout(handler)
  }, [effect, delay, dependency, hasStopCondition])
}

export const useOnClickOutside = (
  elementRefs: React.MutableRefObject<HTMLElement | null>[],
  onClickOutside: () => void,
  condition = true,
) => {
  React.useEffect(() => {
    const handleClick = ({ target }: MouseEvent): void => {
      const isClickOnElements = elementRefs.some((singleRef) => singleRef?.current?.contains(target as Node))
      const isClickOutside = !isClickOnElements

      if (isClickOutside && condition) {
        onClickOutside()
      }
    }

    document.body.addEventListener('click', handleClick)

    return () => document.body.removeEventListener('click', handleClick)
  }, [onClickOutside, elementRefs, condition])
}

interface IBaseHeights {
  rootHeight: number;
  headerHeight: number;
  searchBarHeight: number;
}

export const useBaseHeights = () => {
  const [heights, setHeights] = useState<IBaseHeights>({ rootHeight: 0, headerHeight: 0, searchBarHeight: 0 })

  useEffect(() => {
    const rootHeight = document.getElementById('root')?.getBoundingClientRect().height || 0
    const headerHeight = document.getElementById(IDSection.header)?.getBoundingClientRect().height || 0
    const searchBarHeight = document.getElementById(IDSection.searchBar)?.getBoundingClientRect().height || 0

    setHeights({
      rootHeight,
      headerHeight,
      searchBarHeight,
    })
  }, [])

  return heights
}

export const useCheckIsPage = () => {
  const location = useLocation()
  const isMainPage = location.pathname === YomeURL.mainPage
  const isSearchPage = location.pathname === YomeURL.search
  const isChatPage = location.pathname === YomeURL.messages
  const isProfileSettingsPage = location.pathname === YomeURL.settings

  return { isMainPage, isSearchPage, isChatPage, isProfileSettingsPage }
}

export const usePageTitle = (title: string = META_DATA.TITLE) => {
  useEffect(() => {
    setDocumentTitle(title)
  }, [title])
}

export const useSearchPageTitle = (searchContent: string, category: string) => {
  useEffect(() => {
    setDocumentTitle(getSearchPageMetaTitle(searchContent, category))
  }, [searchContent, category])
}

export const useOfferPageTitle = (title: string, category: string, city: string) => {
  useEffect(() => {
    setDocumentTitle(getOfferPageMetaTitle(title, category, city))
  }, [title, category, city])
}

export const useUserPageTitle = (username: string) => {
  useEffect(() => {
    setDocumentTitle(getUserPageMetaTitle(username))
  }, [username])
}
