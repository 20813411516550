import useSWR from 'swr'

import { sendGet, sendPost } from 'src/utils/network.utils'
import { TUserPreferences } from 'src/models/userPreferences.model'
import SWRkeys from 'src/constants/swr.constants'
import YomeAPI from 'src/constants/network.constants'

const getUserPreferences = (path: string): Promise<TUserPreferences> => sendGet(path)

const updateUserPreferences = (values: Partial<TUserPreferences>) => sendPost(YomeAPI.updateUserPreferences, values)

const useGetUserPreferences = () => useSWR<TUserPreferences>(
  SWRkeys.getUserPreferences,
  getUserPreferences,
)

export {
  useGetUserPreferences,
  updateUserPreferences,
}
