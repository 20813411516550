/* eslint-disable max-len */
import { memo } from 'react'

import Paragraph from 'src/components/paragraph/Paragraph'
import LegalSectionTitle from '../../LegalSectionTitle'

const PrivacyPolicyNewsletter = () => (
  <>
    <LegalSectionTitle
      label='2. Newsletter'
    />
    <Paragraph>
      Wir behalten uns vor, Kunden, die bereits Dienstleistungen von uns in Anspruch genommen haben oder Waren gekauft haben, von Zeit zu Zeit per E-Mail oder auf anderem Weg elektronisch über unsere Angebote zu informieren, falls sie dem nicht widersprochen haben. Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse liegt in der Direktwerbung (Erwägungsgrund 47 DSGVO). Kunden können der Verwendung ihrer E-Mail-Adresse zu Werbezwecken jederzeit ohne zusätzliche Kosten widersprechen, zum Beispiel über den Link am Ende einer jeden E-Mail oder per E-Mail an unsere oben genannte E-Mail-Adresse.
    </Paragraph>
    <Paragraph>
      Interessenten haben die Möglichkeit, einen kostenlosen Newsletter zu abonnieren. Wir verarbeiten die bei der Anmeldung angegebenen Daten ausschließlich für den Versand des Newsletters. Die Anmeldung erfolgt über die Auswahl des entsprechenden Feldes auf unserer Website, durch Ankreuzen des entsprechenden Feldes in einem Papierdokument oder durch eine andere eindeutige Handlung, wodurch Interessenten ihr Einverständnis in die Verarbeitung ihrer Daten erklären, so dass Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. a DSGVO ist. Die Einwilligung kann jederzeit widerrufen werden, z.B. durch Klicken des entsprechenden Links im Newsletter oder Hinweis an unsere oben angegebene E-Mail-Adresse. Die Verarbeitung der Daten bis zum Widerruf bleibt auch im Falle eines Widerrufs rechtmäßig.
    </Paragraph>
    <Paragraph>
      Auf Grundlage der Einwilligung der Empfänger (Art. 6 Abs. 1 S. 1 lit. a DSGVO) messen wir auch die Öffnungs- und Klickrate unserer Newsletter, um zu verstehen, welche Inhalte relevant für unsere Empfänger sind.
    </Paragraph>
    <Paragraph>
      Wir versenden Newsletter mit dem Tool Mailchimp des Anbieters Rocket Science Group LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA (Datenschutzerklärung:
      {' '}
      <a href="https://mailchimp.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
        https://mailchimp.com/legal/privacy/
      </a>
      ). Der Anbieter verarbeitet dabei Inhalts-, Nutzungs-, Meta-/Kommunikationsdaten und Kontaktdaten in den USA.
    </Paragraph>
    <Paragraph>
      Wir versenden Newsletter mit dem Tool Mandrill des Anbieters Rocket Science Group LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA (Datenschutzerklärung:
      {' '}
      <a href="https://mailchimp.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
        https://mailchimp.com/legal/privacy/
      </a>
      ). Der Anbieter verarbeitet dabei Inhalts-, Nutzungs-, Meta-/Kommunikationsdaten und Kontaktdaten in den USA.
    </Paragraph>
    <Paragraph>
      Wir versenden Newsletter mit dem Tool Mailgun des Anbieters Mailgun Technologies, Inc., 112 E Pecan St Ste 1135, San Antonio, TX, 78205-1509, USA (Datenschutzerklärung:
      {' '}
      <a href="https://www.mailgun.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
        https://www.mailgun.com/privacy-policy/
      </a>
      ). Der Anbieter verarbeitet dabei Inhalts-, Nutzungs-, Meta-/Kommunikationsdaten und Kontaktdaten in der EU.
    </Paragraph>
  </>
)

export default memo(PrivacyPolicyNewsletter)
