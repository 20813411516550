import { memo } from 'react'

import CategoryCard from './CategoryCard'
import categoryCardsData from './categoryCards.data'

import styles from './categoryCards.module.scss'

const CategoryCards = () => {
  const firstRowCards = categoryCardsData.slice(0, 7)
  const secondRowCards = categoryCardsData.slice(7)

  return (
    <div className={styles.categoryCardsContainer}>
      <div className={styles.line}>
        {firstRowCards.map(({ id, isLarge }) => (
          <CategoryCard id={id} key={id} isLarge={isLarge} />
        ))}
      </div>
      <div className={styles.line}>
        {secondRowCards.map(({ id, isLarge }) => (
          <CategoryCard id={id} key={id} isLarge={isLarge} />
        ))}
      </div>
    </div>
  )
}

export default memo(CategoryCards)
