import { MainCategories } from 'src/yome-categories-fields-config/models/fields.model'

import { I18N_FORM } from './i18n.constants'

export const FORM_FIELD_INPUT_VALUE_POSTFIX = 'Input'

export const MAX_OFFER_IMAGE_SIZE = 15 // MB

export const SUPPORTING_TEXT = {
  services: `${I18N_FORM}.isAutoRenewal.supportingText.${MainCategories.services}`,
  others: `${I18N_FORM}.isAutoRenewal.supportingText.${MainCategories.others}`,
}
