// error code field
export const ERROR_CODE = 'errorCode'

// backend error codes
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const NOT_FOUND = 'NOT_FOUND'
export const NOT_AUTHORIZED = 'NOT_AUTHORIZED'
export const POST_DELETED = 'POST_DELETED'
export const INCORRECT_CREDENTIALS = 'INCORRECT_CREDENTIALS'
export const UNVERIFIED_EMAIL = 'UNVERIFIED_EMAIL'
export const RESET_ALREADY_SENT = 'RESET_ALREADY_SENT'
export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG'
export const NOT_ACCEPTABLE = 'NOT_ACCEPTABLE'
export const BAD_REQUEST = 'BAD_REQUEST'
export const DELETE_FAILED = 'DELETE_FAILED'
export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS'
export const FORBIDDEN = 'FORBIDDEN'
export const WRONG_OR_EXPIRED_CODE = 'WRONG_OR_EXPIRED_CODE'
export const INVALID_CODE = 'INVALID_CODE'
export const PHONE_WAS_USED = 'PHONE_WAS_USED'

// joi error codes
export const JOI_REQUIRED = 'any.required'

// web error codes
export const NO_DATA = 'NO_DATA'
export const INVALID_POST_STATUS = 'INVALID_POST_STATUS'

// validation error codes
export const INVALID_PHONE = 'INVALID_PHONE'
