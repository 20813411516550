import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import { I18N_COOKIE_MODAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import {
  acceptAllPrivacyCookies,
  getCookie,
  rejectAllPrivacyCookies,
  RequiredPolicyCookies,
} from 'src/services/cookie.service'

import ModalLayout from '../ModalLayout'

import styles from './cookieModal.module.scss'

const CookieModal = () => {
  const { t } = useTranslation()
  const activeHash = useLocation().hash

  const { isDesktop } = useViewport()

  const isCookieUndefined = getCookie(RequiredPolicyCookies.gdprConsent) === undefined

  const [isOpen, setIsOpen] = useState(isCookieUndefined)

  useEffect(() => {
    setIsOpen(isCookieUndefined)
  }, [isCookieUndefined, activeHash])

  if (!isOpen) {
    return null
  }

  const accept = (): void => {
    setIsOpen(false)
    acceptAllPrivacyCookies()
  }

  const disable = (): void => {
    setIsOpen(false)
    rejectAllPrivacyCookies()
  }

  const Wrapper = isDesktop ? React.Fragment : ModalLayout

  return (
    <Wrapper>
      <div className={styles.overlayCookie}>
        <div className={styles.cookieContainer}>
          <Heading level='h2' className='desktop-h5 mobile-h4'>
            {t(`${I18N_COOKIE_MODAL}.title`)}
          </Heading>
          <div className={styles.cookieText}>
            <div>
              <Trans i18nKey={`${I18N_COOKIE_MODAL}.description`}>
                Yome platform uses cookies and local storage for performance and personalization.
                Only essential cookies are turned on by default. See our
                <Link className={'underline'} to={YomeURL.cookies}>
                  Cookies Policy
                </Link>
              </Trans>
            </div>
            <div>
              <Trans i18nKey={`${I18N_COOKIE_MODAL}.manage`}>
                You can
                <Link className={'underline'} to={YomeHash.cookie}>
                  Manage cookies
                </Link>
                and update choices at any time.
              </Trans>
            </div>
          </div>
          <div className={styles.cookieBtnContainer}>
            <RegularButton
              id={ID.cookieModalRejectCookie}
              buttonType={RegularButtonTypes.transparentWithBorder}
              label={`${I18N_COOKIE_MODAL}.rejectAll`}
              onClick={disable}
            />
            <RegularButton
              id={ID.cookieModalAcceptCookie}
              buttonType={RegularButtonTypes.blackNoBorder}
              label={`${I18N_COOKIE_MODAL}.acceptAll`}
              onClick={accept}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CookieModal
