import React from 'react'
import { useTranslation } from 'react-i18next'

import { I18N_GLOBAL } from 'src/constants/i18n.constants'

import Toggle from './Toggle'

import styles from './toggle.module.scss'

interface IToggleOnOffProps {
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const ToggleOnOff: React.FC<IToggleOnOffProps> = ({ name, checked, onChange, disabled = false }) => {
  const { t } = useTranslation()

  const toggleLabel = checked ? `${I18N_GLOBAL}.on` : `${I18N_GLOBAL}.off`

  return (
    <div className={styles.main}>
      <span>
        {t(toggleLabel)}
      </span>
      <Toggle
        disabled={disabled}
        name={name}
        label=''
        checked={checked}
        onChange={onChange}
        size='large'
      />
    </div>
  )
}

export default ToggleOnOff
