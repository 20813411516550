/* eslint-disable max-len */
import { memo } from 'react'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import LegalSectionTitle from '../../LegalSectionTitle'

const PrivacyPolicyProcessingNotice = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.1. Hinweis für Websitebesucher aus Deutschland
    </Heading>
    <Paragraph>
      Unsere Website speichert Informationen in der Endeinrichtung von Websitebesuchern (z.B. Cookies) oder greift auf Informationen zu, die bereits in der Endeinrichtung gespeichert sind (z.B. IP-Adressen). Welche Informationen dies im Einzelnen sind, ist den folgenden Abschnitten zu entnehmen.
    </Paragraph>
    <Paragraph>
      Diese Speicherung und dieser Zugriff erfolgt auf Grundlage der folgenden Bestimmungen:
    </Paragraph>
    <ul>
      <li>
        <Paragraph>
          Soweit diese Speicherung oder dieser Zugriff unbedingt erforderlich ist, damit wir dem von Websitebesuchern ausdrücklich gewünschten Dienst unserer Website zur Verfügung stellen (z.B. zur Durchführung eines vom Websitebesucher genutzten Chatbot oder zur Sicherstellung der IT-Sicherheit unserer Website), erfolgt sie auf Grundlage von § 25 Abs. 2 Nr. 2 TTDSG.
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Im Übrigen erfolgt diese Speicherung oder dieser Zugriff auf Grundlage der Einwilligung der Websitebesucher (§ 25 Abs. 1 TTDSG).
        </Paragraph>
      </li>
    </ul>
    <Paragraph>
      Die nachgelagerte Datenverarbeitung erfolgt nach Maßgabe der folgenden Abschnitte und auf Grundlage der Vorschriften der DSGVO.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingInformative = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.2. Informatorische Nutzung der Website
    </Heading>
    <Paragraph>
      Bei der informatorischen Nutzung der Website, also wenn Seitenbesucher uns nicht gesondert Informationen übermitteln, erheben wir die personenbezogenen Daten, die der Browser an unseren Server übermittelt, um die Stabilität und Sicherheit unserer Website zu gewährleisten. Darin liegt unser berechtigtes Interesse, so dass Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO ist.
    </Paragraph>
    <Paragraph>
      Diese Daten sind:
    </Paragraph>
    <ul>
      <li>
        <Paragraph>
          IP-Adresse
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Datum und Uhrzeit der Anfrage
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Zeitzonendifferenz zur Greenwich Mean Time (GMT)
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Inhalt der Anfrage (konkrete Seite)
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Zugriffsstatus/HTTP-Statuscode
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          jeweils übertragene Datenmenge
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Website, von der die Anfrage kommt
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Browser
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Betriebssystem und dessen Oberfläche
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Sprache und Version der Browsersoftware.
        </Paragraph>
      </li>
    </ul>
    <Paragraph>
      Diese Daten werden außerdem in Logfiles gespeichert. Sie werden gelöscht, wenn ihre Speicherung nicht mehr erforderlich ist, spätestens nach 14 Tagen.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingHosting = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.3. Webhosting und Bereitstellung der Website
    </Heading>
    <Paragraph>
      Unsere Website hostet DigitalOcean. Anbieter ist Digitalocean LLC, 101 Avenue of the Americas 10th Floor, New York, NY 10013, USA. Der Anbieter verarbeitet dabei die über die Website übermittelten personenbezogenen Daten, z.B. Inhalts-, Nutzungs-, Meta-/Kommunikationsdaten oder Kontaktdaten, in der EU. Weitere Informationen finden sich in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://www.digitalocean.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
        https://www.digitalocean.com/legal/privacy-policy
      </a>
      .
    </Paragraph>
    <Paragraph>
      Es ist unser berechtigtes Interesse, eine Website zur Verfügung zu stellen, so dass die Rechtsgrundlage der beschriebenen Datenverarbeitung Art. 6 Abs. 1 S. 1 lit. f DSGVO ist.
    </Paragraph>
    <Paragraph>
      Wir nutzen das Content Delivery Network Cloudflare für unsere Website. Anbieter ist Cloudflare, Inc., 101 Townsend St., San Francisco, CA 94107, USA. Der Anbieter verarbeitet dabei die über die Website übermittelten personenbezogenen Daten, z.B. Inhalts-, Nutzungs-, Meta-/Kommunikations- oder Kontaktdaten in den USA. Weitere Informationen finden Sie in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://www.cloudflare.com/de-de/privacypolicy/" target="_blank" rel="noopener noreferrer">
        https://www.cloudflare.com/de-de/privacypolicy/
      </a>
      .
    </Paragraph>
    <Paragraph>
      Wir haben ein berechtigtes Interesse daran, ausreichende Speicher- und Auslieferungskapazitäten zu nutzen, um auch bei großen Lastspitzen einen optimalen Datendurchsatz zu gewährleisten. Die Rechtsgrundlage der beschriebenen Datenverarbeitung ist daher Art. 6 Abs. 1 S. 1 lit. f GDPR.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Angemessenheitsbeschluss. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist gewährleistet, weil die EU-Kommission im Rahmen eines Angemessenheitsbeschluss nach Art. 45 Abs. 3 DSGVO beschlossen hat, dass das Drittland ein angemessenes Schutzniveau bietet.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingContact = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.4. Kontaktformular
    </Heading>
    <Paragraph>
      Bei Kontaktaufnahme über das Kontaktformular auf unserer Website speichern wir die dort abgefragten Daten und den Inhalt der Nachricht.Rechtsgrundlage für die Verarbeitung ist unser berechtigtes Interesse, an uns gerichtete Anfragen zu beantworten. Rechtsgrundlage für die Verarbeitung ist deshalb Art. 6 Abs. 1 S. 1 lit. f DSGVO.
    </Paragraph>
    <Paragraph>
      Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingVacant = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.5. Stellenanzeigen
    </Heading>
    <Paragraph>
      Wir veröffentlichen Stellen, die in unserem Unternehmen frei sind, auf unserer Website, auf mit der Website verbundenen Seiten oder auf Websites von Dritten.
      <br />
      Die Verarbeitung der im Rahmen der Bewerbung angegebenen Daten erfolgt zur Durchführung des Bewerbungsverfahrens. Soweit diese für unsere Entscheidung, ein Beschäftigungsverhältnis zu begründen, erforderlich sind, ist Rechtsgrundlage Art. 88 Abs. 1 DSGVO i. V. m. § 26 Abs. 1 BDSG. Die zur Durchführung des Bewerbungsverfahrens erforderlichen Daten haben wir entsprechend gekennzeichnet oder weisen auf sie hin. Wenn Bewerber diese Daten nicht angeben, können wir die Bewerbung nicht bearbeiten.
      <br />
      Weitere Daten sind freiwillig und nicht für eine Bewerbung erforderlich. Falls Bewerber weitere Angaben machen, ist Grundlage ihre Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO).
    </Paragraph>
    <Paragraph>
      Wir bitten Bewerber, in Lebenslauf und Anschreiben auf Angaben zu politischen Meinungen, religiösen Anschauungen und ähnlich sensiblen Daten zu verzichten. Sie sind nicht für eine Bewerbung erforderlich. Wenn Bewerber dennoch entsprechende Angaben machen, können wir ihre Verarbeitung im Rahmen der Verarbeitung des Lebenslaufes oder Anschreibens nicht verhindern. Ihre Verarbeitung beruht dann auch auf der Einwilligung der Bewerber (Art. 9 Abs. 2 lit. a DSGVO).
    </Paragraph>
    <Paragraph>
      Schließlich verarbeiten wir die Daten der Bewerber für weitere Bewerbungsverfahren, wenn sie uns dazu ihre Einwilligung erteilt haben. In diesem Fall ist Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. a DSGVO.
    </Paragraph>
    <Paragraph>
      Die Daten der Bewerber geben wir an die zuständigen Mitarbeiter der Personalabteilung, an unsere Auftragsverarbeiter im Bereich Recruiting und an die im Übrigen im Bewerbungsverfahren beteiligten Mitarbeiter weiter.
    </Paragraph>
    <Paragraph>
      Wenn wir im Anschluss an das Bewerbungsverfahren ein Beschäftigungsverhältnis mit dem Bewerber eingehen, löschen wir die Daten erst nach Beendigung des Beschäftigungsverhältnisses. Andernfalls löschen wir die Daten spätestens sechs Monate nach Ablehnung eines Bewerbers.
    </Paragraph>
    <Paragraph>
      Wenn Bewerber uns ihre Einwilligung erteilt haben, ihre Daten auch für weitere Bewerbungsverfahren zu verwenden, löschen wir ihre Daten erst ein Jahr nach Erhalt der Bewerbung.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingReviews = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.6. Bewertungen
    </Heading>
    <Paragraph>
      Seitenbesucher können auf unserer Website Bewertungen für unsere Waren, Dienstleistungen oder allgemein zu unserem Unternehmen hinterlassen. Dafür verarbeiten wir neben den eingegebenen Daten Meta- oder Kommunikationsdaten. Wir haben ein berechtigtes Interesse daran, von Seitenbesuchern eine Rückmeldung zu unserem Angebot zu erhalten. Deshalb ist Rechtsgrundlage der Datenverarbeitung Art. 6 Abs. 1 S. 1 lit. f DSGVO. Soweit wir für die Vereinbarung ein Tool eines Drittanbieters verwenden, sind die Informationen dazu unter "Drittanbieter" zu finden.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingCustomer = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.7. Kundenkonto und weitere Services
    </Heading>
    <Paragraph>
      Website-Besucher können auf unserer Website ein Kundenkonto eröffnen. Die Verarbeitung der Daten erfolgt zur Erfüllung des mit dem jeweiligen Seitenbesucher geschlossenen Vertrags (Art. 6 Abs. 1 S. 1 lit. b DSGVO).
    </Paragraph>
    <Paragraph>
      Über die bei der Registrierung eingegebenen Daten hinaus verarbeiten wir auch Chat-Daten, wenn Nutzer die Chat-Funktion zur Kommunikation untereinander nutzen.
    </Paragraph>
    <Paragraph>
      Darüber hinaus verarbeiten wir Anzeigendaten, wie z.B. Standort, Beschreibung, Preis, Zeitraum der Registrierung, Ansichten, Telefonnummer (optional), wenn Nutzer Anzeigen veröffentlichen. Diese Daten werden anderen Nutzern zur Verfügung gestellt.
    </Paragraph>
    <Paragraph>
      Um die Bewertungsfunktion auf der Plattform bereitzustellen, werden außerdem Bewertungen, die Nutzer über andere Nutzer veröffentlichen, allen Nutzern der Plattform zur Verfügung gestellt.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingSSO = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.8. Single-Sign-On
    </Heading>
    <Paragraph>
      Nutzer können sich auf unserer Website mit Hilfe eines oder mehrerer Single-Sign-On-Verfahren anmelden. Dabei nutzen sie die bereits für einen Anbieter erstellten Anmeldedaten. Voraussetzung ist, dass der Nutzer bereits bei dem jeweiligen Anbieter registriert ist. Wenn sich ein Nutzer mittels Single-Sign-On-Verfahrens anmeldet, erhalten wir vom Anbieter die Information, dass der Nutzer bei dem Anbieter eingeloggt ist und der Anbieter erhält die Information, dass der Nutzer das Single-Sign-On-Verfahren auf unserer Website nutzt. Abhängig von den Einstellungen des Nutzers in seinem Konto auf der Seite des Anbieters, kann es sein, dass uns vom Anbieter zusätzliche Informationen zur Verfügung gestellt werden. Die Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse, Nutzern eine einfache Log-In-Möglichkeit zur Verfügung zu stellen. Gleichzeitig werden die Interessen der Nutzer gewahrt, da die Nutzung nur freiwillig ist.
    </Paragraph>
    <Paragraph>
      Anbieter des oder der angebotenen Verfahren sind:
    </Paragraph>
    <ul>
      <li>
        <Paragraph>
          Apple Inc., Infinite Loop, Cupertino, CA 95014, USA (Datenschutzerklärung:
          {' '}
          <a href="https://www.apple.com/legal/privacy/de-ww/" target="_blank" rel="noopener noreferrer">
            https://www.apple.com/legal/privacy/de-ww/
          </a>
          )
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Meta Platforms Ireland Ltd., 4 Grand Canal Square, Dublin 2, Irland („Facebook“). Welche Daten wir von Facebook erhalten, wird Seitenbesuchern im Rahmen des Anmeldeprozesses von Facebook mitgeteilt. Informationen zu Facebook und die Kontaktdaten des Datenschutzbeauftragten sowie weitere Informationen dazu, wie Facebook personenbezogene Daten verarbeitet, einschließlich der Rechtsgrundlage und der Möglichkeiten zur Wahrnehmung der Rechte als Betroffener gegenüber Facebook, finden sich unter
          {' '}
          <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/about/privacy
          </a>
          . Wir sind für die durch Facebook im Rahmen der Nutzung des Verfahrens stattfindende Datenverarbeitung gemeinsam mit Facebook verantwortlich und haben mit Facebook eine Vereinbarung über die gemeinsame Verantwortung (Art. 26 DSGVO) geschlossen. Dort haben wir die jeweiligen Verantwortlichkeiten für die Erfüllung der Verpflichtungen gemäß der DSGVO hinsichtlich der gemeinsamen Verarbeitung festgelegt. Wir sind verpflichtet, die vorstehenden Informationen bereitzustellen und Facebook hat die Verantwortung für die weiteren Betroffenenrechte gemäß Art. 15-20 DSGVO übernommen.
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (Datenschutzerklärung:
          {' '}
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            https://policies.google.com/privacy
          </a>
          )
        </Paragraph>
      </li>
    </ul>
  </>
)

const PrivacyPolicyProcessingOffer = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.9. Angebot von Waren und Dienstleistungen
    </Heading>
    <Paragraph>
      Wir bieten über unsere Website Waren und Dienstleistungen an. Dabei verarbeiten wir im Rahmen der Bestellung die folgenden Daten:
    </Paragraph>
    <Paragraph>
      Address, name, phone number
    </Paragraph>
    <Paragraph>
      Die Verarbeitung der Daten erfolgt zur Erbringung des mit dem jeweiligen Seitenbesucher geschlossenen Vertrags (Art. 6 Abs. 1 S. 1 lit. b DSGVO).
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingPayment = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.10. Zahlungsdienstleister
    </Heading>
    <Paragraph>
      Zur Abwicklung von Zahlungen nutzen wir Zahlungsabwickler, die selbst datenschutzrechtlich Verantwortliche im Sinne von Art. 4 Nr. 7 DSGVO sind. Soweit diese von uns im Bestellprozess eingegebene Daten und Zahlungsdaten erhalten, erfüllen wir damit den mit unseren Kunden geschlossenen Vertrag (Art. 6 Abs. 1 S. 1 lit. b DSGVO).
    </Paragraph>
    <Paragraph>
      Diese Zahlungsdienstleister sind:
    </Paragraph>
    <ul>
      <li>
        <Paragraph>
          PayPal (Europe) S.à r.l. et Cie, S.C.A., Luxemburg
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Stripe Payments Europe, Ltd., Irland
        </Paragraph>
      </li>
    </ul>
  </>
)

const PrivacyPolicyProcessingCookies = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.11. Technisch notwendige Cookies
    </Heading>
    <ul>
      <li>
        <Paragraph>
          Cookies, die Log-In-Daten speichern,
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Cookies, die Spracheinstellungen übernehmen,
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Cookies, die Zahlungsanbieter zur Zahlungsabwicklung setzen und nicht das Nutzerverhalten analysieren und
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Cookies, die sich Suchbegriffe merken
        </Paragraph>
      </li>
    </ul>
  </>
)

const PrivacyPolicyProcessingGooglePlaces = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.1. Google Places API
    </Heading>
    <Paragraph>
      Wir setzen Google Places API für Karten auf unserer Website ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Dublin, Irland. Der Anbieter verarbeitet Inhaltsdaten (z.B. Eingaben in Onlineformularen) und Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in der EU.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.
    </Paragraph>
    <Paragraph>
      Wir löschen die Daten, wenn der Zweck ihrer Erhebung entfallen ist. Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
        https://policies.google.com/privacy
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingGoogleAds = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.2. Google Ads
    </Heading>
    <Paragraph>
      Wir setzen Google Ads für Werbung ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Der Anbieter verarbeitet Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten) und Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in den USA.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren.
    </Paragraph>
    <Paragraph>
      Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Angemessenheitsbeschluss. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist gewährleistet, weil die EU-Kommission im Rahmen eines Angemessenheitsbeschluss nach Art. 45 Abs. 3 DSGVO beschlossen hat, dass das Drittland ein angemessenes Schutzniveau bietet.
    </Paragraph>
    <Paragraph>
      Wir löschen die Daten, wenn der Zweck ihrer Erhebung entfallen ist. Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
        https://policies.google.com/privacy?hl=de
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingGoogleWebfonts = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.3. Google Webfonts
    </Heading>
    <Paragraph>
      Wir setzen Google Webfonts für Schriftarten auf der Website ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Der Anbieter verarbeitet Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in den USA.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Einwilligungen.
    </Paragraph>
    <Paragraph>
      Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
        https://policies.google.com/privacy?hl=de
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingGoogleAnalytics = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.4. Google Analytics
    </Heading>
    <Paragraph>
      Wir setzen Google Analytics zur Analyse ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Dublin, Irland. Der Anbieter verarbeitet Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten) und Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in den USA.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Angemessenheitsbeschluss. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist gewährleistet, weil die EU-Kommission im Rahmen eines Angemessenheitsbeschluss nach Art. 45 Abs. 3 DSGVO beschlossen hat, dass das Drittland ein angemessenes Schutzniveau bietet.
    </Paragraph>
    <Paragraph>
      Die Daten werden gelöscht, wenn der Zweck ihrer Erhebung entfallen ist und keine Aufbewahrungspflicht entgegensteht. Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
        https://policies.google.com/privacy?hl=de
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingSentry = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.5. Sentry
    </Heading>
    <Paragraph>
      Wir setzen Sentry zur Überwachung von Anwendungen und zur Fehlerverfolgung in Anwendungen oder auf Websites ein. Der Anbieter ist Functional Software, Inc., 132 Hawthorne Street San Francisco, CA 94107, USA. Der Anbieter verarbeitet Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Inhaltsdaten (z.B. Eingaben in Onlineformularen) und Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in den USA.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Standardvertragsklauseln. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist durch gemäß dem Prüfverfahren nach Art. 93 Abs. 2 DSGVO erlassene Standarddatenschutzklauseln gewährleistet (Art. 46 Abs. 2 lit. c DSGVO), die wir mit dem Anbieter vereinbart haben.
    </Paragraph>
    <Paragraph>
      Die Daten werden gelöscht, wenn der Zweck ihrer Erhebung entfallen ist und keine Aufbewahrungspflicht entgegensteht. Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://sentry.io/privacy/" target="_blank" rel="noopener noreferrer">
        https://sentry.io/privacy/
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingGoogleTag = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.6. Google Tag Manager
    </Heading>
    <Paragraph>
      Wir setzen Google Tag Manager zur Analyse und für Werbung ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Der Anbieter verarbeitet Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten) in den USA.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Angemessenheitsbeschluss. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist gewährleistet, weil die EU-Kommission im Rahmen eines Angemessenheitsbeschluss nach Art. 45 Abs. 3 DSGVO beschlossen hat, dass das Drittland ein angemessenes Schutzniveau bietet.
    </Paragraph>
    <Paragraph>
      Wir löschen die Daten, wenn der Zweck ihrer Erhebung entfallen ist. Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
        https://policies.google.com/privacy?hl=de
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingCloudflare = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.7. Cloudflare
    </Heading>
    <Paragraph>
      Wir setzen Cloudflare für die Sicherheit unserer Anwendungen ein. Der Anbieter ist Cloudflare, Inc., 101 Townsend Street, San Francisco, CA 94107, USA. Der Anbieter verarbeitet Inhaltsdaten (z.B. Eingaben in Onlineformularen) und Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in den USA.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse, die Sicherheit unserer App zu erhöhen, indem wir verhindern, dass schädlicher Traffic unseren Server erreicht.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Angemessenheitsbeschluss. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist gewährleistet, weil die EU-Kommission im Rahmen eines Angemessenheitsbeschluss nach Art. 45 Abs. 3 DSGVO beschlossen hat, dass das Drittland ein angemessenes Schutzniveau bietet.
    </Paragraph>
    <Paragraph>
      Die Daten werden gelöscht, wenn der Zweck ihrer Erhebung entfallen ist und keine Aufbewahrungspflicht entgegensteht. Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://www.cloudflare.com/de-de/privacypolicy" target="_blank" rel="noopener noreferrer">
        https://www.cloudflare.com/de-de/privacypolicy
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingGoogleCaptcha = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.8. Google reCAPTCHA
    </Heading>
    <Paragraph>
      Wir setzen Google reCAPTCHA zur Verwaltung von Authentifizierungen ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Dublin, Irland. Der Anbieter verarbeitet Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten) und Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in den USA.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Einwilligungen.
    </Paragraph>
    <Paragraph>
      Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
        https://policies.google.com/privacy?hl=de
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingMailchimp = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12.9. Mailchimp
    </Heading>
    <Paragraph>
      Wir setzen Mailchimp zur Verwaltung von E-Mails und zum E-Mail-Marketing ein. Der Anbieter ist Rocket Science Group LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA. Der Anbieter verarbeitet Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Inhaltsdaten (z.B. Eingaben in Onlineformularen), Kontaktdaten (z.B. E-Mail-Adressen, Telefonnummern) und Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in den USA.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.
    </Paragraph>
    <Paragraph>
      Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Angemessenheitsbeschluss. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist gewährleistet, weil die EU-Kommission im Rahmen eines Angemessenheitsbeschluss nach Art. 45 Abs. 3 DSGVO beschlossen hat, dass das Drittland ein angemessenes Schutzniveau bietet.
    </Paragraph>
    <Paragraph>
      Die Daten werden gelöscht, wenn der Zweck ihrer Erhebung entfallen ist und keine Aufbewahrungspflichten entgegenstehen. Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter
      {' '}
      <a href="https://mailchimp.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
        https://mailchimp.com/legal/privacy/
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingThirdParties = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.12. Drittanbieter
    </Heading>
    <PrivacyPolicyProcessingGooglePlaces />
    <PrivacyPolicyProcessingGoogleAds />
    <PrivacyPolicyProcessingGoogleWebfonts />
    <PrivacyPolicyProcessingGoogleAnalytics />
    <PrivacyPolicyProcessingSentry />
    <PrivacyPolicyProcessingGoogleTag />
    <PrivacyPolicyProcessingCloudflare />
    <PrivacyPolicyProcessingGoogleCaptcha />
    <PrivacyPolicyProcessingMailchimp />
  </>
)

const PrivacyPolicyProcessingRecommendations = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.13. Personalisierte Empfehlungen
    </Heading>
    <Paragraph>
      Wir bieten personalisierte Empfehlungen auf der Grundlage von Nutzungsdaten der Nutzer an. Auf der Grundlage der bisherigen Interaktionen der Nutzer wird Werbung angezeigt, die den Interessen der Nutzer entspricht.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs.. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf Grundlage einer Einwilligung. Die betroffene Person kann ihre Einwilligung jederzeit widerrufen, indem sie sich z.B. über die in unserer Datenschutzerklärung angegebenen Kontaktdaten an uns wendet. Durch den Widerruf wird die Rechtmäßigkeit der Verarbeitung bis zum Widerruf nicht berührt.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessingModeration = () => (
  <>
    <Heading level='h3' className='label-20'>
      3.14. Moderation von Inhalten
    </Heading>
    <Paragraph>
      Wir moderieren Inhalte wie Chats und Anzeigen, um sicherzustellen, dass die Nutzer sich an die Nutzungsbedingungen halten.
    </Paragraph>
    <Paragraph>
      Die Rechtsgrundlage für die Verarbeitung ist unser berechtigtes Interesse (Art. 6 Abs. 1 S. 1 lit. f DSGVO), ein sicheres und respektvolles Umfeld zu gewährleisten und illegale Aktivitäten zu verhindern.
    </Paragraph>
  </>
)

const PrivacyPolicyProcessing = () => (
  <>
    <LegalSectionTitle
      label='3. Datenverarbeitung auf unserer Website'
    />
    <PrivacyPolicyProcessingNotice />
    <PrivacyPolicyProcessingInformative />
    <PrivacyPolicyProcessingHosting />
    <PrivacyPolicyProcessingContact />
    <PrivacyPolicyProcessingVacant />
    <PrivacyPolicyProcessingReviews />
    <PrivacyPolicyProcessingCustomer />
    <PrivacyPolicyProcessingSSO />
    <PrivacyPolicyProcessingOffer />
    <PrivacyPolicyProcessingPayment />
    <PrivacyPolicyProcessingCookies />
    <PrivacyPolicyProcessingThirdParties />
    <PrivacyPolicyProcessingRecommendations />
    <PrivacyPolicyProcessingModeration />
  </>
)

export default memo(PrivacyPolicyProcessing)
