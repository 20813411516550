import classNames from 'classnames'
import { ButtonHTMLAttributes, ForwardedRef, forwardRef, memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { TButtonType } from 'src/models/form.model'

import Loader from '../loader/Loader'
import SvgIcon, { ISvgIconProps } from '../svgIcon/SvgIcon'

import styles from './button.module.scss'

export enum RegularButtonTypes {
  main = 'main',
  transparentWithBorder = 'transparentWithBorder',
  blackNoBorder = 'blackNoBorder',
  transparentNoBorder = 'transparentNoBorder',
  transparentNoBorderLight = 'transparentNoBorderLight',
  moderation = 'moderation',
}

export enum RegularButtonSizes {
  medium = 'medium',
  large = 'large',
  small = 'small',
}

export interface IRegularButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  buttonType: RegularButtonTypes;
  onClick?: (event: React.MouseEvent) => void;
  linkTo?: string;
  label?: string;
  labelI18n?: string;
  children?: ReactNode;
  size?: RegularButtonSizes;
  leftIcon?: ISvgIconProps;
  rightIcon? : ISvgIconProps;
  className?: string;
  title?: string;
  type?: TButtonType;
  disabled?: boolean;
  submitting?: boolean;
  reloadDocument?: boolean;
}

const RegularButton = ({
  id,
  onClick,
  linkTo,
  buttonType,
  title,
  label,
  labelI18n,
  children,
  leftIcon,
  rightIcon,
  className,
  disabled = false,
  submitting,
  type = 'button',
  size = RegularButtonSizes.medium,
  reloadDocument,
}: IRegularButtonProps, ref?: ForwardedRef<HTMLButtonElement>) => {
  const { t } = useTranslation()
  const labelT = labelI18n || (label && t(label))
  const loaderSize = size === RegularButtonSizes.large ? 24 : 20

  const commonProps = {
    id,
    onClick,
    title: title || labelT,
    disabled: submitting || disabled,
    className: classNames(styles.btnWrapper, styles[buttonType], styles[size], className),
  }

  if (linkTo) {
    return (
      <Link {...commonProps} to={linkTo} reloadDocument={reloadDocument}>
        {labelT || children}
        {rightIcon && <SvgIcon {...rightIcon} />}
      </Link>
    )
  }

  return (
    <button {...commonProps} ref={ref} type={type} disabled={disabled}>
      {leftIcon && <SvgIcon {...leftIcon} />}
      {labelT || children}
      {submitting
        ? <Loader size={loaderSize} />
        : rightIcon && <SvgIcon {...rightIcon} />
      }
    </button>
  )
}

export default memo(forwardRef(RegularButton))
