import { I18N_MESSENGER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'

import { filterField } from '../chatsPanel/ChatsPanel'
import ChatFilterForm from './ChatFilterForm'
import FilterChip from './FilterChip'

import styles from './styles.module.scss'

const ChatFilter = () => (
  <div className={styles.filterMain}>
    <ChatFilterForm
      id={ID.messengerInputChatFilter}
      name={filterField}
      placeholder={`${I18N_MESSENGER}.searchMessage`}
    />
    <FilterChip />
  </div>
)

export default ChatFilter
