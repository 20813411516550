import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { I18N_POST } from '../constants/i18n.constants'

const PRICE_FORMAT_OPTIONS = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2 }

type TPrice = number | string | null | undefined

export const usePriceFormatter = (isShowZeroValue: boolean = false) => {
  const { i18n: { language }, t } = useTranslation()
  const formatter = useMemo(() => new Intl.NumberFormat(language, PRICE_FORMAT_OPTIONS), [language])

  return useCallback((price: TPrice) => {
    const priceAsNumber = Number(price)

    if (price === null || price === undefined || Number.isNaN(priceAsNumber)) {
      return price || ''
    }

    if (priceAsNumber === 0 && !isShowZeroValue) {
      return t(`${I18N_POST}.price.forFree`)
    }

    return formatter.format(priceAsNumber)
  }, [formatter, t, isShowZeroValue])
}

export const useGetDisplayPrice = () => {
  const priceFormatter = usePriceFormatter()

  return useCallback((value: TPrice): string | number => priceFormatter(value), [priceFormatter])
}
