import { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import ErrorBoundary from './components/errorBoundary/ErrorBoundary'
import MyOfferList from './components/myOffers/MyOfferList'
import YomeURL from './constants/navigate.constants'
import { PostListType } from './models/post.model'
import AboutRoute from './routes/about/AboutRoute'
import AdvertisingRoute from './routes/advertising/AdvertisingRoute'
import BaseLayout from './routes/BaseLayout'
import ForBusinessRoute from './routes/forBusiness/ForBusiness'
import HelpRoute from './routes/help/HelpRoute'
import HomeRoute from './routes/home/HomeRoute'
import CookiesPolicyRoute from './routes/legal/cookiesPolicy/CookiesPolicyRoute'
import ImprintRoute from './routes/legal/imprint/ImprintRoute'
import LegalLayout from './routes/legal/LegalLayout'
import PrivacyPolicyRoute from './routes/legal/privacyPolicy/PrivacyPolicyRoute'
import TermsAndConditionsRoute from './routes/legal/terms/TermsAndConditionsRoute'
import MessengerPrivateRoute from './routes/messenger/MessengerPrivateRoute'
import PrivateNewItemLayout from './routes/newItem/PrivateNewItemLayout'
import DraftOfferRoute from './routes/newItem/routes/DraftOfferRoute'
import EditItemRoute from './routes/newItem/routes/EditOfferRoute'
import NewItemRoute from './routes/newItem/routes/NewItemRoute'
import NotFoundRoute from './routes/notFound/NotFoundRoute'
import PostRoute from './routes/post/PostRoute'
import BusinessToolsRoute from './routes/profile/components/businessTools/BusinessTools'
import FavoritesLayout from './routes/profile/components/favorites/FavoritesLayout'
import ComparisonRoute from './routes/profile/components/favorites/routes/ComparisonRoute'
import FavoriteOffersRoute from './routes/profile/components/favorites/routes/FavoriteOffersRoute'
import FavoriteSellersRoute from './routes/profile/components/favorites/routes/FavoriteSellersRoute'
import SavedSearchRoute from './routes/profile/components/favorites/routes/SavedSearchRoute'
import FeedbackRoute from './routes/profile/components/FeedbackRoute'
import MyOffersLayout from './routes/profile/components/myOffers/MyOffersLayout'
import NotificationsRoute from './routes/profile/components/notifications/NotificationsRoute'
import PaidServicesRoute from './routes/profile/components/paidServices/PaidServices'
import RequestsRoute from './routes/profile/components/requests/RequestsRoute'
import SettingsRoute from './routes/profile/components/settings/SettingsRoute'
import WalletRoute from './routes/profile/components/wallet/Wallet'
import PrivateProfileLayout from './routes/profile/PrivateProfileLayout'
import PrivateProfileRoute from './routes/profile/PrivateProfileRoute'
import SafetyRoute from './routes/safety/SafetyRoute'
import SearchRoute from './routes/search/SearchRoute'
import SearchLayout from './routes/SearchLayout'
import UserPostListRoute from './routes/user/posts/UserPosts'
import UserLayout from './routes/user/UserLayout'
import { scrollWindowTop } from './utils/browser.utils'

const RouterRoutes = () => (
  <Routes>
    <Route path={YomeURL.mainPage} element={<BaseLayout hasSmallFooter />}>
      <Route path={YomeURL.messages} element={<MessengerPrivateRoute />} />
    </Route>

    <Route element={<BaseLayout hasSmallFooter isFooterNarrow />}>
      <Route path={YomeURL.help} element={<HelpRoute />} />
    </Route>

    <Route path={YomeURL.mainPage} element={<BaseLayout />}>
      <Route path={YomeURL.about} element={<AboutRoute />} />
      <Route path={YomeURL.forBusiness} element={<ForBusinessRoute />} />
      <Route path={YomeURL.advertising} element={<AdvertisingRoute />} />
      <Route path={YomeURL.legal} element={<LegalLayout />}>
        <Route index element={<Navigate to={YomeURL.terms} />} />
        <Route path={YomeURL.safety} element={<SafetyRoute />} />
        <Route path={YomeURL.terms} element={<TermsAndConditionsRoute />} />
        <Route path={YomeURL.imprint} element={<ImprintRoute />} />
        <Route path={YomeURL.privacy} element={<PrivacyPolicyRoute />} />
        <Route path={YomeURL.cookies} element={<CookiesPolicyRoute />} />
      </Route>

      <Route element={<SearchLayout />}>
        <Route index element={<HomeRoute />} />
        <Route path={YomeURL.search} element={<SearchRoute />} />
        <Route path={YomeURL.offer(':category', ':slug')} element={<PostRoute />} />
      </Route>

      <Route path={YomeURL.editOffer(':slug')} element={<EditItemRoute />} />

      <Route path={YomeURL.profilePage} element={<PrivateProfileRoute />} />
      <Route path={YomeURL.profilePage} element={<PrivateProfileLayout />}>
        <Route path={YomeURL.reviews} element={<FeedbackRoute />} />
        <Route path={YomeURL.settings} element={<SettingsRoute />} />
        <Route path={YomeURL.notifications} element={<NotificationsRoute />} />
        <Route path={YomeURL.wallet} element={<WalletRoute />} />
        <Route path={YomeURL.orders} element={<RequestsRoute />} />
        <Route path={YomeURL.business} element={<BusinessToolsRoute />} />
        <Route path={YomeURL.services} element={<PaidServicesRoute />} />

        <Route path={YomeURL.offers} element={<MyOffersLayout />}>
          <Route index
            element={<MyOfferList listType={PostListType.active} />}
          />
          <Route
            path={YomeURL.inactiveOffers}
            element={<MyOfferList listType={PostListType.inactive} />}
          />
          <Route
            path={YomeURL.draftOffers}
            element={<MyOfferList listType={PostListType.draft} />}
          />
          <Route
            path={YomeURL.archiveOffers}
            element={<MyOfferList listType={PostListType.archive} />}
          />
        </Route>

        <Route path={YomeURL.favorites} element={<FavoritesLayout />} >
          <Route index element={<FavoriteOffersRoute />} />
          <Route path={YomeURL.savedsearch} element={<SavedSearchRoute />} />
          <Route path={YomeURL.comparison} element={<ComparisonRoute />} />
          <Route path={YomeURL.favSellers} element={<FavoriteSellersRoute />} />
        </Route>
      </Route>

      <Route path={YomeURL.userPage(':userId')} element={<UserLayout />} >
        <Route
          index
          element={<UserPostListRoute listType={PostListType.active} />}
        />
        <Route
          path={YomeURL.userPageInactivePosts(':userId')}
          element={<UserPostListRoute listType={PostListType.inactive} />}
        />
      </Route>
      <Route path={YomeURL.newOffer} element={<PrivateNewItemLayout />}>
        <Route index element={<NewItemRoute />} />
        <Route path={YomeURL.editDraft(':publicId')} element={<DraftOfferRoute />} />
      </Route>
    </Route>

    {/** Not Found route should be the last one */}
    <Route element={<BaseLayout />}>
      <Route element={<SearchLayout />}>
        <Route path={YomeURL.notFound} element={<NotFoundRoute />} />
      </Route>
    </Route>
    {/** Not Found route should be the last one */}

  </Routes>
)

const RouterRoutesEnhanced = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    scrollWindowTop()
  }, [pathname])

  return <RouterRoutes />
}

const RouterWrapper = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <RouterRoutesEnhanced />
    </ErrorBoundary>
  </BrowserRouter>
)

export default RouterWrapper
