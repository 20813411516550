import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { I18N_GLOBAL } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { IUser } from 'src/models/user.model'
import { useCurrentUser } from 'src/services/currentUser.service'

// eslint-disable-next-line import/prefer-default-export
export const useGetAuthorizedUserData = () => {
  const { t } = useTranslation()
  const { data: currentUser } = useCurrentUser()

  return useCallback((user: IUser) => {
    if (!currentUser) {
      const userForGuests = `${I18N_GLOBAL}.user`
      const userName = `${t(userForGuests)} ${user.name.toLocaleUpperCase()}.`

      return { userName }
    }

    const isUserAuthor = currentUser?.id === user.id
    const userPageUrl = isUserAuthor ? YomeURL.offers : YomeURL.userPage(user.id)

    return { userName: user.name, userPageUrl }
  }, [t, currentUser])
}
