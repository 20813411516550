interface IYomeConfig {
  ENV: string;
  BASE_API_URL: string;
  MAP_API: string;
  MESSENGER_API_URL: string;
  AUTH_API_URL: string;
  IMAGE_API_URL: string;
  BUCKET_IMAGE: string;
  BUCKET_AVATARS: string;
  SENTRY_DSN: string;
  COOKIE_DOMAIN: string;
}

declare global {
  interface Window {
    YOME_CONFIG: IYomeConfig;
  }
}

const getConfigValue = (name: keyof IYomeConfig): string => {
  if (typeof window === 'undefined') {
    const ssrEnvVar = process.env[name]

    if (!ssrEnvVar) {
      throw new Error(`${name} ENV variable must be provided`)
    }

    return ssrEnvVar
  }

  const browserEnvVar = window.YOME_CONFIG[name]

  if (!browserEnvVar) {
    throw new Error(`${name} variable must be provided in config`)
  }

  return browserEnvVar.toString()
}

export default {
  env: getConfigValue('ENV'),
  mapApiKey: getConfigValue('MAP_API'),
  sentryDsn: getConfigValue('SENTRY_DSN'),
  baseApiUrl: getConfigValue('BASE_API_URL'),
  authApiUrl: getConfigValue('AUTH_API_URL'),
  imageApiUrl: getConfigValue('IMAGE_API_URL'),
  messengerApiUrl: getConfigValue('MESSENGER_API_URL'),
  bucket: {
    image: getConfigValue('BUCKET_IMAGE'),
    avatar: getConfigValue('BUCKET_AVATARS'),
  },
  cookieDomain: getConfigValue('COOKIE_DOMAIN'),
}
