import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { TDropdownSection } from 'src/models/dropdown.model'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import { getPathToCurrentCategory } from 'src/yome-categories-fields-config/utils/category.utils'

import {
  CATEGORY_QPARAM,
  IFilterValues,
  ISuggestions,
  ORIGINAL_QPARAM,
  SEARCH_CONTENT_QPARAM,
  SORT_QPARAM,
} from '../models/search.model'

const CATEGORY_PARENT_SEPARATOR = ' · '
export const MAX_CATEGORY_SEARCH_LEVELS = 4
export const DEFAULT_CATEGORY_PATH = [ANY_CATEGORY]

export const getSearchBarInitialCategory = (searchParams: URLSearchParams) => searchParams
  .get(CATEGORY_QPARAM) || ANY_CATEGORY

export const getSearchBarInitialCategoryPath = (category: string): string[] => {
  if (category === ANY_CATEGORY) {
    return DEFAULT_CATEGORY_PATH
  }

  return DEFAULT_CATEGORY_PATH.concat(getPathToCurrentCategory(category))
}

export const getSearchBarInitialValues = (searchParams: URLSearchParams) => ({
  [CATEGORY_QPARAM]: getSearchBarInitialCategory(searchParams),
  [SEARCH_CONTENT_QPARAM]: searchParams.get(SEARCH_CONTENT_QPARAM) || '',
  [SORT_QPARAM]: searchParams.get(SORT_QPARAM) || '',
  [ORIGINAL_QPARAM]: searchParams.get(ORIGINAL_QPARAM) || '',
})

const useCategoryParents = () => {
  const { t } = useTranslation()

  return useCallback((category: string) => {
    const path = getPathToCurrentCategory(category)

    if (path.length < 2) {
      return undefined
    }

    return path
      .slice(0, -1)
      .reverse()
      .map((parent) => t(`${I18N_CATEGORIES}.${parent}`))
      .join(CATEGORY_PARENT_SEPARATOR)
  }, [t])
}

export const useDropdownSuggestionsOptions = () => {
  const getCategoryParents = useCategoryParents()

  return useCallback((
    suggestions: ISuggestions,
    change: (name: string, value: any) => void,
    onSuggestionClick: () => void,
  ) => {
    const categoryOptions: TDropdownSection = suggestions.categories.reduce((acc, category) => ({
      ...acc,
      [`${I18N_CATEGORIES}.${category}`]: {
        icon: 'menu',
        onClick: () => {
          change(CATEGORY_QPARAM, category)
          change(SEARCH_CONTENT_QPARAM, '')
          onSuggestionClick()
        },
        info: getCategoryParents(category),
      },
    }), {})

    const inputOptions: TDropdownSection = suggestions.searchRequests.reduce((acc, suggestion) => ({
      ...acc,
      [suggestion]: {
        onClick: () => {
          change(SEARCH_CONTENT_QPARAM, suggestion)
          onSuggestionClick()
        },
      },
    }), {})

    return { ...categoryOptions, ...inputOptions }
  }, [getCategoryParents])
}

export const getSavedSearchTitle = (searchParams: IFilterValues) => (
  searchParams.searchContent || `${I18N_CATEGORIES}.${searchParams.category}`
)
