import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Loader from 'react-spinners/CircleLoader'

import IconButton from 'src/components/button/IconButton'
import Heading from 'src/components/heading/Heading'
import { I18N_PROFILE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useCurrentUser } from 'src/services/currentUser.service'

import ProfileBadgesSettings from '../profileBadges/ProfileBadges'
import GeneralSettings from './generalSettings/GeneralSettings'
import NotificationsSettings from './notifications/NotificationsSettings'
import PersonalInfoSettings from './personalInfoSettings/PersonalInfoSettings'

import styles from './settings.module.scss'

const SettingsRoute = () => {
  const { t } = useTranslation()
  const { data: currentUser, isLoading: isProfileLoading, isValidating: isProfileValidating } = useCurrentUser()
  const { isDesktop } = useViewport()

  if (isProfileLoading) {
    return (
      <Loader />
    )
  }

  return (
    <div>
      <Heading level='h1' className={classNames(styles.title, 'desktop-h4 mobile-h4')}>
        {!isDesktop && (
          <IconButton
            linkTo={YomeURL.profilePage}
            iconName='back-button'
            id={ID.profileSettingBackButton}
          />
        )}
        {t(`${I18N_PROFILE}.settings.title`)}
      </Heading>
      {!isProfileValidating && (
        <div className={styles.settingSections}>
          <PersonalInfoSettings profile={currentUser!}/>
          <ProfileBadgesSettings user={currentUser!}/>
          {/*  <AccountVerificationSettings /> */}
          <GeneralSettings/>
          <NotificationsSettings/>
        </div>
      )}
    </div>
  )
}

export default SettingsRoute
