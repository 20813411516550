import { memo } from 'react'
import { Suggestions } from 'use-places-autocomplete'

import Suggestion from './Suggestion'

import mapStyles from './map.module.scss'

interface ISuggestionListProps {
  suggestions: Suggestions;
  userAddressHistory: string[];
  onSelect: (address: string) => void;
  inputValue: string | undefined;
  hasIcon?: boolean;
}

const SuggestionList = ({
  onSelect,
  suggestions: { status, data },
  userAddressHistory,
  inputValue,
}: ISuggestionListProps) => (status !== 'OK' ? null : (
  <ul className={mapStyles.autocomletes}>
    {
      userAddressHistory.map((address) => (
        <Suggestion
          key={`history-${address}`}
          addressToSearch={address}
          fullAddress={address}
          inputValue={inputValue}
          onSelect={onSelect}
          hasIcon
          isDisplayHistory={data.length === 1}
        />
      ))
    }
    {
      data.map(({ description, structured_formatting: formatting }) => (
        <Suggestion
          key={description}
          addressToSearch={formatting.main_text}
          secondaryPartAddress={formatting.secondary_text}
          fullAddress={description}
          inputValue={inputValue}
          onSelect={onSelect}
        />
      ))
    }
  </ul >
))

export default memo(SuggestionList)
