import { Link } from 'react-router-dom'

import BlockedStatus from 'src/components/blockedStatus/BlockedStatus'
import YomeURL from 'src/constants/navigate.constants'
import { IChatUser } from 'src/models/messenger.model'

import styles from './styles.module.scss'

interface IChatHeaderUserName {
  receiver: IChatUser;
}

const ChatHeaderUserName: React.FC<IChatHeaderUserName> = ({ receiver }) => {
  const { id, name, isBlocked } = receiver

  return (
    <Link to={YomeURL.userPage(id)} className={styles.userInfoWrapper}>
      {name}
      {isBlocked && <BlockedStatus />}
    </Link>
  )
}

export default ChatHeaderUserName
