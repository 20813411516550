import { useEffect } from 'react'
import useSWR from 'swr'

import { IUserPostList } from 'src/models/post.model'

import { IUser } from '../models/user.model'
import { sendGet } from '../utils/network.utils'
import { useCurrentUser } from './currentUser.service'

const DEFAULT_USER_POST_LIST_DATA = { active: [], inactive: [] }

const getUser = (path: string): Promise<IUser> => sendGet(path)

const getUserPostList = (path: string): Promise<IUserPostList> => sendGet(path)

const useGetUser = (url: string) => useSWR<IUser>(
  url,
  getUser,
)

// TODO it caching when key by useSWR is the same ( post/${userId} )
const useGetUserPostList = (key: string) => {
  const { data: currentUser } = useCurrentUser()

  const isPhoneVerified = !!currentUser?.isPhoneVerified

  const { data, isLoading, mutate } = useSWR<IUserPostList>(
    key,
    isPhoneVerified ? getUserPostList : null,
    {
      fallbackData: DEFAULT_USER_POST_LIST_DATA,
    },
  )

  useEffect(() => {
    if (isPhoneVerified) {
      mutate()
    }
  }, [currentUser, isPhoneVerified, mutate])

  return { data, isLoading }
}

export {
  getUser,
  useGetUser,
  useGetUserPostList,
}
