import { Link } from 'react-router-dom'

import UserAvatar from 'src/components/avatar/UserAvatar'
import DateComponent from 'src/components/date/DateComponent'
import YomeURL from 'src/constants/navigate.constants'
import { DateFormatType } from 'src/models/date.model'
import { IChatMessage } from 'src/models/messenger.model'
import { AvatarSizes } from 'src/models/upload.model'

import styles from './styles.module.scss'

const UserMessage = ({ message } : { message: IChatMessage }) => (
  <div className={styles.messageUserMain}>
    <div className={styles.messageWithDate}>
      <DateComponent isoTimestamp={message.createdAt} type={DateFormatType.time} />
      <div className={styles.userMessageText}>
        {message.content}
      </div>
    </div>
    <Link to={YomeURL.profilePage} className={styles.avatarWrapper}>
      <UserAvatar
        userId={message.author.id}
        avatarSize={AvatarSizes.xtiny}
        image={message.author.avatar}
        firstLetter={message.author.name.charAt(0)}
      />
    </Link>
  </div>
)

export default UserMessage
