import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_MESSENGER } from 'src/constants/i18n.constants'

import styles from './styles.module.scss'

const NoMessage = () => (
  <EmptyStateMessage
    icon='work-messages'
    text={`${I18N_MESSENGER}.noChatHistory`}
    className={styles.main}
  />
)

export default NoMessage
