import { memo } from 'react'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_GLOBAL, I18N_USER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { UserBadgesEnum } from 'src/models/badge.models'
import { IModalWithClose } from 'src/models/modal.model'

import InfoModal from '../infoModal/InfoModal'

import styles from './badgesModals.module.scss'

const PhoneVerifiedModal = ({ handleClose }: IModalWithClose) => {
  const footerButton = (
    <RegularButton
      id={ID.badgeModalCloseButton}
      buttonType={RegularButtonTypes.blackNoBorder}
      onClick={handleClose}
      label={`${I18N_GLOBAL}.gotIt`}
    />
  )

  return (
    <InfoModal
      title={`${I18N_USER}.${UserBadgesEnum.phoneVerified}.title`}
      topIcon='check-verified'
      modalFooter={footerButton}
      className={styles.modalContainer}
    >
      <Paragraph className='bodySizeL'>
        {`${I18N_USER}.${UserBadgesEnum.phoneVerified}.description`}
      </Paragraph>
    </InfoModal>
  )
}

export default memo(PhoneVerifiedModal)
