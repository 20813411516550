import { CountryIsoCode } from 'src/yome-categories-fields-config/models/fields.model'

export const enum VerifyPhoneModals {
  baseVerifyPhone = 'baseVerifyPhone',
  enterCode = 'enterCode',
  verifySuccess = 'verifySuccess',
  codeFailed = 'codeFailed',
  limitReached = 'limitReached',
  phoneInUse = 'phoneInUse',
}

export const enum PhoneCodeOptions {
  sms = 'sms',
  call = 'call',
}

export interface IPhoneNumber {
  phoneCountryCode: CountryIsoCode;
  phoneBody: string;
}

export interface IVerifyPhoneModalProps {
  setCurrentModal: (modal: VerifyPhoneModals) => void;
  phoneNumber: IPhoneNumber;
  setPhoneNumber: (phoneNumber: IPhoneNumber) => void;
  handleClose?: () => void;
  codeType: PhoneCodeOptions;
  setCodeType: (codeType: PhoneCodeOptions) => void;
}
