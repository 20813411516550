import { TFunction } from 'i18next'
import { pick } from 'lodash-es'

import { EURO } from 'src/constants/general.constants'
import { I18N_POST_DATA, I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import {
  ARRAY_FILTERS,
  CATEGORY_QPARAM,
  IFilterValues,
  PRICE_FIELDS,
  SEARCH_BAR_FIELDS,
  TFieldKey,
  TSingleFilterValues,
} from 'src/models/search.model'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import {
  PRICE_FILTER_NAMES,
  searchFilterNames,
  searchFiltersStructure,
} from 'src/yome-categories-fields-config/fields-config/searchFilterFields'
import { IFieldConfig } from 'src/yome-categories-fields-config/models/fields.model'
import { getMainCategoryForChild } from 'src/yome-categories-fields-config/utils/category.utils'

import { getInputValueFieldName } from './form.utils'
import { getSearchBarInitialValues } from './search.utils'

export interface IChipId {
  fieldKey: TFieldKey;
  fieldValue: string | number | boolean;
}

export interface IChipValue {
  chipValue: string;
  chipId: IChipId;
}

type TFilterValueAfterRemoval = string[] | boolean | null | {}

export const removeFilterValueByChipId = (
  filterValues: TSingleFilterValues,
  chipId: IChipId,
): TFilterValueAfterRemoval => {
  const chipToDeleteValue = chipId.fieldValue

  // filter with options
  if (Array.isArray(filterValues)) {
    const restArray = filterValues.filter((value) => value !== chipToDeleteValue)

    return restArray.length ? restArray : null
  }

  // radiobutton filter or numbers - just remove field value
  if (typeof filterValues === 'string' || typeof filterValues === 'number') {
    return null
  }

  // toggle / checkbox
  if (typeof filterValues === 'boolean') {
    return !filterValues
  }

  return {}
}

// TODO: get rid of the function. Fields shouldn't be calculated on the fly
const getFieldNamesAllLevel = (categoryFields: IFieldConfig[]): string[] => {
  const categoryFieldsNames = categoryFields.map((field) => {
    if (!field.fields) {
      return field.name
    }

    const subFields = Object.values(field.fields)

    return getFieldNamesAllLevel(subFields)
  })

  return categoryFieldsNames.flat()
}

interface IFiltersFromQuery extends Record<string, TSingleFilterValues>, Partial<IFilterValues> {}

export const getInitialFilters = (searchParams: URLSearchParams, categoryFields: IFieldConfig[]): IFiltersFromQuery => {
  const initVal: IFiltersFromQuery = getSearchBarInitialValues(searchParams)
  const categoryFieldsNames = getFieldNamesAllLevel(categoryFields)

  searchParams.forEach((val, key) => {
    // check if current key has corresponding field name which starts as key
    if (!categoryFieldsNames.some((fieldName) => key.startsWith(fieldName))) {
      return
    }

    // check if given filter is array value
    if (ARRAY_FILTERS.includes(key)) {
      if (val === '') {
        return
      }

      initVal[key] = val.split(',')

      return
    }

    if (PRICE_FILTER_NAMES.includes(key)) {
      initVal[getInputValueFieldName(key)] = val
    }

    initVal[key] = val
  })

  return initVal
}

export const getFilterSearchParams = (formValues: IFilterValues) => {
  const { category } = formValues
  const newSearchParams = new URLSearchParams()

  if (!category) {
    return newSearchParams
  }

  const mainCategory = getMainCategoryForChild(category)
  const filterNames = searchFilterNames[mainCategory]

  Object.entries(formValues).forEach(
    ([formKey, formValue]) => {
      const isFilter = filterNames.includes(formKey)
      const isEmptyArray = Array.isArray(formValue) && !formValue.length

      if ((!formValue && formValue !== 0) || !isFilter || isEmptyArray) {
        newSearchParams.delete(formKey)

        return
      }

      newSearchParams.set(formKey, formValue)
    },
  )

  return newSearchParams
}

export const getFiltersFieldsStructure = (category: string | null) => {
  const mainCategory = getMainCategoryForChild(category)

  return searchFiltersStructure[mainCategory]
}

export const extractSearchBarValuesFromFilters = (values: IFilterValues) => {
  const searchBarValues: Record<string, any> = {}

  Object.entries(values).forEach(([filterKey, value]) => {
    if (SEARCH_BAR_FIELDS.includes(filterKey)) {
      searchBarValues[filterKey] = value
    }
  })

  return searchBarValues
}

export const getFilterValue = (name: string, filters: IFiltersFromQuery) => {
  if (PRICE_FIELDS.includes(name)) {
    return pick(filters, PRICE_FIELDS)
  }

  return { [name]: filters[name] }
}

export const isAppliedFilter = (name: string, filters: IFiltersFromQuery) => {
  if (name === CATEGORY_QPARAM && filters[CATEGORY_QPARAM] === ANY_CATEGORY) {
    return false
  }

  const filterCurrentValue = getFilterValue(name, filters)

  // check if there is any value for given filter
  return Object.values(filterCurrentValue).some((value) => value)
}

export const getChipLabel = (name: string, t: TFunction, filters: IFiltersFromQuery) => {
  if (!isAppliedFilter(name, filters) || name === CATEGORY_QPARAM) {
    return `${I18N_SEARCH_FILTER}.${name}.chipKey`
  }

  const filterCurrentValue = getFilterValue(name, filters)

  const labels = Object.entries(filterCurrentValue).map(([key, value]) => {
    const isArrayValue = Array.isArray(value)

    if (isArrayValue) {
      const keyLabel = t(`${I18N_SEARCH_FILTER}.${key}.chipKey`)

      if (value.length > 1) {
        return `${keyLabel}: ${value.length}`
      }

      if (name === 'descriptionSearch') {
        return `${keyLabel}: ${value}`
      }
    }

    const isNumberValue = !Number.isNaN(Number(value))

    // price
    if (isNumberValue) {
      const priceKeys = Object.keys(filterCurrentValue)
      const minKey = priceKeys.find((k) => k.includes('Min'))
      const maxKey = priceKeys.find((k) => k.includes('Max'))

      // if both min and max
      if (minKey && maxKey) {
        // return full value once
        if (key === minKey) {
          return `${filterCurrentValue[minKey]}${EURO}-${filterCurrentValue[maxKey]}${EURO}`
        }

        return ''
      }

      const keyLabel = t(`${I18N_SEARCH_FILTER}.${key}`)

      return `${keyLabel} ${value}${EURO}`
    }

    const isBooleanValue = typeof value === 'boolean' || value === 'true' || value === 'false'

    if (isBooleanValue) {
      return t(`${I18N_SEARCH_FILTER}.${key}`)
    }

    return t(`${I18N_POST_DATA}.${key}.${value}`)
  })

  return labels.filter((l) => l !== '').join(', ')
}

// sort fields, so first would be elements with appied filters
export const sortFiltersByApplied = (field1: IFieldConfig, field2: IFieldConfig, initialValues: IFiltersFromQuery) => {
  const appliedFilter1 = isAppliedFilter(field1.name, initialValues)
  const appliedFilter2 = isAppliedFilter(field2.name, initialValues)

  if (appliedFilter2 && !appliedFilter1) return 1
  if (appliedFilter1 && !appliedFilter2) return -1

  return 0
}
