import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { Form, useFormState } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormEmailField, FormInput, FormPassword } from 'src/components/form'
import ValidationInfo from 'src/components/validationInfo/ValidationInfo'
import { emptyFunction } from 'src/constants/function.constants'
import { I18N_AUTH } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { useLoginUserContext } from 'src/contexts/loginUser.context'
import { useViewport } from 'src/contexts/viewportContext'
import { useHashUrl } from 'src/hooks/router.hooks'
import { BaseAuthModalProps, SignupFormFields } from 'src/models/auth.model'
import { IModalWithClose } from 'src/models/modal.model'
import { EmailUrlAction } from 'src/models/url.model'
import { IUser } from 'src/models/user.model'
import { checkUserPresence, signup } from 'src/services/auth.service'
import { validatePassword } from 'src/utils/auth.utils'
import { captureErrorAndShowToast, SubmitFormError } from 'src/utils/error.utils'
import { useToggle } from 'src/utils/hooks.utils'

import InfoModal from '../infoModal/InfoModal'
import AuthModal, { IAuthModalBase } from './AuthModal'
import SocialSignin from './SocialSignin'
import UserExistsModal from './UserExistsModal'

import styles from './auth.module.scss'

interface ICreateAccount extends IModalWithClose {
  goToNextModal: () => void;
  setUser: (user: IUser) => void;
}

const CreateAccount = ({ goToNextModal, setUser, handleClose } : ICreateAccount) => {
  const { t } = useTranslation()

  const { isDesktop } = useViewport()

  const { values } = useFormState<SignupFormFields>()

  const { setLoginUser } = useLoginUserContext()

  const [error, setError] = useState<string | undefined>(undefined)

  const checkUserAndGoNext = async () => {
    try {
      const user = await checkUserPresence(values)

      // if user present - don't go to next modal, show user is present
      if (user) {
        setUser(user)
        setLoginUser(user)

        return
      }

      goToNextModal()
    } catch (err) {
      // in case of Joi error - error returns in email property
      if (err instanceof SubmitFormError) {
        setError(err.errorObject.email)

        return
      }

      if (err instanceof Error) {
        captureErrorAndShowToast(err)
      }
    }
  }

  return (
    <InfoModal
      title={`${I18N_AUTH}.title.createAccount`}
      handleClose={handleClose}
    >
      <FormEmailField
        name='email'
        id={ID.signupModalInputEmail}
        placeholder={`${I18N_AUTH}.placeholder.email`}
        autocomplete='username'
        fieldWrapperProps={{
          name: 'email',
          customError: error,
        }}
        autoFocus
      />
      <RegularButton
        label={`${I18N_AUTH}.button.createAccount`}
        onClick={checkUserAndGoNext}
        id={ID.signupModalButtonSignup}
        buttonType={RegularButtonTypes.main}
        disabled={!values.email}
      />
      <p className={classNames('caption-14', styles.text)}>
        <Trans i18nKey={`${I18N_AUTH}.termsAndPolicy`}>
          0
          <Link to={YomeURL.terms}>
            1
          </Link>
          <Link to={YomeURL.privacy}>
            2
          </Link>
        </Trans>
      </p>

      <div className={styles.footer}>
        <SocialSignin/>
        <div className={styles.toLogin}>
          <p className='bodySizeM'>
            {t(`${I18N_AUTH}.areadyHaveAccount`)}
          </p>
          <RegularButton
            id={ID.signupModalLinkToLoginModal}
            label={`${I18N_AUTH}.logIn`}
            linkTo={useHashUrl(YomeHash.auth)}
            buttonType={
              isDesktop ? RegularButtonTypes.transparentNoBorderLight : RegularButtonTypes.transparentWithBorder
            }
          />
        </div>
      </div>
    </InfoModal>
  )
}

const Welcome = ({ handleClose }: IModalWithClose) => {
  const { t } = useTranslation()

  const { values } = useFormState<SignupFormFields>()

  const { passwordValidationResult, allChecksPassed } = validatePassword(values.password)

  const footerButton = (
    <RegularButton
      disabled={!allChecksPassed}
      label={`${I18N_AUTH}.button.continue`}
      type='submit'
      onClick={emptyFunction}
      id={ID.signupModalButtonSignup}
      buttonType={RegularButtonTypes.main}
    />
  )

  return (
    <InfoModal
      title={`${I18N_AUTH}.title.welcome`}
      topIcon='hand-hello'
      modalFooter={footerButton}
      subtitle={t(`${I18N_AUTH}.welcome.moto`)}
      handleClose={handleClose}
    >
      <p className={classNames(styles.secondaryText, 'bodySizeL')}>
        {t(`${I18N_AUTH}.welcome.text`)}
      </p>
      <input
        name='email'
        autoComplete='username'
        value={values.email}
        style={{ display: 'none' }}
      />
      <FormInput
        id={ID.signupModalInputUsername}
        name='username'
        placeholder={`${I18N_AUTH}.placeholder.username`}
        autocomplete='off'
        fieldWrapperProps={{
          name: 'username',
          info: `${I18N_AUTH}.info.username`,
          label: `${I18N_AUTH}.label.username`,
        }}
      />
      <div className={styles.passwordBlock}>
        <FormPassword
          name='password'
          id={ID.signupModalInputPassword}
          placeholder={`${I18N_AUTH}.placeholder.password`}
          fieldWrapperProps={{
            name: 'password',
            info: `${I18N_AUTH}.info.password`,
          }}
        />
        { values.password && <ValidationInfo checkPoints={passwordValidationResult}/>}
      </div>
    </InfoModal>
  )
}

const SignupModal = React.memo(({ onSuccessSubmit, handleClose }: BaseAuthModalProps) => {
  const [showFirst, toggleShowFirst] = useToggle(true)
  const [user, setUser] = useState<IUser | null>(null)

  const goToSignup = useCallback(() => setUser(null), [setUser])

  if (user) {
    return <UserExistsModal user={user} goToSignup={goToSignup} handleClose={handleClose}/>
  }

  return (
    <Form
      onSubmit={signup}
      render={function SignupFormRender({ values, handleSubmit, submitSucceeded }) {
        React.useEffect(() => {
          if (submitSucceeded) {
            onSuccessSubmit(EmailUrlAction.verify, values.email)
          }
        }, [submitSucceeded, values.email])

        return (
          <form onSubmit={handleSubmit} className={styles.formContainter}>
            { showFirst ? (
              <CreateAccount goToNextModal={toggleShowFirst} setUser={setUser} handleClose={handleClose}/>
            ) : (
              <Welcome handleClose={handleClose}/>
            )}
          </form>
        )
      }}
    />
  )
})

const AuthSignupModal = React.memo(({ handleClose }: IAuthModalBase) => (
  <AuthModal handleClose={handleClose} SingleAuthModal={SignupModal}/>
))

export default AuthSignupModal
