import { useCallback } from 'react'
import { useForm } from 'react-final-form'

import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { ID, concatId } from 'src/constants/id.constants'
import { CATEGORY_QPARAM } from 'src/models/search.model'
import MenuPoint, { MenuPointSize } from 'src/components/menu/MenuPoint'
import { useViewport } from 'src/contexts/viewportContext'
import SubcategoriesList from './SubcategoriesList'

import styles from './category.module.scss'

interface ICategoryItem {
  category: string;
  currentCategory: string;
  showChildren: boolean;
}

const CategoryItem = ({ category, currentCategory, showChildren }: ICategoryItem) => {
  const { change } = useForm()

  const { isDesktop } = useViewport()
  const menuPointSize = isDesktop ? MenuPointSize.SMALL : MenuPointSize.LARGE

  const handleCategoryClick = useCallback((newCategory: string) => {
    change(CATEGORY_QPARAM, newCategory)
  }, [change])

  return (
    <div className={styles.categoryItemstrytr}>
      <MenuPoint
        id={concatId(ID.searchFilterSelectCategory, category)}
        label={`${I18N_CATEGORIES}.${category}`}
        onClick={() => handleCategoryClick(category)}
        icon={'chevron-down'}
        isActive={category === currentCategory}
        size={menuPointSize}
        iconRotate
      />
      {showChildren && (
        <SubcategoriesList
          parentCategory={category}
          currentCategory={currentCategory}
          onClick={handleCategoryClick}
          size={menuPointSize}
        />
      )}
    </div>
  )
}

export default CategoryItem
