import React from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { useViewport } from 'src/contexts/viewportContext'
import { I18N_AUTH } from 'src/constants/i18n.constants'
import { TOAuthProviders, oAuthStrategyToLink } from 'src/models/auth.model'
import { ID } from 'src/constants/id.constants'
import { YomeHash } from 'src/constants/navigate.constants'
import Paragraph from 'src/components/paragraph/Paragraph'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { useHashUrl } from 'src/hooks/router.hooks'
import { SocialLoginUrlAction } from 'src/models/url.model'
import AuthLoginModal from '../authModals/LoginModal'
import { IAuthModalBase } from '../authModals/AuthModal'
import SmthWentWrongModal from './SmthWentWrongModal'

import styles from './smthWentWrongModal.module.scss'

const SocialFailureModal = React.memo((
  { handleClose }: IAuthModalBase,
) => {
  const { isDesktop } = useViewport()

  const [searchParams] = useSearchParams()
  const strategy = searchParams.get(SocialLoginUrlAction.socialFailure) as TOAuthProviders

  const authUrl = useHashUrl(YomeHash.auth)
  const signUpUrl = useHashUrl(YomeHash.signup)

  const { pathname } = useLocation()

  if (!Object.keys(oAuthStrategyToLink).includes(strategy || '')) {
    return <AuthLoginModal handleClose={handleClose}/>
  }

  const retryButtonProps = {
    linkTo: `${oAuthStrategyToLink[strategy]}?from=${encodeURIComponent(pathname)}`,
    reloadDocument: true,
    label: `${I18N_AUTH}.retry`,
    id: ID.loginModalLinkToSocialLogin,
  }

  const backToLoginButtonProps = {
    linkTo: authUrl,
    label: `${I18N_AUTH}.loginWithEmail`,
    id: ID.loginModalLinkToLogin,
  }

  const footerReset = (
    <div className={styles.footerReset}>
      <Paragraph className='bodySizeM'>
        {`${I18N_AUTH}.dontHaveAccount`}
      </Paragraph>
      <RegularButton
        id={ID.signupModalLinkToLoginModal}
        label={`${I18N_AUTH}.button.createAccount`}
        linkTo={signUpUrl}
        buttonType={
          isDesktop ? RegularButtonTypes.transparentNoBorderLight : RegularButtonTypes.transparentWithBorder
        }
        className={styles.buttonFullWidth}
      />
    </div>
  )

  return (
    <SmthWentWrongModal
      title={`${I18N_AUTH}.socialLoginFailed.title`}
      text={`${I18N_AUTH}.socialLoginFailed.text`}
      mainButtonProps={retryButtonProps}
      secondButtonProps={backToLoginButtonProps}
      handleClose={handleClose}
      emptyStateIcon='something-went-wrong-3'
      bottomEl={footerReset}
    />
  )
})

export default SocialFailureModal
