import { memo, ReactNode } from 'react'
import useSWR from 'swr'

import { captureException } from 'src/utils/browser.utils'

import NotFoundRoute from 'src/routes/notFound/NotFoundRoute'
import { NotFoundError } from 'src/utils/error.utils'
import SomethingWentWrong from '../somethingWentWrong/SomethingWentWrong'

export interface ISWRErrorBoundaryProps {
  children: ReactNode;
  swrKey: string;
  emptyStateClassName?: string;
}

const SWRErrorBoundary = ({ children, swrKey, emptyStateClassName }: ISWRErrorBoundaryProps) => {
  const { error } = useSWR(swrKey)

  if (error instanceof NotFoundError) {
    return <NotFoundRoute />
  }

  if (error) {
    captureException(error)

    return <SomethingWentWrong className={emptyStateClassName} />
  }

  return (
    <>
      {children}
    </>
  )
}

export default memo(SWRErrorBoundary)
