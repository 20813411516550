import classNames from 'classnames'
import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import FieldWrapper, { IFieldWrapperProps } from './FieldWrapper'

import styles from './form.module.scss'

interface IFormVerificationCodeInputProps {
  fieldWrapperProps?: IFieldWrapperProps;
  id: string;
  name: string;
  placeholder?: string;
  autoFocus?: boolean;
}

export const FormVerificationCodeInput = React.memo(({
  fieldWrapperProps,
  id,
  name,
  placeholder = '',
  autoFocus,
}: IFormVerificationCodeInputProps) => {
  const { t } = useTranslation()

  return (
    <Field name={name}>
      {function renderFormVerifyEmailCode({ input }) {
        return (
          <FieldWrapper {...fieldWrapperProps} name={name}>
            <input
              {...input}
              id={id}
              placeholder={t(placeholder)}
              className={classNames(styles.fieldInput)}
              title={name}
              autoFocus={autoFocus}
            />
          </FieldWrapper>
        )
      }}
    </Field>
  )
})

export default FormVerificationCodeInput
