import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_FOR_BUSINESS } from 'src/constants/i18n.constants'

import styles from './sectionHeader.module.scss'

interface ISectionHeader {
  label: string;
  text: string;
}

const SectionHeader = ({ label, text }: ISectionHeader) => (
  <div className={styles.main}>
    <Heading
      level='h3'
      label={`${I18N_FOR_BUSINESS}.${label}`}
      className={'desktop-h2 mobile-h3'}
    />
    <Paragraph
      text={`${I18N_FOR_BUSINESS}.${text}`}
      className={classNames('bodySizeL', styles.subheading)}
    />
  </div>
)

export default SectionHeader
