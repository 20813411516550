export const KEYBOARD_ENTER_KEY = 'Enter'
export const KEYBOARD_ESC_KEY = 'Escape'

export const POST_EXPIRATION_TERM = 30

export const POINT = '•'

export const ELLIPSIS = '•••'

export const EURO = '€'

export const UNDERSCORE_REGEXP = /_/g

export const ONE_MB = 1024 * 1024

export const EMPTY_STRING = ''

// Time constants
export const ONE_SEC = 1000
export const ONE_MIN = 60 * 1000
export const HALF_MIN = ONE_MIN / 2
export const ONE_HOUR = 60 * ONE_MIN
export const ONE_DAY = 24 * ONE_HOUR
