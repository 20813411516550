import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { savedLanguage } from './models/language.model'
import deTranslations from './translations/de.json'
import enTranslations from './translations/en.json'
import ruTranslations from './translations/ru.json'

const choosenLang = savedLanguage || 'en'

i18n.use(initReactI18next).init({
  resources: {
    ru: {
      translation: ruTranslations,
    },
    en: {
      translation: enTranslations,
    },
    de: {
      translation: deTranslations,
    },
  },
  lng: choosenLang,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
