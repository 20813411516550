import React from 'react'
import useSWR from 'swr'

import SWRkeys from 'src/constants/swr.constants'
import IconLink from 'src/components/button/IconLink'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'

import styles from './headerIconLinks.module.scss'

const iconLinksData = [
  {
    id: ID.headerLinkToFavorite,
    to: YomeURL.favorites,
    icon: 'heart',
  },
  {
    id: ID.headerLinkToNotifications,
    to: YomeURL.notifications,
    icon: 'bell',
  },
  {
    id: ID.headerLinkToMessenger,
    to: YomeURL.messages,
    icon: 'message',
  },
]

const HeaderIconLinks = () => {
  const { data: hasMessangerUnreadChats } = useSWR<boolean>(SWRkeys.hasMessangerUnreadChats)

  return (
    <div className={styles.main}>
      {iconLinksData.map((link) => (
        <IconLink
          key={link.id}
          {...link}
          hasNotification={link.id === ID.headerLinkToMessenger ? hasMessangerUnreadChats : undefined}
          iconProps={{ className: styles.iconLink, name: link.icon }}
        />
      ))}
    </div>
  )
}

export default React.memo(HeaderIconLinks)
