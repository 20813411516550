import classNames from 'classnames'
import { memo } from 'react'

import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { scrollWindowTop } from 'src/utils/browser.utils'

import RoundedControls from '../roundedControls/RoundedControls'

import styles from './scrollUp.module.scss'

interface IScrollUpProps {
  contentHeight: number;
  className?: string;
}

const ScrollUp = ({ contentHeight, className }: IScrollUpProps) => {
  const { isDesktop } = useViewport()
  const hasScrollUp = contentHeight > window.outerHeight

  if (!(hasScrollUp && isDesktop)) {
    return null
  }

  return (
    <div className={classNames(styles.main, className)}>
      <RoundedControls iconName='chevron-up' onClick={scrollWindowTop} id={ID.pageScrollTop} />
    </div>
  )
}

export default memo(ScrollUp)
