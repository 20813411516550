import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import DateComponent from 'src/components/date/DateComponent'
import Paragraph from 'src/components/paragraph/Paragraph'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_MESSENGER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { DateFormatType } from 'src/models/date.model'
import { IWarningMessage } from 'src/models/messenger.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { sendScanWarningFeedback } from 'src/services/messenger.service'

import styles from './styles.module.scss'

interface IWarningMessageProps {
  message: IWarningMessage;
}

const WarningMessage = ({ message }: IWarningMessageProps) => {
  const { t } = useTranslation()

  const currentUser = useCurrentUser()

  const [isDisplayFeedback, setIsDisplayFeedback] = useState<boolean>(!!message.scanContent.isDisplayFeedback)

  const sendFeedback = useCallback((isHelpful: boolean) => () => {
    sendScanWarningFeedback(message.chatId, message.scanContent.scanWarningId, isHelpful)

    const toastText = isHelpful ? `${I18N_MESSENGER}.helpfulFeedback` : `${I18N_MESSENGER}.notHelpfulFeedback`
    const toastIcon = isHelpful ? 'thumbs-up' : 'check-in-circle'
    toast.success(t(toastText), { icon: <SvgIcon name={toastIcon} size={20} /> })

    setIsDisplayFeedback(false)
  }, [message.chatId, message.scanContent.scanWarningId, t])

  return (
    <div className={classNames(styles.messageStrangerMain, styles.warningMain)}>
      <div className={styles.yomeAvatar} >
        <SvgIcon name='logo' currentColor='fill' />
      </div>
      <div className={styles.messageWithDate}>
        <div className={styles.warningMessagesContainer}>
          <div className={classNames(styles.strangerMessageText, styles.warningText)}>
            {`Hello, ${currentUser.data?.name}!\nThese messages from Yome are only visible to you.`}
          </div>
          <div className={classNames(styles.strangerMessageText, styles.warningText)}>
            <Paragraph>
              <span style={{ fontFamily: 'Arial' }}>
                ⚠️
              </span>
              &nbsp; We’ve detected suspicious activity in this chat.
            </Paragraph>
            <Paragraph>
              {message.scanContent.resume}
            </Paragraph>
            <div>
              <Paragraph className='label-14'>
                To protect yourself:
              </Paragraph>
              <ul>
                {message.scanContent.recommendations.map((reccomendation) => (
                  <li key={reccomendation}>
                    🚫 &nbsp;
                    {reccomendation}
                  </li>
                ))}
              </ul>
            </div>
            <Paragraph>
              If you suspect something is wrong, please contact our Support team right away.
            </Paragraph>
            <div className={styles.warningButtonsContainer}>
              <RegularButton
                buttonType={RegularButtonTypes.blackNoBorder}
                size={RegularButtonSizes.small}
                label={'Contact Support'}
                id={ID.messengerLinkToHelp}
                className={styles.contactSupportButton}
                linkTo={YomeURL.help}
              />
              {isDisplayFeedback && (
                <div className={styles.helpfulBlockContainer}>
                  <Paragraph>
                    Was it helpfull?
                  </Paragraph>
                  <div className={styles.helpfulButtonsContainer}>
                    <RegularButton
                      buttonType={RegularButtonTypes.transparentNoBorder}
                      size={RegularButtonSizes.small}
                      label={'👎'}
                      id={ID.messengerUnhelpfulButton}
                      className={styles.helpfulButton}
                      onClick={sendFeedback(false)}
                    />
                    <RegularButton
                      buttonType={RegularButtonTypes.transparentNoBorder}
                      size={RegularButtonSizes.small}
                      label={'👍'}
                      id={ID.messengerHelpfulButton}
                      className={styles.helpfulButton}
                      onClick={sendFeedback(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <DateComponent isoTimestamp={message.createdAt} type={DateFormatType.time} />
      </div>
    </div>
  )
}

export default WarningMessage
