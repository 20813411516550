import useSWR from 'swr'

import { IUsernameChangeProfileFormValues } from 'src/models/auth.model'
import { IProfile } from 'src/models/profile.model'
import { sendFormPostAndHandleFormErrors, sendGet } from 'src/utils/network.utils'
import YomeAPI from 'src/constants/network.constants'
import SWRkeys from 'src/constants/swr.constants'

export const getProfile = (path: string): Promise<IProfile> => sendGet(path)

const useGetProfile = () => useSWR<IProfile>(
  SWRkeys.profile,
  getProfile,
  { revalidateOnFocus: false },
)

const updateUserName = (
  values: IUsernameChangeProfileFormValues,
) => sendFormPostAndHandleFormErrors(YomeAPI.authUpdateUsername, values)

export {
  useGetProfile,
  updateUserName,
}
