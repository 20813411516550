import classNames from 'classnames'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'

import { getInputValueFieldName } from 'src/utils/form.utils'
import { KEYBOARD_ENTER_KEY } from '../../constants/general.constants'
import { I18N_SEARCH_FILTER } from '../../constants/i18n.constants'
import { ID } from '../../constants/id.constants'
import { MAX_SERCH_WORDS } from '../../models/search.model'
import Chip, { ChipType } from '../button/Chip/Chip'
import IconButton from '../button/IconButton'
import { FormInput } from '../form'

import styles from './filter.module.scss'

interface IFilterSearchDescriptionProps {
  name: string;
}

const FilterSearchDescription = ({ name }: IFilterSearchDescriptionProps) => {
  const { change } = useForm()
  const { values } = useFormState()
  const fieldInputName = getInputValueFieldName(name)
  const [curValue, setCurValue] = useState<string>('')
  const chips: string[] = useMemo(() => values[name] || [], [values, name])

  const addChip = useCallback(() => {
    if (!curValue) {
      return
    }

    // do not add new chip if it is already in the list
    if (chips.includes(curValue)) {
      setCurValue('')

      return
    }

    const newChips = Array.from(chips)
    newChips.push(curValue)

    change(name, newChips)
    setCurValue('')
  }, [change, chips, curValue, name])

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEYBOARD_ENTER_KEY) {
      addChip()
      event.preventDefault()
    }
  }, [addChip])

  const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setCurValue(event.currentTarget.value)
  }, [])

  const removeChip = useCallback((chipValue: string) => {
    const newChips = chips.filter((chip) => chip !== chipValue)
    change(name, newChips.length ? newChips : null)
  }, [change, chips, name])

  return (
    <div className={classNames('bodySizeM', styles.searchWithChips)}>
      <div className={styles.inputPlus}>
        <FormInput
          id={ID.searchFilterInputSearchDescription}
          name={fieldInputName}
          placeholder={`${I18N_SEARCH_FILTER}.${name}.input`}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          value={curValue}
          fieldWrapperProps={{
            name,
            disabled: chips.length === MAX_SERCH_WORDS,
          }}
        />
        <IconButton iconName='plus-circle' onClick={addChip} id={ID.searchFilterAddChip} />
      </div>
      <div className={styles.chips}>
        {
          chips.map((chip: string) => (
            <Chip
              key={chip}
              value={chip}
              iconName='close-cross'
              type={ChipType.light}
              onIconClick={removeChip}
            />
          ))
        }
      </div>
    </div>
  )
}

export default memo(FilterSearchDescription)
