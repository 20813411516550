import { SubmissionErrors } from 'final-form'
import { toast } from 'react-toastify'

import ToggleSettingsLine from 'src/routes/profile/components/settings/SettingLine/ToggleSettingsLine'
import { I18N_PROFILE_SETTING_NOTIFICATIONS } from 'src/constants/i18n.constants'
import { PushNotificationsEnum, TPushNotifications, TUserPreferences } from 'src/models/userPreferences.model'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

const pushToggleNames = [PushNotificationsEnum.hasNewMessagesPush]

interface INotificationsSettingsProps {
  initialValues: TPushNotifications;
  sumbitNotificationsSettings: (values: Partial<TUserPreferences>) => Promise<SubmissionErrors>;
}

const PushNotificationsSettings = ({ initialValues, sumbitNotificationsSettings }: INotificationsSettingsProps) => {
  const { t } = useTranslation()

  // if there is no push permission - set all push settings to false
  if (!('Notification' in window) || Notification.permission !== 'granted') {
    Object.keys(initialValues).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      initialValues[key as keyof TPushNotifications] = false
    })
  }

  const checkPreventChange = useCallback((prevValue: boolean) => {
    const isPushPermissionGranted = 'Notification' in window && Notification.permission === 'granted'

    if (!prevValue && !isPushPermissionGranted) {
      if (!('Notification' in window)) {
        toast.error(t(`${I18N_PROFILE_SETTING_NOTIFICATIONS}.unableToShowPush`))

        return true
      }

      Notification.requestPermission()
      toast.info(t(`${I18N_PROFILE_SETTING_NOTIFICATIONS}.allowNotificationsRequest`))

      return true
    }

    return false
  }, [t])

  return (
    <ToggleSettingsLine
      title={`${I18N_PROFILE_SETTING_NOTIFICATIONS}.push.title`}
      onSubmit={sumbitNotificationsSettings}
      toggleNames={pushToggleNames}
      initialValues = {initialValues}
      checkPreventChange={checkPreventChange}
    />
  )
}

export default PushNotificationsSettings
