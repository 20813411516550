import { Link } from 'react-router-dom'

import UserAvatar from 'src/components/avatar/UserAvatar'
import DateComponent from 'src/components/date/DateComponent'
import YomeURL from 'src/constants/navigate.constants'
import { DateFormatType } from 'src/models/date.model'
import { IChatMessage } from 'src/models/messenger.model'
import { AvatarSizes } from 'src/models/upload.model'

import styles from './styles.module.scss'

interface IStrangerMessageProps {
  message: IChatMessage;
}

const StrangerMessage = ({ message }: IStrangerMessageProps) => (
  <div className={styles.messageStrangerMain}>
    <Link to={YomeURL.userPage(message.author.id)} className={styles.avatarWrapper} >
      <UserAvatar
        userId={message.author.id}
        avatarSize={AvatarSizes.xtiny}
        image={message.author.avatar}
        firstLetter={message.author.name.charAt(0)}
      />
    </Link>
    <div className={styles.messageWithDate}>
      <div className={styles.strangerMessageText}>
        {message.content}
      </div>
      <DateComponent isoTimestamp={message.createdAt} type={DateFormatType.time} />
    </div>
  </div>
)

export default StrangerMessage
