import Loader from 'react-spinners/CircleLoader'

import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import { I18N_PROFILE } from 'src/constants/i18n.constants'
import { useViewport } from 'src/contexts/viewportContext'
import SWRkeys from 'src/constants/swr.constants'
import { useGetProfile } from 'src/services/profile.service'
import { assertData } from 'src/utils/error.utils'
import { addMockUserData } from 'src/utils/mock.utils'

import { IUser } from '../../../../models/user.model'
import ProfileHeading from '../ProfileHeading'
import ProfileCard from './profileCard/ProfileCard'
import ProfileSections from './profileSections/ProfileSections'

import styles from './content.module.scss'

const ProfileMenu = () => {
  const { data: profile, isLoading } = useGetProfile()
  const { isDesktop } = useViewport()

  if (isLoading) {
    return (
      <Loader />
    )
  }

  assertData(profile)

  addMockUserData(profile as IUser)

  return (
    <div className={styles.main}>
      {!isDesktop && <ProfileHeading label={`${I18N_PROFILE}.title`} />}
      <ProfileCard profile={profile} />
      <ProfileSections />
      {isDesktop && <ShowcaseArea /> }
    </div>
  )
}

const ProfileMenuBoundary = () => (
  <SWRErrorBoundary swrKey={SWRkeys.profile}>
    <ProfileMenu />
  </SWRErrorBoundary>
)

export default ProfileMenuBoundary
