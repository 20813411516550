import React from 'react'

import SocialIconLink from 'src/components/button/SocialIconLink'
import { concatId, ID } from 'src/constants/id.constants'
import { SOCIAL_MEDIA_ITEMS } from 'src/models/footer.model'

import styles from './footer.module.scss'

const FooterSocial: React.FC = () => (
  <ul className={styles.socialMediaRow}>
    {SOCIAL_MEDIA_ITEMS.map(({ id, linkTo }) => (
      <li key={id}>
        <SocialIconLink
          link={linkTo}
          name={`social/${id}`}
          id={concatId(ID.footerLinkToOption, id)}
        />
      </li>
    ))}
  </ul>
)

export default FooterSocial
