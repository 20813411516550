import classNames from 'classnames'
import { memo, useEffect, useState } from 'react'

import Heading from 'src/components/heading/Heading'
import { ID } from 'src/constants/id.constants'
import { IPropsWithOffer } from 'src/models/post.model'
import { checkIsInactiveView } from 'src/utils/post.utils'

import AuthorInfoAndButtons from '../authorInfoAndButtons/AuthorInfoAndButtons'
import PostCustomerActions from './postCustomerActions/PostCustomerActions'
import PostOwnerActions from './postOwnerActions/PostOwnerActions'
import PostActionDescription from './postOwnerActions/PostStatusDescription'
import PostPrice from './PostPrice'

import styles from './styles.module.scss'

const PostStickyInfo = ({ offer }: IPropsWithOffer) => {
  const [isTitleOnSticky, setIsTitleOnSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const postTitleElement = document.getElementById(ID.postPagePostTitle)

      if (postTitleElement) {
        const postContentRect = postTitleElement.getBoundingClientRect()

        setIsTitleOnSticky(postContentRect.top < 0)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isInactiveView = checkIsInactiveView(offer)

  return (
    <div className={styles.stickyInfoPanel}>
      <div>
        <Heading level='h3' className={classNames(styles.stickyTitle, 'desktop-h6')}>
          {isTitleOnSticky && (offer.title)}
        </Heading>
        {!isInactiveView && <PostPrice value={offer.price} />}
        {offer.isCreatedByCurrentUser && (
          <PostOwnerActions offer={offer} />
        )}
        {isInactiveView && <PostActionDescription postType={offer.status} descriptionType='guestDescription'/>}
        {(!offer.isCreatedByCurrentUser) && (
          <PostCustomerActions
            title={offer.title}
            postId={offer._id}
            slug={offer.slug}
            isInactiveView ={isInactiveView}
            responseTime={offer.user.averageResponseTime}
            isFavoritePost={offer.isFavorite}
          />
        )}
      </div>
      <AuthorInfoAndButtons author={offer.user} isCurrentUserAuthor={offer.isCreatedByCurrentUser}/>
    </div>
  )
}

export default memo(PostStickyInfo)
