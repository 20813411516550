import { useCallback } from 'react'

import { I18N_PROFILE_SETTING_NOTIFICATIONS } from 'src/constants/i18n.constants'
import { updateUserPreferences, useGetUserPreferences } from 'src/services/userPreferences.service'
import { EmailNotificationsEnum, PushNotificationsEnum, TUserPreferences } from 'src/models/userPreferences.model'
import SWRErrorNotification from 'src/components/swr/SWRErrorNotification'
import SWRkeys from 'src/constants/swr.constants'
import { returnFormErrorsOrShowToast } from 'src/utils/error.utils'
import SettingSectionWrapper from '../settingWrapper/SettingSectionWrapper'
import EmailNotificationsSettings from './EmailNotificationsSettings'
import PushNotificationsSettings from './PushNotificationsSettings'

import styles from './notificationSettings.module.scss'

const NotificationsSettings = () => {
  const { data: initialSettings, isLoading } = useGetUserPreferences()

  // save notification preferences to db
  const sumbitNotificationsSettings = useCallback(async (values: Partial<TUserPreferences>) => {
    try {
      return await updateUserPreferences(values)
    } catch (error) {
      returnFormErrorsOrShowToast(error)

      return null
    }
  }, [])

  if (isLoading || !initialSettings) {
    return null
  }

  const initialPushValues = {
    [PushNotificationsEnum.hasNewMessagesPush]: initialSettings[PushNotificationsEnum.hasNewMessagesPush],
  }

  const initialEmailValues = {
    [EmailNotificationsEnum.hasNewMessagesEmail]: initialSettings[EmailNotificationsEnum.hasNewMessagesEmail],
    [EmailNotificationsEnum.hasMessagesSummaryEmail]: initialSettings[EmailNotificationsEnum.hasMessagesSummaryEmail],
    [EmailNotificationsEnum.hasExpiredOffersEmail]: initialSettings[EmailNotificationsEnum.hasExpiredOffersEmail],
  }

  return (
    <SettingSectionWrapper title={`${I18N_PROFILE_SETTING_NOTIFICATIONS}.title`}>
      <div>
        <PushNotificationsSettings
          initialValues={initialPushValues}
          sumbitNotificationsSettings={sumbitNotificationsSettings}
        />
        <div className={styles.divider}/>
        <EmailNotificationsSettings
          initialValues={initialEmailValues}
          sumbitNotificationsSettings={sumbitNotificationsSettings}
        />
      </div>
    </SettingSectionWrapper>
  )
}

const NotificationsSettingsBoundary = () => (
  <SWRErrorNotification errorMessage={'USER_PREFERENCES_ERROR'} swrKey={SWRkeys.getUserPreferences}>
    <NotificationsSettings />
  </SWRErrorNotification>
)

export default NotificationsSettingsBoundary
