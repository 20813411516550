import { createContext } from 'react'

import { CardInfo, PostListType } from 'src/models/post.model'

import { IdStructure } from '../constants/id.constants'

export interface PostListContextProps {
  idPostPrefix: IdStructure;
  postListKey: string;
  hasAllFavorite: boolean;
  cardInfo: CardInfo;
  listType?: PostListType;
}

const PostListContext = createContext<PostListContextProps>({
  idPostPrefix: '',
  postListKey: '',
  hasAllFavorite: false,
  cardInfo: CardInfo.location,
  listType: PostListType.active,
})

export default PostListContext
