import React from 'react'

import Heading from 'src/components/heading/Heading'
import PostList from 'src/components/postList/PostList'
import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import PostListLoader from 'src/components/skeletons/PostListLoader'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import { I18N_SIMILAR_OFFERS } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { SinglePost } from 'src/models/post.model'
import { getSimilarOffersParams, useGetSimilarPostList } from 'src/services/search.service'
import { assertData } from 'src/utils/error.utils'

import RegularButton, { RegularButtonTypes } from '../../../../components/button/RegularButton'
import { ID } from '../../../../constants/id.constants'

import styles from './similarOffers.module.scss'

interface ISimilarOffers {
  searchEndpoint: string;
  offer: SinglePost;
}

const SimilarOffers: React.FC<ISimilarOffers> = ({ searchEndpoint, offer }) => {
  const { data: similarOffersData, isLoading } = useGetSimilarPostList(searchEndpoint)
  const { category, location } = offer
  const params = getSimilarOffersParams(category, location)

  const { isDesktop } = useViewport()

  if (isLoading) {
    return <PostListLoader />
  }

  assertData(similarOffersData)

  const postData = [...similarOffersData.fromCity, ...similarOffersData.other]
  const { hasMore } = similarOffersData

  if (postData.length === 0) {
    return null
  }

  return (
    <section className={styles.main}>
      <div>
        <Heading
          level='h2'
          className={'desktop-h5 mobile-h5'}
          label={`${I18N_SIMILAR_OFFERS}.title`}
        />
        <PostList
          idPostPrefix={ID.similarPrefixPost}
          postListKey={searchEndpoint}
          posts={postData}
        />
        {hasMore && (
          <RegularButton
            id={ID.postPageShowMorePosts}
            buttonType={RegularButtonTypes.transparentWithBorder}
            linkTo={`${YomeURL.search}?${params}`}
            label={`${I18N_SIMILAR_OFFERS}.btn`}
            className={styles.showMoreBtn}
          />
        )}
        <ShowcaseArea type={isDesktop ? 'horizontal' : 'mobile-big'}/>
      </div>
    </section>
  )
}

interface ISimilarOffersBoundary {
  offer: SinglePost;
}

const SimilarOffersBoundary = ({ offer }: ISimilarOffersBoundary) => {
  const searchEndpoint = SWRkeys.searchSimilar(offer.slug)

  return (
    <SWRErrorBoundary swrKey={searchEndpoint} emptyStateClassName={styles.emptyState}>
      <SimilarOffers searchEndpoint={searchEndpoint} offer={offer} />
    </SWRErrorBoundary>
  )
}

export default SimilarOffersBoundary
