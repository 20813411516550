import classNames from 'classnames'
import React from 'react'

import UserAvatar from 'src/components/avatar/UserAvatar'
import Paragraph from 'src/components/paragraph/Paragraph'
import { ICurrentUser } from 'src/models/currentUser.model'
import { AvatarSizes } from 'src/models/upload.model'

import styles from './userButton.module.scss'

interface IUserButton {
  currentUser: ICurrentUser;
}

const UserButton: React.FC<IUserButton> = ({ currentUser }) => (
  <>
    <UserAvatar
      image={currentUser.avatar}
      avatarSize={AvatarSizes.xtiny}
      userId={currentUser.id}
      firstLetter={currentUser.name.charAt(0)}
      className={styles.avatar}
    />
    <Paragraph
      className={classNames('label-14', styles.userName)}
      text={currentUser.name}
    />
  </>
)

export default UserButton
