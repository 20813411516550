import classNames from 'classnames'
import React from 'react'

import { useViewport } from 'src/contexts/viewportContext'
import { SECTION_ITEMS } from 'src/models/footer.model'
import FooterLogoAndSocial from './FooterLogoAndSocial'
import FooterMobile from './FooterMobile'
import FooterSection from './FooterSection'

import layoutStyles from '../../routes/layout.module.scss'
import styles from './footer.module.scss'

const Footer: React.FC = () => {
  const { isDesktop } = useViewport()

  if (!isDesktop) {
    return <FooterMobile/>
  }

  return (
    <footer className={styles.main}>
      <div className={classNames(layoutStyles.base, styles.layout)}>
        <div className={styles.wrapper}>
          <FooterLogoAndSocial />
          {Object.entries(SECTION_ITEMS).map(([sectionTitle, items]) => (
            <FooterSection items={items} title={sectionTitle} key={sectionTitle} />
          ))}
        </div>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
