import classNames from 'classnames'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ADVERTISING } from 'src/constants/i18n.constants'
import { ADVERTISING_STATS } from 'src/constants/staticPages.constants'
import { useViewport } from 'src/contexts/viewportContext'
import SectionHeading from '../../SectionHeading'

import styles from '../../advertising.module.scss'

const Stats = () => {
  const { isDesktop } = useViewport()

  return (
    <div className={styles.stats}>
      <SectionHeading
        label={'stats.title'}
      />
      <ul className={classNames(styles.statsList, 'static-stat-line')}>
        {ADVERTISING_STATS.map((item) => (
          <li key={item.num} className={'static-stat-line-item'}>
            <Heading label={item.num} level="h2" className="mobile-h2 desktop-h2" />
            <Paragraph
              text={`${I18N_ADVERTISING}.stats.${item.caption}`}
              className={isDesktop ? 'label-20' : 'label-18'}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Stats
