import React from 'react'
import { useTranslation } from 'react-i18next'
import { PlacesType, VariantType } from 'react-tooltip'

import { ITooltipData } from '../../yome-categories-fields-config/models/fields.model'

import styles from './tooltip.module.scss'

type TooltipProps = {
  tooltipData: ITooltipData;
  children: React.ReactElement;
  variant?: VariantType;
  place?: PlacesType;
}

const CustomTooltip = ({ tooltipData, children, variant = 'dark', place = 'bottom' }: TooltipProps) => {
  const { t } = useTranslation()

  const header = tooltipData.header ? `<h5 class='label-16'>${t(tooltipData.header)}</h5>` : ''
  const paragraps = tooltipData.paragraphs.map((p) => `<p>${t(p)}</p>`).join('')

  const tooltipHTML = `${header} ${paragraps}`

  // attributes are used by Tooltip component in yome-web/src/index.tsx from react-tooltip
  return (
    <span
      className={styles.main}
      data-tooltip-id={`${variant}-tooltip`}
      data-tooltip-html={tooltipHTML}
      data-tooltip-place={place}
    >
      {children}
    </span>
  )
}

export default CustomTooltip
