import { getGeocode, getLatLng, LatLng } from 'use-places-autocomplete'
import { memoize } from 'lodash-es'

import { ALLOWED_MAP_COUNTRY_CODES, CountryCodes, MapAddressType, PLUS_CODE_REGEXP } from 'src/constants/map.constants'
import i18n from 'src/i18n'
import { ISearchLocation, LatLngString } from 'src/models/location.model'
import { LOCAL_STORAGE_SEARCH_LOCATION } from 'src/models/search.model'

import { captureException } from './browser.utils'

// TODO: save LatLng object instead of string
export const parseLatLngString = (position: unknown): LatLng | undefined => {
  if (!position) {
    return undefined
  }

  if (typeof position !== 'string') {
    return position as LatLng | undefined
  }

  const sanitizedPosition = position.replace(/[^\d.,-]/g, '')
  const [lat, lng] = sanitizedPosition.split(',').map(Number)

  if (Number.isNaN(lat) || Number.isNaN(lng)) {
    throw new Error(`Position can not be parsed: ${position}`)
  }

  return { lat, lng }
}

// TODO: save LatLng object instead of string
export const getLatLngString = (
  position: LatLng,
): LatLngString => `${position.lat},${position.lng}`

export const getShortenPosition = (position: string) => {
  const [lat, lng] = position.split(',').map((num) => Number(num).toFixed(4))

  return `${lat},${lng}`
}

export const getCityFromGeocoderResult = (geocodeResult: google.maps.GeocoderResult, latLng: LatLng) => {
  const cityComponent = geocodeResult.address_components.find((item) => item.types.includes(MapAddressType.locality))

  if (!cityComponent) {
    throw new Error(`Geocoder can't obtain city by LatLng: ${latLng}`)
  }

  return cityComponent.long_name
}

export const isCountryAllowed = (results: google.maps.GeocoderResult[]): boolean => {
  const countryResult = results.find((result) => result.types.includes('political'))

  if (!countryResult) {
    return false
  }

  const cityComponent = countryResult.address_components.find(
    (component) => component.types.includes('country'),
  )

  const countryCode = cityComponent ? cityComponent.short_name : null

  if (!countryCode) {
    return false
  }

  return ALLOWED_MAP_COUNTRY_CODES.includes(countryCode as CountryCodes)
}

export const getAddressByLatLng = memoize((location: LatLng, hasOnlyCity: boolean) => getGeocode({ location })
  .then((results) => {
    const geocodeResult = results[0]

    if (!isCountryAllowed(results)) {
      return undefined
    }

    if (!geocodeResult) {
      throw new Error(`Geocoder can't resolve LatLng: ${location}`)
    }

    if (hasOnlyCity) {
      return getCityFromGeocoderResult(geocodeResult, location)
    }

    return geocodeResult.formatted_address.replace(PLUS_CODE_REGEXP, '')
  }))

export const getLocationByAddress = (address: string, hasOnlyCity = false) => getGeocode({ address })
  .then((results) => {
    const geocodeResult = results.length && results[0]

    if (geocodeResult) {
      const position = getLatLng(geocodeResult)

      return {
        position,
        address: hasOnlyCity ? getCityFromGeocoderResult(geocodeResult, position) : address,
      }
    }

    throw new Error(`Geocode was not able to resolve LatLng by address: ${address}`)
  })

export const convertLatLng = ({ lat, lng }: google.maps.LatLng): LatLng => ({ lat: lat(), lng: lng() })

export const getZoomByRadius = (radius: number | undefined) => {
  if (!radius) {
    return 10
  }

  if (radius > 100) {
    return 7
  }

  if (radius > 50) {
    return 8
  }

  if (radius > 25) {
    return 9
  }

  if (radius > 10) {
    return 10
  }

  if (radius > 5) {
    return 11
  }

  if (radius > 3) {
    return 12
  }

  if (radius > 1) {
    return 13
  }

  return 14
}

export const storeLocationSettings = (value: ISearchLocation) => {
  localStorage.setItem(LOCAL_STORAGE_SEARCH_LOCATION, JSON.stringify({
    ...value,
    language: i18n.language,
  }))
}

export const getStoredLocationSettings = () => {
  const locationSettings = localStorage.getItem(LOCAL_STORAGE_SEARCH_LOCATION)

  if (!locationSettings) {
    return undefined
  }

  try {
    return JSON.parse(locationSettings) as ISearchLocation
  } catch (error) {
    captureException(new Error(`Can not parse location settings from local storage: ${locationSettings}`))

    return undefined
  }
}
