import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import MapComponent from 'src/components/map/MapComponent'
import { parseLatLngString } from 'src/utils/map.utils'
import { SinglePost } from 'src/models/post.model'
import MobileTopBar from 'src/components/mobileTopBar/MobileTopBar'

import styles from './mobileMapDisplay.module.scss'

interface IMobileMapDisplay {
  location: SinglePost['location'];
  toggleOpenMap?: () => void;
  postTitle: string;
}

const MobileMapDisplay = ({ location, toggleOpenMap, postTitle }: IMobileMapDisplay) => (
  <FullscreenModalLayout>
    <div className={styles.mobileMapContainer}>
      <MobileTopBar onBackClick={toggleOpenMap} title={postTitle}/>
      <div className={styles.mobileMapAdress}>
        <p className='label-16'>
          {location.address}
        </p>
      </div>
      <div className={styles.mobileMapWrapper}>
        <MapComponent markerPosition={parseLatLngString(location.position)} />
      </div>
    </div>
  </FullscreenModalLayout>
)

export default MobileMapDisplay
