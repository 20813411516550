import React from 'react'

import LoginUserProvider from 'src/contexts/loginUser.context'
import { useModalsContext } from 'src/contexts/openModalContext'
import { ContextModalType, ModalComponentType } from 'src/models/modal.model'

import PhoneVerifiedModal from './badgeModals/PhoneVerifiedModal'
import BlockUserModal from './blockModal/BlockUserModal'
import UserBlockedModal from './blockModal/UserBlockedModal'
import ReportedUserModal from './reportModal/reportedModal/ReportSubmittedModal'
import ResponsiveModalWrapper from './ResponsiveModalWrapper'

const CONTEXT_MODALS: Record<ContextModalType, ModalComponentType> = {
  [ContextModalType.verifiedPhone]: PhoneVerifiedModal,
  [ContextModalType.userReported]: ReportedUserModal,
  [ContextModalType.blockUser]: BlockUserModal,
  [ContextModalType.userBlocked]: UserBlockedModal,
}

const ContextModal = React.memo(() => {
  const { contextModalData, setContextModal } = useModalsContext()
  const CurrentModalComponent = contextModalData.modalType && CONTEXT_MODALS[contextModalData.modalType]

  const handleClose = React.useCallback(() => {
    if (contextModalData.onClose) {
      contextModalData.onClose()
    }

    setContextModal({ modalType: null })
  }, [contextModalData, setContextModal])

  if (!CurrentModalComponent) {
    return null
  }

  return (
    <LoginUserProvider>
      <ResponsiveModalWrapper
        handleClose={handleClose}
        isNonFullscreen={contextModalData.isNonFullscreen}
      >
        <CurrentModalComponent handleClose={handleClose} payload={contextModalData.payload}/>
      </ResponsiveModalWrapper>
    </LoginUserProvider>
  )
})

export default ContextModal
