import { UNDERSCORE_REGEXP } from './general.constants'

export const categoryNameForUrl = (value: string) => value.toLowerCase().replace(UNDERSCORE_REGEXP, '-')

const YomeURL = {
  mainPage: '/',
  messages: '/messages',
  newOffer: '/new',
  search: '/search',
  help: '/help',
  about: '/about',
  forBusiness: '/for-business',
  advertising: '/advertising',

  profilePage: '/profile',
  offers: '/profile/offers',
  inactiveOffers: '/profile/offers/inactive',
  draftOffers: '/profile/offers/draft',
  archiveOffers: '/profile/offers/archive',

  reviews: '/profile/reviews',
  orders: '/profile/orders',
  wallet: '/profile/wallet',
  business: '/profile/business',
  services: '/profile/services',

  favorites: '/profile/favorites',
  savedsearch: '/profile/favorites/savedsearch',
  comparison: '/profile/favorites/comparison',
  favSellers: '/profile/favorites/favorite-sellers',

  notifications: '/profile/notifications',
  settings: '/profile/settings',

  legal: '/legal/',
  imprint: '/legal/imprint',
  terms: '/legal/terms-and-conditions',
  safety: '/legal/safety',
  privacy: '/legal/privacy-policy',
  cookies: '/legal/cookies-policy',

  oauthFacebook: '/oauth/facebook',
  oauthGoogle: '/oauth/google',
  oauthApple: '/oauth/apple',

  notFound: '*',

  offer(category: string, slug: string) { return `/offer/${categoryNameForUrl(category)}/${slug}` },
  editOffer(slug: string) { return `/profile/offers/edit/${slug}` },
  editDraft(publicId: string) { return `/new/${publicId}` },

  userPage(userId: string) { return `/user/${userId}` },
  userPageInactivePosts(userId: string) { return `/user/${userId}/inactive` },
}

export enum LegalLink {
  imprint = 'imprint',
  terms = 'terms',
  safety = 'safety',
  privacy = 'privacy',
  cookies = 'cookies',
}

export const LEGAL_LINKS = Object.values(LegalLink).map((id) => ({
  id,
  linkTo: YomeURL[id],
}))

export const YomeHash = {
  auth: '#auth',
  signup: '#signup',
  resetPassword: '#reset',
  cookie: '#cookie',
  unavailable: '#unavailable',
  verifyPhone: '#verify-phone',
}

export default YomeURL
