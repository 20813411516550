import classNames from 'classnames'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

import YomeURL from 'src/constants/navigate.constants'
import { useFavorite } from 'src/hooks/favorite.hooks'
import { OfferStatus } from 'src/models/offerStatus.model'
import { IPropsWithOffer } from 'src/models/post.model'

import { generateIdWithPrefix, PrefixAction } from '../../constants/id.constants'
import PostListContext from '../../contexts/postList.context'
import FavoriteRoundedControls from '../roundedControls/FavoriteRoundedControl'
import OfferSpecs from './OfferSpecs/OfferSpecs'
import PostImage from './PostImage/PostImage'

import styles from './postList.module.scss'

const OfferCard = (
  {
    offer,
  }: IPropsWithOffer,
) => {
  const { idPostPrefix, postListKey, hasAllFavorite } = useContext(PostListContext)
  const postIdLike = generateIdWithPrefix(idPostPrefix, PrefixAction.Like, offer.slug)
  const postId = generateIdWithPrefix(idPostPrefix, PrefixAction.Link, offer.slug)

  const isFavoritePost = hasAllFavorite || offer.isFavorite
  const offerLink = YomeURL.offer(offer.category, offer.slug)

  const { handlePostLike } = useFavorite(
    {
      slug: offer.slug,
      isFavoritePost,
      offerSourceEndpoint: postListKey,
    },
  )

  const isDisplayFavButton = offer.status === OfferStatus.active || isFavoritePost

  return (
    <div className={classNames(styles.item)}>
      {(!offer.isCreatedByCurrentUser && isDisplayFavButton) && (
        <div className={styles.addFavoriteButton}>
          <FavoriteRoundedControls
            id={postIdLike}
            handleFavoriteClick={handlePostLike}
            isActive={hasAllFavorite || isFavoritePost}
          />
        </div>
      )}
      <Link to={offerLink} id={postId}>
        <div className={classNames(styles.imageWithHeart)}>
          <PostImage offer={offer} />
        </div>
        <OfferSpecs offer={offer} />
      </Link>
    </div>

  )
}

export default OfferCard
