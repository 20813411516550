import { Field, useFormState } from 'react-final-form'

import { CATEGORY_PATH_FIELD_NAME } from 'src/models/post.model'
import { ALL_CATEGORIES } from 'src/yome-categories-fields-config/category-config/categoriesTree'

import CategoryCard from './categoryCard/CategoryCard'

interface ICategoryListProps {
  onCategoryChange: (path: string[]) => void;
  searchFieldName: string;
}

const CategoryList = ({ onCategoryChange, searchFieldName }: ICategoryListProps) => {
  const { values } = useFormState()

  return (
    <Field
      name={CATEGORY_PATH_FIELD_NAME}
      render={() => (
        <ul>
          {ALL_CATEGORIES.map((cat) => (
            <CategoryCard
              key={cat.title}
              category={cat}
              onCategoryChange={onCategoryChange}
              searchQuery={values[searchFieldName]}
            />
          ))}
        </ul>
      )}
    >
    </Field>
  )
}

export default CategoryList
