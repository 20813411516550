import classNames from 'classnames'
import { TFunction } from 'i18next'
import React, { ForwardedRef, forwardRef, memo, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import Heading from 'src/components/heading/Heading'
import { DESCRIPTION_FIELD_NAME } from 'src/models/post.model'

import SvgIcon from '../../../../../components/svgIcon/SvgIcon'
import CustomTooltip from '../../../../../components/tooltip/CustomTooltip'
import { I18N_POST_DATA } from '../../../../../constants/i18n.constants'
import { IFieldConfig, ITooltipData } from '../../../../../yome-categories-fields-config/models/fields.model'

import styles from './postField.module.scss'

interface IPostFieldProps {
  fieldName: string;
  fieldValue: any;
  fieldConfig?: IFieldConfig;
  hideTitle?: boolean;
  className?: string;
  afterValueElement?: ReactNode;
}

interface IOfferFieldTitleProps {
  tooltip: ITooltipData | null;
  name: string;
}

const localiseFieldValue = (fieldConfig: IFieldConfig, fieldName: string, value: string, t: TFunction) => {
  if (!fieldConfig || !fieldConfig.options) return value

  return t(`${I18N_POST_DATA}.${fieldName}.${value}`)
}

const getFieldTooltip = (fieldConfig: IFieldConfig, fieldName: string) => {
  if (fieldConfig && fieldConfig.tooltip && fieldName === 'condition') {
    return fieldConfig.tooltip
  }

  return null
}

const OfferFieldTitle = ({ tooltip, name }: IOfferFieldTitleProps) => {
  const { t } = useTranslation()
  const label = `post.fieldName.${name}`
  const isDescriptionField = name === DESCRIPTION_FIELD_NAME

  if (isDescriptionField) {
    return (
      <Heading
        level='h3'
        label={label}
        className={classNames(styles.fieldName, 'desktop-h6 mobile-h6 bodySizeM')}
      />
    )
  }

  return (
    <div className={classNames(styles.fieldName, 'bodySizeM')}>
      {t(label)}
      {tooltip && (
        <CustomTooltip tooltipData={tooltip}>
          <SvgIcon name='hint' />
        </CustomTooltip>
      )}
    </div>
  )
}

export const PostField = forwardRef(({
  fieldName,
  fieldValue,
  children,
  fieldConfig,
  className,
  hideTitle = false,
  afterValueElement,
}: PropsWithChildren<IPostFieldProps>, ref: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation()
  const isColorField = fieldName === 'color'

  return (
    <div className={classNames(styles.field, styles[`${fieldName}Field`], className)} ref={ref}>
      {!hideTitle && <OfferFieldTitle name={fieldName} tooltip={getFieldTooltip(fieldConfig!, fieldName)} />}
      <div className={classNames(styles.fieldData, 'bodySizeM')}>
        {isColorField && <SvgIcon name={`${fieldName}/${fieldValue}`} />}
        <span>
          {localiseFieldValue(fieldConfig!, fieldName, fieldValue, t)}
          {afterValueElement}
        </span>
      </div>
      {children}
    </div>
  )
})

export default memo(PostField)
