import { memo } from 'react'
import { ToastContainer } from 'react-toastify'

import ToastCloseButton from './ToastCloseButton'
import ToastIcon from './ToastIcon'

const TOAST_CONTAINER_LIMIT = 3
const TOAST_CLOSE_DELAY = 6000
export const TOAST_INFO_CLOSE_DELAY = 3000

const ToastContainerWrapper = () => (
  <ToastContainer
    icon={ToastIcon}
    autoClose={TOAST_CLOSE_DELAY}
    position='bottom-center'
    limit={TOAST_CONTAINER_LIMIT}
    closeButton={ToastCloseButton}
  />
)

export default memo(ToastContainerWrapper)
