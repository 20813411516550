import { memo, PropsWithChildren } from 'react'

import fieldsStructure from 'src/yome-categories-fields-config/fields-config/formPostFields'
import { getMainCategory } from 'src/yome-categories-fields-config/utils/fieldConfig.utils'

import OfferFormSection from './OfferFormSection'

import styles from './offerForm.module.scss'

interface IPostFormProps {
  handleSubmit: () => void;
  publicId: string;
  categoryPath: string[];
}

const getFieldsStructure = (rootCategory: string) => {
  const mainCategory = getMainCategory(rootCategory)

  return fieldsStructure[mainCategory]
}

const FullOfferForm = ({
  handleSubmit,
  publicId,
  categoryPath,
  children,
}: PropsWithChildren<IPostFormProps>) => (
  <div className={styles.mainPostForm}>
    <form onSubmit={handleSubmit} className={styles.formBody}>
      {getFieldsStructure(categoryPath[0]).map((sectionInfo) => (
        <OfferFormSection
          publicId={publicId}
          key={sectionInfo.section}
          sectionInfo={sectionInfo}
          mainCategory={getMainCategory(categoryPath[0])}
        />
      ))}
      {children}
    </form>
    <div className={styles.progressBar} />
  </div>
)

export default memo(FullOfferForm)
