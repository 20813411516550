import { I18N_PROFILE_ACTION } from 'src/constants/i18n.constants'
import { TDropdownSections } from 'src/models/dropdown.model'

// eslint-disable-next-line import/prefer-default-export
export const addProfileActionNames = (sections: TDropdownSections) => {
  const dropdownSections: TDropdownSections = {}

  Object.keys(sections).forEach((sectionKey) => {
    const actions = sections[sectionKey]
    dropdownSections[sectionKey] = {}

    Object.keys(actions).forEach((actionKey) => {
      dropdownSections[sectionKey][actionKey] = {
        ...actions[actionKey],
        name: `${I18N_PROFILE_ACTION}.${actionKey}`,
      }
    })
  })

  return dropdownSections
}
