import classNames from 'classnames'
import React, { memo, useContext } from 'react'

import CardHeading from 'src/components/cardHeading/cardHeading'
import DateComponent from 'src/components/date/DateComponent'
import MyOfferCardStats from 'src/components/myOffers/components/myOfferCardStats/MyOfferCardStats'
import PostListContext from 'src/contexts/postList.context'
import { useViewport } from 'src/contexts/viewportContext'
import { dateOptions } from 'src/models/date.model'
import { CardInfo, IPropsWithOffer, PostListType } from 'src/models/post.model'
import { IUser } from 'src/models/user.model'
import { getOfferTimestampByStatus } from 'src/utils/date.utils'
import { checkIsInactiveView } from 'src/utils/post.utils'

import OfferAddress from '../OfferAddress/OfferAddress'
import PostPrice from '../PostPrice/PostPrice'
import PostUserInfo from '../PostUserInfo/PostUserInfo'

import styles from './offerSpecs.module.scss'

interface IOfferSpecs extends IPropsWithOffer {
  isHorizontal?: boolean;
  user?: IUser;
}

const OfferSpecs: React.FC<IOfferSpecs> = ({ offer, user, isHorizontal = false }) => {
  const { idPostPrefix, cardInfo, listType } = useContext(PostListContext)

  const { isDesktop } = useViewport()
  const isInactiveView = checkIsInactiveView(offer)
  const isDisabled = isInactiveView && listType !== PostListType.inactive

  return (
    <div className={classNames(styles.main)}>
      <CardHeading
        offer={offer}
        level='h4'
        className={classNames(styles.title, 'mobile-card-heading desktop-h7')}
        isDisabled={isDisabled}
      />
      <PostPrice offer={offer} />
      {isHorizontal && isDesktop && (
        <div className={classNames(styles.description, 'supportive-14')}>
          {offer.description}
        </div>
      )}
      {cardInfo === CardInfo.statistic ? (
        <MyOfferCardStats
          idPrefix={idPostPrefix}
          stats={offer.statistic}
          isOneRow={true}
        />
      ) : (
        <>
          <OfferAddress location={offer.location} />
          <div className='desktop-hide'>
            {user && <PostUserInfo user={user} />}
          </div>
          <div className={classNames(styles.caption, 'label-14')}>
            <DateComponent
              isoTimestamp={getOfferTimestampByStatus(offer).toString()}
              options={dateOptions.dateTime}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default memo(OfferSpecs)
