import { FORM_ERROR } from 'final-form'
import queryString from 'query-string'

import {
  BAD_REQUEST,
  ERROR_CODE,
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  NOT_AUTHORIZED,
  NOT_FOUND,
  SOMETHING_WENT_WRONG,
  TOO_MANY_REQUESTS,
} from 'src/constants/error.constants'
import { YomeHash } from 'src/constants/navigate.constants'

import {
  AuthorizationError,
  BadRequestError,
  captureErrorAndShowToast,
  ForbiddenError,
  InternalServerError,
  NotAcceptableError,
  NotFoundError,
  returnFormErrorsOrShowToast,
  SubmitFormError,
  TooManyRequestsError,
  UnexpectedError,
} from './error.utils'

const BASE_REQUEST_PARAMS = {
  mode: 'cors' as RequestMode,
  cache: 'default' as RequestCache,
  credentials: 'include' as RequestCredentials,
}

const JSON_REQUEST_PARAMS = {
  ...BASE_REQUEST_PARAMS,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

export const openAuthModal = () => {
  const curPath = window.location.pathname
  window.location.href = `${curPath}${YomeHash.auth}`
}

const handleAuthError = (response: Response) => {
  if (response.status === 401) {
    openAuthModal()

    throw new AuthorizationError(NOT_AUTHORIZED)
  }
}

const handleInternalServerError = (response: Response) => {
  if (response.status === 500) {
    throw new InternalServerError(INTERNAL_SERVER_ERROR)
  }
}

const handleNotFoundError = (response: Response) => {
  if (response.status === 404) {
    throw new NotFoundError(NOT_FOUND)
  }
}

const handleNotAcceptableError = (response: Response, resJSON: any) => {
  if (response.status === 406) {
    throw new NotAcceptableError(resJSON[ERROR_CODE])
  }
}

const handleBadRequestError = (response: Response, resJSON: any) => {
  if (response.status === 400) {
    throw new BadRequestError(resJSON[ERROR_CODE])
  }
}

const handleTooManyRequestsError = (response: Response) => {
  if (response.status === 429) {
    throw new TooManyRequestsError(TOO_MANY_REQUESTS)
  }
}

const handleForbiddenError = (response: Response) => {
  if (response.status === 403) {
    throw new ForbiddenError(FORBIDDEN)
  }
}

const handleFormResponse = async (response: Response) => {
  if (response.status === 500) {
    throw new SubmitFormError(SOMETHING_WENT_WRONG, { [FORM_ERROR]: SOMETHING_WENT_WRONG })
  }

  handleAuthError(response)
  handleNotFoundError(response)
  handleTooManyRequestsError(response)
  handleForbiddenError(response)

  // if there is common error make it form error
  const resJSON = await response.json()

  if (resJSON?.[ERROR_CODE]) {
    const errCode = resJSON[ERROR_CODE]

    throw new SubmitFormError(errCode, { [FORM_ERROR]: errCode })
  }

  if (response.status === 400) {
    throw new SubmitFormError(BAD_REQUEST, resJSON)
  }

  if (!response.ok) {
    throw new UnexpectedError(SOMETHING_WENT_WRONG)
  }

  return resJSON
}

const handleErrors = async (response: Response) => {
  handleAuthError(response)
  handleInternalServerError(response)
  handleNotFoundError(response)
  handleTooManyRequestsError(response)
  handleForbiddenError(response)

  const resJSON = await response.json()

  handleBadRequestError(response, resJSON)
  handleNotAcceptableError(response, resJSON)

  if (!response.ok) {
    throw new UnexpectedError(SOMETHING_WENT_WRONG)
  }

  return resJSON
}

export const sendGet = async (
  url: string,
  query?: queryString.StringifiableRecord,
) => {
  const response = await fetch(queryString.stringifyUrl({ url, query }), {
    ...JSON_REQUEST_PARAMS,
    method: 'GET',
  })

  return handleErrors(response)
}

export const sendFormGet = async (url: string, query?: queryString.StringifiableRecord) => {
  const response = await fetch(queryString.stringifyUrl({ url, query }), {
    ...JSON_REQUEST_PARAMS,
    method: 'GET',
  })

  return handleFormResponse(response)
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const sendPost = async <T,>(url: string, values?: T | undefined) => {
  const response = await fetch(url, {
    ...JSON_REQUEST_PARAMS,
    method: 'POST',
    body: values && JSON.stringify(values),
  })

  return handleErrors(response)
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const sendFormPost = async <T,>(url: string, values?: T | undefined) => {
  const response = await fetch(url, {
    ...JSON_REQUEST_PARAMS,
    method: 'POST',
    body: values && JSON.stringify(values),
  })

  return handleFormResponse(response)
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const sendFormPostAndHandleFormErrors = async <T,>(
  url: string, values?: T | undefined,
) => {
  try {
    return await sendFormPost(url, values)
  } catch (error) {
    return returnFormErrorsOrShowToast(error as Error)
  }
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const sendFormPostOrShowToast = async <T,>(
  url: string, values?: T | undefined,
) => {
  try {
    return await sendFormPost(url, values)
  } catch (error) {
    return captureErrorAndShowToast(error as Error)
  }
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const sendDelete = async <T,>(url: string, values?: T | undefined) => {
  const response = await fetch(url, {
    ...JSON_REQUEST_PARAMS,
    method: 'DELETE',
    body: values && JSON.stringify(values),
  })

  return handleErrors(response)
}

export const sendPostWithFormData = async (url: string, formData: FormData) => {
  const response = await fetch(url, {
    ...BASE_REQUEST_PARAMS,
    method: 'POST',
    body: formData,
  })

  return handleFormResponse(response)
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const sendFormPut = async <T,>(url: string, values?: T | undefined) => {
  const response = await fetch(url, {
    ...JSON_REQUEST_PARAMS,
    method: 'PUT',
    body: values && JSON.stringify(values),
  })

  return handleFormResponse(response)
}
