import classNames from 'classnames'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { I18N_GLOBAL } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'

import { CATEGORY_QPARAM } from '../../models/search.model'
import ButtonLink from '../button/ButtonLink'
import SvgIcon from '../svgIcon/SvgIcon'

import styles from './breadCrumbs.module.scss'

interface IBreadCrumbsProps {
  tags?: string[];
  i18nPrefix?: string;
  className?: string;
}

interface IBreadcrumbProps {
  category: string;
  i18nPrefix?: string;
}

const Breadcrumb = memo(({ category, i18nPrefix }: IBreadcrumbProps) => {
  const [searchParams] = useSearchParams()

  const breadcrumbLink = useMemo(() => {
    searchParams.set(CATEGORY_QPARAM, category)

    return `${YomeURL.search}?${searchParams.toString()}`
  }, [searchParams, category])

  return (
    <div className={styles.tagWrapper}>
      <SvgIcon name='chevron-right' size={16} className={styles.separator} currentColor='stroke'/>
      <ButtonLink
        linkTo={breadcrumbLink}
        label={`${i18nPrefix}.${category}`}
        id={concatId(ID.breadcrumbsLinkToOption, category)}
      />
    </div>
  )
})

const Breadcrumbs = memo(({ tags, i18nPrefix, className }: IBreadCrumbsProps) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const [isOverflow, setIsOverflow] = useState(false)

  useEffect(() => {
    const parentDiv = parentRef.current

    if (parentDiv) {
      const { offsetWidth, scrollWidth } = parentDiv

      setIsOverflow(offsetWidth < scrollWidth)
    }
  }, [tags])

  return (
    <div
      ref={parentRef}
      className={classNames(className, styles.breadcrumbs, { [styles.breadcrumbsOverflow]: isOverflow })}
    >
      <ButtonLink
        label={`${I18N_GLOBAL}.homePage`}
        linkTo={YomeURL.mainPage}
        title={`${I18N_GLOBAL}.homePage`}
        id={ID.breadcrumbsLinkToMainPage}
      />
      {tags?.map((item: string) => (
        <Breadcrumb key={item} category={item} i18nPrefix={i18nPrefix} />
      ))}
    </div>
  )
})

export default Breadcrumbs
