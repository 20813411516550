import React, { useCallback, useEffect } from 'react'
import { useField, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { usePriceFormatter } from 'src/hooks/price.hooks'

import { getInputValueFieldName } from 'src/utils/form.utils'
import { I18N_SEARCH_FILTER } from '../../constants/i18n.constants'
import { parseFloatFromString } from '../../utils/number.utils'
import { FormInput } from '../form'

export const MIN_MODE = 'Min'
export const MAX_MODE = 'Max'

interface IFilterSinglePriceFieldProps {
  name: string;
  id: string;
  mode: 'Min' | 'Max';
}

const FilterSinglePriceField = ({ name, id, mode }: IFilterSinglePriceFieldProps) => {
  const { change } = useForm()
  const { t } = useTranslation()
  const { values } = useFormState()
  const priceFormatter = usePriceFormatter(true)

  const fieldName = `${name}${mode}`
  const isMinField = mode === MIN_MODE
  const minFieldName = `${name}${MIN_MODE}`
  const maxFieldName = `${name}${MAX_MODE}`
  const i18nDisplayKey = `${I18N_SEARCH_FILTER}.${fieldName}`
  const priceFieldInputName = getInputValueFieldName(fieldName)

  const { input: { value: priceValue } } = useField(fieldName)
  const { input: { value: priceInputValue }, meta: { active } } = useField(priceFieldInputName)

  const localizePrice = useCallback((price: string | number | undefined) => (
    price ? `${t(i18nDisplayKey)} ${priceFormatter(price)}` : ''
  ), [t, priceFormatter, i18nDisplayKey])

  useEffect(() => {
    // for change places of max and min values - in that case formValue will change and we need to fix displayed value
    if (priceValue !== '' && !active) {
      change(priceFieldInputName, localizePrice(priceValue))
    }
  }, [change, localizePrice, priceValue, priceInputValue, priceFieldInputName, active])

  const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value
    change(priceFieldInputName, parseFloatFromString(newValue))
  }, [change, priceFieldInputName])

  const onBlur = useCallback(() => {
    const minValue = isMinField ? priceInputValue : values[minFieldName]
    const maxValue = isMinField ? values[maxFieldName] : priceInputValue

    if (minValue && maxValue && Number(minValue) > Number(maxValue)) {
      change(minFieldName, maxValue)
      change(maxFieldName, minValue)

      const newValue = isMinField ? maxValue : minValue
      change(priceFieldInputName, localizePrice(newValue))

      return
    }

    change(fieldName, priceInputValue)
    change(priceFieldInputName, localizePrice(priceInputValue))
  }, [
    change,
    localizePrice,
    values,
    fieldName,
    priceFieldInputName,
    priceInputValue,
    maxFieldName,
    minFieldName,
    isMinField,
  ])

  const onFocus = useCallback(() => {
    change(priceFieldInputName, priceValue || '')
  }, [change, priceValue, priceFieldInputName])

  return (
    <FormInput
      id={id}
      name={priceFieldInputName}
      placeholder={`${I18N_SEARCH_FILTER}.${mode.toLocaleLowerCase()}`}
      value={priceInputValue}
      onBlur={onBlur}
      onFocus={onFocus}
      handleChange={handleChange}
    />
  )
}

export default FilterSinglePriceField
