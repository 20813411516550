import classNames from 'classnames'
import { memo } from 'react'

import formStyles from 'src/components/form/form.module.scss'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_FORM } from 'src/constants/i18n.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { ICategoryStructure, MainCategories } from 'src/yome-categories-fields-config/models/fields.model'

import FieldConstructor from './FieldConstructor'

import styles from './offerForm.module.scss'

interface IOfferFormSection {
  sectionInfo: ICategoryStructure;
  publicId: string;
  mainCategory: MainCategories;
}

const OfferFormSection = ({
  publicId,
  mainCategory,
  sectionInfo: { section: sectionName, info, fields, hasDivider, isTitleHiddenOnDesktop },
}: IOfferFormSection) => {
  const { isDesktop } = useViewport()
  const displayHeader = (!isTitleHiddenOnDesktop) || (!isDesktop && isTitleHiddenOnDesktop)

  return (
    <section className={classNames({ [styles.divider]: hasDivider }, styles.formSection)}>
      {displayHeader ? (
        <div>
          <Heading
            level='h3'
            label={`${I18N_FORM}.section.${sectionName}`}
            className='mobile-h6 desktop-h6'
          />
          {info && (
            <Paragraph className={classNames('caption-14', formStyles.fieldInfo)}>
              {info}
            </Paragraph>
          )}
        </div>
      )
        : null
      }
      {Object.keys(fields).map((field) => (
        <FieldConstructor key={field} publicId={publicId} mainCategory={mainCategory} fieldConfig={fields[field]} />
      ))}
    </section>
  )
}

export default memo(OfferFormSection)
