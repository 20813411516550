import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import useSWR from 'swr'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_GLOBAL, I18N_MESSENGER_BLOCK_USER_MODAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useModalsContext } from 'src/contexts/openModalContext'
import { ContextModalType, IModalWithCloseAndContext } from 'src/models/modal.model'
import { handleBlockUser } from 'src/services/messenger.service'

import InfoModal from '../infoModal/InfoModal'

import styles from './blockModals.module.scss'

const BlockUserModal = (
  { handleClose, payload }: IModalWithCloseAndContext,
) => {
  const { mutate: mutateChats } = useSWR(SWRkeys.getAllChats)
  const { setContextModal } = useModalsContext()
  const { t } = useTranslation()

  const blockUser = useCallback(async () => {
    if (!payload || !handleClose) {
      return
    }

    try {
      const { partnerId, chatId } = payload

      await handleBlockUser(partnerId, chatId)
      mutateChats([])

      setContextModal({ modalType: ContextModalType.userBlocked, isNonFullscreen: true })
    } catch (err) {
      toast.error(`${I18N_GLOBAL}.serverError`)
    }
  }, [payload, handleClose, mutateChats, setContextModal])

  const footerButton = (
    <div className={styles.buttonColumn}>
      <RegularButton
        id={ID.blockedMessengerUserModalCloseButton}
        label={`${I18N_MESSENGER_BLOCK_USER_MODAL}.button.blockUser`}
        buttonType={RegularButtonTypes.blackNoBorder}
        leftIcon={{ name: 'hand' }}
        onClick={blockUser}
      />
      <RegularButton
        id={ID.blockedMessengerUserModalBlockButton}
        label={`${I18N_MESSENGER_BLOCK_USER_MODAL}.button.backToChat`}
        buttonType={RegularButtonTypes.transparentWithBorder}
        onClick={handleClose}
      />
    </div>

  )

  return (
    <InfoModal
      title={`${I18N_MESSENGER_BLOCK_USER_MODAL}.header`}
      modalFooter={footerButton}
      className={styles.modalContainer}
    >
      <div className='bodySizeL'>
        <p>
          {t(`${I18N_MESSENGER_BLOCK_USER_MODAL}.body`)}
        </p>
      </div>
    </InfoModal>
  )
}

export default memo(BlockUserModal)
