import { useEffect, useState } from 'react'
import classNames from 'classnames'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import Paragraph from 'src/components/paragraph/Paragraph'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { ONE_MIN } from 'src/constants/general.constants'
import { ID } from 'src/constants/id.constants'
import { I18N_SHOWCASE_AREA, I18N_GLOBAL } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useIsTabActiveContext } from 'src/contexts/activeTabContext'

import texts from './showcaseTexts'
import styles from './showcaseArea.module.scss'

interface IShowcaseAreaProps {
  type?: 'vertical' | 'horizontal' | 'mobile-big' | 'mobile-small';
}

const AD_VARIANTS = 4
const AD_DELAY = ONE_MIN

const ShowcaseArea = ({ type = 'vertical' }: IShowcaseAreaProps) => {
  const isMobileType = type === 'mobile-big' || type === 'mobile-small'

  const [adNumber, setAdNumber] = useState(Math.ceil(Math.random() * AD_VARIANTS))

  const isTabActive = useIsTabActiveContext()

  useEffect(() => {
    let interval: NodeJS.Timer | undefined

    if (isTabActive) {
      interval = setInterval(() => {
        setAdNumber((prevNum) => {
          let randomNum

          do {
            randomNum = Math.ceil(Math.random() * AD_VARIANTS)
          } while (randomNum === prevNum)

          return randomNum
        })
      }, AD_DELAY)
    }

    if (!isTabActive && interval) {
      clearInterval(interval)
    }

    return () => { if (interval) clearInterval(interval) }
  }, [isTabActive])

  const altText = texts.find((text) => text.id === adNumber)?.text || ''

  return (
    <div className={classNames(styles[type], styles.showcaseContainer)}>
      <img
        src={`/images/showcaseArea/${type}Block-${adNumber}.jpg`}
        alt={altText}
        className={styles.backgroundImg}
      />
      <RegularButton
        id={ID.showCaseAreaLearnMoreButton}
        buttonType={RegularButtonTypes.blackNoBorder}
        label={`${I18N_GLOBAL}.learnMore`}
        linkTo={YomeURL.advertising}
        className={styles.learnMoreBtn}
        size={isMobileType ? RegularButtonSizes.small : RegularButtonSizes.medium}
      />
      <button className={styles.sponsoredButton}>
        <Paragraph text={`${I18N_SHOWCASE_AREA}.sponsored`} />
        <SvgIcon name={'help'} size={16} currentColor='stroke'/>
      </button>
    </div>
  )
}

export default ShowcaseArea
