import classNames from 'classnames'
import { useCallback } from 'react'
import { useForm, useFormState } from 'react-final-form'

import IconButton from 'src/components/button/IconButton'
import { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import ModalContent from 'src/components/modal/mobileModals/ModalContent'
import { I18N_FORM, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { PostFormFields } from 'src/models/post.model'
import { useToggle } from 'src/utils/hooks.utils'
import { getOfferParts } from 'src/utils/post.utils'
import { getMainCategory } from 'src/yome-categories-fields-config/utils/fieldConfig.utils'

import DraftSavedModal from './DraftSavedModal'
import OfferFormSection from './OfferFormSection'

import styles from './offerForm.module.scss'

interface IMultistepOfferFormProps {
  setPartIndex: (value: number) => void;
  publicId: string;
  categoryPath: string[];
  partIndex: number;
  isEditDraftPage?: boolean;
}

const MultistepOfferForm = ({
  setPartIndex,
  publicId,
  categoryPath,
  partIndex,
  isEditDraftPage = false,
}: IMultistepOfferFormProps) => {
  const { structure, totalParts, isLastPart } = getOfferParts(categoryPath, partIndex)

  const goNextPart = useCallback(() => setPartIndex(partIndex + 1), [partIndex, setPartIndex])
  const goPrevPart = useCallback(() => setPartIndex(partIndex - 1), [partIndex, setPartIndex])

  const { submit } = useForm()
  const { errors, values } = useFormState<PostFormFields>()
  const hasFormErrors = Boolean(errors && Object.keys(errors).length)

  const [isDraftSavedModalOpen, toggleDraftSavedModal] = useToggle()

  const validateAndGoNexPart = useCallback(async () => {
    const formErrors = await submit()

    if (!formErrors) {
      goNextPart()
    }
  }, [submit, goNextPart])

  const onDraftClose = useCallback(() => {
    toggleDraftSavedModal()
  }, [toggleDraftSavedModal])

  const headingLabel = isEditDraftPage ? `${I18N_GLOBAL}.newItem` : `${I18N_FORM}.header.editOffer`
  const onCrossClick = isEditDraftPage ? onDraftClose : undefined
  const postUrl = YomeURL.offer(categoryPath[categoryPath.length - 1], values.slug)
  const crossLinkTo = isEditDraftPage ? undefined : postUrl

  const nextStepButtonProps = {
    id: ID.editDraftOpenNextStep,
    buttonType: RegularButtonTypes.main,
    label: `${I18N_FORM}.button.nextStep`,
    rightIcon: { name: 'arrow-right', size: 24 },
    onClick: validateAndGoNexPart,
    disabled: hasFormErrors,
  }

  const submitButtonProps = {
    id: ID.editDraftPostDraft,
    buttonType: RegularButtonTypes.main,
    label: `${I18N_FORM}.button.placeOffer`,
    onClick: submit,
    disabled: hasFormErrors,
  }

  return (
    <>
      <ModalContent
        primaryButtonProps={isLastPart ? submitButtonProps : nextStepButtonProps}
      >
        <div className={classNames(styles.mobileFormContainer, 'mobile-paddings')}>
          <div className={styles.mobileHeaderContainer}>
            <div className={styles.mobileHeaderContainer}>
              {partIndex > 0 && (
                <IconButton
                  iconName='arrow-left'
                  size={24}
                  onClick={goPrevPart}
                  id={ID.modalBackButton}
                />
              )}
              <Heading label={headingLabel} className='mobile-h4' />
              <span className='label-20'>
                {`${partIndex + 1}/${totalParts}`}
              </span>
            </div>
            <IconButton
              iconName='close-cross'
              size={24}
              onClick={onCrossClick}
              linkTo={crossLinkTo}
              id={ID.modalCloseButton}
            />
          </div>
          <OfferFormSection
            publicId={publicId}
            sectionInfo={structure[partIndex]}
            mainCategory={getMainCategory(categoryPath[0])}
          />
        </div>
      </ModalContent>
      {isDraftSavedModalOpen && <DraftSavedModal />}
    </>
  )
}

export default MultistepOfferForm
