import classNames from 'classnames'
import { FormApi, SubmissionErrors } from 'final-form'
import { ReactNode, useCallback } from 'react'
import { Form } from 'react-final-form'

import { useViewport } from 'src/contexts/viewportContext'
import { useToggle } from 'src/utils/hooks.utils'

import SettingLineCurrentInfo from './SettingLineCurrentInfo'
import SettingLineEditButton from './SettingLineEditButton'
import SettingLineEditFormContent from './SettingLineEditFormContent'
import SettingLineEditModal from './SettingLineEditModal'

import styles from './setting.module.scss'

interface SettingLineProps {
  settingId: string;
  title: string;
  subheading?: string;
  currentValue: string;
  btnLabel?: 'edit' | 'change';
  initialFieldName?: string;
  children: ReactNode;
  onSubmit?: (params: any) => Promise<SubmissionErrors>;
  disabled?: boolean;
  valueIcon?: string;
  isSubmitButtonHidden?: boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SettingLine = <T,>({
  settingId,
  title,
  subheading,
  currentValue,
  btnLabel = 'edit',
  initialFieldName,
  children,
  onSubmit,
  disabled = false,
  valueIcon,
  isSubmitButtonHidden = false,
}: SettingLineProps) => {
  const [isFormOpen, toggleShowForm] = useToggle(false)
  const { isDesktop } = useViewport()
  const initialValues = initialFieldName ? { [initialFieldName]: currentValue } : {}

  const submitForm = useCallback(async (values: T, form: FormApi<T, Partial<T>>) => {
    const errors = onSubmit && await onSubmit(values)

    if (errors && Object.keys(errors).length) {
      return errors
    }

    toggleShowForm()
    form.reset()

    return undefined
  }, [onSubmit, toggleShowForm])

  return (
    <Form
      onSubmit={submitForm}
      initialValues={initialValues as Partial<T>}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className={classNames({ [styles.selected]: isFormOpen }, { [styles.disabled]: disabled })}
        >
          <div className={styles.main}>
            <div className={styles.content}>
              {!isFormOpen && (
                <>
                  <SettingLineCurrentInfo
                    onClick={disabled ? undefined : toggleShowForm}
                    title={title}
                    currentValue={currentValue}
                    valueIcon={valueIcon}
                  />
                  <SettingLineEditButton
                    toggle={toggleShowForm}
                    settingId={settingId}
                    btnLabel={btnLabel}
                    disabled={disabled}
                  />
                </>
              )}
              {isFormOpen && (
                isDesktop ? (
                  <SettingLineEditFormContent
                    settingId={settingId}
                    toggle={toggleShowForm}
                    title={title}
                  >
                    {children}
                  </SettingLineEditFormContent>
                ) : (
                  <SettingLineEditModal
                    onSubmit={submitForm}
                    toggle={toggleShowForm}
                    title={title}
                    subheading={subheading}
                    initialValues={initialValues as Partial<T>}
                    isSubmitButtonHidden={isSubmitButtonHidden}
                  >
                    {children}
                  </SettingLineEditModal>
                )
              )}
            </div>
          </div>
        </form>
      )}
    />
  )
}

export default SettingLine
