import React, { useCallback } from 'react'

import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import { ICategory } from 'src/yome-categories-fields-config/models/category.model'
import { getPathToCurrentCategory } from 'src/yome-categories-fields-config/utils/category.utils'

import ButtonLink from '../../../../components/button/ButtonLink'

import styles from './subCategoryList.module.scss'

interface ISubCategoryListProps {
  onSubCategoryChange: (path: string[]) => void;
  className?: string;
  chosenCategory: ICategory;
}

const SubCategoryList = React.memo(({
  onSubCategoryChange,
  chosenCategory,
}: ISubCategoryListProps) => {
  const handleCategoryClick = useCallback((newCategory: string) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    const finalPath = getPathToCurrentCategory(newCategory)
    onSubCategoryChange(finalPath)
  }, [onSubCategoryChange])

  if (!(chosenCategory.items && chosenCategory.items.length)) {
    return null
  }

  return (
    <ul className={styles.wrapper}>
      {chosenCategory.items.map((categoryItem: ICategory) => (
        <li key={categoryItem.title}>
          <ButtonLink
            onClick={handleCategoryClick(categoryItem.title)}
            label={(`${I18N_CATEGORIES}.${categoryItem.title}`)}
            id={concatId(ID.newItemButtonCategory, categoryItem.title)}
          />
        </li>
      ))}
    </ul>
  )
})

export default SubCategoryList
