import classNames from 'classnames'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ADVERTISING } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { ADVERTISING_FEATURES } from 'src/constants/staticPages.constants'

import SectionHeading from '../../SectionHeading'

import styles from '../../advertising.module.scss'

const Features = () => (
  <>
    <SectionHeading
      label='features.heading'
    />
    <ul className={styles.features}>
      {ADVERTISING_FEATURES.map((feature) => (
        <li key={feature} className={classNames(styles.feature, 'static-block')}>
          <Paragraph
            className='label-28'
            text={`${I18N_ADVERTISING}.features.${feature}.title`}
          />
          <Paragraph
            className='bodySizeM'
            text={`${I18N_ADVERTISING}.features.${feature}.subtitle`}
          />
        </li>
      ))}
    </ul>
    <RegularButton
      label={`${I18N_ADVERTISING}.features.btn`}
      id={ID.advertisingGetStartedButton}
      buttonType={RegularButtonTypes.main}
      linkTo={YomeURL.help}
      className={classNames(styles.featuresBtn, 'static-btn')}
      size={RegularButtonSizes.large}
    />
  </>
)

export default Features
