import classNames from 'classnames'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { generateIdWithPrefix, IdStructure, PrefixAction } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { OfferStatus } from 'src/models/offerStatus.model'

import { IPropsWithOffer, PostListType } from '../../models/post.model'
import MyOfferCardImg from './components/myOfferCardImg/MyOfferCardImg'
import MyOfferCardInfo from './components/myOfferCardInfo/MyOfferCardInfo'
import MyOfferCardMiddleStack from './components/myOfferCardMiddleStack/MyOfferCardMiddleStack'
import MyOfferCardRightStack from './components/MyOfferCardRightStack'
import MyOfferCardStats from './components/myOfferCardStats/MyOfferCardStats'

import styles from './styles.module.scss'

interface IMyOfferCardProps extends IPropsWithOffer {
  idPrefix: IdStructure;
  mutator: () => void;
  listType: PostListType;
}

const MyOfferCard: FC<IMyOfferCardProps> = ({
  idPrefix,
  offer,
  mutator,
  listType,
}) => {
  const linkToOfferId = generateIdWithPrefix(idPrefix!, PrefixAction.Link, offer.slug)
  const { isDesktop } = useViewport()

  const isOnModeration = offer.status === OfferStatus.moderating

  return (
    <article className={styles.cardWrapper}>
      <Link
        className={classNames(styles.container, { semitransparent: isOnModeration })}
        id={linkToOfferId}
        to={YomeURL.offer(offer.category, offer.slug)}
      >
        <MyOfferCardImg offer={offer} />
        <div className={styles.content}>
          <MyOfferCardInfo
            offer={offer}
          />
          <MyOfferCardMiddleStack
            offer={offer}
            listType={listType}
          />
          {!isDesktop && (
            <MyOfferCardStats
              idPrefix={idPrefix}
              stats={offer.statistic}
              isOneRow
              haveMessengerStatistic
            />
          )}
        </div>
      </Link>
      {isDesktop && (
        <MyOfferCardRightStack
          idPrefix={idPrefix}
          offer={offer}
          mutator={mutator}
          listType={listType}
        />
      )}
    </article>
  )
}

export default MyOfferCard
