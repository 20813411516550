import classNames from 'classnames'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import Paragraph from 'src/components/paragraph/Paragraph'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_ADVERTISING } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { ADVERTISING_TOOLKIT } from 'src/constants/staticPages.constants'

import SectionHeading from '../../SectionHeading'

import styles from './targetingToolkit.module.scss'

const TargetingToolkit = () => (
  <div className={styles.main}>
    <SectionHeading
      label='toolkit.heading'
    />
    <Paragraph
      className={classNames(styles.subtitle, 'body-text desktop-body-28 bodySizeL')}
      text={`${I18N_ADVERTISING}.toolkit.subheading`}
    />
    <ul className={styles.list}>
      {ADVERTISING_TOOLKIT.map((tool) => (
        <li key={tool} className={classNames(styles.listItem, 'static-block')}>
          <SvgIcon name={`advertising/${tool}`} />
          <Paragraph
            text={`${I18N_ADVERTISING}.toolkit.${tool}.title`}
            className='label-28'
          />
          <Paragraph
            text={`${I18N_ADVERTISING}.toolkit.${tool}.subtitle`}
            className='bodySizeM'
          />
        </li>
      ))}
    </ul>
    <RegularButton
      buttonType={RegularButtonTypes.main}
      id={ID.advertisingButtonRequestConsultation}
      label={`${I18N_ADVERTISING}.toolkit.btn`}
      size={RegularButtonSizes.large}
      className={'static-btn'}
      linkTo={YomeURL.help}
    />
  </div>
)

export default TargetingToolkit
