import React from 'react'
import { useTranslation } from 'react-i18next'

import Duration from 'src/components/duration/Duration'
import Label, { LabelType } from 'src/components/offerCardLabel/OfferCardLabel'
import { I18N_ARCHIVE_MODAL, I18N_MY_OFFER_CARD } from 'src/constants/i18n.constants'
import { ArchivedSinglePost, PostListType } from 'src/models/post.model'

import styles from '../../styles.module.scss'

interface IMyOfferCardMiddleStack {
  listType: PostListType;
  offer: ArchivedSinglePost;
}

const MyOfferCardMiddleStack: React.FC<IMyOfferCardMiddleStack> = ({ listType, offer }) => {
  const { archiveReason } = offer
  const { t } = useTranslation()

  return (
    <>
      {listType !== PostListType.archive ? (
        <div className={styles.middleStack}>
          <Duration offer={offer} />
          {listType === PostListType.inactive && (
            <div className={styles.labelWrapper}>
              <Label text={`${I18N_MY_OFFER_CARD}.label.expired`} rightIcon={LabelType.alert} />
            </div>
          )}
        </div>
      ) : (
        <p className='bodySizeM'>
          {t(`${I18N_ARCHIVE_MODAL}.${archiveReason}`)}
        </p>
      )}
    </>
  )
}

export default MyOfferCardMiddleStack
