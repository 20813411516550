import { memo } from 'react'
import { useLocation } from 'react-router-dom'

import MenuPoint, { MenuPointSize } from 'src/components/menu/MenuPoint'
import { I18N_PROFILE_ACTION } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import { TDropdownSection } from 'src/models/dropdown.model'

import { useViewport } from 'src/contexts/viewportContext'
import styles from './sections.module.scss'

interface IProfileSectionProps {
  section: TDropdownSection;
}

const ProfileSection = ({ section }: IProfileSectionProps) => {
  const { pathname } = useLocation()
  const { isDesktop } = useViewport()

  return (
    <ul className={styles.singleSection}>
      {Object.keys(section).map((action) => (
        <MenuPoint
          key={action}
          id={concatId(ID.profilePageLinkToProfile, action)}
          label={`${I18N_PROFILE_ACTION}.${action}`}
          size={MenuPointSize.LARGE}
          isActive={ pathname.includes(action)}
          iconRight={isDesktop ? undefined : 'chevron-right'}
          {...section[action]}
        />
      ))}
    </ul>
  )
}

export default memo(ProfileSection)
