import { ClientToServerEvents, ServerToClientEvents, SOCKET_CONNECTION_ERROR } from 'src/services/messenger.service'

import YomeAPI from './network.constants'

const SWRkeys = {
  // api

  recomendedOffers: YomeAPI.recomendedOffers,
  popularOffers: YomeAPI.popularOffers,
  profile: YomeAPI.profile,
  search: YomeAPI.search,
  offerProfileCounters: YomeAPI.offerProfileCounters,
  getSavedSearch: YomeAPI.getSavedSearch,
  favoriteCounters: YomeAPI.favoriteCounters,
  getOffer: YomeAPI.getOffer,
  getOfferOriginalText: YomeAPI.getOfferOriginalText,
  getUser: YomeAPI.getUser,
  userOffers: YomeAPI.userOffers,
  lastPublication: YomeAPI.lastPublication,
  favoriteList: YomeAPI.favoriteList,
  viewedOffers: YomeAPI.viewedOffers,
  getDraft: YomeAPI.getDraft,
  getPublicOffer: YomeAPI.getPublicOffer,
  searchSimilar: YomeAPI.searchSimilar,
  searchWithParams: YomeAPI.searchWithParams,
  getUserPreferences: YomeAPI.getUserPreferences,
  getUserAddressHistory: YomeAPI.getUserAddressHistory,
  getUsedEmails: YomeAPI.getUsedEmails,

  currentUser: YomeAPI.authCurrentUser,
  authVerifyEmail: YomeAPI.authVerifyEmail,

  // messenger
  socketConnectionError: SOCKET_CONNECTION_ERROR,
  chatHistory: (chatId: string) => `${ClientToServerEvents.GetChatHistory}.${chatId}`,
  getAllChats: ClientToServerEvents.GetAllChats,
  hasMessangerUnreadChats: ServerToClientEvents.hasUnreadChats,
  currentChat: 'swr.currentChat',

  isModerationPanelOpen: 'swr.moderationPanel',
}

export default SWRkeys
