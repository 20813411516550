import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_GLOBAL, I18N_VERIFY_PHONE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { IVerifyPhoneModalProps } from 'src/models/verifyPhone.model'

import InfoModal from '../infoModal/InfoModal'

const LimitReachedModal = ({ handleClose }: IVerifyPhoneModalProps) => {
  const modalFooter = (
    <RegularButton
      id={ID.verifyPhoneModalCloseButton}
      buttonType={RegularButtonTypes.main}
      label={`${I18N_GLOBAL}.gotIt`}
      onClick={handleClose}
    />
  )

  return (
    <InfoModal modalFooter={modalFooter}>
      <EmptyStateMessage
        icon={'something-went-wrong-2'}
        title={`${I18N_VERIFY_PHONE}.limitReached.title`}
        titleIconProps={{ name: 'alert-error' }}
        text={`${I18N_VERIFY_PHONE}.limitReached.text`}
      />
    </InfoModal>
  )
}

export default LimitReachedModal
