import cookie from 'cookiejs'

import config from 'src/config'

export enum RequiredPolicyCookies {
  gdprConsent = 'gdprConsent',
  essentialCookies = 'essentialCookies',
}

export const RequiredPolicyCookiesArray = Object.values(RequiredPolicyCookies)

export enum OptionPolicyCookies {
  analyticsCookies = 'analyticsCookies',
  functionalCookies = 'functionalCookies',
  advertisingCookies = 'advertisingCookies',
}

export const OptionPolicyCookiesArray = Object.values(OptionPolicyCookies)

export type PrivacyPolicyCookies = RequiredPolicyCookies | OptionPolicyCookies

export type CookieStatuses = { [key in PrivacyPolicyCookies]: boolean }

export const getCookie = (cookieName: string): string | undefined => cookie()[cookieName]

export const getBoolCookie = (cookieName: string): boolean => {
  const gdprCookie = getCookie(cookieName)

  if (gdprCookie === undefined) {
    return false
  }

  if (!['true', 'false'].includes(gdprCookie)) {
    throw Error(`${gdprCookie} is not bool`)
  }

  return gdprCookie === 'true'
}

const defaultSaveCookieOptions = { expires: 365, domain: config.cookieDomain }

export const setCookie = (cookieName: string, cookieValue: string | boolean) => {
  cookie.set(cookieName, cookieValue.toString(), defaultSaveCookieOptions)
}

export const savePrivacyCookies = (cookies: CookieStatuses) => {
  Object.keys(cookies).forEach((cookieName) => {
    const isCookieActive = cookies[cookieName as PrivacyPolicyCookies]

    setCookie(cookieName, isCookieActive)
  })
}

export const setRequiredPrivacyCookies = () => {
  const gdprCookie = getBoolCookie(RequiredPolicyCookies.gdprConsent)

  if (gdprCookie === undefined) {
    setCookie(RequiredPolicyCookies.gdprConsent, true)
    setCookie(RequiredPolicyCookies.essentialCookies, true)
  }
}

export const acceptAllOptionPrivacyCookies = (change?: (name: string, value: boolean) => void) => {
  OptionPolicyCookiesArray.forEach((cookieName) => {
    setCookie(cookieName, true)
    if (change) change(cookieName, true)
  })
}

export const rejectAllOptionPrivacyCookies = (change?: (name: string, value: boolean) => void) => {
  OptionPolicyCookiesArray.forEach((cookieName) => {
    setCookie(cookieName, false)
    if (change) change(cookieName, false)
  })
}

export const rejectUndefinedOptionPrivacyCookies = () => {
  OptionPolicyCookiesArray.forEach((cookieName) => {
    const cookieValue = getBoolCookie(cookieName)

    if (cookieValue === undefined) {
      setCookie(cookieName, false)
    }
  })
}

export const acceptAllPrivacyCookies = () => {
  RequiredPolicyCookiesArray.forEach((cookieName) => {
    setCookie(cookieName, true)
  })
  acceptAllOptionPrivacyCookies()
}

export const rejectAllPrivacyCookies = () => {
  RequiredPolicyCookiesArray.forEach((cookieName) => {
    setCookie(cookieName, true)
  })
  rejectAllOptionPrivacyCookies()
}

export const toggleBooleanCookie = (
  cookieName: string,
  curValue: boolean,
  change: (name: string, value: boolean) => void,
) => {
  setCookie(cookieName, curValue ? 'false' : 'true')
  change(cookieName, !curValue)
}

export const isAllOptionalCookieActive = () => {
  const cookieValues = OptionPolicyCookiesArray.map(
    (optionCookie) => getBoolCookie(optionCookie),
  )

  return cookieValues.every((element) => element === true)
}
