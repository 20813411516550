import ComingSoonEmptyState from 'src/components/emptyStateMessage/ComingSoonEmptyState'
import { I18N_REVIEWS } from 'src/constants/i18n.constants'
import ProfileHeading from './ProfileHeading'

const FeedbackRoute = () => (
  <div>
    <ProfileHeading
      label={`${I18N_REVIEWS}.heading`}
    />
    <ComingSoonEmptyState />
  </div>
)

export default FeedbackRoute
