import YomeAPI from 'src/constants/network.constants'
import { IPhoneNumber } from 'src/models/verifyPhone.model'
import { sendFormPost } from 'src/utils/network.utils'

export const sendSms = (phoneNumber: IPhoneNumber) => (
  sendFormPost(YomeAPI.sendVerifyPhoneSms, phoneNumber))

export const sendCall = (phoneNumber: IPhoneNumber) => (
  sendFormPost(YomeAPI.sendVerifyPhoneCall, phoneNumber))

export const validateCode = (phoneNumber: IPhoneNumber, code: string) => (
  sendFormPost(YomeAPI.verifyPhoneCode, { ...phoneNumber, code }))
