import NoPosts, { NoPostsType } from 'src/components/noPosts/NoPosts'
import PostListLoader from 'src/components/skeletons/PostListLoader'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import SWRkeys from 'src/constants/swr.constants'
import { useGetSavedSearchList } from 'src/services/favorite.service'

import SearchCard from '../SearchCard'

import styles from '../favorites.module.scss'

const SavedSearchRoute = () => {
  const { data: searches, isLoading, mutate } = useGetSavedSearchList()

  if (isLoading || !searches) {
    return <PostListLoader />
  }

  if (!searches.length) {
    return <NoPosts type={NoPostsType.savedSearch}/>
  }

  return (
    <ul className={styles.savedSearchList}>
      {
        searches.map((singleSearch) => (
          <li key={singleSearch._id}>
            <SearchCard
              searchParams={singleSearch.searchParams}
              savedSearchId={singleSearch._id}
              refetchSavedSearch={mutate}
            />
          </li>
        ))
      }
    </ul>
  )
}

const SavedSearchRouteBoundary = () => (
  <SWRErrorBoundary swrKey={SWRkeys.getSavedSearch}>
    <SavedSearchRoute />
  </SWRErrorBoundary>
)

export default SavedSearchRouteBoundary
