import classNames from 'classnames'
import { Trans } from 'react-i18next'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ABOUT_US_HISTORY } from 'src/constants/i18n.constants'
import { HISTORY_POINTS } from 'src/constants/staticPages.constants'

import aboutStyles from '../../about.module.scss'
import styles from './history.module.scss'

const History = () => (
  <div>
    <Heading
      level='h2'
      label={`${I18N_ABOUT_US_HISTORY}.title`}
      className={aboutStyles.h2title}
    />
    <div className={classNames('static-block', styles.headerWrapper)}>
      <img className={styles.photo} src='/images/founder.png' alt='founder' />
      <div className={styles.content}>
        <Heading level='h3' className={styles.heading}>
          <Trans>
            {`${I18N_ABOUT_US_HISTORY}.ceo.title`}
          </Trans>
        </Heading>
        <Paragraph
          text={`${I18N_ABOUT_US_HISTORY}.ceo.desc`}
          className={styles.subheading}
        />
      </div>
    </div>
    <ul className={styles.list}>
      {HISTORY_POINTS.map((point) => (
        <li key={point} className={styles.item}>
          <Paragraph
            text={`${I18N_ABOUT_US_HISTORY}.title.${point}`}
            className={styles.listItemTitle}
          />
          <Paragraph
            className={styles.listItemText}
            text={`${I18N_ABOUT_US_HISTORY}.text.${point}`}
          />
        </li>
      ))}
    </ul>
  </div>
)

export default History
