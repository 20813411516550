import classNames from 'classnames'
import React from 'react'

import OfferCardLabel from 'src/components/offerCardLabel/OfferCardLabel'
import { NO_PHOTOS } from 'src/constants/images.constants'
import { IPropsWithOffer } from 'src/models/post.model'
import { getThumbBigUrl } from 'src/utils/images.utils'

import styles from './styles.module.scss'

const MyOfferCardImg: React.FC<IPropsWithOffer> = ({ offer }) => {
  const image = getThumbBigUrl(offer.photos[0], offer.publicId)
  const src = image ? encodeURI(image) : NO_PHOTOS
  const imagesNumber = offer.photos.length

  return (
    <div
      title={offer.title}
      className={classNames(styles.image, { [styles.noPhoto]: !image })}
      style={{ backgroundImage: `url('${src}')` }}
    >
      {imagesNumber > 1 && (
        <div className={styles.imagesNum}>
          <OfferCardLabel
            text={imagesNumber.toString()}
            leftIcon='photoCamera'
          />
        </div>
      )}
    </div>

  )
}

export default MyOfferCardImg
