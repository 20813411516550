import { captureException } from '@sentry/react'
import { useEffect } from 'react'
import useSWRImmutable from 'swr/immutable'

import { FORBIDDEN } from 'src/constants/error.constants'
import YomeAPI from 'src/constants/network.constants'
import SWRkeys from 'src/constants/swr.constants'
import { IHelpFormValues } from 'src/models/form.model'
import { ForbiddenError, returnFormErrorsOrShowToast } from 'src/utils/error.utils'
import { validateHelpForm } from 'src/utils/form.utils'
import { sendFormPost, sendGet, sendPostWithFormData } from 'src/utils/network.utils'

import { useCurrentUser } from './currentUser.service'

const createFormDataFromValues = (values: IHelpFormValues) => {
  const formData = new FormData()

  formData.append('topic', values.topic)
  formData.append('name', values.name)
  formData.append('email', values.email)
  formData.append('message', values.message)

  if (values.files) {
    values.files.forEach((file) => {
      formData.append('files', file)
    })
  }

  if (values.emailVerifyCode) {
    formData.append('emailVerifyCode', values.emailVerifyCode)
  }

  return formData
}

const handleHelpFormSubmit = async (
  values: IHelpFormValues,
  withEmailVerifyCode: boolean,
) => {
  const errors = validateHelpForm(values, withEmailVerifyCode)

  if (Object.keys(errors).length !== 0) {
    return errors
  }

  const formData = createFormDataFromValues(values)

  try {
    return await sendPostWithFormData(YomeAPI.help, formData)
  } catch (error) {
    if (error instanceof ForbiddenError) {
      return { FORBIDDEN }
    }

    return returnFormErrorsOrShowToast(error as Error)
  }
}

const getVerificationCode = async (
  email: string,
  name?: string,
) => sendFormPost(YomeAPI.getVerificationCode, { email, name })

const getUserUsedEmails = async (path: string): Promise<string[]> => {
  try {
    return await sendGet(path)
  } catch (error) {
    captureException(error as Error)

    return []
  }
}

const useGetUserUsedEmails = () => {
  const { data: currentUser } = useCurrentUser()

  const { data = [], mutate } = useSWRImmutable<string[]>(
    SWRkeys.getUsedEmails,
    currentUser ? getUserUsedEmails : () => [],
  )

  useEffect(() => {
    if (currentUser) {
      mutate()
    }
  }, [currentUser, mutate])

  return { data }
}

export {
  createFormDataFromValues,
  handleHelpFormSubmit,
  getVerificationCode,
  useGetUserUsedEmails,
}
