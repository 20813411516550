import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ABOUT_US } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { usePageTitle } from 'src/utils/hooks.utils'
import { getStaticPageMetaTitle } from 'src/utils/meta.utils'

import Benefits from './components/benefits/Benefits'
import HereYouCan from './components/hereYouCan/HereYouCan'
import History from './components/history/History'
import MainFeatures from './components/mainFeatures/MainFeatures'
import Mission from './components/mission/Mission'
import WeCare from './components/weCare/WeCare'

import styles from './about.module.scss'

const About = () => (
  <div className='static-page-wrapper'>
    <div className={styles.container}>
      <Heading
        label={`${I18N_ABOUT_US}.heading`}
        className="static-heading mobile-h1 desktop-h1"
      >
        &nbsp;
        <span>
          YOME
        </span>
      </Heading>
      <Paragraph
        text={`${I18N_ABOUT_US}.subheading`}
        className={styles.subheading}
      />
      <div className={styles.components}>
        <HereYouCan />
        <History />
        <Mission />
        <MainFeatures />
        <Benefits />
        <WeCare />
      </div>
    </div>
  </div>
)

const AboutRoute = () => {
  usePageTitle(getStaticPageMetaTitle(YomeURL.about))

  return <About />
}

export default AboutRoute
