import classNames from 'classnames'
import React from 'react'
import { useFormState } from 'react-final-form'

import { FormSearch } from 'src/components/form'
import Heading from 'src/components/heading/Heading'
import { I18N_CATEGORIES, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'

import CategoryList from '../CategoryList'
import CategorySearchList from '../categorySearchList/CategorySearchList'

import styles from './newItemCategorySelector.module.scss'

interface ICategorySelectorProps {
  onChange: (value: string[]) => void;
  searchFieldName: string;
}
const NewItemCategorySelector = React.memo(({ onChange, searchFieldName }: ICategorySelectorProps) => {
  const { values } = useFormState()
  const hasSearchQuery = !!values.searchQuery

  return (
    <div className={styles.container}>
      <Heading label={`${I18N_GLOBAL}.newItem`} className='desktop-h4' />
      <div className={styles.main} >
        <div className={styles.list}>

          <FormSearch
            name={searchFieldName}
            id={ID.newItemSearchForm}
            placeholder={`${I18N_CATEGORIES}.search`}
            fieldWrapperProps={{
              name: searchFieldName,
              className: classNames(styles.search),
            }}
          />
          {hasSearchQuery
            ? (
              <CategorySearchList
                onCategoryChange={onChange}
                searchFieldName={searchFieldName}
              />
            )
            : (
              <CategoryList
                onCategoryChange={onChange}
                searchFieldName={searchFieldName}
              />
            )
          }
        </div>
      </div>
    </div>
  )
})

export default NewItemCategorySelector
