import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import ImageOrNoPhoto from 'src/components/imageOrNoPhoto/ImageOrNoPhoto'
import SvgIcon from '../../svgIcon/SvgIcon'
import styles from './imageUpload.module.scss'

interface IUploadPhotoProps {
  imageSrc: string;
  fileName: string;
  hasCaption: boolean;
  handleDelete: () => void;
  className?: string;
}

const UploadedPhoto = ({ imageSrc, hasCaption, handleDelete, className, fileName } : IUploadPhotoProps) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.singleImageBlock, className)}>
      <div className={styles.singleImage}>
        <button type='button' className={styles.crossBtn} onClick={handleDelete}>
          <SvgIcon name='close-cross' size={13} />
        </button>
        <ImageOrNoPhoto src={imageSrc} alt={fileName} className={styles.image}/>
      </div>
      {hasCaption && (
        <p className={classNames('caption-14', styles.fieldInfo)}>
          {t('photo.coveringPhoto')}
        </p>
      )}
    </div>
  )
}

export default UploadedPhoto
