import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import { I18N_POST } from 'src/constants/i18n.constants'
import YomeAPI from 'src/constants/network.constants'
import { PostListType, SinglePost } from 'src/models/post.model'
import { handleDeletePost } from 'src/services/post.service'
import { handleShareOfferLink } from 'src/utils/post.utils'

export const useHandleDeleteOfferOnOfferPage = (offer: SinglePost) => {
  const navigate = useNavigate()

  return useCallback(async () => {
    await handleDeletePost(offer, () => navigate('/'))
  }, [offer, navigate])
}

export const useHandleShareOfferLink = (title: string) => {
  const { t } = useTranslation()
  const offerLink = window.location.href
  const toastText = t(`${I18N_POST}.shareLink.toast`)

  return useCallback(async () => {
    await handleShareOfferLink(title, offerLink, toastText)
  }, [title, offerLink, toastText])
}

export const useProfileOfferListsReset = (listType: PostListType) => {
  const { mutate } = useSWRConfig()

  const resetOfferList = () => mutate(
    (key) => typeof key === 'string' && key.startsWith(`${YomeAPI.offerProfile}/${listType}`),
    undefined,
    false,
  )

  return { resetOfferList }
}
