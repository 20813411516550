import { I18N_FAVORITES, I18N_PROFILE_ITEMS, I18N_USER } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { IMyOffersCounts, IUserPostList } from './post.model'
import { IFavoriteCountersResponse } from './favorite.model'

export interface ITabItem {
  link: string;
  label: string;
  count?: number;
}

export const getProfileNavLink = (offersCount?: IMyOffersCounts): ITabItem[] => [
  {
    link: YomeURL.offers,
    label: `${I18N_PROFILE_ITEMS}.activeTabBar`,
    count: offersCount?.active,
  },
  {
    link: YomeURL.inactiveOffers,
    label: `${I18N_PROFILE_ITEMS}.inactiveTabBar`,
    count: offersCount?.inactive,
  },
  {
    link: YomeURL.draftOffers,
    label: `${I18N_PROFILE_ITEMS}.draftTabBar`,
    count: offersCount?.draft,
  },
  {
    link: YomeURL.archiveOffers,
    label: `${I18N_PROFILE_ITEMS}.archiveTabBar`,
    count: offersCount?.archive,
  },
]

export const getFavoriteNavLink = (favoriteCounters: IFavoriteCountersResponse): ITabItem[] => [
  {
    link: YomeURL.favorites,
    label: `${I18N_FAVORITES}.offers`,
    count: favoriteCounters.favoriteOffersCount,
  },
  {
    link: YomeURL.savedsearch,
    label: `${I18N_FAVORITES}.search`,
    count: favoriteCounters.savedSearchCount,
  },
  {
    link: YomeURL.favSellers,
    label: `${I18N_FAVORITES}.sellersTabBar`,
  },
  {
    link: YomeURL.comparison,
    label: `${I18N_FAVORITES}.comparisonTabBar`,
  },
]

export const getUserNavLink = (userId: string, userPosts: IUserPostList | undefined): ITabItem[] => [
  {
    link: YomeURL.userPage(userId),
    label: `${I18N_USER}.activeOffers`,
    count: userPosts && userPosts.active.length,
  },
  {
    link: YomeURL.userPageInactivePosts(userId),
    label: `${I18N_USER}.completedOffers`,
    count: userPosts && userPosts.inactive.length,
  },
]
