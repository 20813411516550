import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import YomeURL from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { PostFormFields } from 'src/models/post.model'
import PrivateRoute from 'src/routes/PrivateRoute'
import { updateOffer, useFormOfferData, validateOffer } from 'src/services/post.service'
import { getOfferParts, handleOfferUpdateError } from 'src/utils/post.utils'

import EditOfferForm from '../components/form/EditOfferForm'

const EditOfferRoute = ({ slug }: { slug: string }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isDesktop } = useViewport()
  const [partIndex, setPartIndex] = useState(0)
  const { data: formValues, isLoading, mutate } = useFormOfferData(SWRkeys.getOffer(slug))

  const [currentData, setCurrentData] = useState(formValues)

  useEffect(() => {
    if (!currentData && !isLoading) setCurrentData(formValues)
  }, [isLoading, currentData, formValues])

  if (isLoading || !currentData) {
    return null
  }

  return (
    <PrivateRoute>
      <Form
        initialValues={currentData}
        onSubmit={async (data: PostFormFields) => {
          try {
            const { isLastPart } = getOfferParts(data.categoryPath, partIndex)

            if (!isDesktop && !isLastPart) {
              await validateOffer(data, partIndex, slug)
              setCurrentData(data)
            } else {
              const { categoryPath } = data
              const { slug: newSlug } = await updateOffer(data, slug)
              mutate(data)

              const url = YomeURL.offer(categoryPath[categoryPath.length - 1], newSlug)

              return navigate(url)
            }

            return undefined
          } catch (error) {
            return handleOfferUpdateError(error, data, t)
          }
        }}
        render={function EditDraftFormRender(props) {
          return <EditOfferForm {...props} partIndex={partIndex} setPartIndex={setPartIndex} />
        }}
      />
    </PrivateRoute>
  )
}

const EditOfferRouteBoundary = () => {
  const { slug } = useParams()
  const { isDesktop } = useViewport()

  const FormWrapper = isDesktop ? React.Fragment : FullscreenModalLayout

  return (
    <SWRErrorBoundary swrKey={SWRkeys.getOffer(slug!)}>
      <FormWrapper>
        <EditOfferRoute slug={slug!}/>
      </FormWrapper>
    </SWRErrorBoundary>
  )
}

export default EditOfferRouteBoundary
