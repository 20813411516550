import { RefObject, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { LatLng } from 'use-places-autocomplete'

import {
  DEFAULT_MAP_CIRCLE_SETTINGS,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_SETTINGS,
  GMAP_ID,
  MAP_ICON_IMG_EL,
  ONE_KM,
  SEARCH_CITY_FIELD_NAME,
} from 'src/constants/map.constants'

export const useMapInitialisation = (
  mapRef: RefObject<HTMLElement> | null,
  center: LatLng | undefined,
  zoom: number,
) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)

  useEffect(() => {
    if (!mapRef?.current) {
      return
    }

    const initializedMap = new window.google.maps.Map(mapRef.current, {
      mapId: GMAP_ID,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    })

    setMap(initializedMap)
  }, [setMap, mapRef])

  useEffect(() => {
    if (map) {
      map.setOptions(DEFAULT_MAP_SETTINGS)
    }
  }, [map])

  useEffect(() => {
    if (!map) {
      return
    }

    map.setZoom(zoom)
  }, [map, zoom])

  useEffect(() => {
    if (!map) {
      return
    }

    map.setCenter(center || DEFAULT_MAP_POSITION)
  }, [map, center])

  return map
}

export const useMapMarker = (map: google.maps.Map | null, position: LatLng | undefined) => {
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null)

  useEffect(() => {
    if (!map || !position || marker) {
      return
    }

    const newMarker = new google.maps.marker.AdvancedMarkerElement({
      map,
      position,
      content: MAP_ICON_IMG_EL,
    })

    setMarker(newMarker)
    map.setCenter(position)
  }, [setMarker, map, marker, position])
}

export const useMapCircle = (map: google.maps.Map | null, radius: number | undefined) => {
  const [circle, setCircle] = useState<google.maps.Circle | undefined>()

  useEffect(() => {
    if (!map) {
      return
    }

    const currentRadius = (radius || 0) * ONE_KM

    if (circle) {
      if (circle.getRadius() !== (currentRadius)) {
        circle.setRadius(currentRadius)
      }

      return
    }

    if (!currentRadius) {
      return
    }

    const newCircle = new google.maps.Circle({
      ...DEFAULT_MAP_CIRCLE_SETTINGS,
      map,
      radius: currentRadius,
    })

    const mapCenter = map.getCenter()

    if (mapCenter) {
      newCircle.setCenter(mapCenter)
    }

    setCircle(newCircle)
  }, [map, radius, circle])

  return circle
}

export const useMapSearchInput = (onBlurHandler: () => void) => {
  const { change } = useForm()
  const [isInputActive, setInputActive] = useState(false)

  const onFocus = useCallback(() => {
    setInputActive(true)
  }, [setInputActive])

  const onChange = useCallback(() => {
    change(SEARCH_CITY_FIELD_NAME, undefined)
  }, [change])

  const onBlur = useCallback(() => {
    setInputActive(false)
    onBlurHandler()
  }, [setInputActive, onBlurHandler])

  return {
    onBlur,
    onFocus,
    onChange,
    isInputActive,
  }
}
