import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import SvgIcon from '../svgIcon/SvgIcon'
import styles from './offerCardLabel.module.scss'

export enum LabelType {
  success = 'success',
  error = 'error',
  pending = 'pending',
  alert = 'alert',
  photosNumber = 'photosNumber',
}

interface IOfferCardLabelProps {
  text: string;
  leftIcon?: string;
  rightIcon?: LabelType;
  disabled?: boolean;
}

const OfferCardLabel: React.FC<IOfferCardLabelProps> = ({
  text,
  disabled = false,
  leftIcon,
  rightIcon,
}) => {
  const { t } = useTranslation()
  const rightIconClass = rightIcon && styles[rightIcon]

  return (
    <div className={classNames(styles.main, rightIconClass, { [styles.disabled]: disabled })}>
      {leftIcon && (
        <SvgIcon name={leftIcon} size={16} className={styles.leftIcon} />
      )}
      <span>
        {t(text)}
      </span>
      {rightIcon && <SvgIcon name={rightIcon} className={styles.rightIcon} size={16} />}
    </div>
  )
}

export default OfferCardLabel
