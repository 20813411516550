import { useCallback } from 'react'
import { useForm, useFormState } from 'react-final-form'

import Chip, { ChipType } from 'src/components/button/Chip/Chip'
import { I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import { useGetFilterChips } from 'src/hooks/filter.hooks'
import { IFilterValues, PRICE_CHIP_NAME, TFieldKey } from 'src/models/search.model'
import { IChipId, removeFilterValueByChipId } from 'src/utils/filter.utils'
import { IFieldConfig } from 'src/yome-categories-fields-config/models/fields.model'

import ResetFiltersButton from './ResetFiltersButton'

import styles from './filter.module.scss'

const getChipKey = (ind: number, filterKey: string) => {
  const isFirstChip = ind === 0
  const localizedChipKey = `${I18N_SEARCH_FILTER}.${filterKey}.chipKey`

  return isFirstChip ? localizedChipKey : undefined
}

interface IFilterSidebarSummary {
  categoryFields: IFieldConfig[];
}

const FilterSidebarSummary = ({ categoryFields } : IFilterSidebarSummary) => {
  const { values } = useFormState<IFilterValues>()
  const { change } = useForm()

  const getFilterChips = useGetFilterChips()

  const summaryChips = getFilterChips(values, categoryFields)

  const removeChip = useCallback((filterKey: TFieldKey, chipId: IChipId) => {
    let filterValues

    if (filterKey === PRICE_CHIP_NAME) {
      // eslint-disable-next-line no-param-reassign
      filterKey = chipId.fieldKey
      filterValues = chipId.fieldValue
    } else {
      filterValues = values[filterKey]
    }

    const newFilterValue = removeFilterValueByChipId(filterValues, chipId)
    change(filterKey, newFilterValue)
  }, [values, change])

  return (
    <div className={styles.summary}>
      {Object.entries(summaryChips).map(([filterKey, chipValues]) => (
        <div className={styles.chips} key={filterKey}>
          {chipValues && chipValues.map((chip, ind) => (
            <Chip
              key={chip.chipValue}
              chipKey={getChipKey(ind, filterKey)}
              value={chip.chipValue}
              chipId={chip.chipId}
              iconName='close-cross'
              type={ChipType.light}
              onIconClick={(_, chipID) => removeChip(filterKey as TFieldKey, chipID as IChipId)}
            />
          ))}
        </div>
      ))}
      <ResetFiltersButton />
    </div>
  )
}

export default FilterSidebarSummary
