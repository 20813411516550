import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_POST_ACTION, I18N_POST_STATISTIC } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useHandleDeleteOfferOnOfferPage } from 'src/hooks/offer.hooks'
import { dateOptions } from 'src/models/date.model'
import {
  OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS,
  OFFER_STATUS_TO_PERMITTED_ACTIONS,
  OfferActions,
  TOfferActionsMap,
} from 'src/models/offerActions.model'
import { IPropsWithOffer, PostDate } from 'src/models/post.model'
import { useDisplayDate } from 'src/utils/date.utils'

import styles from './styles.module.scss'

const OnModerationActions = ({ offer }: IPropsWithOffer) => {
  const handleDeletePostOnPostPage = useHandleDeleteOfferOnOfferPage(offer)
  const { t } = useTranslation()
  const displayDate = useDisplayDate()

  const offerActions: TOfferActionsMap = {
    [OfferActions.edit]: {
      linkTo: YomeURL.editOffer(offer.slug),
    },
    [OfferActions.delete]: {
      onClick: handleDeletePostOnPostPage,
    },
  }

  const actionsListForStatus = OFFER_STATUS_TO_PERMITTED_ACTIONS(offer.publishedAt)[offer.status]

  return (
    <div className={styles.mainPostOwner}>
      <div className='bodySizeM'>
        <div className={styles.moderationDescription}>
          <SvgIcon name='clock-progress' size={24}/>
          {t(`${I18N_POST_ACTION}.onModeration.description`)}
        </div>
        <span>
          {t(`${I18N_POST_STATISTIC}.${PostDate.createdAt}`)}
          &nbsp;
          {displayDate(offer.createdAt, dateOptions.dateTimeFullYear)}
        </span>
      </div>
      <div className={styles.postOwnerActionButtons}>
        {actionsListForStatus.map((action, index) => {
          const actionProps = action in offerActions ? offerActions[action] : null

          return offerActions ? (
            <RegularButton
              key={action}
              buttonType={index === 0 ? RegularButtonTypes.blackNoBorder : RegularButtonTypes.transparentWithBorder}
              {...OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS[action]}
              {...actionProps}
            />
          ) : null
        })}
      </div>
    </div>
  )
}

export default OnModerationActions
