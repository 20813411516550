import { FORM_ERROR } from 'final-form'
import { TFunction } from 'i18next'
import { omit } from 'lodash-es'
import { toast } from 'react-toastify'

import { TOAST_INFO_CLOSE_DELAY } from 'src/components/toast/ToastContainer'
import { StringRecords } from 'src/models/common.model'
import { OFFER_STATUS_TO_PERMITTED_ACTIONS } from 'src/models/offerActions.model'
import { OfferStatus } from 'src/models/offerStatus.model'
import { republishPost, updateDraft } from 'src/services/post.service'
import fieldsStructure from 'src/yome-categories-fields-config/fields-config/formPostFields'
import postFieldsStructure from 'src/yome-categories-fields-config/fields-config/viewPostFields'
import { getMainCategory } from 'src/yome-categories-fields-config/utils/fieldConfig.utils'

import { I18N_POST } from '../constants/i18n.constants'
import { CATEGORY_PATH_FIELD_NAME, PostFormFieldKeys, PostFormFields, SinglePost } from '../models/post.model'
import { getRemainingDays } from './date.utils'
import { captureErrorAndShowToast, SubmitFormError } from './error.utils'

export const MAX_CATEGORY_PATH_INDEXES_ARRAY = Array.from(Array(5).keys())

export const uppercaseTitle = (title: string | undefined, t: TFunction) => (
  title ? title.charAt(0).toUpperCase() + title.slice(1) : t(`${I18N_POST}.noTitle`)
)

export const isPostClosed = (postStatus: OfferStatus) => [
  OfferStatus.expired,
  OfferStatus.closed,
].includes(postStatus)

export const isPostActive = (postStatus: OfferStatus) => postStatus === OfferStatus.active

export const isOfferOnModeration = (postStatus: OfferStatus) => postStatus === OfferStatus.moderating

export const isPostDraft = (postStatus: OfferStatus) => [
  OfferStatus.draft,
  OfferStatus.declined,
].includes(postStatus)

export const getActionsListForValidPosts = (isDesktop: boolean, offerStatus: OfferStatus, publishedAt?: string) => {
  const actionsListForStatus = OFFER_STATUS_TO_PERMITTED_ACTIONS(publishedAt)[offerStatus]

  if (isDesktop) {
    return actionsListForStatus
  }

  // for mobile display only the first button
  return [actionsListForStatus[0]]
}

export const getPostRemainingDays = ({ deletedAt, expiredAt, status }: SinglePost) => {
  if ([OfferStatus.declined, OfferStatus.blocked, OfferStatus.draft].includes(status)) {
    return getRemainingDays(deletedAt)
  }

  return getRemainingDays(expiredAt)
}

type IValidateDraftError = Partial<Record<PostFormFieldKeys, string | StringRecords>>

export const getSubcategoryFieldName = (name: string, index: number | string) => `${name}Sub${index}`

export const parseValidationErrors = (formError: IValidateDraftError, values: PostFormFields) => {
  if (formError && Object.keys(formError).length) {
    return Object.entries(formError).reduce((
      reduceResult: Record<string, string | StringRecords>,
      singleError,
    ) => {
      const [errorField, errorValue] = singleError

      if (errorField === CATEGORY_PATH_FIELD_NAME) {
        const lastSubcategoryIndex = values[errorField].length

        /**
         * for category path form error has following structure {categoryPath: {sub[IndexOfEmpty]: error}}
         * exmpl: categoryPath = ['SERVICES'] -> formError = {categoryPath: {'sub1': error}}
         */
        reduceResult[getSubcategoryFieldName(CATEGORY_PATH_FIELD_NAME, lastSubcategoryIndex)] = errorValue

        return reduceResult
      }

      if (errorField.includes('.')) {
        const [field, subfield] = errorField.split('.')
        const fieldErrors = (reduceResult[field] || {}) as StringRecords
        reduceResult[field] = { ...fieldErrors, [subfield]: errorValue as string }

        return reduceResult
      }

      reduceResult[errorField] = errorValue

      return reduceResult
    }, {})
  }

  return {}
}

export const handleOfferUpdateError = (error: any, data: PostFormFields, t: TFunction) => {
  if (error instanceof SubmitFormError) {
    const formError = error.errorObject

    if (FORM_ERROR in formError) {
      toast.error(t(formError[FORM_ERROR]))
    }

    return parseValidationErrors(formError, data)
  }

  if (error instanceof Error) toast.error(t(error.message))

  return undefined
}

export const validateAndUpdateDraft = async (values: PostFormFields): Promise<IValidateDraftError | undefined> => {
  try {
    await updateDraft(values)
  } catch (error) {
    if (error instanceof SubmitFormError) {
      const formError = error.errorObject

      return parseValidationErrors(formError, values)
    }
  }

  return undefined
}

export const getOfferParts = (categoryPath: string[], partIndex: number) => {
  const mainCategory = getMainCategory(categoryPath[0])
  const structure = fieldsStructure[mainCategory]

  const totalParts = structure.length
  const isLastPart = partIndex + 1 === totalParts

  return { structure, totalParts, isLastPart }
}

export const getFieldsStructure = (rootCategory: string) => {
  const mainCategory = getMainCategory(rootCategory)

  return postFieldsStructure[mainCategory]
}

export const handleShareOfferLink = async (title: string, link: string, toastText: string) => {
  const shareData = {
    title,
    url: link,
  }

  if (navigator.share && navigator.canShare(shareData)) {
    await navigator.share(shareData)
  } else {
    await navigator.clipboard.writeText(link)
    toast.info(toastText, { autoClose: TOAST_INFO_CLOSE_DELAY })
  }
}

const inactiveStatuses = [OfferStatus.closed, OfferStatus.expired]

export const checkIsInactiveView = (offer: SinglePost) => (
  inactiveStatuses.includes(offer.status) && !offer.isCreatedByCurrentUser
)

export const handleRepublishOffer = async (
  slug: string,
  onSuccess: () => void,
  resetOffersList?: () => void,
) => {
  try {
    await republishPost(slug)
    onSuccess()

    if (resetOffersList) {
      resetOffersList()
    }
  } catch (error) {
    captureErrorAndShowToast(error)
  }
}

export const removeSubcategoryFields = (values: PostFormFields) => {
  const subcategoryFields = MAX_CATEGORY_PATH_INDEXES_ARRAY
    .map((index) => getSubcategoryFieldName(CATEGORY_PATH_FIELD_NAME, index))

  return omit(values, subcategoryFields)
}

export const serialiseOfferFormFields = (values: PostFormFields) => JSON.stringify(removeSubcategoryFields(values))
