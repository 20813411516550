import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { I18N_POST_ACTION } from 'src/constants/i18n.constants'
import { OfferStatus } from 'src/models/offerStatus.model'

import styles from './styles.module.scss'

interface ClosedInfoSectionProps {
  postType: OfferStatus;
  descriptionType?: 'ownerDescription' | 'guestDescription';
}

const PostActionDescription = ({ postType, descriptionType = 'ownerDescription' }: ClosedInfoSectionProps) => {
  const { t } = useTranslation()

  return (
    <div className={classNames('bodySizeM', styles.postActionDescription)}>
      {t(`${I18N_POST_ACTION}.${postType}.${descriptionType}`)}
    </div>
  )
}

export default PostActionDescription
