import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { useCategoryMatch } from 'src/utils/category.utils'
import { ICategory } from 'src/yome-categories-fields-config/models/category.model'

import SubCategoryList from '../subCategoryList/SubCategoryList'

import styles from './categoryCard.module.scss'

interface ICategoryCardProps {
  onCategoryChange: (path: string[]) => void;
  category: ICategory;
  searchQuery: string;
}

const CategoryCard: React.FC<ICategoryCardProps> = ({ category, searchQuery, onCategoryChange }) => {
  const { t } = useTranslation()
  const checkCategoryMatch = useCategoryMatch()
  const isMatch = checkCategoryMatch(searchQuery, category)

  const handleCategorySelect = useCallback(() => {
    onCategoryChange([category.title])
  }, [category.title, onCategoryChange])

  if (!isMatch) {
    return null
  }

  return (
    <li key={category.title} className={styles.card} onClick={handleCategorySelect}>
      <SvgIcon name={`category/${category.title.toLowerCase()}`} size={48} />
      <div>
        <p className='label-20'>
          {t(`${I18N_CATEGORIES}.${category.title}`)}
        </p>
        <SubCategoryList
          onSubCategoryChange={onCategoryChange}
          chosenCategory={category}
        />
      </div>
    </li>
  )
}

export default CategoryCard
