import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'

import styles from './legalLayout.module.scss'

interface ILegalPageTitle {
  label: string;
}

const LegalPageTitle = ({ label }: ILegalPageTitle) => (
  <Heading
    label={label}
    className={classNames('desktop-h4 mobile-h3', styles.pageTitle)}
  />
)

export default LegalPageTitle
