import { memo } from 'react'

import { useViewport } from 'src/contexts/viewportContext'
import { IPropsWithOffer } from 'src/models/post.model'
import { getOfferTimestampByStatus } from 'src/utils/date.utils'
import { checkIsInactiveView, getFieldsStructure } from 'src/utils/post.utils'
import { getMainCategory } from 'src/yome-categories-fields-config/utils/fieldConfig.utils'

import AuthorInfoAndButtons from '../authorInfoAndButtons/AuthorInfoAndButtons'
import OfferMessage from '../OfferMessage/OfferMessage'
import PostSection from '../postContent/postField/PostSection'
import PostSupportiveInfo from '../postContent/postSupportiveInfo/PostSupportiveInfo'

import styles from './offerInfo.module.scss'

const OfferInfo = ({ offer }: IPropsWithOffer) => {
  const { isDesktop } = useViewport()
  const isOfferActive = !checkIsInactiveView(offer)
  const { user, isCreatedByCurrentUser, categoryPath, statistic, expiredAt } = offer

  const mainCategory = getMainCategory(categoryPath[0])
  const structure = getFieldsStructure(categoryPath[0])

  return (
    <div className={styles.main}>
      {isOfferActive && structure.map((section) => (
        <PostSection section={section} post={offer} key={section.section} />
      ))}
      {/* <PostReviews /> */}
      {!isDesktop && <AuthorInfoAndButtons author={user} isCurrentUserAuthor={isCreatedByCurrentUser}/>}
      {(!isCreatedByCurrentUser && isOfferActive) && (
        <>
          <OfferMessage
            postId={offer._id}
            mainCategory={mainCategory}
          />
          <PostSupportiveInfo
            placedAt={getOfferTimestampByStatus(offer).toString()}
            statistic={statistic}
            expiredAt={expiredAt}
            postId={offer._id}
          />
        </>
      )}
    </div>
  )
}

export default memo(OfferInfo)
