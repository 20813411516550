import classNames from 'classnames'
import { memo, useCallback, useRef } from 'react'
import { Form } from 'react-final-form'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import LangSelect from 'src/components/langSelect/LangSelect'
import { emptyFunction } from 'src/constants/function.constants'
import { I18N_SEARCH } from 'src/constants/i18n.constants'
import { ID, IDSection } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { SearchBarFields } from 'src/models/search.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { useCheckIsPage } from 'src/utils/hooks.utils'
import { getSearchBarInitialValues } from 'src/utils/search.utils'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'

import CategorySearchButton from '../categorySearchDropdown/CategorySearchButton'
import InputSearchBar from './InputSearchBar'
import SelectCityButton from './SelectCityButton'

import styles from '../../search.module.scss'

const SearchButtonAndBar = memo(() => (
  <>
    <CategorySearchButton />
    <InputSearchBar />
  </>
))

const SearchBar = () => {
  const navigate = useNavigate()
  const { isDesktop } = useViewport()
  const { isMainPage } = useCheckIsPage()
  const [searchParams] = useSearchParams()
  const { data: currentUser } = useCurrentUser()
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const initialValues = getSearchBarInitialValues(searchParams)

  const onSubmit = useCallback((values: SearchBarFields) => {
    const { sortBy, category, searchContent } = values || {}
    const hasChangedContent = searchContent && initialValues.searchContent !== searchContent

    submitRef.current?.focus()

    const newSearchParams = createSearchParams({
      category: hasChangedContent ? ANY_CATEGORY : category || ANY_CATEGORY,
      ...(searchContent) && { searchContent },
      ...(sortBy) && { sortBy },
    }).toString()

    navigate({
      pathname: YomeURL.search,
      search: newSearchParams,
    })
  }, [navigate, initialValues.searchContent])

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          id={IDSection.searchBar}
          className={classNames(styles.searchBar, { [styles.sticky]: isMainPage && isDesktop })}
        >
          {isDesktop ? (
            <>
              <div className={styles.leftSearchBar}>
                <SearchButtonAndBar />
                <RegularButton
                  ref={submitRef}
                  id={ID.searchBarSearchPost}
                  label={`${I18N_SEARCH}.searchButton`}
                  type='submit'
                  onClick={emptyFunction}
                  buttonType={RegularButtonTypes.blackNoBorder}
                />
              </div>
              <SelectCityButton/>
            </>
          ) : (
            <>
              <SearchButtonAndBar />
              {!currentUser && <LangSelect type='flag' background='light'/>}
            </>
          )}
        </form>
      )}
    />
  )
}

export default memo(SearchBar)
