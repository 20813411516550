import { captureException } from '@sentry/react'
import useSWR from 'swr'

import SWRkeys from 'src/constants/swr.constants'

import { sendGet } from '../utils/network.utils'

const getUserAddressHistory = async (path: string): Promise<string[]> => {
  try {
    return await sendGet(path)
  } catch (error) {
    captureException(error as Error)

    return []
  }
}

const useGetUserAddressHistory = () => useSWR<string[]>(
  SWRkeys.getUserAddressHistory,
  getUserAddressHistory,
)

export default useGetUserAddressHistory
