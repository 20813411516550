import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'

import { emptyFunction } from 'src/constants/function.constants'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import LargeToggle from 'src/components/largeToggle/LargeToggle'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_COOKIE_MANAGE, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import {
  acceptAllOptionPrivacyCookies,
  getBoolCookie,
  isAllOptionalCookieActive,
  OptionPolicyCookiesArray,
  rejectAllOptionPrivacyCookies,
  rejectUndefinedOptionPrivacyCookies,
  RequiredPolicyCookies,
  RequiredPolicyCookiesArray,
  setRequiredPrivacyCookies,
  toggleBooleanCookie,
} from 'src/services/cookie.service'
import InfoModal from '../infoModal/InfoModal'

import styles from './cookieManage.module.scss'

interface CookieManageProps {
  handleClose: () => void;
}

const ALL_COOKIES_NAME = 'allCookies'

const CookieManage = React.memo(({ handleClose }: CookieManageProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    setRequiredPrivacyCookies()
    rejectUndefinedOptionPrivacyCookies()
  }, [])

  const personalCookies = [
    RequiredPolicyCookies.essentialCookies,
    ...OptionPolicyCookiesArray,
  ]

  const initialPersonalCookiesValues = personalCookies.reduce((acc, cookie) => ({
    ...acc,
    [cookie]: getBoolCookie(cookie),
  }), {} as Record<string, boolean>)

  const initialValues = {
    [ALL_COOKIES_NAME]: isAllOptionalCookieActive(),
    ...initialPersonalCookiesValues,
  }

  const allCookieOnChange = (curValue: boolean, change: (name: string, value: boolean) => void) => {
    const newCookieValue = !curValue

    if (newCookieValue) {
      acceptAllOptionPrivacyCookies(change)
    } else {
      rejectAllOptionPrivacyCookies(change)
    }

    change(ALL_COOKIES_NAME, newCookieValue)
  }

  const changeOptionalCookie = (
    cookieName: string,
    values: Record<string, boolean>,
    change: (name: string, value: boolean) => void,
  ) => {
    toggleBooleanCookie(cookieName, values[cookieName], change)

    const isAllCookiesActive = isAllOptionalCookieActive()
    change(ALL_COOKIES_NAME, isAllCookiesActive)
  }

  const footerButton = (
    <RegularButton
      id={ID.cookieManageCloseModal}
      buttonType={RegularButtonTypes.blackNoBorder}
      label={`${I18N_COOKIE_MANAGE}.saveAndClose`}
      onClick={handleClose}
      className={styles.saveAndClose}
    />
  )

  return (
    <InfoModal
      title={`${I18N_COOKIE_MANAGE}.title`}
      className={styles.manageCookie}
      modalFooter={footerButton}
    >
      <div className='bodySizeM'>
        <Paragraph text={`${I18N_COOKIE_MANAGE}.description`}/>
        <div className={styles.linkToPolicy}>
          <Trans i18nKey={`${I18N_COOKIE_MANAGE}.policy`}>
            See our
            <Link className={'underline'} to={YomeURL.cookies}>
              Cookies Policy
            </Link>
            for more details.
          </Trans>
        </div>
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={emptyFunction}
        render={function ManageCookiesFormRender({ values, form: { change } }) {
          return (
            <div className={styles.scrollContent}>
              <div className={styles.singleToggle}>
                <LargeToggle
                  onChange={() => allCookieOnChange(values[ALL_COOKIES_NAME], change)}
                  checked={values[ALL_COOKIES_NAME]}
                  label={`${I18N_COOKIE_MANAGE}.allCookies`}
                  name={ALL_COOKIES_NAME}
                />
              </div>
              <div className={styles.borderLine}/>
              {personalCookies.map((cookie) => (
                <div className={styles.singleToggle} key={cookie}>
                  <LargeToggle
                    onChange={() => changeOptionalCookie(cookie, values, change)}
                    checked={values[cookie]}
                    label={`${I18N_COOKIE_MANAGE}.${cookie}.title`}
                    name={cookie}
                    disabled={RequiredPolicyCookiesArray.includes(cookie as RequiredPolicyCookies)}
                  >
                    <div className={styles.additionalInfo}>
                      <div className='bodySizeM'>
                        {t(`${I18N_COOKIE_MANAGE}.${cookie}.description.one`)}
                      </div>
                      <div className='bodySizeM'>
                        {t(`${I18N_COOKIE_MANAGE}.${cookie}.description.two`)}
                      </div>
                      <RegularButton
                        id={concatId(ID.largeToggleLinkToCookiePolicy, cookie.toString())}
                        label={`${I18N_GLOBAL}.learnMore`}
                        linkTo={YomeURL.privacy}
                        buttonType={RegularButtonTypes.transparentNoBorderLight}
                        className={styles.learnMore}
                      />
                    </div>
                  </LargeToggle>
                </div>
              ))}
            </div>
          )
        }}
      />
    </InfoModal>
  )
})

export default CookieManage
