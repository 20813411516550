import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import UserAvatar from 'src/components/avatar/UserAvatar'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_AUTH } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { YomeHash } from 'src/constants/navigate.constants'
import { useHashUrl } from 'src/hooks/router.hooks'
import { IModalWithClose } from 'src/models/modal.model'
import { AvatarSizes } from 'src/models/upload.model'
import { IUser } from 'src/models/user.model'

import InfoModal from '../infoModal/InfoModal'

import styles from './userExists.module.scss'

interface IUserExistsModal extends IModalWithClose {
  user: IUser;
  goToSignup: () => void;
}

const UserExistsModal = ({ user, goToSignup, handleClose }: IUserExistsModal) => {
  const { t } = useTranslation()

  const { id, email, name, avatar } = user
  const loginLabel = t(`${I18N_AUTH}.loginAs`)

  return (
    <InfoModal
      title={`${I18N_AUTH}.title.profileExists`}
      handleClose={handleClose}
    >
      <div className={styles.user}>
        <UserAvatar
          image={avatar}
          avatarSize={AvatarSizes.tiny}
          firstLetter={name.charAt(0)}
          userId={id}
        />
        <div className={styles.userInfo}>
          <p className='label-20'>
            {`${loginLabel} ${name}`}
          </p>
          <p className={classNames('bodySizeL', styles.email)}>
            {email}
          </p>
        </div>
      </div>
      <RegularButton
        label={`${I18N_AUTH}.logIn`}
        linkTo={useHashUrl(YomeHash.auth)}
        id={ID.signupModalLinkToLoginModal}
        buttonType={RegularButtonTypes.main}
      />
      <p className={classNames('bodySizeM', styles.secondaryText)}>
        {t(`${I18N_AUTH}.notYou`)}
      </p>
      <RegularButton
        label={`${I18N_AUTH}.button.createAccount`}
        onClick={goToSignup}
        id={ID.signupModalButtonSignup}
        buttonType={RegularButtonTypes.transparentWithBorder}
      />
    </InfoModal>
  )
}

export default UserExistsModal
