import { ReactNode } from 'react'

import { ID } from 'src/constants/id.constants'

import IconButton from '../button/IconButton'
import ModalPortal from './ModalPortal'

import styles from './modal.module.scss'

interface IModalLayoutProps {
  handleClose?: () => void;
  children: ReactNode;
}

const ModalLayout = ({ handleClose, children }: IModalLayoutProps) => (
  <ModalPortal>
    <div className={styles.main}>
      <div className={styles.overlay} onClick={handleClose}></div>
      <div className={styles.container}>
        {handleClose && (
          <div className={styles.close}>
            <IconButton iconName='close-cross' size={32} onClick={handleClose} id={ID.modalCloseButton}/>
          </div>
        )}
        { children }
      </div>
    </div>
  </ModalPortal>
)

export default ModalLayout
