import classNames from 'classnames'

import { I18N_NOTHING_FOUND, I18N_NOTHING_FOUND_MESSAGE } from '../../constants/i18n.constants'
import { IRegularButtonProps } from '../button/RegularButton'

import EmptyStateMessage from '../emptyStateMessage/EmptyStateMessage'
import styles from './styles.module.scss'

export enum NoPostsType {
  favorite = 'favorite',
  search = 'search',
  myOffers = 'myOffers',
  savedSearch = 'savedSearch',
}

interface INoPostsProps {
  type: NoPostsType;
  button?: IRegularButtonProps;
}

const NoPosts: React.FC<INoPostsProps> = ({ type, button }) => (
  <div className={classNames(styles.main, styles[type])}>
    <EmptyStateMessage
      icon={`${type}NoPosts`}
      title={`${I18N_NOTHING_FOUND}.${type}`}
      text={`${I18N_NOTHING_FOUND_MESSAGE}.${type}`}
      button={button}
    />
  </div>
)

export default NoPosts
