import { memo } from 'react'

import { useViewport } from 'src/contexts/viewportContext'
import { useGetDisplayPrice } from 'src/hooks/price.hooks'

interface IPostPriceProps {
  value: number;
}

const PostPrice = ({ value }: IPostPriceProps) => {
  const { isDesktop } = useViewport()
  const getDisplayPrice = useGetDisplayPrice()
  const displayPrice = getDisplayPrice(value)

  return (
    <div className={isDesktop ? 'typography-price' : 'heading mobile-h2'}>
      {displayPrice}
    </div>
  )
}

export default memo(PostPrice)
