import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useField, useForm, useFormState } from 'react-final-form'

import { FormSearch } from 'src/components/form'
import { KEYBOARD_ENTER_KEY } from 'src/constants/general.constants'
import { I18N_SEARCH } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { CATEGORY_QPARAM, SEARCH_CONTENT_QPARAM } from 'src/models/search.model'
import { useOnClickOutside } from 'src/utils/hooks.utils'

import SearchSuggestionsDropdown from '../SearchSuggestionsDropdown/SearchSuggestionsDropdown'
import FullScreenSearchInput from './FullScreenSearchInput'

import styles from '../../search.module.scss'

const InputSearchBar = () => {
  const { values } = useFormState()
  const { isDesktop } = useViewport()
  const { blur, change, submit } = useForm()
  const active = useField(SEARCH_CONTENT_QPARAM)?.meta.active

  const searchInput = values[SEARCH_CONTENT_QPARAM]
  const searchRef = useRef<HTMLDivElement | null>(null)
  const [isSuggestionsOpen, setSuggestionsOpen] = useState(false)
  const openSuggestions = useCallback(() => setSuggestionsOpen(true), [setSuggestionsOpen])
  const closeSuggestions = useCallback(() => setSuggestionsOpen(false), [setSuggestionsOpen])

  useEffect(() => {
    const isActive = active && !isSuggestionsOpen

    if (!isActive) {
      return
    }

    const isDesktopAndHasValue = isDesktop && !!searchInput

    if (!isDesktop || isDesktopAndHasValue) {
      openSuggestions()

      if (!isDesktop) {
        blur(SEARCH_CONTENT_QPARAM)
      }
    }
  }, [openSuggestions, searchInput, active, isDesktop, isSuggestionsOpen, blur])

  useOnClickOutside([searchRef], closeSuggestions, isSuggestionsOpen && isDesktop)

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYBOARD_ENTER_KEY) {
      blur(SEARCH_CONTENT_QPARAM)
    }
  }, [blur])

  return (
    <div className={styles.searchInput} ref={searchRef}>
      <FormSearch
        id={ID.searchBarInputSearch}
        name={SEARCH_CONTENT_QPARAM}
        // non-typable search for mobile, typable search field will be open in modal window
        value={isDesktop ? undefined : searchInput}
        placeholder={`${I18N_SEARCH}.inputPlaceholder`}
        onKeyDown={onKeyDown}
      />
      {isSuggestionsOpen && (
        isDesktop ? (
          <SearchSuggestionsDropdown searchInput={searchInput} />
        ) : (
          <FullScreenSearchInput
            change={change}
            submit={submit}
            close={closeSuggestions}
            initialInputValue={searchInput}
            innitialCategoryValue={values[CATEGORY_QPARAM]}
          />
        )
      )}
    </div>
  )
}

export default memo(InputSearchBar)
