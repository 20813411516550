import { FOR_BUSINESS_PLANS } from 'src/constants/staticPages.constants'
import SectionHeader from '../sectionHeader/SectionHeader'
import SinglePlan from './SinglePlan'

import styles from './plans.module.scss'

const Plans = () => (
  <section>
    <SectionHeader
      label={'plans.title'}
      text={'plans.text'}
    />
    <div className={styles.plansList}>
      {FOR_BUSINESS_PLANS.map((plan) => (
        <SinglePlan {...plan} key={plan.name} />
      ))}
    </div>
  </section>
)

export default Plans
