import { memo, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'

import Dropdown from 'src/components/dropdown/Dropdown'
import { ID } from 'src/constants/id.constants'
import { ISuggestions } from 'src/models/search.model'
import { useCategorySearchSuggestions } from 'src/utils/category.utils'
import { useDropdownSuggestionsOptions } from 'src/utils/search.utils'

import styles from './searchSuggestionsDropdown.module.scss'

interface ISearchSuggestionsDropdownProps {
  searchInput: string;
}

const SUGGESTION_ITEMS_NUMBER = 8

const SearchSuggestionsDropdown = ({ searchInput }: ISearchSuggestionsDropdownProps) => {
  const [suggestions, setSuggestions] = useState<ISuggestions>({
    categories: [],
    searchRequests: [],
  })

  const { change, submit } = useForm()
  const getCategorySearchSuggestions = useCategorySearchSuggestions()
  const getDropdownSuggestionsOptions = useDropdownSuggestionsOptions()

  useEffect(() => {
    setSuggestions({
      categories: searchInput ? getCategorySearchSuggestions(searchInput, SUGGESTION_ITEMS_NUMBER) : [],
      searchRequests: [],
    })
  }, [searchInput, getCategorySearchSuggestions])

  const dropdownOptions = useMemo(() => ({
    suggestionSection: getDropdownSuggestionsOptions(suggestions, change, submit),
  }), [suggestions, change, submit, getDropdownSuggestionsOptions])

  if (!suggestions.categories.length) {
    return null
  }

  return <Dropdown id={ID.searchBarSelectSuggestion} sections={dropdownOptions} className={styles.main} />
}

export default memo(SearchSuggestionsDropdown)
