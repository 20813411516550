import classNames from 'classnames'
import { memo, useState } from 'react'
import { FreeMode, Keyboard, Navigation, Swiper as SwiperInstance, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

import Heading from 'src/components/heading/Heading'
import ImageOrNoPhoto from 'src/components/imageOrNoPhoto/ImageOrNoPhoto'
import ModalOverlay from 'src/components/modal/ModalOverlay'
import { useViewport } from 'src/contexts/viewportContext'
import { useOnEscPress } from 'src/hooks/controls.hooks'
import { SinglePost } from 'src/models/post.model'
import { getImageOrigUrl, getThumbSmallUrl } from 'src/utils/images.utils'

import ImagesCounter from '../OfferImages/ImagesCounter'
import ThumbsSwiper from '../OfferImages/ThumbsSwiper'
import TopBarButtons from './TopBarButtons'

import styles from './fullscreenOfferImages.module.scss'

interface IFullscreenImagesProps {
  handleClose: () => void;
  offer: SinglePost;
  showNumber: boolean;
  activeImageIndex?: number;
}

interface ISwiperImagesProps {
  offerOrigPhotos: (string | null)[];
  offerThumbPhotos: (string | null)[];
  showNumber: boolean;
  title: string;
  activeImageIndex: number;
}

const SwiperImages = (
  { offerOrigPhotos, offerThumbPhotos, showNumber, title, activeImageIndex }: ISwiperImagesProps,
) => {
  const { isDesktop } = useViewport()
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperInstance | null>(null)

  return (
    <>
      <Swiper
        className="fullscreen-slider"
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Keyboard]}
        keyboard={{
          enabled: true,
        }}
        initialSlide={activeImageIndex}
        loop
        navigation
      >
        {offerOrigPhotos?.map((url, index) => (
          <SwiperSlide key={`slide-${index}`}>
            <ImageOrNoPhoto
              src={url}
              alt={title}
              objectFit='contain'
              className={classNames(styles.image)}
            />
            {showNumber && (
              <ImagesCounter index={index} total={offerOrigPhotos.length} className={styles.counterOverlay}/>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {isDesktop && (
        <ThumbsSwiper
          thumbPhotos={offerThumbPhotos}
          title={title}
          isInactiveView={false}
          onSwiper={setThumbsSwiper}
          isFullscreen
        />
      )}
    </>
  )
}

const FullScreenOfferImages = (
  { handleClose, showNumber, offer, activeImageIndex }: IFullscreenImagesProps,
) => {
  const { isDesktop } = useViewport()

  const { title, isFavorite, slug, isCreatedByCurrentUser } = offer

  const offerThumbPhotos = offer.photos.map((imageObj) => getThumbSmallUrl(imageObj, offer.publicId))
  const offerOrigPhotos = offer.photos.map((imageObj) => getImageOrigUrl(imageObj, offer.publicId))

  const isDisplaySwiper = offerOrigPhotos.length > 1
  const mainImageSrc = (offerOrigPhotos.length && offerOrigPhotos[0]) || null

  useOnEscPress(handleClose)

  return (
    <ModalOverlay overlayClassName={styles.overlay}>
      <div className={styles.container}>
        <TopBarButtons
          slug={slug}
          title={title}
          isFavorite={isFavorite}
          isCreatedByCurrentUser={isCreatedByCurrentUser}
          handleClose={handleClose}
        />
        {isDesktop && (
          <Heading
            label={title}
            level='h4'
            className={classNames('desktop-h4', styles.title)}
            title={title}
          />
        )}
        {isDisplaySwiper ? (
          <SwiperImages
            offerOrigPhotos={offerOrigPhotos}
            offerThumbPhotos={offerThumbPhotos}
            showNumber={showNumber}
            title={title}
            activeImageIndex={activeImageIndex || 0}
          />
        ) : (
          <ImageOrNoPhoto
            src={mainImageSrc}
            alt={title}
            objectFit='contain'
            className={classNames(styles.singleImage)}
          />
        ) }
      </div>
    </ModalOverlay>
  )
}

export default memo(FullScreenOfferImages)
