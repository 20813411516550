import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { ID } from 'src/constants/id.constants'
import { I18N_FORM } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import ModalLayout from 'src/components/modal/ModalLayout'
import InfoModal from 'src/components/modal/infoModal/InfoModal'

import styles from './offerForm.module.scss'

const DraftSavedModal = () => {
  const { t } = useTranslation()

  const footerButton = (
    <RegularButton
      id={ID.editDraftLinkToDraftOffer}
      label={`${I18N_FORM}.button.resumeLater`}
      buttonType={RegularButtonTypes.blackNoBorder}
      linkTo={YomeURL.draftOffers}
    />
  )

  return (
    <ModalLayout>
      <InfoModal
        title={`${I18N_FORM}.draftSaved.title`}
        topIcon='check-success'
        modalFooter={footerButton}
        className={styles.successContainer}
      >
        <p className='bodySizeM'>
          {t(`${I18N_FORM}.draftSaved.text`)}
        </p>
      </InfoModal>
    </ModalLayout>
  )
}

export default DraftSavedModal
