import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { TButtonType } from 'src/models/form.model'

import SvgIcon, { SvgColorAttr } from '../svgIcon/SvgIcon'

import styles from './iconButton.module.scss'

interface IconButtonProps {
  id: string;
  iconName: string;
  title?: string;
  linkTo?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  size?: number;
  type?: TButtonType;
  className?: string;
  disabled?: boolean;
  iconColor?: SvgColorAttr;
}

const IconButton = React.memo(({
  onClick,
  id,
  title,
  linkTo,
  iconName,
  size = 24,
  type = 'button',
  className,
  disabled = false,
  iconColor,
}: IconButtonProps) => {
  const commonProps = {
    id,
    onClick,
    title: title || iconName,
    className: classNames(styles.main, className, { [styles.disabled]: disabled }),
  }

  const iconEl = <SvgIcon name={iconName} size={size} currentColor={iconColor}/>

  if (linkTo) {
    return (
      <Link {...commonProps} to={linkTo}>
        {iconEl}
      </Link>
    )
  }

  return (
    <button {...commonProps} title={iconName} type={type} disabled={disabled}>
      {iconEl}
    </button>
  )
})

export default IconButton
