import React from 'react'
import { Field } from 'react-final-form'

import { I18N_SEARCH_FILTER } from '../../constants/i18n.constants'
import Toggle from '../toggle/Toggle'

import styles from './filter.module.scss'

interface IFilterToggleProps {
  name: string;
}

const FilterToggle: React.FC<IFilterToggleProps> = ({
  name,
}) => (
  <div className={styles.field}>
    <Field
      key={name}
      name={name}
      type="checkbox"
      render={({ input }) => (
        <Toggle
          label={`${I18N_SEARCH_FILTER}.${name}.label`}
          name={input.name}
          checked={input.checked || false}
          onChange={input.onChange}
        />
      )}
    />
  </div>
)

export default FilterToggle
