import React from 'react'

import { IChatMessage, IWarningMessage } from 'src/models/messenger.model'
import { checkIsScanMessage } from 'src/utils/messenger.utils'

import StrangerMessage from './StrangerMessage'
import UserMessage from './UserMessage'
import WarningMessage from './WarningMessage'

export interface ChatMessageProps {
  message: IChatMessage | IWarningMessage;
}

const ChatMessage = React.memo(({ message }: ChatMessageProps) => {
  if (checkIsScanMessage(message.contentType)) {
    return (
      <WarningMessage message={message as IWarningMessage}/>
    )
  }

  return message.isCurrentUserAuthor ? (
    <UserMessage message={message as IChatMessage} />
  ) : (
    <StrangerMessage message={message as IChatMessage} />
  )
})

export default ChatMessage
