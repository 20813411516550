import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Field, FieldInputProps, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ReactComponent as AddPhotoImage } from 'src/assets/icons/photo.svg'
import Loader from 'src/components/loader/Loader'
import { useAssertValidAttachment, useInputBlurOnValueUpdate } from 'src/hooks/form.hooks'
import { IUploadImageResponse } from 'src/models/upload.model'
import { deleteImages, sendImages } from 'src/services/image.service'
import { captureErrorAndShowToast, SubmitFormError } from 'src/utils/error.utils'
import { getFieldError } from 'src/utils/form.utils'
import { getThumbSmallUrl } from 'src/utils/images.utils'

import FieldWrapper from '../FieldWrapper'
import UploadedPhoto from './uploadedPhoto'

import styles from './imageUpload.module.scss'

interface DropImageUploadProps<T> {
  inputProps: FieldInputProps<T>;
  fieldError: string | undefined;
  publicId: string;
  maxSize: number; // MB
}

interface IFormImageUploadProps {
  name: string;
  publicId: string;
  maxSize: number; // MB
}

const DropImageUpload = ({
  fieldError,
  publicId,
  inputProps,
  maxSize,
}: DropImageUploadProps<IUploadImageResponse[]>) => {
  const { t } = useTranslation()

  const assertValidAttachment = useAssertValidAttachment(maxSize)

  const { name, value, disabled } = inputProps

  const [error, setError] = useState(fieldError)
  const [isLoading, setIsLoading] = useState(false)
  const { change } = useForm()

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    const isValidAttachment = assertValidAttachment(acceptedFiles)

    if (!isValidAttachment) {
      return
    }

    setIsLoading(true)
    sendImages(acceptedFiles, publicId).then((filesObj) => {
      setIsLoading(false)

      setError('')

      if (filesObj) {
        const newPhotos = value.concat(filesObj)
        change(name, newPhotos)
      }
    }).catch((err) => {
      setIsLoading(false)

      if (err instanceof SubmitFormError) {
        setError(err.message)

        return
      }

      captureErrorAndShowToast(err)
    })
  }, [value, name, publicId, change, assertValidAttachment])

  const onDropRejected = React.useCallback((rejectededResult: FileRejection[]) => {
    const files = rejectededResult.map((rej) => rej.file)
    const errorMsg = rejectededResult[0].errors[0].message
    const isValidAttachment = assertValidAttachment(files)

    if (!isValidAttachment) {
      return
    }

    toast.error(t(errorMsg))
  }, [assertValidAttachment, t])

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    {
      onDrop,
      onDropRejected,
      accept: {
        'image/*': [],
      },
    },
  )

  const { onClick, ...rootProps } = getRootProps()

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      e.stopPropagation()
    }

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <FieldWrapper customError={error} name={name} disabled={disabled}>
      <div {...rootProps}>
        <input {...getInputProps()} />
        <button
          type='button'
          onClick={handleButtonClick}
          className={classNames(styles.image, { [styles.disabled]: disabled || isLoading })}
        >
          {isLoading ? (
            <Loader size={32} className={styles.loader} />
          ) : (
            <>
              <AddPhotoImage className={styles.addPhotoImage} />
              <p className={styles.imageText}>
                {isDragActive ? t('form.dragDrop') : t('form.addPhoto')}
              </p>
            </>
          )
          }
        </button>
      </div>
    </FieldWrapper>
  )
}

const FormImageUpload = ({ name, publicId, maxSize }: IFormImageUploadProps) => {
  const { change } = useForm()
  useInputBlurOnValueUpdate(name)

  return (
    <Field<IUploadImageResponse[]>
      name={name}
      render={function FormImageUploadRender({ meta, input }) {
        const { value: uploaded } = input

        const handleDeleteImage = useCallback(async (fileObj: IUploadImageResponse) => {
          const isDeleted = await deleteImages(publicId, fileObj.filename)

          if (!isDeleted) {
            return
          }

          const restUploaded = uploaded.filter((item) => JSON.stringify(item) !== JSON.stringify(fileObj))
          change(name, restUploaded)
        }, [uploaded])

        return (
          <div className={styles.imageBlock}>
            {uploaded.map((fileObj, ind) => {
              const imageSrc = getThumbSmallUrl(fileObj, publicId)

              return (
                <UploadedPhoto
                  key={`${fileObj.filename}${fileObj.extension}`}
                  fileName={fileObj.filename}
                  imageSrc={imageSrc!}
                  hasCaption={ind === 0}
                  handleDelete={() => handleDeleteImage(fileObj)}
                />
              )
            })}
            {uploaded.length < 10 && (
              <DropImageUpload
                inputProps={input}
                publicId={publicId}
                fieldError={getFieldError(meta)}
                maxSize={maxSize}
              />
            )}
          </div>
        )
      }}
    />
  )
}

export default FormImageUpload
