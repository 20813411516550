import { IUploadImageResponse } from './upload.model'

export enum UserRoles {
  user = 'user',
  moderator = 'moderator',
  admin = 'admin',
}

export interface ICurrentUser {
  id: string;
  name: string;
  email: string;
  avatar?: IUploadImageResponse;
  createdAt: string;
  roles?: UserRoles[];
  isPhoneVerified?: boolean;
}
