import React from 'react'

import { emptyFunction } from '../../constants/function.constants'
import { I18N_POST_DATA } from '../../constants/i18n.constants'
import { ID } from '../../constants/id.constants'
import { getLocalizedObject } from '../../utils/translate.utils'
import { ITooltipData } from '../../yome-categories-fields-config/models/fields.model'
import RegularButton, { RegularButtonTypes } from '../button/RegularButton'
import FormCheckboxGroup from '../form/FormCheckbox/FormCheckboxGroup'

import filter from './filter.module.scss'

interface IFilterCheckboxFieldProps {
  title: string;
  fieldName: string;
  options: string[];
  tooltip?: ITooltipData;
  isTwoColumns?: boolean;
  hasButton?: boolean;
  showLabelIcon?: boolean;
  btnLabel?: string;
}

const FilterCheckboxField: React.FC<IFilterCheckboxFieldProps> = ({
  fieldName,
  options,
  isTwoColumns,
  hasButton,
  btnLabel,
  showLabelIcon,
}) => {
  const optionsBlockClassName = isTwoColumns ? filter.optionsBlockTwoCol : filter.optionsBlock

  const optionsObj = getLocalizedObject(options, `${I18N_POST_DATA}.${fieldName}`)

  return (
    <div>
      <div className={optionsBlockClassName}>
        <FormCheckboxGroup
          name={fieldName}
          options={optionsObj}
          icon={showLabelIcon}
        />
      </div>
      {hasButton && (
        <RegularButton
          id={ID.searchFilterShowMoreFilters}
          label={btnLabel || 'search.filterPanel.showMore'}
          onClick={emptyFunction}
          buttonType={RegularButtonTypes.transparentNoBorderLight}
        />
      )}
    </div>
  )
}

export default FilterCheckboxField
