import classNames from 'classnames'
import { memo } from 'react'
import { useLocation } from 'react-router-dom'

import { concatId, IdStructure } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import Paragraph from '../paragraph/Paragraph'
import MenuPoint, { MenuPointSize } from './MenuPoint'

import styles from './menu.module.scss'

interface IMenuPoint {
  id: string;
  linkTo: string;
}

interface IMenuGroupProps {
  i18nKey: string;
  id: IdStructure;
  className?: string;
  items: IMenuPoint[];
}

const MenuGroup = ({ id, items, i18nKey, className }: IMenuGroupProps) => {
  const { pathname } = useLocation()
  const { isDesktop } = useViewport()

  return (
    <section className={classNames(styles.main, className)}>
      {isDesktop && (
        <Paragraph
          text={`${i18nKey}.title`}
          className={classNames('label-20', styles.title)}
        />
      )}
      <ul className={styles.list}>
        {items.map((item) => (
          <MenuPoint
            {...item}
            key={item.id}
            id={concatId(id, item.id)}
            label={`${i18nKey}.${item.id}`}
            isActive={pathname === item.linkTo}
            size={MenuPointSize.LARGE}
          />
        ))}
      </ul>
    </section>
  )
}

export default memo(MenuGroup)
