import classNames from 'classnames'
import React, { useCallback } from 'react'

import { IdStructure } from 'src/constants/id.constants'
import { IAddFavoriteResponse } from 'src/models/post.model'
import { captureErrorAndShowToast } from 'src/utils/error.utils'

import RoundedControls from './RoundedControls'

import styles from './favoriteRoundedControl.module.scss'

interface IFavoriteRoundedControl {
  handleFavoriteClick: () => Promise<IAddFavoriteResponse | undefined>;
  id: IdStructure;
  isActive: boolean;
  isTransparent?: boolean;
}

const FavoriteRoundedControl: React.FC<IFavoriteRoundedControl> = (
  { handleFavoriteClick, id, isActive, isTransparent = false },
) => {
  const handleFavorite = useCallback(async () => {
    try {
      await handleFavoriteClick()
    } catch (error) {
      captureErrorAndShowToast(error)
    }
  }, [handleFavoriteClick])

  return (
    <div className={classNames(styles.main, { [styles.transparent]: isTransparent })}>
      <RoundedControls
        id={id}
        iconName='heart'
        onClick={handleFavorite}
        isActive={isActive}
      />
    </div>
  )
}

export default FavoriteRoundedControl
