import classNames from 'classnames'
import { memo } from 'react'
import { Trans } from 'react-i18next'

import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_LEGAL_IMPRINT, I18N_LEGAL_MENU } from 'src/constants/i18n.constants'
import { LegalLink } from 'src/constants/navigate.constants'
import LegalPageTitle from '../LegalPageTitle'

import styles from '../legalLayout.module.scss'

const ImprintRoute = () => (
  <article>
    <LegalPageTitle
      label={`${I18N_LEGAL_MENU}.${LegalLink.imprint}`}
    />
    <div className={classNames(styles.content, styles.imprint)}>
      <Paragraph className={styles.label} text={`${I18N_LEGAL_IMPRINT}.label`} />
      <Paragraph text={`${I18N_LEGAL_IMPRINT}.address`} />
      <p>
        <Trans i18nKey={`${I18N_LEGAL_IMPRINT}.legal`}>
          <span>
            HRB 257954 B, District Court Charlottenburg
          </span>
          <span>
            VAT identification number according to § 27a UStG: DE365565165
          </span>
          <span>
            Managing Director: Ekaterina Violentova
          </span>
        </Trans>
        <span>
          <a href='mailto:katerina.violentova@yome.network' target='_blank' rel="noopener noreferrer">
            katerina.violentova@yome.network
          </a>
        </span>
      </p>
      <p>
        <Trans i18nKey={`${I18N_LEGAL_IMPRINT}.dispute`}>
          <span>
            Online dispute resolution according to Art. 14 para. 1 ODR-VO:
          </span>
          <span>
            The European Commission provides a platform for online dispute resolution.
          </span>
          You can reach them at
          <a href='https://ec.europa.eu/consumers/odr/' target='_blank' rel="noopener noreferrer">
            Online Dispute Resolution | European Commission.
          </a>
          We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration
          board (§&nbsp;36 para. 1 no. 1 VSBG).
        </Trans>
      </p>
    </div>
  </article>
)

export default memo(ImprintRoute)
