import classNames from 'classnames'
import React from 'react'

import { I18N_FOOTER_LINK } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import { SECTION_ITEMS_SMALL } from 'src/models/footer.model'

import ButtonLink from '../button/ButtonLink'
import FooterSmallLegal from './FooterSmallLegal'

import layoutStyles from '../../routes/layout.module.scss'
import styles from './footer.module.scss'

interface IFooterSmall {
  isNarrow?: boolean;
}

const FooterSmall: React.FC<IFooterSmall> = ({ isNarrow = false }) => (
  <footer className={styles.main}>
    <div className={classNames(layoutStyles.base, styles.layout)}>
      <div className={classNames('supportive-14', styles.wrapperSmall, { [styles.wrapperNarrow]: isNarrow })}>
        <ul className={styles.linksRow}>
          {SECTION_ITEMS_SMALL.map(({ id, linkTo }) => (
            <li key={id}>
              <ButtonLink
                linkTo={linkTo}
                label={`${I18N_FOOTER_LINK}.${id}`}
                id={concatId(ID.footerLinkToOption, id)}
              />
            </li>
          ))}
        </ul>
        <FooterSmallLegal />
      </div>
    </div>
  </footer>
)

export default React.memo(FooterSmall)
