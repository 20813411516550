import { handleLanguageChange } from 'src/utils/language.utils'

export const LANG_COOKIE_NAME = 'yome-selected-language'

export enum TranslatedOptions {
  original = 'true',
  translated = 'false',
}

export enum LanguageOptions {
  DE = 'de',
  EN = 'en',
  RU = 'ru',
  AR = 'ar',
}

export const LANGUAGES: LanguageOptions[] = [
  LanguageOptions.DE,
  LanguageOptions.EN,
  LanguageOptions.RU,
  LanguageOptions.AR,
]

export const savedLanguage = localStorage.getItem(LANG_COOKIE_NAME) as LanguageOptions | null

export const userLanguage = window.navigator.language.split('-')[0] as LanguageOptions

export const LANGUAGE_OPTIONS = LANGUAGES.reduce((acc, lang) => (
  {
    ...acc,
    [lang]: {
      icon: lang,
      onClick: () => handleLanguageChange(lang),
    },
  }
), {})
