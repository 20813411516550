import { I18N_AUTH } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { PasswordCheckPointsEnum } from 'src/models/auth.model'
import { logout } from 'src/services/auth.service'

export interface IValidationResult {
  i18nKey: string;
  isPassed: boolean;
}

const latinRegex = /^[a-zA-Z0-9!@#$%^&*()_+{[\]:;"'<>,.?/|\\-]+$/ // letters, numbers, symbols
const upperLetterRegex = /[A-Z]/
const lowerLetterRegex = /[a-z]/
const numberRegex = /[0-9]/
const symbolRegex = /[^0-9a-zA-Z]/

export const validatePassword = (password: string) => {
  const validationResults = Object.keys(PasswordCheckPointsEnum).reduce((acc, chekPoint) => (
    {
      ...acc,
      [chekPoint]: {
        i18nKey: `${I18N_AUTH}.password.${chekPoint}`,
        isPassed: false,
      },
    }
  ), {}) as Record<PasswordCheckPointsEnum, IValidationResult>

  if (password?.length >= 8) {
    validationResults.minLength.isPassed = true
  }

  if (latinRegex.test(password)) {
    validationResults.latinLetters.isPassed = true
  }

  if (upperLetterRegex.test(password)) {
    validationResults.uppercasePresent.isPassed = true
  }

  if (lowerLetterRegex.test(password)) {
    validationResults.lowercasePresent.isPassed = true
  }

  if (numberRegex.test(password)) {
    validationResults.numberPresent.isPassed = true
  }

  if (symbolRegex.test(password)) {
    validationResults.symbolPresent.isPassed = true
  }

  const allChecksPassed = Object.values(validationResults).every((res) => res.isPassed)

  return {
    passwordValidationResult: validationResults,
    allChecksPassed,
  }
}

export const handleLogout = () => {
  logout()
    .then(() => {
      window.location.href = YomeURL.mainPage
    })
}
