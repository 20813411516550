import classNames from 'classnames'
import { FormApi, SubmissionErrors } from 'final-form'
import { ReactNode } from 'react'
import { Form } from 'react-final-form'

import { RegularButtonTypes } from 'src/components/button/RegularButton'
import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import ModalContent from 'src/components/modal/mobileModals/ModalContent'
import { I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'

interface ISettingLineEditModal<T> {
  toggle: () => void;
  title: string;
  subheading?: string;
  initialValues: Partial<T>;
  onSubmit: (values: T, form: FormApi<T, Partial<T>>) => Promise<SubmissionErrors>;
  children: ReactNode;
  isSubmitButtonHidden?: boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SettingLineEditModal = <T,>({
  toggle,
  title,
  initialValues,
  subheading,
  onSubmit,
  children,
  isSubmitButtonHidden = false,
}: ISettingLineEditModal<T>) => (
  <FullscreenModalLayout>
    <Form
      onSubmit={onSubmit}
      initialValues={ initialValues }
      render={({ handleSubmit, form: { getState } }) => (
        <form onSubmit={handleSubmit} className={classNames('h-100', 'mobile-paddings')}>
          <ModalContent
            title={title}
            subheading={subheading}
            handleClose={toggle}
            primaryButtonProps={isSubmitButtonHidden ? undefined : {
              id: ID.profileSettingButtonSave,
              buttonType: RegularButtonTypes.main,
              label: `${I18N_GLOBAL}.saveChanges`,
              type: 'submit',
              disabled: getState().pristine,
            }}
          >
            {children}
          </ModalContent>
        </form>
      )}
    />
  </FullscreenModalLayout>
)

export default SettingLineEditModal
