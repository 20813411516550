import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'

import Dropdown from 'src/components/dropdown/Dropdown'
import RoundedControls from 'src/components/roundedControls/RoundedControls'
import { I18N_PROFILE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { AvatarChangeTypes, PROFILE_AVATAR_LOCALIZE_PATH } from 'src/models/profile.model'
import { deleteAvatar, sendAvatar } from 'src/services/image.service'
import { captureErrorAndShowToast } from 'src/utils/error.utils'
import { useAssertValidAttachment } from 'src/hooks/form.hooks'
import SWRkeys from 'src/constants/swr.constants'

import styles from '../card.module.scss'

const MAX_AVATAR_SIZE = 6 // MB

interface ProfileAvatarDropdownProps {
  avatarFileName?: string;
}

const ProfileAvatarDropdown = ({ avatarFileName }: ProfileAvatarDropdownProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { mutate } = useSWRConfig()
  const { isDesktop } = useViewport()

  const { t } = useTranslation()
  const assertValidAttachment = useAssertValidAttachment(MAX_AVATAR_SIZE)

  const handleSaveAvatar = useCallback(async () => {
    if (inputRef.current && inputRef.current.files) {
      const photosArray = Array.from(inputRef.current.files)

      const isValidAttachment = assertValidAttachment(photosArray)

      if (!isValidAttachment) {
        return
      }

      try {
        await sendAvatar(photosArray[0], avatarFileName)
        await mutate(SWRkeys.currentUser)
        await mutate(SWRkeys.profile)
        toast.success(t(`${PROFILE_AVATAR_LOCALIZE_PATH}.toast.upload`))
      } catch (error) {
        captureErrorAndShowToast(error)
      }
    }
  }, [inputRef, avatarFileName, mutate, t, assertValidAttachment])

  const handleDeleteAvatar = async () => {
    try {
      await deleteAvatar(avatarFileName)
      await mutate(SWRkeys.currentUser)
      await mutate(SWRkeys.profile)
      toast.success(t(`${PROFILE_AVATAR_LOCALIZE_PATH}.toast.delete`))
    } catch (error) {
      captureErrorAndShowToast(error)
    }
  }

  const saveButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const profileAvatarDropdownSections = {
    [AvatarChangeTypes.upload]: {
      [`${PROFILE_AVATAR_LOCALIZE_PATH}.${AvatarChangeTypes.upload}`]: {
        icon: 'camera',
        onClick: saveButtonClick,
        info: `${I18N_PROFILE}.avatarSizeLimit`,
      },
    },
    [AvatarChangeTypes.delete]: {
      [`${PROFILE_AVATAR_LOCALIZE_PATH}.${AvatarChangeTypes.delete}`]: {
        icon: 'trash',
        onClick: handleDeleteAvatar,
      },
    },
  }

  return (
    <>
      <Dropdown
        id={ID.profilePageEditAvatarDropdown}
        sections={profileAvatarDropdownSections}
        className={styles.avatarDropdown}
        alignLeft={isDesktop}
      >
        <RoundedControls
          id={ID.profilePageSelectAvatarDropdown}
          iconName='camera'
        />
      </Dropdown>
      <input type='file'
        ref={inputRef}
        accept="image/*"
        onChange={handleSaveAvatar}
        hidden
      />
    </>
  )
}

export default ProfileAvatarDropdown
