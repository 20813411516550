import { Outlet } from 'react-router-dom'

import ErrorBoundary from 'src/components/errorBoundary/ErrorBoundary'
import SearchLocationContext from 'src/contexts/searchLocation.context'
import { useViewport } from 'src/contexts/viewportContext'
import { useSearchLocation } from 'src/hooks/search.hooks'
import SearchBar from 'src/routes/search/components/searchBar/SearchBar'
import { useCheckIsPage } from 'src/utils/hooks.utils'

const SearchLayout = () => {
  const { searchLocation, setSearchLocation } = useSearchLocation()

  const { isDesktop } = useViewport()
  const { isMainPage, isSearchPage } = useCheckIsPage()

  const showSearchBar = isDesktop || (isMainPage || isSearchPage)

  return (
    <SearchLocationContext.Provider
      value={{
        searchLocation,
        setSearchLocation,
      }}
    >
      {showSearchBar && <SearchBar/>}
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </SearchLocationContext.Provider>
  )
}

export default SearchLayout
