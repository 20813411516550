import { useViewport } from 'src/contexts/viewportContext'
import SectionHeader from '../sectionHeader/SectionHeader'
import FeaturesDesktop from './FeaturesDesktop'
import FeaturesMobile from './FeaturesMobile'

import styles from './features.module.scss'

const Features = () => {
  const { isDesktop } = useViewport()

  return (
    <section>
      <SectionHeader
        label={'features.title'}
        text={'features.subtitle'}
      />
      <div className={styles.features}>
        {isDesktop ? (
          <FeaturesDesktop />
        ) : <FeaturesMobile />}
      </div>
    </section>
  )
}

export default Features
