import { memo } from 'react'
import { Form } from 'react-final-form'

import { emptyFunction } from 'src/constants/function.constants'
import { IChat } from 'src/models/messenger.model'

import ChatFilter from '../chatFilter/ChatFilter'
import ChatList from '../chatList/ChatList'

import styles from './chatPanel.module.scss'

export const filterField = 'filterMessage'

const filterMessageInitial = {
  [filterField]: '',
}

interface IChatsPanel {
  currentChat: IChat | undefined;
}

const ChatsPanel = ({ currentChat }: IChatsPanel) => (
  <Form
    onSubmit={emptyFunction}
    initialValues={filterMessageInitial}
    render={function channelList() {
      return (
        <form className={styles.main}>
          <ChatFilter />
          <ChatList currentChat={currentChat} />
        </form>
      )
    }}
  />
)

export default memo(ChatsPanel)
