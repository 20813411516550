import { FOR_BUSINESS_FEATURES } from 'src/constants/staticPages.constants'
import SingleFeature from './SingleFeature'

import styles from './features.module.scss'

const FeaturesDesktop = () => (
  <>
    <ul className={styles.topRow}>
      {FOR_BUSINESS_FEATURES.slice(0, 3).map((feature) => (
        <SingleFeature feature={feature} key={feature} />
      ))}
    </ul>
    <ul className={styles.bottomRow}>
      {FOR_BUSINESS_FEATURES.slice(3).map((feature) => (
        <SingleFeature feature={feature} key={feature} />
      ))}
    </ul>
  </>
)

export default FeaturesDesktop
