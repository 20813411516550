import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { FOR_BUSINESS_FEATURES } from 'src/constants/staticPages.constants'

import SingleFeature from './SingleFeature'

const FeaturesMobile = () => (
  <>
    <Swiper
      spaceBetween={50}
      modules={[Pagination, Navigation]}
      slidesPerView={1}
      pagination={{
        clickable: true,
        el: '.swiper-custom-pagination',
      }}
      loop
    >
      {FOR_BUSINESS_FEATURES.map((feature) => (
        <SwiperSlide key={feature}>
          <SingleFeature feature={feature} />
        </SwiperSlide>
      ))}
    </Swiper>
    <div className="swiper-custom-pagination"/>
  </>
)

export default FeaturesMobile
