import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import UserAvatar from 'src/components/avatar/UserAvatar'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton, FormEmailField, FormError, FormPassword } from 'src/components/form'
import { UNVERIFIED_EMAIL } from 'src/constants/error.constants'
import { I18N_AUTH, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { YomeHash } from 'src/constants/navigate.constants'
import { useLoginUserContext } from 'src/contexts/loginUser.context'
import { useHashUrl } from 'src/hooks/router.hooks'
import { BaseAuthModalProps } from 'src/models/auth.model'
import { AvatarSizes } from 'src/models/upload.model'
import { EmailUrlAction } from 'src/models/url.model'
import { login } from 'src/services/auth.service'
import { useCurrentUser } from 'src/services/currentUser.service'

import InfoModal from '../infoModal/InfoModal'
import AuthModal, { IAuthModalBase } from './AuthModal'
import SocialSignin from './SocialSignin'

import styles from './auth.module.scss'

const LoginModal = React.memo(({ onSuccessSubmit, handleClose }: BaseAuthModalProps) => {
  const { t } = useTranslation()

  const { loginUser } = useLoginUserContext()

  const { isValidating, mutate: resetCurrentUser } = useCurrentUser()

  return (
    <InfoModal
      title={`${I18N_AUTH}.title.login`}
      handleClose={handleClose}
    >
      <Form
        initialValues={{ email: loginUser ? loginUser.email : '' }}
        onSubmit={login}
        render={function LoginFormRender({ values, handleSubmit, submitError, submitSucceeded }) {
          React.useEffect(() => {
            if (submitSucceeded) {
              resetCurrentUser()
            }
          }, [submitSucceeded])

          React.useEffect(() => {
            if (submitError && submitError === UNVERIFIED_EMAIL) {
              onSuccessSubmit(EmailUrlAction.verify, values.email)
            }
          }, [submitError, values.email])

          return (
            <form onSubmit={handleSubmit} className={styles.formContainer}>
              {(loginUser && loginUser.email === values.email) && (
                <div className={styles.user}>
                  <UserAvatar
                    image={loginUser.avatar}
                    avatarSize={AvatarSizes.tiny}
                    firstLetter={loginUser.name.charAt(0)}
                    userId={loginUser.id}
                  />
                  <p className='label-20'>
                    {loginUser.name}
                  </p>
                </div>
              )}
              <FormEmailField name='email' id={ID.loginModalInputEmail} autoFocus />
              <FormPassword id={ID.loginModalInputPassword} name="password" placeholder={`${I18N_GLOBAL}.password`}/>
              <FormError error={submitError}/>
              <RegularButton
                id={ID.loginModalLinkToForgotPassword}
                buttonType={RegularButtonTypes.transparentNoBorderLight}
                linkTo={useHashUrl(YomeHash.resetPassword)}
                label={t('auth.forgotPassword')}
                className={styles.alignRight}
              />
              <FormButton id={ID.loginModalButtonLogin} disabled={isValidating} />
            </form>
          )
        }}
      />
      <div className={styles.footer}>
        <SocialSignin />
        <p className='bodySizeM'>
          {t(`${I18N_AUTH}.dontHaveAccount`)}
        </p>
        <RegularButton
          id={ID.signupModalLinkToLoginModal}
          label={`${I18N_AUTH}.button.createAccount`}
          linkTo={useHashUrl(YomeHash.signup)}
          buttonType={RegularButtonTypes.transparentWithBorder}
          className={styles.buttonFullWidth}
        />
      </div>
    </InfoModal>
  )
})

const AuthLoginModal = React.memo(({ handleClose }: IAuthModalBase) => (
  <AuthModal handleClose={handleClose} SingleAuthModal={LoginModal}/>
))

export default AuthLoginModal
