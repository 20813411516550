import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_ABOUT_US_HERE_YOU_CAN } from 'src/constants/i18n.constants'
import { HERE_YOU_CAN } from 'src/constants/staticPages.constants'

import aboutStyles from '../../about.module.scss'
import styles from './hereYouCan.module.scss'

const HereYouCan = () => (
  <div>
    <Heading
      level='h2'
      label={`${I18N_ABOUT_US_HERE_YOU_CAN}.title`}
      className={aboutStyles.h2title}
    />
    <ul className={styles.main}>
      {HERE_YOU_CAN.map((item) => (
        <li key={item} className={styles.item}>
          <SvgIcon name={`about/${item}`} />
          <Paragraph
            text={`${I18N_ABOUT_US_HERE_YOU_CAN}.text.${item}`}
            className={styles.text}
          />
        </li>
      ))}
    </ul>
  </div>
)

export default HereYouCan
