import { memo, useContext, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import SWRkeys from 'src/constants/swr.constants'
import SearchLocationContext from 'src/contexts/searchLocation.context'
import { useViewport } from 'src/contexts/viewportContext'
import { getCurrentSearchPostParams } from 'src/services/search.service'
import { useSearchPageTitle } from 'src/utils/hooks.utils'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import { getPathToCurrentCategory } from 'src/yome-categories-fields-config/utils/category.utils'

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import FilterSidebar from '../../components/filterSidebar/FilterSidebar'
import { getSearchBarInitialValues } from '../../utils/search.utils'
import FilterChips from './components/FilterChips/FilterChips'
import FullscreenFilters from './components/FullscreenFilters/FullscreenFilters'
import SearchActionBar from './components/searchActionBar/SearchActionBar'
import SelectCityButton from './components/searchBar/SelectCityButton'
import SearchResults from './components/searchResults/SearchResults'
import SearchResultsTitle from './components/searchResults/SearchResultsTitle'

import styles from './search.module.scss'

const SearchRoute = () => {
  const { isDesktop } = useViewport()
  const [searchParams] = useSearchParams()
  const [foundPosts, setFoundPosts] = useState(0)
  const initialValues = useMemo(() => getSearchBarInitialValues(searchParams), [searchParams])

  const { category, searchContent } = initialValues
  useSearchPageTitle(searchContent, category)
  const { searchLocation } = useContext(SearchLocationContext)

  const breadcrumbsPath = useMemo(() => (
    getPathToCurrentCategory(category || ANY_CATEGORY)
  ), [category])

  if (!searchLocation?.locationAddress) {
    // TODO: add a loading animation
    return null
  }

  const searchFields = getCurrentSearchPostParams(searchParams, searchLocation.locationPosition)
  const searchEndpoint = SWRkeys.searchWithParams(searchFields as Record<string, any>)

  return (
    <div className={styles.main}>
      {isDesktop && <Breadcrumbs tags={breadcrumbsPath} i18nPrefix={I18N_CATEGORIES} />}
      <SearchResultsTitle
        query={searchContent || ''}
        city={searchLocation.locationAddress}
        foundPostsCount={foundPosts}
      />
      {!isDesktop && (
        <>
          <div className={styles.filtersButtonsRow}>
            <SelectCityButton />
            <FullscreenFilters />
          </div>
          <FilterChips/>
        </>
      )}
      <div className={styles.searchWrapper}>
        {isDesktop && (
          <div className={styles.filterPanelWrapper}>
            <FilterSidebar />
            <ShowcaseArea />
          </div>
        )}
        <div className={styles.resultsWrapper}>
          <SearchActionBar
            endpointSearchFields={searchFields}
            searchEndpoint={searchEndpoint}
          />
          <SearchResults
            setFoundPosts={setFoundPosts}
            searchEndpoint={searchEndpoint}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(SearchRoute)
