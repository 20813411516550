import Chip, { ChipType } from '../button/Chip/Chip'

interface IPromptsList {
  prompts: string[];
  onChipClick: (prompt: string) => void;
}

const PromptsList: React.FC<IPromptsList> = ({ prompts, onChipClick }) => (
  <>
    {prompts.map((prompt) => (
      <Chip
        key={prompt}
        value={prompt}
        type={ChipType.dark}
        onClick={() => onChipClick(prompt)}
      />
    ))}
  </>
)

export default PromptsList
