import { cloneElement, useRef } from 'react'

interface UploadFileProps {
  handleFileUpload: (acceptedFiles: File[]) => void;
  children: JSX.Element;
}

const UploadFile = ({ children, handleFileUpload }: UploadFileProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleInputChange = () => {
    if (inputRef.current && inputRef.current.files) {
      const filesArray = Array.from(inputRef.current.files)
      handleFileUpload(filesArray)
    }
  }

  return (
    <>
      {cloneElement(children, { onClick: () => inputRef.current?.click() })}
      <input
        type='file'
        multiple
        ref={inputRef}
        onChange={handleInputChange}
        accept="image/*"
        hidden
      />
    </>
  )
}

export default UploadFile
