import { Tooltip as ReactTooltip } from 'react-tooltip'

const BASE_TOOLTIP_PROPS = {
  opacity: 1,
  className: 'tooltip',
}

const TooltipContainer = () => (
  <>
    <ReactTooltip
      {...BASE_TOOLTIP_PROPS}
      id='light-tooltip'
    />
    <ReactTooltip
      {...BASE_TOOLTIP_PROPS}
      id='dark-tooltip'
    />
  </>
)

export default TooltipContainer
