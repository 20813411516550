import { memo } from 'react'
import classNames from 'classnames'

import OfferCardLabel from 'src/components/offerCardLabel/OfferCardLabel'

import styles from './offerImages.module.scss'

interface IImagesCounterProps {
  index: number;
  total: number;
  className?: string;
}

const ImagesCounter = memo(({ index, total, className }: IImagesCounterProps) => (
  <div className={classNames(styles.counterOverlay, className)}>
    <div className={styles.counter}>
      <OfferCardLabel text={`${index + 1}/${total}`} leftIcon='photoCamera' />
    </div>
  </div>
))

export default ImagesCounter
