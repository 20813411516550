import React, { useCallback, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'

import ChatInput, { CHAT_INPUT_NAME } from 'src/components/chatInput/ChatInput'
import Heading from 'src/components/heading/Heading'
import PromptsList from 'src/components/promptsList/PromptsList'
import { I18N_POST_CHAT } from 'src/constants/i18n.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { useCurrentUser } from 'src/services/currentUser.service'
import { CHAT_ID_SEARCH_PARAM, openOrCreateChatByPost } from 'src/services/messenger.service'
import { getMessagePrompts } from 'src/utils/chat.utils'
import { captureErrorAndShowToast } from 'src/utils/error.utils'
import { MainCategories } from 'src/yome-categories-fields-config/models/fields.model'

import styles from './offerMessage.module.scss'

interface IOfferMessage {
  mainCategory: MainCategories;
  postId: string;
}

// TODO: component is too complex and should be split
const OfferMessage: React.FC<IOfferMessage> = ({ mainCategory, postId }) => {
  const { data: currentUser } = useCurrentUser()

  const navigate = useNavigate()
  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const { t } = useTranslation()
  const helloLabel = t(`${I18N_POST_CHAT}.hello`)
  const [prompts, setPrompts] = useState(getMessagePrompts(mainCategory))

  const handleOnSubmit = useCallback(async (data: { message: string }) => {
    if (!currentUser) {
      navigate(YomeHash.auth)

      return
    }

    const messageToSend = data.message

    if (messageToSend) {
      try {
        const chatId = await openOrCreateChatByPost(postId, messageToSend)
        navigate({
          pathname: YomeURL.messages,
          search: createSearchParams({ [CHAT_ID_SEARCH_PARAM]: chatId }).toString(),
        })
      } catch (error) {
        captureErrorAndShowToast(error)
      }
    }
  }, [currentUser, navigate, postId])

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={{ [CHAT_INPUT_NAME]: helloLabel }}
      render={({ handleSubmit, form: { change, getFieldState, focus } }) => {
        const handleChipClick = (prompt: string) => {
          const currentState = getFieldState(CHAT_INPUT_NAME)
          const currentValue = currentState?.value ? `${currentState.value} ` : `${helloLabel} `

          change(CHAT_INPUT_NAME, `${currentValue}${t(prompt)}`)
          focus(CHAT_INPUT_NAME)
          inputRef.current?.focus()
          setPrompts((prevPrompts: string[]) => prevPrompts.filter((p) => p !== prompt))
        }

        return (
          <form onSubmit={handleSubmit} className={styles.main}>
            <Heading
              level="h6"
              className="desktop-h6 mobile-h6"
              label={`${I18N_POST_CHAT}.title.${mainCategory}`}
            />
            <div className={styles.message}>
              <ChatInput inputRef={inputRef} />
              <div className={styles.prompts}>
                <PromptsList prompts={prompts} onChipClick={handleChipClick} />
              </div>
            </div>
          </form>
        )
      }}
    />
  )
}

export default React.memo(OfferMessage)
