import Heading from 'src/components/heading/Heading'

interface ILegalHeading {
  label: string;
}

const LegalSectionTitle = ({ label }: ILegalHeading) => (
  <Heading
    level='h2'
    label={label}
    className={'desktop-h5 mobile-h4'}
  />
)

export default LegalSectionTitle
