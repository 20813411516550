import { useEffect } from 'react'

import { KEYBOARD_ESC_KEY } from 'src/constants/general.constants'

// eslint-disable-next-line import/prefer-default-export
export const useOnEscPress = (onEscPress: () => void) => {
  useEffect(() => {
    const handleEscPress = (event: KeyboardEvent) => {
      if (event.key === KEYBOARD_ESC_KEY) {
        onEscPress()
      }
    }

    document.addEventListener('keydown', handleEscPress)

    return () => {
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [onEscPress])
}
