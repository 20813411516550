import classNames from 'classnames'
import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { useToggle } from 'src/utils/hooks.utils'

import SvgIcon from '../svgIcon/SvgIcon'
import FieldWrapper, { IFieldWrapperProps } from './FieldWrapper'

import styles from './form.module.scss'

interface IFormPasswordProps {
  fieldWrapperProps?: IFieldWrapperProps;
  id: string;
  name: string;
  placeholder?: string;
  autoFocus?: boolean;
}

export const FormPassword = React.memo(({
  fieldWrapperProps,
  id,
  name,
  placeholder = '',
  autoFocus,
}: IFormPasswordProps) => {
  const [isOpen, toggleIsOpen] = useToggle()
  const iconName = isOpen ? 'eye-on' : 'eye-off'

  const { t } = useTranslation()

  return (
    <Field name={name}>
      {function renderFormPassword({ input }) {
        return (
          <FieldWrapper {...fieldWrapperProps} name={name}>
            <div className={styles.password}>
              <input
                {...input}
                id={id}
                type={isOpen ? 'text' : 'password'}
                placeholder={t(placeholder)}
                className={classNames(styles.fieldInput, styles.passwordInput)}
                title={name}
                autoFocus={autoFocus}
              />
              <button onClick={toggleIsOpen} className={styles.eyeButton} type='button'>
                <SvgIcon
                  name={iconName}
                  currentColor='stroke'
                />
              </button>
            </div>
          </FieldWrapper>
        )
      }}
    </Field>
  )
})

export default FormPassword
