import { IUserBadgeInfo, UserBadgesEnum } from 'src/models/badge.models'
import { ICurrentUser } from 'src/models/currentUser.model'
import { IUser } from 'src/models/user.model'

// eslint-disable-next-line import/prefer-default-export
export const getUserBadges = (user: IUser | ICurrentUser): IUserBadgeInfo[] => {
  if (!user.isPhoneVerified) {
    return []
  }

  return [{ badgeName: UserBadgesEnum.phoneVerified }]
}
