import { lazy, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Loader from 'react-spinners/CircleLoader'
import useSWR, { KeyedMutator } from 'swr'

import ModerationWrapper from 'src/components/moderation/ModerationWrapper'
import SomethingWentWrong from 'src/components/somethingWentWrong/SomethingWentWrong'
import { ISWRErrorBoundaryProps } from 'src/components/swr/SWRErrorBoundary'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { UserRoles } from 'src/models/currentUser.model'
import { OfferStatus } from 'src/models/offerStatus.model'
import { IPropsWithOffer, SinglePost } from 'src/models/post.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { captureException } from 'src/utils/browser.utils'
import { assertData, NotFoundError, PostDeletedError } from 'src/utils/error.utils'
import { useOfferPageTitle } from 'src/utils/hooks.utils'

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import { I18N_CATEGORIES } from '../../constants/i18n.constants'
import { useGetOffer } from '../../services/post.service'
import MobileOffer from './components/mobileOffer/MobileOffer'
import OfferContent from './components/OfferContent/OfferContent'
import PostNotFound from './components/postNotFound/PostNotFound'
import PostStickyInfo from './components/postStickyInfo/PostStickyInfo'
import SimilarOffers from './components/similarOffers/SimilarOffers'

import styles from './postRoute.module.scss'

const OfferModeration = lazy(() => import('src/components/moderation/OfferModeration'))

interface IPostRouteProps {
  slug: string;
}

interface IOfferWrapperProps extends IPropsWithOffer {
  mutate: KeyedMutator<SinglePost>;
}

const OfferWrapper = ({ offer, mutate }: IOfferWrapperProps) => {
  const { title, category, categoryPath, location } = offer

  const { isDesktop } = useViewport()
  useOfferPageTitle(title, category, location?.city)

  if (!isDesktop) {
    return <MobileOffer offer={offer} mutate={mutate}/>
  }

  return (
    <>
      <Breadcrumbs tags={categoryPath} i18nPrefix={I18N_CATEGORIES} />
      <div className={styles.main}>
        <div className={styles.content}>
          <OfferContent offer={offer}/>
        </div>
        <div className={styles.sidebar}>
          <PostStickyInfo offer={offer}/>
        </div>
      </div>
      {offer.status !== OfferStatus.draft && <SimilarOffers offer={offer} />}
    </>
  )
}

const PostRoute = ({ slug }: IPostRouteProps) => {
  const { data: offer, isLoading, mutate } = useGetOffer(SWRkeys.getPublicOffer(slug))

  const { data: currentUser } = useCurrentUser()

  const isModerator = currentUser?.roles?.includes(UserRoles.moderator)

  useEffect(() => {
    mutate()
  }, [currentUser, mutate])

  if (isLoading) {
    return (
      <Loader />
    )
  }

  assertData(offer)

  return (
    <>
      <OfferWrapper offer={offer} mutate={mutate} />
      {isModerator && (
        <ModerationWrapper>
          <OfferModeration offer={offer} mutateOffer={mutate}/>
        </ModerationWrapper>
      )}
    </>
  )
}

const SWRPostRouteErrorBoundary = ({ children, swrKey }: ISWRErrorBoundaryProps) => {
  const { error } = useSWR(swrKey)

  if (error instanceof NotFoundError) {
    return <PostNotFound/>
  }

  if (error instanceof PostDeletedError) {
    return <PostNotFound isDeleted={true}/>
  }

  if (error) {
    captureException(error)

    return <SomethingWentWrong />
  }

  return (
    <>
      {children}
    </>
  )
}

const PostRouteBoundary = () => {
  const { slug } = useParams()

  return (
    <SWRPostRouteErrorBoundary swrKey={SWRkeys.getPublicOffer(slug!)}>
      <PostRoute slug={slug!}/>
    </SWRPostRouteErrorBoundary>
  )
}

export default PostRouteBoundary
