import classNames from 'classnames'
import { memo, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import IconButton from 'src/components/button/IconButton'
import Heading from 'src/components/heading/Heading'
import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { DraftValidateResponse, PostFormFields } from 'src/models/post.model'
import * as postService from 'src/services/post.service'
import { assertData } from 'src/utils/error.utils'
import { useToggle } from 'src/utils/hooks.utils'
import {
  getOfferParts,
  handleOfferUpdateError,
  serialiseOfferFormFields,
  validateAndUpdateDraft,
} from 'src/utils/post.utils'

import EditDraftForm from '../components/form/EditDraftForm'
import SuccessOfferPosted from '../components/form/SuccessOfferPosted'

import styles from '../newItem.module.scss'

interface IDraftOfferRouteProps {
  publicId: string;
}

const FullDraftOfferFormWrapper = ({ children }: PropsWithChildren) => (
  <>
    <div className={classNames(styles.heading, styles.draftFormHeader)}>
      <IconButton linkTo={YomeURL.newOffer} iconName='back-button' id={ID.editDraftBackButton} size={32} />
      <Heading label={`${I18N_CATEGORIES}.selectCategory`} className='desktop-h4' />
    </div>
    {children}
  </>
)

const DraftOfferRoute = ({ publicId }: IDraftOfferRouteProps) => {
  const { data: formValues, isLoading, mutate } = postService.useGetDraft(SWRkeys.getDraft(publicId))
  const { t } = useTranslation()
  const { isDesktop } = useViewport()
  const [partIndex, setPartIndex] = useState(0)
  const [isSuccessOpen, toggleSuccessOpen] = useToggle()
  const [validatedValues, setValidatedValues] = useState(formValues)
  const lastCategory = formValues?.categoryPath[formValues.categoryPath.length - 1]
  const [submitCategory, setCategory] = useState(lastCategory)
  const [submitSlug, setSlug] = useState(publicId)
  const [submitTitle, setTitle] = useState(formValues?.title || '')
  const [validationErrors, setValidationErrors] = useState<DraftValidateResponse>({})
  const validatedValuesStr = validatedValues ? serialiseOfferFormFields(validatedValues) : ''

  useEffect(() => {
    setValidatedValues(formValues)
  }, [formValues])

  const handleValidate = useCallback(async (data: PostFormFields) => {
    /**
     * Final Form is calling validate on mount. To skip it, we compare initial and validated values
     */
    if (serialiseOfferFormFields(data) !== validatedValuesStr) {
      setValidatedValues(data)
      const errors = await validateAndUpdateDraft(data)

      setValidationErrors(errors)
    }

    return validationErrors
  }, [setValidationErrors, validationErrors, validatedValuesStr])

  const handleSubmit = async (data: PostFormFields) => {
    const { isLastPart } = getOfferParts(data.categoryPath, partIndex)

    try {
      if (!isDesktop && !isLastPart) {
        await postService.validateOffer(data, partIndex, publicId)
        mutate(data)
      } else {
        const { category, slug } = await postService.publishPost(data)

        setCategory(category)
        setSlug(slug)
        setTitle(data.title!)

        return toggleSuccessOpen()
      }

      return undefined
    } catch (error) {
      return handleOfferUpdateError(error, data, t)
    }
  }

  if (isLoading || !validatedValues) {
    return null
  }

  return (
    <>
      <Form<PostFormFields>
        initialValues={formValues!}
        onSubmit={handleSubmit}
        validate={handleValidate}
        validateOnBlur={!Object.keys(validationErrors || {}).length}
        render={function renderEditDraftForm(props) {
          return (
            <EditDraftForm
              {...props}
              resetDraft={mutate}
              partIndex={partIndex}
              setPartIndex={setPartIndex}
            />
          )
        }}
      />
      {isSuccessOpen && (
        <SuccessOfferPosted
          category={submitCategory!}
          slug={submitSlug}
          title={submitTitle}
          onClose={toggleSuccessOpen}
        />
      )}
    </>
  )
}

const DraftOfferRouteBoundary = () => {
  const { isDesktop } = useViewport()
  const { publicId } = useParams()
  assertData(publicId)

  const DraftFormWrapper = isDesktop ? FullDraftOfferFormWrapper : FullscreenModalLayout

  return (
    <SWRErrorBoundary swrKey={SWRkeys.getDraft(publicId)}>
      <DraftFormWrapper>
        <DraftOfferRoute publicId={publicId} />
      </DraftFormWrapper>
    </SWRErrorBoundary>
  )
}

export default memo(DraftOfferRouteBoundary)
