import React from 'react'

interface ScrollHereProps {
  dependency: string | boolean;
}

const ScrollHere = ({ dependency }: ScrollHereProps) => {
  const scrolledToElement = React.useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () => {
    scrolledToElement.current?.scrollIntoView()
  }

  React.useEffect(() => {
    scrollToBottom()
  }, [dependency])

  return (
    <div ref={scrolledToElement} />
  )
}

export default ScrollHere
