import { Form } from 'react-final-form'

import withModalLayout from 'src/components/hocs/withModalLayout'
import { IReportUserFormValues, IReportUserModalProps } from 'src/models/report.model'
import MessengerService from 'src/services/messenger.service'
import { returnFormErrorsOrShowToast } from 'src/utils/error.utils'
import { useToggle } from 'src/utils/hooks.utils'

import ModalLayout from '../ModalLayout'
import ReportSubmittedModal from './reportedModal/ReportSubmittedModal'
import ReportUserForm from './reportForms/ReportUserForm'

import styles from './reportModal.module.scss'

const ReportUserModal = ({ handleClose, modalProps }: IReportUserModalProps) => {
  const [successOpen, toggleSuccessOpen] = useToggle()

  const { chatId, reportedUserId } = modalProps

  const handleReportUser = async ({ reason, comment, isAllowedToRead }: IReportUserFormValues) => {
    try {
      await MessengerService.handleReportUser({ chatId, reportedUserId, reason, isAllowedToRead, comment })
      toggleSuccessOpen()
    } catch (error) {
      returnFormErrorsOrShowToast(error)
    }
  }

  if (successOpen) {
    return (
      <ModalLayout handleClose={handleClose}>
        <ReportSubmittedModal handleClose={handleClose}/>
      </ModalLayout>
    )
  }

  return (
    <Form
      onSubmit={handleReportUser}
      render={function RenderReportModalForm({ handleSubmit }) {
        return (
          <form onSubmit={handleSubmit} className={styles.modalContainer}>
            <ReportUserForm handleClose={handleClose}/>
          </form>
        )
      }}
    />
  )
}

export default withModalLayout(ReportUserModal)
