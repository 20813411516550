import classNames from 'classnames'
import React from 'react'

import SvgIcon from 'src/components/svgIcon/SvgIcon'

import styles from './languageButton.module.scss'

interface ILanguageButton {
  onClick?: () => void;
  label: string;
  type?: 'text' | 'flag';
  background?: 'dark' | 'light';
}

const LanguageButton: React.FC<ILanguageButton> = ({ onClick, label, type = 'text', background = 'dark' }) => (
  <button
    className={classNames(styles.main, styles[background])}
    onClick={onClick}
    type='button'
  >
    {type === 'text' ? (
      <span className={classNames('label-14', styles.selectedText)}>
        {label}
      </span>
    ) : (
      <SvgIcon name={label} size={20} />
    )}
    <div className={styles.chevron}>
      <SvgIcon name='chevron-down' size={16} currentColor='stroke' />
    </div>
  </button>
)

export default LanguageButton
