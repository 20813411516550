import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import useSWR from 'swr'

import IconButton from 'src/components/button/IconButton'
import Dropdown from 'src/components/dropdown/Dropdown'
import ReportUserModal from 'src/components/modal/reportModal/ReportUserModal'
import { emptyFunction } from 'src/constants/function.constants'
import { I18_MESSENGER_HEADER_DROPDOWN, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useModalsContext } from 'src/contexts/openModalContext'
import { IChatUser } from 'src/models/messenger.model'
import { ContextModalType } from 'src/models/modal.model'
import { handleUnblockUser } from 'src/services/messenger.service'

import styles from './styles.module.scss'

enum ChatDropdownTypes {
  toProfile = 'toProfile',
  leaveFeedback = 'leaveFeedback',
  toOffer = 'toOffer',
  blockUser = 'blockUser',
  unblockUser = 'unblockUser',
  report = 'report',
  markUnread = 'markUnread',
  pinChat = 'pinChat',
  deleteChat = 'deleteChat',
  help = 'help',
}

interface ChatHeaderDropdownProps {
  receiverInfo: IChatUser;
  link: string;
  title: string;
  chatId: string;
  receiverId: string;
}

const ChatHeaderDropdown = ({
  receiverInfo,
  link,
  title,
  chatId,
  receiverId,
}: ChatHeaderDropdownProps) => {
  const { mutate: mutateChats } = useSWR(SWRkeys.getAllChats)
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false)
  const { setContextModal } = useModalsContext()

  const handleReportModalClose = useCallback(() => {
    setIsReportModalOpen(false)
  }, [setIsReportModalOpen])

  const onReportClick = useCallback(() => {
    setIsReportModalOpen(true)
  }, [setIsReportModalOpen])

  const { isBlocked } = receiverInfo

  const toggleBlockUser = useCallback(async () => {
    if (isBlocked) {
      try {
        await handleUnblockUser(receiverId)
      } catch (err) {
        toast.error(`${I18N_GLOBAL}.serverError`)
      }
    } else {
      const payload = { partnerId: receiverId, chatId }
      setContextModal({ modalType: ContextModalType.blockUser, isNonFullscreen: true, payload })
    }

    mutateChats([])
  }, [chatId, isBlocked, mutateChats, receiverId, setContextModal])

  const chatActions = {
    profile: {
      [`${I18_MESSENGER_HEADER_DROPDOWN}.${ChatDropdownTypes.toProfile}`]: {
        icon: 'profile',
        linkTo: YomeURL.userPage(receiverInfo.id),
      },
    },
    partner: {
      [`${I18_MESSENGER_HEADER_DROPDOWN}.${ChatDropdownTypes.toOffer}`]: {
        icon: 'card-offer-post',
        linkTo: link,
        i18nOptions: { title },
      },
      // [`${I18_MESSENGER_HEADER_DROPDOWN}.${ChatDropdownTypes.leaveFeedback}`]: {
      //   icon: 'feedback',
      //   onClick: () => console.log('leaveFeedback'),
      // },
      [`${I18_MESSENGER_HEADER_DROPDOWN}.${
        isBlocked
          ? ChatDropdownTypes.unblockUser
          : ChatDropdownTypes.blockUser}`]: {
        icon: isBlocked ? 'hand-crossed' : 'hand',
        onClick: toggleBlockUser,
        i18nOptions: receiverInfo,
      },
      [`${I18_MESSENGER_HEADER_DROPDOWN}.${ChatDropdownTypes.report}`]: {
        icon: 'report',
        onClick: onReportClick,
      },
    },
    // chatInterraction: {
    //   [`${I18_MESSENGER_HEADER_DROPDOWN}.${ChatDropdownTypes.markUnread}`]: {
    //     icon: 'unread',
    //     onClick: () => console.log('markUnread'),
    //   },
    //   [`${I18_MESSENGER_HEADER_DROPDOWN}.${ChatDropdownTypes.pinChat}`]: {
    //     icon: 'pin',
    //     onClick: () => console.log('pinChat'),
    //   },
    //   [`${I18_MESSENGER_HEADER_DROPDOWN}.${ChatDropdownTypes.deleteChat}`]: {
    //     icon: 'trash',
    //     onClick: () => console.log('deleteChat'),
    //   },
    // },
    help: {
      [`${I18_MESSENGER_HEADER_DROPDOWN}.${ChatDropdownTypes.help}`]: {
        icon: 'help',
        linkTo: YomeURL.help,
      },
    },
  }

  const modalProps = {
    chatId,
    reportedUserId: receiverInfo.id,
  }

  return (
    <>
      <Dropdown
        id={ID.messengerSelectOption}
        sections={chatActions}
        className={styles.dropdown}
      >
        <div>
          <IconButton
            onClick={emptyFunction}
            iconName={'dots-menu'}
            id={ID.messengerToggleChatRoomActions}
          />
        </div>
      </Dropdown>
      {isReportModalOpen && (
        <ReportUserModal
          modalProps={modalProps}
          handleClose={handleReportModalClose}
        />
      )
      }
    </>
  )
}

export default ChatHeaderDropdown
