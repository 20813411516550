// eslint-disable-next-line import/prefer-default-export
export const TOPICS = [
  'offerBlocked',
  'accountBlocked',
  'login',
  'fraud',
  'paidServices',
  'delivery',
  'payment',
  'profileSettings',
  'offerSettings',
  'reviews',
  'search',
  'location',
  'legalEntities',
  'feedback',
  'delete',
  'other',
]
