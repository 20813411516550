import React, { useCallback, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import Heading from 'src/components/heading/Heading'
import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import { emptyFunction } from 'src/constants/function.constants'
import { I18N_GLOBAL, I18N_VERIFY_PHONE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { usePhoneVerificationNeeded } from 'src/hooks/verificationCode.hooks'
import { createDraft } from 'src/services/post.service'

import MobileNewItemCategory from '../components/mobileNewItemCategory/MobileNewItemCategory'
import NewItemCategorySelector from '../components/newItemCategorySelector/NewItemCategorySelector'

import styles from '../newItem.module.scss'

const CATEGORY_FIELD_NAME = 'categoryPath'
const SEARCH_CATEGORY_FIELD_NAME = 'searchQuery'

const NewItemRoute = () => {
  const navigate = useNavigate()
  const onSubmit = useCallback(emptyFunction, [])
  const { isDesktop } = useViewport()
  const { t } = useTranslation()

  const activeHash = useLocation().hash

  const { isPhoneVerificationNeeded, mutateIsPhoneVerified } = usePhoneVerificationNeeded()

  // refetch current user on hash change - hash changes on verify modal open/close
  useEffect(() => {
    mutateIsPhoneVerified()
  }, [activeHash, mutateIsPhoneVerified])

  const FormWrapper = isDesktop ? React.Fragment : FullscreenModalLayout

  if (isPhoneVerificationNeeded) {
    const emptyStateProps = {
      title: `${I18N_VERIFY_PHONE}.newItem.title`,
      text: `${I18N_VERIFY_PHONE}.newItem.text`,
      icon: 'user-search',
      button: {
        id: ID.notFoundLinkToMainPage,
        buttonType: RegularButtonTypes.blackNoBorder,
        label: `${I18N_VERIFY_PHONE}.newItem.button`,
        linkTo: YomeHash.verifyPhone,
      },
    }

    return (
      <>
        <Heading label={`${I18N_GLOBAL}.newItem`} className='desktop-h4 mobile-h4' />
        <EmptyStateMessage
          {...emptyStateProps}
        />
      </>
    )
  }

  return (
    <FormWrapper>
      <Form
        initialValues={{ categoryPath: [] }}
        onSubmit={onSubmit}
        render={function NewItemFormInternal({ handleSubmit, form: { change } }) {
          const handleCategoryChange = React.useCallback((value: string[]) => {
            change(CATEGORY_FIELD_NAME, value)

            if (value.length) {
              createDraft({ categoryPath: value }).then(({ slug }) => {
                navigate(YomeURL.editDraft(slug))
              }).catch((error: Error) => toast.error(t(error.message)))
            }
          }, [change])

          return (
            <form className={styles.newItemMainCategories} onSubmit={handleSubmit}>
              {isDesktop ? (
                <NewItemCategorySelector
                  onChange={handleCategoryChange}
                  searchFieldName={SEARCH_CATEGORY_FIELD_NAME}
                />
              ) : (
                <MobileNewItemCategory
                  submitCategory={handleCategoryChange}
                  searchFieldName={SEARCH_CATEGORY_FIELD_NAME}
                />
              )}
            </form>
          )
        }}
      />
    </FormWrapper>
  )
}

export default NewItemRoute
