import { Trans } from 'react-i18next'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ADVERTISING } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'

import styles from './header.module.scss'

const AdvertisingHeader = () => (
  <div className={styles.header}>
    <Heading className="static-heading mobile-h1 desktop-h1">
      <Trans
        i18nKey={`${I18N_ADVERTISING}.heading`}
        components={{ 1: <span /> }}
      >
      </Trans>
    </Heading>
    <Paragraph
      text={`${I18N_ADVERTISING}.subheading`}
      className='body-text desktop-body-32 bodySizeL'
    />
    <RegularButton
      label={`${I18N_ADVERTISING}.headerBtn`}
      id={ID.advertisingLaunchButton}
      buttonType={RegularButtonTypes.main}
      linkTo={YomeURL.help}
      className={styles.btn}
      size={RegularButtonSizes.large}
    />
  </div>
)

export default AdvertisingHeader
