import { useTranslation } from 'react-i18next'
import { CircleLoader } from 'react-spinners'

import SWRLogErrorAndContinue from 'src/components/swr/SWRErrorSkip'
import { I18N_PROFILE } from 'src/constants/i18n.constants'
import SWRkeys from 'src/constants/swr.constants'
import { IMyOffersCounts, IOfferList } from 'src/models/post.model'
import { useGetPostList } from 'src/services/post.service'

import TabBarLayout from '../../../../components/tabBarLayout/TabBarLayout'
import { getProfileNavLink } from '../../../../models/navigation.model'

const MyOffersLayout = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useGetPostList<IOfferList<IMyOffersCounts>>(SWRkeys.offerProfileCounters)

  if (isLoading) {
    return <CircleLoader />
  }

  return (
    <TabBarLayout
      title={t(`${I18N_PROFILE}.myItems`)}
      tabItems={getProfileNavLink(data?.items)}
    />
  )
}

const MyOffersLayoutBoundary = () => (
  <SWRLogErrorAndContinue swrKey={SWRkeys.offerProfileCounters}>
    <MyOffersLayout />
  </SWRLogErrorAndContinue>
)

export default MyOffersLayoutBoundary
