import { countryCodeEmoji } from 'country-code-emoji'

import { I18N_POST_ACTION } from 'src/constants/i18n.constants'
import { OfferActions } from 'src/models/offerActions.model'

import i18n from '../i18n'

// eslint-disable-next-line import/prefer-default-export
export const translatePostAction = (action: OfferActions) => {
  const { t } = i18n

  const actionToTranslation: Record<OfferActions, string> = {
    [OfferActions.archive]: t(`${I18N_POST_ACTION}.archive`),
    [OfferActions.edit]: t(`${I18N_POST_ACTION}.edit`),
    [OfferActions.delete]: t(`${I18N_POST_ACTION}.delete`),
    [OfferActions.promote]: t(`${I18N_POST_ACTION}.promote`),
    [OfferActions.republish]: t(`${I18N_POST_ACTION}.republish`),
  }

  return actionToTranslation[action]
}

export const getLocalizedObject = (options: string[], i18nPrefix: string) => (
  options.reduce((obj, opt) => ({ ...obj, [opt]: `${i18nPrefix}.${opt}` }), {})
)

export const getCountryFlagFromLanguage = (language: string) => {
  if (language === 'en') {
    return countryCodeEmoji('gb')
  }

  return countryCodeEmoji(language)
}
