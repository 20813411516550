import classNames from 'classnames'
import { t } from 'i18next'
import React, { memo } from 'react'
import { Field } from 'react-final-form'
import { toast } from 'react-toastify'

import SvgIcon from 'src/components/svgIcon/SvgIcon'
import Toggle from 'src/components/toggle/Toggle'
import { I18N_POST_AUTO_RENEWAL } from 'src/constants/i18n.constants'
import { useInputBlurOnValueUpdate } from 'src/hooks/form.hooks'

import FieldWrapper, { IFieldWrapperProps } from '../FieldWrapper'

import styles from './formAutoRenewalToggle.module.scss'

interface IFormAutoRenewalToggleProps {
  fieldWrapperProps?: IFieldWrapperProps;
  name: string;
  className?: string;
  label: string;
  supportingText: string;
}

enum RenewalIcon {
  progress = 'clock-progress',
  crossed = 'clock-crossed',
}

const FormAutoRenewalToggle = ({
  fieldWrapperProps,
  name,
  className,
  label,
  supportingText,
}: IFormAutoRenewalToggleProps) => {
  useInputBlurOnValueUpdate(name)

  return (
    <Field name={name} type="checkbox">
      {({ input: { onChange, checked } }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.checked) {
            toast.success(
              t(`${I18N_POST_AUTO_RENEWAL}.on.toast`),
              { icon: <SvgIcon name={RenewalIcon.progress} size={16} /> },
            )
          } else {
            toast.success(
              t(`${I18N_POST_AUTO_RENEWAL}.off.toast`),
              { icon: <SvgIcon name={RenewalIcon.crossed} size={16} /> },
            )
          }

          return onChange(event)
        }

        return (
          <FieldWrapper {...fieldWrapperProps} name={name} className={classNames(className)}>
            <Toggle
              label={label}
              name={name}
              checked={!!checked}
              onChange={handleChange}
              supportingText={supportingText}
              iconName={checked ? RenewalIcon.progress : RenewalIcon.crossed}
              labelClassName={styles.label}
            />
          </FieldWrapper>
        )
      }}
    </Field>
  )
}

export default memo(FormAutoRenewalToggle)
