export enum PushNotificationsEnum {
  hasNewMessagesPush = 'hasNewMessagesPush',
}

export enum EmailNotificationsEnum {
  hasNewMessagesEmail = 'hasNewMessagesEmail',
  hasMessagesSummaryEmail = 'hasMessagesSummaryEmail',
  hasExpiredOffersEmail = 'hasExpiredOffersEmail',
}

export type TPushNotifications = {
  [key in PushNotificationsEnum]: boolean;
}
export type TEmailNotifications = {
  [key in EmailNotificationsEnum]: boolean;
}

export type TUserPreferences = TPushNotifications & TEmailNotifications
