import classNames from 'classnames'
import React, { memo } from 'react'

import ImageOrNoPhoto from 'src/components/imageOrNoPhoto/ImageOrNoPhoto'
import { checkIsInactiveView } from 'src/utils/post.utils'

import { IPropsWithOffer } from '../../../models/post.model'
import { getThumbBigUrl } from '../../../utils/images.utils'

import styles from '../postList.module.scss'

interface PostImageProps extends IPropsWithOffer {
}

const PostImage: React.FC<PostImageProps> = ({ offer }) => {
  const imageSrc = getThumbBigUrl(offer.photos[0], offer.publicId)
  const inactiveView = checkIsInactiveView(offer)

  return (
    <div className={classNames(styles.photo, { semitransparent: inactiveView })}>
      <ImageOrNoPhoto src={imageSrc} alt={offer.title} />
    </div>
  )
}

export default memo(PostImage)
