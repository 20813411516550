import { TFunction } from 'i18next'
import { FieldMetaState } from 'react-final-form'
import { toast } from 'react-toastify'

import { JOI_REQUIRED } from 'src/constants/error.constants'
import { FORM_FIELD_INPUT_VALUE_POSTFIX } from 'src/constants/form.constants'
import { ONE_MB } from 'src/constants/general.constants'
import { I18N_FORM, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { HelpFormErrors, IHelpFormValues } from 'src/models/form.model'
import { IPhoneNumber } from 'src/models/verifyPhone.model'
import { COUNTRY_TO_PHONE_CODE } from 'src/yome-categories-fields-config/models/fields.model'

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const getFieldError = <T,>({
  error,
  submitError,
  dirty,
  modified,
  dirtySinceLastSubmit,
  modifiedSinceLastSubmit,
}: FieldMetaState<T>): string | undefined => {
  const dirtyAndModified = dirty && modified
  const dirtyAndModifiedSinceLastSubmit = dirtySinceLastSubmit && modifiedSinceLastSubmit
  const errorValidation = dirtyAndModified ? error : undefined
  const errorSubmit = !dirtyAndModifiedSinceLastSubmit ? submitError : undefined

  return errorValidation || errorSubmit
}

export const validateHelpForm = (values: IHelpFormValues, withEmailVerifyCode: boolean = false): HelpFormErrors => {
  const errors: HelpFormErrors = {}

  if (!values.topic) {
    errors.topic = JOI_REQUIRED
  } else if (!values.name) {
    errors.name = JOI_REQUIRED
  } else if (!values.email) {
    errors.email = JOI_REQUIRED
  } else if (!values.emailVerifyCode && withEmailVerifyCode) {
    errors.emailVerifyCode = JOI_REQUIRED
  }

  return errors
}

const checkFilesSize = (uploadedFiles: File[], maxSize: number) => (
  uploadedFiles.some((file) => file.size > maxSize * ONE_MB)
)

export const assertFilesSizeLimit = (uploadedFiles: File[], maxSize: number, t: TFunction) => {
  const isSizeLimitExeeded = checkFilesSize(uploadedFiles, maxSize)

  if (isSizeLimitExeeded) {
    toast.error(t(`${I18N_GLOBAL}.SIZE_EXCEEDED`))
  }

  return isSizeLimitExeeded
}

export const assertImageType = (uploadedFiles: File[], t: TFunction) => {
  const isImages = uploadedFiles.every((file) => file.type.includes('image/'))

  if (!isImages) {
    toast.error(t(`${I18N_GLOBAL}.INVALID_ATTACHMENT_EXTENSION`))
  }

  return isImages
}

/**
 * In some cases, e.g. for a price field we should store two its values - real number and a formatted one
 * @param name Name of a real form field
 * @returns "fake" name for a field
 */
export const getInputValueFieldName = (name: string) => `${name}${FORM_FIELD_INPUT_VALUE_POSTFIX}`

export const getInputOverflowError = (count: number, t: TFunction, maxSymbols?: number) => {
  if (!maxSymbols) {
    return undefined
  }

  if (count > maxSymbols) {
    return t(`${I18N_FORM}.textfield.error.maxLimit`, { maxLimit: maxSymbols })
  }

  return undefined
}

export const getPhoneCountryCodeFieldName = (name: string) => `${name}CountryCode`

export const getPhoneNumberFieldName = (name: string) => `${name}Body`

export const getFullPhoneNumber = (phoneNumber: IPhoneNumber) => (
  `${COUNTRY_TO_PHONE_CODE[phoneNumber.phoneCountryCode]}${phoneNumber.phoneBody}`
)
