import classNames from 'classnames'
import React from 'react'

import LangSelect from 'src/components/langSelect/LangSelect'
import SWRErrorNotification from 'src/components/swr/SWRErrorNotification'
import { IDSection } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useCurrentUser } from 'src/services/currentUser.service'
import { useCheckIsPage } from 'src/utils/hooks.utils'
import SWRkeys from 'src/constants/swr.constants'
import HeaderButtons from './HeaderButtons'
import HeaderIconLinks from './headerIconLinks/HeaderIconLinks'
import HeaderLogo from './HeaderLogo'
import HeaderProfile from './HeaderProfile'
import MobileTabBar from './MobileTabBar'

import styles from './header.module.scss'

const getDefaultTheme = () => {
  const localStorageTheme = localStorage.getItem('default-theme')

  return localStorageTheme || 'light'
}

const Header: React.FC = () => {
  const { data: currentUser } = useCurrentUser()

  // eslint-disable-next-line
  const [theme, setTheme] = React.useState(getDefaultTheme())

  // const handleThemeChange = () => {
  //   setTheme(theme === 'light' ? 'dark' : 'light')
  // }

  const { isDesktop } = useViewport()

  React.useEffect(() => {
    document.body.setAttribute('data-theme', theme)
    localStorage.setItem('default-theme', theme)
  }, [theme])

  const { isMainPage } = useCheckIsPage()

  if (isDesktop) {
    return (
      <header className={classNames(styles.main, { [styles.sticky]: isMainPage })} id={IDSection.header}>
        <div className={styles.inner}>
          <HeaderLogo />
          <div className={styles.item}>
            <HeaderProfile />
            <HeaderIconLinks />
            <HeaderButtons isUser={Boolean(currentUser)} />
            <div className={styles.langSelectWrapper}>
              <LangSelect/>
            </div>
          </div>
        </div>
      </header>
    )
  }

  // mobile
  return (
    <header className={classNames(styles.main, styles.mobileHeader)} id={IDSection.header}>
      <div className={styles.inner}>
        <MobileTabBar/>
      </div>
    </header>
  )
}

const HeaderBoundary = () => (
  <SWRErrorNotification errorMessage={'USER_ERROR'} swrKey={SWRkeys.currentUser}>
    <Header />
  </SWRErrorNotification>
)

export default React.memo(HeaderBoundary)
