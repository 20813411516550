import { memo } from 'react'
import { Trans } from 'react-i18next'

import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_LEGAL_MENU, I18N_LEGAL_TERMS } from 'src/constants/i18n.constants'
import YomeURL, { LegalLink } from 'src/constants/navigate.constants'

import LegalPageTitle from '../LegalPageTitle'
import LegalSectionTitle from '../LegalSectionTitle'

import styles from '../legalLayout.module.scss'

const TermsAndConditionsIntro = () => (
  <section>
    <Paragraph text={`${I18N_LEGAL_TERMS}.intro.one`} />
    <Paragraph text={`${I18N_LEGAL_TERMS}.intro.two`} />
  </section>
)

const TermsAndConditionsTerms = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.terms`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.terms.one`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.terms.two`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.terms.three`} />
    </section>
  </>
)

const TermsAndConditionsGoals = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.goals`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.goals.one`} />
    </section>
  </>
)

const TermsAndConditionsRights = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.rights`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.rights.one`} />
      <Paragraph className={styles.label} text={`${I18N_LEGAL_TERMS}.rights.userRights.label`} />
      <ul>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userRights.one`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userRights.two`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userRights.three`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userRights.four`} />
        </li>
      </ul>
      <Paragraph className={styles.label} text={`${I18N_LEGAL_TERMS}.rights.adminRights.label`} />
      <ul>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.adminRights.one`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.adminRights.two`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.adminRights.three`} />
        </li>
      </ul>
      <Paragraph className={styles.label} text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.label`} />
      <ul>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.one`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.two`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.three`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.four`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.five`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.six`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.seven`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.eight`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.userUndertakes.nine`} />
        </li>
      </ul>
      <Paragraph className={styles.label} text={`${I18N_LEGAL_TERMS}.rights.adminUndertakes.label`} />
      <ul>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.adminUndertakes.one`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.rights.adminUndertakes.two`} />
        </li>
      </ul>
    </section>
  </>
)

const TermsAndConditionsUserResp = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.userResponsibilities`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.userResponsibilities.one`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.userResponsibilities.two`} />
    </section>
  </>
)

const TermsAndConditionsCompanyResp = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.companyResponsibilities`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.companyResponsibilities.one`} />
      <ul>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.companyResponsibilities.disclaims.one`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.companyResponsibilities.disclaims.two`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.companyResponsibilities.disclaims.three`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.companyResponsibilities.disclaims.four`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.companyResponsibilities.disclaims.five`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.companyResponsibilities.disclaims.six`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.companyResponsibilities.disclaims.seven`} />
        </li>
      </ul>
    </section>
  </>
)

const TermsAndConditionsPersonalData = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.personalData`}
    />
    <section>
      <p>
        <Trans i18nKey={`${I18N_LEGAL_TERMS}.personalData.one`}>
          The Company processes Users' personal data in accordance with the
          <a href={YomeURL.privacy} target='_blank' rel="noopener noreferrer">
            Personal Data Processing Policy.
          </a>
        </Trans>
      </p>
      <p>
        <Trans>
          {`${I18N_LEGAL_TERMS}.personalData.two`}
        </Trans>
        {' '}
        <a href={YomeURL.cookies} target='_blank' rel="noopener noreferrer">
          <Trans>
            {`${I18N_LEGAL_TERMS}.personalData.three`}
          </Trans>
        </a>
      </p>
    </section>
  </>
)

const TermsAndConditionsProperties = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.properties`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.properties.one`} />
      <p>
        <Trans>
          {`${I18N_LEGAL_TERMS}.properties.two.fonts`}
        </Trans>
        {' '}
        <a
          href='https://fonts.google.com/specimen/Inter?query=inter'
          target='_blank'
          rel="noopener noreferrer"
        >
          <Trans>
            {`${I18N_LEGAL_TERMS}.properties.two.inter`}
          </Trans>
        </a>
        {' '}
        <a
          href='https://www.fontshare.com/fonts/general-sans'
          target='_blank'
          rel="noopener noreferrer"
        >
          <Trans>
            {`${I18N_LEGAL_TERMS}.properties.two.fontshare`}
          </Trans>
        </a>
        .
      </p>

      <p>
        <Trans>
          {`${I18N_LEGAL_TERMS}.properties.three.icons`}
        </Trans>
        {' '}
        <a
          href='https://www.untitledui.com/icons'
          target='_blank'
          rel="noopener noreferrer"
        >
          <Trans>
            {`${I18N_LEGAL_TERMS}.properties.three.figma`}
          </Trans>
        </a>
      </p>
      <p>
        <Trans>
          {`${I18N_LEGAL_TERMS}.properties.four.illustrations`}
        </Trans>
        {' '}
        <a
          href='https://icons8.com/illustrations/t/block/style--3d-casual-life'
          target='_blank'
          rel="noopener noreferrer"
        >
          <Trans>
            {`${I18N_LEGAL_TERMS}.properties.four.block`}
          </Trans>
        </a>
      </p>
      <Paragraph text={`${I18N_LEGAL_TERMS}.properties.five`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.properties.six`} />
      <p>
        <Trans>
          {`${I18N_LEGAL_TERMS}.properties.seven`}
        </Trans>
        {' '}
        <a href='mailto:info@yome.network' target='_blank' rel="noreferrer">
          info@yome.network
        </a>
      </p>
      <Paragraph text={`${I18N_LEGAL_TERMS}.properties.eight`} />
    </section>
  </>
)

const TermsAndConditionsResponsibilities = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.responsibilities`}
    />
    <section>
      <ul>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.responsibilities.one`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.responsibilities.two`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.responsibilities.three`} />
        </li>
      </ul>
    </section>
  </>
)

const TermsAndConditionsAccuracy = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.accuracy`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.accuracy.one`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.accuracy.two`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.accuracy.three`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.accuracy.four`} />
    </section>
  </>
)

const TermsAndConditionsChange = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.change`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.change.one`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.change.two`} />
    </section>
  </>
)

const TermsAndConditionsAvailability = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.availability`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.availability.one`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.availability.two`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.availability.three`} />
      <ul>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.availability.noGuarantees.one`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.availability.noGuarantees.two`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.availability.noGuarantees.three`} />
        </li>
        <li>
          <Paragraph text={`${I18N_LEGAL_TERMS}.availability.noGuarantees.four`} />
        </li>
      </ul>
    </section>
  </>
)

const TermsAndConditionsPromotional = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.promotional`}
    />
    <section>
      <p>
        <Trans>
          {`${I18N_LEGAL_TERMS}.promotional.one`}
        </Trans>
        {' '}
        <a href='mailto:info@yome.network' target='_blank' rel="noreferrer">
          info@yome.network
        </a>
      </p>
    </section>
  </>
)

const TermsAndConditionsTermsAndAgreement = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.termsAndAgreement`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.termsAndAgreement.one`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.termsAndAgreement.two`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.termsAndAgreement.three`} />
      <Paragraph text={`${I18N_LEGAL_TERMS}.termsAndAgreement.four`} />
    </section>
  </>
)

const TermsAndConditionsApplicableLaw = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_TERMS}.h2.applicableLaw`}
    />
    <section>
      <Paragraph text={`${I18N_LEGAL_TERMS}.applicableLaw.one`} />
    </section>
  </>
)

const TermsAndConditionsContent = () => (
  <div className={styles.content}>
    <TermsAndConditionsIntro />
    <TermsAndConditionsTerms />
    <TermsAndConditionsGoals />
    <TermsAndConditionsRights />
    <TermsAndConditionsUserResp />
    <TermsAndConditionsCompanyResp />
    <TermsAndConditionsPersonalData />
    <TermsAndConditionsProperties />
    <TermsAndConditionsResponsibilities />
    <TermsAndConditionsAccuracy />
    <TermsAndConditionsChange />
    <TermsAndConditionsAvailability />
    <TermsAndConditionsPromotional />
    <TermsAndConditionsTermsAndAgreement />
    <TermsAndConditionsApplicableLaw />
  </div>
)

const TermsAndConditionsRoute = () => (
  <article>
    <LegalPageTitle
      label={`${I18N_LEGAL_MENU}.${LegalLink.terms}`}
    />
    <TermsAndConditionsContent />
  </article>
)

export default memo(TermsAndConditionsRoute)
