import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ALL_CATEGORIES_FLAT } from 'src/models/category.model'
import { ALL_CATEGORIES } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import { ICategory } from 'src/yome-categories-fields-config/models/category.model'

const useTranslationMatch = () => {
  const { t } = useTranslation()

  return useCallback((title: string, query: string) => (
    t(`categories.${title}`).toLowerCase().includes(query.toLowerCase())
  ), [t])
}

export const useCategoryMatch = () => {
  const checkTranslationMatch = useTranslationMatch()

  return useCallback((query: string, categoryToCheck: ICategory) => {
    if (!query || checkTranslationMatch(categoryToCheck.title, query)) {
      return true
    }

    return categoryToCheck.items?.some((subCategory) => checkTranslationMatch(subCategory.title, query)) ?? false
  }, [checkTranslationMatch])
}

export const useCategorySearchSuggestions = () => {
  const checkTranslationMatch = useTranslationMatch()

  return useCallback((query: string, suggestionItemsNumber?: number) => {
    const filteredSuggestion = ALL_CATEGORIES_FLAT.filter((category) => (checkTranslationMatch(category, query)))

    if (!suggestionItemsNumber) {
      return filteredSuggestion
    }

    return filteredSuggestion.slice(0, suggestionItemsNumber)
  },
  [checkTranslationMatch])
}

const getCategoriesByPath = (path: string[]) => path
  .reduce(
    (reduceResult: ICategory[], current): ICategory[] => {
      const foundCategory = reduceResult.find((item) => item.title === current)

      if (!foundCategory?.items) {
        return []
      }

      return foundCategory.items
    },
    ALL_CATEGORIES,
  )

/*
Function returns data for category-level of interest (each level is set of siblings)
Input:
selectedPath - full selected path (exmpl: ['ANY', 'CLOTHES', 'WOMEN_SHOES', 'BOOTIES'])
level - category-level of interest (exmpl: 0 for all main categories, 1 for children of single main category, etc)
  for given example, level 1 means level with all children of 'CLOTHES'

Output:
currentLevelPath - path up to current level. Exmpl: for level 1 (children of 'CLOTHES') path would be ['ANY', 'CLOTHES']
  if user chooses something else from this level, the choise would be added to this path ['ANY', 'CLOTHES', 'HANDMADE']
parent - parent category for this level, so by definition it is the last element of current path ('CLOTHES' for level 1)
categories - list of categories (ICategory[]) for current level
*/
export const getLevelCategories = (level: number, selectedPath: string[]) => {
  const currentLevelPath = selectedPath.slice(0, level + 1)
  const parent = currentLevelPath[currentLevelPath.length - 1]
  // slice(1) to remove 'ANY', so path starts from main category (['CLOTHES'] for level 1)
  const categories = getCategoriesByPath(currentLevelPath.slice(1))

  return { currentLevelPath, parent, categories }
}
