import { TFunction } from 'i18next'
import { PropsWithChildren } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { FormSelectDropdownButton } from 'src/components/form/FormSelect/FormSelectDropdown'
import { I18N_CATEGORIES, I18N_GLOBAL, I18N_POST_DATA, I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { IFilterValues, TSingleFilterKey, TSingleFilterValues } from 'src/models/search.model'
import { useToggle } from 'src/utils/hooks.utils'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import { FieldType, IFieldConfig, MainCategories } from 'src/yome-categories-fields-config/models/fields.model'

import FilterFieldTitle from './FilterFieldTitle'
import SingleFullsizeFilter from './SingleFullsizeFilter'

import styles from './filter.module.scss'

interface IFilterFieldProps {
  fieldConfig: IFieldConfig;
  filtersCategory: MainCategories;
}

const getDisplayedName = (name: string, value: TSingleFilterValues, fieldType: FieldType, t: TFunction) => {
  if (fieldType === FieldType.category) {
    return t(`${I18N_CATEGORIES}.${value || ANY_CATEGORY}`)
  }

  if (!value) {
    return t(`${I18N_GLOBAL}.notSpecified`)
  }

  if (Array.isArray(value)) {
    return value.map((optionName: string) => t(`${I18N_POST_DATA}.${name}.${optionName}`)).join(', ')
  }

  return t(`${I18N_POST_DATA}.${name}.${value}`)
}

const FilterField = ({ fieldConfig, children, filtersCategory }: PropsWithChildren<IFilterFieldProps>) => {
  const { isDesktop } = useViewport()
  const { values } = useFormState()
  const { change } = useForm()
  const { t } = useTranslation()

  const { name, tooltip, type, filter } = fieldConfig
  const curValue = getDisplayedName(name, values[name], type, t)

  // for mobile use full size filter
  const openFullSize = !isDesktop && filter?.isFullsizeMobile

  const [isFieldOpen, toggleField] = useToggle()

  return (
    <div className={styles.field}>
      <FilterFieldTitle
        title={`${I18N_SEARCH_FILTER}.${name}.title`}
        tooltip={tooltip}
      />
      {!openFullSize ? children : (
        <>
          <FormSelectDropdownButton
            name={`mobile-${name}`}
            currentValue={curValue}
            onClick={toggleField}
          />
          {isFieldOpen && (
            <SingleFullsizeFilter
              name={name as TSingleFilterKey}
              initialValues={values as IFilterValues}
              children={children}
              filtersCategory={filtersCategory}
              change={change}
              closeFilter={toggleField}
            />
          )}
        </>
      )}
    </div>
  )
}

export default FilterField
