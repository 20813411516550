import { I18N_MESSENGER_REPORT_MODAL, I18N_REPORT_MODAL } from 'src/constants/i18n.constants'

export interface IReportFormProps {
  handleClose: () => void;
}

interface IModalPropsReportUser {
  chatId: string;
  reportedUserId: string;
}

export interface IReportUserModalProps extends IReportFormProps {
  modalProps: IModalPropsReportUser;
}

interface IModalPropsReportOffer {
  postId: string;
}

export interface IReportOfferModal extends IReportFormProps {
  modalProps: IModalPropsReportOffer;
}

export interface IReportOfferFormValues {
  reason: string;
  comment: string;
}

const REPORT_REASONS = [
  'OFFENSIVE_CONTENT',
  'SPAM_OR_MISLEADING',
  'INAPPROPRIATE_BEHAVIOR',
  'HATE_SPEECH',
  'THREATS',
  'PRIVACY_VIOLATION',
  'SCAM_OR_FRAUD',
  'RESTRICTED_ITEMS',
  'HARASSMENT',
  'OTHER',
]

export const REPORT_USER_REASON_MAP = REPORT_REASONS.reduce((acc, reason) => ({
  ...acc,
  [reason]: `${I18N_MESSENGER_REPORT_MODAL}.${reason}`,
}), {})

export interface IReportUserFormValues {
  reason: string;
  comment?: string;
  isAllowedToRead?: boolean;
}

export const REPORT_OFFER_REASON_MAP = {
  itemNonAvailable: `${I18N_REPORT_MODAL}.itemNonAvailable`,
  wrongPrice: `${I18N_REPORT_MODAL}.wrongPrice`,
  restrictedItem: `${I18N_REPORT_MODAL}.restrictedItem`,
  violateRules: `${I18N_REPORT_MODAL}.violateRules`,
  wrongItemInfo: `${I18N_REPORT_MODAL}.wrongItemInfo`,
  wrongAdress: `${I18N_REPORT_MODAL}.wrongAdress`,
  scam: `${I18N_REPORT_MODAL}.scam`,
}
