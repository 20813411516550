import React from 'react'
import { Link } from 'react-router-dom'

import UserButton from 'src/components/button/userButton/UserButton'
import DropdownList from 'src/components/dropdown/DropdownSectionList'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_PROFILE_ACTION } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { PROFILE_MENU_SECTIONS_WITH_NAMES } from 'src/models/profile.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { handleLogout } from 'src/utils/auth.utils'
import { useToggle } from 'src/utils/hooks.utils'
import Loader from '../loader/Loader'

import styles from './header.module.scss'

const HeaderProfile: React.FC = () => {
  const { data: currentUser, isLoading } = useCurrentUser()
  const [isDropdownOpen, toggleDropdownOpen] = useToggle()

  PROFILE_MENU_SECTIONS_WITH_NAMES.logout = {
    logout: {
      name: `${I18N_PROFILE_ACTION}.logout`,
      icon: 'logout',
      onClick: handleLogout,
    },
  }

  if (isLoading) {
    return <Loader size={28} />
  }

  return (
    <div
      className={styles.profileWrapper}
      onMouseEnter={toggleDropdownOpen}
      onMouseLeave={toggleDropdownOpen}
    >
      <Link
        id={ID.headerLinkToProfile}
        to={YomeURL.offers}
        className={styles.profileWrap}
      >
        <div className={styles.profile}>
          {currentUser && (
            <>
              <UserButton currentUser={currentUser} />
              <div className={styles.chevron}>
                <SvgIcon name='chevron-down' size={16} currentColor='stroke' />
              </div>
            </>
          )}
        </div>
      </Link>
      {isDropdownOpen && (
        <div className={styles.profileNav} onClick={toggleDropdownOpen}>
          <DropdownList
            id={ID.headerLinkToOption}
            sections={PROFILE_MENU_SECTIONS_WITH_NAMES}
          />
        </div>
      )}
    </div>

  )
}

export default React.memo(HeaderProfile)
