import classNames from 'classnames'

import { IdStructure } from 'src/constants/id.constants'
import PostListContext from 'src/contexts/postList.context'
import { CardInfo, PostListType, SinglePost } from 'src/models/post.model'

import OfferCard from './OfferCard'
import OfferCardHorizontal from './OfferCardHorizontal'

import styles from './postList.module.scss'

type PostsListProps = {
  idPostPrefix: IdStructure;
  posts: SinglePost[];
  isHorizontalView?: boolean;
  postListKey: string;
  hasAllFavorite?: boolean;
  cardInfo?: CardInfo;
  listType?: PostListType;
}

const PostList = ({
  idPostPrefix,
  posts,
  postListKey,
  isHorizontalView,
  hasAllFavorite = false,
  cardInfo = CardInfo.location,
  listType,
}: PostsListProps) => (
  <div className={styles.main}>
    <div className={classNames(styles.items, { [styles.itemsHorizontal]: isHorizontalView })}>
      {posts && posts.map((offer) => {
        const PostComponent = isHorizontalView ? OfferCardHorizontal : OfferCard

        return (
          <PostListContext.Provider
            value={{ idPostPrefix, postListKey, hasAllFavorite, cardInfo, listType }}
            key={offer.slug}
          >
            <PostComponent
              offer={offer}
            />
          </PostListContext.Provider>
        )
      })}
    </div>
  </div>

)

export default PostList
