import React, { memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_AUTH } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { YomeHash } from 'src/constants/navigate.constants'
import { useAllowResendEmail, useGetTokenFromURL } from 'src/hooks/auth.hooks'
import { useHashUrl } from 'src/hooks/router.hooks'
import { EmailUrlAction } from 'src/models/url.model'
import { resendVerifyEmail, useVerify } from 'src/services/auth.service'

import InfoModal from '../infoModal/InfoModal'
import SmthWentWrongModal from '../smthWentWrongModal/SmthWentWrongModal'

import styles from './auth.module.scss'

interface IVerifyEmailResultModal {
  handleClose: () => void;
}

const VerifyEmailResultModal = ({ handleClose }: IVerifyEmailResultModal) => {
  const token = useGetTokenFromURL(EmailUrlAction.verify)
  const { t } = useTranslation()

  const { data, error, isLoading } = useVerify(token)

  const successToast = t(`${I18N_AUTH}.newVerifyLink`)
  const failedToast = t(`${I18N_AUTH}.waitResend`)
  const resendEmail = useCallback(() => resendVerifyEmail(data?.email || ''), [data?.email])
  const onResendClick = useAllowResendEmail({ resendEmail, successToast, failedToast })

  useEffect(() => {
    if (!isLoading && !data && !error) {
      handleClose()
    }
  }, [data, handleClose, isLoading, error])

  const authUrl = useHashUrl(YomeHash.auth)

  if (isLoading) {
    // TODO: add a loading animation
    return null
  }

  // verify token present and expired case (verify() returns email)
  if (data?.email) {
    const mainButtonProps = {
      onClick: onResendClick,
      label: `${I18N_AUTH}.retryVerification.buttonLabel`,
      id: ID.signupModalButtonResend,
    }

    return (
      <SmthWentWrongModal
        title={`${I18N_AUTH}.verificationFailed.title`}
        text={`${I18N_AUTH}.linkExpired.text`}
        mainButtonProps={mainButtonProps}
        secondButtonProps={{ id: ID.signupModalLinkToHelp }}
        handleClose={handleClose}
      />
    )
  }

  // other errors case
  if (error) {
    const mainButtonProps = {
      linkTo: authUrl,
      label: `${I18N_AUTH}.goToLogin.buttonLabel`,
      id: ID.signupModalButtonLogin,
    }

    return (
      <SmthWentWrongModal
        title={`${I18N_AUTH}.verificationFailed.title`}
        text={`${I18N_AUTH}.verificationFailed.text`}
        mainButtonProps={mainButtonProps}
        secondButtonProps={{ id: ID.signupModalLinkToHelp }}
        handleClose={handleClose}
      />
    )
  }

  // success case
  const footerButton = (
    <RegularButton
      id={ID.signupModalLinkToMainPage}
      buttonType={RegularButtonTypes.main}
      label={`${I18N_AUTH}.verificationSuccess.buttonLabel`}
      onClick={handleClose}
    />
  )

  return (
    <InfoModal
      title={`${I18N_AUTH}.verificationSuccess.title`}
      topIcon='hand-success'
      className={styles.authModalContainer}
      modalFooter={footerButton}
      handleClose={handleClose}
    >
      <p className='bodySizeL'>
        {t(`${I18N_AUTH}.verificationSuccess.text`)}
      </p>
    </InfoModal>
  )
}

export default memo(VerifyEmailResultModal)
