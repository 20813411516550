import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './form.module.scss'

type FormErrorProps = {
  error?: string;
  iconPosition?: 'left' | 'right';
  iconSize?: number;
  className?: string;
}

const FormError = React.memo(({ error, iconPosition = 'right', iconSize = 16, className }: FormErrorProps) => {
  const { t } = useTranslation()

  const errorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [error])

  if (!error) {
    return null
  }

  return (
    <span
      ref={errorRef}
      className={classNames(styles.fieldError, { [styles.positionLeft]: iconPosition === 'left' }, className)}
      title={error}
    >
      {t(error)}
      <SvgIcon name='alert' size={iconSize} />
    </span>
  )
})

export default FormError
