import classNames from 'classnames'
import { memo, useEffect } from 'react'
import { toast } from 'react-toastify'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import OrigianlContentSkeleton from 'src/components/skeletons/OriginalContentSkeleton'
import { I18N_POST } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useCollapseText } from 'src/hooks/form.hooks'
import { useGetOfferOriginalText } from 'src/services/post.service'
import { assertData } from 'src/utils/error.utils'

import styles from './postField.module.scss'

interface IDescriptionField {
  slug: string;
  toggleOriginalOpen: () => void;
}

const OriginalLangDescription = ({ slug, toggleOriginalOpen }: IDescriptionField) => {
  const { isFullyOpen, toggleFullOpen, descrRef, isTextExceeded, buttonLabel } = useCollapseText()

  const { data, isLoading, error } = useGetOfferOriginalText(slug)

  useEffect(() => {
    if (error) {
      toast.error(`${I18N_POST}.description.showOriginalError`)
      toggleOriginalOpen()
    }
  }, [error, toggleOriginalOpen])

  if (error) {
    return null
  }

  if (isLoading) {
    return <OrigianlContentSkeleton className={styles.skeletonContainer}/>
  }

  assertData(data)

  const { title, description } = data

  return (
    <div
      className={classNames(styles.originalTextContainer, isFullyOpen ? styles.isFullyOpen : styles.hideText)}
    >
      <Heading level='h4' className={classNames('desktop-h6 mobile-h6', styles.origTitle)}>
        {title}
      </Heading>
      <div ref={descrRef}>
        <Paragraph className='bodySizeM'>
          {description}
        </Paragraph>
      </div>
      {isTextExceeded && (
        <div className={classNames(styles.buttonWrapper, isFullyOpen && styles.iconRotate)}>
          <RegularButton
            id={ID.postPageToggleDetails}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
            onClick={toggleFullOpen}
            label={`${I18N_POST}.descriptionBtn${buttonLabel}`}
            rightIcon={{ name: 'chevron-down', size: 24 }}
          />
        </div>
      )}
    </div>
  )
}

export default memo(OriginalLangDescription)
