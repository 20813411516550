import { useContext } from 'react'

import { RegularButtonTypes } from 'src/components/button/RegularButton'
import SWRErrorNotification from 'src/components/swr/SWRErrorNotification'
import { I18N_OFFER_CARD_FREE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { DEFAULT_MAP_POSITION } from 'src/constants/map.constants'
import YomeURL from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import SearchLocationContext from 'src/contexts/searchLocation.context'
import { IStackBoundaryProps, IStackProps } from 'src/models/post.model'
import { getSearchFreeOffersParams, useGetSearchPostList } from 'src/services/search.service'

import CardStack from './CardStack'

const FreeCardStack = ({ stackEndpoint }: IStackProps) => {
  const { data, isLoading } = useGetSearchPostList(stackEndpoint)

  if (isLoading || !data) {
    return null
  }

  const { hasMore, fromCity, other } = data

  const moreButton = hasMore ? {
    id: ID.freeCardStackSearchFreeOffers,
    buttonType: RegularButtonTypes.transparentWithBorder,
    linkTo: `${YomeURL.search}?${getSearchFreeOffersParams()}`,
    label: `${I18N_OFFER_CARD_FREE}.button`,
  } : undefined

  return (
    <CardStack
      idOfferPrefix={ID.freeCardStackPrefixOffer}
      cardStackLabel={`${I18N_OFFER_CARD_FREE}.label`}
      offerList={[...fromCity, ...other]}
      offerListKey={stackEndpoint}
      moreButton={moreButton}
    />
  )
}

const FreeCardStackBoundary = ({ offerLimit }: IStackBoundaryProps) => {
  const { searchLocation } = useContext(SearchLocationContext)
  const position = searchLocation?.locationPosition || DEFAULT_MAP_POSITION
  const params = getSearchFreeOffersParams(position, offerLimit)
  const stackEndpoint = `${SWRkeys.search}?${params}`

  return (
    <SWRErrorNotification swrKey={stackEndpoint}>
      <FreeCardStack stackEndpoint={stackEndpoint}/>
    </SWRErrorNotification>
  )
}

export default FreeCardStackBoundary
