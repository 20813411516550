import classNames from 'classnames'
import { memo, useCallback, useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { getSubcategoryFieldName, MAX_CATEGORY_PATH_INDEXES_ARRAY } from 'src/utils/post.utils'
import { getCategoryObject, getCategorySiblings } from 'src/yome-categories-fields-config/utils/category.utils'

import FormSelectDropdown from './FormSelectDropdown'

import formStyles from '../form.module.scss'
import styles from './categorySelect.module.scss'

interface IFormCategorySelectProps {
  name: string;
}

const checkEmptySelectRequired = (path: string[]) => {
  const subcategoryObj = getCategoryObject(path)

  return Boolean(subcategoryObj?.items?.length)
}

const addEmptySelectIfRequired = (path: string[]): string[] => {
  if (checkEmptySelectRequired(path)) {
    return path.concat('')
  }

  return path
}

const CategoryFieldSet = ({ name }: IFormCategorySelectProps) => {
  const { change } = useForm()
  const { t } = useTranslation()
  const { values } = useFormState()
  const { isDesktop } = useViewport()

  const path = values[name]

  const onSubcategoryChange = useCallback((prevPath: string[]) => (value: string) => {
    change(name, [...prevPath, value])
  }, [change, name])

  useEffect(() => {
    MAX_CATEGORY_PATH_INDEXES_ARRAY.forEach((index) => {
      change(getSubcategoryFieldName(name, index), path[index])
    })
  }, [change, name, path])

  return (
    <div className={styles.categoryFormBlock}>
      {addEmptySelectIfRequired(path).map((subcategory: string, index: number) => {
        const prevPath = path.slice(0, index)
        const siblings = getCategorySiblings(prevPath)

        // TODO: get rid of translations here
        const subcatOptions = siblings.reduce((obj, opt) => ({ ...obj, [opt]: t(`${I18N_CATEGORIES}.${opt}`) }), {})
        const subcategoryFieldName = getSubcategoryFieldName(name, index)

        return (
          <div
            key={subcategoryFieldName}
            className={classNames(formStyles.field)}
          >
            <FormSelectDropdown
              name={subcategoryFieldName}
              options={subcatOptions}
              selectedValue={subcategory}
              onChange={onSubcategoryChange(prevPath)}
              isFullSize={!isDesktop}
            />
          </div>
        )
      })}
    </div>
  )
}

const FormCategorySelect = ({ name, ...other }: IFormCategorySelectProps) => (
  <Field name={name}>
    {function renderFormCategorySelect() {
      return <CategoryFieldSet {...other} name={name} />
    }}
  </Field>
)

export default memo(FormCategorySelect)
