import classNames from 'classnames'
import React from 'react'

import styles from './svgIcon.module.scss'

export type SvgColorAttr = 'fill' | 'stroke' | 'fill-stroke'
type SvgProps = React.SVGProps<SVGSVGElement>

export interface ISvgIconProps {
  name: string;
  currentColor?: SvgColorAttr;
  size?: number;
  className?: string;
}

const getSizeProps = (size?: number) => (!size ? {} : {
  width: size,
  height: size,
})

const SvgIcon = ({ name, currentColor, size, className }: ISvgIconProps) => {
  const [IconComponent, setIconComponent] = React
    .useState<React.FunctionComponent<SvgProps> | undefined>()

  const loadIcon = React.useCallback(async () => {
    const importedIcon = await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/icons/${name}.svg`)
    setIconComponent(importedIcon.default as React.FunctionComponent<SvgProps>)
  }, [name, setIconComponent])

  React.useEffect(() => {
    loadIcon()
  }, [name])

  if (!IconComponent) {
    return null
  }

  return (
    <div
      className={classNames(styles.main, className)}
      style={getSizeProps(size)}
    >
      <IconComponent
        className={classNames(currentColor && styles[currentColor])}
        {...getSizeProps(size)}
      />
    </div>
  )
}

export default React.memo(SvgIcon)
