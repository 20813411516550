import { SubmissionErrors } from 'final-form'

import ToggleSettingsLine from 'src/routes/profile/components/settings/SettingLine/ToggleSettingsLine'
import { I18N_PROFILE_SETTING_NOTIFICATIONS } from 'src/constants/i18n.constants'
import { TEmailNotifications, TUserPreferences, EmailNotificationsEnum } from 'src/models/userPreferences.model'

const emailToggleNames = Object.values(EmailNotificationsEnum)

interface INotificationsSettingsProps {
  initialValues: TEmailNotifications;
  sumbitNotificationsSettings: (values: Partial<TUserPreferences>) => Promise<SubmissionErrors>;
}

const EmailNotificationsSettings = ({ initialValues, sumbitNotificationsSettings }: INotificationsSettingsProps) => (
  <ToggleSettingsLine
    title={`${I18N_PROFILE_SETTING_NOTIFICATIONS}.email.title`}
    onSubmit={sumbitNotificationsSettings}
    toggleNames={emailToggleNames}
    initialValues={initialValues}
  />
)

export default EmailNotificationsSettings
