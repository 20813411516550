import classNames from 'classnames'

import { SAFETY_ITEMS } from 'src/constants/staticPages.constants'
import { useViewport } from 'src/contexts/viewportContext'
import Feature from './Feature'

import styles from '../../safety.module.scss'

const SafetyFeatures = () => {
  const { isDesktop } = useViewport()

  return (
    <div className={classNames(styles.list, { [styles.listDesktop]: isDesktop })}>
      <ul className={styles.column}>
        {SAFETY_ITEMS.slice(0, 4).map((item) => (
          <Feature item={item} key={item}/>
        ))}
      </ul>
      <ul className={styles.column}>
        {SAFETY_ITEMS.slice(4).map((item) => (
          <Feature item={item} key={item}/>
        ))}
      </ul>
    </div>
  )
}

export default SafetyFeatures
