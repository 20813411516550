import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import FieldWrapper, { IFieldWrapperProps } from './FieldWrapper'

import styles from './form.module.scss'

export interface IFormEmailProps {
  fieldWrapperProps?: IFieldWrapperProps;
  id: string;
  name: string;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  autocomplete?: string;
}

const FormEmail = React.memo(({
  fieldWrapperProps,
  id,
  name,
  className,
  placeholder = 'Email',
  autoFocus,
  autocomplete,
}: IFormEmailProps) => {
  const { t } = useTranslation()

  return (
    <Field name="email" type="email">
      {function renderFormEmail({ input }) {
        return (
          <FieldWrapper {...fieldWrapperProps} name={name}>
            <input
              {...input}
              id={id}
              className={className || styles.fieldInput}
              type="email"
              placeholder={t(placeholder)}
              autoFocus={autoFocus}
              autoComplete={autocomplete}
            />
          </FieldWrapper>
        )
      }}
    </Field>
  )
})

export default FormEmail
