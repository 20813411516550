export enum UserBadgesEnum {
  phoneVerified = 'phoneVerified',
  documentsVerified = 'documentsVerified',
  purchasesNumber = 'purchasesNumber',
  salesNumber = 'salesNumber',
  ecoContribution = 'ecoContribution',
}

export type BadgeConfig = {
  [config in UserBadgesEnum]: ISingleBadgeConfig;
}

export interface ISingleBadgeConfig {
  icon: string;
}

export interface IUserBadgeInfo {
  badgeName: UserBadgesEnum;
  badgeMetric?: number;
}

export const BadgeInfo: BadgeConfig = {
  [UserBadgesEnum.phoneVerified]: {
    icon: 'check-verified',
  },
  [UserBadgesEnum.ecoContribution]: {
    icon: '',
  },
  [UserBadgesEnum.documentsVerified]: {
    icon: 'check-verified',
  },
  [UserBadgesEnum.purchasesNumber]: {
    icon: '',
  },
  [UserBadgesEnum.salesNumber]: {
    icon: '',
  },
}
