import { useTranslation } from 'react-i18next'

import PostField from './PostField'

interface ICategoryField {
  categoryPath: string[];
  field: string;
}

const CategoryField: React.FC<ICategoryField> = ({ categoryPath, field }) => {
  const { t } = useTranslation()
  const subcategoryFieldDataVal = categoryPath.slice(1).map((subcat) => t(`categories.${subcat}`)).join(', ')

  return (
    <>
      <PostField
        fieldValue={t(`categories.${categoryPath[0]}`)}
        fieldName={field}
      />
      <PostField
        fieldValue={subcategoryFieldDataVal}
        fieldName={'subcategory'}
      />
    </>
  )
}

export default CategoryField
