import classNames from 'classnames'
import { memo, useContext } from 'react'

import CardHeading from 'src/components/cardHeading/cardHeading'
import PostListContext from 'src/contexts/postList.context'
import NumberOfLinesEnum from 'src/models/cardHeading.model'

import { ID } from '../../../../constants/id.constants'
import { IPropsWithOffer, PostListType } from '../../../../models/post.model'
import { checkIsInactiveView } from '../../../../utils/post.utils'
import OfferImages from '../OfferImages/OfferImages'
import OfferInfo from '../OfferInfo/OfferInfo'

import styles from './offerContent.module.scss'

const OfferContent = ({ offer }: IPropsWithOffer) => {
  const { listType } = useContext(PostListContext)
  const isInactiveView = checkIsInactiveView(offer)
  const isDisabled = isInactiveView && listType !== PostListType.inactive

  return (
    <div className={styles.main}>
      <CardHeading
        offer={offer}
        id={ID.postPagePostTitle}
        numberOfLines={NumberOfLinesEnum.ALL}
        className={classNames(styles.title, 'desktop-h4')}
        isStaticText
        isDisabled={isDisabled}
      />
      <div className={styles.content}>
        <OfferImages offer={offer}/>
        {!isInactiveView && <OfferInfo offer={offer} />}
      </div>
    </div>
  )
}

export default memo(OfferContent)
