import React, { memo } from 'react'

import { ID } from '../../constants/id.constants'
import FilterSinglePriceField, { MAX_MODE, MIN_MODE } from './FilterSinglePriceField'

import styles from './filter.module.scss'

interface IFilterPriceFieldProps {
  name: string;
}

const FilterPriceField: React.FC<IFilterPriceFieldProps> = ({ name }: IFilterPriceFieldProps) => (
  <div className={styles.priceField}>
    <FilterSinglePriceField
      id={ID.searchFilterInputMinPrice}
      name={name}
      mode={MIN_MODE}
    />
    <FilterSinglePriceField
      id={ID.searchFilterInputMaxPrice}
      name={name}
      mode={MAX_MODE}
    />
  </div>
)

export default memo(FilterPriceField)
