import { memo, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import MenuGroup from 'src/components/menu/MenuGroup'
import MobileTopBar from 'src/components/mobileTopBar/MobileTopBar'
import ScrollUp from 'src/components/scrollUp/ScrollUp'
import { I18N_LEGAL, I18N_LEGAL_MENU } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { LEGAL_LINKS, LegalLink } from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { TDropdownSection } from 'src/models/dropdown.model'
import { usePageTitle } from 'src/utils/hooks.utils'
import { getStaticPageMetaTitle } from 'src/utils/meta.utils'

import styles from './legalLayout.module.scss'

const SCROLL_POSITION_AFTER_MENU = 250

const LegalLayout = () => {
  const { pathname } = useLocation()
  const { isDesktop } = useViewport()
  const [outletHeight, setOutletHeight] = useState(0)
  const contentRef = useRef<HTMLElement>(null)
  usePageTitle(getStaticPageMetaTitle(pathname))

  const sections = Object.values(LegalLink).reduce((reduceResult, item) => {
    const actionItem = {
      [`${I18N_LEGAL}.menu.${item}`]: {
        linkTo: YomeURL[item],
        isActive: YomeURL[item] === pathname,
      },
    }

    return { ...reduceResult, ...actionItem }
  }, {} as TDropdownSection)

  useEffect(() => {
    setOutletHeight(contentRef?.current?.clientHeight || 0)
  }, [contentRef, pathname])

  return (
    <div className={styles.layout}>
      {!isDesktop && (
        <MobileTopBar
          title={`${I18N_LEGAL}.menu.title`}
          dropdownOptions={{ sections }}
          showActionsAfterScroll={SCROLL_POSITION_AFTER_MENU}
          isDropdownFullWidth
        />
      )}
      <MenuGroup
        items={LEGAL_LINKS}
        className={styles.menu}
        id={ID.legalLinkToOption}
        i18nKey={I18N_LEGAL_MENU}
      />
      <section ref={contentRef} className={classNames({ [styles.safetyLayout]: pathname === YomeURL.safety })}>
        <Outlet />
      </section>
      <ScrollUp
        contentHeight={outletHeight}
        className={styles.scrollUp}
      />
    </div>
  )
}

export default memo(LegalLayout)
