/* eslint-disable max-len */
import classNames from 'classnames'
import { memo } from 'react'
import { Trans } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import {
  I18N_GLOBAL,
  I18N_LEGAL_COOKIES,
  I18N_LEGAL_COOKIES_TYPES,
  I18N_LEGAL_MENU,
} from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { LegalLink, YomeHash } from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useToggle } from 'src/utils/hooks.utils'
import LegalPageTitle from '../LegalPageTitle'
import LegalSectionTitle from '../LegalSectionTitle'
import CookiesPolicyTable from './CookiesPolicyTable'

import styles from '../legalLayout.module.scss'

const CookiesPolicyWhat = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_COOKIES}.what.heading`}
    />
    <Paragraph text={`${I18N_LEGAL_COOKIES}.what.content`} />
  </>
)

const CookiesPolicyWhy = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_COOKIES}.why.heading`}
    />
    <Paragraph className={styles.label} text={`${I18N_LEGAL_COOKIES}.why.paragraph`} />
    <ul>
      <li>
        <Paragraph text={`${I18N_LEGAL_COOKIES}.why.list.work`} />
      </li>
      <li>
        <Paragraph text={`${I18N_LEGAL_COOKIES}.why.list.pref`} />
      </li>
      <li>
        <Paragraph text={`${I18N_LEGAL_COOKIES}.why.list.improve`} />
      </li>
      <li>
        <Paragraph text={`${I18N_LEGAL_COOKIES}.why.list.share`} />
      </li>
    </ul>
  </>
)

const CookiesPolicyTypes = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_COOKIES_TYPES}.heading`}
    />
    <Heading
      level='h3'
      label={`${I18N_LEGAL_COOKIES_TYPES}.essential.heading`}
      className='label-20'
    />
    <Paragraph text={`${I18N_LEGAL_COOKIES_TYPES}.essential.content`} />
    <Heading
      level='h3'
      label={`${I18N_LEGAL_COOKIES_TYPES}.performance.heading`}
      className='label-20'
    />
    <Paragraph text={`${I18N_LEGAL_COOKIES_TYPES}.performance.content`} />
    <Heading
      level='h3'
      label={`${I18N_LEGAL_COOKIES_TYPES}.functional.heading`}
      className='label-20'
    />
    <Paragraph text={`${I18N_LEGAL_COOKIES_TYPES}.functional.content`} />
    <Heading
      level='h3'
      label={`${I18N_LEGAL_COOKIES_TYPES}.advertising.heading`}
      className='label-20'
    />
    <Paragraph text={`${I18N_LEGAL_COOKIES_TYPES}.advertising.content`} />
    <Heading
      level='h3'
      label={`${I18N_LEGAL_COOKIES_TYPES}.external.heading`}
      className='label-20'
    />
    <Paragraph text={`${I18N_LEGAL_COOKIES_TYPES}.external.content`} />
  </>
)

const CookiesPolicyYome = () => {
  const { isDesktop } = useViewport()
  const [isTableOpen, setIsTableOpen] = useToggle()

  return (
    <>
      <LegalSectionTitle
        label={`${I18N_LEGAL_COOKIES}.yome.heading`}
      />
      <div className={styles.tableWrapper}>
        {!isDesktop && (
          <RegularButton
            label={`${I18N_GLOBAL}.${isTableOpen ? 'collapse' : 'expand'}`}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
            id={ID.legalToggleTable}
            onClick={setIsTableOpen}
            className={classNames(styles.tableBtn, { [styles.tableBtnCollapse]: isTableOpen })}
            rightIcon={{ name: 'chevron-down' }}
          />
        )}
        {(isDesktop || isTableOpen) && (
          <div className={styles.table}>
            <CookiesPolicyTable />
          </div>
        )}
      </div>
    </>
  )
}

const CookiesPolicyManageBrowsers = () => (
  <>
    <Paragraph text={`${I18N_LEGAL_COOKIES}.manage.content.browsers`} />
    <ul>
      <li>
        <a href="https://support.mozilla.org/de/kb/Cookies-blockieren" target="_blank" rel="noopener noreferrer">
          Mozilla Firefox
        </a>
      </li>
      <li>
        <a href="http://windows.microsoft.com/de-at/windows-vista/block-or-allow-cookies" target="_blank" rel="noopener noreferrer">
          Internet Explorer
        </a>
      </li>
      <li>
        <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank" rel="noopener noreferrer">
          Google Chrome
        </a>
      </li>
      <li>
        <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noopener noreferrer">
          Opera
        </a>
      </li>
      <li>
        <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">
          Safari
        </a>
      </li>
    </ul>
  </>
)

const CookiesPolicyManage = () => (
  <>
    <LegalSectionTitle
      label={`${I18N_LEGAL_COOKIES}.manage.heading`}
    />
    <Paragraph text={`${I18N_LEGAL_COOKIES}.manage.content.settings`} />
    <Paragraph text={`${I18N_LEGAL_COOKIES}.manage.content.free`} />
    <Paragraph text={`${I18N_LEGAL_COOKIES}.manage.content.deactivate`} />
    <CookiesPolicyManageBrowsers />
    <Paragraph text={`${I18N_LEGAL_COOKIES}.manage.content.advertising`} />
    <ul>
      <li>
        <Paragraph>
          Google:
          {' '}
          <a href="https://www.google.com/+/policy/pagesterm.html" target="_blank" rel="noopener noreferrer">
            https://www.google.com/+/policy/pagesterm.html
          </a>
        </Paragraph>
      </li>
    </ul>
    <Paragraph text={`${I18N_LEGAL_COOKIES}.manage.content.additional`} />
    <Paragraph text={`${I18N_LEGAL_COOKIES}.manage.content.performance`} />
    <ul>
      <li>
        <p>
          <Trans i18nKey={`${I18N_LEGAL_COOKIES}.manage.content.analytics`}>
            Google Analytics:
            <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noopener noreferrer">
              Google Analytics Opt-out Browser
            </a>
          </Trans>
        </p>
      </li>
    </ul>
    <p>
      <Trans i18nKey={`${I18N_LEGAL_COOKIES}.manage.content.manage`}>
        You can
        <RegularButton
          linkTo={YomeHash.cookie}
          className={styles.button}
          id={ID.cookieManageOpenModal}
          buttonType={RegularButtonTypes.transparentNoBorder}
        >
          Manage cookies
        </RegularButton>
        and update your choices at any time.
      </Trans>
    </p>
  </>
)

const CookiesPolicyRoute = () => (
  <article className='static-content'>
    <LegalPageTitle
      label={`${I18N_LEGAL_MENU}.${LegalLink.cookies}`}
    />
    <div className={styles.content}>
      <CookiesPolicyWhat />
      <CookiesPolicyWhy />
      <CookiesPolicyTypes />
      <CookiesPolicyYome />
      <CookiesPolicyManage />
    </div>
  </article>
)

export default memo(CookiesPolicyRoute)
