/* eslint-disable max-len */
import { memo } from 'react'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import LegalSectionTitle from '../../LegalSectionTitle'

const PrivacyPolicyFacebook = () => (
  <>
    <Heading level='h3' className='label-20'>
      4.1. Facebook
    </Heading>
    <Paragraph>
      Wir unterhalten ein Profil auf Facebook. Betreiber ist Meta Platforms Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Die Datenschutzerklärung ist hier abrufbar:
      {' '}
      <a href="https://www.facebook.com/policy.php" target="_blank" rel="noopener noreferrer">
        https://www.facebook.com/policy.php
      </a>
      . Eine Möglichkeit, der Datenverarbeitung zu widersprechen, ergibt sich über Einstellungen für Werbeanzeigen:
      {' '}
      <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">
        https://www.facebook.com/settings?tab=ads
      </a>
      .
    </Paragraph>
    <Paragraph>
      Wir sind auf Grundlage einer Vereinbarung gemeinsam im Sinne von Art. 26 DSGVO mit Facebook für die Verarbeitung der Daten der Besucher unseres Profils verantwortlich. Welche Daten genau verarbeitet werden, erklärt Facebook unter
      {' '}
      <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data" target="_blank" rel="noopener noreferrer">
        https://www.facebook.com/legal/terms/information_about_page_insights_data
      </a>
      . Betroffene können ihre Rechte sowohl gegenüber uns als auch gegenüber Facebook wahrnehmen. Nach unserer Vereinbarung mit Facebook sind wir aber dazu verpflichtet, Anfragen an Facebook weiterzuleiten. Betroffene erhalten also eine schnellere Rückmeldung, wenn sie sich direkt an Facebook wenden.
    </Paragraph>
  </>
)

const PrivacyPolicyInstagram = () => (
  <>
    <Heading level='h3' className='label-20'>
      4.2. Instagram
    </Heading>
    <Paragraph>
      Wir unterhalten ein Profil auf Instagram. Betreiber ist Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Die Datenschutzerklärung ist hier abrufbar:
      {' '}
      <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noopener noreferrer">
        https://help.instagram.com/519522125107875
      </a>
      .
    </Paragraph>
  </>
)

const PrivacyPolicyLinkedIn = () => (
  <>
    <Heading level='h3' className='label-20'>
      4.3. LinkedIn
    </Heading>
    <Paragraph>
      Wir unterhalten ein Profil auf LinkedIn. Betreiber ist LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland. Die Datenschutzerklärung ist hier abrufbar:
      {' '}
      <a href="https://https://www.linkedin.com/legal/privacy-policy?_l=de_DE" target="_blank" rel="noopener noreferrer">
        https://https://www.linkedin.com/legal/privacy-policy?_l=de_DE
      </a>
      . Eine Möglichkeit, der Datenverarbeitung zu widersprechen, ergibt sich über die Einstellungen für Werbeanzeigen:
      {' '}
      <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank" rel="noopener noreferrer">
        https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
      </a>
      .
    </Paragraph>
  </>
)

const PrivacyPolicySocial = () => (
  <>
    <LegalSectionTitle
      label='4. Datenverarbeitung auf Social Media-Plattformen'
    />
    <Paragraph>
      Wir sind in Social Media-Netzwerken vertreten, um dort unsere Organisation und unsere Leistungen vorzustellen. Die Betreiber dieser Netzwerke verarbeiten Daten ihrer Nutzer regelmäßig zu Werbezwecken. Unter anderem erstellen sie aus ihrem Onlineverhalten Nutzerprofile, die beispielsweise dazu verwendet werden, um auf den Seiten der Netzwerke und auch sonst im Internet Werbung zu zeigen, die den Interessen der Nutzer entspricht. Dazu speichern die Betreiber der Netzwerke Informationen zu dem Nutzungsverhalten in Cookies auf dem Rechner der Nutzer. Es ist außerdem nicht auszuschließen, dass die Betreiber diese Informationen mit weiteren Daten zusammenführen. Weitere Informationen sowie Hinweise, wie Nutzer der Verarbeitung durch die Seitenbetreiber widersprechen können, erhalten Nutzer in den unten aufgeführten Datenschutzerklärungen der jeweiligen Betreiber. Es kann auch sein, dass die Betreiber oder ihre Server in Nicht-EU-Staaten sitzen, so dass sie Daten dort verarbeiten. Hierdurch können sich für die Nutzer Risiken ergeben, z.B. weil die Durchsetzung ihrer Rechte erschwert wird oder staatliche Stellen Zugriff auf die Daten nehmen.
    </Paragraph>
    <Paragraph>
      Wenn Nutzer der Netzwerke mit uns über unsere Profile in Kontakt treten, verarbeiten wir die uns mitgeteilten Daten, um die Anfragen zu beantworten. Darin liegt unser berechtigtes Interesse, so dass Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO ist.
    </Paragraph>
    <PrivacyPolicyFacebook />
    <PrivacyPolicyInstagram />
    <PrivacyPolicyLinkedIn />
  </>
)

export default memo(PrivacyPolicySocial)
