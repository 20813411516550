import Heading from 'src/components/heading/Heading'
import { I18N_ABOUT_US_MAIN_FEATURES } from 'src/constants/i18n.constants'

import { useViewport } from 'src/contexts/viewportContext'
import MainFeatureCards from './MainFeaturesCards'
import MainFeaturesCardsMobile from './MainFeaturesMobile'

import aboutStyles from '../../about.module.scss'

const MainFeatures = () => {
  const { isDesktop } = useViewport()

  return (
    <div>
      <Heading
        level='h2'
        label={`${I18N_ABOUT_US_MAIN_FEATURES}.title`}
        className={aboutStyles.h2title}
      />
      {isDesktop ? <MainFeatureCards /> : <MainFeaturesCardsMobile />}
    </div>
  )
}

export default MainFeatures
