import config from 'src/config'

import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { io, Socket } from 'socket.io-client'
import useSWR, { mutate } from 'swr'

import { I18N_MESSENGER } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import YomeAPI from 'src/constants/network.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useIsTabActiveContext } from 'src/contexts/activeTabContext'
import { useUserPreferencesContext } from 'src/contexts/userPreferencesContext'
import { IChat, IChatMessage, IChatOffer, IOnlineStatus, IWarningMessage } from 'src/models/messenger.model'
import { captureErrorAndShowToast, SocketConnectionError, SocketErrorResponse } from 'src/utils/error.utils'
import { useCheckIsPage } from 'src/utils/hooks.utils'
import { getThumbSmallUrl } from 'src/utils/images.utils'
import {
  getUpdatedChatsWithNewMessage,
  getUpdatedHistory,
  setChatHistoryRead,
  setOnlineStatus,
} from 'src/utils/messenger.utils'
import { sendPost } from 'src/utils/network.utils'

export enum ServerToClientEvents {
  Connect = 'connect',
  ConnectError = 'connect_error',
  ServerError = 'v1:serverError',
  CreateChat = 'v1:chat:create',
  ShowNewMessage = 'v1:chat:showNewMessage',
  IncomingMessage = 'v1:chat:incomingMessage',
  GetChatHistory = 'v1:message:getChatHistory',
  GetAllChats = 'v1:message:getAllChats',
  OnlineStatus = 'v1:user:online',
  hasUnreadChats = 'v1:user:hasUnreadChats',
}

export enum ClientToServerEvents {
  CreateChat = 'v1:chat:create',
  AddMessage = 'v1:message:add',
  GetChatHistory = 'v1:message:getChatHistory',
  GetAllChats = 'v1:message:getAllChats',
  ReadChatHistory = 'v1:chat:readChatHistory',
  OnlineStatus = 'v1:user:online',
  ScanWarningFeedback = 'v1:scan-warning:feedback',
}

let singleSocket: Socket

export const getSocket = () => {
  // If the socket is already created, return the existing instance
  if (singleSocket) return singleSocket

  singleSocket = io(
    config.messengerApiUrl,
    {
      transports: ['websocket'],
      autoConnect: false,
      withCredentials: true,
    },
  )

  return singleSocket
}

export const SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR'

export const openOrCreateChatByPost = (postId: string, message?: string):
Promise<string> => new Promise((resolve) => {
  const socket = getSocket()

  socket.emit(ClientToServerEvents.CreateChat, { postId, message })

  socket.on(ServerToClientEvents.CreateChat, (createdChat: { id: string }) => {
    resolve(createdChat.id)
    socket.off(ServerToClientEvents.CreateChat)
  })
})

export const sendScanWarningFeedback = (chatId: string, scanWarningId: string, isHelpful: boolean) => {
  const socket = getSocket()

  socket.emit(ClientToServerEvents.ScanWarningFeedback, { chatId, scanWarningId, isHelpful })
}

export const useSubscribeOnGetAllChats = (handleGetAllChats: (chats: IChat[]) => void) => {
  const socket = getSocket()

  useEffect(() => {
    socket.on(ServerToClientEvents.GetAllChats, handleGetAllChats)

    return () => {
      socket.off(ServerToClientEvents.GetAllChats)
    }
  }, [handleGetAllChats, socket])
}

export const useSubscribeOnGetChatHistory = (
  handleGetChatHistory: (history: IChatMessage[]) => void,
) => {
  const socket = getSocket()

  useEffect(() => {
    socket.on(ServerToClientEvents.GetChatHistory, handleGetChatHistory)

    return () => {
      socket.off(ServerToClientEvents.GetChatHistory)
    }
  }, [socket, handleGetChatHistory])
}

export const useSubscribeOnNewChatMessage = (
  handleNewChatMessage: (newMessage: IChatMessage | IWarningMessage) => void,
) => {
  const socket = getSocket()

  useEffect(() => {
    socket.on(ServerToClientEvents.ShowNewMessage, handleNewChatMessage)

    return () => {
      socket.off(ServerToClientEvents.ShowNewMessage)
    }
  }, [handleNewChatMessage, socket])
}

export const useSubscribeOnOnlineStatus = (handleOnlineStatus: (onlineStatus: IOnlineStatus) => void) => {
  const socket = getSocket()

  useEffect(() => {
    socket.on(ServerToClientEvents.OnlineStatus, handleOnlineStatus)

    return () => {
      socket.off(ServerToClientEvents.OnlineStatus)
    }
  }, [handleOnlineStatus, socket])
}

export const CHAT_ID_SEARCH_PARAM = 'chatId'

export const useSubscribeAndGetChatData = () => {
  const socket = getSocket()

  const { data: chats, mutate: mutateChats } = useSWR<IChat[]>(SWRkeys.getAllChats)
  const { data: currentChat, mutate: mutateCurrentChat } = useSWR<IChat>(SWRkeys.currentChat)
  const { mutate: mutateUnreadChatsStatus } = useSWR<boolean>(SWRkeys.hasMessangerUnreadChats)

  const { data: history, mutate: mutateHistory } = useSWR<(IChatMessage | IWarningMessage)[]>(
    SWRkeys.chatHistory(currentChat?.id || ''))

  // on every chats change check if hasUnreadChats status changes
  useEffect(() => {
    const hasUnreadChats = chats?.some((chat) => chat.unreadMessagesCount > 0)
    mutateUnreadChatsStatus(hasUnreadChats)
  }, [chats, mutateUnreadChatsStatus])

  const handleNewChatMessage = useCallback((newMessage: IChatMessage | IWarningMessage) => {
    if (currentChat) {
      const updatedHistory = getUpdatedHistory(newMessage, currentChat.id, history)
      mutateHistory(updatedHistory)
    }

    // for new message in current chat - read messages
    if (currentChat?.id === newMessage.chatId && !newMessage.isCurrentUserAuthor) {
      socket.emit(ClientToServerEvents.ReadChatHistory, currentChat.id)
    }

    mutateChats((curChats) => getUpdatedChatsWithNewMessage(socket, newMessage, curChats, currentChat?.id))
  }, [mutateChats, currentChat, history, mutateHistory, socket])

  const handleUserOnlineStatus = useCallback((onlineStatus: IOnlineStatus) => {
    mutateChats((curChats) => setOnlineStatus(curChats, onlineStatus))
  }, [mutateChats])

  const handleGetChatHistory = useCallback((chatHistory: IChatMessage[]) => {
    mutateHistory(chatHistory)

    mutateChats((curChats) => setChatHistoryRead(curChats, currentChat?.id))
  }, [mutateHistory, mutateChats, currentChat])

  useSubscribeOnGetChatHistory(handleGetChatHistory)
  useSubscribeOnGetAllChats(mutateChats)
  useSubscribeOnNewChatMessage(handleNewChatMessage)
  useSubscribeOnOnlineStatus(handleUserOnlineStatus)

  return { chats, currentChat, mutateCurrentChat }
}

const useDisplayMessageNotification = () => {
  const { t } = useTranslation()

  return useCallback((newMessage: IChatMessage, offerData: IChatOffer) => {
    if (!('Notification' in window) || Notification.permission !== 'granted') return

    const title = t(`${I18N_MESSENGER}.newMsgNotificationTitle`)
    const titleWithAuthor = `${title} ${newMessage.author.name}`
    const pushText = newMessage.content
    const postPicture = getThumbSmallUrl(offerData?.photo, offerData?.publicId) || undefined
    const messengerURL = `${YomeURL.messages}?${CHAT_ID_SEARCH_PARAM}=${newMessage.chatId}`

    const notification = new Notification(titleWithAuthor, {
      body: pushText,
      icon: postPicture,
      // removed to check if it do not let show mobile notification
      // badge: `${GOOGLE_API_URL}/${config.bucket.image}/yome-small.png`,
      tag: newMessage.chatId,
    })

    notification.onclick = (event) => {
      event.preventDefault()
      window.open(messengerURL)
    }
  }, [t])
}

export const useSubscribeOnUnreadChatsStatus = () => {
  const socket = getSocket()

  const { mutate: mutateUnreadChatsStatus } = useSWR<boolean>(SWRkeys.hasMessangerUnreadChats)

  const handleUnreadChatsStatus = useCallback((hasUnreadChats: boolean) => {
    mutateUnreadChatsStatus(hasUnreadChats)
  }, [mutateUnreadChatsStatus])

  useEffect(() => {
    socket.on(ServerToClientEvents.hasUnreadChats, handleUnreadChatsStatus)

    return () => {
      socket.off(ServerToClientEvents.hasUnreadChats)
    }
  }, [socket, handleUnreadChatsStatus])
}

export const useSubscribeOnIncomingMessage = () => {
  const socket = getSocket()
  const userPreferences = useUserPreferencesContext()
  const { isChatPage } = useCheckIsPage()

  const isTabActive = useIsTabActiveContext()

  const displayMessageNotification = useDisplayMessageNotification()

  const hasNewMessagesPush = userPreferences ? userPreferences.hasNewMessagesPush : false

  const isDisplayNotification = hasNewMessagesPush && (!isChatPage || !isTabActive)

  const handleIncomingMessage = useCallback((newMessage: IChatMessage, offerData: IChatOffer) => {
    if (isDisplayNotification) {
      displayMessageNotification(newMessage, offerData)
    }
  }, [isDisplayNotification, displayMessageNotification])

  useEffect(() => {
    socket.on(ServerToClientEvents.IncomingMessage, handleIncomingMessage)

    return () => {
      socket.off(ServerToClientEvents.IncomingMessage)
    }
  }, [socket, handleIncomingMessage])
}

export const useSubscribeOnVisibilityChange = () => {
  const socket = getSocket()
  const isTabActive = useIsTabActiveContext()

  useEffect(() => {
    socket.emit(ClientToServerEvents.OnlineStatus, isTabActive)
  }, [socket, isTabActive])
}

const handleWSError = ({ errorCode }: { errorCode: string }) => {
  captureErrorAndShowToast(new SocketErrorResponse(errorCode))
}

export const disconnectSocket = () => {
  const socket = getSocket()

  if (!socket.connected) {
    return
  }

  socket.off(ServerToClientEvents.ConnectError)
  socket.off(ServerToClientEvents.ServerError)
  socket.off(ServerToClientEvents.Connect)
  socket.disconnect()
}

export const setConnectionErrorAndDisconnect = (error: Error) => {
  const socket = getSocket()

  if (!socket) return

  mutate(
    SWRkeys.socketConnectionError,
    new SocketConnectionError(error.message, error),
  )
}

const onConnect = () => {
  mutate(SWRkeys.socketConnectionError, undefined)
  toast.dismiss(SOCKET_CONNECTION_ERROR)
}

export const connectSocket = () => {
  const socket = getSocket()

  if (socket.connected) {
    return
  }

  socket.connect()
  socket.on(ServerToClientEvents.ConnectError, setConnectionErrorAndDisconnect)
  socket.on(ServerToClientEvents.Connect, onConnect)
  socket.on(ServerToClientEvents.ServerError, handleWSError)
}

export const handleBlockUser = async (
  partnerId: string,
  chatId: string,
): Promise<void> => {
  try {
    await sendPost(YomeAPI.messengerBlockUser, { partnerId, chatId })
  } catch (error) {
    captureErrorAndShowToast(error)
  }
}

export const handleUnblockUser = async (
  partnerId: string,
): Promise<void> => {
  try {
    await sendPost(YomeAPI.messengerUnblockUser, { partnerId })
  } catch (error) {
    captureErrorAndShowToast(error)
  }
}

interface IHandleReportChatUser{
  chatId: string;
  reportedUserId: string;
  reason: string;
  isAllowedToRead?: boolean;
  comment?: string;

}

const handleReportUser = async (
  { chatId,
    reportedUserId,
    reason,
    isAllowedToRead = false,
    comment }: IHandleReportChatUser,
): Promise<void> => sendPost(YomeAPI.messengerReportUser, { chatId, reportedUserId, reason, comment, isAllowedToRead })

export default {
  handleReportUser,
}
