import classNames from 'classnames'
import React from 'react'

import { useViewport } from 'src/contexts/viewportContext'

import RegularButton, { IRegularButtonProps } from '../button/RegularButton'
import Heading from '../heading/Heading'
import Paragraph from '../paragraph/Paragraph'
import SvgIcon, { ISvgIconProps } from '../svgIcon/SvgIcon'

import styles from './emptyStateMessage.module.scss'

export enum EmptyStateMessageType {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

interface IEmptyStateMessage {
  type?: EmptyStateMessageType;
  icon?: string;
  iconSize?: number;
  title?: string;
  text?: string;
  button?: IRegularButtonProps;
  className?: string;
  titleIconProps?: ISvgIconProps;
}

const EMPTY_STATE_ICON_SIZE = 184
const HORIZONTAL_EMPTY_STATE_ICON_SIZE = 400

const getIconSize = (isVertical: boolean, isDesktop: boolean) => (
  isVertical || !isDesktop ? EMPTY_STATE_ICON_SIZE : HORIZONTAL_EMPTY_STATE_ICON_SIZE
)

const EmptyStateMessage: React.FC<IEmptyStateMessage> = ({
  type = EmptyStateMessageType.vertical,
  icon,
  iconSize,
  title,
  text,
  button,
  className,
  titleIconProps,
}) => {
  const isVertical = type === EmptyStateMessageType.vertical

  const { isDesktop } = useViewport()

  return (
    <div className={classNames((isVertical ? styles.main : styles.mainHorizontal), className)}>
      {icon && <SvgIcon name={icon} size={iconSize || getIconSize(isVertical, isDesktop)}/>}
      <div className={styles.content}>
        <div className={styles.title}>
          {titleIconProps && <SvgIcon size={24} {...titleIconProps}/>}
          <Heading
            level="h2"
            label={title}
            className="desktop-h5 mobile-h5"
          />
        </div>

        {text && <Paragraph text={text} className={styles.text} />}
        {button && (
          <RegularButton
            {...button}
            className={styles.button}
          />
        )}
      </div>
    </div>
  )
}

export default EmptyStateMessage
