import SWRErrorNotification from 'src/components/swr/SWRErrorNotification'
import { ILimitedSingleOfferList, IStackBoundaryProps, IStackProps } from 'src/models/post.model'
import { useGetPostList } from 'src/services/post.service'
import SWRkeys from 'src/constants/swr.constants'
import FavoriteOrViewHistoryCardStack from './FavoriteOrViewHistoryCardStack'
import LastPublicationCardStack from './LastPublicationCardStack'

const AuthorizedUserCardStack = ({ stackEndpoint, offerLimit }: IStackProps) => {
  const {
    data: lastPublications,
    isLoading,
  } = useGetPostList<ILimitedSingleOfferList>(stackEndpoint)

  if (isLoading) {
    return null
  }

  if (lastPublications && lastPublications.items.length > 0) {
    return (
      <LastPublicationCardStack
        stackInfo={lastPublications}
        stackEndpoint={stackEndpoint}
      />
    )
  }

  return (
    <FavoriteOrViewHistoryCardStack
      offerLimit={offerLimit!}
    />
  )
}

const AuthorizedUserCardStackBoundary = ({ offerLimit }: IStackBoundaryProps) => {
  const params = { limit: offerLimit }
  const stackSWRkey = SWRkeys.lastPublication(params)

  return (
    <SWRErrorNotification swrKey={stackSWRkey}>
      <AuthorizedUserCardStack stackEndpoint={stackSWRkey} offerLimit={offerLimit}/>
    </SWRErrorNotification>
  )
}

export default AuthorizedUserCardStackBoundary
