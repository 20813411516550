/* eslint-disable max-len */
import { memo } from 'react'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import LegalSectionTitle from '../../LegalSectionTitle'

const PrivacyPolicyIntroContact = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.1. Kontaktdaten
    </Heading>
    <Paragraph>
      Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO) ist Yome Network UG (haftungsbeschränkt), Kolonnenstraße 8, 10827 Berlin, Germany, E-Mail:
      {' '}
      <a href="mailto:info@yome.network" target="_blank" rel="noopener noreferrer">
        info@yome.network
      </a>
      . Gesetzlich vertreten werden wir durch Ekaterina Violentova.
    </Paragraph>
    <Paragraph>
      Unser Datenschutzbeauftragter ist über die heyData GmbH, Schützenstraße 5, 10117 Berlin,
      {' '}
      <a href="www.heydata.eu" target="_blank" rel="noopener noreferrer">
        www.heydata.eu
      </a>
      , E-Mail:
      {' '}
      <a href="mailto:datenschutz@heydata.eu" target="_blank" rel="noopener noreferrer">
        datenschutz@heydata.eu
      </a>
      {' '}
      erreichbar.
    </Paragraph>
  </>
)

const PrivacyPolicyIntroScope = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.2. Umfang der Datenverarbeitung, Verarbeitungszwecke und Rechtsgrundlagen
    </Heading>
    <Paragraph>
      Den Umfang der Verarbeitung der Daten, Verarbeitungszwecke und Rechtsgrundlagen führen wir im Detail weiter unten aus. Als Rechtsgrundlage für eine Datenverarbeitung kommen grundsätzlich die folgenden in Betracht:
    </Paragraph>
    <ul>
      <li>
        <Paragraph>
          Art. 6 Abs. 1 S. 1 lit. a DSGVO dient uns als Rechtsgrundlage für Verarbeitungsvorgänge, für die wir eine Einwilligung einholen.
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Art. 6 Abs. 1 S. 1 lit. b DSGVO ist Rechtsgrundlage, soweit die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrages erforderlich ist, z.B. wenn ein Seitenbesucher von uns ein Produkt erwirbt oder wir für ihn eine Leistung ausführen. Diese Rechtsgrundlage gilt auch für Verarbeitungen, die für vorvertragliche Maßnahmen erforderlich sind, etwa bei Anfragen zu unseren Produkten oder Leistungen.
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Art. 6 Abs. 1 S. 1 lit. c DSGVO findet Anwendung, wenn wir mit der Verarbeitung personenbezogener Daten eine rechtliche Verpflichtung erfüllen, wie es z.B. im Steuerrecht der Fall sein kann.
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Art. 6 Abs. 1 S. 1 lit. f DSGVO dient als Rechtsgrundlage, wenn wir uns zur Verarbeitung personenbezogener Daten auf berechtigte Interessen berufen können, z.B. für Cookies, die für den technischen Betrieb unserer Website erforderlich sind.
        </Paragraph>
      </li>
    </ul>
  </>
)

const PrivacyPolicyIntroOutsideEEA = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.3. Datenverarbeitung außerhalb des EWR
    </Heading>
    <Paragraph>
      Soweit wir Daten an Dienstleister oder sonstige Dritte außerhalb des EWR übermitteln, garantieren Angemessenheitsbeschlüsse der EU-Kommission nach Art. 45 Ab. 3 DSGVO die Sicherheit der Daten bei der Weitergabe, soweit diese vorhanden sind, wie dies z.B. für Großbritannien, Kanada und Israel der Fall ist.
    </Paragraph>
    <Paragraph>
      Bei der Datenweitergabe an Dienstleister in den USA, ist Rechtsgrundlage für die Datenweitergabe ein Angemessenheitsbeschluss der EU-Kommission, wenn sich der Dienstleister zusätzlich unter dem EU US Data Privacy Framework zertifiziert hat.
    </Paragraph>
    <Paragraph>
      In anderen Fällen (z.B. wenn kein Angemessenheitsbeschluss existiert), sind Rechtsgrundlage für die Datenweitergabe im Regelfall, also soweit wir keinen abweichenden Hinweis geben, Standardvertragsklauseln. Diese sind ein von der EU-Kommission verabschiedetes Regelwerk und Teil des Vertrags mit dem jeweiligen Dritten. Nach Art. 46 Abs. 2 lit. b DSGVO gewährleisten sie die Sicherheit der Datenweitergabe. Viele der Anbieter haben über die Standardvertragsklauseln hinausgehende vertragliche Garantien abgegeben, die die Daten über die Standardvertragsklauseln hinaus schützen. Das sind z.B. Garantien hinsichtlich der Verschlüsselung der Daten oder hinsichtlich einer Pflicht des Dritten zum Hinweis an Betroffene, wenn Strafverfolgungsorgane auf Daten zugreifen wollen.
    </Paragraph>
  </>
)

const PrivacyPolicyIntroStorage = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.4. Speicherdauer
    </Heading>
    <Paragraph>
      Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird ihre Verarbeitung eingeschränkt, d.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die wir aus handels- oder steuerrechtlichen Gründen aufbewahren müssen.
    </Paragraph>
  </>
)

const PrivacyPolicyIntroRights = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.5. Rechte der Betroffenen
    </Heading>
    <Paragraph>
      Betroffene haben gegenüber uns folgende Rechte hinsichtlich der sie betreffenden personenbezogenen Daten:
    </Paragraph>
    <ul>
      <li>
        <Paragraph>
          Recht auf Auskunft,
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Recht auf Berichtigung oder Löschung,
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Recht auf Einschränkung der Verarbeitung,
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          <strong>
            Recht auf Widerspruch gegen die Verarbeitung,
          </strong>
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          Recht auf Datenübertragbarkeit,
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          <strong>
            Recht, eine erteilte Einwilligung jederzeit zu widerrufen.
          </strong>
        </Paragraph>
      </li>
    </ul>
    <Paragraph>
      Betroffene haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung ihrer personenbezogenen Daten zu beschweren. Kontaktdaten der Datenschutz-Aufsichtsbehörden sind unter
      {' '}
      <a
        href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html
      </a>
      {' '}
      abrufbar.
    </Paragraph>
  </>
)

const PrivacyPolicyIntroObligation = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.6. Pflicht zur Bereitstellung von Daten
    </Heading>
    <Paragraph>
      Kunden, Interessenten oder Dritte müssen uns im Rahmen einer Geschäftsbeziehung oder sonstigen Beziehung nur diejenigen personenbezogenen Daten bereitstellen, die für die Begründung, Durchführung und Beendigung der Geschäftsbeziehung oder für die sonstige Beziehung erforderlich sind oder zu deren Erhebung wir gesetzlich verpflichtet sind. Ohne diese Daten werden wir in der Regel den Abschluss eines Vertrages oder die Bereitstellung einer Leistung ablehnen müssen oder einen bestehenden Vertrag oder sonstige Beziehung nicht mehr durchführen können.
    </Paragraph>
    <Paragraph>
      Pflichtangaben sind als solche gekennzeichnet.
    </Paragraph>
  </>
)

const PrivacyPolicyIntroAutomaticDecision = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.7. Keine automatische Entscheidungsfindung im Einzelfall
    </Heading>
    <Paragraph>
      Zur Begründung und Durchführung einer Geschäftsbeziehung oder sonstigen Beziehung nutzen wir grundsätzlich keine vollautomatisierte Entscheidungsfindung gemäß Artikel 22 DSGVO. Sollten wir diese Verfahren in Einzelfällen einsetzen, werden wir hierüber gesondert informieren, sofern dies gesetzlich geboten ist.
    </Paragraph>
  </>
)

const PrivacyPolicyIntroMakingContact = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.8. Kontaktaufnahme
    </Heading>
    <Paragraph>
      Bei der Kontaktaufnahme mit uns, z.B. per E-Mail oder Telefon, werden die uns mitgeteilten Daten (z.B. Namen und E-Mail-Adressen) von uns gespeichert, um Fragen zu beantworten. Rechtsgrundlage für die Verarbeitung ist unser berechtigtes Interesse (Art. 6 Abs. 1 S. 1 lit. f DSGVO), an uns gerichtete Anfragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
    </Paragraph>
  </>
)

const PrivacyPolicyIntroCustomerSurveys = () => (
  <>
    <Heading level='h3' className='label-24'>
      1.9. Kundenumfragen
    </Heading>
    <Paragraph>
      Von Zeit zu Zeit führen wir Kundenumfragen durch, um unsere Kunden und ihre Wünsche besser kennenzulernen. Dabei erheben wir die jeweils abgefragten Daten. Es ist unser berechtigtes Interesse, unsere Kunden und ihre Wünsche besser kennenzulernen, so dass Rechtsgrundlage der damit einhergehenden Datenverarbeitung Art. 6 Abs. 1 S. 1 lit f DSGVO ist. Die Daten löschen wir, wenn die Ergebnisse der Umfragen ausgewertet sind.
    </Paragraph>
  </>
)

const PrivacyPolicyIntro = () => (
  <>
    <LegalSectionTitle
      label='1. Einleitung'
    />
    <Paragraph>
      Im Folgenden informieren wir über die Verarbeitung personenbezogener Daten bei der Nutzung
    </Paragraph>
    <ul>
      <li>
        <Paragraph>
          unserer Website
          {' '}
          <a href='https://yome.network/'>
            https://yome.network/
          </a>
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          unserer Profile in Sozialen Medien.
        </Paragraph>
      </li>
    </ul>
    <Paragraph>
      Personenbezogene Daten sind alle Daten, die auf eine konkrete natürliche Person beziehbar sind, z. B. ihr Name oder ihre IP-Adresse.
    </Paragraph>
    <PrivacyPolicyIntroContact />
    <PrivacyPolicyIntroScope />
    <PrivacyPolicyIntroOutsideEEA />
    <PrivacyPolicyIntroStorage />
    <PrivacyPolicyIntroRights />
    <PrivacyPolicyIntroObligation />
    <PrivacyPolicyIntroAutomaticDecision />
    <PrivacyPolicyIntroMakingContact />
    <PrivacyPolicyIntroCustomerSurveys />
  </>
)

export default memo(PrivacyPolicyIntro)
