import classNames from 'classnames'
import React, { HTMLAttributes, memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { LevelType } from 'src/models/heading.model'

interface IHeadingProps extends HTMLAttributes<HTMLHeadingElement>{
  label?: string;
  className?: string;
  children?: ReactNode;
  level?: LevelType;
}

const Heading: React.FC<IHeadingProps> = (
  { label,
    className,
    children,
    level: Level = 'h1',
    ...headingProps },
) => {
  const { t } = useTranslation()

  return (
    <Level className={classNames(
      'heading',
      className,
    )}
    {...headingProps}
    >
      {label && t(label)}
      {children}
    </Level>
  )
}

export default memo(Heading)
