import { useTranslation } from 'react-i18next'

import ButtonLink from 'src/components/button/ButtonLink'
import { I18N_FOOTER_LINK, I18N_FOOTER_SECTION, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { DOC_LINKS } from 'src/models/footer.model'

import styles from './footer.module.scss'

const FooterSmallLegal = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.smallLegalLinks}>
      <span>
        {t(`${I18N_GLOBAL}.yomeIs`)}
        <span className={styles.linkWrapper}>
          <ButtonLink
            linkTo={YomeURL.mainPage}
            label={`${I18N_FOOTER_LINK}.isOnlineClassified`}
            id={ID.footerLinkToMainPage}
          />
        </span>
      </span>
      <span>
        {t(`${I18N_FOOTER_SECTION}.date`)}
      </span>
      <span>
        {DOC_LINKS.map(({ id, linkTo }) => (
          <span className={styles.linkWrapper} key={id}>
            <ButtonLink
              linkTo={linkTo}
              label={`${I18N_FOOTER_LINK}.${id}`}
              id={concatId(ID.footerLinkToOption, id)}
            />
          </span>
        ))}
      </span>
    </div>
  )
}

export default FooterSmallLegal
