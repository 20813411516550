import React from 'react'

import { captureException } from 'src/utils/browser.utils'

interface IErrorBoundaryProps {
  children: React.ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  errorClass?: string;
}

class ErrorBoundarySkip extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorClass: error.name }
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error) {
    captureException(error)
  }

  render() {
    const { children } = this.props

    if (this.state.hasError) {
      return null
    }

    return children
  }
}

export default ErrorBoundarySkip
