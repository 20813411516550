import { useTranslation } from 'react-i18next'

import Heading from 'src/components/heading/Heading'

import styles from './wrapper.module.scss'

interface SettingSectionWrapperProps {
  title: string;
  hint?: string;
  children: JSX.Element;
}

const SettingSectionWrapper = ({ title, hint, children }: SettingSectionWrapperProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.main}>
      <div className={styles.wrapperInfo}>
        <Heading className={'desktop-h5 mobile-h6'} level='h2'>
          {t(title)}
        </Heading>
        <span className={'supportive-14'}>
          {hint && t(hint)}
        </span>
      </div>
      {children}
    </div>
  )
}

export default SettingSectionWrapper
