import classNames from 'classnames'

import NoPhoto from '../noPhoto/NoPhoto'

import styles from './imageOrNoPhoto.module.scss'

interface IImageOrNoPhotoProps {
  src: string | null;
  className?: string;
  alt?: string;
  objectFit?: 'contain' | 'cover';
}

const ImageOrNoPhoto: React.FC<IImageOrNoPhotoProps> = ({ src, alt = '', className, objectFit = 'cover' }) => (
  <div className={classNames(styles.main, className)}>
    {src ? <img src={src} alt={alt} className={styles[objectFit]}/> : <NoPhoto />}
  </div>
)

export default ImageOrNoPhoto
