import React, { useMemo } from 'react'

import { useViewport } from 'src/contexts/viewportContext'
import { TDropdownSection } from 'src/models/dropdown.model'

import { emptyFunction } from '../../constants/function.constants'
import { I18N_SORT } from '../../constants/i18n.constants'
import { IdStructure } from '../../constants/id.constants'
import RegularButton, { RegularButtonTypes } from '../button/RegularButton'
import Dropdown from '../dropdown/Dropdown'

import styles from './sortDropdown.module.scss'

interface ISortDropdowProps<T> {
  onChange: (selectedOption: T) => void;
  sortOptions: T[];
  defaultValue: T;
  idSortButton: IdStructure;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SortDropdown = <T,>({
  onChange,
  sortOptions,
  idSortButton,
  defaultValue,
}: ISortDropdowProps<T>) => {
  const { isDesktop } = useViewport()
  const [activeOption, setActiveOption] = React.useState(defaultValue)

  React.useEffect(() => {
    setActiveOption(defaultValue)
  }, [defaultValue])

  const sortActions = useMemo(() => sortOptions.reduce((reduceResult, item) => {
    const sortActionItem = {
      [`${I18N_SORT}.${item}`]: {
        onClick: () => {
          setActiveOption(item)
          onChange(item)
        },
      },
    }

    return { ...reduceResult, ...sortActionItem }
  }, {} as TDropdownSection), [onChange, sortOptions])

  const sortButtonLabel = `${I18N_SORT}.${activeOption}`

  return (
    <div className={styles.main}>
      <Dropdown
        id={idSortButton}
        sections={{ sortActions }}
        selectedAction={sortButtonLabel}
        alignLeft={!isDesktop}
      >
        <div className={styles.chevron}>
          <RegularButton
            id={idSortButton}
            onClick={emptyFunction}
            buttonType={RegularButtonTypes.transparentNoBorder}
            label={sortButtonLabel}
            leftIcon={{ name: 'sort' }}
            rightIcon={{ name: 'chevron-down', size: 24 }}
            className="label-14"
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default SortDropdown
