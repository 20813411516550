import { CircleLoader } from 'react-spinners'

import { assertData } from 'src/utils/error.utils'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import TabBarLayout from 'src/components/tabBarLayout/TabBarLayout'
import { I18N_FAVORITES } from 'src/constants/i18n.constants'
import { getFavoriteNavLink } from 'src/models/navigation.model'
import { useGetFavoriteCounters } from 'src/services/favorite.service'
import SWRkeys from 'src/constants/swr.constants'

const FavoritesRoute = () => {
  const { data: favoriteCounters, isLoading } = useGetFavoriteCounters()

  if (isLoading) {
    return (
      <CircleLoader />
    )
  }

  assertData(favoriteCounters)

  return (
    <TabBarLayout title={`${I18N_FAVORITES}.layout.title`} tabItems={getFavoriteNavLink(favoriteCounters)} />
  )
}

const FavoritesRouteBoundary = () => (
  <SWRErrorBoundary swrKey={SWRkeys.favoriteCounters}>
    <FavoritesRoute />
  </SWRErrorBoundary>

)

export default FavoritesRouteBoundary
