import { useTranslation } from 'react-i18next'

import { I18N_POST_STATISTIC } from 'src/constants/i18n.constants'
import { dateOptions } from 'src/models/date.model'
import { PostDate } from 'src/models/post.model'
import { useDisplayDate } from 'src/utils/date.utils'

interface DraftInfoProps {
  createdDate: string;
}

const DraftInfo = ({ createdDate }: DraftInfoProps) => {
  const { t } = useTranslation()
  const displayDate = useDisplayDate()

  return (
    <div className={'bodySizeM'}>
      <span>
        {t(`${I18N_POST_STATISTIC}.${PostDate.createdAt}`)}
        &nbsp;
        {displayDate(createdDate, dateOptions.dateTimeFullYear)}
      </span>
    </div>
  )
}

export default DraftInfo
