const texts = [
  {
    id: 1,
    text: 'Register now and grow your business with us',
  },
  {
    id: 2,
    text: 'Register now and start saving',
  },
  {
    id: 3,
    text: 'Register now and care about the environment',
  },
  {
    id: 4,
    text: 'Register now and give things a second life',
  },
]

export default texts
