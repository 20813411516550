import { ICategoryStructure, MainCategories } from '../models/fields.model'
import fieldsConfig from './field-config'

const {
  categoryPath,
  description,
  priceWithRate,
  experience,
  place,
  location,
  condition,
  color,
} = fieldsConfig

const postFieldsStructure: Record<MainCategories, ICategoryStructure[]> = {
  services: [
    {
      section: 'location',
      fields: { location },
    },
    {
      section: 'generalInfo',
      fields: { categoryPath, place },
    },
    {
      section: 'details',
      fields: { experience, priceWithRate },
      fieldsFlatten: { experience, rate: priceWithRate.fields!.rate },
    },
    {
      section: 'description',
      fields: { description },
    },
  ],
  others: [
    {
      section: 'location',
      fields: { location },
    },
    {
      section: 'generalInfo',
      fields: { categoryPath, condition },
    },
    {
      section: 'characteristics',
      fields: { color },
    },
    {
      section: 'description',
      fields: { description },
    },
  ],
}

export default postFieldsStructure
