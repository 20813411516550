import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ONE_DAY, POST_EXPIRATION_TERM } from 'src/constants/general.constants'
import { DateStatusMap, PostDate, SinglePost } from 'src/models/post.model'

import { I18N_GLOBAL } from '../constants/i18n.constants'
import { dateOptions } from '../models/date.model'

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000

export const checkDateEqual = (a: Date, b: Date, options = dateOptions.dateFullMonth) => {
  const aTimestamp = a.toLocaleString('en', options)
  const bTimestamp = b.toLocaleString('en', options)

  return aTimestamp === bTimestamp
}

const timePassed = (date: Date) => Date.now() - date.getTime()

const checkIsToday = (date: Date) => checkDateEqual(new Date(), date)

const checkIsYesterday = (date: Date) => {
  const today = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)

  return checkDateEqual(yesterday, date)
}

const isPastYear = (date: Date) => {
  const today = new Date()

  return today.getFullYear() > date.getFullYear()
}

export const useDisplayDate = () => {
  const { i18n: { language } } = useTranslation()

  return useCallback((timestamp: string, options = dateOptions.dateFullMonth) => {
    const date = new Date(timestamp)

    return date.toLocaleString(language, options)
  }, [language])
}

export const useDisplayTime = () => {
  const displayDate = useDisplayDate()

  return useCallback((timestamp: string) => displayDate(timestamp, dateOptions.hourMinutes), [displayDate])
}

export const useDisplayDateLabel = () => {
  const { t } = useTranslation()
  const displayTime = useDisplayTime()

  return useCallback((label: 'today' | 'yesterday', timestamp: string, hasOnlyLabel = false) => {
    const time = displayTime(timestamp)

    const labelTranslated = t(`${I18N_GLOBAL}.${label}`)

    return hasOnlyLabel ? labelTranslated : `${labelTranslated} ${time}`
  }, [t, displayTime])
}

export const useDisplayDateFormatted = () => {
  const displayDate = useDisplayDate()
  const displayDateLabel = useDisplayDateLabel()

  return useCallback((
    timestamp: string,
    options: Intl.DateTimeFormatOptions,
    hasOnlyLabel = false,
  ) => {
    const date = new Date(timestamp)

    if (checkIsToday(date)) {
      return displayDateLabel('today', timestamp, hasOnlyLabel)
    }

    if (checkIsYesterday(date)) {
      return displayDateLabel('yesterday', timestamp, hasOnlyLabel)
    }

    return displayDate(timestamp, options)
  }, [displayDate, displayDateLabel])
}

export const useDisplayChatDate = (hasShortTime = false) => {
  const displayDate = useDisplayDate()
  const displayTime = useDisplayTime()
  const displayDateLabel = useDisplayDateLabel()

  return useCallback((timestamp: string, hasOnlyLabel = false) => {
    const date = new Date(timestamp)

    if (checkIsToday(date)) {
      if (hasShortTime) {
        return displayTime(timestamp)
      }

      return displayDateLabel('today', timestamp, hasOnlyLabel)
    }

    if (isPastYear(date)) {
      return displayDate(timestamp, dateOptions.dateNumMonth)
    }

    if (timePassed(date) < ONE_WEEK) {
      return displayDate(timestamp, dateOptions.weekDay)
    }

    return displayDate(timestamp, dateOptions.dayMonth)
  }, [displayDate, displayTime, displayDateLabel, hasShortTime])
}

export const getRemainingDays = (timeStamp: string) => (
  Math.ceil((new Date(timeStamp).getTime() - Date.now()) / ONE_DAY)
)

export const getRemainingDatePercentage = (timeStamp: string) => {
  const passedDays = POST_EXPIRATION_TERM - getRemainingDays(timeStamp)

  return Math.min(100, Math.max((passedDays / POST_EXPIRATION_TERM) * 100, 0))
}

export const getOfferTimestampByStatus = (
  offer: SinglePost,
) => {
  const offerDateField = DateStatusMap[offer.status]
  const offerTimeStamp = offer[offerDateField]

  if (!offerTimeStamp) {
    return offer[PostDate.createdAt]
  }

  return offerTimeStamp
}
