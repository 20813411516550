import React from 'react'

import { I18N_SMTH_WENT_WRONG } from 'src/constants/i18n.constants'

import EmptyStateMessage, { EmptyStateMessageType } from '../emptyStateMessage/EmptyStateMessage'

interface ISomethingWentWrongProps {
  className?: string;
}

const SomethingWentWrong = ({ className }: ISomethingWentWrongProps) => (
  <EmptyStateMessage
    icon='something-went-wrong'
    title={I18N_SMTH_WENT_WRONG}
    className={className}
    type={EmptyStateMessageType.vertical}
    text={`${I18N_SMTH_WENT_WRONG}.text`}
  />
)

export default SomethingWentWrong
