import React from 'react'
import { useLocation } from 'react-router-dom'

import { captureException } from 'src/utils/browser.utils'

import SomethingWentWrong from '../somethingWentWrong/SomethingWentWrong'

interface IErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  pathname?: string;
}

interface IErrorBoundaryState {
  hasError: boolean;
  errorClass?: string;
}

class ErrorBoundaryInternal extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorClass: error.name }
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error) {
    captureException(error)
  }

  componentDidUpdate(prevProps: IErrorBoundaryProps) {
    const isLocationChanged = this.props.pathname !== prevProps.pathname

    if (this.state.hasError && isLocationChanged) {
      this.setState({ hasError: false })
    }
  }

  render() {
    const { children, fallback } = this.props

    if (this.state.hasError) {
      if (fallback) {
        return fallback
      }

      return (
        <div className='flex-column-center h-100'>
          <SomethingWentWrong />
        </div>
      )
    }

    return children
  }
}

const ErrorBoundary = (props: IErrorBoundaryProps) => {
  const { pathname } = useLocation()

  return <ErrorBoundaryInternal {...props} pathname={pathname} />
}

export default ErrorBoundary
