import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_FOR_BUSINESS } from 'src/constants/i18n.constants'
import { FOR_BUSINESS_IMAGES_PATH } from 'src/constants/staticPages.constants'
import { useViewport } from 'src/contexts/viewportContext'

import styles from './features.module.scss'

interface ISingleFeature {
  feature: string;
}

const SingleFeature = ({ feature }: ISingleFeature) => {
  const { isDesktop } = useViewport()
  const image = isDesktop ? `${feature}Desktop` : feature

  return (
    <div className={classNames('static-block', styles.feature)}>
      <div className={styles.content}>
        <Heading
          level='h4'
          className={isDesktop ? 'label-24' : 'label-28'}
          label={`${I18N_FOR_BUSINESS}.card.title.${feature}`}
        />
        <Paragraph
          className={styles.subtitle}
          text={`${I18N_FOR_BUSINESS}.card.subtitle.${feature}`}
        />
        <Paragraph
          text={`${I18N_FOR_BUSINESS}.card.text.${feature}`}
          className={isDesktop ? 'body-text desktop-body-16' : 'bodySizeL'}
        />
      </div>
      <img
        alt={image}
        className={classNames(styles.cardImg)}
        src={`${FOR_BUSINESS_IMAGES_PATH}/features/${image}.png`}
      />
    </div>
  )
}

export default SingleFeature
