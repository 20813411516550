import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import ToggleOnOff from '../toggle/ToggleOnOff'

import styles from './largeToggle.module.scss'

interface LargeToggleProps {
  label: string;
  name: string;
  children?: JSX.Element;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
  supportingText?: string;
  className?: string;
}

const LargeToggle = ({
  label,
  name,
  children,
  onChange,
  checked,
  disabled = false,
  supportingText,
  className,
}: LargeToggleProps) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.main, className)}>
      <div className={styles.toggleLabel}>
        <div className='label-18'>
          {t(label)}
        </div>
      </div>
      <div className={styles.toggleDescription}>
        {supportingText && (
          <p className='bodySizeM'>
            {t(supportingText)}
          </p>
        )}
        {children}
      </div>
      <div className={classNames('bodySizeM', styles.toggleButton)}>
        <ToggleOnOff
          disabled={disabled}
          name={name}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default LargeToggle
