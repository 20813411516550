import classNames from 'classnames'
import { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import UserAvatar from 'src/components/avatar/UserAvatar'
import { InfoButtonSizes } from 'src/components/button/InfoButton/InfoButton'
import DateComponent from 'src/components/date/DateComponent'
import Heading from 'src/components/heading/Heading'
import { I18N_USER } from 'src/constants/i18n.constants'
import { useGetAuthorizedUserData } from 'src/hooks/user.hooks'
import { DateFormatType, dateOptions } from 'src/models/date.model'
import { AvatarSizes } from 'src/models/upload.model'
import { IUser } from 'src/models/user.model'
import BadgeList from 'src/routes/user/info/components/badge/BadgeList'
import { getUserBadges } from 'src/utils/user.utils'

import styles from './style.module.scss'

interface PostUserInfoProps {
  user: IUser;
}

interface IAvatarWrapperProps extends PropsWithChildren {
  url?: string;
}

const AvatarWrapper = ({ children, url }: IAvatarWrapperProps) => (url ? (
  <Link to={url}>
    {children}
  </Link>
) : (
  <>
    {children}
  </>
))

const PostUserInfo = ({ user }: PostUserInfoProps) => {
  const { t } = useTranslation()

  const badges = useMemo(() => getUserBadges(user), [user])

  const getUserData = useGetAuthorizedUserData()

  const { userName, userPageUrl } = getUserData(user)

  return (
    <div>
      <div className={styles.userData}>
        <AvatarWrapper url={userPageUrl}>
          <UserAvatar
            image={user.avatar}
            userId={user.id}
            firstLetter={user.name.charAt(0)}
            avatarSize={AvatarSizes.tiny}
          />
        </AvatarWrapper>
        <div>
          <AvatarWrapper url={userPageUrl}>
            <Heading
              label={userName}
              level='h6'
              className={classNames(styles.userName, 'desktop-h6 mobile-h6')}
            />
          </AvatarWrapper>
          {/* <Grade rating={user.rating} /> */}
        </div>
      </div>
      <div className='bodySizeM'>
        {/* <SubscribersLabel followers={user.followers} subscriptions={user.subscriptions} /> */}
        <div className={styles.registrationDate}>
          <span>
            {t(`${I18N_USER}.onYomeSince`)}
          </span>
          <DateComponent options={dateOptions.monthYear} isoTimestamp={user.createdAt} type={DateFormatType.noLabel} />
        </div>
      </div>
      { !!badges.length && (
        <div className={styles.userBadgeBlock}>
          <BadgeList badgeList={badges} badgeSize={InfoButtonSizes.small}/>
        </div>
      ) }
    </div>
  )
}

export default PostUserInfo
