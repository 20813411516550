import ComingSoonEmptyState from 'src/components/emptyStateMessage/ComingSoonEmptyState'
import { I18N_FOR_BUSINESS } from 'src/constants/i18n.constants'
import { Trans } from 'react-i18next'
import ProfileHeading from '../ProfileHeading'

const BusinessToolsRoute = () => (
  <div>
    <ProfileHeading>
      <Trans
        i18nKey={`${I18N_FOR_BUSINESS}.heading`}
        components={{ 1: <span /> }}
      >
      </Trans>
    </ProfileHeading>
    <ComingSoonEmptyState />
  </div>
)

export default BusinessToolsRoute
