import classNames from 'classnames'
import { memo, useContext } from 'react'
import { Link } from 'react-router-dom'

import ImageOrNoPhoto from 'src/components/imageOrNoPhoto/ImageOrNoPhoto'
import { generateIdWithPrefix, PrefixAction } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import PostListContext from 'src/contexts/postList.context'
import { useGetDisplayPrice } from 'src/hooks/price.hooks'
import NumberOfLinesEnum from 'src/models/cardHeading.model'
import { OfferStatus } from 'src/models/offerStatus.model'
import { CardInfo, PostListType, SinglePost } from 'src/models/post.model'
import { getThumbSmallUrl } from 'src/utils/images.utils'
import { checkIsInactiveView } from 'src/utils/post.utils'

import CardHeading from '../cardHeading/cardHeading'
import MyOfferCardStats from '../myOffers/components/myOfferCardStats/MyOfferCardStats'

import styles from './shortenOfferCard.module.scss'

export interface ShortenOfferCardProps {
  idPrefix: string;
  offer: SinglePost;
  cardInfo?: CardInfo;
  isLink?: boolean;
}

const ShortenOfferCard = ({
  idPrefix,
  offer,
  cardInfo = CardInfo.location,
  isLink = false,
}: ShortenOfferCardProps) => {
  const getDisplayPrice = useGetDisplayPrice()
  const { listType } = useContext(PostListContext)

  const isBluredPost = offer.status !== OfferStatus.active
  const isInactiveView = checkIsInactiveView(offer)
  const isDisabled = isInactiveView && listType !== PostListType.inactive

  const containerStyles = classNames(styles.container,
    { [styles.disabledContainer]: isInactiveView })

  const content = (
    <>
      <div className={classNames(styles.imageBlock, { semitransparent: isBluredPost })}>
        <ImageOrNoPhoto
          src={getThumbSmallUrl(offer.photos[0], offer.publicId)}
          alt={offer.title}
        />
      </div>
      <div className={styles.itemInfo}>
        <CardHeading
          offer={offer}
          level='h4'
          numberOfLines={NumberOfLinesEnum.ONE}
          isDisabled={isDisabled}
          className={classNames(
            styles.title,
            'desktop-h7 mobile-heading-subtitle',
          )}
        />
        <p className={classNames('heading desktop-h7 mobile-heading-subtitle',
          styles.price,
          { [styles.disabled]: isInactiveView })}
        >
          {getDisplayPrice(offer.price)}
        </p>
        {cardInfo === CardInfo.statistic ? (
          <MyOfferCardStats idPrefix={idPrefix} stats={offer.statistic} isOneRow={true} />
        ) : (
          <p className={classNames(styles.address, 'supportive-14')} title={offer.location?.address}>
            {offer.location?.city}
          </p>
        )}
      </div>
    </>
  )

  return isLink ? (
    <div className={containerStyles}>
      {content}
    </div>
  ) : (
    <Link
      id={generateIdWithPrefix(idPrefix, PrefixAction.Link, offer.slug)}
      to={YomeURL.offer(offer.category, offer.slug)}
      className={containerStyles}
    >
      {content}
    </Link>
  )
}

export default memo(ShortenOfferCard)
