import classNames from 'classnames'

import { useViewport } from 'src/contexts/viewportContext'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './skeletons.module.scss'

const OfferSkeleton = ({ isHorizontalView }: { isHorizontalView: boolean }) => {
  const { isDesktop } = useViewport()

  return (
    <div className={classNames(styles.wireframe, { [styles.horizontal]: isHorizontalView })}>
      <div className={styles.wireframeBody}>
        <span className={styles.skeletonLoaderGradient}/>
      </div>
      { isHorizontalView ? (
        <>
          <SvgIcon
            name={isDesktop ? 'horizontal-skeleton' : 'horizontal-mobile-skeleton'}
            className={classNames(styles.wireframeInfo, styles.hotizontalInfo)}
          />
          <SvgIcon name='avatar-skeleton' className={classNames(styles.wireframeInfo, 'heading mobile-hide')} />
        </>
      ) : (
        <SvgIcon name='info-skeleton' className={classNames(styles.wireframeInfo, styles.tiles)} />
      )}
    </div>
  )
}

export default OfferSkeleton
