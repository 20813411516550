import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import { useViewport } from 'src/contexts/viewportContext'
import PrivateRoute from 'src/routes/PrivateRoute'
import { CHAT_ID_SEARCH_PARAM, useSubscribeAndGetChatData } from 'src/services/messenger.service'

import ChatsPanelAndDialog from './ChatsPanelAndDialog'
import ChatsPanelOrDialog from './ChatsPanelOrDialog'

import styles from './styles.module.scss'

const MessengerPrivateRoute = () => {
  const { chats, currentChat, mutateCurrentChat } = useSubscribeAndGetChatData()
  const { isDesktop } = useViewport()

  const [searchParams] = useSearchParams()
  const chatId = searchParams.get(CHAT_ID_SEARCH_PARAM)
  // set initial currentChat
  useEffect(() => {
    // if user came from post page with new dialog
    if (chats && chatId) {
      const chatWithGivenId = chats.find((chat) => chat.id === chatId)

      if (chatWithGivenId) {
        mutateCurrentChat(chatWithGivenId)
      }
    }

    return () => {
      mutateCurrentChat(undefined)
    }
  }, [chats, mutateCurrentChat, chatId])

  return (
    <PrivateRoute>
      <div className={styles.main}>
        <div className={styles.messengerWrapper}>
          {isDesktop ? (
            <ChatsPanelAndDialog currentChat={currentChat} />
          ) : (
            <ChatsPanelOrDialog currentChat={currentChat} />
          )}
        </div>
        <div className={styles.showcaseBlock}>
          { isDesktop && <ShowcaseArea /> }
        </div>
      </div>
    </PrivateRoute>
  )
}

export default MessengerPrivateRoute
