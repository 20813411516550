import { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage, { EmptyStateMessageType } from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_GLOBAL_NOT_FOUND } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { usePageTitle } from 'src/utils/hooks.utils'
import { getStaticPageMetaTitle } from 'src/utils/meta.utils'

const NotFoundRoute = () => {
  usePageTitle(getStaticPageMetaTitle('*'))

  const buttonProps = {
    id: ID.notFoundLinkToMainPage,
    buttonType: RegularButtonTypes.main,
    label: `${I18N_GLOBAL_NOT_FOUND}.button`,
    linkTo: YomeURL.mainPage,
  }

  return (
    <EmptyStateMessage
      title={`${I18N_GLOBAL_NOT_FOUND}.title`}
      text={`${I18N_GLOBAL_NOT_FOUND}.description`}
      type={EmptyStateMessageType.horizontal}
      icon='error404'
      button={buttonProps}
    />
  )
}

export default NotFoundRoute
