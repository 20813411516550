import classNames from 'classnames'
import { memo } from 'react'

import { ILocation } from 'src/models/location.model'

import styles from './offerAddress.module.scss'

interface IOfferAddress {
  location: ILocation;
}

const OfferAddress: React.FC<IOfferAddress> = ({ location }) => (
  <span className={classNames('label-14', styles.main)}>
    {location?.address}
  </span>
)

export default memo(OfferAddress)
