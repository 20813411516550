import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'

import { SinglePost } from './post.model'
import { SortPostListTypes } from './sort.model'

export type SearchBarFields = {
  category?: string;
  searchContent?: string;
  city?: string;
  sortBy?: string;
  isOriginalContent?: string;
}

export interface IFilterValues extends SearchBarFields {
  rate?: string;
  negotiable?: boolean;
  priceMin?: number;
  priceMax?: number;
  place?: string[];
  color?: string[];
  rating?: boolean;
  condition?: string[];
  descriptionSearch?: string[];
}

export interface ISuggestions {
  categories: string[];
  searchRequests: string[];
}

export interface ICitySettings {
  city: string;
  distance?: number;
  neighborhood?: string;
  station?: string;
}

export type TSingleFilterValues = IFilterValues[keyof IFilterValues]
export type TSingleFilterKey = keyof IFilterValues

export type TFieldKey = keyof IFilterValues | typeof PRICE_CHIP_NAME

export const PRICE_FIELDS = [
  'rate', 'negotiable',
  'price', 'priceMin', 'priceMax',
  'priceWithNegotiable', 'priceWithRate',
]
export const PRICE_CHIP_NAME = 'price'
export const ARRAY_FILTERS = [
  'place',
  'color',
  'condition',
  'descriptionSearch',
]

export const MAX_SERCH_WORDS = 3

export const CATEGORY_QPARAM = 'category'
export const SEARCH_CONTENT_QPARAM = 'searchContent'
export const SORT_QPARAM = 'sortBy'
export const CITY_QPARAM = 'city'
export const ORIGINAL_QPARAM = 'isOriginalContent'

export const SEARCH_BAR_FIELDS = [
  CATEGORY_QPARAM,
  SEARCH_CONTENT_QPARAM,
  SORT_QPARAM,
  ORIGINAL_QPARAM,
]

export interface ISortedCityPosts {
  fromCity: SinglePost[];
  other: SinglePost[];
  savedSearchId?: string;
  hasMore: boolean;
}

export interface ISavedSearch {
  _id: string;
  user: string;
  searchParams: IFilterValues;
  hashSum: string;
  isDeleted: boolean;
}

export interface ISaveSeacrhResponse {
  savedSearchId: string | undefined;
}

export const LOCAL_STORAGE_SEARCH_LOCATION = 'searchLocation'

export const BASE_FREE_OFFERS_SEARCH_PARAMS = {
  [CATEGORY_QPARAM]: ANY_CATEGORY,
  [SORT_QPARAM]: SortPostListTypes.newest,
  priceMax: '0',
}
