import { ALL_CATEGORIES, ANY_CATEGORY, SERVICES } from '../category-config/categoriesTree'
import { ICategory } from '../models/category.model'
import { MainCategories } from '../models/fields.model'

export const getFirstSubCategories = ({ title, items }: ICategory, initialPath: string[]): string[] => {
  const withTitle = initialPath.concat(title)

  return items?.length ? getFirstSubCategories(items[0], withTitle) : withTitle
}

// pathToCategory includes target category
export const getCategoryObject = (pathToCategory: string[]) => {
  let curLevelCategories: ICategory[] | undefined = ALL_CATEGORIES
  let categoryObject: ICategory | undefined

  pathToCategory.forEach((curCategory) => {
    if (curLevelCategories) {
      categoryObject = curLevelCategories.find((subcat) => (subcat.title === curCategory))
      curLevelCategories = categoryObject?.items
    }
  })

  return categoryObject
}

export const getCategorySiblings = (pathToCategory: string[]) => {
  const categoryObj = getCategoryObject(pathToCategory)
  const siblingObjects = pathToCategory.length ? categoryObj?.items : ALL_CATEGORIES

  if (!siblingObjects) {
    return []
  }

  return siblingObjects.map((subcategory) => subcategory.title)
}

const checkCategoryInChildren = (valueToFind: string, category: ICategory): boolean => {
  if (!category.items) {
    return false
  }

  // here we can stop traverse the entire tree
  if ((category.title === valueToFind) || category.items.map((item) => item.title).includes(valueToFind)) {
    return true
  }

  return category.items.some((subcategory) => checkCategoryInChildren(valueToFind, subcategory))
}

export const getMainCategoryForChild = (childTitle: string | null) => {
  if (!childTitle || childTitle === ANY_CATEGORY) {
    return MainCategories.others
  }

  if (checkCategoryInChildren(childTitle, SERVICES)) {
    return MainCategories.services
  }

  return MainCategories.others
}

const categoryDFS = (valueToFind: string, curSiblings: ICategory[] | undefined): string[] | null => {
  if (!curSiblings) {
    return null
  }

  const hasValueInCurrentLayer = curSiblings.map((item) => item.title).includes(valueToFind)

  if (hasValueInCurrentLayer) {
    return [valueToFind]
  }

  for (let i = 0; i < curSiblings.length; i += 1) {
    const sibling = curSiblings[i]
    const path = categoryDFS(valueToFind, sibling?.items)

    if (path) {
      path.push(sibling.title)

      return path
    }
  }

  return null
}

// current category is included in path
export const getPathToCurrentCategory = (category: string) => {
  if (category === ANY_CATEGORY) {
    return [category]
  }

  const path = categoryDFS(category, ALL_CATEGORIES) // returns reversed path

  return path?.reverse() || []
}

export const getCategoryChildren = (category: string) => {
  if (category === ANY_CATEGORY) {
    return ALL_CATEGORIES.map((catObj) => catObj.title)
  }

  const pathToCategory = getPathToCurrentCategory(category)
  const categoryObj = getCategoryObject(pathToCategory)

  return categoryObj?.items?.map((item) => item.title)
}

export const getCategoryChildrenFullDepth = (categoryObj: ICategory): string[] => {
  if (!categoryObj.items) {
    return []
  }

  const currentLvlChildren: string[][] = categoryObj.items.map((item) => {
    const prevLvlChildren = getCategoryChildrenFullDepth(item)
    prevLvlChildren.push(item.title)

    return prevLvlChildren
  })

  return currentLvlChildren.flat()
}
