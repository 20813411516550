import classNames from 'classnames'

import { AvatarSizeMap, AvatarSizes, IUploadImageResponse } from 'src/models/upload.model'
import { getAvatar } from 'src/utils/images.utils'

import styles from './avatar.module.scss'

type UserAvatarProps = {
  userId: string;
  avatarSize: AvatarSizes;
  image?: IUploadImageResponse;
  firstLetter?: string;
  className?: string;
}

const UserAvatar = ({ userId, avatarSize, image, firstLetter, className }: UserAvatarProps) => {
  if (!image?.filename) {
    return (
      <div className={classNames('body-font-style', styles.emptyAvatar, styles[avatarSize], className)} >
        {firstLetter?.toUpperCase()}
      </div>
    )
  }

  const imageSrc = getAvatar(userId, AvatarSizeMap[avatarSize], image)

  return (
    <div
      style={{ backgroundImage: `url(${imageSrc})` }}
      className={classNames(styles.defaultAvatar, styles[avatarSize], className)}
    />
  )
}

export default UserAvatar
