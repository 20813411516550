import React from 'react'

import { emptyFunction } from 'src/constants/function.constants'
import { ISearchLocation } from 'src/models/location.model'

export interface ISearchLocationContext {
  setSearchLocation: (value: ISearchLocation) => void;
  searchLocation: ISearchLocation | undefined;
}

const SearchLocationContext = React.createContext<ISearchLocationContext>({
  searchLocation: undefined,
  setSearchLocation: emptyFunction,
})

export default SearchLocationContext
