import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_CATEGORIES, I18N_FOR_BUSINESS } from 'src/constants/i18n.constants'

import { FOR_BUSINESS_CATEGORIES, FOR_BUSINESS_IMAGES_PATH } from 'src/constants/staticPages.constants'

import classNames from 'classnames'
import SectionHeader from '../sectionHeader/SectionHeader'
import styles from './categories.module.scss'

const Categories = () => (
  <section className={styles.main}>
    <SectionHeader
      label={`${I18N_CATEGORIES}.title`}
      text={`${I18N_CATEGORIES}.subtitle`}
    />
    <ul className={styles.list}>
      {FOR_BUSINESS_CATEGORIES.map((category) => (
        <li key={category} className={classNames(styles.item, 'static-block')}>
          <img className='static-block-img' src={`${FOR_BUSINESS_IMAGES_PATH}/categories/${category}.png`} />
          <div className={styles.itemContent}>
            <Heading level='h6' className='label-28' label={`${I18N_CATEGORIES}.${category}`} />
            <Paragraph className='bodySizeM' text={`${I18N_FOR_BUSINESS}.categories.${category}.text`} />
          </div>
        </li>
      ))}
    </ul>

  </section>
)

export default Categories
