import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_POST_STATISTIC } from 'src/constants/i18n.constants'
import { DateStatusMap, IPropsWithOffer } from 'src/models/post.model'
import { getOfferTimestampByStatus, useDisplayDate } from 'src/utils/date.utils'

import styles from './styles.module.scss'

const OfferStatistics = ({ offer }: IPropsWithOffer) => {
  const { t } = useTranslation()
  const displayDate = useDisplayDate()

  const dateFieldName = DateStatusMap[offer.status]

  const statisticsDate = getOfferTimestampByStatus(offer).toString()

  const noNewMessagesLabel = offer.statistic.connectionsNumber || t(`${I18N_POST_STATISTIC}.noNewMessages`)

  const statisticData = [
    {
      i18key: 'views',
      value: offer.statistic.viewsNumber,
      icon: 'eye-on',
    },
    {
      i18key: 'likes',
      value: offer.statistic.likesNumber,
      icon: 'heart',
    },
    {
      i18key: 'newMessage',
      value: noNewMessagesLabel,
      icon: 'message',
    },
    {
      i18key: dateFieldName,
      value: displayDate(statisticsDate),
    },
  ]

  return (
    <div className={styles.statisticsSection}>
      <div className={'label-18'}>
        {t(`${I18N_POST_STATISTIC}.title`)}
      </div>
      {statisticData.map((singleLine) => (
        <div key={singleLine.i18key} className={styles.statisticLine}>
          <span className={classNames('supportive-14', styles.statisticKey)}>
            {t(`${I18N_POST_STATISTIC}.${singleLine.i18key}`)}
          </span>
          <span className={styles.statisticValue}>
            {singleLine.icon && <SvgIcon name={singleLine.icon} size={16}/>}
            {singleLine.value}
          </span>
        </div>
      ))}
    </div>
  )
}

export default memo(OfferStatistics)
