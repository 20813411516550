import { memo } from 'react'
import { useFormState } from 'react-final-form'

import { TButtonType } from 'src/models/form.model'

import IconButton from '../button/IconButton'
import RegularButton, { RegularButtonTypes } from '../button/RegularButton'

type FormButtonProps = {
  id: string;
  linkTo?: string;
  buttonType?: RegularButtonTypes | 'icon';
  label?: string;
  disabled?: boolean;
  className?: string;
}

const FormButton = ({
  id,
  linkTo,
  disabled,
  className,
  label = '',
  buttonType = RegularButtonTypes.main,
}: FormButtonProps) => {
  const { submitting, validating, dirty } = useFormState()

  const title = label || 'global.submit'
  const type: TButtonType = 'submit'
  const isLoading = submitting || validating
  const isDisabled = disabled || !dirty
  const buttonProps = { linkTo, id, submitting: isLoading, disabled: isLoading || isDisabled, type }

  if (buttonType === 'icon') {
    return <IconButton {...buttonProps} iconName={label} />
  }

  return (
    <RegularButton
      {...buttonProps}
      buttonType={buttonType}
      label={title}
      className={className}
    />
  )
}

export default memo(FormButton)
