import classNames from 'classnames'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton, FormCheckbox, FormRadio, FormTextArea } from 'src/components/form'
import InfoModal from 'src/components/modal/infoModal/InfoModal'
import { I18N_GLOBAL, I18N_MESSENGER_REPORT_MODAL, I18N_REPORT_MODAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { IReportFormProps, REPORT_USER_REASON_MAP } from 'src/models/report.model'

import styles from './reportForms.module.scss'

const ReportUserForm = ({ handleClose }: IReportFormProps) => {
  const { isDesktop } = useViewport()

  const footerButtons = (
    <div className={styles.buttonRow}>
      <FormButton
        id={ID.reportUserModalSubmitButton}
        label={`${I18N_REPORT_MODAL}.submitButton`}
        buttonType={RegularButtonTypes.blackNoBorder}
      />
      <RegularButton
        id={ID.reportUserModalCancelButton}
        label={`${I18N_GLOBAL}.cancel`}
        buttonType={RegularButtonTypes.transparentWithBorder}
        onClick={handleClose}
      />
    </div>
  )

  return (
    <InfoModal
      title={`${I18N_MESSENGER_REPORT_MODAL}.title`}
      modalFooter={footerButtons}
      handleClose={handleClose}
    >
      <FormRadio
        name='reason'
        options={REPORT_USER_REASON_MAP}
        className={classNames(styles.formRadio, { [styles.twoColumnFormRadio]: isDesktop })}
        id={ID.reportUserModalInputReason}
      />
      <FormTextArea
        name='comment'
        id={ID.reportUserModalInputComment}
        placeholder={`${I18N_REPORT_MODAL}.comment.placeholder`}
        fieldWrapperProps={{
          name: 'comment',
          info: `${I18N_REPORT_MODAL}.comment.info`,
        }}
        autoFocus
      />
      <FormCheckbox
        name={'isAllowedToRead'}
        label={`${I18N_MESSENGER_REPORT_MODAL}.isAllowedToRead`}
        supportingText={`${I18N_MESSENGER_REPORT_MODAL}.isAllowedToRead.supportingText`}
      />
    </InfoModal>
  )
}

export default ReportUserForm
