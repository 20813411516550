import { memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import useSWR from 'swr'

import { INTERNAL_SERVER_ERROR_CODE } from 'src/constants/i18n.constants'
import { captureException } from 'src/utils/browser.utils'

export interface ISWRErrorNotificationProps {
  children: ReactNode;
  swrKey: string;
  errorMessage?: string;
}

const SWRErrorNotification = ({
  children,
  swrKey,
  errorMessage,
}: ISWRErrorNotificationProps) => {
  const { error } = useSWR(swrKey)
  const { t } = useTranslation()

  if (error) {
    captureException(error)
    toast.error(t(errorMessage ?? INTERNAL_SERVER_ERROR_CODE))
  }

  return (
    <>
      {children}
    </>
  )
}

export default memo(SWRErrorNotification)
