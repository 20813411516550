import { memo } from 'react'
import classNames from 'classnames'
import { FreeMode, Navigation, Thumbs, Swiper as SwiperInstance } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

import ImageOrNoPhoto from 'src/components/imageOrNoPhoto/ImageOrNoPhoto'

interface IThumbsSwiperProps {
  thumbPhotos: (string | null)[];
  title: string;
  isInactiveView: boolean;
  onSwiper: (swiper: SwiperInstance) => void;
  isFullscreen?: boolean;
}

const ThumbsSwiper = (
  { thumbPhotos, title, isInactiveView, onSwiper, isFullscreen }: IThumbsSwiperProps,
) => (
  <Swiper
    spaceBetween={8}
    slidesPerView={10}
    onSwiper={onSwiper}
    className={classNames('offer-page-thumbs-slider', { 'fullscreen-thumbs-slider': isFullscreen })}
    modules={[FreeMode, Navigation, Thumbs]}
    freeMode
    watchSlidesProgress
  >
    {thumbPhotos?.map((url) => (
      <SwiperSlide key={url}>
        <ImageOrNoPhoto src={url} alt={title} className={classNames({ semitransparent: isInactiveView })}/>
      </SwiperSlide>
    ))}
  </Swiper>
)

export default memo(ThumbsSwiper)
