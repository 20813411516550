import React, { useRef } from 'react'
import { Field, useForm } from 'react-final-form'
import { useBaseHeights, useOnClickOutside } from 'src/utils/hooks.utils'

import { MAX_CATEGORY_SEARCH_LEVELS } from 'src/utils/search.utils'
import { getLevelCategories } from 'src/utils/category.utils'
import { useSelectedCategoryPath } from 'src/hooks/categories.hooks'
import CategoryDropdownLevel from './CategoryDropdownLevel'

import styles from './categorySearchDropdown.module.scss'

interface ICategorySearchDropdownProps {
  name: string;
  toggleDropdown: () => void;
  isDropdownVisible: boolean;
  buttonRef: React.MutableRefObject<HTMLElement | null>;
}

const CategorySearchDropdown = (
  { name, toggleDropdown, isDropdownVisible, buttonRef }: ICategorySearchDropdownProps,
) => {
  const { change, submit } = useForm()
  const { selectedPath, setSelectedPath } = useSelectedCategoryPath()

  const handleItemClick = (title: string) => {
    change(name, [title])
    submit()
    toggleDropdown()
  }

  const dropdownRef = useRef<HTMLDivElement | null>(null)

  useOnClickOutside([dropdownRef, buttonRef], toggleDropdown, isDropdownVisible)

  const { rootHeight, headerHeight, searchBarHeight } = useBaseHeights()
  const totHeight = rootHeight - searchBarHeight - headerHeight + 5

  return (
    <Field name={name}>
      {() => (
        <div className={styles.overlay} style={{ height: totHeight }}>
          <div className={styles.dropdownWrapper} ref={dropdownRef}>
            {selectedPath.map((_, index) => {
              const { currentLevelPath, parent, categories } = getLevelCategories(index, selectedPath)

              if (!categories.length || currentLevelPath.length > MAX_CATEGORY_SEARCH_LEVELS) {
                return null
              }

              // sort each level list so items with children were first
              categories.sort((a, b) => {
                if (a.items?.length && !b.items?.length) {
                  return -1
                }

                return 0
              })

              return (
                <CategoryDropdownLevel
                  key={parent}
                  items={categories}
                  parent={parent}
                  path={currentLevelPath}
                  submitCategory={handleItemClick}
                  openSubcategory={setSelectedPath}
                  active={selectedPath[index + 1]}
                  hasIcon={currentLevelPath.length < 3}
                />
              )
            })}
          </div>
        </div>
      )}
    </Field>
  )
}

export default CategorySearchDropdown
