import { memo, ReactNode } from 'react'
import useSWR from 'swr'

import { captureException } from 'src/utils/browser.utils'

export interface SWRErrorSkipProps {
  children: ReactNode;
  swrKey: string;
}

const SWRLogErrorAndContinue = ({
  children,
  swrKey,
}: SWRErrorSkipProps) => {
  const { error } = useSWR(swrKey)

  if (error) {
    captureException(error)
  }

  return (
    <>
      {children}
    </>
  )
}

export default memo(SWRLogErrorAndContinue)
