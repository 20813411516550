import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

const initialVallues = {
  width: window.innerWidth,
}

export const BREAKPOINT_MOBILE = 575
export const BREAKPOINT_DESKTOP = 1280

const ViewportContext = createContext(initialVallues)

export const ViewportProvider = ({ children }: { children: ReactNode }) => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <ViewportContext.Provider value={{ width }}>
      {children}
    </ViewportContext.Provider>
  )
}

export const useViewport = () => {
  const { width } = useContext(ViewportContext)

  const isMobile = width < BREAKPOINT_MOBILE
  const isTablet = width >= BREAKPOINT_MOBILE && width < BREAKPOINT_DESKTOP
  const isDesktop = width >= BREAKPOINT_DESKTOP

  return { isMobile, isTablet, isDesktop }
}
