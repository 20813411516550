import { IChat } from 'src/models/messenger.model'

import { I18N_POST_CHAT } from '../constants/i18n.constants'
import { quickMessagePromptsMap } from '../constants/prompts.constants'
import { MainCategories } from '../yome-categories-fields-config/models/fields.model'

// eslint-disable-next-line import/prefer-default-export
export const getMessagePrompts = (mainCategory: MainCategories) => {
  const prompts = quickMessagePromptsMap[mainCategory]

  return prompts.map((value) => `${I18N_POST_CHAT}.prompt.${value}`)
}

export const getFilteredChats = (chats: IChat[], searchValue: string = '') => {
  const hasSearchValue = (chatProperty: string) => chatProperty.toLowerCase().includes(searchValue.toLowerCase())

  return chats.filter(({ offer, lastMessage, receiverInfo }) => {
    const isInTitle = hasSearchValue(offer.title)
    const isInUserName = hasSearchValue(receiverInfo.name)
    const isInLastMessage = lastMessage ? hasSearchValue(lastMessage) : false

    return isInTitle || isInLastMessage || isInUserName
  })
}
