import React from 'react'
import { useForm, useFormState } from 'react-final-form'

import { FormSearch } from 'src/components/form'

import styles from './styles.module.scss'

interface ISearchFieldProps {
  id: string;
  name: string;
  placeholder?: string;
}

const ChatFilterForm = React.memo(({
  id,
  name,
  placeholder = '',
}: ISearchFieldProps) => {
  const { values } = useFormState()
  const { change } = useForm()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    change(name, value)
  }

  return (
    <div className={styles.filterContainer}>
      <div className={styles.input}>
        <FormSearch
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          value={values[name]}
          hasRightIcon
        />
      </div>
      {/* <ChatFilterDropdown /> */}
    </div>
  )
})

export default ChatFilterForm
