import { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_OFFER_CARD_FAVORITE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { IStackOfferProps } from 'src/models/post.model'

import CardStack from './CardStack'

const FavoriteCardStack = ({
  stackInfo,
  stackEndpoint,
}: IStackOfferProps) => {
  const { items, hasMore } = stackInfo

  const moreButton = hasMore ? {
    id: ID.favoriteCardStackLinkToFavorite,
    buttonType: RegularButtonTypes.transparentWithBorder,
    linkTo: YomeURL.favorites,
    label: `${I18N_OFFER_CARD_FAVORITE}.button`,
  } : undefined

  return (
    <CardStack
      idOfferPrefix={ID.favoriteCardStackPrefixOffer}
      cardStackLabel={`${I18N_OFFER_CARD_FAVORITE}.label`}
      offerList={items}
      offerListKey={stackEndpoint}
      moreButton={moreButton}
      hasAllFavorite={true}
    />
  )
}

export default FavoriteCardStack
