import { FormApi } from 'final-form'
import { memo } from 'react'
import { Form } from 'react-final-form'

import ChatInput from 'src/components/chatInput/ChatInput'
import { ContentType, IChat, ISendChatMessage } from 'src/models/messenger.model'
import { ClientToServerEvents, getSocket } from 'src/services/messenger.service'

import styles from './chatMessageForm.module.scss'

interface MessageFormProps {
  chat: IChat;
}

const ChatMessageForm = ({ chat } : MessageFormProps) => {
  const socket = getSocket()

  const onSubmitSendMessage = async (data: { message: string }, form: FormApi<{ message: string }, string>) => {
    const messageToSend: ISendChatMessage = {
      chatId: chat.id,
      contentType: ContentType.TEXT,
      content: data.message,
    }
    socket.emit(ClientToServerEvents.AddMessage, messageToSend, chat.id)
    form.reset()
  }

  return (
    <Form
      onSubmit={onSubmitSendMessage}
      render={function MessageFormRender({ handleSubmit }) {
        return (
          <div className={styles.main}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <ChatInput hasUploadButton hasScrollOnBlur hasAutoFocus />
            </form>
          </div>
        )
      }}
    />
  )
}

export default memo(ChatMessageForm)
