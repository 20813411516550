import PostListLoader from 'src/components/skeletons/PostListLoader'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import SWRkeys from 'src/constants/swr.constants'
import { ILimitedSingleOfferList, OfferListProps } from 'src/models/post.model'
import { SORT_QPARAM } from 'src/models/search.model'
import { SortPostListTypes } from 'src/models/sort.model'
import { assertData } from 'src/utils/error.utils'

import NoPosts, { NoPostsType } from '../../../../components/noPosts/NoPosts'
import PostList from '../../../../components/postList/PostList'
import { ID } from '../../../../constants/id.constants'
import { useGetPostList } from '../../../../services/post.service'

const FavoritesList = ({ offerListEndpoint }: OfferListProps) => {
  const {
    data: favoriteOffers,
    isValidating: isPostValidating,
  } = useGetPostList<ILimitedSingleOfferList>(offerListEndpoint)

  if (isPostValidating) {
    return (
      <PostListLoader />
    )
  }

  assertData(favoriteOffers)

  const { items } = favoriteOffers

  if (items.length === 0) {
    return <NoPosts type={NoPostsType.favorite} />
  }

  return (
    <PostList
      idPostPrefix={ID.favoritesPagePrefixPost}
      posts={items}
      postListKey={offerListEndpoint}
      hasAllFavorite={true}
    />
  )
}

interface FavoritesListProps {
  sortBy: SortPostListTypes;
}

const FavoritesListBoundary = ({ sortBy }: FavoritesListProps) => {
  const params = { [SORT_QPARAM]: sortBy }
  const offerListEndpoint = SWRkeys.favoriteList(params)

  return (
    <SWRErrorBoundary swrKey={offerListEndpoint}>
      <FavoritesList offerListEndpoint={offerListEndpoint}/>
    </SWRErrorBoundary>
  )
}

export default FavoritesListBoundary
