import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { toast } from 'react-toastify'
import useSWR from 'swr'

import ErrorBoundary from 'src/components/errorBoundary/ErrorBoundary'
import Footer from 'src/components/footer/Footer'
import FooterSmall from 'src/components/footer/FooterSmall'
import Header from 'src/components/header/Header'
import ContextModal from 'src/components/modal/ContextModal'
import CookieModal from 'src/components/modal/cookieModal/CookieModal'
import ModalByHashOrQuery from 'src/components/modal/ModalByHashOrQuery'
import SWRkeys from 'src/constants/swr.constants'
import { ActiveTabProvider } from 'src/contexts/activeTabContext'
import { ContextModalsProvider } from 'src/contexts/openModalContext'
import { UserPreferencesProvider } from 'src/contexts/userPreferencesContext'
import { useViewport, ViewportProvider } from 'src/contexts/viewportContext'
import { LANG_COOKIE_NAME } from 'src/models/language.model'
import { getCookie, setCookie } from 'src/services/cookie.service'
import { useCurrentUser } from 'src/services/currentUser.service'
import * as messengerService from 'src/services/messenger.service'
import { captureException } from 'src/utils/browser.utils'
import { SocketConnectionError } from 'src/utils/error.utils'
import { useCheckIsPage } from 'src/utils/hooks.utils'
import { getInitialLanguage } from 'src/utils/language.utils'

import styles from './layout.module.scss'

interface IBaseLayout {
  hasSmallFooter?: boolean;
  isFooterNarrow?: boolean;
}

const BaseLayout: React.FC<IBaseLayout> = ({ hasSmallFooter, isFooterNarrow = false }) => {
  const { isDesktop } = useViewport()
  const { isChatPage, isProfileSettingsPage } = useCheckIsPage()
  const FooterVisible = hasSmallFooter ? <FooterSmall isNarrow={isFooterNarrow} /> : <Footer />
  const isFooterHide = !isDesktop && (isChatPage || isProfileSettingsPage)

  messengerService.useSubscribeOnUnreadChatsStatus()
  messengerService.useSubscribeOnIncomingMessage()
  messengerService.useSubscribeOnVisibilityChange()

  return (
    <>
      <Header />
      <div className={classNames(styles.base, hasSmallFooter && styles.smallFooter)}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </div>
      {isFooterHide ? null : FooterVisible}
      <ModalByHashOrQuery />
      <ContextModal />
      <CookieModal />
    </>
  )
}

const BaseLayoutBoundary = (props: IBaseLayout) => {
  const { t } = useTranslation()
  const { data: currentUser } = useCurrentUser()
  const { data: socketConnectionError } = useSWR<SocketConnectionError>(SWRkeys.socketConnectionError)

  useEffect(() => {
    const langCookie = getCookie(LANG_COOKIE_NAME)

    if (!langCookie) {
      const lang = getInitialLanguage()
      setCookie(LANG_COOKIE_NAME, lang)
    }
  }, [])

  useEffect(() => {
    if (socketConnectionError) {
      captureException(socketConnectionError)

      toast.error(t(messengerService.SOCKET_CONNECTION_ERROR), {
        toastId: messengerService.SOCKET_CONNECTION_ERROR,
        pauseOnFocusLoss: false,
      })
    }
  }, [t, socketConnectionError])

  React.useEffect(() => {
    if (currentUser) {
      if (!socketConnectionError) {
        messengerService.connectSocket()
      }
    } else {
      messengerService.disconnectSocket()
    }
  }, [currentUser, socketConnectionError])

  return (
    <ActiveTabProvider>
      <ViewportProvider>
        <UserPreferencesProvider>
          <ContextModalsProvider>
            <BaseLayout {...props} />
          </ContextModalsProvider>
        </UserPreferencesProvider>
      </ViewportProvider>
    </ActiveTabProvider>
  )
}

export default BaseLayoutBoundary
