import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_ABOUT_US_BENEFITS } from 'src/constants/i18n.constants'
import { BENEFITS } from 'src/constants/staticPages.constants'
import { useViewport } from 'src/contexts/viewportContext'

import styles from './benefits.module.scss'

const Benefits = () => {
  const { isDesktop } = useViewport()

  return (
    <div className={classNames(styles.main, 'static-block')}>
      <Heading
        level='h4'
        label={`${I18N_ABOUT_US_BENEFITS}.label`}
        className={'static-h4'}
      />
      <Heading
        level='h2'
        label={`${I18N_ABOUT_US_BENEFITS}.title`}
        className={styles.title}
      />
      <ul className={styles.list}>
        {BENEFITS.map((benefit) => (
          <li key={benefit} className={styles.offer}>
            <SvgIcon name='check-tick' size={isDesktop ? 48 : 32}/>
            <Paragraph
              text={`${I18N_ABOUT_US_BENEFITS}.${benefit}`}
              className={styles.offerText}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Benefits
