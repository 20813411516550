import { pick } from 'lodash-es'

import { IOfferData, PostFormFields } from '../models/post.model'
import { getFieldsForCategory } from '../yome-categories-fields-config/utils/fieldConfig.utils'

export const cleanOfferFormData = (
  { price, priceInput, ...formFields }: PostFormFields,
  partIndex?: number | undefined,
  isDraft = false,
) => {
  const fieldsForCategory = getFieldsForCategory(formFields.categoryPath[0], partIndex)
  const fields = isDraft ? fieldsForCategory.concat(['publicId', 'slug']) : fieldsForCategory

  return pick({
    ...formFields,
    price: priceInput || price,
  }, fields)
}

// complexFieldName - field name which consists '.' (exmpl 'priceWithRate.rate')
// function change complex field value in form values (like price 'rate' in offer form)
export const changeComplexValue = (complexFieldName: string, fieldValue: any, formValues: Record<string, any>) => {
  const [parentKey, childKey] = complexFieldName.split('.')
  const parentValue = (
    parentKey in formValues ? formValues[parentKey as keyof PostFormFields] : {}
  ) as Record<string, any>
  parentValue[childKey] = fieldValue

  return formValues
}

export const prepareFormOfferData = ({
  price,
  location,
  ...other
}: IOfferData): PostFormFields => ({
  ...other,
  price: price?.toString(),
  locationAddress: location?.address,
  locationPosition: location?.position,
})
