import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { I18N_LEGAL_SAFETY } from 'src/constants/i18n.constants'
import { SAFETY_STICKER_SIZE } from 'src/constants/staticPages.constants'

import styles from '../../safety.module.scss'

interface IFeature {
  item: string;
}

const Feature = ({ item }: IFeature) => (
  <li key={item} className={styles.item}>
    <SvgIcon name={`safety/${item}`} size={SAFETY_STICKER_SIZE} className={styles.icon}/>
    <Heading level='h2' label={`${I18N_LEGAL_SAFETY}.${item}`} className={styles.itemHeading}/>
    <Paragraph text={`${I18N_LEGAL_SAFETY}.${item}.text`} className={styles.itemText}/>
  </li>
)

export default Feature
