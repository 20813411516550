import { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_OFFER_LAST_PUBLICATION } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { CardInfo, IStackOfferProps } from 'src/models/post.model'

import CardStack from './CardStack'

const LastPublicationCardStack = ({
  stackInfo,
  stackEndpoint,
}: IStackOfferProps) => {
  const { items, hasMore } = stackInfo
  const moreButton = hasMore ? {
    id: ID.lastPublicationCardStackLinkToProfile,
    buttonType: RegularButtonTypes.transparentWithBorder,
    linkTo: YomeURL.offers,
    label: `${I18N_OFFER_LAST_PUBLICATION}.button`,
  } : undefined

  const itemsWithCurrentUserFlag = items.map((offer) => ({ ...offer, isCreatedByCurrentUser: true }))

  return (
    <CardStack
      idOfferPrefix={ID.lastPublicationCardStackPrefixOffer}
      cardStackLabel={`${I18N_OFFER_LAST_PUBLICATION}.label`}
      offerList={itemsWithCurrentUserFlag}
      offerListKey={stackEndpoint}
      moreButton={moreButton}
      cardInfo={CardInfo.statistic}
    />
  )
}

export default LastPublicationCardStack
