import { I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID, concatId } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import IconButton from '../../../../../components/button/IconButton'
import RegularButton, { RegularButtonTypes } from '../../../../../components/button/RegularButton'

import styles from './setting.module.scss'

interface ISettingLineEditButton {
  settingId: string;
  toggle: () => void;
  btnLabel?: string;
  disabled?: boolean;
}

const SettingLineEditButton = ({ settingId, toggle, btnLabel = 'edit', disabled }: ISettingLineEditButton) => {
  const { isDesktop } = useViewport()

  return isDesktop ? (
    <RegularButton
      id={concatId(ID.profileSettingEditSetting, settingId)}
      buttonType={RegularButtonTypes.transparentNoBorderLight}
      onClick={toggle}
      label={`${I18N_GLOBAL}.${btnLabel}`}
      disabled={disabled}
      className={styles.editButton}
    />
  ) : (
    <IconButton
      id={concatId(ID.profileSettingButtonOpenModal, settingId)}
      onClick={toggle}
      iconName='chevron-right'
      className={styles.editBtnMobile}
      disabled={disabled}
    />
  )
}

export default SettingLineEditButton
