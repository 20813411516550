import classNames from 'classnames'
import { PropsWithChildren, memo, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'

import { IRegularButtonProps, RegularButtonTypes } from 'src/components/button/RegularButton'
import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import { I18N_GLOBAL, I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { IFilterValues, TSingleFilterKey, TSingleFilterValues } from 'src/models/search.model'

import { MainCategories } from 'src/yome-categories-fields-config/models/fields.model'
import { searchFilterNames } from 'src/yome-categories-fields-config/fields-config/searchFilterFields'
import ModalContent from '../modal/mobileModals/ModalContent'

interface IFilterFieldProps {
  closeFilter: () => void;
  change: (name: TSingleFilterKey, value: TSingleFilterValues) => void;
  initialValues: IFilterValues;
  name: TSingleFilterKey;
  filtersCategory: MainCategories;
}

const SingleFullsizeFilter = ({
  change,
  closeFilter,
  children,
  initialValues,
  name,
  filtersCategory,
}: PropsWithChildren<IFilterFieldProps>) => {
  const filterNames = useMemo(() => searchFilterNames[filtersCategory], [filtersCategory])

  const onSingleFilterSubmit = useCallback((values: Partial<IFilterValues>) => {
    // change value for all the fields (since it could be complex field as price)
    Object.keys(values).forEach((filterName) => {
      if (!filterNames.includes(filterName)) {
        return
      }

      const filterValue = values[filterName as TSingleFilterKey]

      change(filterName as TSingleFilterKey, filterValue)
    })

    closeFilter()
  }, [change, closeFilter, filterNames])

  const resetFilter = useCallback((values: IFilterValues, reset: (values: IFilterValues) => void) => () => {
    const resetedValues: Record<string, undefined> = {}

    // reset value for all the fields (since it could be complex field as price)
    Object.keys(values).forEach((singleFieldName) => {
      resetedValues[singleFieldName] = undefined
    })

    reset(resetedValues)
  }, [])

  const applyButtonProps: IRegularButtonProps = {
    id: ID.searchFilterApplyFilter,
    buttonType: RegularButtonTypes.main,
    label: `${I18N_GLOBAL}.apply`,
    type: 'submit',
  }

  const clearButtonProps = {
    id: ID.searchFilterClearFilter,
    buttonType: RegularButtonTypes.transparentWithBorder,
    label: `${I18N_GLOBAL}.clear`,
  }

  return (
    <FullscreenModalLayout>
      <Form
        onSubmit={onSingleFilterSubmit}
        initialValues={initialValues}
        render={({ values, handleSubmit, form: { reset } }) => (
          <form onSubmit={handleSubmit} className={classNames('h-100', 'mobile-paddings')}>
            <ModalContent
              title={`${I18N_SEARCH_FILTER}.${name}.title`}
              handleClose={closeFilter}
              primaryButtonProps={applyButtonProps}
              secondaryButtonProps={{ ...clearButtonProps, onClick: resetFilter(values, reset) } }
            >
              {children}
            </ModalContent>
          </form>
        )}
      />
    </FullscreenModalLayout>
  )
}

export default memo(SingleFullsizeFilter)
