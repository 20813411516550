import { RegularButtonSizes } from 'src/components/button/RegularButton'
import { I18N_POST_ACTION } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'

import { OfferStatus } from './offerStatus.model'

export enum OfferActions {
  edit = 'edit',
  delete = 'delete',
  archive = 'archive',
  promote = 'promote',
  republish = 'republish',
}

export type TOfferActionsMap = Partial<Record<OfferActions, { onClick?: () => void; linkTo?: string }>>

export const OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS = {
  [OfferActions.edit]: {
    id: ID.postPageEditPost,
    size: RegularButtonSizes.large,
    leftIcon: { name: 'pen', size: 24 },
    label: `${I18N_POST_ACTION}.edit`,
  },
  [OfferActions.delete]: {
    id: ID.postPageArchivePost,
    size: RegularButtonSizes.large,
    leftIcon: { name: 'trash', size: 24 },
    label: `${I18N_POST_ACTION}.delete`,
  },
  [OfferActions.promote]: {
    id: ID.postPagePromotePost,
    size: RegularButtonSizes.large,
    leftIcon: { name: 'announcement', size: 24 },
    label: `${I18N_POST_ACTION}.promote`,
  },
  [OfferActions.archive]: {
    id: ID.postPageArchivePost,
    size: RegularButtonSizes.large,
    leftIcon: { name: 'eye-off', size: 24 },
    label: `${I18N_POST_ACTION}.archive`,
  },
  [OfferActions.republish]: {
    id: ID.postPagePublishPost,
    size: RegularButtonSizes.large,
    leftIcon: { name: 'eye-on', size: 24 },
    label: `${I18N_POST_ACTION}.republish`,
  },
}

export const OFFER_STATUS_TO_PERMITTED_ACTIONS = (publishedAt?: string) => (
  {
    [OfferStatus.active]: [OfferActions.promote, OfferActions.edit, OfferActions.archive],
    [OfferStatus.moderating]: publishedAt ? [OfferActions.edit] : [OfferActions.edit, OfferActions.delete],
    [OfferStatus.expired]: [OfferActions.republish, OfferActions.delete],
    [OfferStatus.draft]: [OfferActions.edit, OfferActions.delete],
    [OfferStatus.closed]: [OfferActions.republish, OfferActions.delete],
    [OfferStatus.declined]: [OfferActions.edit, OfferActions.archive],
    [OfferStatus.blocked]: [],
    [OfferStatus.deleted]: [],
  }
)
