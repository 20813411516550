import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_MESSENGER } from 'src/constants/i18n.constants'

import styles from './noSelectedChat.module.scss'

const NoSelectedChat = () => (
  <EmptyStateMessage
    icon='no-selected-chat'
    text={`${I18N_MESSENGER}.noSelectedChat`}
    className={styles.main}
  />
)

export default NoSelectedChat
