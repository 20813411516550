import classNames from 'classnames'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_POST } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useCollapseText } from 'src/hooks/form.hooks'
import { useI18nLanguage } from 'src/hooks/i18n.hooks'
import { DESCRIPTION_FIELD_NAME, SinglePost } from 'src/models/post.model'
import { useToggle } from 'src/utils/hooks.utils'
import { getCountryFlagFromLanguage } from 'src/utils/translate.utils'
import { IFieldConfig } from 'src/yome-categories-fields-config/models/fields.model'

import OriginalLangDescription from './OriginalLangDescription'
import PostField from './PostField'

import styles from './postField.module.scss'

interface IAddressProps {
  offer: SinglePost;
  fieldConfig: IFieldConfig;
}

const TranslatedLabelElement = ({ tranlatedFrom }: { tranlatedFrom?: string }) => {
  const { t } = useTranslation()

  const flagEmoji = tranlatedFrom ? getCountryFlagFromLanguage(tranlatedFrom) : ''

  const translatedLabel = t(`${I18N_POST}.description.translatedLabel`)

  return (
    <span className={classNames('supportive-14', styles.translatedLabel)}>
      {` (${translatedLabel}\u00A0${flagEmoji})`}
    </span>
  )
}

const DescriptionField: FC<IAddressProps> = ({ offer, fieldConfig }) => {
  const { isFullyOpen, toggleFullOpen, descrRef, isTextExceeded, buttonLabel } = useCollapseText()

  const userLanguage = useI18nLanguage()

  const hasTranslatedButton = !!offer.translatedFrom && offer.translatedFrom !== userLanguage
  const hasTranslatedLabel = hasTranslatedButton && (isFullyOpen || !isTextExceeded)

  const [isOrginalOpen, toggleOriginalOpen] = useToggle()

  const origBtnLabelKey = isOrginalOpen ? 'hideOriginalBtn' : 'seeOriginalBtn'

  return (
    <>
      <PostField
        ref={descrRef}
        fieldValue={offer.description}
        fieldName={DESCRIPTION_FIELD_NAME}
        fieldConfig={fieldConfig}
        className={isFullyOpen ? styles.isFullyOpen : styles.hideText}
        afterValueElement={hasTranslatedLabel ? (
          <TranslatedLabelElement tranlatedFrom={offer.translatedFrom}/>
        ) : undefined}
      />
      <div className={styles.descriptionButtons}>
        {isTextExceeded && (
          <div className={classNames(styles.buttonWrapper, isFullyOpen && styles.iconRotate)}>
            <RegularButton
              id={ID.postPageToggleDetails}
              buttonType={RegularButtonTypes.transparentNoBorderLight}
              onClick={toggleFullOpen}
              label={`${I18N_POST}.descriptionBtn${buttonLabel}`}
              rightIcon={{ name: 'chevron-down', size: 24 }}
            />
          </div>
        )}
        { hasTranslatedButton && (
          <div className={styles.origContainer}>
            <div></div>
            <RegularButton
              id={ID.postPageTranslateButton}
              buttonType={RegularButtonTypes.transparentWithBorder}
              label={`${I18N_POST}.${origBtnLabelKey}`}
              leftIcon={{ name: 'translation', size: 24 }}
              className={classNames(styles.seeOriginalBtn)}
              onClick={toggleOriginalOpen}
            />
            {isOrginalOpen && <OriginalLangDescription slug={offer.slug} toggleOriginalOpen={toggleOriginalOpen}/>}
          </div>
        )
        }
      </div>
    </>
  )
}

export default memo(DescriptionField)
