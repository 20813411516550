export interface IUploadImageResponse {
  filename: string;
  extension: string;
}

export enum ImageType {
  Orig = 'orig',
  Post = 'post',
  Blured = 'blured',
  ThumbSmall = 'thumb-small',
  ThumbNormal = 'thumb-normal',
  ThumbBig = 'thumb-big',
}

// avatar types in image service
export enum AvatarType {
  Orig = 'orig',
  AvatarTiny = 'avatar-tiny', // 48 * 48
  AvatarSmall = 'avatar-small', // 120 * 120
}

// displayed avatar sizes
export enum AvatarSizes {
  xtiny = 'xtiny', // 32 * 32
  tiny = 'tiny', // 48 * 48
  xsmall = 'xsmall', // 80 * 80
  small = 'small', // 120 * 120
}

export const AvatarSizeMap: Record<AvatarSizes, AvatarType> = {
  xtiny: AvatarType.AvatarTiny,
  tiny: AvatarType.AvatarTiny,
  xsmall: AvatarType.AvatarSmall,
  small: AvatarType.AvatarSmall,
}
