import classNames from 'classnames'
import React from 'react'

import { I18N_FOOTER_LINK } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import { SECTION_ITEMS_MOBILE } from 'src/models/footer.model'

import ButtonLink from '../button/ButtonLink'
import FooterSmallLegal from './FooterSmallLegal'
import FooterSocial from './FooterSocial'

import layoutStyles from '../../routes/layout.module.scss'
import styles from './footer.module.scss'

const FooterMobile = () => (
  <footer className={styles.main}>
    <div
      className={classNames(layoutStyles.base, styles.mobileFooterContainer)}
    >
      <ul className={styles.linksRow}>
        {SECTION_ITEMS_MOBILE.map(({ id, linkTo }) => (
          <li key={id}>
            <ButtonLink
              linkTo={linkTo}
              label={`${I18N_FOOTER_LINK}.${id}`}
              id={concatId(ID.footerLinkToOption, id)}
            />
          </li>
        ))}
      </ul>
      <FooterSocial />
      <FooterSmallLegal />
    </div>
  </footer>
)

export default React.memo(FooterMobile)
