import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import MapComponent from 'src/components/map/MapComponent'
import { I18N_POST } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { IPropsWithOffer } from 'src/models/post.model'
import { useToggle } from 'src/utils/hooks.utils'
import { parseLatLngString } from 'src/utils/map.utils'
import { IFieldConfig } from 'src/yome-categories-fields-config/models/fields.model'

import MobileMapDisplay from '../../mobileMapDisplay/MobileMapDisplay'
import PostField from './PostField'

import styles from './postField.module.scss'

interface IAddressProps extends IPropsWithOffer {
  fieldConfig: IFieldConfig;
}

const AddressField: FC<IAddressProps> = ({ offer, fieldConfig }) => {
  const [isOpen, toggleOpenMap] = useToggle(false)
  const { t } = useTranslation()
  const buttonLabel = isOpen ? t('Hide') : t('')

  const { isDesktop } = useViewport()

  return (
    <PostField
      fieldValue={offer.location?.address}
      fieldName='location'
      fieldConfig={fieldConfig}
      hideTitle={!isDesktop}
    >
      <div className={classNames(styles.buttonWrapper, isOpen && styles.iconRotate)}>
        <RegularButton
          id={ID.postPageToggleMap}
          buttonType={RegularButtonTypes.transparentNoBorderLight}
          onClick={toggleOpenMap}
          label={`${I18N_POST}.addressBtn${buttonLabel}`}
          rightIcon={isDesktop ? { name: 'chevron-down', size: 24 } : undefined}
          leftIcon={isDesktop ? undefined : { name: 'location-map_pin', size: 24 }}
        />
      </div>
      {isOpen && offer.location && (
        isDesktop ? (
          <div className={styles.mapWrapper}>
            <MapComponent markerPosition={parseLatLngString(offer.location.position)} />
          </div>
        ) : (
          <MobileMapDisplay location={offer.location} toggleOpenMap={toggleOpenMap} postTitle={offer.title}/>
        )
      )}
    </PostField>
  )
}

export default AddressField
