import React from 'react'

import { DESCRIPTION_FIELD_NAME, SinglePost } from '../../../../../models/post.model'
import { IFieldConfig } from '../../../../../yome-categories-fields-config/models/fields.model'
import AddressField from './AddressField'
import CategoryField from './CategoryField'
import DescriptionField from './DescriptionField'
import PostField from './PostField'

import styles from './postField.module.scss'

interface IPostSectionFields {
  fields: Record<string, IFieldConfig>;
  post: SinglePost;
}

const isKeyOfSinglePost = <K extends keyof SinglePost>(post: SinglePost, key: K | string): key is K => key in post

const PostSectionFields: React.FC<IPostSectionFields> = ({ fields, post }) => (
  <div className={styles.sectionFields}>
    {Object.keys(fields).map((field) => {
      if (!isKeyOfSinglePost(post, field)) {
        return null
      }

      const fieldValue = post[field]
      const fieldConfig = fields[field]

      if (fieldValue === '') {
        return null
      }

      if (field === 'location') {
        return (
          <AddressField
            key={field}
            offer={post}
            fieldConfig={fieldConfig}
          />
        )
      }

      if (field === 'categoryPath') {
        return (
          <CategoryField
            key={field}
            field={field}
            categoryPath={post.categoryPath}
          />
        )
      }

      if (field === DESCRIPTION_FIELD_NAME) {
        return (
          <DescriptionField
            key={field}
            offer={post}
            fieldConfig={fieldConfig}
          />
        )
      }

      return (
        <PostField
          key={field}
          fieldName={field}
          fieldValue={fieldValue}
          fieldConfig={fieldConfig}
        />
      )
    })}
  </div>
)

export default PostSectionFields
