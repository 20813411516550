import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_AUTH, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useAllowResendEmail } from 'src/hooks/auth.hooks'
import { EmailUrlAction } from 'src/models/url.model'
import { resendResetPassword, resendVerifyEmail } from 'src/services/auth.service'
import InfoModal from '../infoModal/InfoModal'

import styles from './auth.module.scss'

interface IEmailSentMessageProps {
  action: EmailUrlAction;
  email: string;
  handleClose: () => void;
}

const EmailSentModal = React.memo(({ action, email, handleClose }: IEmailSentMessageProps) => {
  const { t } = useTranslation()

  const modalPropsByAction = {
    [EmailUrlAction.verify]: {
      mainText: `${I18N_AUTH}.mail.verifyEmail`,
      resendEmail: () => resendVerifyEmail(email),
      successToast: t(`${I18N_AUTH}.newVerifyLink`),
    },
    [EmailUrlAction.reset]: {
      mainText: `${I18N_AUTH}.mail.resetPassword`,
      resendEmail: () => resendResetPassword(email),
      successToast: t(`${I18N_AUTH}.newPasswordResetLink`),
    },
  }

  const { mainText, resendEmail, successToast } = modalPropsByAction[action]
  const failedToast = t(`${I18N_AUTH}.waitResend`)

  const onResendClick = useAllowResendEmail({ resendEmail, successToast, failedToast })

  const footerButton = (
    <RegularButton
      label={`${I18N_GLOBAL}.gotIt`}
      onClick={handleClose}
      id={ID.signupModalCloseButton}
      buttonType={RegularButtonTypes.main}
    />
  )

  return (
    <InfoModal
      title={`${I18N_AUTH}.title.emailSent`}
      topIcon="plane"
      modalFooter={footerButton}
      handleClose={handleClose}
    >
      <p className="bodySizeL">
        <Trans i18nKey={mainText} values={{ email }}>
          0
          <strong>
            1
          </strong>
          2
        </Trans>
      </p>
      <p className={styles.messageSentEmail}>
        <Trans i18nKey={`${I18N_AUTH}.dontSeeEmail`}>
          0
          <RegularButton
            id={ID.signupModalButtonResend}
            onClick={onResendClick}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
            label={`${I18N_AUTH}.resend`}
            className={styles.inlineButton}
          />
          <RegularButton
            id={ID.signupModalLinkToHelp}
            label={`${I18N_AUTH}.helpCenter`}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
            linkTo={YomeURL.help}
            className="inline"
          />
        </Trans>
      </p>
    </InfoModal>
  )
})

export default EmailSentModal
