export enum FieldType {
  categorySelect = 'categorySelect',
  category = 'category',
  text = 'text',
  textarea = 'textarea',
  complex = 'complex',
  file = 'file',
  select = 'select',
  radio = 'radio',
  location = 'location',
  phone = 'phone',
  price = 'price',
  checkbox = 'checkbox',
  toggle = 'toggle',
  descriptionSearch = 'descriptionSearch',
  isAutoRenewal = 'isAutoRenewal',
  rating = 'rating',
}

export interface IValidationConfig {
  array?: boolean;
  min?: number;
  max?: number;
}

export interface IFieldConfig {
  name: string;
  type: FieldType;
  validation?: IValidationConfig;
  fields?: Record<string, IFieldConfig>;
  optional?: boolean;
  options?: string[];
  defaultValue?: string;
  info?: string;
  mainLabelInfo?: string;
  optionsInfo?: string[];
  tooltip?: ITooltipData;
  placeholder?: string;
  filter?: IFilter;
  showCounter?: boolean;
}

export interface ICategoryStructure {
  section: string;
  fields: Record<string, IFieldConfig>;
  fieldsFlatten?: Record<string, IFieldConfig>;
  info?: string;
  hasDivider?: boolean;
  isTitleHiddenOnDesktop?: boolean;
}

export interface ITooltipData {
  header?: string;
  paragraphs: string[];
}

export interface IFilter {
  type: FieldType;
  isTwoColumns?: boolean;
  isFullsizeMobile?: boolean;
}

export enum MainCategories {
  services = 'services',
  others = 'others',
}

export enum DealResultType {
  serviceDealResult = 'serviceDealResult',
  itemDealResult = 'itemDealResult',
}

export enum CountryIsoCode {
  de = 'de',
  bg = 'bg',
  hr = 'hr',
  ge = 'ge',
  gr = 'gr',
  it = 'it',
  kz = 'kz',
  pl = 'pl',
  ro = 'ro',
  tr = 'tr',
}

export const COUNTRY_TO_PHONE_CODE: Record<CountryIsoCode, string> = {
  de: '+49',
  bg: '+359',
  hr: '+385',
  ge: '+995',
  gr: '+30',
  it: '+39',
  kz: '+7',
  pl: '+48',
  ro: '+40',
  tr: '+90',
}
