import React, { useEffect } from 'react'
import useSWR from 'swr'

import SWRErrorNotification from 'src/components/swr/SWRErrorNotification'
import SWRkeys from 'src/constants/swr.constants'
import { IChat, IChatMessage } from 'src/models/messenger.model'
import { ClientToServerEvents, getSocket } from 'src/services/messenger.service'
import { checkDateEqual } from 'src/utils/date.utils'
import { getPreviousMessageDate } from 'src/utils/messenger.utils'

import ScrollHere from '../../components/ScrollHere'
import ChatMessage from '../chatMessage/ChatMessage'
import GroupMessageDate from '../groupMessageDate/GroupMessageDate'
import NoMessage from '../noMessage/NoMessage'

import styles from './styles.module.scss'

interface IChatHistory {
  currentChat: IChat;
}

const ChatHistory = ({ currentChat }: IChatHistory) => {
  const { data: history } = useSWR<IChatMessage[]>(SWRkeys.chatHistory(currentChat?.id))

  useEffect(() => {
    const socket = getSocket()
    socket.emit(ClientToServerEvents.GetChatHistory, currentChat.id)
  }, [currentChat.id])

  if (!history || history.length === 0) {
    return <NoMessage />
  }

  const lastMessage = history[history.length - 1]

  return (
    <div className={styles.history}>
      <div className={styles.content}>
        {history.map((message, index) => {
          const currentMessageDate = new Date(message.createdAt)
          const isMessageFromNextDay = !checkDateEqual(currentMessageDate, getPreviousMessageDate(index, history))

          return (
            <React.Fragment key={`${message.createdAt}${index}`}>
              <GroupMessageDate
                messageDate={message.createdAt}
                isMessageFromNextDay={isMessageFromNextDay}
              />
              <ChatMessage message={message} />
            </React.Fragment>
          )
        })}

        <ScrollHere dependency={lastMessage.createdAt}/>
      </div>
    </div>
  )
}

const ChatHistoryBoundary = ({ currentChat }: IChatHistory) => (
  <SWRErrorNotification swrKey={SWRkeys.chatHistory(currentChat?.id)}>
    <ChatHistory currentChat={currentChat}/>
  </SWRErrorNotification>
)

export default ChatHistoryBoundary
