import { PropsWithChildren } from 'react'

import ModalPortal from '../ModalPortal'

import styles from './mobileModal.module.scss'

const FullscreenModalLayout = ({ children }: PropsWithChildren) => (
  <ModalPortal>
    <div className={styles.modalLayout}>
      <div className={styles.mobileModalContainer}>
        { children }
      </div>
    </div>
  </ModalPortal>
)

export default FullscreenModalLayout
