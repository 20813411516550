import { ICategory } from '../models/category.model'

const CLOTHES: ICategory = { title: 'CLOTHES',
  items: [
    { title: 'WOMEN',
      items: [
        { title: 'WOMEN_OUTERWEAR',
          items: [
            { title: 'FUR_COATS' },
            { title: 'WOMEN_COATS' },
            { title: 'WOMEN_WINTER_JACKETS' },
            { title: 'WOMEN_DEMI_SEASON_JACKETS' },
            { title: 'WOMEN_RAINCOATS' },
            { title: 'WOMEN_PARKS' },
            { title: 'WOMEN_VESTS' },
            { title: 'WOMEN_JEAN_JACKETS' },
            { title: 'WOMEN_LIGHT_JACKETS' },
            { title: 'WOMEN_SHEEPSKIN_COATS' },
            { title: 'WOMEN_LEATHER_JACKETS' },
            { title: 'WOMEN_BIKER_LEATHER_JACKETS' },
            { title: 'WOMEN_BOMBER' },
            { title: 'WOMEN_PONCHOS' },
            { title: 'WOMEN_OUTERWEAR_OTHER' },
          ] },
        { title: 'DRESSES',
          items: [
            { title: 'CASUAL_DRESSES' },
            { title: 'EVENING_DRESSES' },
            { title: 'SUNDRESSES' },
            { title: 'WEDDING_DRESSES' },
          ] },
        { title: 'SKIRTS' },
        { title: 'SWEATSHIRTS' },
        { title: 'JUMPERS' },
        { title: 'SUITS' },
        { title: 'WOMEN_PANTS' },
        { title: 'WOMEN_JEANS' },
        { title: 'WOMEN_UNDERWEAR' },
        { title: 'WOMEN_SHIRTS' },
        { title: 'T_SHIRTS' },
        { title: 'WOMEN_SWIMSUITS' },
        { title: 'WOMEN_CLOTHES_OTHER' },
      ] },
    { title: 'WOMEN_SHOES',
      items: [
        { title: 'HEELS' },
        { title: 'WOMEN_BOOTS' },
        { title: 'WOMEN_SANDALS' },
        { title: 'BOOTIES' },
        { title: 'WOMEN_ANKLE_BOOTS' },
        { title: 'HALF_BOOTS' },
        { title: 'WOMEN_SNEAKERS' },
        { title: 'WOMEN_FLATS' },
        { title: 'WOMEN_UGGS' },
        { title: 'WOMEN_CLOGS' },
        { title: 'WOMEN_RUBBER_FOOTWEAR' },
        { title: 'LOAFERS' },
        { title: 'OPEN_TOED_SHOES' },
        { title: 'WOMEN_FLIPFLOPS' },
        { title: 'WOMEN_SLIPPERS' },
        { title: 'WOMEN_SPORTSHOES' },
        { title: 'WOMEN_SHOES_CARE' },
        { title: 'WOMEN_SHOES_OTHER' },
      ] },
    { title: 'MEN',
      items: [
        { title: 'MEN_OUTERWEAR',
          items: [
            { title: 'MEN_WINTER_JACKETS' },
            { title: 'MEN_LIGHT_JACKETS' },
            { title: 'MEN_DEMI_SEASON_JACKETS' },
            { title: 'MEN_COATS' },
            { title: 'MEN_SHEEPSKIN_FUR_COATS' },
            { title: 'MEN_VESTS' },
            { title: 'MEN_PARKS' },
            { title: 'MEN_JEAN_JACKETS' },
            { title: 'MEN_LEATHER_JACKETS' },
            { title: 'MEN_BOMBERS' },
            { title: 'MEN_RAINCOATS' },
            { title: 'MEN_BIKERS_LEATHER_JACKETS' },
            { title: 'MEN_OUTERWEAR_OTHER' },
          ] },
        { title: 'MEN_T_SHIRTS' },
        { title: 'SWEATSHIRTS' },
        { title: 'MEN_JEANS' },
        { title: 'MEN_JUMPERS' },
        { title: 'MEN_SHIRTS' },
        { title: 'MEN_PANTS' },
        { title: 'MEN_SUITS' },
        { title: 'TRACKSUITS' },
        { title: 'SHORTS' },
        { title: 'MEN_UNDERWEAR' },
        { title: 'MEN_CLOTHES_OTHER' },
      ] },
    { title: 'MEN_SHOES',
      items: [
        { title: 'MEN_SNEAKERS' },
        { title: 'MEN_BOOTS' },
        { title: 'MEN_FLAT_SHOES' },
        { title: 'MEN_HALF_BOOTS' },
        { title: 'MEN_LOAFERS' },
        { title: 'MEN_SPORTSHOES' },
        { title: 'MEN_UGGS' },
        { title: 'MEN_WORK_SHOES' },
        { title: 'RUBBER_FOOTWEAR' },
        { title: 'SANDALS' },
        { title: 'FLIPFLOPS' },
        { title: 'MEN_SLIPPERS' },
        { title: 'SHOES_CARE' },
        { title: 'MEN_SHOES_OTHER' },
      ] },
    { title: 'ACCESSORIZES',
      items: [
        { title: 'HEAD_WEAR' },
        { title: 'SCARVES' },
        { title: 'GLASSES' },
        { title: 'WATCHES' },
        { title: 'BELTS' },
        { title: 'GLOVES' },
        { title: 'JEWELRY' },
        { title: 'UMBRELLAS' },
        { title: 'DOCUMENTS_COVER' },
        { title: 'FOR_HAIR' },
        { title: 'TIES' },
        { title: 'SOCKS' },
        { title: 'BIJOUTERIE' },
        { title: 'ACCESSORIZES_OTHER' },
      ] },
    { title: 'CHILDREN_CLOTHES',
      items: [
        { title: 'GIRLS',
          items: [
            { title: 'GIRLS_OUTERWEAR' },
            { title: 'GIRLS_SHOES' },
            { title: 'GIRLS_DRESSES_SKIRTS' },
            { title: 'GIRLS_KNITWEAR' },
            { title: 'GIRLS_OVERALLS' },
            { title: 'GIRLS_HATS' },
            { title: 'GIRLS_PANTS' },
            { title: 'GIRLS_PAJAMAS' },
            { title: 'GIRLS_OTHER' },
          ] },
        { title: 'BOYS',
          items: [
            { title: 'BOYS_OUTERWEAR' },
            { title: 'BOYS_SHOES' },
            { title: 'BOYS_KNITWEAR' },
            { title: 'BOYS_OVERALLS' },
            { title: 'BOYS_HATS' },
            { title: 'BOYS_PANTS' },
            { title: 'BOYS_PAJAMAS' },
            { title: 'BOYS_OTHER' },
          ] },
      ] },
    { title: 'CHILDREN_GOODS',
      items: [
        { title: 'STROLLERS' },
        { title: 'CHILDREN_FURNITURE' },
        { title: 'BIKES' },
        { title: 'NUTRITION' },
        { title: 'CAR_SEATS' },
        { title: 'BEDDINGS' },
        { title: 'BATHING' },
        { title: 'SCHOOL' },
        { title: 'CHILDREN_TOYS' },
      ] },
    { title: 'BEAUTY',
      items: [
        { title: 'BEAUTY_TOOLS_ACCESSORIES' },
        { title: 'PERFUMES' },
        { title: 'HYGENE' },
        { title: 'COSMETICS' },
        { title: 'HAIR_PRODUCTS' },
        { title: 'MEDICAL_PRODUCTS' },
      ] },
    { title: 'SPORT_RECREATION',
      items: [
        { title: 'SPORTS_PROTECTION' },
        { title: 'SPORT_BIKES' },
        { title: 'ROLLERS' },
        { title: 'SPORT_SCOOTERS' },
        { title: 'BILLIARD_BOWLING' },
        { title: 'WATER_SPORTS' },
        { title: 'MARTIAL_ARTS' },
        { title: 'WINTER_SPORTS' },
        { title: 'BALL_SPORTS' },
        { title: 'FISHING_HUNTING' },
        { title: 'SPORT_TOURISM' },
        { title: 'TENNIS' },
        { title: 'SPORT_EQUIPMENT' },
        { title: 'SPORT_NUTRITION' },
        { title: 'FITNESS' },
        { title: 'SPORT_OTHER' },
      ] },
    { title: 'HOBBIES',
      items: [
        { title: 'TICKETS' },
        { title: 'FILMS' },
        { title: 'HOBBY_GAMES_CONSOLES' },
        { title: 'HOBBY_VIDEO_GAMES' },
        { title: 'BOOKS' },
        { title: 'COLLECTING' },
        { title: 'ART' },
        { title: 'MUSIC' },
        { title: 'MUSICAL_INSTRUMENTS' },
        { title: 'BOARD_GAMES' },
        { title: 'HOBBIES_OTHER' },
      ] },
    { title: 'HANDMADE',
      items: [
        { title: 'HANDMADE_COSMETICS' },
        { title: 'HANDMADE_JEWELRY' },
        { title: 'HANDMADE_DOLLS' },
        { title: 'HANDMADE_STATIONNERY' },
        { title: 'HANDMADE_TABLEWARE' },
        { title: 'HANDMADE_OTHER' },
      ] },
    { title: 'OTHER_ITEMS' },
  ] }

const AUTO: ICategory = { title: 'AUTO',
  items: [
    { title: 'CARS' },
    { title: 'MOTORCYCLES_AND_MOTOR_VEHICLES',
      items: [
        { title: 'ALL_TERRAIN_VEHICLES' },
        { title: 'KARTING' },
        { title: 'QUADS_AND_BUGGIES' },
        { title: 'MOPEDS' },
        { title: 'MOTORCYCLES' },
        { title: 'SNOWMOBILES' },
      ] },
    { title: 'TRUCKS_AND_SPECIAL_VEHICLES',
      items: [
        { title: 'BUSES' },
        { title: 'MOTORHOMES' },
        { title: 'CRANES' },
        { title: 'BULLDOZERS' },
        { title: 'TRUCKS' },
        { title: 'MUNICIPAL_EQUIPMENT' },
        { title: 'LIGHT_COMMERCIAL_TRANSPORT' },
        { title: 'ATTACHMENTS' },
        { title: 'LOADERS' },
        { title: 'TRAILERS' },
        { title: 'AGRICULTURAL_EQUIPMENT' },
        { title: 'CONSTRUCTION_EQUIPMENT' },
        { title: 'FORESTRY_EQUIPMENT' },
        { title: 'TRACTORS' },
        { title: 'EXCAVATORS' },
        { title: 'TRUCKS_OTHER' },
      ] },
    { title: 'WATER_TRANSPORT',
      items: [
        { title: 'PADDLE_BOATS' },
        { title: 'JET_SKIS' },
        { title: 'BOATS_AND_YACHTS' },
        { title: 'OUTBOARD_MOTORS' },
      ] },
    { title: 'BICYCLES_AND_SCOOTERS',
      items: [
        { title: 'BICYCLES' },
        { title: 'SCOOTERS' },
      ] },
  ] }

const REALTY: ICategory = { title: 'REALTY',
  items: [
    { title: 'APARTMENTS',
      items: [
        { title: 'APARTMENT_SALE' },
        { title: 'APARTMENT_RENT_LONG_TERM' },
        { title: 'APARTMENT_RENT_SHORT_TERM' },
      ] },
    { title: 'ROOMS',
      items: [
        { title: 'ROOM_SALE' },
        { title: 'ROOM_RENT_LONG_TERM' },
        { title: 'ROOM_RENT_SHORT_TERM' },
      ] },
    { title: 'HOUSES_COTTAGES_DACHAS',
      items: [
        { title: 'HOUSE_SALE' },
        { title: 'HOUSE_RENT_LONG_TERM' },
        { title: 'HOUSE_RENT_SHORT_TERM' },
        { title: 'COTTAGE_SALE' },
        { title: 'COTTAGE_RENT_LONG_TERM' },
        { title: 'COTTAGE_RENT_SHORT_TERM' },
        { title: 'COUNTRY_HOUSE_SALE' },
        { title: 'COUNTRY_HOUSE_RENT_LONG_TERM' },
        { title: 'COUNTRY_HOUSE_RENT_SHORT_TERM' },
      ] },
    { title: 'LAND',
      items: [
        { title: 'LAND_SALE' },
      ] },
    { title: 'GARAGES_PARKING_SPACES',
      items: [
        { title: 'GARAGE_SALE' },
        { title: 'GARAGE_RENT' },
        { title: 'PARKING_SPACE_SALE' },
        { title: 'PARKING_SPACE_RENT' },
      ] },
    { title: 'COMMERCIAL_REAL_ESTATE',
      items: [
        { title: 'COMMERCIAL_REAL_ESTATE_SALE' },
        { title: 'COMMERCIAL_REAL_ESTATE_RENT' },
      ] },
    { title: 'REALTY_OTHER' },
  ] }

export const SERVICES: ICategory = { title: 'SERVICES',
  items: [
    { title: 'LESSONS_COURSES',
      items: [
        { title: 'GERMAN_LANGUAGE' },
        { title: 'SCHOOL_UNIVERSITY_SUBJECTS' },
        { title: 'FOREIGN_LANGUAGE' },
        { title: 'LESSONS_BEAUTY_HEALTH' },
        { title: 'CHILD_DEVELOPMENT' },
        { title: 'MUSIC_LESSONS' },
        { title: 'DESIGN_DRAWING' },
        { title: 'SPORT_DANCING' },
        { title: 'DRIVING_LESSONS' },
        { title: 'TRAININGS' },
        { title: 'IT_COURSES' },
        { title: 'ACTING_LESSONS' },
        { title: 'LESSONS_OTHER' },
      ] },
    { title: 'REPAIR_CONSTRUCTION_GARDEN',
      items: [
        { title: 'RENOVATIONS',
          items: [
            { title: 'HANDYMAN' },
            { title: 'HOUSE_REPAIRS' },
            { title: 'HANDYMAN_PLUMBING' },
            { title: 'ELECTRICIAN' },
            { title: 'HANDYMAN_FURNITURE' },
            { title: 'BALCONY_GLAZING' },
            { title: 'CEILING_WORK' },
            { title: 'FLOOR' },
            { title: 'PLASTER_WORK' },
            { title: 'DOORS_WORK' },
            { title: 'TILE_WORKS' },
            { title: 'CARPENTRY' },
            { title: 'DRYWALL_WORKS' },
            { title: 'WORKS_AT_HEIGHT' },
            { title: 'METAL_STRUCTURES' },
            { title: 'HANDYMAN_INSULATION' },
            { title: 'OFFICE_RENOVATIONS' },
            { title: 'VENTILATION' },
            { title: 'RENOVATIONS_OTHER' },
          ] },
        { title: 'EQUIPMENT_REPAIR',
          items: [
            { title: 'LARGE_APPLIANCES' },
            { title: 'SMALL_APPLIANCES' },
            { title: 'COMPUTER_TECHNOLOGY' },
            { title: 'MOBILE_DEVICES' },
            { title: 'TV_REPAIR' },
            { title: 'PHOTO_AUDIO_VIDEO' },
            { title: 'GAME_CONSOLES' },
            { title: 'REPAIR_OTHER' },
          ] },
        { title: 'EQUIPMENT_INSTALLATION',
          items: [
            { title: 'AIR_CONDITIONERS_INSTALL' },
            { title: 'HOODS_INSTALL' },
            { title: 'WASHING_MACHINES_INSTALL' },
            { title: 'DISHWASHERS_INSTALL' },
            { title: 'FRIDGES' },
            { title: 'DRYING_CABINET' },
            { title: 'OVENS' },
            { title: 'COOKTOP' },
            { title: 'COFFEE_MACHINES' },
            { title: 'INSTALLATION_OTHER' },
          ] },
        { title: 'GARDEN_LANDSCAPING',
          items: [
            { title: 'GARDEN_CARE' },
            { title: 'EXCAVATION_DIGGING' },
            { title: 'FENCES' },
            { title: 'POOLS_WORK' },
            { title: 'ROAD_TILES' },
            { title: 'WATER_WELLS' },
            { title: 'ROLLER_SHUTTERS' },
            { title: 'GARDEN_OTHER' },
          ] },
        { title: 'MUSICAL_INSTRUMENT_REPAIR' },
      ] },
    { title: 'BEAUTY_HEALTH_SERVICE',
      items: [
        { title: 'MANI_PEDI' },
        { title: 'HAIR' },
        { title: 'LASHES_BROWS' },
        { title: 'COSMETOLOGY' },
        { title: 'EPILATION' },
        { title: 'MAKEUP' },
        { title: 'MASSAGE_SPA' },
        { title: 'TATTOO_PIERCING' },
        { title: 'NUTRITIONIST' },
        { title: 'SPORTS_COACHES' },
        { title: 'PSYCOLOGY' },
        { title: 'BEAUTY_OTHER' },
      ] },
    { title: 'HOUSEHOLD_SERVICES',
      items: [
        { title: 'BABYSITTER' },
        { title: 'ELDERLY_CARE' },
        { title: 'HOUSEKEEPING' },
        { title: 'HOUSE_CLEANING' },
        { title: 'OFFICE_CLEANING' },
        { title: 'CARPET_CLEANING' },
        { title: 'FURNITURE_CLEANING' },
        { title: 'WINDOW_CLEANING' },
        { title: 'DESINFECTION' },
        { title: 'HOUSEHOLD_OTHER' },
      ] },
    { title: 'TRANSPORTATION_DELIVERY',
      items: [
        { title: 'CAR_SERVICE' },
        { title: 'CAR_RENT' },
        { title: 'MOVING' },
        { title: 'COMMERCIAL_TRANSPORT' },
        { title: 'MOVING_MEN' },
        { title: 'RECYCLABLE_REMOVAL' },
        { title: 'GARBAGE_REMOVAL' },
        { title: 'COURIER_DELIVERY' },
        { title: 'DELIVERY_OTHER' },
      ] },
    { title: 'ART_ENTERTAINMENT',
      items: [
        { title: 'MUSICIANS' },
        { title: 'DANCERS' },
        { title: 'EVENT_MANAGERS' },
        { title: 'SHOWMEN' },
        { title: 'MAGICIANS_CLOWNS' },
        { title: 'MUSIC_WRITERS' },
        { title: 'SOUND_RECORDING' },
        { title: 'PHOTO_VIDEO_SHOOTING' },
        { title: 'DESIGN' },
        { title: 'ART_OTHER' },
      ] },
    { title: 'PETS_PLANTS_CARE',
      items: [
        { title: 'VETIRINARY' },
        { title: 'PET_CARE' },
        { title: 'GROOMING' },
        { title: 'DOG_TRAINING' },
        { title: 'DOG_WALKING' },
        { title: 'INDOOR_PLANTS' },
        { title: 'PETS_OTHER' },
      ] },
    { title: 'BUSINESS_SERVICES',
      items: [
        { title: 'IT',
          items: [
            { title: 'SITE_CREATION' },
            { title: 'SOFTWARE_INSTALLATION' },
            { title: 'NETWORK_SETTING' },
            { title: 'WEB_DESIGN' },
            { title: 'COMPUTER_FIXER' },
            { title: 'IT_OTHER' },
          ] },
        { title: 'MARKETING' },
        { title: 'TRANSLATION' },
        { title: 'PRINTING' },
        { title: 'ACCOUNTING' },
        { title: 'LAWYERS' },
        { title: 'RECRUITING' },
        { title: 'CONSULTING' },
        { title: 'REALTOR' },
        { title: 'BUSINESS_OTHER' },
      ] },
    { title: 'HANDMADE_ORDERS',
      items: [
        { title: 'FOOD' },
        { title: 'FURNITURE' },
        { title: 'CLOTHING' },
        { title: 'TOYS' },
        { title: 'HANDMADE_ACCESSORIES' },
        { title: 'SOUVENIRS' },
        { title: 'SCULPTURES' },
        { title: 'PAINTINGS' },
        { title: 'HANDMADE_ORDERS_OTHER' },
      ] },
    { title: 'SERVICES_OTHER',
      items: [
        { title: 'FUNERAL' },
        { title: 'ESOTERICS' },
        { title: 'KEY_MAKING' },
        { title: 'CLOTHING_REPAIR' },
        { title: 'DRY_CLEANING' },
        { title: 'JEWELRY_SERVICE' },
        { title: 'WATCH_REPAIR' },
      ] },
  ] }

const HOME: ICategory = { title: 'HOME',
  items: [
    { title: 'REPAIR_AND_CONSTRUCTION',
      items: [
        { title: 'BUILDING_MATERIALS',
          items: [
            { title: 'GATES_FENCES_BARRIERS' },
            { title: 'REINFORCED_CONCRETE_PRODUCTS' },
            { title: 'INSULATION' },
            { title: 'FASTENERS' },
            { title: 'ROOFING_DRAINAGE' },
            { title: 'LACQUERS_PAINTS' },
            { title: 'LADDERS_ACCESSORIES' },
            { title: 'SHEET_MATERIALS' },
            { title: 'METAL_ROLLING' },
            { title: 'FINISHING' },
            { title: 'LUMBER' },
            { title: 'PILING' },
            { title: 'CONSTRUCTION_MIXES' },
            { title: 'BULK_MATERIALS' },
            { title: 'ELECTRICS' },
            { title: 'BUILDING_OTHER' },
          ] },
        { title: 'TOOLS',
          items: [
            { title: 'CHAINSAWS' },
            { title: 'GAS_WELDING_EQUIPMENT',
              items: [
                { title: 'WELDING_ACCESSORIES_COMPONENTS' },
                { title: 'GAS_BURNERS_SOLDERING_LAMPS' },
                { title: 'WELDING_MASKS_GLOVES' },
                { title: 'SOLDERING_IRONS_ACCESSORIES' },
                { title: 'WELDING_MACHINES' },
                { title: 'ELECTRODES_WIRES_RODS' },
              ] },
            { title: 'OTHER_TOOLS',
              items: [
                { title: 'MACHINES' },
                { title: 'WORKBENCHES_WORKSHOP_EQUIPMENT' },
                { title: 'COMPRESSORS' },
                { title: 'LADDERS_STEPS_SCAFFOLDS' },
                { title: 'PUMPS_COMPONENTS' },
                { title: 'TACKLE' },
              ] },
            { title: 'MEASURING_TOOLS',
              items: [
                { title: 'LASER_TAPE_MEASURES_RANGEFINDERS' },
                { title: 'LASER_LEVELS' },
                { title: 'PYROMETERS_OTHER_DETECTORS' },
                { title: 'MARKING_TOOLS' },
                { title: 'TAPE_MEASURES' },
                { title: 'HAND_MEASURING_TOOLS' },
                { title: 'BUILDING_LEVELS' },
                { title: 'TRIPODS_RAILS_HOLDERS' },
                { title: 'ELECTRICAL_MEASUREMENT_DEVICES_TESTERS' },
                { title: 'MEASURING_OTHER' },
              ] },
            { title: 'CONSUMABLES_EQUIPMENT',
              items: [
                { title: 'DIAMOND_DISCS' },
                { title: 'DRILLS_CHISELS' },
                { title: 'CHARGERS_BATTERIES' },
                { title: 'CROWNS' },
                { title: 'CUTTING_WHEELS_SAW_DISCS' },
                { title: 'CHUCKS_ADAPTERS' },
                { title: 'DRILL_BITS' },
                { title: 'GRINDING_WHEELS_ATTACHMENTS' },
                { title: 'CONSUMABLES_OTHER' },
              ] },
            { title: 'HAND_TOOLS',
              items: [
                { title: 'JACKS' },
                { title: 'WRENCHES' },
                { title: 'PAINTING_TOOLS' },
                { title: 'TOOL_SETS' },
                { title: 'SCISSORS' },
                { title: 'SCREWDRIVERS' },
                { title: 'SAWS_JIGSAWS' },
                { title: 'FOAM_SEALANT_GUNS' },
                { title: 'HAND_TILE_CUTTERS' },
                { title: 'CARPENTRY_TOOLS' },
                { title: 'VISES_CLAMPS' },
                { title: 'IMPACT_LEVERAGE_TOOLS' },
                { title: 'HANDTOOLS_OTHER' },
              ] },
            { title: 'POWER_CONSTRUCTION_EQUIPMENT',
              items: [
                { title: 'CONCRETE_MIXERS' },
                { title: 'CONCRETE_VIBRATORS' },
                { title: 'VIBROPLATES' },
                { title: 'MACHINE_TOOLS' },
                { title: 'POWER_OTHER' },
              ] },
            { title: 'PROTECTIVE_CLOTHING_EQUIPMENT',
              items: [
                { title: 'CONSTRUCTION_HELMETS' },
                { title: 'MASKS_GOGGLES' },
                { title: 'EARMUFFS_EARPLUGS' },
                { title: 'NEODYMIUM_MAGNETS' },
                { title: 'FIRE_EXTINGUISHERS' },
                { title: 'GLOVES_PROTECT' },
                { title: 'BELTS_STRAPS_BAGS' },
                { title: 'RESPIRATORS' },
                { title: 'WORKWEAR_SHOES_KNEEPADS' },
                { title: 'PROTECTIVE_OTHER' },
              ] },
            { title: 'ELECTRICAL_COMPONENTS',
              items: [
                { title: 'GENERATORS' },
                { title: 'ENGINES' },
                { title: 'CABLES_WIRES' },
                { title: 'VOLTAGE_STABILIZERS' },
                { title: 'TRANSFORMERS' },
                { title: 'ELECTRICAL_OTHER' },
              ] },
            { title: 'POWER_TOOLS',
              items: [
                { title: 'ANGLE_GRINDERS' },
                { title: 'DRILLS_SCREWDRIVERS' },
                { title: 'CONSTRUCTION_MIXERS' },
                { title: 'DEMOLITION_HAMMERS' },
                { title: 'ROTARY_HAMMERS' },
                { title: 'ELECTRIC_SAWS' },
                { title: 'MOUNTING_GUNS' },
                { title: 'TILE_CUTTERS' },
                { title: 'CONSTRUCTION_VACUUM_CLEANERS' },
                { title: 'HEAT_GUNS' },
                { title: 'MILLING_MACHINES' },
                { title: 'SANDING_MACHINES' },
                { title: 'ELECTRIC_JIGSAWS' },
                { title: 'ELECTRIC_PLANERS' },
                { title: 'POWER_TOOLS_OTHER' },
              ] },
          ] },
        { title: 'PLUMBING_WATER_SUPPLY_SAUNA',
          items: [
            { title: 'BATHROOM_ACCESSORIES' },
            { title: 'POOLS' },
            { title: 'BATHTUBS_COMPONENTS',
              items: [
                { title: 'BATHTUBS' },
                { title: 'BATH_COMPONENTS' },
                { title: 'BATH_CURTAINS_PARTITIONS' },
                { title: 'BATH_SCREENS' },
              ] },
            { title: 'WATER_SUPPLY_SEWAGE',
              items: [
                { title: 'TANKS_CONTAINERS' },
                { title: 'WATER_HEATERS' },
                { title: 'DRAINAGE_SEWERAGE' },
                { title: 'WATER_PIPES_FITTINGS' },
                { title: 'SHUT_OFF_CONTROL_VALVES_AUTOMATION' },
                { title: 'COLLECTORS_FOR_WATER_HEATING_SYSTEMS' },
                { title: 'WATER_SUPPLY_COMPONENTS' },
                { title: 'WATER_PUMPS' },
                { title: 'WATER_SUPPLY_ISTRUMENTS_FOR_MEASUREMENT' },
                { title: 'WATER_ACCESS_PANEL' },
                { title: 'SEPTIC_TANKS' },
                { title: 'WATER_PURIFICATION_FILTER_SYSTEMS' },
                { title: 'WATER_SUPPLY_SEWAGE_OTHER' },
              ] },
            { title: 'SHOWER_CABINS_ENCLOSURES',
              items: [
                { title: 'SHOWER_CABINS' },
                { title: 'SHOWER_TRAYS' },
                { title: 'SHOWER_CORNERS_PARTITIONS_DOORS' },
                { title: 'SHOWER_CABINS_COMPONENTS' },
              ] },
            { title: 'SHOWER_SYSTEMS_HEADS',
              items: [
                { title: 'SHOWER_SYSTEMS_COMPONENTS' },
                { title: 'SHOWER_HEADS_PANELS_RACKS_SETS' },
              ] },
            { title: 'KITCHEN_SINKS_COMPONENTS',
              items: [
                { title: 'KITCHEN_SINKS_PARTS' },
                { title: 'KITCHEN_SINKS' },
              ] },
            { title: 'BATHROOM_FURNITURE',
              items: [
                { title: 'BATHROOM_SETS' },
                { title: 'MIRRORS' },
                { title: 'BATHROOM_FURNITURE_COMPONENTS' },
                { title: 'BATHROOM_LIGHTING' },
                { title: 'RACKS_STANDS_SHELVES' },
                { title: 'SINK_COUNTERTOPS' },
                { title: 'CABINETS' },
                { title: 'WARDROBES_DRESSERS' },
                { title: 'BATHROOM_OTHER' },
              ] },
            { title: 'TOWEL_RADIATORS_COMPONENTS',
              items: [
                { title: 'RADIATORS_COMPONENTS' },
                { title: 'TOWEL_RADIATORS' },
              ] },
            { title: 'SINKS_WASHBASINS_PEDESTALS',
              items: [
                { title: 'SINKS_WASHBASINS_COMPONENTS' },
                { title: 'SINKS' },
                { title: 'WASHBASINS' },
              ] },
            { title: 'FAUCETS_COMPONENTS',
              items: [
                { title: 'COMPONENTS_FAUCETS' },
                { title: 'FAUCETS' },
              ] },
            { title: 'SAUNA_BATH_PRODUCTS' },
            { title: 'TOILETS_URINALS_BIDETS_INSTALLATIONS',
              items: [
                { title: 'BIDETS' },
                { title: 'TOILETS_INSTALLATIONS' },
                { title: 'TOILETS_URINALS_COMPONENTS' },
                { title: 'URINALS' },
                { title: 'TOILETS_TANKS' },
              ] },
            { title: 'PLUMBING_OTHER' },
          ] },
        { title: 'GARDEN_MACHINERY',
          items: [
            { title: 'LAWN_MOWERS_TRIMMERS' },
            { title: 'JERRY_CANS' },
            { title: 'SHOVELS' },
            { title: 'WHEELBARROWS_CARTS' },
          ] },
        { title: 'DOORS' },
        { title: 'WINDOWS_BALCONIES' },
        { title: 'READY_MADE_BUILDINGS_LOG_HOUSES',
          items: [
            { title: 'READY_MADE_HOUSE' },
            { title: 'READY_MADE_BATHHOUSE' },
            { title: 'READY_MADE_GREENHOUSE' },
            { title: 'READY_MADE_GAZEBO' },
            { title: 'READY_MADE_VERANDA_TERRACE' },
            { title: 'READY_MADE_GARAGE' },
            { title: 'READY_MADE_FOR_ANIMALS' },
            { title: 'READY_MADE_WELL' },
            { title: 'READY_MADE_CANOPY' },
            { title: 'READY_MADE_TOILET' },
            { title: 'READY_MADE_BUILDINGS_OTHER' },
          ] },
        { title: 'CEILINGS' },
      ] },
    { title: 'HOUSEHOLD_APPLIANCES',
      items: [
        { title: 'FOR_KITCHEN',
          items: [
            { title: 'HOODS' },
            { title: 'KITCHEN_SETS' },
            { title: 'KITCHEN_SET_COMPONENTS' },
            { title: 'SMALL_KITCHEN_APPLIANCES' },
            { title: 'MICROWAVE_OVENS' },
            { title: 'STOVES' },
            { title: 'DISHWASHERS' },
            { title: 'REFRIGERATORS_FREEZERS' },
            { title: 'OTHER_KITCHEN_APPLIANCES' },
          ] },
        { title: 'FOR_HOME',
          items: [
            { title: 'VACUUM_CLEANERS' },
            { title: 'WASHING_MACHINES' },
            { title: 'IRONS' },
            { title: 'SEWING_MACHINES' },
            { title: 'OTHER_HOUSEHOLD_APPLIANCES' },
          ] },
        { title: 'CLIMATE_EQUIPMENT',
          items: [
            { title: 'FANS' },
            { title: 'AIR_CONDITIONERS' },
            { title: 'HEATERS' },
            { title: 'FIREPLACES' },
            { title: 'AIR_PURIFIERS' },
            { title: 'OTHER_CLIMATE_EQUIPMENT' },
          ] },
      ] },
    { title: 'FURNITURE_AND_INTERIOR',
      items: [
        { title: 'BEDS_SOFAS_ARMCHAIRS',
          items: [
            { title: 'SOFAS' },
            { title: 'ARMCHAIRS' },
            { title: 'BEDS' },
            { title: 'MATTRESSES' },
            { title: 'POUFS_AND_BENCHES' },
            { title: 'BEDROOM_SETS' },
            { title: 'BED_COMPONENTS' },
            { title: 'OTHER_BEDROOM_FURNITURE' },
          ] },
        { title: 'INTERIOR_DECOR',
          items: [
            { title: 'INTERIOR_ITEMS_AND_ART' },
          ] },
        { title: 'LIGHTING' },
        { title: 'STANDS_CABINETS' },
        { title: 'CLOSETS_DRAWERS_SHELVES',
          items: [
            { title: 'WARDROBES' },
            { title: 'DRESSERS' },
            { title: 'RACKS_AND_SHELVES' },
            { title: 'SHELVES' },
            { title: 'HALLWAYS_AND_SHOE_CABINETS' },
            { title: 'CLOSET_SYSTEMS_AND_HANGERS' },
            { title: 'SETS_AND_SUITES' },
            { title: 'CLOSET_COMPONENTS' },
            { title: 'OTHER_CLOSETS' },
          ] },
        { title: 'TABLES_CHAIRS',
          items: [
            { title: 'TABLE_COMPONENTS' },
            { title: 'DINING_SETS' },
            { title: 'BENCHES' },
            { title: 'TABLES' },
            { title: 'CHAIRS' },
            { title: 'STOOLS' },
            { title: 'OTHER_TABLES_AND_CHAIRS' },
          ] },
        { title: 'FURNITURE_OTHER' },
      ] },
    { title: 'PLANTS',
      items: [
        { title: 'PLANTS_SEEDS' },
      ] },
    { title: 'TEXTILE',
      items: [
        { title: 'CARPETS' },
        { title: 'FABRICS_MATERIALS' },
        { title: 'THROWS_BEDSPREADS' },
        { title: 'BED_LINEN' },
        { title: 'PILLOWS' },
        { title: 'TOWELS' },
        { title: 'BLANKETS' },
        { title: 'TABLECLOTHS_NAPKINS' },
        { title: 'FURNITURE_COVERS' },
        { title: 'CURTAINS_DRAPES_TULLES' },
        { title: 'OTHER_TEXTILE' },
      ] },
    { title: 'TABLEWARE_AND_KITCHEN_PRODUCTS',
      items: [
        { title: 'TABLE_SETTING',
          items: [
            { title: 'TABLE_SETTING_SETS_OF_DISHES' },
            { title: 'TABLE_SETTING_CUPS' },
            { title: 'TABLE_SETTING_DRINKING_GLASS' },
            { title: 'TABLE_SETTING_SERVING_ITEMS' },
            { title: 'TABLE_SETTING_PLATES_SAUCERS' },
            { title: 'TABLE_SETTING_VASES' },
            { title: 'TABLE_SETTING_CUTLERY' },
            { title: 'TABLE_SETTING_DISHES_SALAD_BOWLS' },
            { title: 'TABLE_SETTING_JARS' },
            { title: 'TABLE_SETTING_OTHER' },
          ] },
        { title: 'FOOD_PREPARATION',
          items: [
            { title: 'FOOD_PREPARATION_FRYING_STEW_PANS' },
            { title: 'FOOD_PREPARATION_POTS_LADLES' },
            { title: 'FOOD_PREPARATION_UTENSILS_FOR_BAKING' },
            { title: 'FOOD_PREPARATION_COOKING_ACCESSORIES' },
            { title: 'FOOD_PREPARATION_GRILLS_BARBEQUES' },
            { title: 'FOOD_PREPARATION_KITCHEN_APPLIANCES' },
            { title: 'FOOD_PREPARATION_OTHER' },
          ] },
        { title: 'FOOD_STORAGE',
          items: [
            { title: 'FOOD_STORAGE_CANS_BOTTLES' },
            { title: 'FOOD_STORAGE_CONTAINERS_LUNCH_BOXES' },
            { title: 'FOOD_STORAGE_OTHER' },
          ] },
        { title: 'BEVERAGE_PREPARATION',
          items: [
            { title: 'DRINKWARE_SHAKERS' },
            { title: 'THERMAL_MUGS_THERMOSES' },
            { title: 'BEVERAGE_PREPARATION_OTHER' },
          ] },
        { title: 'HOUSEHOLD_GOODS',
          items: [
            { title: 'HOUSEHOLD_CHEMICALS' },
            { title: 'HOUSEHOLD_CLEANING_EQUIPMENT' },
          ] },
        { title: 'KITCHEN_ACCESSORIES',
          items: [
            { title: 'KITCHEN_TEXTTILES' },
            { title: 'KITCHEN_STANDS' },
            { title: 'OTHER_KITCHEN_ACCESSORIES' },
          ] },
        { title: 'OTHER_KITCHEN_ITEMS' },
      ] },
    { title: 'HOME_OTHER' },
  ] }

const ELECTRONICS: ICategory = { title: 'ELECTRONICS',
  items: [
    { title: 'PHONES_TABLETS',
      items: [
        { title: 'PHONE_ACCESSORIES',
          items: [
            { title: 'PHONE_ACCESSORIES_BATTERIES' },
            { title: 'PHONE_ACCESSORIES_HEADSETS' },
            { title: 'PHONE_ACCESSORIES_CHARGERS' },
            { title: 'PHONE_ACCESSORIES_CABLES' },
            { title: 'PHONE_ACCESSORIES_MODEMS' },
            { title: 'PHONE_ACCESSORIES_CASES' },
            { title: 'PHONE_ACCESSORIES_STYLUSES' },
            { title: 'PHONE_ACCESSORIES_SPARE_PARTS' },
            { title: 'PHONE_ACCESSORIES_OTHER' },
          ] },
        { title: 'MOBILE' },
        { title: 'TABLETS' },
        { title: 'E_BOOKS' },
        { title: 'TALKIES' },
        { title: 'LANDLINE' },
      ] },
    { title: 'PHOTO_VIDEO_EQUIPMENT',
      items: [
        { title: 'PHOTO_CAMERAS' },
        { title: 'VIDEO_CAMERAS' },
        { title: 'CCTV' },
        { title: 'LENS' },
        { title: 'FLASH' },
        { title: 'PHOTO_VIDEO_ACCESSORIES' },
        { title: 'TRIPODS_STABILIZERS' },
        { title: 'STUDIO_EQUIPMENT' },
        { title: 'DIGITAL_PHOTOFRAMES' },
        { title: 'COMPACT_PRINTER' },
        { title: 'BINOCULARS' },
        { title: 'PHOTO_VIDEO_EQUIPMENT_OTHER' },
      ] },
    { title: 'DESKTOP_LAPTOP',
      items: [
        { title: 'LAPTOPS' },
        { title: 'COMPUTERS' },
        { title: 'SCREENS' },
        { title: 'KEY_MOUSE' },
        { title: 'DESKTOP_OFFICE_EQUIPMENT' },
        { title: 'NETWORK' },
        { title: 'STORAGE_DEVICES' },
        { title: 'MULTIMEDIA' },
        { title: 'SOFTWARE_DEVICES' },
        { title: 'JOYSTICKS' },
        { title: 'DESCTOPS_LAPTOPS_SPARE_PARTS' },
        { title: 'DESCTOPS_LAPTOPS_ACCESSORIES' },
        { title: 'DESCTOPS_LAPTOPS_OTHER' },
      ] },
    { title: 'TV_AUDIO',
      items: [
        { title: 'TV' },
        { title: 'PROJECTOR' },
        { title: 'SPEAKERS' },
        { title: 'CINEMA' },
        { title: 'DVD' },
        { title: 'STEREO_SYSTEM' },
        { title: 'MP3' },
        { title: 'SATELITE_TV' },
        { title: 'AMPLIFIERS' },
        { title: 'EARPHONES' },
        { title: 'MIC' },
        { title: 'TV_ACCESSORIES' },
        { title: 'TV_OTHER' },
      ] },
    { title: 'GAMES_CONSOLES',
      items: [
        { title: 'CONSOLES' },
        { title: 'CONSOLE_GAMES' },
        { title: 'VIDEO_GAMES' },
        { title: 'PROGRAMMES' },
        { title: 'GAMES_OTHER' },
      ] },
    { title: 'OFFICE_EQUIPMENT',
      items: [
        { title: 'UPS_NETWORK_FILTERS',
          items: [
            { title: 'UPS' },
            { title: 'OFFICE_VOLTAGE_STABILIZERS' },
            { title: 'NETWORK_FILTERS' },
            { title: 'UPS_OTHER' },
          ] },
        { title: 'MFP_COPIERS_SCANNERS_PRINTERS' },
        { title: 'TELEPHONY' },
        { title: 'PAPER_SHREDDERS' },
        { title: 'POWER_SUPPLIES_BATTERIES' },
        { title: 'CABLES_ADAPTERS' },
        { title: 'CARTRIDGES' },
        { title: 'CONSUMABLES' },
        { title: 'OFFICE_OTHER' },
      ] },
    { title: 'ELECTRONICS_OTHER' },
  ] }

const PETS: ICategory = { title: 'PETS',
  items: [
    { title: 'DOGS' },
    { title: 'CATS' },
    { title: 'BIRDS' },
    { title: 'RODENTS' },
    { title: 'REPTILES' },
    { title: 'AGRICULTURAL_ANIMALS' },
    { title: 'OTHER_PETS' },
    { title: 'PET_SUPPLIES' },
    { title: 'AQUARIUM_HOBBY' },
  ] }

const SPARE_PARTS: ICategory = { title: 'SPARE_PARTS_PRODUCTS',
  items: [
    { title: 'SPARE_PARTS',
      items: [
        { title: 'FOR_CARS' },
        { title: 'FOR_MOTORCYCLES' },
        { title: 'FOR_TRUCKS_SPECIAL_MACHINERY' },
        { title: 'FOR_WATER_TRANSPORT' },
      ] },
    { title: 'TIRES_RIMS_WHEELS',
      items: [
        { title: 'PASSENGER_CAR_TIRES' },
        { title: 'TRUCK_SPECIAL_MACHINERY_TIRES' },
        { title: 'MOTORCYCLE_TIRES' },
        { title: 'RIMS' },
        { title: 'HUBCAPS' },
        { title: 'WHEELS' },
      ] },
    { title: 'AUDIO_VIDEO_EQUIPMENT',
      items: [
        { title: 'CAR_RADIO_TAPE' },
        { title: 'CAR_ACOUSTICS' },
        { title: 'DASH_CAMS' },
        { title: 'PARTS_AMPLIFIERS' },
        { title: 'TRANSITION_FRAMES' },
        { title: 'BOXES_PODIUMS' },
        { title: 'CAR_SPEAKER_ACCESSORIES' },
        { title: 'AUDIO_VIDEO_EQUIPMENT_OTHER' },
      ] },
    { title: 'SPARE_PARTS_ACCESSORIES',
      items: [
        { title: 'SPARE_PARTS_WIPER_BLADES' },
        { title: 'SPARE_PARTS_PROTECTION_AND_DECOR' },
        { title: 'SPARE_PARTS_FOR_INTERIOR' },
        { title: 'SPARE_PARTS_FOR_WHEELS' },
        { title: 'SPARE_PARTS_FOR_MOTO_WATER_TRANSPORT' },
        { title: 'SPARE_PARTS_HEATING_EQUIPMENT' },
        { title: 'SPARE_PARTS_CAR_KIT' },
        { title: 'SPARE_PARTS_CARE' },
      ] },
    { title: 'TUNING' },
    { title: 'ROOF_RACKS_TOWBARS' },
    { title: 'SPARE_PARTS_TRAILERS' },
    { title: 'SPARE_PARTS_EQUIPMENT' },
    { title: 'OILS_CAR_CHEMICALS' },
    { title: 'ANTI_THEFT_DEVICES',
      items: [
        { title: 'CAR_ALARMS' },
        { title: 'IMMOBILIZERS' },
        { title: 'MECHANICAL_LOCKS' },
        { title: 'SATELLITE_SYSTEMS' },
      ] },
    { title: 'GPS_NAVIGATORS' },
    { title: 'SPARE_PARTS_OTHER' },
  ] }

export const ALL_BUT_SERVICES_CATEGORIES: ICategory[] = [CLOTHES, AUTO, REALTY, HOME, ELECTRONICS, PETS, SPARE_PARTS]
export const ALL_CATEGORIES: ICategory[] = [CLOTHES, AUTO, REALTY, SERVICES, HOME, ELECTRONICS, PETS, SPARE_PARTS]
export const ANY_CATEGORY = 'ANY'
