import YomeURL, { LEGAL_LINKS } from 'src/constants/navigate.constants'

export type LinkOption = {
  id: string;
  linkTo: string;
}

export enum FooterSections {
  Company = 'company',
  Legal = 'legal',
}

export const SOCIAL_MEDIA_ITEMS: LinkOption[] = [
  { id: 'facebook', linkTo: 'https://www.facebook.com/yome.network' },
  { id: 'instagram', linkTo: 'https://instagram.com/yome_network' },
  { id: 'telegram', linkTo: 'https://t.me/yome_network' },
  { id: 'linkedin', linkTo: 'https://www.linkedin.com/company/yome-network' },
]

export const SECTION_ITEMS_SMALL: LinkOption[] = [
  { id: 'about', linkTo: YomeURL.about },
  { id: 'advertising', linkTo: YomeURL.advertising },
  { id: 'support', linkTo: YomeURL.help },
  { id: 'safety', linkTo: YomeURL.safety },
  { id: 'cookies', linkTo: YomeURL.cookies },
]

export const SECTION_ITEMS_MOBILE: LinkOption[] = [
  { id: 'about', linkTo: YomeURL.about },
  { id: 'forBussines', linkTo: YomeURL.forBusiness },
  { id: 'advertising', linkTo: YomeURL.advertising },
  { id: 'benefits', linkTo: 'https://benefits.yome.network' },
  { id: 'support', linkTo: YomeURL.help },
  { id: 'safety', linkTo: YomeURL.safety },
  { id: 'cookies', linkTo: YomeURL.cookies },
  { id: 'imprint', linkTo: YomeURL.imprint },
]

export const SECTION_ITEMS: Record<string, LinkOption[]> = {
  [FooterSections.Company]: [
    { id: 'forBussines', linkTo: YomeURL.forBusiness },
    { id: 'about', linkTo: YomeURL.about },
    { id: 'advertising', linkTo: YomeURL.advertising },
    { id: 'benefits', linkTo: 'https://benefits.yome.network' },
    { id: 'support', linkTo: YomeURL.help },
  ],
  [FooterSections.Legal]: LEGAL_LINKS,
}

export const DOC_LINKS : LinkOption[] = [
  { id: 'privacy', linkTo: YomeURL.privacy },
  { id: 'terms', linkTo: YomeURL.terms },
]
