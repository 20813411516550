import classNames from 'classnames'
import { t } from 'i18next'
import { useCallback } from 'react'
import { useForm } from 'react-final-form'

import ImageButton from 'src/components/button/ImageButton'
import { EMPTY_STRING } from 'src/constants/general.constants'
import { I18N_CATEGORIES, I18N_POST } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'

import styles from './foundCategoryCardsSet.module.scss'

interface IFoundCategoryCardsSetProps {
  categoryPathList: string[][];
  submitCategory: (categoryPath: string[]) => void;
  searchFieldName?: string;
}

interface IBrowseButtonProps {
  resetSearch: () => void;
}

const BrowseButton = ({ resetSearch }: IBrowseButtonProps) => (
  <li key={'browseButton'}>
    <ImageButton
      size='medium'
      onClick={resetSearch}
      label={t(`${I18N_POST}.${'browse'}`)}
      id={ID.formFieldClearForm}
    />
  </li>
)

const FoundCategoryCardsSet = ({
  categoryPathList,
  submitCategory,
  searchFieldName,
}: IFoundCategoryCardsSetProps) => {
  const { isDesktop } = useViewport()
  const { change } = useForm()

  const handleClick = useCallback((categoryPath: string[]) => () => submitCategory(categoryPath), [submitCategory])

  const resetSearch = useCallback(() => {
    change(searchFieldName!, EMPTY_STRING)
  }, [searchFieldName])

  const categoryList = categoryPathList.map((categoryPath) => {
    const mainCategory = categoryPath[0]
    const foundCategory = categoryPath[categoryPath.length - 1] // last category in path
    const isMainCategoryResult = categoryPath.length === 1
    // icon present only for main categories search reslut (as ['AUTO'])
    const icon = isMainCategoryResult ? `category/${mainCategory.toLocaleLowerCase()}` : undefined

    const supportiveTextMobile = icon ? undefined : `${I18N_CATEGORIES}.${mainCategory}`

    // receives maternal, parent and superior category from the path
    let categoriesToDisplay = [...categoryPath]

    categoriesToDisplay = (categoriesToDisplay.length > 3)
      ? categoriesToDisplay.reverse().slice(0, 2).concat(categoriesToDisplay.slice(-1))
      : categoriesToDisplay.reverse()

    const supportiveTextDesktop = categoriesToDisplay.length !== 1
      ? categoriesToDisplay.map((item) => t(`${I18N_CATEGORIES}.${item}`)).join(' · ')
      : undefined

    const supportiveText = isDesktop ? supportiveTextDesktop : supportiveTextMobile

    return (
      <li key={foundCategory} className={classNames({ [styles.card]: isDesktop })}>
        <ImageButton
          label={`${I18N_CATEGORIES}.${foundCategory}`}
          supportiveText={supportiveText}
          onClick={handleClick(categoryPath)}
          icon={icon}
          size='medium'
          showChevron
          className={classNames(styles.supportive, 'supportive-14')}
        />
      </li>
    )
  })

  return (
    <>
      {categoryList}
      {searchFieldName && <BrowseButton resetSearch={resetSearch} />}
    </>
  )
}

export default FoundCategoryCardsSet
