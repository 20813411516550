import { memo, ReactNode } from 'react'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { captureException } from 'src/utils/browser.utils'

import NotFoundRoute from 'src/routes/notFound/NotFoundRoute'
import { NotFoundError, SubmitFormError } from 'src/utils/error.utils'
import SomethingWentWrong from '../somethingWentWrong/SomethingWentWrong'
import NoPosts, { NoPostsType } from '../noPosts/NoPosts'

export interface ISWRFormErrorBoundaryProps {
  children: ReactNode;
  swrKey: string;
  getFormI18NKey: (fieldName: string) => string;
}

const SWRFormErrorBoundary = ({ children, swrKey, getFormI18NKey }: ISWRFormErrorBoundaryProps) => {
  const { error } = useSWR(swrKey)
  const { t } = useTranslation()

  if (error instanceof NotFoundError) {
    return <NotFoundRoute />
  }

  if (error instanceof SubmitFormError) {
    const errorField = Object.keys(error.errorObject)[0]
    const errorMessage = Object.values(error.errorObject)[0]
    toast.error(`${t(getFormI18NKey(errorField))}: ${t(errorMessage)}`)

    return <NoPosts type={NoPostsType.search}/>
  }

  if (error) {
    captureException(error)

    return <SomethingWentWrong />
  }

  return (
    <>
      {children}
    </>
  )
}

export default memo(SWRFormErrorBoundary)
