import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import DateComponent from 'src/components/date/DateComponent'
import Heading from 'src/components/heading/Heading'
import { I18N_USER } from 'src/constants/i18n.constants'
import { DateFormatType, dateOptions } from 'src/models/date.model'
import { IProfile } from 'src/models/profile.model'

import ProfileAvatar from './components/ProfileAvatar'

import styles from './card.module.scss'

interface ProfileCardProps {
  profile: IProfile;
}

const ProfileCard = ({ profile }: ProfileCardProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <ProfileAvatar
          id={profile.id}
          username={profile.name}
          avatar={profile.avatar}
        />
        <div className={styles.userInfo}>
          <Heading
            level='h6'
            label={profile.name}
            className={classNames(styles.userName, 'desktop-h6 mobile-h6')}
          />
          {/* <Grade rating={profile.rating} hasChatIcon={isDesktop}/> */}
          <div className={styles.dateWrapper}>
            {t(`${I18N_USER}.onYomeSince`)}
            &nbsp;
            <DateComponent
              isoTimestamp={profile.createdAt}
              options={dateOptions.monthYear}
              type={DateFormatType.noLabel}
            />
          </div>
        </div>
      </div>
      <div className={styles.followers}>
        {/* <SubscribersCards followers={profile.followers} subscriptions={profile.subscriptions} /> */}
      </div>
    </div>
  )
}

export default ProfileCard
