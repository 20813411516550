import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Heading from 'src/components/heading/Heading'
import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import { YomeHash } from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { AvatarSizes } from 'src/models/upload.model'
import { IUser } from 'src/models/user.model'
import { assertData } from 'src/utils/error.utils'
import { useUserPageTitle } from 'src/utils/hooks.utils'
import { addMockUserData } from 'src/utils/mock.utils'
import { getUserBadges } from 'src/utils/user.utils'

import UserAvatar from '../../../components/avatar/UserAvatar'
import RegularButton, { RegularButtonTypes } from '../../../components/button/RegularButton'
import DateComponent from '../../../components/date/DateComponent'
import { ID } from '../../../constants/id.constants'
import { DateFormatType, dateOptions } from '../../../models/date.model'
import BadgeList from './components/badge/BadgeList'

import styles from './info.module.scss'

interface IUserInfoProps {
  user: IUser;
}

const UserInfo = ({ user }: IUserInfoProps) => {
  assertData(user)
  addMockUserData(user)

  useUserPageTitle(user.name)

  const { t } = useTranslation()

  const { isDesktop } = useViewport()

  const badges = useMemo(() => getUserBadges(user), [user])

  return (
    <div className={styles.infoCard}>
      <div className={styles.avatarCard}>
        <UserAvatar
          image={user.avatar}
          avatarSize={isDesktop ? AvatarSizes.small : AvatarSizes.xsmall}
          firstLetter={user.name.charAt(0)}
          userId={user.id}
        />
        <div className={styles.userNameWithGrade}>
          <Heading level='h3' className={'desktop-h6 mobile-h6'}>
            {user.name}
          </Heading>
          {/* <Grade rating={user.rating} hasChatIcon/> */}
        </div>
      </div>
      <div className={styles.innerInfo}>
        {/* <SubscribersLabel followers={user.followers} subscriptions={user.subscriptions} /> */}
        <div className={styles.registrationDate}>
          <span>
            {t('user.onYomeSince')}
          </span>
          <DateComponent options={dateOptions.monthYear} isoTimestamp={user.createdAt} type={DateFormatType.noLabel} />
        </div>
      </div>
      {!!badges.length && (
        <div className={styles.badgesContainer}>
          <BadgeList badgeList={badges} showMoreButton hasBadgeIcon/>
        </div>
      )}
      {/* <div className={styles.answerTime}>
        {t('user.responseWaitingTime', { minutes: user.averageResponseTime })}
      </div>
      */}
      <RegularButton
        id={ID.userPageSubscribeUser}
        linkTo={YomeHash.unavailable}
        label={'user.subscribe'}
        buttonType={RegularButtonTypes.transparentWithBorder}
      />
      {isDesktop && <ShowcaseArea />}
    </div>
  )
}

export default UserInfo
