import classNames from 'classnames'
import React from 'react'

import ButtonLink from 'src/components/button/ButtonLink'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { emptyFunction } from 'src/constants/function.constants'
import { I18N_MY_OFFER_CARD } from 'src/constants/i18n.constants'
import { concatId, IdStructure } from 'src/constants/id.constants'
import { IPostStatistic } from 'src/models/post.model'

import styles from './styles.module.scss'

interface IMyOfferCardStats {
  idPrefix: IdStructure;
  stats: IPostStatistic;
  haveMessengerStatistic?: boolean;
  isOneRow?: boolean;
}

const MyOfferCardStats: React.FC<IMyOfferCardStats> = ({
  idPrefix,
  stats,
  haveMessengerStatistic = false,
  isOneRow = false,
}) => {
  const statsData = [
    {
      iconName: 'eye-on',
      label: stats?.viewsNumber || 0,
    },
    {
      iconName: 'heart',
      label: stats?.likesNumber || 0,
    },
    haveMessengerStatistic ? {
      iconName: 'message',
      label: stats?.connectionsNumber || (isOneRow ? 0 : `${I18N_MY_OFFER_CARD}.noNewMessages`),
    } : null,
  ]

  return (
    <div className={classNames({ [styles.isOneRow]: isOneRow, [styles.severalRows]: !isOneRow })}>
      {statsData.map((data, index) => (data && (
        <div key={index} className={styles.iconWrap}>
          <SvgIcon name={data.iconName} size={16} />
          <ButtonLink
            label={data.label.toString()}
            onClick={emptyFunction}
            id={concatId(idPrefix, data.iconName)}
          />
        </div>
      )))}
    </div>
  )
}

export default MyOfferCardStats
