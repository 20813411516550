import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import Toggle from 'src/components/toggle/Toggle'
import { I18N_SEARCH_ACTION_BAR } from 'src/constants/i18n.constants'
import { useGetValueFromUrlParam } from 'src/hooks/query.hooks'
import { TranslatedOptions } from 'src/models/language.model'
import { ORIGINAL_QPARAM } from 'src/models/search.model'

const TranslationToggle = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const translatedState = useGetValueFromUrlParam(ORIGINAL_QPARAM, TranslatedOptions.translated)

  const isTranslated = translatedState === TranslatedOptions.translated

  const translateSearchResult = useCallback(() => {
    searchParams.set(ORIGINAL_QPARAM, isTranslated ? TranslatedOptions.original : TranslatedOptions.translated)
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams, isTranslated])

  return (
    <Toggle
      label={`${I18N_SEARCH_ACTION_BAR}.translate`}
      name='translationToggle'
      checked={isTranslated}
      onChange={translateSearchResult}
    />
  )
}

export default TranslationToggle
