import { Socket } from 'socket.io-client'

import { ContentType, IChat, IChatMessage, IOnlineStatus, IWarningMessage } from 'src/models/messenger.model'
import { ServerToClientEvents } from 'src/services/messenger.service'

export const getPreviousMessageDate = (index: number, messageList: IChatMessage[]) => {
  if (index > 0) {
    const previousMessage = messageList[index - 1]

    return new Date(previousMessage.createdAt)
  }

  return new Date()
}

export const checkIsScanMessage = (contentType: ContentType) => contentType === ContentType.SCAN_WARNING

export const getUpdatedChatsWithNewMessage = (
  socket: Socket,
  newMessage: IChatMessage | IWarningMessage,
  chats?: IChat[],
  currentChatId?: string,
) => {
  if (!chats) {
    return []
  }

  const { chatId: incomingChatId, isRead, isCurrentUserAuthor, contentType } = newMessage

  const chatToUpdate = chats.find((chat) => chat.id === incomingChatId)

  if (!chatToUpdate) {
    socket.emit(ServerToClientEvents.GetAllChats)

    return chats
  }

  const restChats = chats.filter((chat) => chat.id !== incomingChatId)

  const isScanMessage = checkIsScanMessage(contentType)

  // update unread count if it's not current chat and user not author
  const isUpdateCount = (currentChatId !== chatToUpdate.id && !isRead && !isCurrentUserAuthor) && (
    !isScanMessage
  )

  const isUpdateLastMessage = !isScanMessage && 'content' in newMessage
  const lastMessageUpdates = isUpdateLastMessage ? {
    lastMessage: newMessage.content,
    lastMessageCreatedAt: newMessage.createdAt,
  } : {}

  return [{
    ...chatToUpdate,
    ...lastMessageUpdates,
    unreadMessagesCount: chatToUpdate.unreadMessagesCount + (isUpdateCount ? 1 : 0),
  }, ...restChats]
}

export const getUpdatedHistory = (
  newMessage: IChatMessage | IWarningMessage,
  currentChatId?: string,
  history?: (IChatMessage | IWarningMessage)[],
): (IChatMessage | IWarningMessage)[] => {
  if (!history) {
    return []
  }

  if (!currentChatId) {
    return history
  }

  // if incoming message is from current chat
  if (currentChatId === newMessage.chatId) {
    return [...history, newMessage]
  }

  return history
}

export const setOnlineStatus = (chats: IChat[] | undefined, onlineStatus: IOnlineStatus) => (
  (chats || []).map((chat) => (
    chat.id === onlineStatus.chatId
      ? { ...chat, isReceiverOnline: onlineStatus.isOnline }
      : chat
  ))
)

export const setChatHistoryRead = (chats: IChat[] | undefined, currentChatId?: string) => (
  chats?.map((chat) => (
    chat.id === currentChatId ? (
      { ...chat, unreadMessagesCount: 0 }
    ) : chat))
)
