import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'

import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import { getCategoryObject, getPathToCurrentCategory } from 'src/yome-categories-fields-config/utils/category.utils'
import CategoryItem from './CategoryItem'

interface ICategorFilterProps {
  name: string;
}

const FilterCategory = ({ name }: ICategorFilterProps) => {
  const { values } = useFormState()
  const currentCategory = values[name] || ANY_CATEGORY
  const pathToCurrentCategory = getPathToCurrentCategory(currentCategory)

  const categoryObj = useMemo(
    () => getCategoryObject(pathToCurrentCategory),
    [pathToCurrentCategory],
  )

  pathToCurrentCategory.unshift(ANY_CATEGORY) // add "All categories" in the beggining"

  // if there are children in currentCategory - use all the path as "closed" items
  //  - so current category also includes in list for <CategoryItem> display
  // if there are no children - use last subcategory as "open" item with showed children,
  // so current category should not be added to path since it will be in children
  const hasChildren = !!categoryObj?.items?.length

  if (!hasChildren) {
    pathToCurrentCategory.pop()
  }

  const lastAncestor = pathToCurrentCategory[pathToCurrentCategory.length - 1]

  return (
    <ul>
      {pathToCurrentCategory.map((category) => (
        <CategoryItem
          key={category}
          category={category}
          currentCategory={currentCategory}
          showChildren={category === lastAncestor}
        />
      ))}
    </ul>
  )
}

export default React.memo(FilterCategory)
