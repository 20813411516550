import classNames from 'classnames'
import React, { memo } from 'react'
import { useForm, useFormState } from 'react-final-form'

import { KEYBOARD_ENTER_KEY } from 'src/constants/general.constants'
import { I18N_MESSENGER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import ScrollHere from 'src/routes/messenger/components/ScrollHere'
import { useToggle } from 'src/utils/hooks.utils'

import { FormButton, FormTextArea } from '../form'

import styles from './styles.module.scss'

interface IChatProps {
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  hasAutoFocus?: boolean;
  hasScrollOnBlur?: boolean;
  hasUploadButton?: boolean;
}

const CHAT_INPUT_MIN_ROWS = 1
const CHAT_INPUT_MAX_ROWS = 4
const CHAT_INPUT_MAX_SYMBOLS = 3000
export const CHAT_INPUT_NAME = 'message'

const ChatInput: React.FC<IChatProps> = ({ inputRef, hasScrollOnBlur = false, hasAutoFocus = false }) => {
  const { submit } = useForm()
  const { errors, values } = useFormState()
  const [doScroll, toggleScroll] = useToggle()

  // const handleFileUpload = React.useCallback((acceptedFiles: File[]) => {
  //   console.log(acceptedFiles)
  // }, [])

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === KEYBOARD_ENTER_KEY && !event.shiftKey) {
      event.preventDefault()
      submit()
    }
  }, [submit])

  const hasInputValue = !!values[CHAT_INPUT_NAME]
  const hasInputError = errors && errors[CHAT_INPUT_NAME]
  const disabled = !hasInputValue || hasInputError

  return (
    <div className={styles.main}>
      {/* {hasUploadButton && (
        <div className={styles.upload}>
          <UploadFile handleFileUpload={handleFileUpload}>
            <IconButton iconName='clip' id={ID.messengerAttachFile} />
          </UploadFile>
        </div>
      )} */}
      <div className={styles.message}>
        <div className={styles.field}>
          <FormTextArea
            fieldRef={inputRef}
            name={CHAT_INPUT_NAME}
            id={ID.messengerInputMessage}
            minRows={CHAT_INPUT_MIN_ROWS}
            maxRows={CHAT_INPUT_MAX_ROWS}
            fieldClassName={styles.textarea}
            maxSymbols={CHAT_INPUT_MAX_SYMBOLS}
            placeholder={`${I18N_MESSENGER}.writeChatMessage`}
            onKeyDown={handleKeyDown}
            onBlur={hasScrollOnBlur ? toggleScroll : undefined}
            hideCounter
            autoFocus={hasAutoFocus}
          />
        </div>
        <div className={classNames(styles.send, { [styles.disabled]: disabled })}>
          <FormButton
            id={ID.messengerSendMessage}
            buttonType="icon"
            label="arrow-send"
            disabled={disabled}
          />
        </div>
      </div>
      {hasScrollOnBlur && <ScrollHere dependency={doScroll} />}
    </div>
  )
}

export default memo(ChatInput)
