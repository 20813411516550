import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { OnlineStatus } from 'src/components/onlineStatus/OnlineStatus'
import { POINT } from 'src/constants/general.constants'
import YomeURL from 'src/constants/navigate.constants'
import { usePriceFormatter } from 'src/hooks/price.hooks'
import { IChat } from 'src/models/messenger.model'

import ChatHeaderUserName from './ChatHeaderUserName'

import styles from './styles.module.scss'

export interface IChatHeaderContentProps {
  chat: IChat;
}

const ChatHeaderContent = ({
  chat: { receiverInfo, isReceiverOnline, offer: { price, title, slug, category } },
}: IChatHeaderContentProps) => {
  const formatPrice = usePriceFormatter()
  const linkToOffer = YomeURL.offer(category, slug)
  const { isBlocked } = receiverInfo

  return (
    <div className={styles.itemInfo}>
      <div className={styles.userInfo}>
        <ChatHeaderUserName receiver={receiverInfo}/>
        {isReceiverOnline && <OnlineStatus hasOnlineIcon={!isBlocked} hasOnlineLabel/>}
      </div>
      <div className={styles.postInfo}>
        <Link className={styles.offerTitle} to={linkToOffer}>
          {title}
        </Link>
        {POINT}
        <span>
          {formatPrice(price)}
        </span>
      </div>
    </div>
  )
}

export default memo(ChatHeaderContent)
