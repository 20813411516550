import { I18N_PROFILE_SETTING_GENERAL } from 'src/constants/i18n.constants'
import { useViewport } from 'src/contexts/viewportContext'
import PasswordSettingField from '../settingForms/PasswordSettingField'
import SettingSectionWrapper from '../settingWrapper/SettingSectionWrapper'
import LanguageSettings from './LanguageSettings'

const GeneralSettings = () => {
  const { isDesktop } = useViewport()

  return (
    <SettingSectionWrapper title={`${I18N_PROFILE_SETTING_GENERAL}.title`}>
      <>
        {!isDesktop && <LanguageSettings /> }
        <PasswordSettingField/>
      </>
    </SettingSectionWrapper>
  )
}

export default GeneralSettings
