import META_DATA from '../constants/meta.data.json'
import YomeURL from '../constants/navigate.constants'
import { ANY_CATEGORY } from '../yome-categories-fields-config/category-config/categoriesTree'

const CITY_STUB = 'Germany'

// TODO: localize using user's language
export const getOfferPageMetaTitle = (
  title: string,
  category: string,
  city: string,
) => (title ? `${title} purchase in ${city ?? CITY_STUB} | ${category} | Yome` : META_DATA.TITLE)

export const getSearchPageMetaTitle = (searchContent: string, category: string) => {
  const hasAnyCategory = category === ANY_CATEGORY
  const categoryAddition = hasAnyCategory ? '' : ` | ${category}`

  // TODO: get city in particular language
  const city = 'Germany'

  // eslint-disable-next-line max-len
  const defaultTitle = `Ads in ${city}: buy an item, choose a performer or job, select real estate and transport at a low price${categoryAddition} | Yome`

  return searchContent ? `${searchContent} - Yome | ${defaultTitle}` : defaultTitle
}

// TODO: localize using user's language
export const getUserPageMetaTitle = (username: string) => `${username}. User profile on Yome`

// TODO: localize using user's language
export const getStaticPageMetaTitle = (url: string) => {
  switch (url) {
    case YomeURL.mainPage:
    case YomeURL.newOffer:
    case YomeURL.messages:
    case YomeURL.profilePage:
      return META_DATA.TITLE
    case YomeURL.help:
      return 'Support Service | Yome'
    case YomeURL.terms:
      return 'Terms and Conditions | Yome'
    case YomeURL.imprint:
      return 'Imprint | Yome'
    case YomeURL.safety:
      return 'User Safety | Yome'
    case YomeURL.privacy:
      return 'Privacy Policy | Yome'
    case YomeURL.cookies:
      return 'Cookies Policy | Yome'
    case YomeURL.about:
      return 'About | Yome'
    case YomeURL.forBusiness:
      return 'For business | Yome'
    case YomeURL.advertising:
      return 'Advertising | Yome'
    default:
      return 'Page not found | Yome'
  }
}
