import { useCallback } from 'react'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_AUTH, I18N_VERIFY_PHONE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { IVerifyPhoneModalProps, VerifyPhoneModals } from 'src/models/verifyPhone.model'

import InfoModal from '../infoModal/InfoModal'

import styles from './verifyPhoneModals.module.scss'

const CodeFailedModal = ({ setCurrentModal }: IVerifyPhoneModalProps) => {
  const handleResendButton = useCallback(async () => {
    setCurrentModal(VerifyPhoneModals.enterCode)
  }, [setCurrentModal])

  const handleNewNumberButton = useCallback(() => {
    setCurrentModal(VerifyPhoneModals.baseVerifyPhone)
  }, [setCurrentModal])

  const modalFooter = (
    <div className={styles.enterCodeFooter}>
      <RegularButton
        id={ID.verifyPhoneModalCloseButton}
        buttonType={RegularButtonTypes.main}
        label={`${I18N_VERIFY_PHONE}.codeFailed.label`}
        onClick={handleResendButton}
      />
      <div className={styles.footerResend}>
        <div className={styles.oneLine}>
          <Paragraph className='bodySizeM'>
            {`${I18N_VERIFY_PHONE}.enterCode.wrongNumber`}
          </Paragraph>
          <RegularButton
            id={ID.verifyPhoneModalLinkToPhone}
            label={`${I18N_VERIFY_PHONE}.enterCode.enterNewNumber`}
            onClick={handleNewNumberButton}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
          />
        </div>
        <div className={styles.oneLine}>
          <Paragraph className='bodySizeM'>
            {`${I18N_VERIFY_PHONE}.codeFailed.needHelp`}
          </Paragraph>
          <RegularButton
            id={ID.verifyPhoneModalLinkToPhone}
            label={`${I18N_AUTH}.contactSupport.buttonLabel`}
            linkTo={YomeURL.help}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
          />
        </div>
      </div>
    </div>
  )

  return (
    <InfoModal modalFooter={modalFooter}>
      <EmptyStateMessage
        icon={'something-went-wrong-2'}
        title={`${I18N_VERIFY_PHONE}.codeFailed.title`}
        titleIconProps={{ name: 'alert-error' }}
        text={`${I18N_VERIFY_PHONE}.codeFailed.text`}
      />
    </InfoModal>
  )
}

export default CodeFailedModal
