import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Heading from 'src/components/heading/Heading'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import CustomTooltip from 'src/components/tooltip/CustomTooltip'
import { ITooltipData } from 'src/yome-categories-fields-config/models/fields.model'

import styles from './filter.module.scss'

interface IFilterFieldTitleProps {
  title: string;
  tooltip?: ITooltipData;
}

const FilterFieldTitle: React.FC<IFilterFieldTitleProps> = ({ title, tooltip }) => {
  const { t } = useTranslation()

  return (
    <Heading level='h3' className={classNames(styles.fieldTitle, 'desktop-h6 mobile-h6')}>
      {title && t(title)}
      {tooltip && (
        <CustomTooltip tooltipData={tooltip} place='right'>
          <SvgIcon name='hint' size={20} />
        </CustomTooltip>
      )}
    </Heading>
  )
}

export default FilterFieldTitle
