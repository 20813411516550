import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Heading from 'src/components/heading/Heading'
import OfferAddress from 'src/components/postList/OfferAddress/OfferAddress'
import PostPrice from 'src/components/postList/PostPrice/PostPrice'
import { IPropsWithOffer } from 'src/models/post.model'
import { uppercaseTitle } from 'src/utils/post.utils'

import styles from './styles.module.scss'

const MyOfferCardInfo: React.FC<IPropsWithOffer> = ({ offer }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.info}>
      <Heading level='h3' className={classNames(styles.title, 'mobile-card-heading desktop-h6')}>
        {uppercaseTitle(offer.title, t)}
      </Heading>
      <div className={styles.price}>
        <PostPrice offer={offer} />
      </div>
      <OfferAddress location={offer.location} />
    </div>
  )
}

export default MyOfferCardInfo
