import { LoaderOptions } from '@googlemaps/js-api-loader'
import { LatLng, RequestOptions } from 'use-places-autocomplete'

export const ONE_KM = 1000
export const MAP_CIRCLE_COLOR = '#AFBAFF' // $blue_main-300
export const PLUS_CODE_REGEXP = /(^|\s)([23456789CFGHJMPQRVWX]{4,6}\+[23456789CFGHJMPQRVWX]{2,3})(\s|$)/i

export const SEARCH_CITY_FIELD_NAME = 'city'
export const SEARCH_RADIUS_FIELD_NAME = 'radius'

// Berlin Brandenburger Tor
export const DEFAULT_MAP_POSITION: LatLng = {
  lat: 52.5165,
  lng: 13.3800,
}

export const DEFAULT_MAP_CITY_NAME = 'Berlin'

export const DEFAULT_MAP_ZOOM = 17

export const DEFAULT_MAP_SETTINGS = {
  minZoom: 5,
  maxZoom: 20,
}

export const DEFAULT_MAP_CIRCLE_SETTINGS = {
  fillOpacity: 0.3,
  fillColor: MAP_CIRCLE_COLOR,
  strokeWeight: 1,
  strokeOpacity: 0.6,
  strokeColor: MAP_CIRCLE_COLOR,
}

export const GMAP_ID = 'gmap-id'
export const GMAPS_LIBS: LoaderOptions['libraries'] = ['places', 'marker']

export enum CountryCodes {
  DE = 'DE',
}

export enum MapAddressType {
  locality = 'locality',
}

export const ALLOWED_MAP_COUNTRY_CODES = [CountryCodes.DE]

export const MAP_AUTOCOMPLETE_REQUEST_OPTIONS: RequestOptions = {
  componentRestrictions: { country: 'de' },
}

export const MAP_ICON_IMG_EL = document.createElement('img')
MAP_ICON_IMG_EL.src = '/map/marker.svg'
