import { YomeHash } from 'src/constants/navigate.constants'
import i18n from 'src/i18n'
import { LANG_COOKIE_NAME, LanguageOptions, savedLanguage, userLanguage } from 'src/models/language.model'
import { setCookie } from 'src/services/cookie.service'

const setLanguage = (language: LanguageOptions) => {
  i18n.changeLanguage(language)
  localStorage.setItem(LANG_COOKIE_NAME, language)
  setCookie(LANG_COOKIE_NAME, language)
}

export const handleLanguageChange = (language: LanguageOptions) => {
  if (language === LanguageOptions.AR) {
    window.location.hash = YomeHash.unavailable
  } else {
    setLanguage(language)

    // TODO: find a way to reload Google Map script without page reload
    window.location.reload()
  }
}

export const getInitialLanguage = () => {
  let initialLanguage = Object.values(LanguageOptions).includes(userLanguage) ? userLanguage : LanguageOptions.EN

  if (savedLanguage) {
    initialLanguage = savedLanguage
  }

  return initialLanguage
}
