import { createContext, ReactNode, useContext } from 'react'

import { TUserPreferences } from 'src/models/userPreferences.model'
import { useGetUserPreferences } from 'src/services/userPreferences.service'

const UserPreferencesContext = createContext<TUserPreferences | null>(null)

export const UserPreferencesProvider = ({ children }: { children: ReactNode }) => {
  const { data: userPreferences } = useGetUserPreferences()

  return (
    <UserPreferencesContext.Provider value={userPreferences || null}>
      {children}
    </UserPreferencesContext.Provider>
  )
}

export const useUserPreferencesContext = () => useContext(UserPreferencesContext)
