import YomeAPI from 'src/constants/network.constants'
import SWRkeys from 'src/constants/swr.constants'
import { IFavoriteCountersResponse } from 'src/models/favorite.model'
import { IFavoriteActionParams } from 'src/models/post.model'
import { ISavedSearch } from 'src/models/search.model'
import { sendDelete, sendGet, sendPost } from 'src/utils/network.utils'
import useSWRImmutable from 'swr/immutable'

export const handleFavorite = async ({
  slug,
  method,
}: IFavoriteActionParams) => {
  const endPoint = YomeAPI.favoriteOffer(slug)

  return method === 'add' ? sendPost(endPoint) : sendDelete(endPoint)
}

export const useGetSavedSearchList = () => useSWRImmutable<ISavedSearch[]>(
  SWRkeys.getSavedSearch,
  sendGet,
  { revalidateOnMount: true },
)

export const useGetFavoriteCounters = () => useSWRImmutable<IFavoriteCountersResponse>(
  SWRkeys.favoriteCounters,
  sendGet,
  { revalidateOnMount: true },
)
