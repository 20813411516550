import ComingSoonEmptyState from 'src/components/emptyStateMessage/ComingSoonEmptyState'
import { I18N_WALLET } from 'src/constants/i18n.constants'
import ProfileHeading from '../ProfileHeading'

const WalletRoute = () => (
  <div>
    <ProfileHeading
      label={`${I18N_WALLET}.heading`}
    />
    <ComingSoonEmptyState />
  </div>
)

export default WalletRoute
