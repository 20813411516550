import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import InfoModal from 'src/components/modal/infoModal/InfoModal'
import ModalLayout from 'src/components/modal/ModalLayout'
import { I18N_GLOBAL, I18N_HELP_FORM_ERROR } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'

import { IErrorModalStatus } from './HelpRoute'

import styles from './helpRoute.module.scss'

export const CODE_VERIFICATION_MODAL_ERROR_STATUS = {
  OFF: 'OFF',
  FORBIDDEN: 'FORBIDDEN',
  TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
}

const modalContent = {
  [CODE_VERIFICATION_MODAL_ERROR_STATUS.TOO_MANY_REQUESTS]: {
    title: 'tooManyRequests.title',
    icon: 'something-went-wrong-2',
    text: 'tooManyRequests.text',
  },
  [CODE_VERIFICATION_MODAL_ERROR_STATUS.FORBIDDEN]: {
    title: 'forbidden.title',
    icon: 'spam',
    text: 'forbidden.text',
  },
  [CODE_VERIFICATION_MODAL_ERROR_STATUS.INTERNAL_SERVER_ERROR]: {
    title: 'internalServerError.title',
    icon: 'problem-1',
    text: 'internalServerError.text',
  },
}

export interface IHelpRouteResponseErrorProps {
  close: ()=>void;
  type: IErrorModalStatus;
}

const HelpRouteResponseErrorModal = ({ close, type }: IHelpRouteResponseErrorProps) => {
  const modalFooterButton = (
    <RegularButton
      id={ID.supportPageLinkToMainPage}
      buttonType={RegularButtonTypes.main}
      label={`${I18N_GLOBAL}.gotIt`}
      onClick={close}
    />
  )

  return (
    <ModalLayout handleClose={close} >
      <InfoModal
        modalFooter={modalFooterButton}
        className={styles.successWrapper}
      >
        <EmptyStateMessage
          icon={modalContent[type].icon}
          title={`${I18N_HELP_FORM_ERROR}.${modalContent[type].title}`}
          titleIconProps={{ name: 'alert-error' }}
          text={`${I18N_HELP_FORM_ERROR}.${modalContent[type].text}`}
        />
      </InfoModal>
    </ModalLayout>
  )
}

export default HelpRouteResponseErrorModal
