import { memo, PropsWithChildren, useEffect } from 'react'
import ReactDOM from 'react-dom'

const modalRoot = document.getElementById('modal')

const ModalPortal = ({ children }: PropsWithChildren) => {
  const el = document.createElement('div')

  useEffect(() => {
    if (!modalRoot) {
      return undefined
    }

    modalRoot.appendChild(el)

    return () => {
      modalRoot.removeChild(el)
    }
  }, [el])

  useEffect(() => {
    document.body.classList.add('modal-open')

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return ReactDOM.createPortal(children, el)
}

export default memo(ModalPortal)
