import { ReactNode } from 'react'
import classNames from 'classnames'

import ModalPortal from './ModalPortal'

import styles from './modal.module.scss'

interface IModalOverlayProps {
  handleClose?: () => void;
  overlayClassName?: string;
  children: ReactNode;
}

const ModalOverlay = ({ handleClose, overlayClassName, children }: IModalOverlayProps) => (
  <ModalPortal>
    <div className={styles.main}>
      <div className={classNames(styles.overlay, overlayClassName)} onClick={handleClose}></div>
      { children }
    </div>
  </ModalPortal>
)

export default ModalOverlay
