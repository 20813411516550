import { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage, { EmptyStateMessageType } from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_POST, I18N_POST_NOT_FOUND } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'

interface PostNotFoundProps {
  isDeleted?: boolean;
}

const PostNotFound = ({ isDeleted = false }: PostNotFoundProps) => {
  const description = isDeleted ? `${I18N_POST}.deleted.description` : `${I18N_POST_NOT_FOUND}.description`

  const buttonProps = {
    id: ID.notFoundLinkToMainPage,
    buttonType: RegularButtonTypes.main,
    label: `${I18N_POST_NOT_FOUND}.button`,
    linkTo: YomeURL.mainPage,
  }

  return (
    <EmptyStateMessage
      title={`${I18N_POST_NOT_FOUND}.title`}
      text={description}
      type={EmptyStateMessageType.horizontal}
      icon='error404'
      button={buttonProps}
    />
  )
}

export default PostNotFound
