import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useViewport } from 'src/contexts/viewportContext'
import { OfferStatus } from 'src/models/offerStatus.model'

import { I18N_MY_OFFER_CARD } from '../../constants/i18n.constants'
import { IPropsWithOffer } from '../../models/post.model'
import { getPostRemainingDays } from '../../utils/post.utils'
import DurationLine from './DurationLine'

import styles from './styles.module.scss'

const getDurationLabelKey = (status: OfferStatus) => {
  if (status === OfferStatus.expired) {
    return 'expired'
  }

  if (status === OfferStatus.draft) {
    return 'untilRemovalDays'
  }

  if (status === OfferStatus.moderating) {
    return 'moderating'
  }

  return 'expiresIn'
}

const Duration: React.FC<IPropsWithOffer> = ({ offer }) => {
  const { expiredAt, deletedAt, status, publishedAt } = offer
  const { isDesktop } = useViewport()
  const { t } = useTranslation()

  const isDraft = status === OfferStatus.draft
  const isModerating = status === OfferStatus.moderating

  // offer on moderating status which come from draft status (i.e. does not have publishedAt) has no duration
  const hasEmptyDuration = !publishedAt && isModerating

  const durationLineDateWidth = isDraft ? deletedAt : expiredAt
  const remainingDaysValue = getPostRemainingDays(offer)

  const remainingDays = remainingDaysValue > 0 ? remainingDaysValue : 0

  return (
    <>
      <p className={classNames(isDesktop ? 'bodySizeM' : 'supportive-14', styles.caption)}>
        {t(`${I18N_MY_OFFER_CARD}.${getDurationLabelKey(status)}`, { remainingDays })}
      </p>
      {isDesktop && (
        <div className={styles.main}>
          <DurationLine date={durationLineDateWidth} isEmptyDuration={hasEmptyDuration} />
        </div>
      )}
    </>
  )
}

export default Duration
