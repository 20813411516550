import { memo } from 'react'

import { PROFILE_MENU_SECTIONS } from 'src/models/profile.model'

import { useViewport } from 'src/contexts/viewportContext'
import { TDropdownSections } from 'src/models/dropdown.model'
import { handleLogout } from 'src/utils/auth.utils'
import ProfileSection from './ProfileSection'

import styles from './sections.module.scss'

const ProfileSectionList = () => {
  const { isDesktop } = useViewport()

  const dropdownSections: TDropdownSections = {
    ...PROFILE_MENU_SECTIONS,
    logout: {
      logout: {
        icon: 'logout',
        onClick: handleLogout,
      },
    },
  }

  const sections = isDesktop ? PROFILE_MENU_SECTIONS : dropdownSections

  return (
    <div className={styles.main}>
      {Object.keys(sections).map((section) => (
        <ProfileSection key={section} section={sections[section]} />
      ))}
    </div>
  )
}

export default memo(ProfileSectionList)
