import classNames from 'classnames'
import React from 'react'

import { I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { useI18nLanguage } from 'src/hooks/i18n.hooks'
import { useHashUrl } from 'src/hooks/router.hooks'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from '../button/RegularButton'

import styles from './header.module.scss'

interface IHeaderButtons {
  isUser: boolean;
}

const HeaderAuthButton = () => (
  <RegularButton
    linkTo={useHashUrl(YomeHash.auth)}
    buttonType={RegularButtonTypes.main}
    label={`${I18N_GLOBAL}.join`}
    id={ID.headerLinkToLogin}
    className={styles.authBtn}
    size={RegularButtonSizes.small}
  />
)

const HeaderButtons: React.FC<IHeaderButtons> = ({ isUser }) => (
  <div className={classNames(styles.buttons, styles[useI18nLanguage()])}>
    {!isUser && <HeaderAuthButton />}
    <RegularButton
      id={ID.headerLinkToNewOffer}
      linkTo={YomeURL.newOffer}
      label={`${I18N_GLOBAL}.newItem`}
      buttonType={RegularButtonTypes.main}
      size={RegularButtonSizes.small}
      className={styles.newItemBtn}
    />
  </div>
)

export default React.memo(HeaderButtons)
