import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import IconButton from 'src/components/button/IconButton'
import { FormSearch } from 'src/components/form'
import Heading from 'src/components/heading/Heading'
import ModalContent from 'src/components/modal/mobileModals/ModalContent'
import { I18N_CATEGORIES, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { MAX_SUGGESTIONS_DISPLAY } from 'src/constants/newOffer.constants'
import { useCategoryLevel } from 'src/hooks/categories.hooks'
import CategoryDropdownLevel from 'src/routes/search/components/categorySearchDropdown/CategoryDropdownLevel'
import { getLevelCategories, useCategorySearchSuggestions } from 'src/utils/category.utils'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import { getPathToCurrentCategory } from 'src/yome-categories-fields-config/utils/category.utils'

import CategorySearchResults from '../categorySearchResults/CategorySearchResults'

import styles from './mobileNewItemCategory.module.scss'

interface ICategorySearchDropdownProps {
  submitCategory: (categoryPath: string[]) => void;
  searchFieldName: string;
}

const MobileNewItemCategory = ({ submitCategory, searchFieldName }: ICategorySearchDropdownProps) => {
  const navigate = useNavigate()
  const [suggestions, setSuggestions] = useState<string[]>([])
  const categorySearchSuggestions = useCategorySearchSuggestions()

  const { values } = useFormState()

  useEffect(() => {
    if (values.searchQuery) {
      setSuggestions(categorySearchSuggestions(values.searchQuery.trim(), MAX_SUGGESTIONS_DISPLAY))
    }
  }, [categorySearchSuggestions, searchFieldName, values.searchQuery])

  const [selectedPath, setSelectedPath] = useState([ANY_CATEGORY])
  const initialLevel = selectedPath.length > 1 ? selectedPath.length - 2 : 0

  const { level, goLevelBack, goToNextLevel } = useCategoryLevel(initialLevel, setSelectedPath)
  const { currentLevelPath, parent, categories } = getLevelCategories(level, selectedPath)

  const searchQuery: string = values[searchFieldName]
  const showSearchResults = searchQuery && searchQuery.length > 0

  const handleClose = useCallback(() => navigate(YomeURL.offers), [navigate])

  const onFinalCategoryClick = useCallback((value: string) => {
    const finalPath = selectedPath.slice(1).concat(value)
    submitCategory(finalPath)
  }, [selectedPath, submitCategory])

  const suggestionsMemo = useMemo(() => suggestions.map((item) => getPathToCurrentCategory(item)),
    [suggestions, values.searchQuery])

  return (
    <div className={classNames(styles.container, 'mobile-paddings')}>
      <div className={styles.header}>
        <Heading label={`${I18N_GLOBAL}.newItem`} className='mobile-h4' />
        <IconButton iconName='close-cross' size={24} onClick={handleClose} id={ID.modalCloseButton} />
      </div>
      <ModalContent
        goBack={level > 0 ? goLevelBack : undefined}
        title={`${I18N_CATEGORIES}.${parent}`}
      >
        {level === 0 && (
          <FormSearch
            name={searchFieldName}
            id={ID.newItemSearchForm}
            placeholder={`${I18N_CATEGORIES}.search`}
            fieldWrapperProps={{
              name: searchFieldName,
              className: styles.search,
            }}
          />
        )}
        {showSearchResults ? (
          <CategorySearchResults
            categoryPathList={suggestionsMemo}
            submitCategory={submitCategory}
            searchFieldName={searchFieldName}
          />
        ) : (
          <CategoryDropdownLevel
            key={parent}
            items={categories}
            path={currentLevelPath}
            openSubcategory={goToNextLevel}
            submitCategory={onFinalCategoryClick}
            active={selectedPath[level + 1]}
            hasIcon={level < 2}
          />
        )}
      </ModalContent>
    </div>
  )
}

export default MobileNewItemCategory
