import { nanoid } from 'nanoid'
import { CloseButtonProps } from 'react-toastify/dist/components'

import { concatId, ID } from 'src/constants/id.constants'

import IconButton from '../button/IconButton'

const ToastCloseButton = ({ closeToast }: CloseButtonProps) => (
  <IconButton onClick={closeToast} iconName='close-cross' id={concatId(ID.toastCloseButton, nanoid())} size={16} />
)

// doesn't work with memo
export default ToastCloseButton
