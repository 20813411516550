import classNames from 'classnames'
import React from 'react'
import { Field, FieldInputProps } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import form from '../form.module.scss'

type FormCheckboxProps<T> = Partial<FieldInputProps<T> & {
  value: string | number | readonly string[] | undefined;
}> & {
  name: string;
  label: string;
  labelClassName?: string;
  supportingText?: string;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const FormCheckbox = React.memo(<T,>({ name,
  label,
  labelClassName,
  className,
  supportingText,
  ...props }: FormCheckboxProps<T>) => {
  const { t } = useTranslation()

  return (
    <Field name={name} type='checkbox'>
      {({ input }) => (

        <label className={classNames(form.checkbox, className)} htmlFor={name}>
          <input {...input} {...props} id={name} />
          <div className={form.text}>
            <span className={classNames('body-text', labelClassName)}>
              {t(label)}
            </span>
            {supportingText && (
              <p className={classNames('supportive-14', form.supportiveText)}>
                {t(supportingText)}
              </p>
            )}
          </div>

        </label>

      )}
    </Field>

  )
})

export default FormCheckbox
