import { useLocation } from 'react-router-dom'

import { ADVERTISING_IMAGES_PATH } from 'src/constants/staticPages.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { usePageTitle } from 'src/utils/hooks.utils'
import { getStaticPageMetaTitle } from 'src/utils/meta.utils'
import AdvertisingHeader from './components/advertisingHeader/AdvertisingHeader'
import Features from './components/features/Features'
import Formats from './components/formats/Formats'
import Stats from './components/stats/Stats'
import TargetingToolkit from './components/targetingToolkit/TargetingToolkit'

import styles from './advertising.module.scss'

const AdvertisingRoute = () => {
  const { isDesktop } = useViewport()
  const imageSrc = isDesktop ? 'headerDesktop' : 'header'
  const { pathname } = useLocation()
  usePageTitle(getStaticPageMetaTitle(pathname))

  return (
    <div className='static-page-wrapper'>
      <img className={styles.headerImage} src={`${ADVERTISING_IMAGES_PATH}/${imageSrc}.png`} />
      <AdvertisingHeader />
      <Stats />
      <Features />
      <TargetingToolkit />
      <Formats />
    </div>
  )
}

export default AdvertisingRoute
