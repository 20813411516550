import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import 'react-toastify/dist/ReactToastify.css'

import MapProvider from './components/map/MapProvider'
import ToastContainer from './components/toast/ToastContainer'
import TooltipContainer from './components/tooltip/TooltipContainer'
import i18n from './i18n'
import reportWebVitals from './reportWebVitals'
import Router from './Router'
import { initSentry } from './utils/browser.utils'

import './styles/index.scss'

initSentry()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

root.render(
  <I18nextProvider i18n={i18n}>
    <MapProvider>
      <Router />
    </MapProvider>
    <ToastContainer />
    <TooltipContainer />
  </I18nextProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
