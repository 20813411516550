import classNames from 'classnames'
import { useCallback } from 'react'

import SvgIcon from '../svgIcon/SvgIcon'

import mapStyles from './map.module.scss'

interface ISuggestionProps {
  onSelect: (address: string) => void;
  fullAddress: string;
  addressToSearch: string;
  secondaryPartAddress?: string;
  inputValue?: string;
  hasIcon?: boolean;
  isDisplayHistory?: boolean;
}

const splitSuggestionMainText = (value: string, inputValue: string | undefined) => {
  if (!inputValue) {
    return [value]
  }

  const inputLen = inputValue.length
  const inputIndex = value.toUpperCase().indexOf(inputValue.toUpperCase())

  if (inputIndex < 0) {
    return [value]
  }

  const inputEndIndex = inputIndex + inputLen

  if (inputIndex === 0) {
    // when input value is in the beginning of suggestion, e.g. "Berlin" in "Berlin, Deutschland"
    return [
      '',
      value.substring(inputIndex, inputEndIndex),
      value.substring(inputLen),
    ]
  }

  // when input value is in the middle/end of suggestion, e.g. "ünchen" in "München, Deutschland"
  return [
    value.substring(0, inputIndex),
    value.substring(inputIndex, inputEndIndex),
    value.substring(inputEndIndex),
  ]
}

const Suggestion = ({
  fullAddress,
  addressToSearch,
  secondaryPartAddress,
  inputValue,
  hasIcon,
  onSelect,
  isDisplayHistory,
}: ISuggestionProps) => {
  const [mainTextStart, mainTextBold, mainTextEnd] = splitSuggestionMainText(addressToSearch, inputValue)

  const handleSelect = useCallback(() => {
    onSelect(fullAddress)
  }, [onSelect, fullAddress])

  const isHidden = isDisplayHistory ? false : hasIcon && inputValue && !mainTextBold

  if (isHidden) {
    return null
  }

  /**
   * onMouseDown is required to prevent blur event on the search input
   */

  return (
    <li
      className={mapStyles.autocomlete}
      onMouseDown={handleSelect}
    >
      <span className={classNames('bodySizeS', mapStyles.item)}>
        {hasIcon && (
          <div className={mapStyles.icon} >
            <SvgIcon name="clock-progress" />
          </div>
        )}
        <span className={mapStyles.primary}>
          {mainTextStart}
          {mainTextBold && (
            <strong>
              {mainTextBold}
            </strong>
          )}
          {mainTextEnd}
          {!!secondaryPartAddress && ',\u00A0'}
        </span>
        <span className={mapStyles.secondary}>
          {secondaryPartAddress}
        </span>
      </span>
      <span className={mapStyles.divider} />
    </li >
  )
}

export default Suggestion
