import pick from 'lodash-es/pick'
import React, { useCallback } from 'react'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Dropdown from 'src/components/dropdown/Dropdown'
import ArchiveModal from 'src/components/modal/archiveModal/ArchiveModal'
import { ELLIPSIS } from 'src/constants/general.constants'
import { I18N_MY_OFFER_CARD } from 'src/constants/i18n.constants'
import { generateIdWithPrefix, IdStructure, PrefixAction } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useProfileOfferListsReset } from 'src/hooks/offer.hooks'
import { OFFER_STATUS_TO_PERMITTED_ACTIONS, OfferActions } from 'src/models/offerActions.model'
import { OfferStatus } from 'src/models/offerStatus.model'
import { IPropsWithOffer, PostListType } from 'src/models/post.model'
import SuccessOfferPosted from 'src/routes/newItem/components/form/SuccessOfferPosted'
import { handleDeletePost } from 'src/services/post.service'
import { useToggle } from 'src/utils/hooks.utils'
import { handleRepublishOffer } from 'src/utils/post.utils'

import MyOfferCardStats from './myOfferCardStats/MyOfferCardStats'

import styles from '../styles.module.scss'

interface IMyOfferCardRightStack extends IPropsWithOffer {
  idPrefix: IdStructure;
  mutator: () => void;
  listType: PostListType;
}

const MyOfferCardRightStack: React.FC<IMyOfferCardRightStack> = ({ idPrefix, mutator, offer, listType }) => {
  const [isArchiveModalOpen, toggleArchiveModal] = useToggle(false)
  const { isDesktop } = useViewport()
  const [isSuccessOpen, toggleSuccessOpen] = useToggle()
  const { resetOfferList } = useProfileOfferListsReset(PostListType.active)

  const { slug, statistic } = offer
  const isDraft = offer.status === OfferStatus.draft
  const linkToEdit = isDraft ? YomeURL.editDraft : YomeURL.editOffer

  const handleRepublish = useCallback(() => {
    handleRepublishOffer(slug, toggleSuccessOpen, resetOfferList)
  }, [slug, toggleSuccessOpen, resetOfferList])

  const postActions = {
    [OfferActions.archive]: {
      onClick: toggleArchiveModal,
    },
    [OfferActions.edit]: {
      linkTo: linkToEdit(offer.slug),
    },
    [OfferActions.delete]: {
      onClick: () => handleDeletePost(offer, mutator),
    },
    [OfferActions.promote]: {
      linkTo: YomeHash.unavailable,
    },
    [OfferActions.republish]: {
      onClick: handleRepublish,
    },
  }

  const actionsListForStatus = OFFER_STATUS_TO_PERMITTED_ACTIONS(offer.publishedAt)[offer.status]

  const buttonPropsMap = {
    [OfferStatus.active]: { linkTo: YomeHash.unavailable, label: `${I18N_MY_OFFER_CARD}.btn.promote` },
    [OfferStatus.moderating]: { linkTo: linkToEdit(offer.slug), label: `${I18N_MY_OFFER_CARD}.btn.edit` },
    [OfferStatus.expired]: { onClick: handleRepublish, label: `${I18N_MY_OFFER_CARD}.btn.republish` },
    [OfferStatus.closed]: { onClick: handleRepublish, label: `${I18N_MY_OFFER_CARD}.btn.republish` },
    [OfferStatus.draft]: { linkTo: linkToEdit(offer.slug), label: `${I18N_MY_OFFER_CARD}.btn.edit` },
    [OfferStatus.declined]: { linkTo: linkToEdit(offer.slug), label: `${I18N_MY_OFFER_CARD}.btn.edit` },
    [OfferStatus.blocked]: {},
    [OfferStatus.deleted]: {},
  }

  const onSuccessModalClose = useCallback(() => {
    mutator()
    toggleSuccessOpen()
  }, [mutator, toggleSuccessOpen])

  return (
    <div className={styles.rightStack}>
      {isDesktop && (
        <div className={styles.buttonsBlock}>
          <div className={styles.buttonsRow}>
            <RegularButton
              id={generateIdWithPrefix(idPrefix, PrefixAction.Apply, slug)}
              buttonType={RegularButtonTypes.blackNoBorder}
              className={styles.btn}
              {...buttonPropsMap[offer.status]}
            />
            <Dropdown
              id={idPrefix}
              sections={{ section: pick(postActions, actionsListForStatus) }}
              className={styles.dropdown}
            >
              <div className={styles.ellipsisButton}>
                <RegularButton
                  id={generateIdWithPrefix(idPrefix, PrefixAction.Toggle, slug)}
                  buttonType={RegularButtonTypes.transparentWithBorder}
                  label={ELLIPSIS}
                />
              </div>
            </Dropdown>
          </div>
          {isArchiveModalOpen && (
            <ArchiveModal
              handleClose={toggleArchiveModal}
              offer={offer}
              mutate={mutator}
            />
          )}
        </div>
      )}
      {listType !== PostListType.draft && (
        <MyOfferCardStats
          idPrefix={idPrefix}
          stats={statistic}
          isOneRow={!isDesktop}
          haveMessengerStatistic
        />
      )}
      { isSuccessOpen && (
        <SuccessOfferPosted
          category={offer.category!}
          slug={offer.slug}
          title={offer.title}
          onClose={onSuccessModalClose}
        />
      )}
    </div>
  )
}

export default MyOfferCardRightStack
