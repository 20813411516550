import classNames from 'classnames'
import { useEffect, useRef } from 'react'

import { DEFAULT_MAP_ZOOM } from 'src/constants/map.constants'
import { useMapCircle, useMapInitialisation, useMapMarker } from 'src/hooks/map.hooks'
import { IMapComponentProps } from 'src/models/map.model'
import { captureException } from 'src/utils/browser.utils'
import { convertLatLng, getAddressByLatLng } from 'src/utils/map.utils'

import { isEqual } from 'lodash-es'
import mapStyles from './map.module.scss'

const MapComponent = ({
  onAddressChange,
  radius,
  center,
  mapClassName,
  markerPosition,
  skipPositionResolving,
  zoom = DEFAULT_MAP_ZOOM,
  hasOnlyCity = false,
  hasMapError = false,
}: IMapComponentProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const map = useMapInitialisation(ref, center, zoom)

  useMapMarker(map, markerPosition)
  const circle = useMapCircle(map, radius)

  useEffect(() => {
    const hasAddressChangeHandler = map && center && onAddressChange

    if (hasAddressChangeHandler) {
      google.maps.event.addListener(map, 'idle', () => {
        const mapCenter = map.getCenter()

        if (!mapCenter) {
          captureException(new Error("Can't get map center"))

          return
        }

        const position = convertLatLng(mapCenter)
        const hasBeenRecentred = !isEqual(position, skipPositionResolving)

        if (hasBeenRecentred) {
          getAddressByLatLng(position, hasOnlyCity)
            .then((address = '') => {
              onAddressChange({ address, position })
            })
            .catch(captureException)
        }

        circle?.setCenter(mapCenter)
      })
    }

    return () => {
      if (hasAddressChangeHandler) {
        google.maps.event.clearListeners(map, 'idle')
      }
    }
  }, [onAddressChange, map, circle, center, skipPositionResolving, hasOnlyCity])

  const hasMapCenterMarker = center || radius

  return (
    <div
      ref={ref}
      className={classNames(
        mapStyles.map,
        mapClassName,
        hasMapCenterMarker && mapStyles.centerPointer,
        hasMapError && mapStyles.markerError,
      )}
    />
  )
}

export default MapComponent
