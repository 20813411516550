import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_LEGAL_SAFETY } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'

const SafetySupportBlock = () => (
  <div className='static-block'>
    <Heading
      level='h4'
      label={`${I18N_LEGAL_SAFETY}.support.label`}
      className='static-block-subheading'
    />
    <Heading
      level='h2'
      label={`${I18N_LEGAL_SAFETY}.support.heading`}
      className='static-block-heading'
    />
    <Paragraph
      text={`${I18N_LEGAL_SAFETY}.support.text`}
      className='static-block-text'
    />
    <RegularButton
      buttonType={RegularButtonTypes.blackNoBorder}
      id={ID.legalLinkToSafety}
      label={`${I18N_LEGAL_SAFETY}.support.button`}
      linkTo={YomeURL.help}
      className='static-block-button'
    />
  </div>
)

export default SafetySupportBlock
