import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import YomeURL from 'src/constants/navigate.constants'
import SvgIcon, { SvgColorAttr } from 'src/components/svgIcon/SvgIcon'

import styles from './tabBarButton.module.scss'

interface ITabBarButton {
  id: string;
  linkTo: string;
  label: string;
  icon: string;
  hasNotifications?: boolean;
  currentColor?: SvgColorAttr;
}

const isButtonActive = (pathname: string, linkTo: string) => {
  const isFavoritesPage = pathname.includes(YomeURL.favorites)
  const isOfferssPage = pathname.includes(YomeURL.offers)

  if (isFavoritesPage || isOfferssPage) {
    // take 2 first parts (exmple /profile/offers/draft -> /profile/offers )
    const shortPathName = pathname.split('/').slice(0, 3).join('/')

    return shortPathName === linkTo
  }

  const isProfilePage = pathname.includes(YomeURL.profilePage)

  if (isProfilePage) {
    return linkTo === YomeURL.profilePage
  }

  return pathname === linkTo
}

const TabBarButton = (
  { id, linkTo, label, icon, hasNotifications = false, currentColor = 'stroke' }: ITabBarButton,
) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const isActive = isButtonActive(pathname, linkTo)

  return (
    <NavLink
      id={id}
      to={linkTo}
      className={classNames(styles.tabbarButton, 'label-12', { [styles.active]: isActive })}
    >
      <SvgIcon
        name={icon}
        size={24}
        currentColor={currentColor}
        className={classNames(styles.icon, { [styles.hasNotification]: hasNotifications })}
      />
      {t(label)}
    </NavLink>
  )
}

export default TabBarButton
