import { memo } from 'react'

import LegalPageTitle from '../LegalPageTitle'
import PrivacyPolicyChanges from './de/PrivacyPolicyChanges'
import PrivacyPolicyIntro from './de/PrivacyPolicyIntro'
import PrivacyPolicyNewsletter from './de/PrivacyPolicyNewsletter'
import PrivacyPolicyProcessing from './de/PrivacyPolicyProcessing'
import PrivacyPolicyQuestions from './de/PrivacyPolicyQuestions'
import PrivacyPolicySocial from './de/PrivacyPolicySocial'

import styles from '../legalLayout.module.scss'

const PrivacyPolicyDE = () => (
  <article>
    <LegalPageTitle
      label='Datenschutzerklärung'
    />
    <div className={styles.content}>
      <PrivacyPolicyIntro />
      <PrivacyPolicyNewsletter />
      <PrivacyPolicyProcessing />
      <PrivacyPolicySocial />
      <PrivacyPolicyChanges />
      <PrivacyPolicyQuestions />
    </div>
  </article>
)

export default memo(PrivacyPolicyDE)
