import { useEffect, useState } from 'react'

import { useCurrentUser } from 'src/services/currentUser.service'
import { useToggle } from 'src/utils/hooks.utils'

export const useCountDown = (initialCountDown: number) => {
  const [countDown, setCountDown] = useState(initialCountDown)
  const [trigger, triggerCountDown] = useToggle()

  useEffect(() => {
    const timer = setInterval(() => {
      setCountDown((time) => {
        if (time === 0) {
          clearInterval(timer)

          return 0
        }

        return time - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [trigger])

  return { countDown, setCountDown, triggerCountDown }
}

export const usePhoneVerificationNeeded = () => {
  const { data: currentUser, mutate } = useCurrentUser()

  // authorized users without phone verification
  return {
    isPhoneVerificationNeeded: (currentUser && !currentUser?.isPhoneVerified),
    mutateIsPhoneVerified: mutate,
  }
}
