import { Outlet } from 'react-router-dom'

import PrivateRoute from '../PrivateRoute'

import styles from './newItem.module.scss'

const PrivateNewItemLayout = () => (
  <PrivateRoute>
    <div className={styles.newItemContainer}>
      <Outlet />
    </div>
  </PrivateRoute>
)

export default PrivateNewItemLayout
