import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_FOR_BUSINESS } from 'src/constants/i18n.constants'
import { FOR_BUSINESS_STATS } from 'src/constants/staticPages.constants'

import { useViewport } from 'src/contexts/viewportContext'

const Stats = () => {
  const { isDesktop } = useViewport()

  return (
    <ul className={'static-stat-line'}>
      {FOR_BUSINESS_STATS.map((item) => (
        <li key={item.num} className={'static-stat-line-item'}>
          <Heading label={item.num} level="h2" className="mobile-h2 desktop-h2" />
          <Paragraph
            text={`${I18N_FOR_BUSINESS}.stats.${item.caption}`}
            className={isDesktop ? 'label-20' : 'label-18'}
          />
        </li>
      ))}
    </ul>
  )
}

export default Stats
