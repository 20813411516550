import FormMap from 'src/components/map/FormMap'
import { IFormMapProps } from 'src/models/map.model'
import useGetUserAddressHistory from 'src/services/userAddressHistory.service'

const OfferFormMap = (mapProps: IFormMapProps) => {
  const { data: userAddressHistory = [] } = useGetUserAddressHistory()

  return <FormMap {...mapProps} userAddressHistory={userAddressHistory} />
}

export default OfferFormMap
