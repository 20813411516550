import { useCallback, useEffect, useRef, useState } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { I18N_FORM_FIELD_NAME, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { assertFilesSizeLimit, assertImageType } from 'src/utils/form.utils'
import { useToggle } from 'src/utils/hooks.utils'
import {
  CountryIsoCode,
  FieldType,
  IFieldConfig,
  MainCategories,
} from 'src/yome-categories-fields-config/models/fields.model'

const DESCRIPTION_HEIGHT_TO_EXPAND_MOBILE = 254
const DESCRIPTION_HEIGHT_TO_EXPAND_DESKTOP = 820

// eslint-disable-next-line import/prefer-default-export
export const useAssertValidAttachment = (maxSize: number) => {
  const { t } = useTranslation()

  return useCallback((uploadedFiles: File[]) => {
    const isSizeLimitExeeded = assertFilesSizeLimit(uploadedFiles, maxSize, t)
    const isImages = assertImageType(uploadedFiles, t)

    return (!isSizeLimitExeeded && isImages)
  }, [maxSize, t])
}

export const useInputHandler = <T>(
  inputHandler: (e: T) => void,
  parentHandler?: (e: T) => void,
) => useCallback((e: T) => {
  if (parentHandler) {
    parentHandler(e)
  }

  inputHandler(e)
}, [inputHandler, parentHandler])

export const useInputBlurOnValueUpdate = (name: string) => {
  const { input: { value, onBlur } } = useField(name)

  useEffect(() => {
    // to trigger form update
    onBlur()
  }, [onBlur, value])
}

export const useTranslatedCountriesSorted = (countries: CountryIsoCode[]) => {
  const { t } = useTranslation()

  return countries.map((country) => ({
    translated: t(`${I18N_GLOBAL}.country.${country}`),
    isoCode: country,
  })).sort((a, b) => {
    if (a.translated === b.translated) {
      return 0
    }

    return a.translated < b.translated ? -1 : 1
  })
}

export const useFieldNameTranslation = () => {
  const { t } = useTranslation()

  return useCallback(({ type, name }: IFieldConfig, mainCategory: MainCategories) => {
    if (type === FieldType.location) {
      return t(`${I18N_FORM_FIELD_NAME}.${mainCategory}.${name}`)
    }

    return t(`${I18N_FORM_FIELD_NAME}.${name}`)
  }, [t])
}

export const useCollapseText = () => {
  const [isFullyOpen, toggleFullOpen] = useToggle()

  const { isDesktop } = useViewport()

  const descrRef = useRef<HTMLDivElement>(null)
  const descrHeigthLimit = isDesktop ? DESCRIPTION_HEIGHT_TO_EXPAND_DESKTOP : DESCRIPTION_HEIGHT_TO_EXPAND_MOBILE

  const [isTextExceeded, setTextExceeded] = useState(true)

  useEffect(() => {
    const descrHeight = descrRef.current?.getBoundingClientRect().height
    setTextExceeded(Boolean(descrHeight && descrHeight >= descrHeigthLimit))
  }, [descrRef, descrHeigthLimit])

  const buttonLabel = isFullyOpen ? 'Hide' : ''

  return { isFullyOpen, toggleFullOpen, descrRef, isTextExceeded, buttonLabel }
}
