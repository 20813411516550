import { FOR_BUSINESS_STEPS } from 'src/constants/staticPages.constants'
import SectionHeader from '../sectionHeader/SectionHeader'
import SingleStep from './SingleStep'

import styles from './steps.module.scss'

const Steps = () => (
  <section>
    <SectionHeader
      label={'steps.heading'}
      text={'steps.subheading'}
    />
    <ul className={styles.list}>
      {FOR_BUSINESS_STEPS.map((step, index) => {
        const isLastEl = index === FOR_BUSINESS_STEPS.length - 1

        return (
          <SingleStep
            key={step.label}
            isLastEl={isLastEl}
            index={index}
            {...step}
          />
        )
      })}
    </ul>
  </section>
)

export default Steps
