import classNames from 'classnames'
import React from 'react'
import SvgIcon from '../svgIcon/SvgIcon'

import styles from './loader.module.scss'

interface ILoader {
  size: number;
  className?: string;
}

const Loader: React.FC<ILoader> = ({ size, className }) => (
  <SvgIcon name='loader' size={size} className={classNames(styles.main, className)} />
)

export default Loader
