import SettingLine from '../SettingLine/SettingLine'
import { FormPassword } from '../../../../../components/form'
import { I18N_PROFILE_SETTING_GENERAL } from '../../../../../constants/i18n.constants'
import { ID, Subject } from '../../../../../constants/id.constants'
import {
  CONFIRM_PASSWORD_FIELDNAME,
  IPasswordChangeProfileFormValues,
  NEW_PASSWORD_FIELDNAME,
  PASSWORD_FIELDNAME,
} from '../../../../../models/auth.model'
import { changePasswordProfile } from '../../../../../services/auth.service'

import styles from './password.module.scss'

const PASSWORD_PLACEHOLDER = '********'

const PasswordSettingField = () => (
  <SettingLine<IPasswordChangeProfileFormValues>
    settingId={Subject.Password}
    title={`${I18N_PROFILE_SETTING_GENERAL}.password`}
    currentValue={PASSWORD_PLACEHOLDER}
    onSubmit={changePasswordProfile}
    btnLabel='change'
  >
    <fieldset className={styles.formContainer}>
      <FormPassword
        id={ID.profileSettingInputPassword}
        name={PASSWORD_FIELDNAME}
        fieldWrapperProps={{
          name: PASSWORD_FIELDNAME,
          label: `${I18N_PROFILE_SETTING_GENERAL}.oldPassword.label`,
        }}
      />
      <FormPassword
        id={ID.profileSettingInputNewPassword}
        name={NEW_PASSWORD_FIELDNAME}
        fieldWrapperProps={{
          name: NEW_PASSWORD_FIELDNAME,
          info: `${I18N_PROFILE_SETTING_GENERAL}.newPassword.info`,
          label: `${I18N_PROFILE_SETTING_GENERAL}.newPassword.label`,
        }}
      />
      <FormPassword
        id={ID.profileSettingInputPasswordConfirm}
        name={CONFIRM_PASSWORD_FIELDNAME}
        fieldWrapperProps={{
          name: PASSWORD_FIELDNAME,
          label: `${I18N_PROFILE_SETTING_GENERAL}.confirmNewPassword.label`,
        }}
      />
    </fieldset>
  </SettingLine>
)

export default PasswordSettingField
