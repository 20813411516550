import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import ArchiveModal from 'src/components/modal/archiveModal/ArchiveModal'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS, OfferActions, TOfferActionsMap } from 'src/models/offerActions.model'
import { IPropsWithOffer, SinglePost } from 'src/models/post.model'
import { useGetOffer } from 'src/services/post.service'
import { useToggle } from 'src/utils/hooks.utils'
import { getActionsListForValidPosts, isPostActive } from 'src/utils/post.utils'

import StatisticsSection from './OfferStatistics'

import styles from './styles.module.scss'

const ValidPostActions = ({ offer }: IPropsWithOffer) => {
  const [isModalOpen, toggleModal] = useToggle()
  const { mutate } = useGetOffer(SWRkeys.getPublicOffer(offer.slug!))

  const { isDesktop } = useViewport()

  if (!isPostActive(offer.status)) {
    return null
  }

  const offerActions: TOfferActionsMap = {
    [OfferActions.archive]: { onClick: toggleModal },
    [OfferActions.promote]: { linkTo: YomeHash.unavailable },
    [OfferActions.edit]: {
      linkTo: YomeURL.editOffer(offer.slug),
    },
  }

  const actionsListForStatus = getActionsListForValidPosts(isDesktop, offer.status, offer.publishedAt)

  return (
    <div className={styles.mainPostOwner}>
      <StatisticsSection offer={offer} />
      <div className={styles.postOwnerActionButtons}>
        {actionsListForStatus.map((action, index) => {
          const actionProps = action in offerActions ? offerActions[action] : null

          return offerActions && (
            <RegularButton
              key={action}
              buttonType={index === 0 ? RegularButtonTypes.main : RegularButtonTypes.transparentWithBorder}
              {...OFFER_PAGE_ACTIONS_TO_BUTTON_PROPS[action]}
              {...actionProps}
            />
          )
        })}
      </div>
      {isModalOpen && (
        <ArchiveModal<SinglePost> handleClose={toggleModal} offer={offer} mutate={mutate}/>
      )}
    </div>
  )
}

export default ValidPostActions
