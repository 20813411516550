import { useCallback } from 'react'
import { FormRenderProps } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton } from 'src/components/form'
import { I18N_FORM } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { PostFormFields } from 'src/models/post.model'

import FullOfferForm from './FullOfferForm'
import MultistepOfferForm from './MultistepOfferForm'

import styles from './offerForm.module.scss'

interface IEditDraftForm extends FormRenderProps<PostFormFields> {
  resetDraft: () => void;
  setPartIndex: (value: number) => void;
  partIndex: number;
}

const EditDraftForm = ({
  resetDraft,
  handleSubmit,
  setPartIndex,
  form: { getState },
  partIndex,
}: IEditDraftForm) => {
  const navigate = useNavigate()
  const { isDesktop } = useViewport()
  const { values, hasValidationErrors } = getState()

  const saveDraft = useCallback(() => {
    navigate(YomeURL.draftOffers)
    resetDraft()
  }, [navigate, resetDraft])

  return (isDesktop ? (
    <FullOfferForm
      publicId={values.publicId}
      categoryPath={values.categoryPath}
      handleSubmit={handleSubmit}
    >
      <div className={styles.buttonBlock}>
        <FormButton
          id={ID.editDraftPostDraft}
          disabled={hasValidationErrors}
          label={`${I18N_FORM}.button.placeOffer`}
        />
        <RegularButton
          id={ID.editDraftSaveDraft}
          buttonType={RegularButtonTypes.transparentWithBorder}
          label={'draft.save'}
          onClick={saveDraft}
        />
      </div>
    </FullOfferForm>
  ) : (
    <form onSubmit={handleSubmit} className='h-100'>
      <MultistepOfferForm
        categoryPath={values.categoryPath}
        publicId={values.publicId}
        partIndex={partIndex}
        setPartIndex={setPartIndex}
        isEditDraftPage
      />
    </form>
  ))
}

export default EditDraftForm
