import classNames from 'classnames'
import { cloneElement, ReactElement, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RegularButtonTypes } from 'src/components/button/RegularButton'
import MenuPoint, { MenuPointSize } from 'src/components/menu/MenuPoint'
import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import ModalContent from 'src/components/modal/mobileModals/ModalContent'
import { I18N_GLOBAL } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import { StringRecords } from 'src/models/common.model'
import { useToggle } from 'src/utils/hooks.utils'

import styles from './select.module.scss'

interface IFormSelectFullScreenBaseProps {
  name: string;
  label?: string;
  options: StringRecords;
  hasIcon?: boolean;
  selectedValue?: string;
  handleChange: (event: React.MouseEvent, key: string) => void;
}

interface IFormSelectFullScreenOptionsProps extends IFormSelectFullScreenBaseProps {
  toggleDropdown: () => void;
}

interface IFormSelectFullScreenProps extends IFormSelectFullScreenBaseProps {
  children: ReactElement;
}

const FormSelectFullScreenOptions = ({
  handleChange,
  toggleDropdown,
  name,
  label,
  options,
  hasIcon,
  selectedValue,
}: IFormSelectFullScreenOptionsProps) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState(selectedValue)

  const onSelectClick = useCallback((event: React.MouseEvent) => {
    if (selectedOption) {
      handleChange(event, selectedOption)
    }

    toggleDropdown()
  }, [toggleDropdown, handleChange, selectedOption])

  const selectButtonProps = {
    id: ID.formFieldSelectForm,
    buttonType: RegularButtonTypes.main,
    label: `${I18N_GLOBAL}.select`,
    onClick: onSelectClick,
  }

  return (
    <FullscreenModalLayout>
      <div className={classNames('h-100', 'mobile-paddings')}>
        <ModalContent
          title={t(label || `${I18N_GLOBAL}.select`)}
          handleClose={toggleDropdown}
          primaryButtonProps={selectButtonProps}
        >
          <ul className={styles.options}>
            {Object.entries(options).map(([key, text]) => (
              <MenuPoint
                key={key}
                id={concatId(ID.formFieldSelectForm, key)}
                label={t(text)}
                onClick={() => setSelectedOption(key)}
                isActive={key === selectedOption}
                iconRight={key === selectedOption ? 'check-tick' : undefined}
                size={MenuPointSize.LARGE}
                icon={hasIcon ? `${name}/${key}` : undefined}
              />
            ))}
          </ul>
        </ModalContent>
      </div>
    </FullscreenModalLayout>
  )
}

const FormSelectFullScreen = ({ children, ...other }: IFormSelectFullScreenProps) => {
  const [isDropdownOpen, toggleDropdown] = useToggle()
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      {cloneElement(children, { onClick: toggleDropdown, ref: buttonRef })}
      {isDropdownOpen && <FormSelectFullScreenOptions toggleDropdown={toggleDropdown} {...other}/>}
    </>
  )
}

export default FormSelectFullScreen
