import SortDropdown from 'src/components/SortDropdown/SortDropdown'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import { useSorting } from 'src/hooks/sort.hooks'
import { SortPostListTypes } from 'src/models/sort.model'

import FavoritesList from '../FavoriteList'

const FavoriteOffersRoute = () => {
  const { sortOfferList, sortBy } = useSorting()
  const { isDesktop } = useViewport()

  return (
    <>
      <SortDropdown
        idSortButton={ID.favoritesPageSortPost}
        defaultValue={sortBy}
        onChange={sortOfferList}
        sortOptions={Object.values(SortPostListTypes)}
      />
      <FavoritesList sortBy={sortBy}/>
      {!isDesktop && <ShowcaseArea type='mobile-big' />}
    </>
  )
}

export default FavoriteOffersRoute
