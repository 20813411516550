import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SORT_QPARAM } from 'src/models/search.model'

import { SortPostListTypes } from 'src/models/sort.model'
import { useGetValueFromUrlParam } from './query.hooks'

// eslint-disable-next-line import/prefer-default-export
export const useSorting = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const sortOfferList = useCallback((sortType: SortPostListTypes) => {
    searchParams.set(SORT_QPARAM, sortType)
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  const sortBy = useGetValueFromUrlParam(SORT_QPARAM, SortPostListTypes.newest)

  return { sortOfferList, sortBy }
}
