import { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { I18N_GLOBAL } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import SearchLocationContext from 'src/contexts/searchLocation.context'

import { ISearchLocation } from 'src/models/location.model'
import styles from '../../search.module.scss'

const CitySelectLabelSearch = ({ locationAddress, radius, neighborhood, station }: ISearchLocation) => {
  const { t } = useTranslation()
  const kmLabel = t(`${I18N_GLOBAL}.km`)
  const isNeighborhoodOrStation = neighborhood || station

  if (!locationAddress) {
    // TODO: add a loading animation
    return null
  }

  return (
    <>
      <span>
        <>
          {locationAddress}
        </>
        {radius && (
          <>
            , &nbsp;
            {`+${radius}${kmLabel}`}
          </>
        )}
        {isNeighborhoodOrStation && ','}
      </span>
      {isNeighborhoodOrStation && (
        <span className={styles.secondaryCityLabel}>
          {neighborhood ? `${neighborhood}, ${station}` : station}
        </span>
      )}
    </>
  )
}

const CitySelectLabel = () => {
  const { searchLocation } = useContext(SearchLocationContext)
  const isSearchLocation = useLocation().pathname === YomeURL.search

  if (!searchLocation?.locationAddress) {
    // TODO: add a loading animation
    return null
  }

  return (
    <span className={styles.citySelectLabel}>
      {!isSearchLocation ? (
        <span>
          {searchLocation.locationAddress}
        </span>
      ) : (
        <CitySelectLabelSearch {...searchLocation} />
      )}
    </span>
  )
}

export default memo(CitySelectLabel)
