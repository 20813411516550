import { isOfferOnModeration, isPostActive, isPostClosed, isPostDraft } from 'src/utils/post.utils'

import { IPropsWithOffer } from '../../../../../models/post.model'
import ClosedPostActions from './ClosedPostActions'
import DraftActions from './DraftActions'
import OnModerationActions from './OnModerationActions'
import ValidPostActions from './ValidPostActions'

const PostOwnerActions = ({ offer }: IPropsWithOffer) => {
  if (isPostClosed(offer.status)) {
    return <ClosedPostActions offer={offer} />
  }

  if (isOfferOnModeration(offer.status)) {
    return <OnModerationActions offer={offer} />
  }

  if (isPostActive(offer.status)) {
    return <ValidPostActions offer={offer} />
  }

  if (isPostDraft(offer.status)) {
    return <DraftActions offer={offer} />
  }

  return null
}

export default PostOwnerActions
