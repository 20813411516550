import { useCallback } from 'react'
import { Field, useForm } from 'react-final-form'
import classNames from 'classnames'

import { getLevelCategories } from 'src/utils/category.utils'
import { useCategoryLevel, useSelectedCategoryPath } from 'src/hooks/categories.hooks'
import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import { ID } from 'src/constants/id.constants'
import { I18N_CATEGORIES, I18N_GLOBAL } from 'src/constants/i18n.constants'
import ModalContent from 'src/components/modal/mobileModals/ModalContent'
import { RegularButtonTypes } from 'src/components/button/RegularButton'
import CategoryDropdownLevel from './CategoryDropdownLevel'

interface ICategorySearchDropdownProps {
  name: string;
  toggleDropdown: () => void;
}

const MobileCategoryMenu = ({ name, toggleDropdown }: ICategorySearchDropdownProps) => {
  const { change, submit } = useForm()

  const { selectedPath, setSelectedPath } = useSelectedCategoryPath()
  const initialLevel = selectedPath.length > 1 ? selectedPath.length - 2 : 0

  const { level, goLevelBack, goToNextLevel } = useCategoryLevel(initialLevel, setSelectedPath)
  const { currentLevelPath, parent, categories } = getLevelCategories(level, selectedPath)

  const submitCategory = useCallback((currentCategory: string) => {
    change(name, [currentCategory])
    submit()
    toggleDropdown()
  }, [change, submit, toggleDropdown, name])

  const submitCategoryCallback = useCallback(() => submitCategory(parent), [submitCategory, parent])

  const footerButtonProps = {
    id: ID.formFieldSelectForm,
    buttonType: RegularButtonTypes.main,
    label: `${I18N_GLOBAL}.showOffers`,
    onClick: submitCategoryCallback,
  }

  return (
    <Field name={name}>
      {() => (
        <FullscreenModalLayout>
          <div className={classNames('mobile-paddings', 'h-100')}>
            <ModalContent
              goBack={level > 0 ? goLevelBack : undefined}
              title={`${I18N_CATEGORIES}.${parent}`}
              handleClose={toggleDropdown}
              primaryButtonProps={footerButtonProps}
            >
              <CategoryDropdownLevel
                key={parent}
                items={categories}
                path={currentLevelPath}
                openSubcategory={goToNextLevel}
                submitCategory={submitCategory}
                active={selectedPath[level + 1]}
                hasIcon={level < 2}
              />
            </ModalContent>
          </div>
        </FullscreenModalLayout>
      )}
    </Field>
  )
}

export default MobileCategoryMenu
