import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ABOUT_US_MISSION } from 'src/constants/i18n.constants'

const Mission = () => (
  <div className='static-block'>
    <Heading
      level='h4'
      label={`${I18N_ABOUT_US_MISSION}.title`}
      className='static-block-subheading'
    />
    <Heading
      level='h2'
      label={`${I18N_ABOUT_US_MISSION}.subtitle`}
      className='static-block-heading'
    />
    <Paragraph
      text={`${I18N_ABOUT_US_MISSION}.caption`}
      className='static-block-text'
    />
  </div>
)

export default Mission
