import classNames from 'classnames'
import { useCallback } from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { FormInput } from 'src/components/form'
import Paragraph from 'src/components/paragraph/Paragraph'
import { INVALID_CODE } from 'src/constants/error.constants'
import { I18N_VERIFY_PHONE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useCountDown } from 'src/hooks/verificationCode.hooks'
import { IVerifyPhoneModalProps, PhoneCodeOptions, VerifyPhoneModals } from 'src/models/verifyPhone.model'
import { sendCall, sendSms, validateCode } from 'src/services/verifyPhone.service'
import { BadRequestError, SubmitFormError, TooManyRequestsError } from 'src/utils/error.utils'
import { getFullPhoneNumber } from 'src/utils/form.utils'
import { getCountDownText } from 'src/utils/verificationCode.utils'

import InfoModal from '../infoModal/InfoModal'

import styles from './verifyPhoneModals.module.scss'

interface IEnterCodeModalProps extends IVerifyPhoneModalProps {
  codeType: PhoneCodeOptions;
}

const WAITING_TIME = 2 * 60 // 2 min

const EnterCodeModal = ({ setCurrentModal, phoneNumber, codeType }: IEnterCodeModalProps) => {
  const { countDown, setCountDown, triggerCountDown } = useCountDown(WAITING_TIME)

  const { t } = useTranslation()

  const countDownText = getCountDownText(countDown)

  const handleNewNumberButton = useCallback(() => {
    setCurrentModal(VerifyPhoneModals.baseVerifyPhone)
  }, [setCurrentModal])

  const handleResendButton = useCallback(async () => {
    const sendCode = codeType === PhoneCodeOptions.sms ? sendSms : sendCall

    try {
      await sendCode(phoneNumber)
      setCountDown(WAITING_TIME)
      triggerCountDown()
    } catch (error) {
      if (error instanceof TooManyRequestsError) {
        setCurrentModal(VerifyPhoneModals.limitReached)
      } else if (error instanceof Error) {
        toast.error(t(error.message))
      }
    }
  }, [setCountDown, triggerCountDown, codeType, phoneNumber, t, setCurrentModal])

  const handleVerify = useCallback(async (values: { 'code': string }) => {
    const { code } = values

    try {
      await validateCode(phoneNumber, code)
      setCurrentModal(VerifyPhoneModals.verifySuccess)

      return undefined
    } catch (error) {
      if (error instanceof SubmitFormError) {
        if (error.message === INVALID_CODE) {
          setCurrentModal(VerifyPhoneModals.codeFailed)

          return undefined
        }

        return error.errorObject
      }

      if (error instanceof BadRequestError) {
        setCurrentModal(VerifyPhoneModals.codeFailed)
      } else if (error instanceof Error) {
        toast.error(t(error.message))
      }

      return undefined
    }
  }, [phoneNumber, setCurrentModal, t])

  const modalFooter = (
    <div className={styles.enterCodeFooter}>
      <RegularButton
        id={ID.verifyPhoneModalVerifyButton}
        buttonType={RegularButtonTypes.main}
        label={`${I18N_VERIFY_PHONE}.enterCode.verify`}
        className={styles.verifyButton}
        type='submit'
      />
      <div className={styles.footerResend}>
        <div>
          <div className={styles.oneLine}>
            <Paragraph className='bodySizeM'>
              {`${I18N_VERIFY_PHONE}.enterCode.resendText.${codeType}`}
            </Paragraph>
            <RegularButton
              id={ID.verifyPhoneModalResendButton}
              label={`${I18N_VERIFY_PHONE}.enterCode.resendButton.${codeType}`}
              buttonType={RegularButtonTypes.transparentNoBorderLight}
              className={styles.buttonFullWidth}
              onClick={handleResendButton}
              disabled={!!countDown}
            />
          </div>
          {!!countDown && (
            <p className={classNames('supportive-12', styles.counterDown)}>
              <Trans
                i18nKey={`${I18N_VERIFY_PHONE}.enterCode.conuterDown`}
                values={{ countDown: countDownText }}
              />
            </p>
          )}
        </div>
        <div className={styles.oneLine}>
          <Paragraph className='bodySizeM'>
            {`${I18N_VERIFY_PHONE}.enterCode.wrongNumber`}
          </Paragraph>
          <RegularButton
            id={ID.verifyPhoneModalLinkToPhone}
            label={`${I18N_VERIFY_PHONE}.enterCode.enterNewNumber`}
            onClick={handleNewNumberButton}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
            className={styles.buttonFullWidth}
          />
        </div>
      </div>
    </div>
  )

  return (
    <Form
      onSubmit={handleVerify}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={styles.verifyPhoneContainer}>
          <InfoModal modalFooter={modalFooter}>
            <EmptyStateMessage
              icon={'phone-verification'}
              iconSize={120}
              title={`${I18N_VERIFY_PHONE}.enterCode.title`}
            />
            <p className={classNames('bodySizeL', styles.mainText)}>
              <Trans
                i18nKey={`${I18N_VERIFY_PHONE}.enterCode.text.${codeType}`}
                values={{ phoneNumber: getFullPhoneNumber(phoneNumber) }}
                components={{ 1: <strong className={styles.noWrap}/>, 2: <br/> }}
              />
            </p>
            <FormInput name='code' id={ID.verifyPhoneModalInputPhone}/>
          </InfoModal>
        </form>

      )}
    />
  )
}

export default EnterCodeModal
