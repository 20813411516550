import { useMemo } from 'react'

import { ICurrentUser } from 'src/models/currentUser.model'
import BadgeList from 'src/routes/user/info/components/badge/BadgeList'
import { getUserBadges } from 'src/utils/user.utils'

import SettingSectionWrapper from '../settings/settingWrapper/SettingSectionWrapper'

import styles from './profileBadges.module.scss'

interface IProfileBadgesSettings {
  user: ICurrentUser;
}

const ProfileBadgesSettings = ({ user }: IProfileBadgesSettings) => {
  const badges = useMemo(() => getUserBadges(user), [user])

  if (!badges.length) {
    return null
  }

  return (
    <SettingSectionWrapper
      title={'profile.setting.profileBadgesSettings.title'}
      hint={'profile.setting.accountVerificationSettings.hint'}
    >
      <div className={styles.badgesContainer}>
        <BadgeList
          badgeList={badges}
          displayedBadgesNum={badges.length}
          badgeClassName={styles.settingsBadge}
          hasBadgeIcon
        />
      </div>
    </SettingSectionWrapper>
  )
}

export default ProfileBadgesSettings
