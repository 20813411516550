import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { mutate } from 'swr'

import { I18N_FAVORITES, I18N_POST_ACTION } from 'src/constants/i18n.constants'
import { IAddFavoriteResponse } from 'src/models/post.model'
import { handleFavorite } from 'src/services/favorite.service'
import { captureErrorAndShowToast } from 'src/utils/error.utils'

interface IUseFavorite {
  slug: string;
  isFavoritePost: boolean;
  offerSourceEndpoint: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useFavorite = ({ slug, isFavoritePost, offerSourceEndpoint }: IUseFavorite) => {
  const { t } = useTranslation()

  const handlePostLike = useCallback(async () => {
    const method = isFavoritePost ? 'remove' : 'add'

    try {
      const favoriteResponse = await handleFavorite({ slug, method })
      mutate(offerSourceEndpoint)
      const toastText = isFavoritePost ? `${I18N_FAVORITES}.removeToast` : `${I18N_FAVORITES}.addToast`
      toast.success(t(toastText))

      return favoriteResponse as IAddFavoriteResponse
    } catch (error) {
      captureErrorAndShowToast(error)

      return undefined
    }
  }, [mutate, isFavoritePost, slug])

  const favoriteIconName = isFavoritePost ? 'heart-filled' : 'heart'
  const favoriteButtonLabel = isFavoritePost ? (
    `${I18N_POST_ACTION}.addedToWishlist`
  ) : (
    `${I18N_POST_ACTION}.addToWishlist`
  )

  return { handlePostLike, favoriteIconName, favoriteButtonLabel }
}
