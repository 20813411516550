import classNames from 'classnames'
import React from 'react'

import SvgIcon from 'src/components/svgIcon/SvgIcon'

import styles from './info.module.scss'

export enum InfoButtonSizes {
  large = 'large',
  small = 'small',
}

interface IInfoButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  leftIcon?: string;
  rightIcon? : string;
  label?: string;
  buttonSize?: InfoButtonSizes;
  className?: string;
}

const InfoButton = (
  { onClick, leftIcon, rightIcon, label, buttonSize = InfoButtonSizes.large, className }: IInfoButtonProps,
  ref?: React.ForwardedRef<HTMLButtonElement>,
) => {
  const infoButtonClassName = classNames(
    className,
    styles.main,
    styles[buttonSize],
    buttonSize === InfoButtonSizes.large ? 'supportive-14' : 'supportive-12',
  )

  const children = (
    <>
      {leftIcon && <SvgIcon name={leftIcon} className={styles.left} size={20}/>}
      {label}
      {rightIcon && <SvgIcon name={rightIcon} className={styles.right} size={20}/>}
    </>
  )

  if (onClick) {
    return (
      <button
        onClick={onClick}
        ref={ref}
        type="button"
        className={infoButtonClassName}
      >
        {children}
      </button>
    )
  }

  return (
    <div
      className={infoButtonClassName}
    >
      {children}
    </div>
  )
}

export default React.memo(React.forwardRef(InfoButton))
