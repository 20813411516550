import React from 'react'

import { IChat } from 'src/models/messenger.model'

import ChatDialog from './components/chatDialog/ChatDialog'
import ChatsPanel from './components/chatsPanel/ChatsPanel'
import MessengerLabel from './components/MessengerLabel'
import NoSelectedChat from './components/noSelectedChat/NoSelectedChat'

import styles from './styles.module.scss'

interface IChatsPanelAndDialog {
  currentChat: IChat | undefined;
}

const ChatsPanelAndDialog: React.FC<IChatsPanelAndDialog> = ({ currentChat }) => (
  <>
    <MessengerLabel />
    <div className={styles.messenger}>
      <ChatsPanel currentChat={currentChat} />
      {currentChat ? <ChatDialog currentChat={currentChat}/> : <NoSelectedChat />}
    </div>
  </>
)

export default ChatsPanelAndDialog
