import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ABOUT_US_MAIN_FEATURES } from 'src/constants/i18n.constants'
import { MAIN_FEATURES_DESKTOP } from 'src/constants/staticPages.constants'

import styles from './mainFeatures.module.scss'

const MainFeatureCards = () => (
  <div className={styles.list}>
    <div className={styles.column}>
      {MAIN_FEATURES_DESKTOP.slice(0, 4).map((feature) => (
        <div key={feature} className={classNames(styles.item, 'static-block')}>
          <Heading
            level='h4'
            label={`${I18N_ABOUT_US_MAIN_FEATURES}.card.title.${feature}`}
            className={styles.itemTitle}
          />
          <Paragraph
            text={`${I18N_ABOUT_US_MAIN_FEATURES}.card.text.${feature}`}
            className={styles.itemText}
          />
        </div>
      ))}
    </div>
    <div className={styles.column}>
      {MAIN_FEATURES_DESKTOP.slice(4).map((feature) => (
        <div key={feature} className={classNames(styles.item, 'static-block')}>
          <Heading
            level='h4'
            label={`${I18N_ABOUT_US_MAIN_FEATURES}.card.title.${feature}`}
            className={styles.itemTitle}
          />
          <Paragraph
            text={`${I18N_ABOUT_US_MAIN_FEATURES}.card.text.${feature}`}
            className={styles.itemText}
          />
        </div>
      ))}
    </div>
  </div>
)

export default MainFeatureCards
