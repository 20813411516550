import { useMemo } from 'react'

import { getCategoryChildren } from 'src/yome-categories-fields-config/utils/category.utils'
import MenuPoint, { MenuPointSize } from 'src/components/menu/MenuPoint'
import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { ID, concatId } from 'src/constants/id.constants'

import styles from './category.module.scss'

interface ISubcategoriesListProps {
  parentCategory: string;
  currentCategory: string;
  onClick: (category: string)=>void;
  size?: MenuPointSize;
}

const SubcategoriesList = ({ parentCategory, currentCategory, onClick, size }: ISubcategoriesListProps) => {
  const subcategories = useMemo(() => getCategoryChildren(parentCategory), [parentCategory])

  if (!subcategories || !subcategories.length) {
    return null
  }

  return (
    <ul className={styles.dropdownList}>
      {subcategories.map((subCategory) => (
        <MenuPoint
          key={subCategory}
          id={concatId(ID.searchFilterSelectCategory, subCategory)}
          label={`${I18N_CATEGORIES}.${subCategory}`}
          onClick={() => onClick(subCategory)}
          isActive={subCategory === currentCategory}
          size={size}
        />
      ))}
    </ul>
  )
}

export default SubcategoriesList
