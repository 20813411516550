import classNames from 'classnames'
import { throttle } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Dropdown from 'src/components//dropdown/Dropdown'
import IconButton from 'src/components/button/IconButton'
import Heading from 'src/components/heading/Heading'
import { ID } from 'src/constants/id.constants'
import { TDropdownSection } from 'src/models/dropdown.model'

import styles from './mobileTopBar.module.scss'

export interface ITopBarIcon {
  id: string;
  iconName: string;
  onClick: () => void;
}

interface IMobileTopBar {
  title?: string;
  onBackClick?: () => void;
  iconButtons?: Array<ITopBarIcon>;
  dropdownOptions?: Record<string, TDropdownSection>;
  isDropdownFullWidth?: boolean;
  className?: string;
  showActionsAfterScroll?: number;
}

const THROTTLE_SCROLL_DELAY = 100
const SCROLL_LISTENER_OPTIONS = { passive: true }

const MobileTopBar = ({
  title,
  iconButtons,
  onBackClick,
  dropdownOptions,
  className,
  showActionsAfterScroll,
  isDropdownFullWidth = false,
}: IMobileTopBar) => {
  const navigate = useNavigate()
  const [isMenuVisible, setIsMenuVisible] = useState(!showActionsAfterScroll)

  useEffect(() => {
    if (!showActionsAfterScroll) {
      return undefined
    }

    const handleScroll = throttle(() => {
      const currentScrollPosition = window.scrollY

      const isMenuVisibleCurrent = currentScrollPosition > showActionsAfterScroll
      setIsMenuVisible(isMenuVisibleCurrent)
    }, THROTTLE_SCROLL_DELAY)

    window.addEventListener('scroll', handleScroll, SCROLL_LISTENER_OPTIONS)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showActionsAfterScroll])

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.titleBlock}>
        <IconButton
          id={ID.topBarBackButton}
          iconName='arrow-left'
          onClick={onBackClick || (() => navigate(-1))}
        />
        <Heading label={title} level='h2' className='mobile-h4'/>
      </div>
      {iconButtons && (
        <div className={styles.iconsRow}>
          {iconButtons?.map((singleIconButton) => (
            <IconButton
              key={singleIconButton.id}
              {...singleIconButton}
            />
          ))}
        </div>
      )}
      {dropdownOptions && isMenuVisible && (
        <Dropdown
          id={ID.topBarSelectOption}
          sections={dropdownOptions}
          className={classNames(styles.dropdownMenu, { [styles.dropdownFullWidth]: isDropdownFullWidth })}
        >
          <div>
            <IconButton
              iconName={'dots-menu'}
              id={ID.topBarSelectButton}
            />
          </div>
        </Dropdown>
      )}
    </div>
  )
}

export default MobileTopBar
