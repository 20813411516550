import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import SvgIcon, { ISvgIconProps } from '../svgIcon/SvgIcon'

import styles from './iconLink.module.scss'

export interface IconLinkProps {
  id: string;
  to: string;
  hasNotification?: boolean;
  iconProps: ISvgIconProps;
}

const IconLink = ({ id, to, hasNotification = false, iconProps }: IconLinkProps) => (
  <Link
    id={id}
    to={to}
    className={classNames(styles.main, { [styles.hasNotification]: hasNotification })}
  >
    <SvgIcon size={20} {...iconProps} />
  </Link>
)

export default React.memo(IconLink)
