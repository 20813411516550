import React, { useCallback } from 'react'
import { useForm, useFormState } from 'react-final-form'

import { I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { IFilterValues } from 'src/models/search.model'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { useViewport } from 'src/contexts/viewportContext'
import { extractSearchBarValuesFromFilters } from 'src/utils/filter.utils'

const ResetFiltersButton = () => {
  const { values } = useFormState<IFilterValues>()
  const { reset } = useForm()

  const { isDesktop } = useViewport()

  const resetFilters = useCallback(() => {
    const searchBarValues = extractSearchBarValuesFromFilters(values)

    reset(searchBarValues) // reset with initial values
  }, [values, reset])

  return (
    <RegularButton
      id={ID.searchFilterResetFilters}
      label={`${I18N_SEARCH_FILTER}.${isDesktop ? 'resetFilters' : 'resetAll'}`}
      buttonType={RegularButtonTypes.transparentNoBorderLight}
      onClick={resetFilters}
    />
  )
}

export default ResetFiltersButton
