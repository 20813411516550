import classNames from 'classnames'
import { memo, useCallback } from 'react'
import { useField, useForm } from 'react-final-form'

import { I18N_CITY_MODAL } from 'src/constants/i18n.constants'
import { SEARCH_RADIUS_FIELD_NAME } from 'src/constants/map.constants'
import { useViewport } from 'src/contexts/viewportContext'
import Heading from 'src/components/heading/Heading'

import styles from './cityModal.module.scss'

interface IMapRadiusButtonProps {
  value: number;
}

const RADIUS_OPTIONS = [1, 2, 3, 5, 10, 15, 25, 50, 100, 150]

const MapRadiusButton = ({ value }: IMapRadiusButtonProps) => {
  const { change } = useForm()
  const { input } = useField(SEARCH_RADIUS_FIELD_NAME)
  const isSelected = value === input.value

  const handleChange = useCallback(() => {
    change(SEARCH_RADIUS_FIELD_NAME, isSelected ? undefined : value)
  }, [change, value, isSelected])

  return (
    <label className={classNames('label-14', styles.radiusButton)}>
      <input
        onChange={handleChange}
        type='radio'
        value={value}
        name={SEARCH_RADIUS_FIELD_NAME}
        className={styles.radiusOption}
        checked={isSelected}
      />
      <span>
        {value}
      </span>
    </label>
  )
}

const MapRadiusButtons = () => {
  const { isDesktop } = useViewport()

  return (
    <div className={styles.radius}>
      <Heading
        level='h6'
        className={classNames(styles.radiusTitle, isDesktop ? 'desktop-h6' : 'label-14')}
        label={`${I18N_CITY_MODAL}.radiusTitle`}
      />
      <div className={styles.radiusButtons}>
        {RADIUS_OPTIONS.map((value) => (
          <MapRadiusButton key={value} value={value} />
        ))}
      </div>
    </div>
  )
}

export default memo(MapRadiusButtons)
