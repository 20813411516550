import {
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import RegularButton, { IRegularButtonProps, RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_CITY_MODAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { SEARCH_CITY_FIELD_NAME, SEARCH_RADIUS_FIELD_NAME } from 'src/constants/map.constants'
import YomeURL from 'src/constants/navigate.constants'
import SearchLocationContext from 'src/contexts/searchLocation.context'
import { useViewport } from 'src/contexts/viewportContext'
import { ILocation, ISearchLocation } from 'src/models/location.model'
import { getZoomByRadius } from 'src/utils/map.utils'

import {
  LOCATION_ADDRESS_FIELD_NAME,
  LOCATION_POSITION_FIELD_NAME,
} from 'src/yome-categories-fields-config/utils/fieldConfig.utils'
import { FormButton } from '../../form'
import FormMap from '../../map/FormMap'
import InfoModal from '../infoModal/InfoModal'
import FullscreenModalLayout from '../mobileModals/FullscreenModalLayout'
import ModalContent from '../mobileModals/ModalContent'
import ModalLayout from '../ModalLayout'
import MapRadiusButtons from './MapRadiusButtons'

import styles from './cityModal.module.scss'

interface ICityModalBaseProps {
  onClose: () => void;
}

interface ICityModalProps extends ICityModalBaseProps {
}

interface ICityModalFormControls {
  hasMapError: boolean;
}

const CityModalFormWrapper = ({ onClose, children }: PropsWithChildren<ICityModalBaseProps>) => {
  const navigate = useNavigate()
  const { isDesktop } = useViewport()
  const { searchLocation, setSearchLocation } = useContext(SearchLocationContext)

  const onFormSubmit = useCallback((values: ISearchLocation) => {
    setSearchLocation(values)
    onClose()

    if (isDesktop) {
      navigate(YomeURL.search)
    }
  }, [onClose, navigate, setSearchLocation, isDesktop])

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={searchLocation}
      render={function RenderCityModalForm({ handleSubmit }) {
        return (
          <form onSubmit={handleSubmit} className={styles.cityModal}>
            {children}
          </form>
        )
      }}
    />
  )
}

const CityModalFormControls = ({ hasMapError }: ICityModalFormControls) => {
  const { change } = useForm()
  const { values } = useFormState()

  const selectedRadius: number | undefined = values[SEARCH_RADIUS_FIELD_NAME]
  const selectedAddress: string | undefined = values[LOCATION_ADDRESS_FIELD_NAME]
  const selectedPosition: ILocation | undefined = values[LOCATION_POSITION_FIELD_NAME]

  const handleReset = useCallback(() => change(SEARCH_RADIUS_FIELD_NAME, undefined), [change])

  useEffect(() => {
    if (!selectedAddress) {
      handleReset()
    }
  }, [handleReset, selectedAddress])

  return (
    <div className={styles.controls}>
      {!!selectedPosition && <MapRadiusButtons />}
      <div className={styles.buttonGroup}>
        {!!selectedRadius && (
          <RegularButton
            onClick={handleReset}
            id={ID.cityModalSubmitCity}
            className={styles.resetButton}
            label={`${I18N_CITY_MODAL}.reset`}
            buttonType={RegularButtonTypes.transparentWithBorder}
          />
        )}
        <FormButton
          id={ID.cityModalSubmitCity}
          label={`${I18N_CITY_MODAL}.showFound`}
          disabled={hasMapError || !selectedPosition}
        />
      </div>
    </div>
  )
}

const CityModalFormContent = memo(() => {
  const { values } = useFormState()
  const { isDesktop } = useViewport()
  const [hasMapError, setMapError] = useState(false)

  const selectedRadius: number | undefined = values[SEARCH_RADIUS_FIELD_NAME]
  const selectedZoom = useMemo(() => getZoomByRadius(selectedRadius), [selectedRadius])

  return (
    <>
      <FormMap
        id={ID.cityModalDisplayMap}
        onMapError={setMapError}
        zoom={selectedZoom}
        radius={selectedRadius}
        mapClassName={styles.map}
        name={SEARCH_CITY_FIELD_NAME}
        placeholder={`${I18N_CITY_MODAL}.enterCityOrRegion`}
        hasOnlyCity
        selectFirstSuggestionOnBlur
      />
      {isDesktop && <CityModalFormControls hasMapError={hasMapError} />}
    </>
  )
})

const CityModal = ({ onClose }: ICityModalProps) => {
  const { isDesktop } = useViewport()

  if (isDesktop) {
    return (
      <ModalLayout handleClose={onClose}>
        <InfoModal title={`${I18N_CITY_MODAL}.cityOrRegion`}>
          <CityModalFormWrapper onClose={onClose}>
            <CityModalFormContent/>
          </CityModalFormWrapper>
        </InfoModal>
      </ModalLayout>
    )
  }

  const submitBtnProps: IRegularButtonProps = {
    id: ID.cityModalSubmitCity,
    label: `${I18N_CITY_MODAL}.showFound`,
    buttonType: RegularButtonTypes.main,
    type: 'submit',
  }

  return (
    <FullscreenModalLayout>
      <CityModalFormWrapper onClose={onClose}>
        <ModalContent
          title={`${I18N_CITY_MODAL}.location.title`}
          handleClose={onClose}
          primaryButtonProps={submitBtnProps}
        >
          <CityModalFormContent/>
        </ModalContent>
      </CityModalFormWrapper>
    </FullscreenModalLayout>
  )
}

export default memo(CityModal)
