import { Outlet } from 'react-router-dom'

import ErrorBoundary from 'src/components/errorBoundary/ErrorBoundary'
import { useViewport } from 'src/contexts/viewportContext'
import PrivateRoute from '../PrivateRoute'
import ProfileMenuBoundary from './components/profileMenu/ProfileMenu'

import layout from './profileLayout.module.scss'

const PrivateProfileLayout = () => {
  const { isDesktop } = useViewport()

  return (
    <PrivateRoute>
      <main className={layout.profileLayout}>
        {isDesktop && <ProfileMenuBoundary />}
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </main>
    </PrivateRoute>
  )
}

export default PrivateProfileLayout
