import React from 'react'
import classNames from 'classnames'

import ButtonLink from 'src/components/button/ButtonLink'
import { I18N_FOOTER_LINK, I18N_FOOTER_SECTION } from 'src/constants/i18n.constants'
import { ID, concatId } from 'src/constants/id.constants'
import { LinkOption } from 'src/models/footer.model'

import Heading from '../heading/Heading'
import styles from './footer.module.scss'

interface IFooterSection {
  title: string;
  items: LinkOption[];
}

const FooterSection: React.FC<IFooterSection> = ({ title, items }) => (
  <div className={styles.section}>
    <Heading
      level='h6'
      className={classNames('desktop-h6', styles.sectionTitle)}
      label={`${I18N_FOOTER_SECTION}.${title}`}
    />
    <ul className={styles.sectionList}>
      {items.map(({ id, linkTo }) => (
        <li key={id} className={styles.sectionListItem}>
          <ButtonLink
            linkTo={linkTo}
            label={`${I18N_FOOTER_LINK}.${id}`}
            id={concatId(ID.footerLinkToOption, id)}
          />
        </li>
      ))}
    </ul>
  </div>
)

export default FooterSection
