import classNames from 'classnames'

import DateComponent from 'src/components/date/DateComponent'
import { DateFormatType } from 'src/models/date.model'

import styles from './styles.module.scss'

interface GroupMessageDateProps {
  messageDate: string;
  isMessageFromNextDay: boolean;
}

const GroupMessageDate = ({ messageDate, isMessageFromNextDay }: GroupMessageDateProps) => (
  <>
    {isMessageFromNextDay && (
      <div className={styles.headerGroupDate}>
        <div className={classNames('supportive-14', styles.dateLabel)}>
          <DateComponent isoTimestamp={messageDate} type={DateFormatType.chat} hasOnlyLabel />
        </div>
      </div>
    )}
  </>
)

export default GroupMessageDate
