import { memo } from 'react'

import IconButton from 'src/components/button/IconButton'
import Heading from 'src/components/heading/Heading'
import { I18N_MESSENGER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'

import styles from '../styles.module.scss'

const MessengerLabel = () => {
  const { isDesktop } = useViewport()

  return (
    <div className={styles.messengerLabel}>
      {isDesktop && <IconButton linkTo={YomeURL.profilePage} iconName='back-button' id={ID.messengerBackButton} />}
      <Heading label={`${I18N_MESSENGER}.label`} className={'desktop-h4 mobile-h4'}/>
    </div>
  )
}

export default memo(MessengerLabel)
