import YomeURL from 'src/constants/navigate.constants'

import { IModalWithClose } from './modal.model'
import { EmailUrlAction } from './url.model'

export type SignupFormFields = {
  email: string;
  username: string;
  password: string;
}

export enum PasswordCheckPointsEnum {
  minLength = 'minLength',
  latinLetters = 'latinLetters',
  numberPresent = 'numberPresent',
  uppercasePresent = 'uppercasePresent',
  lowercasePresent = 'lowercasePresent',
  symbolPresent = 'symbolPresent',
}

export const PASSWORD_FIELDNAME = 'oldPassword'
export const NEW_PASSWORD_FIELDNAME = 'newPassword'
export const CONFIRM_PASSWORD_FIELDNAME = 'confirmNewPassword'

export type LoginFormFields = {
  email: string;
  password: string;
}

export type ResetFormFields = {
  email: string;
}

export interface ISetNewPasswordFields {
  [NEW_PASSWORD_FIELDNAME]: string;
  [CONFIRM_PASSWORD_FIELDNAME]: string;
  token: string;
}

export interface IVerifyEmailResponse {
  email?: string;
}

export interface IResendEmailResponse {
  timeToResend: number;
}

export interface BaseAuthModalProps extends IModalWithClose {
  onSuccessSubmit: (action: EmailUrlAction, email: string) => void;
}

export interface IPasswordChangeProfileFormValues {
  [PASSWORD_FIELDNAME]: string;
  [NEW_PASSWORD_FIELDNAME]: string;
  [CONFIRM_PASSWORD_FIELDNAME]: string;
}

export interface IUsernameChangeProfileFormValues {
  username: string;
}

export enum OAuthProviders {
  google = 'google',
  facebook = 'facebook',
  apple = 'apple',
}

export type TOAuthProviders = keyof typeof OAuthProviders

export const oAuthStrategyToLink = {
  google: YomeURL.oauthGoogle,
  facebook: YomeURL.oauthFacebook,
  apple: YomeURL.oauthApple,
}
