import useSWR from 'swr'

import {
  IResendEmailResponse,
  ISetNewPasswordFields,
  IVerifyEmailResponse,
  LoginFormFields,
  ResetFormFields,
  SignupFormFields,
} from 'src/models/auth.model'
import { IUser } from 'src/models/user.model'
import { sendFormGet, sendFormPostAndHandleFormErrors, sendPost } from 'src/utils/network.utils'
import YomeAPI from 'src/constants/network.constants'
import SWRkeys from 'src/constants/swr.constants'

export const signup = async (
  { email, username, password }: SignupFormFields,
) => sendFormPostAndHandleFormErrors(YomeAPI.authSignup, { email, username, password })

export const checkUserPresence = (
  { email }: SignupFormFields,
): Promise<IUser> => sendFormGet(YomeAPI.authUserExists, { email })

export const logout = (): Promise<void> => sendPost(YomeAPI.authLogout)

export const login = async (
  values: LoginFormFields,
) => sendFormPostAndHandleFormErrors(YomeAPI.authLogin, values)

export const resendVerifyEmail = (
  email: string,
): Promise<IResendEmailResponse> => sendPost(YomeAPI.authResendVerifyEmail, { email })

export const reset = async (
  values: ResetFormFields,
) => sendFormPostAndHandleFormErrors(YomeAPI.authResetPassord, values)

export const resendResetPassword = (
  email: string,
): Promise<IResendEmailResponse> => sendPost(YomeAPI.authResendResetPassword, { email })

export const setNewPassword = async (
  { newPassword, confirmNewPassword, token }: ISetNewPasswordFields,
) => sendFormPostAndHandleFormErrors(YomeAPI.authSetNewPassword, { newPassword, confirmNewPassword, token })

export const changePasswordProfile = async <T>(
  props: T,
) => sendFormPostAndHandleFormErrors(YomeAPI.authChangePassword, props)

export const useVerify = (token?: string | null) => {
  const handleVerify: () => Promise<IVerifyEmailResponse | null> = async () => {
    if (!token) {
      return null
    }

    return sendPost(YomeAPI.authVerifyEmail, { token })
  }

  return useSWR(SWRkeys.authVerifyEmail, handleVerify, { revalidateOnFocus: false })
}
