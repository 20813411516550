// eslint-disable-next-line import/prefer-default-export
export const dateOptions: Record<string, Intl.DateTimeFormatOptions> = {
  hourMinutes: {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },
  monthYear: {
    month: 'short',
    year: 'numeric',
  },
  dateTime: {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },
  dateFullMonth: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  dateNumMonth: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  weekDay: {
    weekday: 'short',
  },
  dayMonth: {
    day: 'numeric',
    month: 'short',
  },
  dateTimeFullYear: {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour12: false,
  },
}

export enum DateFormatType {
  chat = 'chat',
  chatShort = 'chatShort',
  time = 'time',
  noLabel = 'noLabel',
}
