import classNames from 'classnames'
import { ReactNode } from 'react'

import { useViewport } from 'src/contexts/viewportContext'
import { IModalWithClose } from 'src/models/modal.model'

import FullscreenModalLayout from './mobileModals/FullscreenModalLayout'
import ModalContent from './mobileModals/ModalContent'
import ModalLayout from './ModalLayout'

interface IHashModalWrapper extends IModalWithClose {
  children: ReactNode;
  isCloseButtonHidden?: boolean;
  isNonFullscreen?: boolean;
  showMobileCloseButton?: boolean;
}

const ResponsiveModalWrapper = ({
  children,
  handleClose,
  isCloseButtonHidden = false,
  isNonFullscreen = false,
  showMobileCloseButton = false,
} : IHashModalWrapper) => {
  const { isDesktop } = useViewport()

  if (isDesktop || isNonFullscreen) {
    return (
      <ModalLayout handleClose={!isCloseButtonHidden ? handleClose : undefined}>
        {children}
      </ModalLayout>
    )
  }

  return (
    <FullscreenModalLayout>
      <ModalContent handleClose={showMobileCloseButton ? handleClose : undefined}>
        <div className={classNames('mobile-paddings', 'h-100')}>
          {children}
        </div>
      </ModalContent>
    </FullscreenModalLayout>
  )
}

export default ResponsiveModalWrapper
