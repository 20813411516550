import { Suspense } from 'react'

import ErrorBoundarySkip from '../errorBoundary/ErrorBoundarySkip'

const ModerationWrapper = ({ children }: { children: React.ReactNode }) => (
  <div>
    <ErrorBoundarySkip>
      <Suspense>
        {children}
      </Suspense>
    </ErrorBoundarySkip>
  </div>
)

export default ModerationWrapper
