import { useSWRConfig } from 'swr'

import { FormEmailField } from 'src/components/form'
import FormInput from 'src/components/form/FormInput'
import { I18N_GLOBAL, I18N_PROFILE_SETTING_PERSONAL } from 'src/constants/i18n.constants'
import { ID, Subject } from 'src/constants/id.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { IUsernameChangeProfileFormValues } from 'src/models/auth.model'
import { ISettingsProps } from 'src/models/profile.model'
import SettingLine from 'src/routes/profile/components/settings/SettingLine/SettingLine'
import { updateUserName } from 'src/services/profile.service'

import SettingSectionWrapper from '../settingWrapper/SettingSectionWrapper'

import styles from '../settings.module.scss'

const PersonalInfoSettings = ({ profile }: ISettingsProps) => {
  const { mutate } = useSWRConfig()
  const { isDesktop } = useViewport()
  const usernameField = 'username'
  const fieldLabel = !isDesktop ? `${I18N_GLOBAL}.input.username.label` : ''

  const sumbitChangeUserName = async (values: IUsernameChangeProfileFormValues) => {
    const result = await updateUserName(values)

    if (Object.keys(result).length) {
      return result
    }

    mutate(SWRkeys.profile)
    mutate(SWRkeys.currentUser)

    return undefined
  }

  return (
    <SettingSectionWrapper title={ `${I18N_PROFILE_SETTING_PERSONAL}.title`}>
      <>
        <SettingLine<IUsernameChangeProfileFormValues>
          settingId={Subject.Username}
          title={`${I18N_PROFILE_SETTING_PERSONAL}.name`}
          subheading={`${I18N_PROFILE_SETTING_PERSONAL}.name.subheading`}
          initialFieldName={usernameField}
          currentValue={profile.name}
          onSubmit={sumbitChangeUserName}
        >
          <FormInput
            id={ID.profileSettingInputUsername}
            name={usernameField}
            showClearButton={!isDesktop}
            fieldWrapperProps={{
              label: fieldLabel,
              name: usernameField,
              className: styles.input,
            }}
          />
        </SettingLine>
        <SettingLine
          settingId={Subject.Password}
          currentValue={profile.email}
          title={`${I18N_PROFILE_SETTING_PERSONAL}.email`}
          disabled
        >
          <FormEmailField
            id={ID.profileSettingInputEmail}
            name='email'
            className={styles.input}
          />
        </SettingLine>
      </>
    </SettingSectionWrapper>
  )
}

export default PersonalInfoSettings
