import classNames from 'classnames'
import React, { memo, useContext } from 'react'

import Paragraph from 'src/components/paragraph/Paragraph'
import PostListContext from 'src/contexts/postList.context'
import { useGetDisplayPrice } from 'src/hooks/price.hooks'
import { checkIsInactiveView } from 'src/utils/post.utils'

import { IPropsWithOffer, PostListType } from '../../../models/post.model'
import SvgIcon from '../../svgIcon/SvgIcon'

import styles from './postPrice.module.scss'

const PostPrice: React.FC<IPropsWithOffer> = ({ offer }) => {
  const { listType } = useContext(PostListContext)

  const getDisplayPrice = useGetDisplayPrice()
  const { price, hasDelivery } = offer
  const inactiveView = checkIsInactiveView(offer)
  const isDisabled = inactiveView && listType !== PostListType.inactive

  return (
    <div>
      <div className={styles.bodyPrice}>
        <Paragraph className={classNames(
          'heading desktop-h7 mobile-heading-subtitle',
          styles.active,
          { [styles.disabled]: isDisabled },
        )}
        >
          { getDisplayPrice(price)}
        </Paragraph>
        {hasDelivery && (
          <div className={styles.delivery}>
            <SvgIcon name="delivery" />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(PostPrice)
