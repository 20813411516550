import { FieldType, IFieldConfig } from '../models/fields.model'

const createPlaceholderString = (field: string) => `field.${field}.placeholder`
const createMainLabelInfoString = (field: string) => `field.${field}.mainLabelInfo`

export enum ServiceDealResult {
  OK = 'OK',
  NOT_DELIVERED = 'NOT_DELIVERED',
  NO_AGREEMENT = 'NO_AGREEMENT',
  NO_COMMUNICATION = 'NO_COMMUNICATION',
}

export const ServiceDealResultValues = Object.values(ServiceDealResult)

export enum ItemDealResult {
  IN_PERSON_MEETING = 'IN_PERSON_MEETING',
  SENDING_DELIVERY_SERVICES = 'SENDING_DELIVERY_SERVICES',
  ARRANGEMENT_STATE = 'ARRANGEMENT_STATE',
  OFFLINE_MEETING = 'OFFLINE_MEETING',
  NO_COMMUNICATION = 'NO_COMMUNICATION',
}

export const ItemDealResultValues = Object.values(ItemDealResult)

const reviewFieldsConfig: Record<string, IFieldConfig> = {
  isDealCompleted: {
    name: 'isDealCompleted',
    type: FieldType.checkbox,
    options: ['bought', 'no-bought'],
    defaultValue: undefined,
  },
  serviceDealResult: {
    name: 'serviceDealResult',
    type: FieldType.radio,
    options: ServiceDealResultValues,
    defaultValue: undefined,
  },
  itemDealResult: {
    name: 'itemDealResult',
    type: FieldType.radio,
    options: ItemDealResultValues,
    defaultValue: undefined,
  },
  rating: {
    name: 'rating',
    type: FieldType.rating,
    options: ['1', '2', '3', '4', '5'],
    defaultValue: undefined,
  },
  comment: {
    name: 'comment',
    type: FieldType.textarea,
    placeholder: createPlaceholderString('comment'),
    validation: { min: 1, max: 3000 },
    showCounter: true,
  },
  images: {
    name: 'images',
    type: FieldType.file,
    validation: { array: true },
    mainLabelInfo: createMainLabelInfoString('images'),
  },
}

export default reviewFieldsConfig
