import classNames from 'classnames'

import { useViewport } from 'src/contexts/viewportContext'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './skeletons.module.scss'

const OriginalContentSkeleton = ({ className }: { className?: string }) => {
  const { isDesktop } = useViewport()

  return (
    <div className={classNames(styles.relativeContainer, className)}>
      <span className={styles.skeletonAboveImageLoader}/>
      <SvgIcon
        name={isDesktop ? 'translation-skeleton' : 'translation-skeleton-mobile'}
        className={styles.wireframeInfo}
      />
    </div>
  )
}

export default OriginalContentSkeleton
