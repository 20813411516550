import { OfferStatus } from 'src/models/offerStatus.model'
import { IUser } from 'src/models/user.model'

// TODO remove when performing a task on user data
// eslint-disable-next-line import/prefer-default-export
export const addMockUserData = (user: IUser) => {
  // eslint-disable-next-line no-param-reassign
  user.rating = {
    averageStarRating: 4.8,
    comments: [
      {
        userName: 'another user',
        postTitle: 'some good',
        content: 'Thanks, it was what I looked for',
        createdAt: '2023-09-04T06:32:30.490+00:00',
        rating: 4.8,
        dealStatus: OfferStatus.closed,
      },
    ],
  }

  /* eslint-disable no-param-reassign */
  user.averageResponseTime = 10

  /* eslint-disable no-param-reassign */
  user.subscriptions = 12
  /* eslint-disable no-param-reassign */
  user.followers = 2
}
