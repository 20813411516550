import classNames from 'classnames'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import styles from './buttonLink.module.scss'

interface ButtonLinkProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  linkTo?: string;
  label: string;
  title?: string;
  id: string;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ onClick, label, title, linkTo, id }) => {
  const { t } = useTranslation()

  const labelT = t(label)
  const isButton = onClick && !linkTo

  const commonProps = {
    id,
    title: title ? t(title) : labelT,
    className: classNames('caption-14', styles.main),
  }

  if (isButton) {
    return (
      <button
        {...commonProps}
        onClick={onClick}
        type='button'
      >
        {labelT}
      </button>
    )
  }

  return (
    <Link {...commonProps} to={linkTo!}>
      {labelT}
    </Link>
  )
}

export default memo(ButtonLink)
