export enum EmailUrlAction {
  reset = 'reset',
  verify = 'verify',
}

export enum SocialLoginUrlAction {
  socialFailure = 'socialFailure',
}

export const AVAILABLE_URL_ACTIONS = [EmailUrlAction.reset, EmailUrlAction.verify, SocialLoginUrlAction.socialFailure]
