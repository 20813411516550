import { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'

import { IFieldConfig } from 'src/yome-categories-fields-config/models/fields.model'
import { CATEGORY_QPARAM } from 'src/models/search.model'
import { getFiltersFieldsStructure } from 'src/utils/filter.utils'
import { getMainCategoryForChild } from 'src/yome-categories-fields-config/utils/category.utils'
import FilterField from './FilterField'
import FilterFieldConstructor from './FilterFieldConstructor'

interface IFiltersList {
  initialFields: IFieldConfig[];
}

const FiltersList = ({ initialFields }: IFiltersList) => {
  const { values } = useFormState()
  const currentCategory = values[CATEGORY_QPARAM]

  // to keep track of fields depending on category (different for goods and services)
  const [fromFields, setFormFields] = useState(initialFields)

  useEffect(() => {
    const newFormFields = getFiltersFieldsStructure(currentCategory)
    setFormFields(newFormFields)
  }, [currentCategory])

  return (
    <>
      {fromFields.map((field) => (
        <FilterField
          key={field.name}
          fieldConfig={field}
          filtersCategory={getMainCategoryForChild(currentCategory)}
        >
          <FilterFieldConstructor
            fieldConfig={field}
          />
        </FilterField>
      ))}
    </>
  )
}

export default FiltersList
