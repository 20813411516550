import { CardInfo, SinglePost } from 'src/models/post.model'

import ShortenOfferCard from './ShortenOfferCard'
import ShortenOfferCardWithLike from './ShortenOfferCardWithLike'

import styles from './shortenOfferCard.module.scss'

interface ShortenOfferCardListProps {
  idPrefix: string;
  postList: SinglePost[];
  offerSourceEndpoint: string;
  hasAllFavorite?: boolean;
  cardInfo?: CardInfo;
}

const ShortenOfferCardList = (
  {
    idPrefix,
    postList,
    offerSourceEndpoint,
    hasAllFavorite = false,
    cardInfo = CardInfo.location,
  }: ShortenOfferCardListProps,
) => (
  <div className={styles.cardListPost}>
    {postList.map((offer) => (offer.isCreatedByCurrentUser ? (
      <ShortenOfferCard
        key={offer.slug}
        idPrefix={idPrefix}
        offer={offer}
        cardInfo={cardInfo}
      />
    ) : (
      <ShortenOfferCardWithLike
        idPrefix={idPrefix}
        key={offer.slug}
        offer={offer}
        offerSourceEndpoint={offerSourceEndpoint}
        hasAllFavorite={hasAllFavorite}
      />
    )))}
  </div>
)

export default ShortenOfferCardList
