import { I18N_MESSENGER } from 'src/constants/i18n.constants'

import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'

const NoChats = () => (
  <EmptyStateMessage
    icon='no-chats'
    text={`${I18N_MESSENGER}.noChats`}
  />
)

export default NoChats
