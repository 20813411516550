import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { I18N_MESSENGER } from 'src/constants/i18n.constants'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './blockedStatus.module.scss'

export const BlockedStatus = () => {
  const { t } = useTranslation()

  return (
    <div
      data-tooltip-id="blocked-status"
      data-tooltip-content={t(`${I18N_MESSENGER}.tooltip.blockedUser`)}
    >
      <SvgIcon
        name={'hand'}
        size={16}
        className={styles.icon}
        currentColor={'stroke'}
      />
      <Tooltip
        id="blocked-status"
        noArrow
        variant={'dark'}
        className={styles.tooltip}
      />
    </div>
  )
}

export default BlockedStatus
