import classNames from 'classnames'
import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react'
import { Field, useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { emptyFunction } from 'src/constants/function.constants'
import { useInputHandler } from 'src/hooks/form.hooks'

import SvgIcon from '../../svgIcon/SvgIcon'
import FieldWrapper, { IFieldWrapperProps } from '../FieldWrapper'

import formStyles from '../form.module.scss'
import styles from './search.module.scss'

interface ISearchFieldProps {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  fieldWrapperProps?: IFieldWrapperProps;
  id: string;
  name: string;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  spellCheck?: boolean;
  hasRightIcon?: boolean;
  skipInputBlur?: boolean;
}

const FormSearch = React.memo(forwardRef(({
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  fieldWrapperProps,
  name,
  value,
  children,
  placeholder = '',
  autoFocus = false,
  spellCheck = false,
  hasRightIcon = false,
  skipInputBlur = false,
}: PropsWithChildren<ISearchFieldProps>, ref: ForwardedRef<HTMLInputElement>) => {
  const { t } = useTranslation()
  const { input } = useField(name)
  const { onBlur: onInputBlur, onFocus: onInputFocus, onChange: onInputChange } = input

  const handleFocus = useInputHandler(onInputFocus, onFocus)
  const handleChange = useInputHandler(onInputChange, onChange)
  const handleBlur = useInputHandler(skipInputBlur ? emptyFunction : onInputBlur, onBlur)

  return (
    <Field name={name}>
      {() => (
        <div className={styles.searchContainer}>
          <FieldWrapper {...fieldWrapperProps} name={name}>
            <SvgIcon name='search' className={styles.searchIcon} />
            <input
              {...input}
              ref={ref}
              id={name}
              title={name}
              type='search'
              value={value || input.value}
              placeholder={t(placeholder)}
              className={classNames(formStyles.fieldInput, styles.textInput, { [styles.hasRightIcon]: hasRightIcon })}
              autoComplete={'off'}
              autoFocus={autoFocus}
              spellCheck={spellCheck}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={handleChange}
              onKeyDown={onKeyDown}
            />
          </FieldWrapper>
          {children}
        </div>
      )}
    </Field>
  )
}))

export default FormSearch
