import { Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_FOR_BUSINESS } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { FOR_BUSINESS_IMAGES_PATH } from 'src/constants/staticPages.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { usePageTitle } from 'src/utils/hooks.utils'
import { getStaticPageMetaTitle } from 'src/utils/meta.utils'

import Categories from './components/categories/Categories'
import Features from './components/features/Features'
import Plans from './components/plans/Plans'
import Stats from './components/Stats'
import Steps from './components/steps/Steps'

import styles from './forBusiness.module.scss'

const ForBusinessRoute = () => {
  const { isDesktop } = useViewport()
  const image = isDesktop ? 'mainDesktop' : 'main'
  const { pathname } = useLocation()
  usePageTitle(getStaticPageMetaTitle(pathname))

  return (
    <div className='static-page-wrapper'>
      <img
        className={styles.photos}
        src={`${FOR_BUSINESS_IMAGES_PATH}/${image}.png`}
        alt='photos'
      />
      <Heading className="static-heading mobile-h2 desktop-h1">
        <Trans
          i18nKey={`${I18N_FOR_BUSINESS}.heading`}
          components={{ 1: <span /> }}
        >
        </Trans>
      </Heading>
      <Paragraph
        className={styles.subheading}
        text={`${I18N_FOR_BUSINESS}.subheading`}
      />
      <div className={styles.buttons}>
        <RegularButton
          linkTo={YomeURL.help}
          id={ID.forBusinessButtonBecomePartner}
          label={`${I18N_FOR_BUSINESS}.becomePartner`}
          buttonType={RegularButtonTypes.main}
          size={isDesktop ? RegularButtonSizes.large : RegularButtonSizes.medium}
        />
        <RegularButton
          linkTo={YomeURL.help}
          id={ID.forBusinessButtonRequestCall}
          label={`${I18N_FOR_BUSINESS}.requestBtn`}
          buttonType={RegularButtonTypes.transparentWithBorder}
          size={isDesktop ? RegularButtonSizes.large : RegularButtonSizes.medium}
        />
      </div>
      <Stats />
      <Categories />
      <Features />
      <Plans />
      <Steps />
    </div>
  )
}

export default ForBusinessRoute
