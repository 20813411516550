import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { FORM_FIELD_INPUT_VALUE_POSTFIX } from 'src/constants/form.constants'
import { I18N_POST_DATA, I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import {
  IFilterValues,
  PRICE_CHIP_NAME,
  PRICE_FIELDS,
  SEARCH_BAR_FIELDS,
  TFieldKey,
  TSingleFilterValues,
} from 'src/models/search.model'
import { IChipId, IChipValue } from 'src/utils/filter.utils'
import { IFieldConfig } from 'src/yome-categories-fields-config/models/fields.model'

import { usePriceFormatter } from './price.hooks'

const useGetSingleFilterChips = () => {
  const { t } = useTranslation()
  const priceFormatter = usePriceFormatter(true)

  return useCallback((
    filterKey: TFieldKey,
    filterValues: TSingleFilterValues,
    fieldConfig?: IFieldConfig | null,
  ): IChipValue[] => {
    const hasOptions = fieldConfig ? Boolean(fieldConfig.options?.length) : false

    // filter with options
    if (Array.isArray(filterValues)) {
      return filterValues.map((val) => (
        // if field has options means value need to be translated
        { chipValue: hasOptions ? `${I18N_POST_DATA}.${filterKey}.${val}` : val,
          chipId: { fieldKey: filterKey, fieldValue: val } }
      ))
    }

    if (typeof filterValues !== 'string' && typeof filterValues !== 'number' && typeof filterValues !== 'boolean') {
      throw new Error(`Unexpected filterValues type = ${typeof filterValues} for filer key = ${filterKey}`)
    }

    const chipId: IChipId = { fieldKey: filterKey, fieldValue: filterValues }

    // toggle / checkbox
    if (typeof filterValues === 'boolean' || filterValues === 'true' || filterValues === 'false') {
      return [
        { chipValue: `${I18N_SEARCH_FILTER}.${filterKey}`,
          chipId },
      ]
    }

    // radiobutton filter
    if (typeof filterValues === 'string' && Number.isNaN(Number(filterValues))) {
      return [
        { chipValue: `${I18N_POST_DATA}.${filterKey}.${filterValues}`,
          chipId },
      ]
    }

    // price
    if (typeof filterValues === 'number' || !Number.isNaN(Number(filterValues))) {
      const filterKeyLabel = t(`${I18N_SEARCH_FILTER}.${filterKey}`)

      return [
        { chipValue: `${filterKeyLabel} ${priceFormatter(filterValues)}`,
          chipId },
      ]
    }

    throw new Error(`Unexpected filterValues type = ${typeof filterValues} for filer key = ${filterKey}`)
  }, [priceFormatter, t])
}

// eslint-disable-next-line import/prefer-default-export
export const useGetFilterChips = () => {
  const getChips = useGetSingleFilterChips()

  return useCallback((
    formValues: IFilterValues,
    categoryFields: IFieldConfig[],
  ): Partial<Record<TFieldKey, IChipValue[]>> => {
    const filterChips: Partial<Record<TFieldKey, IChipValue[]>> = {}
    const formValuesArray = Object.entries(formValues) as [keyof IFilterValues, TSingleFilterValues][]

    formValuesArray.forEach(([filterKey, filterValues]) => {
      // TODO: checking of constant should be removed
      // Skip synthetic fields for price
      if (filterKey.includes(FORM_FIELD_INPUT_VALUE_POSTFIX)) {
        return
      }

      if (!filterValues && filterValues !== 0) {
        return // continue if filter value is null or false
      }

      if (SEARCH_BAR_FIELDS.includes(filterKey)) {
        return // no chips for search bar values (city, category)
      }

      if (PRICE_FIELDS.includes(filterKey)) {
        let priceFilters = filterChips[PRICE_CHIP_NAME] || []
        const singleFilterChips = getChips(filterKey, filterValues)
        priceFilters = priceFilters.concat(singleFilterChips)
        filterChips[PRICE_CHIP_NAME] = priceFilters
      } else {
      // find config for given field
        const fieldConfig = categoryFields.find((field) => field.name === filterKey)
        if (!fieldConfig) return // continue if filter is not in config
        filterChips[filterKey] = getChips(filterKey, filterValues, fieldConfig)
      }
    })

    return filterChips
  }, [getChips])
}
