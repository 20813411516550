import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import { useViewport } from 'src/contexts/viewportContext'
import { usePageTitle } from 'src/utils/hooks.utils'
import RecommendedPostList from '../../components/postList/RecommendedPostList'
import { useCurrentUser } from '../../services/currentUser.service'
import CategoryCards from './components/categoryCards/CategoryCards'
import OfferCardStack from './components/offerCardStack/OfferCardStack'

import styles from './homeRoute.module.scss'

const HomeRoute = () => {
  usePageTitle()
  const navigate = useNavigate()
  const [query] = useSearchParams()
  const { data: currentUser } = useCurrentUser()

  const { isDesktop } = useViewport()

  useEffect(() => {
    const nextURL = query.get('next')

    if (nextURL && currentUser) {
      navigate(nextURL)
    }
  }, [navigate, currentUser, query])

  return (
    <div className={styles.main}>
      <div className={styles.leftSide}>
        <CategoryCards />
        <RecommendedPostList />
        <ShowcaseArea type={isDesktop ? 'horizontal' : 'mobile-big'}/>
      </div>
      <div className={styles.rightSide}>
        <OfferCardStack />
      </div>
    </div>
  )
}

export default HomeRoute
