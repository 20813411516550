import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Chip, { ChipType } from 'src/components/button/Chip/Chip'
import FilterFieldConstructor from 'src/components/filterSidebar/FilterFieldConstructor'
import SingleFullsizeFilter from 'src/components/filterSidebar/SingleFullsizeFilter'
import { CATEGORY_QPARAM, IFilterValues, TSingleFilterKey, TSingleFilterValues } from 'src/models/search.model'
import {
  getChipLabel,
  getFiltersFieldsStructure,
  getFilterValue,
  getInitialFilters,
  isAppliedFilter,
  sortFiltersByApplied,
} from 'src/utils/filter.utils'
import { IFieldConfig } from 'src/yome-categories-fields-config/models/fields.model'
import { getMainCategoryForChild } from 'src/yome-categories-fields-config/utils/category.utils'

import FullscreenFilters from '../FullscreenFilters/FullscreenFilters'

import styles from './filterChips.module.scss'

const FilterChips = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentCategory = searchParams.get(CATEGORY_QPARAM)
  const filterFields = getFiltersFieldsStructure(currentCategory)
  const [activeFilter, setActiveFilter] = useState<null | IFieldConfig>(null)
  const filterValues = useMemo(() => getInitialFilters(searchParams, filterFields), [searchParams, filterFields])

  const onCloseFilter = useCallback(() => setActiveFilter(null), [])
  const onChipClick = useCallback((filter: IFieldConfig) => () => setActiveFilter(filter), [])

  const changeFilterValue = useCallback((name: TSingleFilterKey, value: TSingleFilterValues) => {
    // empty value if no value or empty array
    const emptyValue = !value || (Array.isArray(value) && !value.length)

    if (emptyValue && searchParams.has(name)) {
      searchParams.delete(name)
    }

    if (!emptyValue) {
      searchParams.set(name, value.toString())
    }

    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  // sort fields, so first would be elements with appied filters
  filterFields.sort((field1, field2) => sortFiltersByApplied(field1, field2, filterValues))

  return (
    <ul className={styles.main}>
      {filterFields.map((filter) => (
        <li key={filter.name}>
          <Chip
            iconName="chevron-down"
            value={getChipLabel(filter.name, t, filterValues)}
            type={isAppliedFilter(filter.name, filterValues) ? ChipType.dark : ChipType.light}
            onClick={onChipClick(filter)}
            isNonBreakableText
          />
        </li>
      ))}
      <FullscreenFilters isChip />
      {activeFilter && (
        <SingleFullsizeFilter
          name={activeFilter.name as TSingleFilterKey}
          filtersCategory={getMainCategoryForChild(currentCategory)}
          initialValues={getFilterValue(activeFilter.name, filterValues) as Partial<IFilterValues>}
          closeFilter={onCloseFilter}
          change={changeFilterValue}
        >
          <FilterFieldConstructor fieldConfig={activeFilter} />
        </SingleFullsizeFilter>
      )}
    </ul>
  )
}

export default memo(FilterChips)
