import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_POST_ACTION } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { IUser } from 'src/models/user.model'

import PostUserInfo from '../postStickyInfo/postUserInfo/PostUserInfo'

import styles from './authorInfoAndButtons.module.scss'

interface IAuthorInfoAndButtonsProps {
  author: IUser;
  isCurrentUserAuthor?: boolean;
}

const AuthorInfoAndButtons = ({ author, isCurrentUserAuthor }: IAuthorInfoAndButtonsProps) => (
  <div className={styles.authorInfoAndButtonsContainer}>
    <PostUserInfo user={author}/>
    {!isCurrentUserAuthor && (
      <div className={styles.userActionButtons}>
        <RegularButton
          id={ID.postPageSubscribeUser}
          linkTo={YomeHash.unavailable}
          buttonType={RegularButtonTypes.transparentWithBorder}
          label={`${I18N_POST_ACTION}.subscribe`}
        />
        <RegularButton
          id={ID.postPageLinkToUserOffers}
          buttonType={RegularButtonTypes.transparentWithBorder}
          label={`${I18N_POST_ACTION}.showOffer`}
          linkTo={YomeURL.userPage(author.id)}
        />
      </div>
    )}
  </div>
)

export default AuthorInfoAndButtons
