import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { concatId, ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'

import IconButton from '../IconButton'

import styles from './chip.module.scss'

export enum ChipType {
  light = 'light',
  dark = 'dark',
}

interface IChip<T> {
  value: string;
  type: ChipType;
  chipKey?: string;
  chipId?: T;
  onClick?: () => void;
  iconName?: string;
  onIconClick?: (value: string, chipId?: T) => void;
  isNonBreakableText?: boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Chip = <T=string,>(
  { value, chipKey, type, chipId, onClick, onIconClick, iconName, isNonBreakableText = false }: IChip<T>,
) => {
  const { t } = useTranslation()
  const { isDesktop } = useViewport()

  return (
    <div
      className={classNames(
        isDesktop ? 'bodySizeM' : 'bodySizeS',
        styles.main,
        styles[type],
        { [styles.nonBreakableText]: isNonBreakableText },
      )}
      onClick={onClick}
    >
      {chipKey && (
        <span className={styles.chipLeft}>
          {t(chipKey)}
          : &nbsp;
        </span>
      )}
      <span className={styles.chipRight}>
        {t(value)}
      </span>
      {iconName && (
        <IconButton
          iconName={iconName}
          onClick={() => onIconClick?.(value, chipId) }
          id={concatId(ID.chipCloseButton, chipKey || value)}
          size={16}
        />
      )}
    </div>
  )
}

export default Chip
