import { I18N_COMING_SOON } from 'src/constants/i18n.constants'
import EmptyStateMessage from './EmptyStateMessage'

import styles from './emptyStateMessage.module.scss'

const ComingSoonEmptyState = () => (
  <EmptyStateMessage
    className={styles.comingSoon}
    icon='comingSoon'
    title={`${I18N_COMING_SOON}.title`}
    text={`${I18N_COMING_SOON}.text`}
  />
)

export default ComingSoonEmptyState
