import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_VERIFY_PHONE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { IVerifyPhoneModalProps } from 'src/models/verifyPhone.model'

import InfoModal from '../infoModal/InfoModal'

import styles from './verifyPhoneModals.module.scss'

const SuccessModal = ({ handleClose }: IVerifyPhoneModalProps) => {
  const modalFooter = (
    <RegularButton
      id={ID.verifyPhoneModalCloseButton}
      buttonType={RegularButtonTypes.main}
      label={`${I18N_VERIFY_PHONE}.success.label`}
      onClick={handleClose}
    />
  )

  return (
    <InfoModal modalFooter={modalFooter}>
      <EmptyStateMessage
        icon={'dragon-security'}
        title={`${I18N_VERIFY_PHONE}.success.title`}
        titleIconProps={{ name: 'check-in-circle', className: styles.checkIcon, currentColor: 'stroke' }}
        text={`${I18N_VERIFY_PHONE}.success.text`}
      />
    </InfoModal>
  )
}

export default SuccessModal
