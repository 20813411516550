import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { IValidationResult } from 'src/utils/auth.utils'
import SvgIcon from '../svgIcon/SvgIcon'

import styles from './validation.module.scss'

interface IValidationInfoProps {
  checkPoints: Record<string, IValidationResult>;
}

const ValidationInfo = ({ checkPoints }: IValidationInfoProps) => {
  const { t } = useTranslation()

  return (
    <ul className={styles.checkPoints}>
      { Object.values(checkPoints).map((singleCheckPoint) => (
        <li
          key={singleCheckPoint.i18nKey}
          className={classNames(styles.singleCheckPoint, singleCheckPoint.isPassed ? styles.pass : styles.fail)}
        >
          <SvgIcon
            currentColor='stroke'
            name={singleCheckPoint.isPassed ? 'check-in-circle' : 'cross-in-circle'}
            size={16}
          />
          <span className={classNames('supportive-14', styles.text)}>
            {t(singleCheckPoint.i18nKey)}
          </span>
        </li>
      ))}
    </ul>
  )
}

export default ValidationInfo
