import { InputHTMLAttributes } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import UploadFile from 'src/components/uploadFile/UploadFile'
import { emptyFunction } from 'src/constants/function.constants'
import { I18N_FORM, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useAssertValidAttachment } from 'src/hooks/form.hooks'
import FormError from '../FormError'
import UploadedPhoto from '../FormImageUpload/uploadedPhoto'

import styles from './formFileField.module.scss'

interface IFileFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  error: string;
  maxSize: number; // in MB (exmpl 2 or 5)
}

const FormFileField = ({ name, error, maxSize }: IFileFieldProps) => {
  const { values } = useFormState()
  const { change } = useForm()
  const assertValidAttachment = useAssertValidAttachment(maxSize)

  const files: File[] = values[name] || []

  const handleFileUpload = (acceptedFiles: File[]) => {
    const uploadedFiles = Array.from(acceptedFiles)
    const isValidAttachment = assertValidAttachment(uploadedFiles)

    if (!isValidAttachment) {
      return
    }

    change(name, [...files, ...uploadedFiles])
  }

  const handleDeleteFile = (index: number) => {
    const updatedFiles = files.filter((_, idx) => idx !== index)
    change(name, updatedFiles)
  }

  return (
    <div>
      <Field<FileList> name={name}>
        {({ input: { value, onChange, meta, ...input } }) => (
          <UploadFile handleFileUpload={handleFileUpload} {...input}>
            <RegularButton
              label={`${I18N_GLOBAL}.btn.attachFiles`}
              onClick={emptyFunction}
              id={ID.supportPageAttachButton}
              buttonType={RegularButtonTypes.transparentWithBorder}
              leftIcon={{ name: 'plus' }}
            />
          </UploadFile>
        )}
      </Field>
      <div className={styles.filesWrapper}>
        {files.map((file, index) => {
          const preview = URL.createObjectURL(file)

          return (
            <UploadedPhoto
              key={preview}
              hasCaption={false}
              handleDelete={() => handleDeleteFile(index)}
              imageSrc={preview}
              fileName={file.name}
              className={styles.uploadedPhoto}
            />
          )
        })}
      </div>
      {error && (
        <div className={styles.error}>
          <FormError
            error={`${I18N_FORM}.fileField.${error}`}
            iconSize={20}
            iconPosition='left'
          />
        </div>
      )}
    </div>
  )
}

export default FormFileField
