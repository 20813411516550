import classNames from 'classnames'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ABOUT_US_MAIN_FEATURES } from 'src/constants/i18n.constants'
import { MAIN_FEATURES } from 'src/constants/staticPages.constants'

import styles from './mainFeatures.module.scss'

const MainFeaturesCardsMobile = () => (
  <>
    <Swiper
      spaceBetween={50}
      className={styles.swiper}
      modules={[Pagination, Navigation]}
      slidesPerView={1}
      pagination={{
        clickable: true,
        el: '.swiper-custom-pagination',
      }}
      loop
    >
      {MAIN_FEATURES.map((feature) => (
        <SwiperSlide key={feature} className={styles.slide}>
          <div className={classNames('static-block', styles.item)}>
            <Heading
              level='h4'
              label={`${I18N_ABOUT_US_MAIN_FEATURES}.card.title.${feature}`}
              className={styles.itemTitle}
            />
            <Paragraph
              text={`${I18N_ABOUT_US_MAIN_FEATURES}.card.text.${feature}`}
              className={styles.itemText}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    <div className="swiper-custom-pagination"/>
  </>

)

export default MainFeaturesCardsMobile
