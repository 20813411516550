import classNames from 'classnames'
import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { KeyedMutator } from 'swr'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton } from 'src/components/form'
import FormRadio from 'src/components/form/FormRadio/FormRadio'
import ShortenOfferCard from 'src/components/shortenOfferCard/ShortenOfferCard'
import { I18N_ARCHIVE_MODAL, I18N_GLOBAL, I18N_POST_ACTION } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useProfileOfferListsReset } from 'src/hooks/offer.hooks'
import { ILimitedSingleOfferList, IPropsWithOffer, PostListType, SinglePost } from 'src/models/post.model'
import { archivePost } from 'src/services/post.service'
import { captureErrorAndShowToast, SubmitFormError } from 'src/utils/error.utils'
import { useToggle } from 'src/utils/hooks.utils'

import InfoModal from '../infoModal/InfoModal'
import FullscreenModalLayout from '../mobileModals/FullscreenModalLayout'
import ModalLayout from '../ModalLayout'

import styles from './archiveModal.module.scss'

const ARCHIVE_REASON_MAP = {
  soldOnYome: `${I18N_ARCHIVE_MODAL}.soldOnYome`,
  soldElsewhere: `${I18N_ARCHIVE_MODAL}.soldElsewhere`,
  itemNotForSale: `${I18N_ARCHIVE_MODAL}.itemNotForSale`,
  otherReason: `${I18N_ARCHIVE_MODAL}.otherReason`,
}

interface IArchiveModalProps<T> extends IPropsWithOffer {
  handleClose: () => void;
  mutate: KeyedMutator<T> | (() => void);
}

interface IArchiveReasonFormProps {
  handleArchivePost: ({ reason }: { reason: string })=>void;
  handleClose: () => void;
  toggleSuccessOpen: () => void;
}

const SuccessArchiveSubmit = ({ handleClose }: { handleClose: () => void }) => {
  const { t } = useTranslation()

  const footerButton = (
    <RegularButton
      id={ID.modalCloseButton}
      label={`${I18N_GLOBAL}.gotIt`}
      buttonType={RegularButtonTypes.blackNoBorder}
      onClick={handleClose}
    />
  )

  return (
    <ModalLayout handleClose={handleClose}>
      <InfoModal
        title={`${I18N_ARCHIVE_MODAL}.success.title`}
        topIcon='check-success'
        modalFooter={footerButton}
        className={styles.successContainer}
      >
        <div className='bodySizeL'>
          <p>
            {t(`${I18N_ARCHIVE_MODAL}.success.text`)}
          </p>
        </div>
      </InfoModal>
    </ModalLayout>
  )
}

const ArchiveReasonForm = React.memo((
  { handleArchivePost, handleClose, toggleSuccessOpen }: IArchiveReasonFormProps,
) => {
  const { t } = useTranslation()
  const { isDesktop } = useViewport()

  return (
    <Form
      onSubmit={handleArchivePost}

      render={function ArchiveReasonFormRender({ handleSubmit, submitSucceeded }) {
        if (submitSucceeded) {
          toggleSuccessOpen()
        }

        return (
          <form onSubmit={handleSubmit} className={styles.formContainer} >
            <label className='label-20'>
              { t(`${I18N_ARCHIVE_MODAL}.reason`) }
            </label>
            <div className={styles.formRadio}>
              <FormRadio
                id={ID.archiveModalInputReason}
                name="reason"
                options={ ARCHIVE_REASON_MAP }
              />
            </div>
            <div className={classNames(styles.buttonRow, !isDesktop && 'two-fixed-buttons-modal')}>
              <FormButton id={ID.archiveModalArchivePost} label={t(`${I18N_POST_ACTION}.archive`)}/>
              <RegularButton
                id={ID.archiveModalCancelModal}
                buttonType={RegularButtonTypes.transparentWithBorder}
                label={`${I18N_ARCHIVE_MODAL}.cancelButton`}
                onClick={handleClose}
              />
            </div>
          </form>
        )
      }}
    />
  )
})

const ArchiveModal = <T extends SinglePost | ILimitedSingleOfferList>
({ handleClose, offer, mutate }: IArchiveModalProps<T>) => {
  const { isDesktop } = useViewport()
  const { resetOfferList } = useProfileOfferListsReset(PostListType.archive)

  const [successOpen, toggleSuccessOpen] = useToggle()

  const handleArchivePost = async ({ reason }: { reason: string }) => {
    try {
      await archivePost(offer.slug, reason)
      resetOfferList()
      toggleSuccessOpen()
    } catch (error) {
      if (error instanceof SubmitFormError) {
        return error.errorObject
      }

      captureErrorAndShowToast(error)
    }

    return null
  }

  const onSuccessModalClose = useCallback(() => {
    mutate()
    handleClose()
  }, [mutate, handleClose])

  if (successOpen) {
    return (
      <SuccessArchiveSubmit handleClose={onSuccessModalClose}/>
    )
  }

  const ModalWrapper = isDesktop ? ModalLayout : FullscreenModalLayout

  return (
    <ModalWrapper handleClose={handleClose}>
      <div className={styles.modalContainer}>
        <InfoModal
          title={`${I18N_ARCHIVE_MODAL}.adArchived`}
          handleClose={handleClose}
        >
          <div className={styles.shortenOfferContainer}>
            <ShortenOfferCard idPrefix={ID.archiveModalPrefixPost} offer={offer} isLink/>
          </div>
          <ArchiveReasonForm
            handleArchivePost={handleArchivePost}
            handleClose={handleClose}
            toggleSuccessOpen={toggleSuccessOpen}
          />
        </InfoModal>
      </div>
    </ModalWrapper>
  )
}

export default ArchiveModal
