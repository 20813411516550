import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Heading from 'src/components/heading/Heading'
import NoPosts, { NoPostsType } from 'src/components/noPosts/NoPosts'
import PostList from 'src/components/postList/PostList'
import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import PostListLoader from 'src/components/skeletons/PostListLoader'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import SWRFormErrorBoundary from 'src/components/swr/SWRFormErrorBoundary'
import { I18N_SEARCH, I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useCurrentUser } from 'src/services/currentUser.service'
import { useGetSearchPostList } from 'src/services/search.service'
import { assertData } from 'src/utils/error.utils'

import styles from './searchResults.module.scss'

export const MIN_OFFERS_BEFORE_AD = 4

interface ISearchResultsProps {
  setFoundPosts: (nFoundPosts: number) => void;
}

interface ISearchResultsInner extends ISearchResultsProps {
  searchEndpoint: string;
}

interface ISearchResultsWrapper extends ISearchResultsProps {
  searchEndpoint: string;
}

const SearchResults = ({ setFoundPosts, searchEndpoint }: ISearchResultsInner) => {
  const { data: currentUser } = useCurrentUser()
  const { data: searchData, mutate, isLoading } = useGetSearchPostList(searchEndpoint)

  const { isDesktop } = useViewport()

  const { t } = useTranslation()

  useEffect(() => {
    mutate()
  }, [currentUser, mutate])

  useEffect(() => {
    setFoundPosts(searchData ? searchData.fromCity.length : 0)
  }, [searchData, setFoundPosts])

  if (isLoading) {
    return <PostListLoader isHorizontalView/>
  }

  assertData(searchData)

  if (searchData.fromCity.length === 0 && searchData.other.length === 0) {
    return <NoPosts type={NoPostsType.search} />
  }

  return (
    <div>
      <PostList
        idPostPrefix={ID.searchResultPrefixPost}
        posts={searchData.fromCity}
        postListKey={searchEndpoint}
        isHorizontalView
      />
      <ShowcaseArea type={isDesktop ? 'horizontal' : 'mobile-big'}/>
      {searchData.other.length > 0 && (
        <>
          <SvgIcon name='searchOtherCity' className={styles.otherCityIcon}/>
          <Heading
            className='desktop-h5 mobile-h5'
            level='h5'
            label={t(`${I18N_SEARCH}.otherCitiesTitle`, { offers: searchData.other.length })}
          />
        </>
      )}
      <PostList
        idPostPrefix={ID.searchResultPrefixPost}
        posts={searchData.other}
        postListKey={searchEndpoint}
        isHorizontalView
      />
      {searchData.other.length > MIN_OFFERS_BEFORE_AD && <ShowcaseArea type={isDesktop ? 'horizontal' : 'mobile-big'}/>}
    </div>
  )
}

export const SearchResultsBoundary = ({ setFoundPosts, searchEndpoint }: ISearchResultsWrapper) => {
  const getFormI18NKey = useCallback((fieldName: string) => `${I18N_SEARCH_FILTER}.${fieldName}.title`, [])

  return (
    <SWRFormErrorBoundary swrKey={searchEndpoint} getFormI18NKey={getFormI18NKey}>
      <SearchResults setFoundPosts={setFoundPosts} searchEndpoint={searchEndpoint}/>
    </SWRFormErrorBoundary>
  )
}

export default SearchResultsBoundary
