import classNames from 'classnames'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton, FormPassword } from 'src/components/form'
import ValidationInfo from 'src/components/validationInfo/ValidationInfo'
import { I18N_AUTH } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { useGetTokenFromURL } from 'src/hooks/auth.hooks'
import { useHashUrl } from 'src/hooks/router.hooks'
import { CONFIRM_PASSWORD_FIELDNAME, NEW_PASSWORD_FIELDNAME } from 'src/models/auth.model'
import { IModalWithClose } from 'src/models/modal.model'
import { EmailUrlAction } from 'src/models/url.model'
import { setNewPassword } from 'src/services/auth.service'
import { validatePassword } from 'src/utils/auth.utils'

import InfoModal from '../infoModal/InfoModal'
import SmthWentWrongModal from '../smthWentWrongModal/SmthWentWrongModal'

import styles from './auth.module.scss'

// TODO: it's too complex, has to be divided
const ChangePasswordModal = React.memo(({ handleClose }: IModalWithClose) => (
  <Form
    initialValues={{ token: useGetTokenFromURL(EmailUrlAction.reset) }}
    onSubmit={setNewPassword}
    render={function ChangePasswordForm({ handleSubmit, submitErrors, submitSucceeded, values }) {
      const { passwordValidationResult, allChecksPassed } = validatePassword(values.newPassword)

      const resetUrl = useHashUrl(YomeHash.resetPassword)
      const authUrl = useHashUrl(YomeHash.auth)

      const hasFormFieldErrors = submitErrors?.[NEW_PASSWORD_FIELDNAME] || submitErrors?.[CONFIRM_PASSWORD_FIELDNAME]

      if (submitErrors && !hasFormFieldErrors) {
        const resetPswButtonProps = {
          linkTo: resetUrl,
          label: `${I18N_AUTH}.resetPassword.buttonLabel`,
          id: ID.loginModalLinkToPasswordReset,
        }

        return (
          <SmthWentWrongModal
            title={`${I18N_AUTH}.resetPasswordFailed.title`}
            text={`${I18N_AUTH}.resetPasswordFailed.text`}
            mainButtonProps={resetPswButtonProps}
            secondButtonProps={{ id: ID.signupModalLinkToHelp }}
            handleClose={handleClose}
          />
        )
      }

      if (submitSucceeded) {
        const footerButton = (
          <RegularButton
            id={ID.loginModalButtonLogin}
            buttonType={RegularButtonTypes.main}
            linkTo={authUrl}
            label={`${I18N_AUTH}.logIn`}
          />
        )

        return (
          <InfoModal
            title={`${I18N_AUTH}.successResetPsw.title`}
            topIcon="check-success"
            className={styles.authModalContainer}
            modalFooter={footerButton}
            handleClose={handleClose}
          >
            <p className="bodySizeL">
              <Trans
                i18nKey={`${I18N_AUTH}.successResetPsw.text`}
                components={{
                  1: (
                    <RegularButton
                      id={ID.loginModalButtonProfile}
                      buttonType={RegularButtonTypes.transparentNoBorderLight}
                      linkTo={YomeURL.settings}
                      className={classNames(styles.inlineButton, styles.largeButton)}
                    />
                  ),
                }}
              />
            </p>
          </InfoModal>
        )
      }

      const footerButton = (
        <FormButton
          id={ID.changePasswordModalSubmitPassword}
          label={`${I18N_AUTH}.button.continue`}
          disabled={!allChecksPassed}
        />
      )

      return (
        <form onSubmit={handleSubmit} className={styles.formContainter}>
          <InfoModal
            title={`${I18N_AUTH}.changePassword`}
            className={styles.authModalContainer}
            modalFooter={footerButton}
            handleClose={handleClose}
          >
            <FormPassword
              id={ID.changePasswordModalInputPassword}
              name={NEW_PASSWORD_FIELDNAME}
              placeholder={`${I18N_AUTH}.placeholder.password`}
              fieldWrapperProps={{
                name: NEW_PASSWORD_FIELDNAME,
                label: `${I18N_AUTH}.label.password`,
                info: `${I18N_AUTH}.info.password`,
              }}
              autoFocus
            />
            { values.newPassword && <ValidationInfo checkPoints={passwordValidationResult}/>}
            <FormPassword
              id={ID.changePasswordModalInputPasswordConfirm}
              name={CONFIRM_PASSWORD_FIELDNAME}
              placeholder={`${I18N_AUTH}.placeholder.passwordConfirm`}
              fieldWrapperProps={{
                name: CONFIRM_PASSWORD_FIELDNAME,
                label: `${I18N_AUTH}.label.passwordConfirm`,
              }}
            />
          </InfoModal>
        </form>
      )
    }}
  />
))

export default ChangePasswordModal
