import { useNavigate } from 'react-router-dom'
import { MouseEvent, useCallback } from 'react'
import { mutate } from 'swr'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { I18N_SEARCH } from 'src/constants/i18n.constants'
import SWRkeys from 'src/constants/swr.constants'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import IconButton from 'src/components/button/IconButton'
import { IFilterValues } from 'src/models/search.model'
import YomeURL from 'src/constants/navigate.constants'
import { ID } from 'src/constants/id.constants'
import { getSavedSearchTitle } from 'src/utils/search.utils'
import { useSavedSearchText } from 'src/hooks/search.hooks'
import { getFilterSearchParams } from 'src/utils/filter.utils'
import { handleDeleteSearch } from 'src/services/search.service'

import styles from './favorites.module.scss'

interface ISearchCard {
  searchParams: IFilterValues;
  savedSearchId: string;
  refetchSavedSearch: () => void;
}

const SearchCard = ({ searchParams, savedSearchId, refetchSavedSearch }: ISearchCard) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const searchTitle = getSavedSearchTitle(searchParams)
  const searchParamsText = useSavedSearchText(searchParams)
  const urlSearchParams = getFilterSearchParams(searchParams).toString()
  const searchUrl = `${YomeURL.search}?${urlSearchParams}`

  const onSearchCardClick = useCallback(() => navigate(searchUrl), [navigate, searchUrl])
  const onDelete = useCallback(async (event: MouseEvent) => {
    event.stopPropagation()

    try {
      await handleDeleteSearch(savedSearchId)
      refetchSavedSearch()
      mutate(SWRkeys.favoriteCounters)
      toast.success(t(`${I18N_SEARCH}.deleteSuccess`))
    } catch (error) {
      toast.error(t(`${I18N_SEARCH}.deleteError`))
    }
  }, [refetchSavedSearch, savedSearchId, t])

  if (!searchParamsText) {
    return null
  }

  return (
    <div className={styles.searchCard} onClick={onSearchCardClick}>
      <div className={styles.searchDisplayBlock}>
        <RegularButton
          id={ID.savedSearchLinkToSearch}
          linkTo={searchUrl}
          buttonType={RegularButtonTypes.transparentNoBorderLight}
          label={searchTitle}
          className={styles.searchTitle}
        />
        <p className={styles.searchParams}>
          {searchParamsText}
        </p>
      </div>
      <div>
        <IconButton id={ID.savedSearchDeleteButton} iconName='trash' onClick={onDelete}/>
      </div>
    </div>
  )
}

export default SearchCard
