import { useParams } from 'react-router-dom'
import Loader from 'react-spinners/CircleLoader'

import { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import PostList from 'src/components/postList/PostList'
import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import SortDropdown from 'src/components/SortDropdown/SortDropdown'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import { I18N_GLOBAL, I18N_USER_NO_OFFERS } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useSorting } from 'src/hooks/sort.hooks'
import { PostListType } from 'src/models/post.model'
import { SortPostListTypes } from 'src/models/sort.model'
import { useGetUserPostList } from 'src/services/user.service'

import styles from './userPosts.module.scss'

interface IUserOffersListBoundary {
  listType: PostListType.active | PostListType.inactive;
}

interface IUserOffersList extends IUserOffersListBoundary{
  userOffersListKey: string;
}

const UserOffersListRoute = ({ listType, userOffersListKey }: IUserOffersList) => {
  const { sortOfferList, sortBy } = useSorting()
  const { data: userOffers, isLoading } = useGetUserPostList(userOffersListKey)

  const { isDesktop } = useViewport()

  const noPostsButton = {
    linkTo: YomeURL.mainPage,
    id: ID.myItemsButtonNewOffer,
    label: `${I18N_GLOBAL}.toHome`,
    buttonType: RegularButtonTypes.blackNoBorder,
  }

  if (isLoading) {
    return (
      <Loader />
    )
  }

  if (!userOffers || !userOffers[listType].length) {
    return (
      <div className={styles.offersContainer}>
        <EmptyStateMessage
          icon='offers-search'
          title={`${I18N_USER_NO_OFFERS}.title`}
          text={`${I18N_USER_NO_OFFERS}.${listType}.description`}
          button={noPostsButton}
        />
      </div>
    )
  }

  return (
    <div className={styles.offersContainer}>
      <SortDropdown
        idSortButton={ID.userPageSortPost}
        defaultValue={sortBy}
        onChange={sortOfferList}
        sortOptions={Object.values(SortPostListTypes)}
      />
      <PostList
        idPostPrefix={ID.userPagePrefixPost}
        posts={userOffers[listType]}
        postListKey={userOffersListKey}
        hasAllFavorite={false}
        listType={listType}
      />
      {!isDesktop && <ShowcaseArea type='mobile-small' />}
    </div>
  )
}

const UserOffersListRouteBoundary = ({ listType }: IUserOffersListBoundary) => {
  const { userId } = useParams()
  const { sortBy } = useSorting()
  const userOffersListKey = SWRkeys.userOffers(userId!, sortBy)

  return (
    <SWRErrorBoundary swrKey={userOffersListKey}>
      <UserOffersListRoute listType={listType} userOffersListKey={userOffersListKey}/>
    </SWRErrorBoundary>
  )
}

export default UserOffersListRouteBoundary
