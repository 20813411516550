import React, { useEffect } from 'react'
import { Field, useForm } from 'react-final-form'

import { I18N_HELP_FORM } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'

import RegularButton, { RegularButtonTypes } from '../button/RegularButton'
import FieldWrapper, { IFieldWrapperProps } from './FieldWrapper'

import styles from './form.module.scss'

interface IFormEmailValidationFieldProps {
  fieldWrapperProps?: IFieldWrapperProps;
  id: string;
  name: string;
  className?: string;
  autocomplete?: string;
  isDisplayButton: boolean;
  lastUsedEmail?: string;
  handleClick: (email: string)=> void;
  resetError: () => void;
  handleBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormEmailValidationField = React.memo(({
  fieldWrapperProps,
  id,
  name,
  className,
  autocomplete,
  isDisplayButton,
  lastUsedEmail,
  handleClick,
  resetError,
  handleBlur,
}: IFormEmailValidationFieldProps) => {
  const { change } = useForm()

  useEffect(() => {
    if (lastUsedEmail) {
      change(name, lastUsedEmail)
    }
  }, [change, name, lastUsedEmail])

  return (
    <Field name={name} type="email">
      {function renderFormEmail({ input }) {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target
          change(name, value)
          resetError()
        }

        return (
          <div className={styles.wrapper}>
            <FieldWrapper {...fieldWrapperProps} name={name}>
              <input
                {...input}
                id={id}
                className={className || styles.fieldInput}
                onChange={handleChange}
                type={name}
                placeholder='username@domain.com'
                autoComplete={autocomplete}
                onBlur={handleBlur}
              />
            </FieldWrapper>
            { isDisplayButton && (
              <RegularButton
                id={ID.searchBarSearchPost}
                label={`${I18N_HELP_FORM}.button.verifyEmail`}
                onClick={() => handleClick(input.value)}
                buttonType={RegularButtonTypes.blackNoBorder}
                className={styles.verifyButton}
              />
            )}
          </div>
        )
      }}
    </Field>
  )
})

export default FormEmailValidationField
