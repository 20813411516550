import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_MAP_CITY_NAME, DEFAULT_MAP_POSITION } from 'src/constants/map.constants'
import {
  getAddressByLatLng,
  getStoredLocationSettings,
  parseLatLngString,
  storeLocationSettings,
} from 'src/utils/map.utils'
import { I18N_CATEGORIES, I18N_SEARCH_FILTER } from 'src/constants/i18n.constants'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'
import { IFilterValues } from 'src/models/search.model'
import { getPathToCurrentCategory } from 'src/yome-categories-fields-config/utils/category.utils'
import { getFiltersFieldsStructure } from 'src/utils/filter.utils'
import { ISearchLocation } from 'src/models/location.model'
import { useGetFilterChips } from './filter.hooks'

// eslint-disable-next-line import/prefer-default-export
export const useSearchLocation = () => {
  const { i18n: { language } } = useTranslation()
  const [searchLocation, setSearchLocation] = useState<ISearchLocation | undefined>(getStoredLocationSettings())

  const handleSearchLocation = useCallback((value: ISearchLocation) => {
    storeLocationSettings(value)
    setSearchLocation(value)
  }, [])

  useEffect(() => {
    if (!searchLocation?.locationPosition || searchLocation.language !== language) {
      const locationPosition = searchLocation?.locationPosition || DEFAULT_MAP_POSITION

      getAddressByLatLng(locationPosition, true).then(((address) => {
        handleSearchLocation({
          ...searchLocation,
          language,
          locationPosition,
          locationAddress: address || DEFAULT_MAP_CITY_NAME,
        })
      }))
    }
  }, [handleSearchLocation, language, searchLocation])

  return { searchLocation, setSearchLocation: handleSearchLocation }
}

const getPreviousCategoryI18Key = (category: string) => {
  const path = getPathToCurrentCategory(category)

  if (path.length > 1) {
    const prevCategory = path[path.length - 2]

    return prevCategory === ANY_CATEGORY ? '' : `${I18N_CATEGORIES}.${prevCategory}`
  }

  return ''
}

export const useSavedSearchText = (searchParams: IFilterValues) => {
  const { t } = useTranslation()

  const [city, setCity] = useState('')

  const categoryFields = getFiltersFieldsStructure(searchParams.category || ANY_CATEGORY)

  const firstPart = searchParams.searchContent ? `${I18N_CATEGORIES}.${searchParams.category}` : (
    getPreviousCategoryI18Key(searchParams.category || ANY_CATEGORY)
  )

  getAddressByLatLng(parseLatLngString(searchParams.city)!, true).then(
    (address) => setCity(address || ''),
  )

  const getFilterChips = useGetFilterChips()

  if (!city) {
    return ''
  }

  const summaryChips = getFilterChips(searchParams, categoryFields)
  const otherFilters = Object.entries(summaryChips).map(([filterKey, chipValues]) => {
    const values = chipValues && chipValues.map((chip) => t(chip.chipValue))
    const key = `${I18N_SEARCH_FILTER}.${filterKey}.chipKey`

    return `${t(key)}: ${values.join(', ')}`
  })

  return [t(firstPart), city].concat(otherFilters).filter((str) => str).join(', ')
}
