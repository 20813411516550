import { useViewport } from 'src/contexts/viewportContext'
import { useCurrentUser } from 'src/services/currentUser.service'
import { DESKTOP_CARD_STACK_LIMIT, MOBILE_CARD_STACK_LIMIT } from 'src/services/post.service'
import ShowcaseArea from 'src/components/showcaseArea/ShowcaseArea'
import AuthorizedUserCardStack from './AuthorizedUserCardStack'
import FreeCardStack from './FreeCardStack'
import ViewHistoryCardStack from './ViewHistoryCardStack'

import styles from './cardStack.module.scss'

const OfferCardStack = () => {
  const { data: currentUser } = useCurrentUser()
  const { isDesktop } = useViewport()

  const offerLimit = isDesktop ? DESKTOP_CARD_STACK_LIMIT : MOBILE_CARD_STACK_LIMIT

  return (
    <div className={styles.cardStackList}>
      {currentUser ? (
        <AuthorizedUserCardStack offerLimit={offerLimit}/>
      )
        : (
          <ViewHistoryCardStack offerLimit={offerLimit} />
        )}
      <FreeCardStack offerLimit={offerLimit}/>
      <ShowcaseArea type={isDesktop ? 'vertical' : 'mobile-small'}/>
    </div>
  )
}

export default OfferCardStack
