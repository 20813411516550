import classNames from 'classnames'
import { memo } from 'react'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_LEGAL_COOKIES } from 'src/constants/i18n.constants'

const COOKIES_TABLE_HEAD = ['category', 'name', 'purpose', 'expiry']

const COOKIES_TABLE_BODY = [
  'gdprConsent',
  'ga',
  'secure',
  'sid',
  'sidcc',
  'sapisid',
  'aec',
  'nid',
  'dv',
  'searchSamesite',
]

const CookiesPolicyTable = () => (
  <div className="table">
    <div className="table-row table-head">
      {COOKIES_TABLE_HEAD.map((cookiesHeadCell) => (
        <Heading
          level='h4'
          key={cookiesHeadCell}
          className={classNames('table-cell', 'desktop-h7 mobile-heading-subtitle')}
          label={`${I18N_LEGAL_COOKIES}.table.head.${cookiesHeadCell}`}
        />
      ))}
    </div>
    {COOKIES_TABLE_BODY.map((cookiesTableRow) => (
      <div key={cookiesTableRow} className='table-row'>
        {COOKIES_TABLE_HEAD.map((cookiesTableColumn) => (
          <Paragraph
            className='table-cell'
            key={cookiesTableColumn}
            text={`${I18N_LEGAL_COOKIES}.table.${cookiesTableRow}.${cookiesTableColumn}`}
          />
        ))}
      </div>
    ))}
  </div>
)

export default memo(CookiesPolicyTable)
