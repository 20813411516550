import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ADVERTISING } from 'src/constants/i18n.constants'
import { ADVERTISING_IMAGES_PATH } from 'src/constants/staticPages.constants'

import styles from './formats.module.scss'

interface ISingleFormat {
  format: string;
  isSecondElementOnDesktop: boolean;
}

const SingleFormat = ({ format, isSecondElementOnDesktop }: ISingleFormat) => {
  const imageSrc = isSecondElementOnDesktop ? `${format}Desktop` : format

  return (
    <li className={classNames(styles.listItem, 'static-block')}>
      <div className={styles.content}>
        <Heading
          level='h6'
          label={`${I18N_ADVERTISING}.formats.${format}.title`}
          className='label-28'
        />
        <Paragraph
          text={`${I18N_ADVERTISING}.formats.${format}.subtitle`}
          className={classNames(styles.cardSubtitle, 'label-20')}
        />
        <Paragraph
          text={`${I18N_ADVERTISING}.formats.${format}.text`}
          className='bodySizeL'
        />
      </div>
      <img
        src={`${ADVERTISING_IMAGES_PATH}/formats/${imageSrc}.png`}
        className={styles.img}
      />
    </li>
  )
}

export default SingleFormat
