import { useSearchParams } from 'react-router-dom'

import { IChat } from 'src/models/messenger.model'
import { CHAT_ID_SEARCH_PARAM } from 'src/services/messenger.service'

import ChatDialog from './components/chatDialog/ChatDialog'
import ChatsPanel from './components/chatsPanel/ChatsPanel'
import MessengerLabel from './components/MessengerLabel'

interface IChatsPanelOrDialog {
  currentChat: IChat | undefined;
}

const ChatsPanelOrDialog: React.FC<IChatsPanelOrDialog> = ({ currentChat }) => {
  const [searchParams] = useSearchParams()
  const chatId = searchParams.get(CHAT_ID_SEARCH_PARAM)

  return (
    <>
      {chatId && currentChat ? (
        <ChatDialog currentChat={currentChat} />
      ) : (
        <>
          <MessengerLabel />
          <ChatsPanel currentChat={currentChat} />
        </>
      )}
    </>
  )
}

export default ChatsPanelOrDialog
