import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import { I18N_FOR_BUSINESS, I18N_GLOBAL } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'

import PlanListItem from './PlanListItem'

import styles from './plans.module.scss'

interface ISinglePlan {
  name: string;
  includes: string[];
  benefits?: string[];
  benefitsDesktop?: string[];
}

const SinglePlan = ({ name, includes, benefits, benefitsDesktop }: ISinglePlan) => {
  const { t } = useTranslation()
  const { isDesktop } = useViewport()
  const benefitsToRender = isDesktop ? benefitsDesktop : benefits

  return (
    <div className={classNames(styles.singlePlan, 'static-block')}>
      <div className={styles.header}>
        <Heading
          level='h4'
          label={`${I18N_FOR_BUSINESS}.plans.${name}`}
          className={'desktop-h3 mobile-h4'}
        />
        <span
          className={classNames('heading desktop-h5 mobile-heading-subtitle', styles.price)}
        >
          {t(`${I18N_FOR_BUSINESS}.plans.${name}.price`)}
        </span>
      </div>
      <ul className={styles.featuresList}>
        {includes.map((option) => (
          <PlanListItem
            key={option}
            label={`${I18N_FOR_BUSINESS}.plans.${name}.${option}`}
          />
        ))}
      </ul>
      <ul className={styles.benefitsList}>
        {benefitsToRender?.map((benefit) => (
          <PlanListItem
            key={benefit}
            isBenefit
            label={`${I18N_FOR_BUSINESS}.plans.${name}.${benefit}`}
          />
        ))}
      </ul>
      <RegularButton
        className={styles.learnMoreBtn}
        linkTo={YomeURL.help}
        buttonType={RegularButtonTypes.transparentNoBorder}
        id={concatId(ID.forBusinessLearnMoreButton, name)}
        label={`${I18N_GLOBAL}.learnMore`}
        rightIcon={{ name: 'chevron-right', size: 24 }}
        size={RegularButtonSizes.large}
      />
    </div>
  )
}

export default SinglePlan
