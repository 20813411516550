import { memo, useState } from 'react'

import { IModalWithClose } from 'src/models/modal.model'
import { IPhoneNumber, IVerifyPhoneModalProps, PhoneCodeOptions, VerifyPhoneModals } from 'src/models/verifyPhone.model'
import { CountryIsoCode } from 'src/yome-categories-fields-config/models/fields.model'

import CodeFailedModal from './CodeFailedModal'
import EnterCodeModal from './EnterCodeModal'
import LimitReachedModal from './LimitReachedModal'
import PhoneUsedModal from './PhoneUsedModal'
import SuccessModal from './SuccessModal'
import VerifyPhoneModal from './VerifyPhoneModal'

type ModalComponentType = ((props: IVerifyPhoneModalProps) => JSX.Element | null)

const MODAL_TYPE_TO_COMPONENT: Record<VerifyPhoneModals, ModalComponentType> = {
  [VerifyPhoneModals.baseVerifyPhone]: VerifyPhoneModal,
  [VerifyPhoneModals.enterCode]: EnterCodeModal,
  [VerifyPhoneModals.verifySuccess]: SuccessModal,
  [VerifyPhoneModals.codeFailed]: CodeFailedModal,
  [VerifyPhoneModals.limitReached]: LimitReachedModal,
  [VerifyPhoneModals.phoneInUse]: PhoneUsedModal,
}

const VerifyPhoneModalWrapper = ({ handleClose }: IModalWithClose) => {
  const [currentModal, setCurrentModal] = useState<VerifyPhoneModals>(VerifyPhoneModals.baseVerifyPhone)
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>({ phoneCountryCode: CountryIsoCode.de, phoneBody: '' })
  const [codeType, setCodeType] = useState<PhoneCodeOptions>(PhoneCodeOptions.sms)

  const ModalComponent = MODAL_TYPE_TO_COMPONENT[currentModal]
  const modalProps = {
    setCurrentModal,
    phoneNumber,
    setPhoneNumber,
    handleClose,
    codeType,
    setCodeType,
  }

  return (
    <ModalComponent {...modalProps} />
  )
}

export default memo(VerifyPhoneModalWrapper)
