import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_NOTIFICATIONS, I18N_NOTIFICATIONS_EMPTY_STATE } from 'src/constants/i18n.constants'

import ProfileHeading from '../ProfileHeading'
import styles from './notifications.module.scss'

const NotificationsRoute = () => (
  <div className={styles.main}>
    <ProfileHeading
      label={`${I18N_NOTIFICATIONS}.heading`}
    />
    <EmptyStateMessage
      title={`${I18N_NOTIFICATIONS_EMPTY_STATE}.title`}
      icon='notification'
      text={`${I18N_NOTIFICATIONS_EMPTY_STATE}.text`}
    />
  </div>
)

export default NotificationsRoute
