import classNames from 'classnames'
import { Trans } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import { I18N_AUTH, I18N_VERIFY_PHONE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { YomeHash } from 'src/constants/navigate.constants'
import { IVerifyPhoneModalProps, VerifyPhoneModals } from 'src/models/verifyPhone.model'
import { getFullPhoneNumber } from 'src/utils/form.utils'

import InfoModal from '../infoModal/InfoModal'

import styles from './verifyPhoneModals.module.scss'

const PhoneUsedModal = ({ setCurrentModal, phoneNumber }: IVerifyPhoneModalProps) => {
  const modalFooter = (
    <div className={styles.buttonRow}>
      <RegularButton
        id={ID.verifyPhoneModalLinkToLogin}
        buttonType={RegularButtonTypes.main}
        label={`${I18N_AUTH}.logIn`}
        linkTo={YomeHash.auth}
      />
      <RegularButton
        id={ID.verifyPhoneModalLinkToPhone}
        buttonType={RegularButtonTypes.transparentWithBorder}
        label={`${I18N_VERIFY_PHONE}.correctNumber.label`}
        onClick={() => setCurrentModal(VerifyPhoneModals.baseVerifyPhone)}
      />
    </div>
  )

  return (
    <InfoModal modalFooter={modalFooter} className={styles.verifyPhoneContainer}>
      <EmptyStateMessage
        icon={'something-went-wrong-2'}
        title={`${I18N_VERIFY_PHONE}.numberInUse.title`}
      />
      <p className={classNames('bodySizeL', styles.mainText)}>
        <Trans
          i18nKey={`${I18N_VERIFY_PHONE}.numberInUse.text`}
          values={{ phoneNumber: getFullPhoneNumber(phoneNumber) }}
          components={{ 1: <strong className={styles.noWrap}/> }}
        />
      </p>
    </InfoModal>
  )
}

export default PhoneUsedModal
