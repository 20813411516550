import classNames from 'classnames'
import { Trans } from 'react-i18next'

import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_ABOUT_US_WE_CARE } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useI18nLanguage } from 'src/hooks/i18n.hooks'

import styles from './weCare.module.scss'

const WeCare = () => (
  <div className={styles.main}>
    <Paragraph
      text={`${I18N_ABOUT_US_WE_CARE}.text`}
      className={styles.text}
    />
    <div className={classNames(styles.pointsBox, styles[useI18nLanguage()])}>
      <Heading
        level='h4'
        label={`${I18N_ABOUT_US_WE_CARE}.label`}
        className={styles.subheading}
      />
      <div className={styles.points}>
        <Paragraph
          text={`${I18N_ABOUT_US_WE_CARE}.paragraph.first`}
        />
        <Paragraph
          text={`${I18N_ABOUT_US_WE_CARE}.paragraph.second`}
        />
        <p>
          <Trans
            i18nKey={`${I18N_ABOUT_US_WE_CARE}.paragraph.last`}
            components={{
              1: <a className={styles.link} href={YomeURL.help} />,
              2: <a className={styles.link} href="mailto:info@yome.network" />,
            }}
          />
        </p>
      </div>
    </div>
  </div>
)

export default WeCare
