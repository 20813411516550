import ComingSoonEmptyState from 'src/components/emptyStateMessage/ComingSoonEmptyState'
import { I18N_REQUESTS } from 'src/constants/i18n.constants'
import ProfileHeading from '../ProfileHeading'

const RequestsRoute = () => (
  <div>
    <ProfileHeading label={`${I18N_REQUESTS}.heading`}/>
    <ComingSoonEmptyState />
  </div>
)

export default RequestsRoute
