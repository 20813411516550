import classNames from 'classnames'
import { memo } from 'react'

import { AvatarSizes, IUploadImageResponse } from 'src/models/upload.model'

import { getThumbSmallUrl } from '../../utils/images.utils'
import ImageOrNoPhoto from '../imageOrNoPhoto/ImageOrNoPhoto'

import styles from './avatar.module.scss'

export const DEFAULT_POST_AVATAR_ALT = 'avatar'

export type IPostAvatarProps = {
  image: IUploadImageResponse;
  folder: string;
  avatarType: AvatarSizes;
}

const PostAvatar = ({ avatarType, image, folder }: IPostAvatarProps) => (
  <ImageOrNoPhoto
    alt={image.filename || DEFAULT_POST_AVATAR_ALT}
    src={getThumbSmallUrl(image, folder)}
    className={classNames(styles.round, styles[avatarType])}
  />
)

export default memo(PostAvatar)
