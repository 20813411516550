import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_POST, I18N_POST_ACTION } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { YomeHash } from 'src/constants/navigate.constants'
import YomeAPI from 'src/constants/network.constants'
import { useFavorite } from 'src/hooks/favorite.hooks'
import { useHandleShareOfferLink } from 'src/hooks/offer.hooks'

import WriteMsgBtn from './WriteMsgBtn'

import styles from './customerActions.module.scss'

interface PostCustomerActionsProps {
  slug: string;
  postId: string;
  responseTime?: number;
  isFavoritePost: boolean;
  title: string;
  isInactiveView: boolean;
}

const PostCustomerActions = ({
  slug,
  postId,
  responseTime,
  isFavoritePost,
  title,
  isInactiveView,
}: PostCustomerActionsProps) => {
  const { t } = useTranslation()
  const handleShareLink = useHandleShareOfferLink(title)

  const offerSourceEndpoint = YomeAPI.getPublicOffer(slug)

  const { handlePostLike, favoriteIconName, favoriteButtonLabel } = useFavorite(
    { slug, isFavoritePost, offerSourceEndpoint },
  )

  const favoriteButton = (
    <RegularButton
      id={ID.postPageLikePost}
      buttonType={RegularButtonTypes.transparentWithBorder}
      size={RegularButtonSizes.large}
      onClick={handlePostLike}
      leftIcon={{ name: favoriteIconName, size: 24, currentColor: isFavoritePost ? 'fill' : undefined }}
      label={favoriteButtonLabel}
    />
  )

  if (!isInactiveView) {
    return (
      <div className={styles.customerActionsContainer}>
        <div className={styles.priceAndFeatures}>
          <div className={styles.suggestPrice}>
            <span className='label-16'>
              {t(`${I18N_POST}.or`)}
            </span>
            <RegularButton
              id={ID.postPageSuggestPrice}
              buttonType={RegularButtonTypes.transparentNoBorderLight}
              linkTo={YomeHash.unavailable}
              label={`${I18N_POST}.suggestYourPrice`}
            />
          </div>
          <WriteMsgBtn postId={postId} responseTime={responseTime}/>
          {/* <PostFeatureList
            postFeatures={[PostFeatures.Delivery, PostFeatures.PaymentUponReceipt, PostFeatures.Refund]}
          /> */}
        </div>

        <div className={styles.postActionButtons}>
          {favoriteButton}
          <RegularButton
            id={ID.postPageSharePost}
            buttonType={RegularButtonTypes.transparentWithBorder}
            size={RegularButtonSizes.large}
            onClick={handleShareLink}
            leftIcon={{ name: 'share', size: 24 }}
            label={`${I18N_POST_ACTION}.shareOffer`}
          />
        </div>
      </div>
    )
  }

  if (isInactiveView && isFavoritePost) {
    return (
      <div className={styles.inactivePostActionButton}>
        {favoriteButton}
      </div>
    )
  }

  return null
}

export default PostCustomerActions
